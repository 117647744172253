import React, { useContext, useEffect, useRef, useState } from "react";
import "../../../profile/profile.css";
import DataTable from "react-data-table-component";
import { Fetchdata } from "../../../hooks/getData";
import AuthContext from "../../../context/auth-context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Spinner from "../../../loading/spinner";
import UpperbarContext from "../../../context/upperbar-context";
import { FaRegCalendarAlt } from "react-icons/fa";
import Toast from "../../../Toast";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { GoPlus } from "react-icons/go";
import { AiOutlineEdit, AiOutlineEye, AiOutlineSearch } from "react-icons/ai";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { englishToNepaliNumber } from "nepali-number";
import { generatePdf } from "../../../hooks/PdfExcel";
import ResignationPopup from "./ResignationPopup";
import ResignationViewPopup from "./ResignationViewPopup";
import ApprovePop from "../../leaveReport/ApprovePop";
import RejectPop from "../../leaveReport/RejectPop";
import { GetEnglishDate, GetNepaliDate } from "../../../hooks/dateConvertor";

export default function Resignation() {
  const {
    fiscalYear,
    expires,
    customStyles,
    appURL,
    todayDate,
    mode,
    userDetails,
    dateMode
  } = useContext(UpperbarContext);
  const [popup, setPopup] = useState(false);
  const [viewPopup, setViewPopup] = useState(false);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const searchInput = useRef("");
  const [DFlag, setDFlag] = useState("N");

  const initialValue = {
    department: "",
    staff: "",
    date: "",
    type: "R",
    reason: "",
  };
  const [formValue, setFormValue] = useState(initialValue);
  const [formError, setFormError] = useState({});
  const [isApproved, setIsApproved] = useState(false);

  const { User } = useContext(AuthContext);

  const addLeaveNote = (e) => {
    setPopup(true);
    setFormValue(initialValue);
    setReload(Math.random());
  };

  const [viewData, setViewData] = useState();
  const handleView = (datas) => {
    setViewPopup(true);
    setViewData(datas);
  };

  //

  const [approveGmail, setApproveGmail] = useState(false);
  const [rejectGmail, setRejectGmail] = useState(false);

  //Everything to pop up and approve the  list
  const [approvePopup, setApprovePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [rejectPopup, setRejectPopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  // To approve the credit List

  const [Acdata, setACData] = useState();
  const [leaveAccess, setLeaveAccess] = useState();
  const [acceptUserId, setAcceptUserId] = useState();

  const handleApprove = async (data) => {
    setACData(data[0]);
    setLeaveAccess(data[1]);
    setAcceptUserId(data[2]);

    setApprovePopup({
      show: true,
      data,
    });
  };

  // This will perform the approve and hide the Confirmation Box

  const handleApproveTrue = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID.toString(),
      Flag: "AI",
      StaffID: acceptUserId.toString(),
      RegTermID: Acdata.toString(),
      IsApproved: "Y",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Signature: "p0m76" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/reg-term`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          rgsnList();
          toast.success(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });
    });
    setApprovePopup({
      show: false,
      data: null,
    });
    setApproveGmail(false);
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleApproveFalse = () => {
    setApprovePopup({
      show: false,
      data: null,
    });
    setApproveGmail(false);
  };

  //Everything to pop up and approve the leave list ends

  // To reject the leave List

  const [bcdata, setBcData] = useState();
  const [leavedata, setLeaveData] = useState();
  const [userId, setUserId] = useState();

  const handleReject = async (data) => {
    setBcData(data[0]);
    setLeaveData(data[1]);
    setUserId(data[2]);

    setRejectPopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleRejectTrue = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID.toString(),
      Flag: "AI",
      StaffID: userId.toString(),
      RegTermID: bcdata.toString(),
      IsApproved: "N",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Signature: "p0m76" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/reg-term`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          rgsnList();
          toast.success(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });
    });
    setRejectPopup({
      show: false,
      data: null,
    });
    setRejectGmail(false);
  };

  //
  // };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleRejectFalse = () => {
    setRejectPopup({
      show: false,
      data: null,
    });
    setRejectGmail(false);
  };

  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      grow: 0,
      center: true,
      width: "70px",
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },
    {
      name: mode === "en" ? "Fullname" : "पुरानाम",
      // grow: 0,
      // center: true,
      minWidth: "200px",
      selector: (row) => row.FullName,
    },
    {
      name: mode === "en" ? "Department" : "विभाग",
      // center: true,
      // grow: 0,
      minWidth: "200px",
      selector: (row) => row.Department,
    },

    {
      name: mode === "en" ? "Join Date" : "सामेल हुने मिति",
      // grow: 1,
      // center: true,
      width: "120px",
      selector: (row) => dateMode === "en" ? row.JoinDate : GetNepaliDate(row.JoinDate),
    },
    {
      name: mode === "en" ? "Leave Date" : "मिति छोड्नुहोस्",
      // grow: 1,
      // center: true,
      width: "120px",
      selector: (row) => dateMode === "np" ? row.LeaveDate : GetEnglishDate(row.LeaveDate),
    },
    {
      name: mode === "en" ? "Type" : "टाइप ",
      // grow: 0,
      // center: true,
      width: "150px",
      selector: (row) => row.Type,
    },

    {
      name: mode === "en" ? "Status" : "स्थिति",
      // grow: 0,
      center: true,
      width: "200px",
      selector: (row) => {
        return (
          <>
            {row.IsApproved === "P" ? (
              <div className="ln-verition d-flex">
                <button
                  type="button"
                  className="ln-verition btn btn-sm d-flex pe-1"
                  // style={{ background: "var(--button-color)", color: "white" }}
                  onClick={() =>
                    handleApprove([row.TermRegID, row.IsApproved, row.UserID])
                  }
                >
                  <span
                    className=" badge rounded-pill bg-primary"
                    style={{ fontSize: "11px" }}
                  >
                    {mode === "en" ? "Approve" : "अनुमोदन"}
                  </span>
                </button>
                <button
                  type="button"
                  className="ln-verition btn btn-sm d-flex ps-1"
                  // style={{
                  //   background: "red",
                  //   color: "white",
                  //   marginLeft: "5px",
                  // }}
                  onClick={() =>
                    handleReject([row.TermRegID, row.IsApproved, row.UserID])
                  }
                >
                  <span
                    className=" badge rounded-pill bg-danger"
                    style={{ fontSize: "11px" }}
                  >
                    {mode === "en" ? "Reject" : "अस्वीकार"}
                  </span>
                </button>
              </div>
            ) : row.IsApproved === "Y" ? (
              <span
                className=" badge rounded-pill bg-success"
                style={{ fontSize: "11px" }}
              >
                {mode === "en" ? "Approved" : "स्वीकृत"}
              </span>
            ) : row.IsApproved === "N" ? (
              <span
                className=" badge rounded-pill bg-danger"
                style={{ fontSize: "11px" }}
              >
                {mode === "en" ? "Rejected" : "अस्वीकार गरियो"}
              </span>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      name: mode === "en" ? "Action" : "कार्य",
      // grow: 0,
      center: true,
      width: "90px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                class="btn btn-sm downloadspan mx-1"
                onClick={() => handleView(row)}
                uk-tooltip={mode === "en" ? "View" : "हेर्नुहोस्"}
              >
                <AiOutlineEye />
              </button>{" "}
            </div>
          </>
        );
      },
    },
  ];

  //API to hit Resignation list
  const [resignationList, setResignationList] = useState([]);
  useEffect(() => {
    rgsnList();
  }, []);

  const rgsnList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      IsApproved: "Y",
      Type: "POST",
      FetchURL: `${appURL}api/admin/reg-term`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setResignationList(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setLoading(false);
        setOriginalList([]);
        setResignationList([]);
      }
    });
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["FullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setResignationList(srchResult);
      } else {
        setResignationList({});
      }
    } else {
      setResignationList(originalList);
    }
  };

  const dataWithIndex = resignationList.map((d, i) => ({
    ...d,
    Index: i + 1,
    Status:
      d.IsApproved === "P"
        ? "Pending"
        : d.IsApproved === "Y"
        ? "Approved"
        : "Rejected",
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Fullname", field: "FullName" },
    { header: "Department", field: "Department" },
    { header: "Join Date", field: "JoinDate" },
    { header: "Leave Date", field: "LeaveDate" },
    { header: "Type", field: "Type" },
    { header: "Status", field: "Status" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    generatePdf(userDetails, exportColumns, dataWithIndex);
  };

  const dataToExcel = resignationList.map((d, i) => ({
    "S.N.": i + 1,
    Fullname: d.FullName,
    Department: d.Department,
    "Join Date": d.JoinDate,
    "Leave Date": d.LeaveDate,
    Type: d.Type,
    Status:
      d.IsApproved === "P"
        ? "Pending"
        : d.IsApproved === "Y"
        ? "Approved"
        : "Rejected",
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Designation");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper  ps-4 pe-4">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">
              {mode === "en" ? "Resignation" : "राजीनामा"}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <span className="me-2 ">Expires in: {expires} </span>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>
        <>
          <div className="sec-dataTable">
            <div className="upper-dataTbl">
              <div className="uk-flex  uk-flex-wrap uk-flex-between uk-flex-middle mb-3">
                <div></div>
                <div className="">
                  <div className="d-flex uk-flex-wrap uk-flex-middle uk-flex-right">
                    <div className="btn-addlnote mt-3">
                      <button
                        type="button"
                        class="btn btn-sm"
                        style={{
                          background: "var(--button-color)",
                          color: "white",
                        }}
                        onClick={addLeaveNote}
                      >
                        <GoPlus color="#fff" className="me-1" />
                        {mode === "en" ? "Add New" : "नयाँ थप्नुहोस्"}
                      </button>
                    </div>

                    <div className="export-btn">
                      <button
                        uk-tooltip="Export Excel"
                        onClick={toExcel}
                        className="me-1 ms-2 border-0"
                      >
                        <RiFileExcel2Fill size="1rem" color="#136438" />
                      </button>
                    </div>
                    <div className="export-btn">
                      <button
                        uk-tooltip="Export Pdf"
                        onClick={toPdf}
                        className="mx-1 border-0"
                      >
                        <BsFileEarmarkPdfFill size="1rem" color="#ff0002" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={resignationList}
                customStyles={customStyles}
                pagination
                paginationRowsPerPageOptions={[10, 20, 50, 100]} // Customizable options
                fixedHeader
                fixedHeaderScrollHeight="370px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderComponent={
                  <>
                    <div className=" w-100">
                      <div className="d-flex uk-flex-middle justify-content-end">
                        <div>
                          <div class="uk-search uk-search-default">
                            <AiOutlineSearch className="search-icon" />
                            <input
                              placeholder={
                                mode === "en" ? "Search" : "खोजी गर्नुहोस्"
                              }
                              ref={searchInput}
                              type="text"
                              className="form-control form-control-sm searchField"
                              onChange={searchHandler}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      <ResignationPopup
        setPopup={setPopup}
        reload={reload}
        popup={popup}
        setReload={setReload}
        rgsnList={rgsnList}
        formValue={formValue}
        setFormValue={setFormValue}
        formError={formError}
        setFormError={setFormError}
        DFlag={DFlag}
        isApproved={isApproved}
        setIsApproved={setIsApproved}
      />

      <ResignationViewPopup
        setViewPopup={setViewPopup}
        viewPopup={viewPopup}
        viewData={viewData}
      />

      {approvePopup.show && (
        <ApprovePop
          handleApproveTrue={handleApproveTrue}
          handleApproveFalse={handleApproveFalse}
          approveGmail={approveGmail}
          setApproveGmail={setApproveGmail}
        />
      )}

      {rejectPopup.show && (
        <RejectPop
          handleRejectTrue={handleRejectTrue}
          handleRejectFalse={handleRejectFalse}
          rejectGmail={rejectGmail}
          setRejectGmail={setRejectGmail}
        />
      )}
    </>
  );
}
