import React, { useContext, useEffect, useState } from "react";
import "./profile.css";
import DataTable from "react-data-table-component";
import AuthContext from "../context/auth-context";
import { Fetchdata } from "../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../loading/spinner";
import UpperbarContext from "../context/upperbar-context";
import StaffContext from "../adminPanel/organization/staffState/StaffContext";
import Toast from "../Toast";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { englishToNepaliNumber } from "nepali-number";
import { generatePdf } from "../hooks/PdfExcel";

function Bank() {
  const [loading, setLoading] = useState(true);
  const [bankList, setBankList] = useState([]);
  const { User } = useContext(AuthContext);
  const { userDetails, customStyles, appURL, mode } =
    useContext(UpperbarContext);
  //

  useEffect(() => {
    banklst();
  }, []);

  const banklst = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      Flag: "S",
      UserID: User.UID,
      Type: "POST",
      FetchURL: `${appURL}api/bank`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setBankList(postResult);
        setLoading(false);
      } else {
        setBankList([]);
        setLoading(false);
      }
    });
  };

  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      grow: 0,
      center: true,
      width: "70px",
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },
    {
      name: mode === "en" ? "Bank" : "बैंक",
      // grow: 0,
      // center: true,
      minWidth: "200px",
      selector: (row) => row.BankName,
    },
    {
      name: mode === "en" ? "Account Name" : "खाताको नाम",
      // center: true,
      grow: 1,
      width: "300px",
      selector: (row) => row.AcName,
    },
    {
      name: mode === "en" ? "Account Number" : "खाता नम्बर",
      // grow: 0,
      // center: true,
      width: "300px",
      selector: (row) =>
        mode === "en" ? row.AcNumber : englishToNepaliNumber(row.AcNumber),
    },

    {
      name: mode === "en" ? "Branch" : "साखा",
      // grow: 0,
      // center: true,
      width: "200px",
      selector: (row) => row.Branch,
    },
  ];

  const dataWithIndex = bankList.map((d, i) => ({
    ...d,
    Index: i + 1,
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Bank", field: "BankName" },
    { header: "Account Name", field: "AcName" },
    { header: "Account Number", field: "AcNumber" },
    { header: "Branch", field: "Branch" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    generatePdf(userDetails, exportColumns, dataWithIndex);

  };

  const dataToExcel = bankList.map((d, i) => ({
    "S.N.": i + 1,
    Bank: d.BankName,
    "Account Name": d.AcName,
    "Account Number": d.AcNumber,
    Branch: d.Branch,
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 25 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Bank");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  return (
    <>
      {/* <Toast /> */}
      {loading ? (
        <>
          <Spinner />
        </>
      ) : (
        <>
          <div className="d-flex uk-flex-wrap uk-flex-middle uk-flex-right mb-3">
            <div className="export-btn">
              <button
                uk-tooltip="Export Excel"
                onClick={toExcel}
                className="me-1 ms-2 border-0"
              >
                <RiFileExcel2Fill size="1rem" color="#136438" />
              </button>
            </div>
            <div className="export-btn">
              <button
                uk-tooltip="Export Pdf"
                onClick={toPdf}
                className="mx-1 border-0"
              >
                <BsFileEarmarkPdfFill size="1rem" color="#ff0002" />
              </button>
            </div>
          </div>
          <DataTable
            columns={columns}
            data={bankList}
            customStyles={customStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="410px"
            highlightOnHover
            progressPending={loading}
            pointerOnHover
            responsive
            dense
            striped
          />
        </>
      )}
    </>
  );
}

export default Bank;
