import React, { useState, useEffect, useContext } from "react";
import { Fetchdata } from "../../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import JobContext from "./JobContext";
import AuthContext from "../../context/auth-context";
import UpperbarContext from "../../context/upperbar-context";
import { GetNepaliDate } from "../../hooks/dateConvertor";

function JobState(props) {
  // const chhimekiURL = process.env.REACT_APP_URL_TWO;
  const { appURL } = useContext(UpperbarContext);

  const { User } = useContext(AuthContext);
  const [popup, setPopup] = useState(false);
  const [applicantPop, setApplicantPop] = useState(false);
  const [editPop, setEditPop] = useState(false);
  const [jobFormError, setJobFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isEditSubmit, setIsEditSubmit] = useState(false);
  const [loading, setLoading] = useState(true);

  const jobvalue = {
    department: "",
    subDepartment: "",
    designation: "",
    shifts: "",
    shiftType: "",
    jobType: "",
    title: "",
    description: "",
    responsibility: "",
    position: "",
    education: "",
    experience: "",
    startDate: "",
    endDate: "",
    salary: "",
    interviewDate: "",
  };

  const [jobFormValue, setJobFormValue] = useState(jobvalue);
  const [image, setImage] = useState("");
  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");

  const [originalList, setOriginalList] = useState(null);
  const [searchList, setSearchList] = useState(null);
  const [negotiable, setNegotiable] = useState(false);

  const [chooseDepartment, setChooseDepartment] = useState("-1");
  const [chooseSubDepartment, setChooseSubDepartment] = useState("-1");
  const [chooseDesignation, setChooseDesignation] = useState("-1");
  const [chooseShift, setChooseShift] = useState("-1");
  const [chooseShiftType, setChooseShiftType] = useState("-1");
  const [chooseJobType, setChooseJobType] = useState("-1");

  const [reload, setReload] = useState(false);
  const [jobStatus, setJobStatus] = useState("");

  //API to hit Department list
  const [departmentList, setDepartmentList] = useState([]);

  useEffect(() => {
    deptList();
  }, []);

  const deptList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      IsActive: "A",
      Type: "POST",
      FetchURL: `${appURL}api/admin/department`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setLoading(false);
        setDepartmentList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setOriginalList([]);
      }
    });
  };

  // API to hit Sub-Department list
  const [subdepartmentList, setSubdepartmentList] = useState([]);

  // useEffect(() => {
  //   subdeptList();
  // }, [jobFormValue.department]);

  // const subdeptList = () => {
  //   const params = {
  //     ComID: User.CompanyId,
  //     StaffID: -1,
  //     DepartID: jobFormValue.department,
  //     Flag: "S",
  //     Type: "POST",
  //     Status: -1,
  //     BranchID: User.BranchId,
  //     FetchURL: `${appURL}api/admin/sub-department`,
  //   };

  //   Fetchdata(params).then(function (result) {
  //     if (result.StatusCode === 200) {
  //       const postResult = result.SubDepList ? result.SubDepList : "";
  //       setLoading(false);
  //       setSubdepartmentList(postResult);
  //       setOriginalList(postResult);
  //     } else {
  //       setLoading(false);
  //       setSubdepartmentList([]);
  //       setOriginalList([]);
  //     }
  //   });
  // };

  //API to hit Designation list
  const [designationList, setDesignationList] = useState([]);

  useEffect(() => {
    desgList();
  }, [jobFormValue.department]);

  const desgList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      DepartID: jobFormValue.department,
      IsActive: "A",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/designation`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setDesignationList(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setLoading(false);
        setDesignationList([]);
        setOriginalList([]);
      }
    });
  };

  //API to hit Shift list
  const [shiftList, setShiftList] = useState([]);

  useEffect(() => {
    shftList();
  }, []);

  const shftList = () => {
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      BranchID: Branch,
      FiscalID: Fiscal,
      Type: "POST",
      FetchURL: `${appURL}api/admin/shift`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setShiftList(postResult);
      } else {
        setShiftList([]);
      }
    });
  };

  //API to hit Job List

  const [jobList, setJobList] = useState([]);

  useEffect(() => {
    getJobList();
  }, [reload]);

  const getJobList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/job`,
    };

    Fetchdata(params).then(function (result) {
    

      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setLoading(false);
        setJobList(postResult);
        setOriginalList(postResult);
      } else {
        setJobList([]);
        setOriginalList([]);
      }
    });
  };

  const [titleId, setTitleID] = useState();

  const handleEdit = (datas) => {
    setEditPop(true);
    setTitleID(datas.JobID);
    easyList();
  };

  //API to hit Job Info

  // const [staffInfoList, setStaffInfoList] = useState([])

  useEffect(() => {
    easyList();
  }, [titleId]);

  const easyList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID,
      Flag: "SI",
      JobID: titleId,
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      FetchURL: `${appURL}api/admin/job`,
      Type: "POST",
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const data = result.Values[0] ? result.Values[0] : "";

        setJobFormValue({
          department: data.DepartID,
          // subDepartment: data.SubDepartID,
          designation: data.DesignationID,
          shifts: data.ShiftID,
          shiftType: data.ShiftTypeID,
          jobType: data.JobTypeID,
          title: data.Title,
          description: data.Description,
          responsibility: data.Responsibility,
          position: data.NoPos,
          education: data.Education,
          experience: data.Experience,
          startDate: GetNepaliDate(data.StartDate),
          endDate: GetNepaliDate(data.EndDate),
          salary: data.Salary,
          interviewDate: GetNepaliDate(data.InterviewDate),
        });
        // setImage(data.Banner)
        setNegotiable(data.IsNegID === "0" ? false : true);
      } else {
      }
    });
  };

  //API to hit Job status

  const changeStatus = (data) => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID,
      Flag: "UJS",
      JobID: data[0],
      JobStatus: data[1],
      FetchURL: `${appURL}api/admin/job`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getJobList();

        // toast.success(result.Message, {
        //   style: {
        //     color: "green",
        //     fontSize: "13px",
        //   },
        //   theme: "light",
        // });
      }
      // else {
      //   toast.error("Error: " + result.Message, {
      //     style: {
      //       color: "red",
      //       fontSize: "13px",
      //     },
      //     theme: "light",
      //   });
      // }
    });
  };

  const [forID, setForID] = useState("");

  const addApplicant = (datas) => {
    setApplicantPop(true);
    setForID(datas);
  };

  // API to hit applicant list

  const [applicantList, setApplicantList] = useState([]);

  // useEffect(() => {
  //   getApplicantList();
  // }, [forID]);

  // const getApplicantList = () => {
  //   const params = {
  //     ComID: User.CompanyId,
  //     StaffID: "-1",
  //     Flag: "S",
  //     JobID: forID,
  //     Type: "POST",
  //     FetchURL: `${appURL}api/admin/job-applicant`,
  //   };
  //   Fetchdata(params).then(function (result) {


  //     if (result.StatusCode === 200) {
  //       const postResult = result.JobApplicantlst ? result.JobApplicantlst : "";
  //       setLoading(false);
  //       setApplicantList(postResult);
  //       setSearchList(postResult);
  //     } else {
  //       setApplicantList([]);
  //       setSearchList([]);
  //     }
  //   });
  // };

  return (
    <JobContext.Provider
      value={{
        jobFormValue,
        setJobFormValue,
        jobvalue,
        popup,
        setPopup,
        jobFormError,
        setJobFormError,
        isSubmit,
        setIsSubmit,
        loading,
        setLoading,
        applicantPop,
        setApplicantPop,
        applicantList,
        image,
        setImage,
        isUploaded,
        setIsUploaded,
        typeFile,
        setTypeFile,
        negotiable,
        setNegotiable,
        editPop,
        setEditPop,
        isEditSubmit,
        setIsEditSubmit,
        originalList,
        setOriginalList,
        departmentList,
        subdepartmentList,
        designationList,
        shiftList,
        jobList,
        getJobList,
        handleEdit,
        titleId,
        changeStatus,
        setJobList,
        setApplicantList,
        searchList,
        chooseDepartment,
        setChooseDepartment,
        chooseSubDepartment,
        setChooseSubDepartment,
        chooseDesignation,
        setChooseDesignation,
        chooseShift,
        setChooseShift,
        chooseShiftType,
        setChooseShiftType,
        chooseJobType,
        setChooseJobType,
        addApplicant,
        jobStatus,
        setJobStatus,
        reload,
        setReload,
      }}
    >
      {props.children}
    </JobContext.Provider>
  );
}
export default JobState;
