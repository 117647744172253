import React from "react";
import CloseIcon from "../../../../../images/CloseIcon.svg";
import { useState } from "react";

const EditDocument = ({
  handleChange,
  formValues,
  formErrors,
  typeFile,
  setTypeFile,
  isUploaded,
  setIsUploaded,
  files,
  setFile,
  mode,
}) => {
  function handlePdfChange(e) {
    if (e.target.files && e.target.files[0]) {
      setTypeFile(e.target.files[0].type);
      setFile(e.target.files[0].name);
      let reader = new FileReader();

      reader.onload = function (e) {
        setTypeFile(e.target.result);
        setIsUploaded(true);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  return (
    <>
      <div className="row text-start ">
        <div className="form-group">
          {!isUploaded ? (
            <>
              <div className="pdfFile w-100">
                <label htmlFor="pdfData">
                  <div
                    // type="button"
                    class="btn btn-sm"
                    style={{
                      background: "var(--button-color)",
                      color: "white",
                    }}
                  >
                    {mode === "en" ? "Add Pdf" : "Pdf थप्नुहोस्"}
                  </div>
                </label>

                <input
                  id="pdfData"
                  type="file"
                  accept=".pdf"
                  onChange={handlePdfChange}
                  name="pdf"
                  className="d-none"
                  value={formValues.pdf}
                />
              </div>
              {/* {formErrors.name && (
      <p className="errormsg">{formErrors.name}</p>
    )} */}
            </>
          ) : (
            <>
              <div className="w-100 ">
                <div className="uk-position-relative">
                  <input
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    value={files}
                    name="ext"
                    disabled
                  />
                  <img
                    className="close-icon close-pdf"
                    src={CloseIcon}
                    alt="CloseIcon"
                    onClick={() => {
                      setIsUploaded(false);
                      setTypeFile(null);
                      setFile("");
                      formValues.ext = "";
                    }}
                    style={{
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                      height: " 15px",
                    }}
                  />
                </div>
                {/* {formErrors.name && (
      <p className="errormsg">{formErrors.name}</p>
    )} */}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default EditDocument;
