import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import AuthContext from "../../../context/auth-context";
import UpperbarContext from "../../../context/upperbar-context";
import Toast from "../../../Toast";
import { GoPlus } from "react-icons/go";
import {
  AiOutlineSearch,
  AiOutlineEdit,
  AiTwotonePrinter,
} from "react-icons/ai";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import AgentPopup from "./AgentPopup";
import AgentContext from "./agentState/AgentContext";
import EditAgentPopup from "./EditAgentPopup";
import Spinner from "../../../loading/spinner";
import { englishToNepaliNumber } from "nepali-number";

export default function Agent() {
  const { User } = useContext(AuthContext);

  const {
    loading,
    setLoading,
    originalList,
    setOriginalList,
    popup,
    setPopup,
    submit,
    setSubmit,
    perEditSubmit,
    setPerEditSubmit,
    initalvalue,
    formValues,
    setFormValues,
    formErrors,
    setFormErrors,
    agentList,
    setAgentList,
    editPopup,
    setEditPopup,
    handleEdit,
    editData,
    deactivateData,
    agentLst,
    isUploaded,
    setIsUploaded,
    typeFile,
    setTypeFile,
    files,
    setFile,
    checkedVat,
    setCheckedVat,
    checkedActive,
    setCheckedActive,
    agentUnderList,
    rateInput,
    agntunderLst,
    setRateInput,
  } = useContext(AgentContext);
  //   const searchInput = useRef("");
  const {
    fiscalYear,
    todayDate,
    mode,
    customStyles,
    appURL,
    userDetails,
    darkText,
  } = useContext(UpperbarContext);

  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      grow: 0,
      center: true,
      width: "70px",
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },
    {
      name: mode === "en" ? "Name" : "नाम",
      // center: true,
      // grow: 0,
      // width: "200px",

      selector: (row) => row.Agent,
    },
    // {
    //   name: "Alias",
    //   // center: true,
    //   // grow: 0,

    //   selector: (row) => row.Alias,
    // },
    {
      name: mode === "en" ? "Under" : "अन्तर्गत",
      // center: true,
      // width: "200px",
      // grow: 0,
      selector: (row) => row.Refer,
    },

    {
      name: mode === "en" ? "PAN" : "प्यान",
      // grow: 0,
      // center: true,
      width: "150px",
      selector: (row) =>
        mode === "en" ? row.PAN : englishToNepaliNumber(row.PAN),
    },

    {
      name: mode === "en" ? "Status" : "स्थिति",
      // grow: 0,
      center: true,
      width: "140px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                // class="btn btn-sm actvspan"
                className="border-0 bg-transparent"
                onClick={() => changeStatus(row.AgentID, row.IsActive)}
              >
                <span
                  className=" badge rounded-pill bg-success"
                  style={{ fontSize: "11px" }}
                >
                  {checkStatus(row.IsActive)}
                </span>
              </button>
            </div>
          </>
        );
      },
    },
    {
      name: mode === "en" ? "Action" : "कार्य",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                class="btn btn-sm editspan"
                onClick={() => handleEdit(row)}
                uk-tooltip={mode === "en" ? "Edit" : "सम्पादन गर्नुहोस्"}
              >
                <AiOutlineEdit />
              </button>{" "}
            </div>
          </>
        );
      },
    },
  ];

  const checkStatus = (IsActive) => {
    if (IsActive === "A") {
      return mode === "en" ? "Deactivate" : "निष्क्रिय";
    } else if (IsActive === "I") {
      return mode === "en" ? "Activate" : "सक्रिय";
    }
  };

  const changeStatus = (ID, IsActive) => {
    deactivateData(ID, IsActive);
  };

  const handleAdd = () => {
    setPopup(true);
    setFormValues(initalvalue);
    setCheckedActive(false);
    setCheckedVat(false);
    setIsUploaded(false);
    setRateInput(false);
    agntunderLst();
  };

  const searchInput = useRef("");

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Agent"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setAgentList(srchResult);
      } else {
        setAgentList({});
      }
    } else {
      setAgentList(originalList);
    }
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper ps-4 pe-4">
        <div className="row ">
          <div className="page-header">
            <div className="text-start  page-title" style={darkText}>
              {mode === "en" ? "Agent" : "एजेन्ट"}
            </div>
            <div className="page-date">
              <div className="sec-content" style={darkText}>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>

        <>
          <div className="sec-dataTable">
            <div className="upper-dataTbl pb-3">
              <div className="">
                <div className="d-flex uk-flex-middle uk-flex-right">
                  <div className="btn-addlnote mt-3">
                    <button
                      type="button"
                      class="btn btn-sm"
                      style={{
                        background: "var(--button-color)",
                        color: "white",
                      }}
                      onClick={handleAdd}
                    >
                      <GoPlus color="#fff" className="me-1" />
                      {mode === "en" ? "Add Agent" : "एजेन्ट थप्नुहोस्"}
                    </button>
                  </div>
                  <div className="export-btn">
                    <button
                      uk-tooltip="Export Excel"
                      // onClick={toExcel}
                      className="me-1 ms-2 border-0"
                    >
                      <RiFileExcel2Fill size="1rem" color="#136438" />
                    </button>
                  </div>
                  <div className="export-btn">
                    <button
                      uk-tooltip="Export Pdf"
                      // onClick={toPdf}
                      className="mx-1 border-0"
                    >
                      <BsFileEarmarkPdfFill size="1rem" color="#ff0002" />
                    </button>
                  </div>
                  <div className="export-btn">
                    <button
                      uk-tooltip="Print"
                      // onClick={toPrint}
                      className="mx-1 border-0"
                    >
                      <AiTwotonePrinter size="1rem" color="#555" />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={agentList}
                customStyles={customStyles}
                pagination
                paginationRowsPerPageOptions={[10, 20, 50, 100]} // Customizable options
                fixedHeader
                fixedHeaderScrollHeight="350px"
                highlightOnHover
                pointerOnHover
                responsive
                // progressPending={loading}
                dense
                striped
                subHeader
                subHeaderComponent={
                  <>
                    <div className=" w-100 mb-3 mt-3">
                      <div className="d-flex uk-flex-middle justify-content-end">
                        <div>
                          <form class="uk-search uk-search-default">
                            <AiOutlineSearch className="search-icon" />
                            <input
                              placeholder={
                                mode === "en" ? "Search" : "खोजी गर्नुहोस्"
                              }
                              ref={searchInput}
                              type="text"
                              className="form-control form-control-sm searchField"
                              onChange={searchHandler}
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      <AgentPopup
        popup={popup}
        setPopup={setPopup}
        submit={submit}
        setSubmit={setSubmit}
        formValues={formValues}
        setFormValues={setFormValues}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        agentLst={agentLst}
        checkedVat={checkedVat}
        setCheckedVat={setCheckedVat}
        checkedActive={checkedActive}
        setCheckedActive={setCheckedActive}
        isUploaded={isUploaded}
        setIsUploaded={setIsUploaded}
        typeFile={typeFile}
        setTypeFile={setTypeFile}
        files={files}
        setFile={setFile}
        agentUnderList={agentUnderList}
        rateInput={rateInput}
      />

      <EditAgentPopup
        formValues={formValues}
        setFormValues={setFormValues}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        editPopup={editPopup}
        setEditPopup={setEditPopup}
        perEditSubmit={perEditSubmit}
        setPerEditSubmit={setPerEditSubmit}
        editData={editData}
        checkedVat={checkedVat}
        setCheckedVat={setCheckedVat}
        checkedActive={checkedActive}
        setCheckedActive={setCheckedActive}
        isUploaded={isUploaded}
        setIsUploaded={setIsUploaded}
        typeFile={typeFile}
        setTypeFile={setTypeFile}
        files={files}
        setFile={setFile}
        agentUnderList={agentUnderList}
        rateInput={rateInput}
      />
    </>
  );
}
