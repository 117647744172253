import { useContext, useState, useEffect } from "react";
import AuthContext from "../context/auth-context";
import { useNavigate } from "react-router-dom";
import UpperbarContext from "../context/upperbar-context";
import "./logoutPopup.css";
import CloseIcon from "../../images/CloseIcon.svg";
import $ from "jquery";

export const ShowLogoutPopup = () => {
  const { logout } = useContext(AuthContext);
  let navigate = useNavigate();
  const { sidebarLogout, setSidebarLogout, mode } = useContext(UpperbarContext);

  const logoutHandler = (e) => {
    localStorage.clear();
    sessionStorage.clear();
    logout();
    setSidebarLogout(false);
    navigate("/login");
  };

  useEffect(() => {
    if (sidebarLogout) {
      $(".addSideLogoutPopBg").fadeIn(500);
      $(".addSideLogoutPop").slideDown(500);
    }
  }, [sidebarLogout]);

  const closePop = () => {
    setSidebarLogout(false);
    $(".addSideLogoutPopBg").fadeOut(500);
    $(".addSideLogoutPop").slideUp(500);
  };

  return (
    <>
      <div className="container-fluid p-0 logoutPopup-wrapper addSideLogoutPopBg">
        <div className="logoutpopup-inner addSideLogoutPop">
          <div className="row">
            <div className="col-md-12 col-lg-12 col-sm-12">
              <div className="popUpHeader ">
                <div className="popUpTitle">Elite Office</div>
                <div className="popUpClose">
                  <img
                    className="popUpCloseIcon"
                    src={CloseIcon}
                    alt="CloseIcon"
                    onClick={closePop}
                  />
                </div>
              </div>

              <div className="logoutpopup-body text-start ps-4">
                {mode === "en"
                  ? "Do you want to Logout ?"
                  : "के तपाई लगआउट गर्न चाहनुहुन्छ?"}
              </div>
              <div className="logoutpopup-footer mt-4">
                <button
                  type="button"
                  class="btn btn-sm ps-4 pe-4 me-2 ms-3"
                  style={{
                    backgroundColor: "var(--button-color)",
                    color: "white",
                  }}
                  onClick={logoutHandler}
                >
                  {mode === "en" ? "Logout" : "लगआउट"}
                </button>
                <button
                  type="button"
                  class="btn btn-light btn-sm ps-4 pe-4 me-3"
                  onClick={closePop}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
