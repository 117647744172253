import React, { useState, useEffect, useContext } from "react";
import DeductionContext from "./DeductionContext";
import { Fetchdata } from "../../../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import AuthContext from "../../../context/auth-context";
import UpperbarContext from "../../../context/upperbar-context";
import $ from "jquery";

function DeductionState(props) {
  const { User } = useContext(AuthContext);
  const { appURL } = useContext(UpperbarContext);
  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const [popup, setPopup] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [perEditSubmit, setPerEditSubmit] = useState(false);

  const [checkedTaxable, setCheckedTaxable] = useState(false);
  const [addNew, setAddNew] = useState(false);

  const initalvalue = {
    allowance: "",
    acHead: "",
    ledgerName: "",
    acGrp: "",
  };
  const [formValues, setFormValues] = useState(initalvalue);
  const [formErrors, setFormErrors] = useState({});

  //  API to show allowance list
  const [deductionList, setDeductionList] = useState([]);

  useEffect(() => {
    deductionLst();
  }, [submit, perEditSubmit]);

  const deductionLst = () => {
    const UserID = "" + User.UID;
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      CID: "1",
      Flag: "S",
      DFlag: "R",
      BranchID: Branch,
      FiscalID: Fiscal,
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/payroll-head`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setDeductionList(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setDeductionList([]);
        setLoading(false);
      }
    });
  };

  //  API to edit main group
  const [perID, setPerId] = useState();
  const [editPopup, setEditPopup] = useState(false);
  const handleEdit = (data) => {
    setPerId(data.HeadID);
    setEditPopup(true);
    $(".side__panel").addClass("low");
    $(".nav").addClass("high");
  };

  const editData = () => {
    const UserID = "" + User.UID;
    const id = "" + perID;
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;
    const ledgerid = "" + formValues.acHead;
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      CID: "1",
      Flag: "U",
      DFlag: "R",
      HeadID: id,
      Head: formValues.allowance !== " " ? formValues.allowance : " ",
      LedgerID: ledgerid !== " " ? ledgerid : " ",
      BranchID: Branch,
      FiscalID: Fiscal,
      FetchURL: `${appURL}api/acc/admin/payroll-head`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (resp) {
      if (resp.StatusCode === 200) {
        setEditPopup(false);
        $(".editDeductionPopBg").fadeOut(300);
        $(".editDeductionPop").slideUp(500);
        $(".side__panel").removeClass("low");
        $(".nav").removeClass("high");
        deductionLst();
        toast.success(resp.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + resp.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  // API to get main group info
  useEffect(() => {
    infoList();
  }, [perID]);

  const infoList = () => {
    const UserID = "" + User.UID;
    const id = "" + perID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      CID: "1",
      Flag: "SI",
      DFlag: "R",
      HeadID: id,
      FetchURL: `${appURL}api/acc/admin/payroll-head`,
      Type: "POST",
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const data = result.Values[0] ? result.Values[0] : "";
        setFormValues({
          allowance: data.Head,
          acHead: data.LedgerID,
        });
      } else {
      }
    });
  };

  // API to hit main group status
  const stat = [];
  const [newStatus, setNewStatus] = useState(stat);

  const deactivateData = (ID, IsActive) => {
    const UserID = "" + User.UID;
    const id = "" + ID;

    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      CID: "1",
      Flag: "AI",
      DFlag: "R",
      HeadID: id,
      IsActive: IsActive,
      FetchURL: `${appURL}api/acc/admin/payroll-head`,
      Type: "POST",
    };
    if (IsActive === "A") {
      dataForm.IsActive = "I";
    } else {
      dataForm.IsActive = "A";
    }
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        deductionLst();
        let NewstatsN = JSON.parse(JSON.stringify(newStatus));
        let pitchNewStatus;

        if (dataForm.IsActive === "A") {
          pitchNewStatus = "Activated";
        } else if (dataForm.IsActive === "I") {
          pitchNewStatus = "Deactivated";
        }
        setNewStatus(NewstatsN);
        toast.success(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  return (
    <DeductionContext.Provider
      value={{
        loading,
        setLoading,
        originalList,
        setOriginalList,
        popup,
        setPopup,
        submit,
        setSubmit,
        perEditSubmit,
        setPerEditSubmit,
        initalvalue,
        formValues,
        setFormValues,
        formErrors,
        setFormErrors,
        deductionList,
        setDeductionList,
        editPopup,
        setEditPopup,
        handleEdit,
        editData,
        deactivateData,
        deductionLst,
        checkedTaxable,
        setCheckedTaxable,
        addNew, setAddNew
      }}
    >
      {props.children}
    </DeductionContext.Provider>
  );
}

export default DeductionState;
