import React, { useContext, useEffect, useState } from "react";
// import "./leaveNotePopup.css";
import CloseIcon from "../../../../images/CloseIcon.svg";
import { Fetchdata } from "../../../hooks/getData";
import AuthContext from "../../../context/auth-context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import UpperbarContext from "../../../context/upperbar-context";
import Toast from "../../../Toast";
import $ from "jquery";
import Select from "react-select";

export default function EditCompanyPopup({
  editPopup,
  setEditPopup,
  perEditSubmit,
  setPerEditSubmit,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
  editData,
  millionFormat,
  setMillionFormat,
  allowSpace,
  setAllowSpace,
  DFlag,
  countryList,
  stateList,
  re,
}) {
  const { appURL, sidePanelBg, mode } = useContext(UpperbarContext);
  const { User } = useContext(AuthContext);

  const handleCountry = (e) => {
    let name = "country";
    setFormValues({ ...formValues, [name]: e.CtryCode });
  };

  const handleState = (e) => {
    let name = "state";
    setFormValues({ ...formValues, [name]: e.StID });
  };

  const closePopUp = (e) => {
    setEditPopup(false);
    $(".editCompanyPopBg").fadeOut(300);
    $(".editCompanyPop").slideUp(500);
    setPerEditSubmit(false);
    setFormErrors({});
  };

  useEffect(() => {
    if (editPopup) {
      $(".editCompanyPopBg").fadeIn(500);
      $(".editCompanyPop").slideDown(500);
    }
  }, [editPopup]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCompanyDate = ({ bsDate }) => {
    let name = "companyRegistration";
    setFormValues({ ...formValues, [name]: bsDate });
  };

  const handleFiscalDate = ({ bsDate }) => {
    let name = "fiscal";
    setFormValues({ ...formValues, [name]: bsDate });
  };

  const handleBookDate = ({ bsDate }) => {
    let name = "bookStart";
    setFormValues({ ...formValues, [name]: bsDate });
  };

  const validate = (values) => {
    const errors = {};
    const numv = /^[0-9]+$/i;
    const digits = /^\d{10}$/;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.companyName) {
      errors.companyName = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.country) {
      errors.country = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.state) {
      errors.state = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.address) {
      errors.address = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.contact) {
      errors.contact = mode === "en" ? "Required" : "आवश्यक छ";
    } else if (!numv.test(values.contact)) {
      errors.contact = mode === "en" ? "Must be digits" : "अंक हुनुपर्छ";
    } else if (!digits.test(values.contact)) {
      errors.contact = mode === "en" ? "Must be 10 digits" : "दस अंक हुनुपर्छ";
    }
    if (!values.pan) {
      errors.pan = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.companyRegistration) {
      errors.companyRegistration = mode === "en" ? "Required" : "आवश्यक छ";
    }

    if (!values.decimal) {
      errors.decimal = mode === "en" ? "Required" : "आवश्यक छ";
    } else if (!numv.test(values.decimal)) {
      errors.decimal = mode === "en" ? "Must be digits" : "अंक हुनुपर्छ";
    }
    if (!values.word) {
      errors.word = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.symbol) {
      errors.symbol = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.currency) {
      errors.currency = mode === "en" ? "Required" : "आवश्यक छ";
    }

    if (!values.user) {
      errors.user = mode === "en" ? "Required" : "आवश्यक छ";
    } else if (!numv.test(values.user)) {
      errors.user = mode === "en" ? "Must be digits" : "अंक हुनुपर्छ";
    }
    if (!values.feature) {
      errors.feature = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.password) {
      errors.password = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (values.email.length === 0) {
      setPerEditSubmit(true);
    } else if (!regex.test(values.email)) {
      errors.email =
        mode === "en"
          ? "This is not a valid email format"
          : "यो मान्य इमेल ढाँचा होइन";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setPerEditSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && perEditSubmit) {
      editData();
      setPerEditSubmit(false);
    }
  }, [formErrors]);

  return (
    <>
      {/* <Toast /> */}
      <div className="container popup-wrapper editCompanyPopBg">
        <div className="popup-inner editCompanyPop">
          <div className="popUpHeader ps-0 pe-0" style={sidePanelBg}>
            <div className="popUpTitle">
              {mode === "en" ? "Edit Company" : "कम्पनी सम्पादन गर्नुहोस्"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div className="popUpBody ps-3 pe-3">
          <div className="form-padding">
              <h5
                className="m-0 mt-2 fw-bold text-start"
                style={{ transform: "translateX(-10px)" }}
              >
                {mode === "en" ? "Company Information" : "कम्पनी जानकारी"}
              </h5>
              <div className="row text-start ">
                <div className="form-group mt-2">
                  <label htmlFor="company" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Company Name" : "कम्पनीको नाम"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="company"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="companyName"
                    placeholder={
                      mode === "en" ? "Company Name" : "कम्पनीको नाम"
                    }
                    value={formValues.companyName}
                    onChange={handleChange}
                  />
                  {formErrors.companyName && (
                    <p className="errormsg">{formErrors.companyName}</p>
                  )}
                </div>
              </div>

              <div className="row text-start">
                <div className="col-md-6 col-sm-6 col-lg-6  form-group">
                  <label htmlFor="country" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Select Country" : "देश चयन गर्नुहोस्"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <Select
                    onChange={handleCountry}
                    className="form-control form-control-sm "
                    id="country"
                    name="country"
                    getOptionLabel={(option) => {
                      return option.CtryName;
                    }}
                    getOptionValue={(option) => {
                      return option.CtryCode;
                    }}
                    options={countryList}
                    key={re}
                    placeholder={
                      mode === "en" ? "Select Country" : "देश चयन गर्नुहोस्"
                    }
                  />
                  {formErrors.country && (
                    <p className="errormsg">{formErrors.country}</p>
                  )}
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6  form-group">
                  <label htmlFor="state" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Select State" : "राज्य चयन गर्नुहोस्"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <Select
                    onChange={(e, i) => handleState(e, i)}
                    className="form-control form-control-sm "
                    id="state"
                    name="state"
                    getOptionLabel={(option) => {
                      return option.CtryName;
                    }}
                    getOptionValue={(option) => {
                      return option.CtryID;
                    }}
                    options={stateList}
                    key={re}
                    placeholder={
                      mode === "en" ? "Select State" : "राज्य चयन गर्नुहोस्"
                    }
                  />
                  {formErrors.state && (
                    <p className="errormsg">{formErrors.state}</p>
                  )}
                </div>
              </div>

              <div className="row text-start ">
                <div className="col-md-8 col-sm-8 col-lg-8  form-group">
                  <label htmlFor="address" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Address" : "ठेगाना"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="address"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="address"
                    placeholder={mode === "en" ? "Address" : "ठेगाना"}
                    value={formValues.address}
                    onChange={handleChange}
                  />
                  {formErrors.address && (
                    <p className="errormsg">{formErrors.address}</p>
                  )}
                </div>
                <div className="col-md-4 col-sm-4 col-lg-4 form-group">
                  <label htmlFor="postalCode" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Postal Code" : "हुलाक कोड"}
                  </label>
                  <input
                    id="postalCode"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="postalCode"
                    placeholder={mode === "en" ? "Postal Code" : "हुलाक कोड"}
                    value={formValues.postalCode}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row text-start">
                <div className="col-md-6 col-sm-6 col-lg-6 form-group">
                  <label htmlFor="contact" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Contact Number" : "सम्पर्क नम्बर"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="contact"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="contact"
                    placeholder={
                      mode === "en" ? "Contact Number" : "सम्पर्क नम्बर"
                    }
                    value={formValues.contact}
                    onChange={handleChange}
                  />
                  {formErrors.contact && (
                    <p className="errormsg">{formErrors.contact}</p>
                  )}
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 form-group">
                  <label htmlFor="telephone" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Telephone" : "टेलिफोन"}
                  </label>
                  <input
                    id="telephone"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="telephone"
                    placeholder={mode === "en" ? "Telephone" : "टेलिफोन"}
                    value={formValues.telephone}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row text-start ">
                <div className="col-md-6 col-sm-6 col-lg-6 form-group">
                  <label htmlFor="pan" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "PAN" : "प्यान"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="pan"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="pan"
                    placeholder={mode === "en" ? "PAN" : "प्यान"}
                    value={formValues.pan}
                    onChange={handleChange}
                  />
                  {formErrors.pan && (
                    <p className="errormsg">{formErrors.pan}</p>
                  )}
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 form-group">
                  <label htmlFor="email" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Email" : "इमेल"}
                  </label>
                  <input
                    id="email"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="email"
                    placeholder={mode === "en" ? "Email" : "इमेल"}
                    value={formValues.email}
                    onChange={handleChange}
                  />
                  {formErrors.email && (
                    <p className="errormsg">{formErrors.email}</p>
                  )}
                </div>
              </div>

              <div className="row text-start ">
                <div className="form-group">
                  <label htmlFor="website" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Website" : "वेबसाइट"}
                  </label>
                  <input
                    id="website"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="website"
                    placeholder={mode === "en" ? "Website" : "वेबसाइट"}
                    value={formValues.website}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row text-start">
                <div className="col-md-4 col-sm-4 col-lg-4 form-group">
                  <label
                    htmlFor="companyRegistration"
                    style={{ fontSize: "12px" }}
                  >
                    {mode === "en" ? "Company Registration" : "कम्पनी दर्ता"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  {DFlag === "N" ? (
                    <Calendar
                      id="companyRegistration"
                      className="form-control form-control-sm pt-0 pb-0 from-date"
                      dateFormat="YYYY/MM/DD"
                      defaultDate="2010/01/01"
                      theme="default"
                      language="en"
                      value={formValues.companyRegistration}
                      onChange={handleCompanyDate}
                      name="companyRegistration"
                      key={re}
                      hideDefaultValue={true}
                      placeholder={
                        mode === "en" ? "Select Date" : "मिति चयन गर्नुहोस्"
                      }
                    />
                  ) : (
                    <input
                      type="date"
                      id="companyRegistration"
                      value={formValues.companyRegistration}
                      name="companyRegistration"
                      placeholder="Select a Date"
                      className="form-control form-control-sm "
                      onChange={handleChange}
                    />
                  )}
                  {formErrors.companyRegistration && (
                    <p className="errormsg">{formErrors.companyRegistration}</p>
                  )}
                </div>

                <div className="col-md-4 col-sm-4 col-lg-4 form-group">
                  <label htmlFor="fiscal" style={{ fontSize: "12px" }}>
                    {mode === "en"
                      ? "Fiscal Year Start"
                      : "आर्थिक वर्षको सुरुवात"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  {DFlag === "N" ? (
                    <Calendar
                      id="fiscal"
                      className="form-control form-control-sm pt-0 pb-0 from-date"
                      dateFormat="YYYY/MM/DD"
                      defaultDate="2010/01/01"
                      theme="default"
                      language="en"
                      value={formValues.fiscal}
                      onChange={handleFiscalDate}
                      name="fiscal"
                      key={re}
                      // hideDefaultValue={true}
                      // placeholder={"Select From Date"}
                    />
                  ) : (
                    <input
                      id="fiscal"
                      type="date"
                      value={formValues.fiscal}
                      name="fiscal"
                      placeholder="Select a Date"
                      className="form-control form-control-sm "
                      onChange={handleChange}
                    />
                  )}
                  {/* {formErrors.fiscal && (
                    <p className="errormsg">{formErrors.fiscal}</p>
                  )} */}
                </div>

                <div className="col-md-4 col-sm-4 col-lg-4 form-group">
                  <label htmlFor="bookStart" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Book Start" : "बुक स्टार्ट"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  {DFlag === "N" ? (
                    <Calendar
                      id="bookStart"
                      className="form-control form-control-sm pt-0 pb-0 from-date"
                      dateFormat="YYYY/MM/DD"
                      defaultDate="2010/01/01"
                      theme="default"
                      language="en"
                      value={formValues.bookStart}
                      onChange={handleBookDate}
                      name="bookStart"
                      key={re}
                      // hideDefaultValue={true}
                      // placeholder={"Select From Date"}
                    />
                  ) : (
                    <input
                      id="bookStart"
                      type="date"
                      value={formValues.bookStart}
                      name="bookStart"
                      placeholder="Select a Date"
                      className="form-control form-control-sm "
                      onChange={handleChange}
                    />
                  )}
                  {/* {formErrors.bookStart && (
                    <p className="errormsg">{formErrors.bookStart}</p>
                  )} */}
                </div>
              </div>

              <h5
                className="m-0 mt-3 fw-bold text-start"
                style={{ transform: "translateX(-10px)" }}
              >
                {mode === "en" ? "Currency Setting" : "मुद्रा सेटिङ"}
              </h5>

              <div className="row text-start ">
                <div className="col-md-4 col-sm-4 col-lg-4 form-group">
                  <label htmlFor="decimal" style={{ fontSize: "12px" }}>
                    {mode === "en"
                      ? "No. of Decimal Place"
                      : "दशमलव स्थानको संख्या"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="decimal"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="decimal"
                    placeholder={
                      mode === "en"
                        ? "No. of Decimal Place"
                        : "दशमलव स्थानको संख्या"
                    }
                    value={formValues.decimal}
                    onChange={handleChange}
                  />
                  {formErrors.decimal && (
                    <p className="errormsg">{formErrors.decimal}</p>
                  )}
                </div>
                <div className="col-md-8 col-sm-8 col-lg-8 form-group">
                  <label htmlFor="word" style={{ fontSize: "12px" }}>
                    {mode === "en"
                      ? "Word Representing Amount After Decimal"
                      : "दशमलव पछि रकम प्रतिनिधित्व गर्ने शब्द"}

                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="word"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="word"
                    placeholder={
                      mode === "en"
                        ? "Word Representing Amount After Decimal"
                        : "दशमलव पछि रकम प्रतिनिधित्व गर्ने शब्द"
                    }
                    value={formValues.word}
                    onChange={handleChange}
                  />
                  {formErrors.word && (
                    <p className="errormsg">{formErrors.word}</p>
                  )}
                </div>
              </div>

              <div className="row text-start">
                <div className="col-md-6 col-sm-6 col-lg-6 form-group">
                  <label htmlFor="symbol" style={{ fontSize: "12px" }}>
                    {mode === "en"
                      ? "Base Currency Symbol"
                      : "आधार मुद्रा प्रतीक"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="symbol"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="symbol"
                    placeholder={
                      mode === "en"
                        ? "Base Currency Symbol"
                        : "आधार मुद्रा प्रतीक"
                    }
                    value={formValues.symbol}
                    onChange={handleChange}
                  />
                  {formErrors.symbol && (
                    <p className="errormsg">{formErrors.symbol}</p>
                  )}
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 form-group">
                  <label htmlFor="currency" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Base Currency" : "आधार मुद्रा"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="currency"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="currency"
                    placeholder={
                      mode === "en" ? "Base Currency" : "आधार मुद्रा"
                    }
                    value={formValues.currency}
                    onChange={handleChange}
                  />
                  {formErrors.currency && (
                    <p className="errormsg">{formErrors.currency}</p>
                  )}
                </div>
              </div>

              <div className="row text-start uk-flex uk-flex-wrap">
                <div
                  className="form-group me-3"
                  style={{ width: "max-content" }}
                >
                  <div class="form-check" style={{ fontSize: "12px" }}>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="millionFormat"
                      name="millionFormat"
                      checked={millionFormat}
                      onChange={() => setMillionFormat(!millionFormat)}
                    />
                    <label
                      style={{ fontSize: "12px", cursor: "pointer" }}
                      class="form-check-label"
                      htmlFor="millionFormat"
                    >
                      {mode === "en" ? "Million Format" : "मिलियन ढाँचा"}
                    </label>
                  </div>
                </div>

                <div className="form-group" style={{ width: "max-content" }}>
                  <div class="form-check" style={{ fontSize: "12px" }}>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="allowSpace"
                      name="allowSpace"
                      checked={allowSpace}
                      onChange={() => setAllowSpace(!allowSpace)}
                    />
                    <label
                      style={{ fontSize: "12px", cursor: "pointer" }}
                      class="form-check-label"
                      htmlFor="allowSpace"
                    >
                      {mode === "en"
                        ? "Allow Space Between Amount & Currency"
                        : "रकम र मुद्रा बीच स्पेस अनुमति दिनुहोस्"}
                    </label>
                  </div>
                </div>
              </div>

              <h5
                className="m-0 mt-2 fw-bold text-start"
                style={{ transform: "translateX(-10px)" }}
              >
                {mode === "en"
                  ? "Access & Security Information"
                  : "पहुँच र सुरक्षा जानकारी"}
              </h5>

              <div className="row text-start">
                <div className="col-md-4 col-sm-4 col-lg-4 form-group">
                  <label htmlFor="user" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "No. of user" : "प्रयोगकर्ताको संख्या"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="user"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="user"
                    placeholder={
                      mode === "en" ? "No. of user" : "प्रयोगकर्ताको संख्या"
                    }
                    value={formValues.user}
                    onChange={handleChange}
                  />
                  {formErrors.user && (
                    <p className="errormsg">{formErrors.user}</p>
                  )}
                </div>
                <div className="col-md-8 col-sm-8 col-lg-8 form-group">
                  <label htmlFor="feature" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Select Feature" : "सुविधा चयन गर्नुहोस्"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    name="feature"
                    id="feature"
                    value={formValues.feature}
                    onChange={handleChange}
                  >
                    <option disabled value="" selected>
                      {mode === "en"
                        ? "Select Feature"
                        : "सुविधा चयन गर्नुहोस्"}
                    </option>
                    <option value="S">Service Only</option>
                    <option value="I">Inventory</option>
                    <option value="IP">Inventory with Product</option>
                  </select>
                  {formErrors.feature && (
                    <p className="errormsg">{formErrors.feature}</p>
                  )}
                </div>
              </div>

              <div className="row text-start ">
                <div className="form-group">
                  <label htmlFor="password" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Password" : "पासवर्ड"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="password"
                    style={{ fontSize: "13px" }}
                    type="password"
                    className="form-control form-control-sm "
                    name="password"
                    placeholder={mode === "en" ? "Password" : "पासवर्ड"}
                    value={formValues.password}
                    onChange={handleChange}
                  />
                  {formErrors.password && (
                    <p className="errormsg">{formErrors.password}</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="ln-popUpFooter">
            <div className="row  mt-1 mb-1">
              <div>
                <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={handleSubmit}
                >
                  {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
