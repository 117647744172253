import React, { useContext, useEffect, useRef, useState } from "react";
import CloseIcon from "../images/CloseIcon.svg";
import "react-toastify/dist/ReactToastify.css";
import "./layout/layout.css";
import Toast from "./Toast";
import UpperbarContext from "./context/upperbar-context";
import { AiTwotonePrinter } from "react-icons/ai";
import { useReactToPrint } from "react-to-print";
import $ from "jquery";

export default function PrintPopup({ data, column, printData }) {
  const { printPopup, setPrintPopup, userDetails } =
    useContext(UpperbarContext);
  const closePopUp = () => {
    setPrintPopup(false);
    $(".printPop").slideUp(500);
    $(".printPopBg").fadeOut(500);
  };

  useEffect(() => {
    if (printPopup) {
      $(".printPop").slideDown(500);
      $(".printPopBg").fadeIn(500);
    }
  }, [printPopup]);

  // const pageStyle = `
  // @media print {
  //   .printBody .printTable {
  //     margin-top: 9rem;
  //   }
  // }
  // `;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "printBody App",
    documentTitle: "print",
    // pageStyle: { pageStyle },
  });

  return (
    <>
      {/* <Toast /> */}
      <div className="container printPop-wrapper printPopBg">
        <div className="printPop-inner printPop">
          <div className="popUpHeader ps-0 pe-0">
            <div className="popUpTitle">Print</div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div className="chgpassBody printBody ps-3 pe-3">
            <table
              ref={componentRef}
              className="uk-table uk-table-striped printTable"
            >
              <thead className="sticky-head">
                <div className="printHeader">
                  <img
                    src={userDetails.ComLogo}
                    alt=""
                    style={{
                      width: "60px",
                      position: "absolute",
                      left: "20px",
                    }}
                  />
                  <h5 className="m-0 mb-2">{userDetails.ComName}</h5>
                  <p className="m-0 mb-2">
                    {userDetails.ComAddress}, {userDetails.ComBranch}
                  </p>
                  <p className="m-0 mb-2">{userDetails.ComTel}</p>
                </div>
                <tr>
                  {column.map((props) => {
                    const { name } = props;
                    return <td className="fw-bold">{name}</td>;
                  })}
                </tr>
              </thead>

              <tbody>
                {data.map((props) => {
                  const {
                    Index,
                    Department,
                    Head,
                    NoOfSubDepart,
                    NoOfDesign,
                    NoOfStaff,
                    Status,
                  } = props;

                  return (
                    <tr>
                      <td>{Index}</td>
                      <td>{Department}</td>
                      <td>{Head}</td>
                      <td>{NoOfSubDepart}</td>
                      <td>{NoOfDesign}</td>
                      <td>{NoOfStaff}</td>
                      <td>{Status}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="chgpopup-footer">
            <div className="row  mt-2 mb-2">
              <div>
                <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={handlePrint}
                >
                  <AiTwotonePrinter size="1rem" className="me-1" color="#fff" />
                  Print
                </button>

                <button
                  type="button"
                  class="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
