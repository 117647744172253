import React, { useContext, useEffect, useState } from "react";
import "../../../leaveNotes/leaveNotePopup.css";
import "../subdepartment/SubdepartmentPopup.css";
import CloseIcon from "../../../../images/CloseIcon.svg";
import "../../../hooks/imagePreview.css";
import "../leaveType/LeavePopup.css";
import AuthContext from "../../../context/auth-context";
import { Fetchdata } from "../../../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UpperbarContext from "../../../context/upperbar-context";
import Toast from "../../../Toast";
import $ from "jquery";
import { GetTwelveHourFormatTime } from "../../../hooks/dateConvertor";

export default function ShiftPopup({
  setShiftPopup,
  reload,
  setReload,
  shiftFormValue,
  setShiftFormValue,
  shiftFormError,
  setShiftFormError,
  shftList,
  shiftPopup,
}) {
  const { User } = useContext(AuthContext);
  const { appURL, mode } = useContext(UpperbarContext);
  const closePopUp = (e) => {
    setShiftPopup(false);
    setShiftFormError({});
    $(".addShiftPopBg").fadeOut(500);
    $(".addShiftPop").slideUp(500);
    setIsSubmit(false);
  };

  useEffect(() => {
    if (shiftPopup) {
      $(".addShiftPopBg").fadeIn(500);
      $(".addShiftPop").slideDown(500);
    }
  }, [shiftPopup]);

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type == "checkbox" ? target.checked : target.value;
    setShiftFormValue({ ...shiftFormValue, [name]: value });
  };

  const [isSubmit, setIsSubmit] = useState(false);

  const validate = (values) => {
    const errors = {};
    if (!values.lunchStart) {
      errors.lunchStart = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.lunchEnd) {
      errors.lunchEnd = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.shifts) {
      errors.shifts = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.shiftEnd) {
      errors.shiftEnd = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.shiftStart) {
      errors.shiftStart = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.allowLateIn) {
      errors.allowLateIn = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.allowEarlyOut) {
      errors.allowEarlyOut = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.hdHour) {
      errors.hdHour = mode === "en" ? "Required" : "आवश्यक छ";
    }

    return errors;
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setShiftFormError(validate(shiftFormValue));
    setIsSubmit(true);
  };


  useEffect(() => {
    if (Object.keys(shiftFormError).length === 0 && isSubmit) {
      const user = "" + User.UID;
      const Branch = "" + User.BranchID;
      const Fiscal = "" + User.FiscalID;
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: user,
        Flag: "i",
        Shift: shiftFormValue.shifts,
        Start: GetTwelveHourFormatTime(shiftFormValue.shiftStart),
        End: GetTwelveHourFormatTime(shiftFormValue.shiftEnd),
        AllowLateIn: GetTwelveHourFormatTime(shiftFormValue.allowLateIn),
        AllowEarlyOut: GetTwelveHourFormatTime(shiftFormValue.allowEarlyOut),
        LunchStart: GetTwelveHourFormatTime(shiftFormValue.lunchStart),
        LunchEnd: GetTwelveHourFormatTime(shiftFormValue.lunchEnd),
        BranchID: Branch,
        FiscalID: Fiscal,
        HDHour: shiftFormValue.hdHour,
        FetchURL: `${appURL}api/admin/shift`,
        Type: "POST",
      };

      Fetchdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          shftList();
          setShiftPopup(false);
          $(".addShiftPopBg").fadeOut(500);
          $(".addShiftPop").slideUp(500);
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });

      setIsSubmit(false);
    }
  }, [shiftFormError]);

  return (
    <>
      {/* <Toast /> */}
      <div className="container newpopup-bg addShiftPopBg">
        <div className="newpopup addShiftPop" style={{ width: "50%" }}>
          <div className="newpopup-head ps-0 pe-0">
            <div className="popUpTitle">
              {mode === "en" ? "Shift" : "शिफ्ट"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div className="newpopup-body ps-3 pe-3">
            <div className="form-in-padding">
              <div className="row text-start mt-2 ">
                <div className="col-md-12 col-sm-12 col-lg-12">
                  <div className="row text-start ">
                    <div className="form-group mt-0">
                      <label htmlFor="shifts" style={{ fontSize: "12px" }}>
                        {mode === "en" ? "Shift" : "शिफ्ट"}
                        <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <input
                        id="shifts"
                        style={{ fontSize: "13px" }}
                        type="text"
                        className="form-control form-control-sm "
                        name="shifts"
                        value={shiftFormValue.shifts}
                        onChange={handleChange}
                        placeholder={mode === "en" ? "Shift" : "शिफ्ट"}
                      />
                      {shiftFormError.shifts && (
                        <p className="errormsg">{shiftFormError.shifts}</p>
                      )}
                    </div>
                  </div>

                  <div className="row text-start ">
                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="shiftStart"
                          style={{ fontSize: "12px" }}
                        >
                          {mode === "en" ? "Shift Start" : "शिफ्ट सुरु"}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                          id="shiftStart"
                          style={{ fontSize: "13px" }}
                          type="time"
                          className="form-control form-control-sm "
                          name="shiftStart"
                          value={shiftFormValue.shiftStart}
                          onChange={handleChange}
                        />
                        {shiftFormError.shiftStart && (
                          <p className="errormsg">
                            {shiftFormError.shiftStart}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="shiftEnd" style={{ fontSize: "12px" }}>
                          {mode === "en" ? "Shift End" : "शिफ्ट अन्त्य"}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                          id="shiftEnd"
                          style={{ fontSize: "13px" }}
                          type="time"
                          className="form-control form-control-sm "
                          name="shiftEnd"
                          value={shiftFormValue.shiftEnd}
                          onChange={handleChange}
                        />
                        {shiftFormError.shiftEnd && (
                          <p className="errormsg">{shiftFormError.shiftEnd}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row text-start ">
                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="allowLateIn"
                          style={{ fontSize: "12px" }}
                        >
                          {mode === "en"
                            ? "Allow Late In"
                            : "ढिलो अनुमति दिइएको"}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                          id="allowLateIn"
                          style={{ fontSize: "13px" }}
                          type="time"
                          className="form-control form-control-sm "
                          name="allowLateIn"
                          value={shiftFormValue.allowLateIn}
                          onChange={handleChange}
                        />
                        {shiftFormError.allowLateIn && (
                          <p className="errormsg">
                            {shiftFormError.allowLateIn}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="allowEarlyOut"
                          style={{ fontSize: "12px" }}
                        >
                          {mode === "en"
                            ? "Allow Early Out"
                            : "प्रारम्भिक बाहिर अनुमति दिइएको"}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                          id="allowEarlyOut"
                          style={{ fontSize: "13px" }}
                          type="time"
                          className="form-control form-control-sm "
                          name="allowEarlyOut"
                          value={shiftFormValue.allowEarlyOut}
                          onChange={handleChange}
                        />
                        {shiftFormError.allowEarlyOut && (
                          <p className="errormsg">
                            {shiftFormError.allowEarlyOut}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row text-start ">
                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="lunchStart"
                          style={{ fontSize: "12px" }}
                        >
                          {mode === "en" ? "Lunch Start" : "लंच सुरु"}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                          id="lunchStart"
                          style={{ fontSize: "13px" }}
                          type="time"
                          className="form-control form-control-sm "
                          name="lunchStart"
                          value={shiftFormValue.lunchStart}
                          onChange={handleChange}
                        />
                        {shiftFormError.lunchStart && (
                          <p className="errormsg">
                            {shiftFormError.lunchStart}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="lunchEnd" style={{ fontSize: "12px" }}>
                          {mode === "en" ? "Lunch End" : "लंच अन्त्य"}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                          id="lunchEnd"
                          style={{ fontSize: "13px" }}
                          type="time"
                          className="form-control form-control-sm "
                          name="lunchEnd"
                          value={shiftFormValue.lunchEnd}
                          onChange={handleChange}
                        />
                        {shiftFormError.lunchEnd && (
                          <p className="errormsg">{shiftFormError.lunchEnd}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row text-start ">
                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="hdHour" style={{ fontSize: "12px" }}>
                          {mode === "en" ? "HD Hour" : "HD घण्टा"}{" "}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example"
                          name="hdHour"
                          value={shiftFormValue.hdHour}
                          onChange={handleChange}
                        >
                          <option disabled value="" selected>
                            {mode === "en" ? "HD Hour" : "HD घण्टा"}
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </select>
                        {shiftFormError.hdHour && (
                          <p className="errormsg">{shiftFormError.hdHour}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="new-footer">
            <div className="row  mt-1 mb-1">
              <div>
                <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={formSubmit}
                >
                  {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
