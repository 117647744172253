import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import AuthContext from "../../../context/auth-context";
import UpperbarContext from "../../../context/upperbar-context";
import Toast from "../../../Toast";
import { GoPlus } from "react-icons/go";
import {
  AiOutlineSearch,
  AiOutlineEdit,
  AiTwotonePrinter,
} from "react-icons/ai";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { Fetchdata } from "../../../hooks/getData";
import Spinner from "../../../loading/spinner";
import "../../entry/voucher/voucher.css";
import { ToastContainer, toast } from "react-toastify";
import { englishToNepaliNumber } from "nepali-number";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import $ from "jquery";

export default function SalaryFacilities() {
  const { User } = useContext(AuthContext);

  //   const searchInput = useRef("");
  const {
    fiscalYear,
    todayDate,
    customStyles,
    appURL,
    userDetails,
    darkText,
    mode,
  } = useContext(UpperbarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const [chooseSalary, setChooseSalary] = useState("1");

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setChooseSalary(value);
  };

  //  API to show list
  const [staffData, setStaffData] = useState({});
  useEffect(() => {
    staffLst();
  }, [chooseSalary]);

  const staffLst = () => {
    const UserID = "" + User.UID;
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;

    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      CID: "1",
      Flag: "S",
      DFlag: chooseSalary === "1" ? "A" : "R",
      BranchID: Branch,
      FiscalID: Fiscal,
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/payroll-staff`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setOriginalList(postResult);
        setLoading(false);

        const groupedByName = postResult.reduce((acc, curr) => {
          if (acc[curr.FullName]) {
            acc[curr.FullName].push(curr);
          } else {
            acc[curr.FullName] = [curr];
          }
          return acc;
        }, {});

        // create newStaffList from groupedByName
        const newStaffList = Object.keys(groupedByName).map((name) => {
          const designation = groupedByName[name][0].Designation; // assuming all objects with the same name have the same designation
          return {
            FullName: name,
            Designation: designation,
            data: groupedByName[name],
          };
        });

        const uniqueHead = [...new Set(postResult.map((item) => item.Head))];

        // create staffData object
        const newData = {};
        newStaffList.forEach((item) => {
          newData[item.FullName] = {};
          newData[item.FullName]["Designation"] = item.data[0].Designation;

          uniqueHead.forEach((head) => {
            const data = item.data.find((d) => d.Head === head);

            if (data) {
              newData[item.FullName][head] = {
                Amount: data.Amount,
                StaffID: data.StaffID,
                HeadID: data.HeadID,
              };
            } else {
              newData[item.FullName][head] = null;
            }
          });
        });

        setStaffData(newData);
      } else {
        setStaffData({});
        setLoading(false);
      }
    });
  };

  const allStaffData = Object.values(staffData)
    .flatMap((staff) => Object.entries(staff))
    .filter(([key, value]) => key !== "Designation")
    .map(([key, value]) => value);

  let totalAmt = allStaffData.reduce(function (prev, current) {
    return prev + +current.Amount;
  }, 0);

  const handleInputChange = (fullName, head, fieldName, fieldValue) => {
    setStaffData((prevData) => {
      const updatedData = {
        ...prevData,
        [fullName]: {
          ...prevData[fullName],
          [head]: {
            ...prevData[fullName][head],
            [fieldName]: fieldValue || 0,
          },
        },
      };
      return updatedData;
    });
  };

  const [isSubmit, setIsSubmit] = useState(false);

  const handleSubmit = () => {
    setIsSubmit(true);
  };

  useEffect(() => {
    const UserID = "" + User.UID;
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;

    if (isSubmit) {
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: UserID,
        CID: "1",
        Flag: "i",
        DFlag: chooseSalary === "1" ? "A" : "R",
        Values: allStaffData,
        BranchID: Branch,
        FiscalID: Fiscal,
        Type: "POST",
        FetchURL: `${appURL}api/acc/admin/payroll-staff`,
      };

      Fetchdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          staffLst();
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });
      setIsSubmit(false);
    }
  }, [isSubmit]);
  // ------------------
  const searchInput = useRef("");

  const searchHandler = (e) => {
    e.preventDefault();

    const searchQuery = searchInput.current.value.toLowerCase();
    if (searchQuery) {
      const filteredData = Object.entries(staffData).filter(([key, value]) =>
        key.toLowerCase().includes(searchQuery)
      );
      const newData = Object.fromEntries(filteredData);
      setStaffData(newData);
    } else {
      staffLst();
    }
  };

  const handleKeyPress = (event) => {
    // Check if the key pressed is not a number

    if (isNaN(Number(event.key))) {
      event.preventDefault(); // Prevent the character from being entered
    } // Prevent the character from being entered
  };

  const handleParticularKeyChange = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const inputs = document.querySelectorAll(".salary-input");
      const currentInput = e.target;
      const currentIndex = Array.from(inputs).indexOf(currentInput);
      if (currentIndex >= 0 && currentIndex < inputs.length - 1) {
        inputs[currentIndex + 1].focus();
        inputs[currentIndex + 1].setSelectionRange(0, 0); // Set cursor to the start
      }
    }
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper ps-4 pe-4">
        <div className="row ">
          <div className="page-header">
            <div className="text-start  page-title" style={darkText}>
              {mode === "en" ? "Salary & Facilities" : "तलब र सुविधा"}
            </div>
            <div className="page-date">
              <div className="sec-content" style={darkText}>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>

        <>
          <div className="sec-dataTable">
            <div className="upper-dataTbl pb-2">
              <div className="uk-flex  uk-flex-wrap uk-flex-between uk-flex-middle">
                <div className="uk-flex uk-flex-wrap uk-flex-bottom me-3">
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en" ? "Select Option" : "विकल्प चयन गर्नुहोस्"}
                    </label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="salary"
                      value={chooseSalary}
                      onChange={handleChange}
                      className="form-select form-select-sm"
                    >
                      <option
                        value="0"
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        {mode === "en"
                          ? "Select Option"
                          : "विकल्प चयन गर्नुहोस्"}
                      </option>

                      <option value="1">
                        {mode === "en" ? "Addition" : "थप"}
                      </option>
                      <option value="2">
                        {mode === "en" ? "Deduction" : "कटौती"}
                      </option>
                    </select>
                  </div>
                  <div className="me-2 mt-3">
                    <button
                      type="button"
                      class="btn btn-sm"
                      style={{
                        background: "var(--button-color)",
                        color: "white",
                      }}
                      onClick={handleSubmit}
                    >
                      {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
                    </button>
                  </div>
                </div>

                <div className="">
                  <div className="d-flex uk-flex-middle uk-flex-right">
                    <div className="btn-addlnote mt-3">
                      {/* <button
                      type="button"
                      class="btn btn-sm"
                      style={{
                        background: "var(--button-color)",
                        color: "white",
                      }}
                      //   onClick={handleAdd}
                    >
                      <GoPlus color="#fff" className="me-1" />
                      Add Area
                    </button> */}
                    </div>
                    <div className="export-btn">
                      <button
                        uk-tooltip="Export Excel"
                        // onClick={toExcel}
                        className="me-1 ms-2 border-0"
                      >
                        <RiFileExcel2Fill size="1rem" color="#136438" />
                      </button>
                    </div>
                    <div className="export-btn">
                      <button
                        uk-tooltip="Export Pdf"
                        // onClick={toPdf}
                        className="mx-1 border-0"
                      >
                        <BsFileEarmarkPdfFill size="1rem" color="#ff0002" />
                      </button>
                    </div>
                    <div className="export-btn">
                      <button
                        uk-tooltip="Print"
                        // onClick={toPrint}
                        className="mx-1 border-0"
                      >
                        <AiTwotonePrinter size="1rem" color="#555" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" w-100 mb-3 mt-3">
              <div className="d-flex uk-flex-middle justify-content-end">
                <div>
                  <form class="uk-search uk-search-default">
                    <AiOutlineSearch className="search-icon" />
                    <input
                      placeholder={mode === "en" ? "Search" : "खोजी गर्नुहोस्"}
                      ref={searchInput}
                      type="text"
                      className="form-control form-control-sm searchField"
                      onChange={searchHandler}
                    />
                  </form>
                </div>
              </div>
            </div>

            <div
              className="mt-3 mt-3 tableHeight"
              style={{ maxHeight: "400px" }}
            >
              {loading ? (
                <Spinner />
              ) : (
                <>
                  {Object.keys(staffData).length !== 0 ? (
                    <table className="uk-table reportTable voucher-table">
                      <thead>
                        <tr>
                          <td
                            className="rdt_TableHeadRow tableHead text-center"
                            width="70px"
                          >
                            {mode === "en" ? "S.N." : "क्र.सं"}
                          </td>
                          <td
                            className="rdt_TableHeadRow tableHead sticky-left"
                            width="200px"
                          >
                            {mode === "en" ? "Name" : "नाम"}
                          </td>
                          {Object.keys(staffData)
                            .reduce((acc, curr) => {
                              Object.keys(staffData[curr]).forEach((head) => {
                                if (!acc.includes(head)) {
                                  acc.push(head);
                                }
                              });
                              return acc;
                            }, [])
                            .map((head) => (
                              <td
                                className="rdt_TableHeadRow tableHead"
                                key={head}
                              >
                                {head}

                                {head === "Designation" ? null : (
                                  <>
                                    {chooseSalary === "1" ? (
                                      <FaArrowUp />
                                    ) : (
                                      <FaArrowDown />
                                    )}
                                  </>
                                )}
                              </td>
                            ))}
                          <td
                            className="rdt_TableHeadRow tableHead  text-end sticky-right"
                            width="150px"
                          >
                            {mode === "en" ? "Total" : "कुल"}
                          </td>
                        </tr>
                      </thead>

                      <tbody>
                        {Object.keys(staffData).map((fullName, i) => {
                          let rowTotal = 0;
                          return (
                            <tr key={fullName}>
                              <td
                                style={{ minWidth: "70px" }}
                                className="rdt_TableCell tablecell text-center"
                              >
                                {mode === "en"
                                  ? i + 1
                                  : englishToNepaliNumber(i + 1)}
                              </td>

                              <td
                                style={{ minWidth: "200px" }}
                                className="rdt_TableCell tablecell text-start sticky-left"
                              >
                                {fullName}
                              </td>

                              <td
                                style={{ minWidth: "200px" }}
                                className="rdt_TableCell tablecell text-start"
                              >
                                {staffData[fullName].Designation}
                              </td>

                              {Object.keys(staffData[fullName]).map((head) => {
                                if (head === "Designation") {
                                  return null;
                                }
                                const data = staffData[fullName][head];
                                if (data) {
                                  rowTotal += parseInt(data.Amount);
                                }
                                const amt = parseInt(data.Amount);
                                return (
                                  <td
                                    style={{ minWidth: "150px" }}
                                    className="rdt_TableCell tablecell voucherCell text-end"
                                    key={head}
                                  >
                                    <input
                                      type="text"
                                      className="form-control form-control-sm voucher-input salary-input text-end"
                                      name="Amount"
                                      onKeyPress={handleKeyPress}
                                      defaultValue={
                                        data && amt.toFixed(2)
                                          ? amt.toFixed(2)
                                          : 0
                                      }
                                      onKeyDown={(e) => {
                                        handleParticularKeyChange(e, i);
                                      }}
                                      onChange={(e) =>
                                        handleInputChange(
                                          fullName,
                                          head,
                                          "Amount",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                );
                              })}

                              <td
                                style={{
                                  minWidth: "150px",
                                }}
                                className="rdt_TableCell tablecell voucherCell reportTotal sticky-right text-end fw-bold"
                              >
                                {rowTotal.toFixed(2)}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>

                      <tfoot style={{ position: "sticky", bottom: "-1px" }}>
                        <tr className="reportTotal">
                          <td
                            colSpan="3"
                            className="rdt_TableCell tablecell text-end fw-bold"
                          >
                            {mode === "en" ? "Total" : "कुल"}
                          </td>

                          {Object.keys(staffData)
                            .reduce((acc, curr) => {
                              Object.keys(staffData[curr]).forEach((head) => {
                                if (!acc.includes(head)) {
                                  acc.push(head);
                                }
                              });
                              return acc;
                            }, [])
                            .map((head) => {
                              if (head === "Designation") {
                                return null;
                              }
                              let headTotal = 0;
                              Object.keys(staffData).forEach((fullName) => {
                                const data = staffData[fullName][head];
                                if (data) {
                                  headTotal += parseInt(data.Amount);
                                }
                              });
                              return (
                                <td
                                  className="rdt_TableCell tablecell text-end fw-bold"
                                  key={head}
                                >
                                  {headTotal.toFixed(2)}
                                </td>
                              );
                            })}
                          <td
                            className="rdt_TableCell tablecell text-end sticky-right fw-bold"
                            style={{ background: "#f8f8ff" }}
                          >
                            {totalAmt.toFixed(2)}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  ) : (
                    <p className="text-center">
                      {mode === "en" ? "No Data" : "डाटा उपलब्ध छैन"}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      </div>
    </>
  );
}
