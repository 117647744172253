import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "../../../../images/CloseIcon.svg";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import $ from "jquery";
import Toast from "../../../Toast";
import UpperbarContext from "../../../context/upperbar-context";
import AuthContext from "../../../context/auth-context";
import { Fetchdata } from "../../../hooks/getData";
import { capitalizeFirstLetter } from "../../../hooks/dateConvertor";

export default function AccountGroupPopup({
  popup,
  setPopup,
  submit,
  setSubmit,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
  accountGroupLst,
  acGrpList,
}) {
  const { appURL, sidePanelBg, mode } = useContext(UpperbarContext);
  const { User } = useContext(AuthContext);

  const closePopUp = (e) => {
    setPopup(false);
    $(".addAccountGroupPopBg").fadeOut(300);
    $(".addAccountGroupPop").slideUp(500);
    setSubmit(false);
    setFormErrors({});
  };

  useEffect(() => {
    if (popup) {
      $(".addAccountGroupPopBg").fadeIn(500);
      $(".addAccountGroupPop").slideDown(500);
    }
  }, [popup]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setFormValues({ ...formValues, [name]: capitalizeFirstLetter(value) });

    // Check if the changed field is "under" (account group)
    if (name === "under") {
      // Get the corresponding account group object
      const selectedGroup = acGrpList.find(
        (group) => group.AccGroupID === parseInt(value, 10)
      );

      // Update the "type" and "category" values based on the selected account group
      if (selectedGroup) {
        // Set the "type" and "category" based on the selected account group
        setFormValues((prevValues) => ({
          ...prevValues,
          type: selectedGroup.BSPLType,
          category: selectedGroup.GroupType,
        }));
      }else{
        setFormValues((prevValues) => ({
          ...prevValues,
          type: "",
          category: "",
        }));
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const numv = /^[0-9]+$/i;
    if (!values.groupName) {
      errors.groupName = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.schedule) {
      setSubmit(true);
    } else if (!numv.test(values.schedule)) {
      errors.schedule = mode === "en" ? "Must be digits" : "अंक हुनुपर्छ";
    } else if (values.schedule < 0 || values.schedule > 999) {
      errors.schedule =
        mode === "en" ? "Limit upto only 3 digits" : "३ अंकमा मात्र सीमित";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && submit) {
      const UserID = "" + User.UID;
      const Branch = "" + User.BranchID;
      const Fiscal = "" + User.FiscalID;
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: UserID,
        CID: "1",
        Flag: "i",
        ReferID: formValues.under,
        GroupName: formValues.groupName,
        GroupType: formValues.category,
        BSPLType: formValues.type,
        Schedule: formValues.schedule,
        BranchID: Branch,
        FiscalID: Fiscal,
        FetchURL: `${appURL}api/acc/admin/main-group`,
        Type: "POST",
      };
      Fetchdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          setPopup(false);
          $(".addAccountGroupPopBg").fadeOut(300);
          $(".addAccountGroupPop").slideUp(500);
          accountGroupLst();
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });

      setSubmit(false);
    }
  }, [formErrors]);

  //
  return (
    <>
      {/* <Toast /> */}
      <div className="container popup-wrapper addAccountGroupPopBg">
        <div className="popup-inner addAccountGroupPop">
          <div className="popUpHeader ps-0 pe-0" style={sidePanelBg}>
            <div className="popUpTitle">
              {mode === "en" ? "Add Account Group" : "खाता समूह थप्नुहोस्"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div className="popUpBody ps-3 pe-3">
            <div className="form-padding">
              <div className="row text-start ">
                <div className="form-group">
                  <label htmlFor="group" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Group Name" : "समूहको नाम"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="group"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="groupName"
                    placeholder={mode === "en" ? "Group Name" : "समूहको नाम"}
                    value={formValues.groupName}
                    onChange={handleChange}
                  />
                  {formErrors.groupName && (
                    <p className="errormsg">{formErrors.groupName}</p>
                  )}
                </div>
              </div>

              <div className="row text-start">
                <div className="form-group ">
                  <label htmlFor="under" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Under" : "अन्तर्गत"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    name="under"
                    id="under"
                    value={formValues.under}
                    onChange={handleChange}
                  >
                    <option disabled value="" selected>
                      {mode === "en" ? "Under" : "अन्तर्गत"}
                    </option>
                    <option value="0">
                      {mode === "en" ? "Primary" : "प्राथमिक"}
                    </option>
                    {acGrpList.map((props) => {
                      return (
                        <option key={props.AccGroupID} value={props.AccGroupID}>
                          {props.AccGroup}
                        </option>
                      );
                    })}
                  </select>
                  {/* {formErrors.under && (
                  <p className="errormsg">{formErrors.under}</p>
                )} */}
                </div>
              </div>

              <div className="row text-start">
                <div className="col-md-6 col-sm-6 col-lg-6">
                  <div className="form-group">
                    <label htmlFor="type" style={{ fontSize: "12px" }}>
                      {mode === "en" ? "Select Type" : "प्रकार चयन गर्नुहोस्"}
                      <sup style={{ color: "red" }}>*</sup>
                    </label>
                    <select
                      id="type"
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      name="type"
                      value={formValues.type}
                      onChange={handleChange}
                      disabled={formValues.under !== "0"}
                    >
                      <option disabled value="" selected>
                        {mode === "en" ? "Select Type" : "प्रकार चयन गर्नुहोस्"}
                      </option>
                      <option value="P">
                        {mode === "en" ? "Profit & Loss" : "नाफा घाटा"}
                      </option>
                      <option value="B">
                        {mode === "en" ? "Balance Sheet" : "ब्यालेन्स शीट"}
                      </option>
                      <option value="T">
                        {mode === "en" ? "Trading" : "व्यापार"}
                      </option>
                    </select>
                    {/* {formErrors.type && (
                  <p className="errormsg">{formErrors.type}</p>
                )} */}
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6">
                  <div className="form-group">
                    <label htmlFor="category" style={{ fontSize: "12px" }}>
                      {mode === "en"
                        ? "Select Category"
                        : "श्रेणी चयन गर्नुहोस्"}
                      <sup style={{ color: "red" }}>*</sup>
                    </label>
                    <select
                      id="category"
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      name="category"
                      value={formValues.category}
                      onChange={handleChange}
                      disabled={formValues.under !== "0"}
                    >
                      <option disabled value="" selected>
                        {mode === "en"
                          ? "Select Category"
                          : "श्रेणी चयन गर्नुहोस्"}
                      </option>
                      {formValues.type === "B" && (
                        <>
                          <option value="A">
                            {mode === "en" ? "Assets" : "सम्पत्तिहरू"}
                          </option>
                          <option value="L">
                            {mode === "en" ? "Liability" : "दायित्व"}
                          </option>
                        </>
                      )}
                      {formValues.type === "P" || formValues.type === "T" ? (
                        <>
                          <option value="I">
                            {mode === "en" ? "Income" : "आम्दानी"}
                          </option>
                          <option value="E">
                            {mode === "en" ? "Expenditure" : "खर्च"}
                          </option>
                        </>
                      ) : (
                        ""
                      )}
                    </select>
                    {/* {formErrors.category && (
                  <p className="errormsg">{formErrors.category}</p>
                )} */}
                  </div>
                </div>
              </div>

              <div className="row text-start ">
                <div className="form-group">
                  <label htmlFor="schedule" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Schedule No." : "अनुसूची नं."}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="schedule"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm scheduleNum"
                    name="schedule"
                    placeholder={mode === "en" ? "Schedule No." : "अनुसूची नं."}
                    value={formValues.schedule}
                    onChange={handleChange}
                    min="0"
                    max="999"
                    maxLength="3"
                  />
                  {formErrors.schedule && (
                    <p className="errormsg">{formErrors.schedule}</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="ln-popUpFooter">
            <div className="row  mt-1 mb-1">
              <div>
                <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={handleSubmit}
                >
                  {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
