import React, { useContext, useEffect, useRef, useState } from "react";
import "../../../profile/profile.css";
import DataTable from "react-data-table-component";
import { Fetchdata } from "../../../hooks/getData";
import AuthContext from "../../../context/auth-context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StaffPopup from "./StaffPopup";
import StaffContext from "../staffState/StaffContext";
import StaffEditPopup from "./StaffEditPopup";
import { ShowImgPreview } from "../../../hooks/imagePreview";
import Spinner from "../../../loading/spinner";
import UpperbarContext from "../../../context/upperbar-context";
import { FaRegCalendarAlt } from "react-icons/fa";
import Toast from "../../../Toast";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { GoPlus } from "react-icons/go";
import {
  AiOutlineEdit,
  AiOutlineSearch,
  AiOutlineReload,
} from "react-icons/ai";
import ResetPop from "./ResetPop";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { GetNepaliDate } from "../../../hooks/dateConvertor";
import { englishToNepaliNumber } from "nepali-number";
import { createCanvas, loadImage } from "canvas";
import { generatePdf } from "../../../hooks/PdfExcel";
import placeholder from "../../../../images/placeholder.png";

export default function Staff() {
  const {
    fiscalYear,
    todayDate,
    dateMode,
    expires,
    appURL,
    mode,
    userDetails,
  } = useContext(UpperbarContext);
  const {
    staffPopup,
    setStaffPopup,
    staffEditPopup,
    setStaffEditPopup,
    setStaffFormValue,
    staffValue,
    staffList,
    editPop,
    deactivateDepart,
    originalList,
    setOriginalList,
    setStaffList,
    chooseDepartment,
    setChooseDepartment,
    chooseSubDepartment,
    setChooseSubDepartment,
    chooseDesignation,
    setChooseDesignation,
    loading,
    setLoading,
    customStylesForImage,
    setNotWorking,
    setCheckedList,
    setManagerChecked,
    workingStatus,
    setworkingStatus,
    branch,
    setBranch,
  } = useContext(StaffContext);

  const [activeTab, setActiveTab] = useState({
    tab1: true,
    tab2: false,
    tab3: false,
  });

  const handleTab1 = () => {
    setActiveTab({
      tab1: true,
      tab2: false,
      tab3: false,
    });
  };
  const handleTab2 = () => {
    setActiveTab({
      tab1: false,
      tab2: true,
      tab3: false,
    });
  };
  const handleTab3 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: true,
    });
  };

  const [re, setRe] = useState();

  const { User } = useContext(AuthContext);

  const [imgPrv, setImgPrv] = useState(false);
  const [imagePre, setImagePre] = useState("");

  const searchInput = useRef("");

  const addLeaveNote = (e) => {
    setStaffPopup(true);
    setNotWorking(false);
    setStaffFormValue(staffValue);
    setCheckedList([]);
    setManagerChecked(false);
    setRe(Math.random());
    document.getElementById("SUN").checked = false;
    document.getElementById("MON").checked = false;
    document.getElementById("TUES").checked = false;
    document.getElementById("WED").checked = false;
    document.getElementById("THURS").checked = false;
    document.getElementById("FRI").checked = false;
  };

  const handleDepartment = (e) => {
    setChooseDesignation("-1");
    setChooseSubDepartment("-1");

    const target = e.target;
    const name = target.name;
    const value = target.value;
    setChooseDepartment(value);
  };

  const handleSubDepartment = (e) => {
    setChooseDesignation("-1");
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setChooseSubDepartment(value);
  };

  const handleDesignation = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setChooseDesignation(value);
  };
  const handleWorkingStatus = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setworkingStatus(value);
  };
  const handleBranch = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setBranch(value);
  };

  useEffect(() => {
    if (activeTab.tab1 === true) {
      setworkingStatus("Y");
    } else if (activeTab.tab2 === true) {
      setworkingStatus("N");
    } else if (activeTab.tab3 === true) {
      setworkingStatus("-1");
    }
  }, [activeTab]);

  const [branchList, setBranchList] = useState([]);

  useEffect(() => {
    brnchList();
  }, []);

  const brnchList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      IsActive: "-1",
      Type: "POST",
      FetchURL: `${appURL}api/admin/branch`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setBranchList(postResult);
      } else {
        setBranchList([]);
      }
    });
  };

  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      grow: 0,
      center: true,
      width: "70px",
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },
    {
      name: mode === "en" ? "Image" : "फोटो",
      // grow: 0,
      center: true,
      width: "90px",
      selector: (row) => {
        return (
          <>
            <div
              className="staffImg tl"
              style={{ height: "40px", width: "40px", margin: "0.3rem" }}
            >
              <div
                className="staffContentLogo tl"
                style={{ height: "40px", width: "40px" }}
              >
                <img
                  src={row.Image}
                  alt="dp"
                  onClick={() => {
                    setImagePre(row.Image);
                    setImgPrv(true);
                  }}
                  style={{ height: "40px", width: "40px" }}
                  onError={(e) => (e.target.src = placeholder)}
                />
              </div>
            </div>
          </>
        );
      },
    },
    // {
    //   name: "User Code",
    //   center: true,
    //   // grow: 0,
    //   selector: (row) => row.UserCode,
    // },
    {
      name: mode === "en" ? "Full Name" : "पुरा नाम",
      // grow: 0,
      // center: true,
      minWidth: "200px",
      selector: (row) => {
        return (
          <>
            <span className="me-2">({row.UserName})</span>
            <span>{row.FirstName + " " + row.LastName}</span>
          </>
        );
      },
    },

    {
      name: mode === "en" ? "Designation" : "पदनाम",
      // grow: 0,
      // center: true,
      minWidth: "200px",
      selector: (row) => row.Designation,
    },
    {
      name: mode === "en" ? "Enroll Date" : "भर्ना मिति",
      // center: true,
      // grow: 0,
      width: "120px",
      selector: (row) =>
        dateMode === "en" && mode === "np"
          ? englishToNepaliNumber(row.EnrollDate)
          : dateMode === "en"
          ? row.EnrollDate
          : dateMode === "np" && mode === "np"
          ? englishToNepaliNumber(GetNepaliDate(row.EnrollDate))
          : dateMode === "np"
          ? GetNepaliDate(row.EnrollDate)
          : null,
    },
    {
      name: mode === "en" ? "Working Status" : "काम गर्ने स्थिति",
      center: true,
      grow: 1,
      width: "140px",
      selector: (row) => {
        return (
          <span
            className=" badge rounded-pill"
            style={{ fontSize: "11px", background: "var(--button-color)" }}
          >
            {row.WorkingStatus}
          </span>
        );
      },
    },

    {
      name: mode === "en" ? "Status" : "स्थिति",
      // grow: 0,
      center: true,
      width: "140px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                className="border-0 bg-transparent"
                onClick={() => changeStatus(row.UID, row.IsActive)}
              >
                <span
                  className=" badge rounded-pill bg-success"
                  style={{ fontSize: "11px" }}
                >
                  {checkStatus(row.IsActive)}
                </span>
              </button>
            </div>
          </>
        );
      },
    },
    {
      name: mode === "en" ? "Action" : "कार्य",
      // grow: 0,
      center: true,
      width: "130px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                class="btn btn-sm editspan"
                onClick={() => editPop(row)}
                uk-tooltip={mode === "en" ? "Edit" : "सम्पादन गर्नुहोस्"}
              >
                <AiOutlineEdit />
              </button>{" "}
              <button
                type="button"
                class="btn btn-sm deletespan mx-1"
                onClick={() => handleReset(row)}
                uk-tooltip={mode === "en" ? "Reset Password" : "पासवर्ड रिसेट"}
              >
                <AiOutlineReload />
              </button>{" "}
            </div>
          </>
        );
      },
    },
  ];

  const [iD, setID] = useState();
  const [resetPop, setResetPop] = useState(false);
  const handleReset = (data) => {
    setID(data.UID);
    setResetPop(true);
  };

  const reset = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      Flag: "RP",
      UserID: User.UID,
      StaffID: iD,
      FetchURL: `${appURL}api/admin/user`,
      Type: "POST",
    };

    Fetchdata(dataForm)
      .then(function (result) {
        if (result.StatusCode === 200) {
          setResetPop(false);
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      })
      .catch((err) => {
        toast.error("Error: " + err, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      });
  };

  const changeStatus = (ID, IsActive) => {
    deactivateDepart(ID, IsActive);
  };

  const checkStatus = (IsActive) => {
    if (IsActive === "A") {
      return mode === "en" ? "Deactivate" : "निष्क्रिय";
    } else if (IsActive === "I") {
      return mode === "en" ? "Activate" : "सक्रिय";
    }
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();

    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["FirstName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setStaffList(srchResult);
      } else {
        setStaffList({});
      }
    } else {
      setStaffList(originalList);
    }
  };

  //API to hit Department list
  const [departmentList, setDepartmentList] = useState([]);

  useEffect(() => {
    deptList();
  }, []);

  const deptList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      IsActive: "A",
      Type: "POST",
      FetchURL: `${appURL}api/admin/department`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setDepartmentList(postResult);
      } else {
        setDepartmentList([]);
      }
    });
  };

  // API to hit Sub-Department list
  // const [subdepartmentList, setSubdepartmentList] = useState([]);

  // useEffect(() => {
  //   subdeptList();
  // }, [chooseDepartment]);

  // const subdeptList = () => {
  //   const params = {
  //     ComID: User.CompanyId,
  //     StaffID: -1,
  //     DepartID: chooseDepartment,
  //     Flag: "S",
  //     Type: "POST",
  //     Status: 1,
  //     BranchID: User.BranchId,
  //     FetchURL: `${appURL}api/admin/sub-department`,
  //   };

  //   Fetchdata(params).then(function (result) {
  //     if (result.StatusCode === 200) {
  //       const postResult = result.SubDepList ? result.SubDepList : "";
  //       setSubdepartmentList(postResult);
  //     } else {
  //       setSubdepartmentList([]);
  //     }
  //   });
  // };

  //API to hit Designation list
  const [designationList, setDesignationList] = useState([]);

  useEffect(() => {
    desgList();
  }, [chooseDepartment]);

  const desgList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      DepartID: chooseDepartment,
      IsActive: "A",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/designation`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setDesignationList(postResult);
      } else {
        setDesignationList([]);
      }
    });
  };

  const dataWithIndex = staffList.map((d, i) => ({
    ...d,
    Index: i + 1,
    Name: d.FirstName + " " + d.LastName,
    Status: d.Status === 1 ? "Inactive" : "Active",
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    // { header: "Image", field: "Image" },
    { header: "Full Name", field: "Name" },
    { header: "Designation", field: "Designation" },
    { header: "Enroll Date", field: "EnrollDate" },
    { header: "Working Status", field: "WorkingStatus" },
    { header: "Status", field: "Status" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    generatePdf(userDetails, exportColumns, dataWithIndex);
  };

  const dataToExcel = staffList.map((d, i) => ({
    "S.N.": i + 1,
    "Full Name": d.FirstName + " " + d.LastName,
    Designation: d.Designation,
    "Enroll Date": d.EnrollDate,
    "Working Status": d.WorkingStatus,
    Status: d.Status === 1 ? "Inactive" : "Active",
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Staff");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper  ps-4 pe-4">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">
              {mode === "en" ? "Staff" : "कर्मचारी"}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <span className="me-2 ">Expires in: {expires} </span>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>
        <>
          <div className="sec-dataTable">
            <div className="insidePopup mt-3 attRoute">
              <ul>
                <li
                  className={activeTab.tab1 === true ? "active" : ""}
                  onClick={handleTab1}
                >
                  <i className="fa fa-sliders icon"></i>
                  {mode === "en" ? "Active" : "सक्रिय"}{" "}
                </li>
                <li
                  className={activeTab.tab2 === true ? "active" : ""}
                  onClick={handleTab2}
                >
                  <i className="fas fa-sliders icon"></i>
                  {mode === "en" ? "Not working" : "काम गरिरहेको छैन"}{" "}
                </li>
                <li
                  className={activeTab.tab3 === true ? "active" : ""}
                  onClick={handleTab3}
                >
                  <i className="fas fa-sliders icon"></i>
                  {mode === "en" ? "All" : "सबै"}{" "}
                </li>
              </ul>
            </div>

            <div className="upper-dataTbl">
              <div className="uk-flex uk-flex-wrap uk-flex-between uk-flex-middle mb-3">
                <div className="uk-flex uk-flex-wrap me-5">
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en"
                        ? "Select Department"
                        : "विभाग चयन गर्नुहोस्"}
                    </label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="snotifiaction"
                      value={chooseDepartment}
                      onChange={handleDepartment}
                      className="form-select form-select-sm"
                    >
                      <option
                        value="0"
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        {mode === "en"
                          ? "Select Department"
                          : "विभाग चयन गर्नुहोस्"}
                      </option>
                      <option value="-1">
                        {mode === "en" ? "Select All" : "सबै चयन गर्नुहोस्"}
                      </option>
                      {departmentList.map((item) => (
                        <option
                          key={item.DepartmentID}
                          value={item.DepartmentID}
                        >
                          {item.Department}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en"
                        ? "Select Sub-Department"
                        : "उप विभाग चयन गर्नुहोस्"}
                    </label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="snotifiaction"
                      value={chooseSubDepartment}
                      onChange={handleSubDepartment}
                      className="form-select form-select-sm"
                    >
                      <option
                        value="0"
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        {mode === "en"
                          ? "Select Sub-Department"
                          : "उप विभाग चयन गर्नुहोस्"}
                      </option>
                      <option value="-1">
                        {mode === "en" ? "Select All" : "सबै चयन गर्नुहोस्"}
                      </option>
                      {subdepartmentList.map((item) => (
                        <option key={item.SubDepartID} value={item.SubDepartID}>
                          {item.SubDepartName}
                        </option>
                      ))}
                    </select>
                  </div> */}
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en"
                        ? "Select Designation"
                        : "पदनाम चयन गर्नुहोस्"}
                    </label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="snotifiaction"
                      value={chooseDesignation}
                      onChange={handleDesignation}
                      className="form-select form-select-sm"
                    >
                      <option
                        value="0"
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        {mode === "en"
                          ? "Select Designation"
                          : "पदनाम चयन गर्नुहोस्"}
                      </option>
                      <option value="-1">
                        {mode === "en" ? "Select All" : "सबै चयन गर्नुहोस्"}
                      </option>
                      {designationList.map((item) => (
                        <option
                          key={item.DesignationID}
                          value={item.DesignationID}
                        >
                          {item.Designation}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en"
                        ? " Select Working Status"
                        : "काम गर्ने स्थिति चयन गर्नुहोस्"}
                    </label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="snotifiaction"
                      value={workingStatus}
                      onChange={handleWorkingStatus}
                      className="form-select form-select-sm"
                    >
                      <option
                        value="0"
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        {mode === "en"
                          ? " Select Working Status"
                          : "काम गर्ने स्थिति चयन गर्नुहोस्"}
                      </option>
                      <option value="-1">
                        {mode === "en" ? "Select All" : "सबै चयन गर्नुहोस्"}
                      </option>
                      <option value="Y">
                        {mode === "en" ? "Working" : "काम गर्दै"}
                      </option>
                      <option value="N">
                        {mode === "en" ? "Not Working" : " काम गरिरहेको छैन"}
                      </option>
                    </select>
                  </div> */}

                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en" ? " Select Branch" : "साखा चयन गर्नुहोस्"}
                    </label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="snotifiaction"
                      value={branch}
                      onChange={handleBranch}
                      className="form-select form-select-sm"
                    >
                      <option
                        value="0"
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        {mode === "en"
                          ? " Select Branch"
                          : "साखा चयन गर्नुहोस्"}
                      </option>
                      {/* <option value="-1">
                        {mode === "en" ? "Select All" : "सबै चयन गर्नुहोस्"}
                      </option> */}
                      {branchList.map((item) => (
                        <option key={item.BranchID} value={item.BranchID}>
                          {item.Name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="">
                  <div className="d-flex uk-flex-wrap uk-flex-middle uk-flex-right">
                    <div className="btn-addlnote mt-3">
                      <button
                        type="button"
                        class="btn btn-sm"
                        style={{
                          background: "var(--button-color)",
                          color: "white",
                        }}
                        onClick={addLeaveNote}
                      >
                        <GoPlus color="#fff" className="me-1" />
                        {mode === "en" ? "Add New" : "नयाँ थप्नुहोस्"}
                      </button>
                    </div>

                    <div className="export-btn">
                      <button
                        uk-tooltip="Export Excel"
                        onClick={toExcel}
                        className="me-1 ms-2 border-0"
                      >
                        <RiFileExcel2Fill size="1rem" color="#136438" />
                      </button>
                    </div>
                    <div className="export-btn">
                      <button
                        uk-tooltip="Export Pdf"
                        onClick={toPdf}
                        className="mx-1 border-0"
                      >
                        <BsFileEarmarkPdfFill size="1rem" color="#ff0002" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={staffList}
                customStyles={customStylesForImage}
                pagination
                paginationRowsPerPageOptions={[10, 20, 50, 100]} // Customizable options
                fixedHeader
                fixedHeaderScrollHeight="410px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderComponent={
                  <>
                    <div className=" w-100">
                      <div className="d-flex uk-flex-middle justify-content-end">
                        <div>
                          <div class="uk-search uk-search-default">
                            <AiOutlineSearch className="search-icon" />
                            <input
                              placeholder={
                                mode === "en" ? "Search" : "खोजी गर्नुहोस्"
                              }
                              ref={searchInput}
                              type="text"
                              className="form-control form-control-sm searchField"
                              onChange={searchHandler}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      <StaffPopup re={re} setRe={setRe} />

      <StaffEditPopup re={re} setRe={setRe} />

      {resetPop && <ResetPop reset={reset} setResetPop={setResetPop} />}

      {imgPrv &&
        ShowImgPreview({
          img: imagePre,
          setTrigger: setImgPrv,
        })}
    </>
  );
}
