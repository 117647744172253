import React, { useContext, useEffect, useState } from "react";
import joblogo from "../../../images/jobLogo.png";
import "./elitejob.css";
import EliteJobLoginPop from "./EliteJobLoginPop";
import Job from "./Job";
import { toast } from "react-toastify";
import UpperbarContext from "../../context/upperbar-context";
import { FaRegCalendarAlt } from "react-icons/fa";
import AuthContext from "../../context/auth-context";
import { Fetchdata } from "../../hooks/getData";

const EliteJobLogin = ({
  // checkUser,
  // setCheckUser,
  checkCompany,
  setCheckCompany,
}) => {
  const {
    fiscalYear,
    todayDate,
    appURL,
    customStyles,
    mode,
    userDetails,
    expires,
  } = useContext(UpperbarContext);
  const { User } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const enableUser = () => {
    setLoading(true);
  };

  // const userSync = () => {
  //   const params = {
  //    ComID: User.ComID,
  //     Flag: "i",
  //     UserName: User.UserName,
  //     FirstName: User.FullName.split(" ")[0],
  //     LastName: User.FullName.split(" ")[1],
  //     Password: User.UserName,
  //     Image: User.UserImage,
  //     Type: "POST",
  //     FetchURL: `${appURL}api/job/user-sync`,
  //   };

  //   Fetchdata(params).then(function (result) {
  //     if (result.StatusCode === 200) {
  //       setCheckUser(true);
  //       setLoading(false);
  //       toast(result.Message, {
  //         style: {
  //           color: "green",
  //           fontSize: "13px",
  //         },
  //       });
  //     } else {
  //       setCheckUser(false);
  //       setLoading(false);
  //       toast(result.Message, {
  //         style: {
  //           color: "red",
  //           fontSize: "13px",
  //         },
  //       });
  //     }
  //   });
  // };
  const comSync = () => {
    const params = {
      ComID: User.ComID,
      Flag: "i",
      Email: User.Email,
      Name: User.ComName,
      Address: User.ComAddress,
      District: User.ComDistrictID,
      IndustryID: "1",
      Website: User.ComWebsite,
      Bio: "",
      Latitude: User.ComLat,
      Longitude: User.ComLong,
      Facebook: User.ComFb,
      Instagram: "",
      Linkedin: "",
      Logo: User.ComLogo,
      Telephone: User.ComTel,
      Type: "POST",
      FetchURL: `${appURL}api/job/com-sync`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        setCheckCompany(true);
        setLoading(false);
        // toast(result.Message, {
        //   style: {
        //     color: "green",
        //     fontSize: "13px",
        //   },
        // });
      } else {
        setCheckCompany(false);
        setLoading(false);
        // toast(result.Message, {
        //   style: {
        //     color: "red",
        //     fontSize: "13px",
        //   },
        // });
      }
    });
  };

  useEffect(() => {
    if (loading) {
      // if (checkCompany) {
      // userSync();
      // } else {
      comSync();
      // userSync();
      // }
    }
  }, [loading]);

  // const [closeChecked, setCloseChecked] = useState(false);
  // const [popup, setPopup] = useState(false);
  // const togglePopup = () => {
  //   setPopup(!popup);
  // };

  // const closePopup = (e) => {
  //   e.preventDefault();
  //   togglePopup();
  //   // setAbsentFormValue(absentValue);
  //   // setAbsentFormError({});
  //   // setIsSubmit(false);
  // };

  // const addnew = (e) => {
  //   e.preventDefault();
  //   togglePopup();
  //   // setIsSubmit(false);
  //   // setAbsentFormValue(absentValue);
  //   // setAbsentFormError({});
  //   setCloseChecked(false);
  // };

  return (
    <>
      <div className="container-fluid classatten-wrapper ps-4 pe-4">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Job</div>
            <div className="page-date">
              <div className="sec-content">
                <span className="me-2 ">Expires in: {expires} </span>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>

        <div className="sec-dataTable">
          <div className="">
            <div className="elite-job-login">
              <div className="elitejoblogo">
                <img src={joblogo} alt="logo" />
              </div>
              <h2>Elite Jobs</h2>
              <p>Join the best. Be the best</p>

              {!checkCompany ? (
                //  || !checkUser
                <div className="btn-addlnote justify-content-center">
                  <button
                    type="button"
                    class="btn btn-sm"
                    style={{
                      background: "var(--button-color)",
                      color: "white",
                    }}
                    onClick={enableUser}
                  >
                    {loading ? "Please wait..." : "Enable"}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {/* {popup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Elite Job</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <EliteJobLoginPop
                closePopup={closePopup}
                closeChecked={closeChecked}
                setCloseChecked={setCloseChecked}
              />
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default EliteJobLogin;
