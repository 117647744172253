import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import { TiMinus } from "react-icons/ti";
import AuthContext from "../../context/auth-context";
import UpperbarContext from "../../context/upperbar-context";
import Toast from "../../Toast";
import Allowance from "./allowance/Allowance";
import Deduction from "./deduction/Deduction";
// import "../ledger report/ledger.css";
// import Report from "./Report";
// import Summary from "./Summary";

export default function AllowanceDeduction() {
  const { User } = useContext(AuthContext);

  //   const searchInput = useRef("");
  const { fiscalYear, todayDate, appURL, userDetails, darkText, mode } =
    useContext(UpperbarContext);

  const [activeTab, setActiveTab] = useState({
    tab1: true,
    tab2: false,
  });

  const handleTab1 = () => {
    setActiveTab({
      tab1: true,
      tab2: false,
    });
  };
  const handleTab2 = () => {
    setActiveTab({
      tab1: false,
      tab2: true,
    });
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper ps-4 pe-4">
        <div className="row ">
          <div className="page-header">
            <div className="text-start  page-title" style={darkText}>
              {mode === "en" ? "Payroll" : "तलब"}
            </div>
            <div className="page-date">
              <div className="sec-content" style={darkText}>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>

        <>
          <div className="sec-dataTable">
            <div className="insidePopup mt-3">
              <ul>
                <li
                  className={`d-flex ${
                    activeTab.tab1 === true ? "active" : ""
                  }`}
                  onClick={handleTab1}
                >
                  <GoPlus className="me-2" />
                  {mode === "en" ? "Addition" : "थप"}
                </li>
                <li
                  className={`d-flex ${
                    activeTab.tab2 === true ? "active" : ""
                  }`}
                  onClick={handleTab2}
                >
                  <TiMinus className="me-2" />
                  {mode === "en" ? "Deduction" : "कटौती"}
                </li>
              </ul>
            </div>
            <div>
              {activeTab.tab1 && <Allowance />}
              {activeTab.tab2 && <Deduction />}
            </div>
          </div>
        </>
      </div>
    </>
  );
}
