import React from "react";
import CloseIcon from "../../images/CloseIcon.svg";
import "./confirmPopup.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import UpperbarContext from "../context/upperbar-context";
import Toast from "../Toast";
// import { RiAlertFill } from "react-icons/ri";

export default function ConfirmPopup(data) {
  const { sidePanelBg, mode } = useContext(UpperbarContext);
  const handlePopup = () => {
    fetchdata(data.FormData)
      .then(function (result) {
        if (result.StatusCode === 200) {
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
          data.setReload(!data.reload);
          data.setTrigger(false);
        } else {
          toast.error("Error: Occurred error during fetch", {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      })
      .catch((err) => {
        toast.error("Error: Occurred error during fetch" + err, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      });
  };

  const fetchdata = async (dataToSend) => {
    if (dataToSend.Type === "POST") {
      const response = await fetch(dataToSend.FetchURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Signature: "p0m76",
        },
        body: JSON.stringify(dataToSend),
      });
      const cooptive = await response.json();

      return cooptive;
    } else {
      const response = await fetch(dataToSend.FetchURL);
      const cooptive = await response.json();

      return cooptive;
    }
  };

  return (
    <>
      <Toast />
      <div className="container confirm-popup">
        <div className="confirm-popup-inner ">
          <div className="popUpHeader ps-0 pe-0" style={sidePanelBg}>
            <div className="popUpTitle">Elite Office</div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={() => data.setTrigger(false)}
              />
            </div>
          </div>

          <div className="confirm-body ps-3 pe-3">
            <div className="row text-start ">
              <div className="ms-2">{data.message}</div>
            </div>
          </div>

          <div className="confirm-footer">
            <div className="row mt-2 mb-2">
              <div>
                <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={handlePopup}
                >
                  {mode === "en" ? "Okay" : "बुझाउनुहोस्"}
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-1"
                  style={{ color: "white" }}
                  onClick={() => data.setTrigger(false)}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
