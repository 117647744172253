import React, { useContext, useEffect, useRef, useState } from "react";
import "../../profile/profile.css";
import DataTable from "react-data-table-component";
// import { Fetchdata } from "../../../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { ShowImgPreview } from "../../../hooks/imagePreview";
import UpperbarContext from "../../context/upperbar-context";
import StaffContext from "../organization/staffState/StaffContext";
import AuthContext from "../../context/auth-context";
import { Fetchdata } from "../../hooks/getData";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import NepaliDate from "nepali-date-converter";
import ApprovePop from "./ApprovePop";
import RejectPop from "./RejectPop";
import { GetNepaliDate } from "../../hooks/dateConvertor";
import { FaRegCalendarAlt } from "react-icons/fa";
import Toast from "../../Toast";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { AiOutlineSearch, AiOutlineEye } from "react-icons/ai";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ViewLeaveReport from "./ViewPop";
import { GoPlus } from "react-icons/go";
import LeaveReportPop from "./LeaveReportPop";
import { englishToNepaliNumber } from "nepali-number";
import { NavLink } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { generatePdf } from "../../hooks/PdfExcel";

export default function LeaveReport() {
  const {
    appURL,
    fiscalYear,
    expires,
    customStyles,
    todayDate,
    mode,
    userDetails,
  } = useContext(UpperbarContext);
  const { User } = useContext(AuthContext);

  const [originalList, setOriginalList] = useState("");
  const [loading, setLoading] = useState(true);
  const [viewPopup, setViewPopup] = useState(false);
  const [selected_note, setSelectedNote] = useState("");

  const [reload, setReload] = useState(false);
  const [DFlag, setDFlag] = useState("N");
  const [leaveNotePopup, setLeaveNotePopup] = useState(false);
  const [formErrors, setformErrors] = useState({});
  const [re, setRe] = useState();
  const [leaveReport, setLeaveReport] = useState([]);
  const [updatedLeaveReports, setUpdatedLeaveReports] = useState([]);
  const initalvalue = {
    leaveType: "",
    dayType: "",
    LeaveTitle: "",
    LeaveDescription: "",
    assignedStaff: "",
    fromDate: "",
    toDate: "",
  };
  const [formValues, setFormValues] = useState(initalvalue);

  // const [reportOption, setReportOption] = useState("day");
  // const handleReport = (e) => {
  //   const target = e.target;
  //   const value = target.value;
  //   setReportOption(value);
  // };

  const [nepaliDate, setNepaliDate] = useState("");
  const handleDate = ({ bsDate }) => {
    setNepaliDate({ ...nepaliDate, fromDate: bsDate });
  };

  // const [year, setYear] = useState("");
  // const handleYear = (e) => {
  //   const target = e.target;
  //   const value = target.value;
  //   setYear(value);
  // };

  // const [month, setMonth] = useState("");
  // const handleMonth = (e) => {
  //   const target = e.target;
  //   const value = target.value;
  //   setMonth(value);
  // };

  const searchInput = useRef("");

  // const [approveGmail, setApproveGmail] = useState(false);
  // const [rejectGmail, setRejectGmail] = useState(false);

  // //Everything to pop up and approve the  list
  // const [approvePopup, setApprovePopup] = useState({
  //   show: false, // initial values set to false and null
  //   data: null,
  // });

  // const [rejectPopup, setRejectPopup] = useState({
  //   show: false, // initial values set to false and null
  //   data: null,
  // });

  // // To approve the credit List

  // const [Acdata, setACData] = useState();
  // const [leaveAccess, setLeaveAccess] = useState();
  // const [acceptUserId, setAcceptUserId] = useState();

  // const handleApprove = async (data) => {
  //   setACData(data[0]);
  //   setLeaveAccess(data[1]);
  //   setAcceptUserId(data[2]);

  //   setApprovePopup({
  //     show: true,
  //     data,
  //   });
  // };

  // // This will perform the approve and hide the Confirmation Box

  // const handleApproveTrue = () => {
  //   const dataForm = {
  //     AuthCode: User.AuthCode,
  //     ComID: User.ComID,
  //     UserID: User.UID,
  //     StaffID: acceptUserId,
  //     Flag: "ULS",
  //     LeaveID: Acdata,
  //     LeaveStatus: "A",
  //   };

  //   const requestOptions = {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json", Signature: "p0m76" },
  //     body: JSON.stringify(dataForm),
  //   };
  //   fetch(`${appURL}api/admin/leave-report`, requestOptions).then((result) => {
  //     result.json().then((resp) => {

  //       if (resp.StatusCode === 200) {
  //         leaveList();
  //         toast.success(resp.Message, {
  //           style: {
  //             color: "green",
  //             fontSize: "13px",
  //           },
  //           theme: "light",
  //         });
  //       } else {
  //         toast.error(resp.Message, {
  //           style: {
  //             color: "red",
  //             fontSize: "13px",
  //           },
  //           theme: "light",
  //         });
  //       }
  //     });
  //   });
  //   setApprovePopup({
  //     show: false,
  //     data: null,
  //   });
  //   setApproveGmail(false);
  // };

  // // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  // const handleApproveFalse = () => {
  //   setApprovePopup({
  //     show: false,
  //     data: null,
  //   });
  //   setApproveGmail(false);
  // };

  //Everything to pop up and approve the leave list ends

  // To reject the leave List

  // const [bcdata, setBcData] = useState();
  // const [leavedata, setLeaveData] = useState();
  // const [userId, setUserId] = useState();

  // const handleReject = async (data) => {
  //   setBcData(data[0]);
  //   setLeaveData(data[1]);
  //   setUserId(data[2]);

  //   setRejectPopup({
  //     show: true,
  //     data,
  //   });
  // };

  // This will perform the deletion and hide the Confirmation Box

  // const handleRejectTrue = () => {
  //   const dataForm = {
  //     AuthCode: User.AuthCode,
  //     ComID: User.ComID,
  //     UserID: User.UID,
  //     Flag: "M",
  //     DFlag: "N",
  //     BranchID: "1",
  //     FiscalID: "1"
  //   };

  //   const requestOptions = {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json", Signature: "p0m76" },
  //     body: JSON.stringify(dataForm),
  //   };
  //   fetch(`${appURL}api/admin/leave-report`, requestOptions).then((result) => {
  //     result.json().then((resp) => {
  //       if (resp.StatusCode === 200) {
  //         leaveList();
  //         toast.success(resp.Message, {
  //           style: {
  //             color: "green",
  //             fontSize: "13px",
  //           },
  //           theme: "light",
  //         });
  //       } else {
  //         toast.error(resp.Message, {
  //           style: {
  //             color: "red",
  //             fontSize: "13px",
  //           },
  //           theme: "light",
  //         });
  //       }
  //     });
  //   });

  //   setRejectPopup({
  //     show: false,
  //     data: null,
  //   });
  //   setRejectGmail(false);
  // };

  //
  // };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  // const handleRejectFalse = () => {
  //   setRejectPopup({
  //     show: false,
  //     data: null,
  //   });
  //   setRejectGmail(false);
  // };

  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      grow: 0,
      center: true,
      width: "70px",
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },

    {
      name: mode === "en" ? "Month" : "पुरा नाम",
      // grow: 0,
      // center: true,
      // minWidth: "200px",
      selector: (row) => row.Month,
    },

    {
      name: mode === "en" ? "Half Day Type Days" : "आधा दिन प्रकारका दिनहरू",
      // grow: 0,
      // center: true,
      width: "200px",
      selector: (row) => row.HalfDayTypeDays,
    },
    {
      name: mode === "en" ? "Full Day Type Days" : "शीर्षक",
      // grow: 0,
      // center: true,
      width: "200px",
      selector: (row) => row.FullDayTypeDays,
    },

    {
      name: mode === "en" ? "Leave Type" : "बिदाको प्रकार",
      // center: true,
      // grow: 0,
      width: "200px",
      selector: (row) => row.LeaveType,
    },
    {
      name: mode === "en" ? "LeaveTypeDays" : "मिति देखि",
      // center: true,
      // grow: 0,
      width: "150px",
      selector: (row) => (mode === "en" ? row.LeaveTypeDays : "मिति देखि"),
    },
  ];

  // const handleView = (row) => {
  //   setViewPopup(true);
  //   setSelectedNote(row);
  // };

  // API to hit leave list

  //API to get staff list

  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    getsStaffLst();
  }, []);

  const getsStaffLst = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      Designation: "-1",
      BranchID: "1",
      WorkingStatus: "-1",
      IsActive: "-1",
      FiscalID: "1",
      Type: "POST",
      FetchURL: `${appURL}api/admin/user`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setStaffList(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setStaffList([]);
        setOriginalList([]);
        setLoading(false);
      }
    });
  };

  const [staffId, setStaffId] = useState("0");

  const selectStaffId = (event) => {
    setStaffId(event.target.value); // Update staffId with the selected value
  };

  // API to hit leave Report list

  const months = [
    "Baishakh",
    "Jestha",
    "Ashar",
    "Shrawan",
    "Bhadra",
    "Asoj",
    "Kartik",
    "Mangsir",
    "Poush",
    "Magh",
    "Falgun",
    "Chaitra",
  ];
  // const months = [
  //   mode === "en" ? "Baishakh" : "बैशाख",
  //   mode === "en" ? "Jestha" : "जेष्ठ",
  //   mode === "en" ? "Ashar" : "असार",
  //   mode === "en" ? "Shrawan" : "श्रावण",
  //   mode === "en" ? "Bhadra" : "भाद्र",
  //   mode === "en" ? "Asoj" : "असोज",
  //   mode === "en" ? "Kartik" : "कार्तिक",
  //   mode === "en" ? "Mangsir" : "मंसिर",
  //   mode === "en" ? "Poush" : "पौष",
  //   mode === "en" ? "Magh" : "माघ",
  //   mode === "en" ? "Falgun" : "फाल्गुन",
  //   mode === "en" ? "Chaitra" : "चैत्र",
  // ];

  useEffect(() => {
    leaveList();
  }, [staffId]);

  const leaveList = async () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: staffId,
      Flag: "M",
      DFlag: "N",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/leave-report`,
    };

    try {
      const response = await Fetchdata(params);

      if (response.StatusCode === 200) {
        const postResult = response.Values || [];
        setLeaveReport(postResult);

        // Transform the data
        const updatedLeaveReport = postResult.map((item) => {
          const extractedMonth = item.Month.split("/")[1];
          const monthNumber = parseInt(extractedMonth, 10);
          let monthName = "0"; // Default to "0" if not a valid month

          if (monthNumber >= 1 && monthNumber <= 12) {
            monthName = months[monthNumber - 1];
          }

          return {
            ...item,
            Month: monthName,
          };
        });

        setUpdatedLeaveReports(updatedLeaveReport);
        setOriginalList(postResult);
      } else {
        // Handle API error
        console.error("API Error:", response.Message);
        setLoading(false);
        setOriginalList([]);
        setLeaveReport([]);
        setUpdatedLeaveReports([]);
      }
    } catch (error) {
      // Handle network error or other unexpected issues
      console.error("An error occurred:", error);
      setLoading(false);
      setOriginalList([]);
      setLeaveReport([]);
      setUpdatedLeaveReports([]);
    }
  };

  // Extract unique leave types
  const uniqueLeaveTypes = Array.from(
    new Set(leaveReport.map((item) => item.LeaveType))
  );

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();

    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["StaffName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setLeaveReport(srchResult);
      } else {
        setLeaveReport({});
      }
    } else {
      setLeaveReport(originalList);
    }
  };

  const dataWithIndex = leaveReport.map((d, i) => ({
    ...d,
    Index: i + 1,
    FromDate: d.FromDate,
    ToDate: d.ToDate,
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Staff", field: "StaffName" },
    { header: "Title", field: "Title" },
    { header: "Cause", field: "Cause" },
    { header: "Day Type", field: "DayType" },
    { header: "Leave Type", field: "LeaveType" },
    { header: "From Date", field: "FromDate" },
    { header: "To Date", field: "ToDate" },
    { header: "Total Days", field: "TotalDays" },
    { header: "Is Field Work", field: "IsFieldWork" },
    { header: "Assigned To", field: "AssignedTo" },
    { header: "Is Verified", field: "LeaveVerified" },
    { header: "Verified By", field: "VerifiedBy" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    generatePdf(userDetails, exportColumns, dataWithIndex);

    // const doc = new jsPDF("l", "pt", "a4", true);
    // var midPage = doc.internal.pageSize.getWidth() / 2;

    // doc.addImage(`${userDetails.ComLogo}`, "JPG", 40, 40, 70, 60);

    // doc.setFontSize(18);
    // doc.text(`${userDetails.ComName}`, midPage, 50, null, null, "center");

    // doc.setFontSize(15);
    // doc.text(
    //   `${userDetails.ComAddress},${userDetails.ComBranch}`,
    //   midPage,
    //   68,
    //   null,
    //   null,
    //   "center"
    // );

    // doc.setFontSize(10);
    // doc.text(`Tel:${userDetails.ComTel}`, midPage, 85, null, null, "center");

    // doc.setFontSize(10);
    // doc.text(
    //   `${GetNepaliDate(nepaliDate.fromDate)}`,
    //   midPage,
    //   100,
    //   null,
    //   null,
    //   "center"
    // );

    // doc.autoTable({
    //   startY: 120,
    //   theme: "grid",
    //   // headStyles: {
    //   //   fillColor: [255, 255, 255],
    //   //   textColor: [0, 0, 0],
    //   // },
    //   columns: exportColumns,
    //   body: dataWithIndex,
    //   // tableWidth: "auto",
    //   // showHeader: "everyPage",
    //   // styles: { overflow: "linebreak", columnWidth: "wrap" },
    //   // columnStyles: { text: { columnWidth: "auto" } },
    // });
    // doc.save("Leave report.pdf");
  };

  const dataToExcel = leaveReport.map((d, i) => ({
    "S.N.": i + 1,
    Staff: d.StaffName,
    Title: d.Title,
    Cause: d.Cause,
    "Day Type": d.DayType,
    "Leave Type": d.LeaveType,
    "From Date": d.FromDate,
    "To Date": d.ToDate,
    "Total Days": d.TotalDays,
    "Is Field Work": d.IsFieldWork,
    "Assigned To": d.AssignedTo,
    "Is Verified": d.LeaveVerified,
    "Verified By": d.VerifiedBy,
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Leave report");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  // const addLeaveNote = (e) => {
  //   setLeaveNotePopup(true);
  //   setFormValues(initalvalue);
  //   setRe(Math.random());
  //   setformErrors({});
  // };
  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper ps-4 pe-4">
        <div className="row ">
          <div className="page-header">
            <div className="text-start  page-title">
              {mode === "en" ? "Leave Report" : "बिदाको नोट"}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <span className="me-2 ">Expires in: {expires} </span>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>

        <>
          <div className="sec-dataTable">
            <div>
              <div
                className="d-flex justify-content-between"
                style={{
                  marginBlock: "1.25rem",
                  backgroundColor: "#f4f8fb",
                  padding: "0",
                  width: "100%",
                }}
              >
                <div className="d-flex">
                  <NavLink
                    to="/leave-notes"
                    className="nav-link"
                    style={{
                      padding: "0.75rem 1rem",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    activeClassName="active"
                  >
                    Leave Note
                  </NavLink>
                  <NavLink
                    to="/leave-report"
                    className="nav-link"
                    style={{
                      padding: "0.75rem 1rem",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    activeClassName="active"
                  >
                    Leave Report
                  </NavLink>
                  <NavLink
                    to="/leave-balance"
                    className="nav-link"
                    style={{
                      padding: "0.75rem 1rem",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    activeClassName="active"
                  >
                    Leave Balance
                  </NavLink>
                </div>
              </div>
              <div className="upper-dataTbl">
                <div className="uk-flex uk-flex-wrap uk-flex-between uk-flex-middle mb-3">
                  <div className="uk-flex uk-flex-wrap me-5">
                    <div className="me-2 mt-3">
                      <label className="dark-gray d-block text-start">
                        {mode === "en" ? "Staff" : "कर्मचारी"}
                      </label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="status"
                        className="form-select form-select-sm"
                        aria-label="Default select example "
                        value={staffId}
                        onChange={selectStaffId}
                      >
                        <option
                          selected
                          disabled
                          value="0"
                          style={{ fontSize: "11px" }}
                        >
                          {mode === "en"
                            ? "Select Staff"
                            : "कर्मचारी चयन गर्नुहोस्"}
                        </option>

                        {staffList?.map((staffData) => {
                          return (
                            <option key={staffData.UID} value={staffData.UID}>
                              {staffData.FirstName + " " + staffData.LastName}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    {/* {reportOption === "day" && (
                      <div className=" me-2 mt-3">
                        <label
                          style={{
                            textAlign: "left",
                            display: "block",
                          }}
                          className="dark-gray"
                        >
                          {mode === "en" ? "Select Date" : "मिति चयन गर्नुहोस्"}
                        </label>
                        <Calendar
                          className="form-control form-control-sm mb-1"
                          dateFormat="YYYY/MM/DD"
                          theme="default"
                          language="en"
                          values={nepaliDate}
                          onChange={handleDate}
                        />
                      </div>
                    )} */}

                    {/* {reportOption === "month" && (
                      <>
                        <div className=" me-2 mt-3">
                          <label
                            style={{
                              textAlign: "left",
                              display: "block",
                            }}
                            className="dark-gray"
                          >
                            {mode === "en" ? "Year" : "वर्ष"}
                          </label>
                          <select
                            style={{ fontSize: "11px" }}
                            name="status"
                            className="form-select form-select-sm"
                            aria-label="Default select example "
                            value={year}
                            onChange={handleYear}
                          >
                            <option
                              selected
                              disabled
                              value=""
                              style={{ fontSize: "11px" }}
                            >
                              {mode === "en"
                                ? "Select Year"
                                : "वर्ष चयन गर्नुहोस्"}
                            </option>
                            <option value="2079">
                              {mode === "en" ? "2079" : "२०७९"}
                            </option>
                            <option value="2080">
                              {mode === "en" ? "2080" : "२०८०"}
                            </option>
                          </select>
                        </div>

                        <div className=" me-2 mt-3">
                          <label
                            style={{
                              textAlign: "left",
                              display: "block",
                            }}
                            className="dark-gray"
                          >
                            {mode === "en" ? "Month" : "महिना"}
                          </label>
                          <select
                            style={{ fontSize: "11px" }}
                            name="status"
                            className="form-select form-select-sm"
                            aria-label="Default select example "
                            value={month}
                            onChange={handleMonth}
                          >
                            <option
                              selected
                              disabled
                              value=""
                              style={{ fontSize: "11px" }}
                            >
                              {mode === "en"
                                ? "Select Month"
                                : "महिना चयन गर्नुहोस्"}
                            </option>
                            <option value="01">
                              {mode === "en" ? "Baishakh" : "बैशाख"}
                            </option>
                            <option value="02">
                              {mode === "en" ? "Jestha" : "जेष्ठ"}
                            </option>
                            <option value="03">
                              {mode === "en" ? "Ashar" : "असार"}
                            </option>
                            <option value="04">
                              {mode === "en" ? "Shrawan" : "श्रावण"}
                            </option>
                            <option value="05">
                              {mode === "en" ? "Bhadra" : "भाद्र"}
                            </option>
                            <option value="06">
                              {mode === "en" ? "Asoj" : "असोज"}
                            </option>
                            <option value="07">
                              {mode === "en" ? "Kartik" : "कार्तिक"}
                            </option>
                            <option value="08">
                              {mode === "en" ? "Mangsir" : "मंसिर"}
                            </option>
                            <option value="09">
                              {mode === "en" ? "Poush" : "पौष"}
                            </option>
                            <option value="10">
                              {mode === "en" ? "Magh" : "माघ"}
                            </option>
                            <option value="11">
                              {mode === "en" ? "Falgun" : "फाल्गुन"}
                            </option>
                            <option value="12">
                              {mode === "en" ? "Chaitra" : "चैत्र"}
                            </option>
                          </select>
                        </div>
                      </>
                    )} */}
                  </div>

                  <div className="">
                    <div className="d-flex uk-flex-wrap uk-flex-middle uk-flex-right">
                      {/* <div className="btn-addlnote mt-3">
                        <button
                          type="button"
                          class="btn btn-sm"
                          style={{
                            background: "var(--button-color)",
                            color: "white",
                          }}
                          onClick={addLeaveNote}
                        >
                          <GoPlus color="#fff" className="me-1" />
                          {mode === "en" ? "Add Leave" : "बिदा थप्नुहोस्"}
                        </button>
                      </div> */}
                      {/* <div className="export-btn">
                        <button
                          uk-tooltip="Export Excel"
                          onClick={toExcel}
                          className="me-1 ms-2 border-0"
                        >
                          <RiFileExcel2Fill size="1rem" color="#136438" />
                        </button>
                      </div> */}
                      <div className="export-btn" uk-tooltip="Export Excel">
                        <ReactHTMLTableToExcel
                          id="test-table-xls-button"
                          className="download-table-xls-button me-1 ms-2 border-0"
                          table="table-to-xls2"
                          filename="Leave Report"
                          sheet="tablexls"
                          buttonText={
                            <RiFileExcel2Fill size="1rem" color="#136438" />
                          }
                        />
                      </div>
                      {/* <div className="export-btn">
                        <button
                          uk-tooltip="Export Pdf"
                          onClick={toPdf}
                          className="mx-1 border-0"
                        >
                          <BsFileEarmarkPdfFill size="1rem" color="#ff0002" />
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ overflow: "auto" }}>
              {updatedLeaveReports.length > 0 ? (
                <table className="uk-table custom__table" id="table-to-xls2">
                  <tr>
                    <td
                      style={{ verticalAlign: "middle" }}
                      className="rdt_TableHeadRow tableHead text-center"
                      width="70px"
                      // rowSpan={2}
                    >
                      {mode === "en" ? "S.N." : "क्र.सं"}
                    </td>
                    <td
                      style={{ verticalAlign: "middle" }}
                      className="rdt_TableHeadRow tableHead"
                      width="200px"
                      // rowSpan={2}
                    >
                      {mode === "en" ? "Month" : "पुरा नाम"}
                    </td>
                    <td
                      style={{ verticalAlign: "middle" }}
                      className="rdt_TableHeadRow tableHead"
                      // rowSpan={2}
                    >
                      {mode === "en" ? "Half Day" : "आधा दिन"}
                    </td>
                    <td
                      style={{ verticalAlign: "middle" }}
                      className="rdt_TableHeadRow tableHead"
                      // rowSpan={2}
                    >
                      {mode === "en" ? "Full Day" : "पूर्ण दिन"}
                    </td>
                    {uniqueLeaveTypes.map((leaveType, index) => {
                      return (
                        <td key={index} className="rdt_TableHeadRow tableHead">
                          {leaveType}
                          <hr
                            style={{
                              height: "2px",
                              backgroundColor: "#fff",
                              margin: "10px 0px",
                            }}
                          />
                          Leave Type
                        </td>
                      );
                    })}
                  </tr>

                  {/* <tr>
                    <td colSpan={uniqueLeaveTypes.length} className="rdt_TableHeadRow tableHead text-center">Leave Type</td>
                  </tr> */}

                  {/* Map over your data and create rows here  */}
                  {months.map((month, index) => {
                    // Find the data for the current month
                    const rowData = updatedLeaveReports.find((item) =>
                      item.Month.includes(month)
                    );

                    return (
                      <tr key={index}>
                        <td className="rdt_TableCell tablecell  text-center">
                          {index + 1}
                        </td>
                        <td className="rdt_TableCell tablecell ">{month}</td>
                        <td className="rdt_TableCell tablecell ">
                          {rowData ? rowData.HalfDayTypeDays : "0"}
                        </td>
                        <td className="rdt_TableCell tablecell ">
                          {rowData ? rowData.FullDayTypeDays : "0"}
                        </td>
                        {uniqueLeaveTypes.map((leaveType, leaveIndex) => {
                          // Find data for the current leave type and month
                          const leaveTypeData = updatedLeaveReports.find(
                            (item) =>
                              item.LeaveType === leaveType &&
                              item.Month.includes(month)
                          );

                          return (
                            <td
                              className="rdt_TableCell tablecell "
                              key={leaveIndex}
                            >
                              {leaveTypeData
                                ? leaveTypeData.LeaveTypeDays
                                : "0"}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </table>
              ) : (
                <span className="d-block text-center mt-3 d-block">
                  {" "}
                  {mode === "en" ? "No Data" : "डाटा उपलब्ध छैन"}
                </span>
              )}
            </div>

            {/* <table className="table table-striped table-bordered">
              <thead>
                <tr style={{ textAlign: "center" }}>
                  <th scope="col">
                    {mode === "en" ? "S.N." : "क्र.सं"}
                  </th>
                  <th scope="col">
                    {mode === "en" ? "Month" : "पुरा नाम"}
                  </th>
                  <th scope="col">
                    {mode === "en" ? "Half Day Type Days" : "आधा दिन प्रकारका दिनहरू"}
                  </th>
                  <th scope="col">
                    {mode === "en" ? "Full Day Type Days" : "पूर्ण दिन प्रकारका दिनहरू"}
                  </th>
                  {updatedLeaveReports.map((data, i) => (
                    <th key={i} scope="col">
                      {data.LeaveType}
                      <hr style={{ height: "2px", backgroundColor: "#000000", margin: "10px 0px" }} />
                      LeaveTypeDays
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                Map over your data and create rows here
                {updatedLeaveReports.map((rowData, rowIndex) => (
                  <tr key={rowIndex}>
                    <td>{rowIndex + 1}</td>
                    <td>{rowData.Month}</td>
                    <td>{rowData.HalfDayTypeDays}</td>
                    <td>{rowData.FullDayTypeDays}</td>
                    {updatedLeaveReports.map((data, i) => (
                      <td key={i}>
                        {data.Month === rowData.Month && data.LeaveType === rowData.LeaveType
                          ? data.LeaveTypeDays
                          : 0}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table> */}

            {/* <DataTable
              columns={columns}
              data={updatedLeaveReports}
              customStyles={customStyles}
              pagination
              paginationRowsPerPageOptions={[10, 20, 50, 100]} // Customizable options
              fixedHeader
              fixedHeaderScrollHeight="410px"
              data-tag="allowRowEvents"
              highlightOnHover
              pointerOnHover
              responsive
              dense
              striped
              subHeader
              subHeaderComponent={
                <>
                  <div className=" w-100">
                    <div className="d-flex uk-flex-middle justify-content-end">
                      <div>
                        <div class="uk-search uk-search-default">
                          <AiOutlineSearch className="search-icon" />
                          <input
                            placeholder={
                              mode === "en" ? "Search" : "खोजी गर्नुहोस्"
                            }
                            ref={searchInput}
                            type="text"
                            className="form-control form-control-sm searchField"
                            onChange={searchHandler}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
            /> */}
          </div>
        </>
      </div>
      {/* {approvePopup.show && (
        <ApprovePop
          handleApproveTrue={handleApproveTrue}
          handleApproveFalse={handleApproveFalse}
          approveGmail={approveGmail}
          setApproveGmail={setApproveGmail}
        />
      )} */}
      {/* 
      {rejectPopup.show && (
        <RejectPop
          handleRejectTrue={handleRejectTrue}
          handleRejectFalse={handleRejectFalse}
          rejectGmail={rejectGmail}
          setRejectGmail={setRejectGmail}
        />
      )} */}

      {selected_note && (
        <ViewLeaveReport
          note={selected_note}
          viewPopup={viewPopup}
          setViewPopup={setViewPopup}
        />
      )}

      <LeaveReportPop
        setLeaveNotePopup={setLeaveNotePopup}
        leaveNotePopup={leaveNotePopup}
        reload={reload}
        setReload={setReload}
        DFlag={DFlag}
        initalvalue={initalvalue}
        setFormValues={setFormValues}
        formValues={formValues}
        setformErrors={setformErrors}
        formErrors={formErrors}
        re={re}
        leaveList={leaveList}
      />
    </>
  );
}
