import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import AuthContext from "../../../context/auth-context";
import UpperbarContext from "../../../context/upperbar-context";
import Toast from "../../../Toast";
import { GoPlus } from "react-icons/go";
import {
  AiOutlineSearch,
  AiOutlineEdit,
  AiTwotonePrinter,
} from "react-icons/ai";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { Fetchdata } from "../../../hooks/getData";
import Spinner from "../../../loading/spinner";
import "../../entry/voucher/voucher.css";
import { ToastContainer, toast } from "react-toastify";
import {
  GetCurrMonth,
  GetCurrYear,
  GetNepaliDate,
} from "../../../hooks/dateConvertor";
import NepaliDate from "nepali-date-converter";
import { englishToNepaliNumber } from "nepali-number";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { MdOutlineDelete } from "react-icons/md";
import ConfirmPopup from "../../../hooks/confirmPopup";
import ConfirmDetetePopup from "../../../hooks/confirmDeletePopup";

export default function GenerateSalary() {
  const { User } = useContext(AuthContext);

  //   const searchInput = useRef("");
  const {
    fiscalYear,
    todayDate,
    customStyles,
    appURL,
    userDetails,
    darkText,
    mode,
  } = useContext(UpperbarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const cur_year = new Date().getFullYear();
  const eYears = generateArrayOfYears(cur_year);
  const nYears = generateArrayOfNepYears(cur_year);

  const initalvalue = {
    // year: mode === "en" ? cur_year : GetCurrYear(),
    year: GetCurrYear(),
    month: GetCurrMonth(),
  };
  const [formValues, setFormValues] = useState(initalvalue);
  useEffect(() => {
    setFormValues(initalvalue);
  }, [mode]);
  function getNepaliDate(date) {
    const nepDate = new NepaliDate().getBS();
    //
    return nepDate;
  }
  function generateArrayOfYears(cur_year) {
    var max = cur_year;
    var min = 2022;
    var years = [];

    for (var i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  }

  function generateArrayOfNepYears(cur_year) {
    let nCur_date = getNepaliDate(cur_year);
    var max = nCur_date.year;
    var min = 2079;
    var years = [];

    // for (var i = max; i >= min; i--) {
    //   years.push(i);
    // }
    for (var i = min; i <= max; i++) {
      years.push(i);
    }
    return years;
  }

  const handleOnChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  //  API to show list
  const [staffData, setStaffData] = useState({});
  useEffect(() => {
    staffLst();
  }, [formValues.year, formValues.month, mode]);

  const staffLst = () => {
    const UserID = "" + User.UID;
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;

    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      CID: "1",
      Flag: "S",
      DFlag: "-1",
      // MonthEn: mode === "en" ? `${formValues.year}-${formValues.month}` : "",
      // MonthNp: mode === "np" ? `${formValues.year}/${formValues.month}` : "",
      MonthEn: "",
      MonthNp: `${formValues.year}/${formValues.month}`,
      BranchID: Branch,
      FiscalID: Fiscal,
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/payroll-generate`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setOriginalList(postResult);
        setLoading(false);

        const groupedByName = postResult.reduce((acc, curr) => {
          if (acc[curr.FullName]) {
            acc[curr.FullName].push(curr);
          } else {
            acc[curr.FullName] = [curr];
          }
          return acc;
        }, {});

        // create newStaffList from groupedByName
        const newStaffList = Object.keys(groupedByName).map((name) => {
          const designation = groupedByName[name][0].Designation; // assuming all objects with the same name have the same designation
          return {
            FullName: name,
            Designation: designation,
            data: groupedByName[name],
          };
        });

        const uniqueHead = [...new Set(postResult.map((item) => item.Head))];

        // create staffData object
        const newData = {};
        newStaffList.forEach((item) => {
          newData[item.FullName] = {};
          newData[item.FullName]["Designation"] = item.data[0].Designation;

          uniqueHead.forEach((head) => {
            const data = item.data.find((d) => d.Head === head);

            if (data) {
              newData[item.FullName][head] = {
                Amount: data.Amount === "NaN" ? "0" : data.Amount,
                StaffID: data.StaffID,
                HeadID: data.HeadID,
                HeadType: data.HeadType,
                MaritalStatus: data.MaritalStatus,
                IsTax: data.IsTax,
                IsProvident: data.IsProvident,
                IsAbsent: data.IsAbsent,
                ProvFund: data.ProvFund,
                DayCt: data.DayCt,
                AbsentCt: data.AbsentCt,
              };
            } else {
              newData[item.FullName][head] = null;
            }
          });
        });

        // --------------

        setStaffData(newData);
      } else {
        setStaffData({});
        setLoading(false);
      }
    });
  };

  //   API to get tax slab
  const [taxSlabList, setTaxSlabList] = useState([]);

  useEffect(() => {
    taxslablst();
  }, []);

  const taxslablst = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      DFlag: "TAXSLAB",
      Flag: "S",
      CID: "1",
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/ref-values`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setTaxSlabList(postResult);
      } else {
        setTaxSlabList([]);
      }
    });
  };

  const filteredTaxData = Object.values(staffData).flatMap((staff) =>
    Object.entries(staff)
      .filter(
        ([, details]) =>
          details && details.HeadType === "A" && details.IsTax === "Y"
      )
      .map(([head, details]) => ({
        StaffID: details.StaffID,
        HeadType: details.HeadType,
        IsTax: details.IsTax,
        IsProvident: details.IsProvident,
        IsAbsent: details.IsAbsent,
        ProvFund: details.ProvFund,
        MaritalStatus: details.MaritalStatus,
        AbsentCt: details.AbsentCt,
        DayCt: details.DayCt,
        Head: head,
        Amount: details.Amount === "NaN" ? "0" : Number(details.Amount),
      }))
  );

  const filteredTaxTotal = filteredTaxData.reduce(
    (acc, { StaffID, Amount }) => {
      if (!acc[StaffID]) {
        acc[StaffID] = 0;
      }
      acc[StaffID] += Amount;
      return acc;
    },
    {}
  );

  const absentAmountSum = Object.values(staffData)
    .flatMap((staff) =>
      Object.entries(staff)
        .filter(
          ([, details]) =>
            details && details.HeadType === "A" && details.IsAbsent === "Y"
        )
        .map(([head, details]) => Number(details.Amount))
    )
    .reduce((sum, amount) => sum + amount, 0);

  // Loop through staffData and update Amount property for each staffID
  const updatedStaffData = { ...staffData };
  for (const staffName in updatedStaffData) {
    const staff = updatedStaffData[staffName];
    for (const head in staff) {
      const details = staff[head];
      // if (details && details.HeadType === "R" && details.IsTax === "Y") {
      //   const staffID = details.StaffID;
      //   const amt = filteredTaxTotal[staffID];

      //   if (details.IsProvident === "Y" && details.HeadType === "A") {
      //     const basicSalary = parseFloat(staffData[staffName].BasicSalary);
      //     const provFundPercentage = parseFloat(details.ProvFund) / 100;
      //     details.Amount = (basicSalary * provFundPercentage).toFixed(2);
      //   }
      //   else {
      //     details.Amount = amt.toString() || 0;
      //   }
      // }
      if (details) {
        if (details.HeadType === "R" && details.IsTax === "Y") {
          const staffID = details.StaffID;
          const amt = filteredTaxTotal[staffID] || "";
          details.Amount = amt.toString() || 0;
        }
        // if (details.ProvFund !== undefined) {
        //   if (details.IsProvident === "Y" && details.HeadType === "A") {
        //     const basicSalary = parseFloat(
        //       updatedStaffData[staffName]["Basic salary"].Amount
        //     );
        //     const provFundPercentage = parseFloat(details.ProvFund) || 0;
        //     const provFundAmount = (basicSalary * provFundPercentage) / 100;
        //     details.Amount = provFundAmount.toFixed(2);
        //   }
        // }
        // if (details.ProvFund !== undefined) {
        //   if (details.HeadType === "R" && details.IsProvident === "Y") {
        //     const provFundAmount =
        //       parseFloat(updatedStaffData[staffName]["Basic salary"].Amount) *
        //       (parseFloat(details.ProvFund) / 100);
        //     const updatedAmount = 2 * provFundAmount;
        //     details.Amount = updatedAmount.toFixed(2);
        //   }
        // }
        // if (details.HeadType === "A" && details.IsAbsent === "Y") {
        //   const amount = parseFloat(details.Amount);
        //   absentAmountSum += amount;
        // }
        if (details.AbsentCt !== undefined) {
          if (details.HeadType === "R" && details.IsAbsent === "Y") {
            const DayCt = parseFloat(details.DayCt);
            const AbsentCt = parseFloat(details.AbsentCt);

            const divideAmt = absentAmountSum / DayCt;

            const TotalAmt = divideAmt * AbsentCt;

            details.Amount = TotalAmt.toFixed(2);
          }
        }
      }
    }
  }

  useEffect(() => {
    // Set the updated staffData object
    setStaffData(updatedStaffData);
  }, []);

  const allStaffData = Object.values(staffData)
    .flatMap((staff) => Object.entries(staff))
    .filter(([key, value]) => key !== "Designation")
    .map(([key, value]) => value);

  const keysToRemove = ["IsTax"];
  const finalValue = allStaffData.map((obj) => {
    const newObj = { ...obj };
    keysToRemove.forEach((key) => delete newObj[key]);
    return newObj;
  });

  let totAmt = allStaffData.reduce(function (prev, current) {
    return prev + +current.Amount;
  }, 0);

  let totalAmt = parseFloat(totAmt);

  const handleInputChange = (fullName, head, fieldName, fieldValue) => {
    setStaffData((prevData) => {
      const isTaxField =
        prevData[fullName][head]?.HeadType === "A" &&
        prevData[fullName][head]?.IsTax === "Y";
      const updatedData = {
        ...prevData,
        [fullName]: {
          ...prevData[fullName],
          [head]: {
            ...prevData[fullName][head],
            [fieldName]: fieldValue,
          },
        },
      };

      if (isTaxField) {
        const filteredTaxData = Object.values(updatedData[fullName]).flatMap(
          (details) =>
            details && details.HeadType === "A" && details.IsTax === "Y"
              ? [details]
              : []
        );

        const filteredTaxTotal = filteredTaxData.reduce(
          (acc, { StaffID, Amount }) => {
            if (!acc[StaffID]) {
              acc[StaffID] = 0;
            }
            acc[StaffID] += Amount;
            return acc;
          },
          {}
        );

        for (const currHead in updatedData[fullName]) {
          const currDetails = updatedData[fullName][currHead];
          if (
            currDetails &&
            currDetails.HeadType === "R" &&
            currDetails.IsTax === "Y"
          ) {
            const staffID = currDetails.StaffID;
            const amt = filteredTaxTotal[staffID];
            currDetails.Amount = amt ? amt.toString() : "0";
          }
        }
      }

      return updatedData;
    });
  };

  const [isSubmit, setIsSubmit] = useState(false);

  const handleSubmit = () => {
    setIsSubmit(true);
  };

  useEffect(() => {
    const UserID = "" + User.UID;
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;

    if (isSubmit) {
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: UserID,
        CID: "1",
        Flag: "i",
        DFlag: "-1",
        // MonthEn: mode === "en" ? `${formValues.year}-${formValues.month}` : "",
        // MonthNp: mode === "np" ? `${formValues.year}/${formValues.month}` : "",
        MonthNp: `${formValues.year}/${formValues.month}`,
        Values: allStaffData,
        BranchID: Branch,
        FiscalID: Fiscal,
        Type: "POST",
        FetchURL: `${appURL}api/acc/admin/payroll-generate`,
      };

      Fetchdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          staffLst();
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });
      setIsSubmit(false);
    }
  }, [isSubmit]);

  // --- generate ----

  const handleGenerate = () => {
    const UserID = "" + User.UID;
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      CID: "1",
      Flag: "V",
      DFlag: "-1",
      MonthNp: `${formValues.year}/${formValues.month}`,
      // MonthNp: mode === "np" ? `${formValues.year}/${formValues.month}` : "",
      BranchID: Branch,
      FiscalID: Fiscal,
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/payroll-generate`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        staffLst();
        toast.success(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  // --- reverse ----

  const handleReverse = () => {
    const UserID = "" + User.UID;
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      CID: "1",
      Flag: "RV",
      DFlag: "-1",
      // MonthNp: mode === "np" ? `${formValues.year}/${formValues.month}` : "",
      MonthNp: `${formValues.year}/${formValues.month}`,
      BranchID: Branch,
      FiscalID: Fiscal,
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/payroll-generate`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        staffLst();
        toast.success(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  // ------------------
  const searchInput = useRef("");

  const searchHandler = (e) => {
    e.preventDefault();

    const searchQuery = searchInput.current.value.toLowerCase();
    if (searchQuery) {
      const filteredData = Object.entries(staffData).filter(([key, value]) =>
        key.toLowerCase().includes(searchQuery)
      );
      const newData = Object.fromEntries(filteredData);
      setStaffData(newData);
    } else {
      staffLst();
    }
  };

  const handleKeyPress = (event) => {
    // Check if the key pressed is not a number

    if (isNaN(Number(event.key))) {
      event.preventDefault(); // Prevent the character from being entered
    } // Prevent the character from being entered
  };

  const [confirmPopup, setConfirmPopup] = useState(false);
  const [selected_note, setSelectedNote] = useState("");
  const updateRequest = () => {
    // setSelectedNote(row);
    setConfirmPopup(true);
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper ps-4 pe-4">
        <div className="row ">
          <div className="page-header">
            <div className="text-start  page-title" style={darkText}>
              {mode === "en" ? "Generate Salary" : "तलब उत्पन्न गर्नुहोस्"}
            </div>
            <div className="page-date">
              <div className="sec-content" style={darkText}>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>

        <>
          <div className="sec-dataTable">
            <div className="upper-dataTbl pb-2">
              <div className="uk-flex  uk-flex-wrap uk-flex-between uk-flex-middle">
                <div className="uk-flex uk-flex-wrap uk-flex-bottom me-3">
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en" ? "Select Month" : "महिना चयन गर्नुहोस्"}
                    </label>
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example "
                      onChange={handleOnChange}
                      name="month"
                      value={formValues.month}
                    >
                      <option disabled value="" selected>
                        {mode === "en" ? "Select Month" : "महिना चयन गर्नुहोस्"}
                      </option>
                      {/* {mode === "np" ? (
                        <>
                          <option value="01">बैशाख</option>
                          <option value="02">जेष्ठ</option>
                          <option value="03">असार</option>
                          <option value="04">श्रावण</option>
                          <option value="05">भाद्र</option>
                          <option value="06">असोज</option>
                          <option value="07">कार्तिक</option>
                          <option value="08">मंसिर</option>
                          <option value="09">पौष</option>
                          <option value="10">माघ</option>
                          <option value="11">फाल्गुन</option>
                          <option value="12">चैत्र</option>
                        </>
                      ) : (
                        <>
                          <option value="01">January</option>
                          <option value="02">February</option>
                          <option value="03">March</option>
                          <option value="04">April</option>
                          <option value="05">May</option>
                          <option value="06">June</option>
                          <option value="07">July</option>
                          <option value="08">August</option>
                          <option value="09">September</option>
                          <option value="10">October</option>
                          <option value="11">November</option>
                          <option value="12">December</option>
                        </>
                      )} */}
                      {
                        <>
                          <option value="01">Baishakh</option>
                          <option value="02">Jestha</option>
                          <option value="03">Ashadh</option>
                          <option value="04">Shrawan</option>
                          <option value="05">Bhadra</option>
                          <option value="06">Ashwin</option>
                          <option value="07">Kartik</option>
                          <option value="08">Mangsir</option>
                          <option value="09">Poush</option>
                          <option value="10">Magh</option>
                          <option value="11">Falgun</option>
                          <option value="12">Chaitra</option>
                        </>
                      }
                    </select>
                  </div>
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en" ? "Select Year" : "वर्ष चयन गर्नुहोस्"}
                    </label>
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example "
                      onChange={handleOnChange}
                      name="year"
                      value={formValues.year}
                    >
                      <option disabled value="" selected>
                        {mode === "en" ? "Select Year" : "वर्ष चयन गर्नुहोस्"}
                      </option>
                      {/* {mode === "np"
                        ? nYears.map((list) => (
                            <>
                              <option key={list} value={list}>
                                {list}
                              </option>
                            </>
                          ))
                        : eYears.map((list) => (
                            <>
                              <option key={list} value={list}>
                                {list}
                              </option>
                            </>
                          ))} */}
                      {nYears.map((list) => (
                        <>
                          <option key={list} value={list}>
                            {list}
                          </option>
                        </>
                      ))}
                    </select>
                  </div>
                  <div className="me-2 mt-3">
                    <button
                      type="button"
                      class="btn btn-sm"
                      style={{
                        background: "var(--button-color)",
                        color: "white",
                      }}
                      onClick={handleSubmit}
                    >
                      {mode === "en" ? "Save" : "बुझाउनुहोस्"}
                    </button>
                  </div>
                  <div className="me-2 mt-3">
                    <button
                      type="button"
                      class="btn btn-sm"
                      style={{
                        background: "var(--button-color)",
                        color: "white",
                      }}
                      onClick={handleGenerate}
                    >
                      {mode === "en"
                        ? "Generate Voucher"
                        : "वाउचर उत्पन्न गर्नुहोस्"}
                    </button>
                  </div>
                  <div className="me-2 mt-3">
                    <button
                      type="button"
                      class="btn btn-sm"
                      style={{
                        background: "var(--button-color)",
                        color: "white",
                      }}
                      onClick={handleReverse}
                    >
                      {mode === "en"
                        ? "Reverse Voucher"
                        : "वाउचर उल्टो गर्नुहोस्"}
                    </button>
                  </div>
                  <div className="me-2 mt-3">
                    <div className="ln-verition d-flex uk-flex-center">
                      <button
                        type="button"
                        class="btn btn-sm deletespan mx-1"
                        onClick={() => updateRequest()}
                        uk-tooltip={mode === "en" ? "Delete" : "हटाउनुहोस्"}
                      >
                        <MdOutlineDelete />
                      </button>{" "}
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="d-flex uk-flex-middle uk-flex-right">
                    <div className="btn-addlnote mt-3">
                      {/* <button
                      type="button"
                      class="btn btn-sm"
                      style={{
                        background: "var(--button-color)",
                        color: "white",
                      }}
                      //   onClick={handleAdd}
                    >
                      <GoPlus color="#fff" className="me-1" />
                      Add Area
                    </button> */}
                    </div>
                    <div className="export-btn">
                      <button
                        uk-tooltip="Export Excel"
                        // onClick={toExcel}
                        className="me-1 ms-2 border-0"
                      >
                        <RiFileExcel2Fill size="1rem" color="#136438" />
                      </button>
                    </div>
                    <div className="export-btn">
                      <button
                        uk-tooltip="Export Pdf"
                        // onClick={toPdf}
                        className="mx-1 border-0"
                      >
                        <BsFileEarmarkPdfFill size="1rem" color="#ff0002" />
                      </button>
                    </div>
                    <div className="export-btn">
                      <button
                        uk-tooltip="Print"
                        // onClick={toPrint}
                        className="mx-1 border-0"
                      >
                        <AiTwotonePrinter size="1rem" color="#555" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" w-100 mb-3 mt-3">
              <div className="d-flex uk-flex-middle justify-content-end">
                <div>
                  <form class="uk-search uk-search-default">
                    <AiOutlineSearch className="search-icon" />
                    <input
                      placeholder={mode === "en" ? "Search" : "खोजी गर्नुहोस्"}
                      ref={searchInput}
                      type="text"
                      className="form-control form-control-sm searchField"
                      onChange={searchHandler}
                    />
                  </form>
                </div>
              </div>
            </div>

            <div
              className="mt-3 mt-3 tableHeight show-scrollbar"
              style={{ maxHeight: "400px" }}
            >
              {loading ? (
                <Spinner />
              ) : (
                <>
                  {Object.keys(staffData).length !== 0 ? (
                    <table className="uk-table reportTable voucher-table">
                      <thead>
                        <tr>
                          <td
                            className="rdt_TableHeadRow tableHead text-center"
                            width="70px"
                          >
                            {mode === "en" ? "S.N." : "क्र.सं"}
                          </td>
                          <td
                            className="rdt_TableHeadRow tableHead sticky-left"
                            width="200px"
                          >
                            {mode === "en" ? "Name" : "नाम"}
                          </td>
                          {Object.keys(staffData)
                            .reduce((acc, curr) => {
                              Object.keys(staffData[curr]).forEach((head) => {
                                if (!acc.includes(head)) {
                                  acc.push(head);
                                }
                              });
                              return acc;
                            }, [])
                            .map((head) => {
                              const isArrowUp = Object.keys(staffData).some(
                                (user) => staffData[user][head].HeadType === "A"
                              );
                              const isArrowDown = Object.keys(staffData).some(
                                (user) => staffData[user][head].HeadType === "R"
                              );
                              return (
                                <td
                                  className="rdt_TableHeadRow tableHead"
                                  key={head}
                                >
                                  {head}{" "}
                                  {isArrowUp ? (
                                    <FaArrowUp />
                                  ) : isArrowDown ? (
                                    <FaArrowDown />
                                  ) : null}
                                </td>
                              );
                            })}
                          <td
                            className="rdt_TableHeadRow tableHead  text-end sticky-right"
                            width="150px"
                          >
                            {mode === "en" ? "Total" : "कुल"}
                          </td>
                        </tr>
                      </thead>

                      <tbody>
                        {Object.keys(staffData).map((fullName, i) => {
                          let rowTotal = 0;
                          let totalHeadTypeA = 0;
                          let totalHeadTypeR = 0;
                          return (
                            <tr key={fullName}>
                              <td
                                style={{ minWidth: "70px" }}
                                className="rdt_TableCell tablecell text-center"
                              >
                                {mode === "en"
                                  ? i + 1
                                  : englishToNepaliNumber(i + 1)}
                              </td>

                              <td
                                style={{ minWidth: "200px" }}
                                className="rdt_TableCell tablecell text-start sticky-left"
                              >
                                {fullName}
                              </td>

                              <td
                                style={{ minWidth: "200px" }}
                                className="rdt_TableCell tablecell text-start"
                              >
                                {staffData[fullName].Designation}
                              </td>

                              {Object.keys(staffData[fullName]).map((head) => {
                                if (head === "Designation") {
                                  return null;
                                }
                                const data = staffData[fullName][head];

                                if (data) {
                                  // rowTotal += parseFloat(data.Amount);

                                  if (
                                    data &&
                                    data.IsTax === "Y" &&
                                    data.HeadType === "R"
                                  ) {
                                    const taxableAmount = parseFloat(
                                      data.Amount
                                    );
                                    let slabAmount = 0;
                                    let taxAmount = 0;
                                    let remainingAmount = taxableAmount;

                                    for (
                                      let i = 0;
                                      i < taxSlabList.length;
                                      i++
                                    ) {
                                      const taxGroup = taxSlabList[i];
                                      const incomeFrom = parseFloat(
                                        taxGroup.IncomeFrom
                                      );
                                      const incomeTo = parseFloat(
                                        taxGroup.IncomeTo
                                      );
                                      const isMarried =
                                        taxGroup.isMarried === "Y";
                                      const taxRate = parseFloat(
                                        taxGroup.TaxRate
                                      );

                                      if (taxableAmount >= incomeFrom) {
                                        if (
                                          remainingAmount >=
                                          incomeTo - incomeFrom
                                        ) {
                                          // const slabAmount =
                                          //   incomeTo - incomeFrom;

                                          slabAmount =
                                            i === 0
                                              ? taxSlabList[0].IncomeTo
                                              : incomeTo -
                                                taxSlabList[i - 1].IncomeTo;

                                          taxAmount +=
                                            (slabAmount * taxRate) / 100;
                                          remainingAmount -= slabAmount;
                                        } else {
                                          taxAmount +=
                                            (remainingAmount * taxRate) / 100;
                                          remainingAmount = 0;

                                          break;
                                        }
                                      }
                                    }
                                    // rowTotal -= data.Amount;
                                    // totalHeadTypeR += parseFloat(taxAmount);
                                    // rowTotal -= totalHeadTypeR;
                                    // rowTotal += taxAmount;
                                    data.Amount = taxAmount.toFixed(2);
                                  }
                                }

                                if (data.HeadType === "A") {
                                  totalHeadTypeA += parseFloat(data.Amount);
                                }

                                if (data.HeadType === "R") {
                                  totalHeadTypeR += parseFloat(data.Amount);
                                }
                                rowTotal = totalHeadTypeA - totalHeadTypeR;

                                const amt =
                                  data.Amount === "NaN"
                                    ? 0
                                    : parseFloat(data.Amount);

                                const isTaxable =
                                  data?.IsTax === "Y" && data?.HeadType === "R";

                                const isAbsent =
                                  data?.IsAbsent === "Y" &&
                                  data?.HeadType === "R";

                                const isProvident = data?.IsProvident === "Y";

                                const isDisabled =
                                  isProvident || isTaxable || isAbsent;
                                return (
                                  <td
                                    style={{ minWidth: "150px" }}
                                    className="rdt_TableCell tablecell voucherCell text-end"
                                    key={head}
                                  >
                                    <input
                                      type="text"
                                      className="form-control form-control-sm voucher-input text-end"
                                      name="Amount"
                                      value={
                                        data && amt.toFixed(2)
                                          ? amt.toFixed(2)
                                          : 0
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          fullName,
                                          head,
                                          "Amount",
                                          e.target.value
                                        )
                                      }
                                      onKeyPress={handleKeyPress}
                                      disabled
                                      // disabled={isDisabled}
                                    />
                                  </td>
                                );
                              })}

                              <td
                                style={{
                                  minWidth: "150px",
                                }}
                                className="rdt_TableCell tablecell voucherCell reportTotal sticky-right text-end fw-bold"
                              >
                                {rowTotal.toFixed(2)}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>

                      <tfoot style={{ position: "sticky", bottom: "-1px" }}>
                        <tr className="reportTotal">
                          <td
                            colSpan="3"
                            className="rdt_TableCell tablecell text-end fw-bold"
                          >
                            {mode === "en" ? "Total" : "कुल"}
                          </td>

                          {Object.keys(staffData)
                            .reduce((acc, curr) => {
                              Object.keys(staffData[curr]).forEach((head) => {
                                if (!acc.includes(head)) {
                                  acc.push(head);
                                }
                              });
                              return acc;
                            }, [])
                            .map((head) => {
                              if (head === "Designation") {
                                return null;
                              }
                              let headTotal = 0;
                              Object.keys(staffData).forEach((fullName) => {
                                const data = staffData[fullName][head];
                                if (data) {
                                  headTotal += parseFloat(data.Amount);
                                }
                              });
                              return (
                                <td
                                  className="rdt_TableCell tablecell text-end fw-bold"
                                  key={head}
                                >
                                  {headTotal.toFixed(2)}
                                </td>
                              );
                            })}

                          <td
                            style={{ background: "#f8f8ff" }}
                            className="rdt_TableCell tablecell text-end sticky-right fw-bold"
                          >
                            {Object.keys(staffData)
                              .reduce((acc, curr) => {
                                Object.keys(staffData[curr]).forEach((head) => {
                                  if (
                                    !acc.includes(head) &&
                                    head !== "Designation"
                                  ) {
                                    acc.push(head);
                                  }
                                });
                                return acc;
                              }, [])
                              .reduce((total, head) => {
                                let headTotal = 0;
                                let headTotalA = 0;
                                let headTotalR = 0;
                                Object.keys(staffData).forEach((fullName) => {
                                  const data = staffData[fullName][head];
                                  if (data.HeadType === "A") {
                                    headTotalA += parseFloat(data.Amount);
                                  }
                                  if (data.HeadType === "R") {
                                    headTotalR += parseFloat(data.Amount);
                                  }
                                  headTotal = headTotalA - headTotalR;
                                });
                                return total + headTotal;
                              }, 0)
                              .toFixed(2)}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  ) : (
                    <p className="text-center">
                      {mode === "en" ? "No Data" : "डाटा उपलब्ध छैन"}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      </div>

      {confirmPopup &&
        ConfirmDetetePopup({
          FormData: {
            AuthCode: User.AuthCode,
            ComID: User.ComID,
            UserID: User.UID.toString(),
            CID: "1",
            Flag: "D",
            MonthNp: `${formValues.year}/${formValues.month}`,
            FetchURL: `${appURL}api/acc/admin/payroll-generate`,
            Type: "POST",
          },

          setTrigger: setConfirmPopup,
          message:
            mode === "en"
              ? "Are you sure you want to delete ?"
              : " के तपाइँ मेटाउन चाहनुहुन्छ",
        })}
    </>
  );
}
