import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "../../images/CloseIcon.svg";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import DataTable from "react-data-table-component";
import { Fetchdata } from "../hooks/getData";
import AuthContext from "../context/auth-context";
import NepaliDate from "nepali-date-converter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { englishToNepaliNumber } from "nepali-number";
import UpperbarContext from "../context/upperbar-context";
import $ from "jquery";
import Spinner from "../loading/spinner";
import { AiOutlineEdit } from "react-icons/ai";

export default function CategoryPop({ setCategoryPopUp, categoryPopUp }) {
  const { User } = useContext(AuthContext);
  const { appURL, sidePanelBg, mainBg, mode, customStyles } =
    useContext(UpperbarContext);

  useEffect(() => {
    if (categoryPopUp) {
      $(".addCategoryPopBg").fadeIn(500);
      $(".addCategoryPop").slideDown(500);
    }
  }, [categoryPopUp]);

  const initialValue = {
    category: "",
  };

  const [formValues, setFormValues] = useState(initialValue);

  const [formErrors, setFormErrors] = useState({});
  const [submit, setSubmit] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    const target = e.target;
    const name = target.name;
    const value = target.value;

    setFormValues({ ...formValues, [name]: value });
  };

  const closePopUp = (e) => {
    setCategoryPopUp(false);
    $(".addCategoryPopBg").fadeOut(500);
    $(".addCategoryPop").slideUp(500);
    setSubmit(false);
    setFormValues(initialValue);
    setFormErrors({});
  };

  const validate = (values) => {
    const errors = {};

    if (!values.category) {
      errors.category = mode === "en" ? "Required" : "आवश्यक छ";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && submit) {
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: User.UID.toString(),
        Flag: "i",
        CatName: formValues.category,
        FetchURL: `${appURL}api/help-desk/category`,
        Type: "POST",
      };

      Fetchdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          setFormValues(initialValue);
          setFormErrors({});
          formLst();
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
          setFormErrors({
            errorv: "Please enter valid credentials",
          });
        }
      });

      setSubmit(false);
    }
  }, [formErrors]);

  //
  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const [formList, setFormList] = useState([]);

  // to show Todo list

  useEffect(() => {
    formLst();
  }, []);

  const formLst = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      IsActive: "-1",
      FetchURL: `${appURL}api/help-desk/category`,
      Type: "POST",
    };

    Fetchdata(params).then(function (resp) {
      if (resp.StatusCode === 200) {
        const postResult = resp.Values ? resp.Values : "";
        setFormList(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setFormList([]);
        setOriginalList([]);
        setLoading(false);
      }
    });
  };

  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      grow: 0,
      center: true,
      width: "70px",
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },
    {
      name: mode === "en" ? "Category" : "श्रेणी",
      // center: true,
      // grow: 0,
      // minWidth: "200px",
      selector: (row) => row.CatName,
    },

    {
      name: mode === "en" ? "No of Ticket" : "टिकट नम्बर",
      center: true,
      width: "120px",
      selector: (row) => row.NoOfHelp,
    },
    {
      name: mode === "en" ? "Status" : "स्थिति",
      // grow: 0,
      center: true,
      width: "140px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                // class="btn btn-sm actvspan"
                className="border-0 bg-transparent"
                onClick={() => changeStatus(row.CatID, row.IsActive)}
              >
                <span
                  className=" badge rounded-pill bg-success"
                  style={{ fontSize: "11px" }}
                >
                  {checkStatus(row.IsActive)}
                </span>
              </button>
            </div>
          </>
        );
      },
    },
    {
      name: mode === "en" ? "Action" : "कार्य",
      center: true,
      width: "90px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                class="btn btn-sm editspan mx-1"
                onClick={() => handleEdit(row)}
                uk-tooltip={mode === "en" ? "Edit" : "सम्पादन गर्नुहोस्"}
              >
                <AiOutlineEdit />
              </button>{" "}
            </div>
          </>
        );
      },
    },
  ];

  const [perId, setPerId] = useState("");
  const [editData, setEditData] = useState(false);
  const [editSubmit, setEditSubmit] = useState(false);
  const handleEdit = (data) => {
    setPerId(data.CatID);
    setEditData(true);
    setFormValues({
      category: data.CatName,
    });
  };

  const handleEditData = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setEditSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && editSubmit) {
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: User.UID.toString(),
        Flag: "U",
        CatID: perId.toString(),
        CatName: formValues.category,
        FetchURL: `${appURL}api/help-desk/category`,
        Type: "POST",
      };

      Fetchdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          setFormValues(initialValue);
          setFormErrors({});
          formLst();
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
          setFormErrors({
            errorv: "Please enter valid credentials",
          });
        }
      });

      setEditSubmit(false);
    }
  }, [formErrors]);

  //

  const stateInitial = [];
  const [newStat, setNewStat] = useState(stateInitial);

  const deactivateData = (ID, IsActive) => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID.toString(),
      Flag: "AI",
      CatID: ID.toString(),
      IsActive: IsActive,
      FetchURL: `${appURL}api/help-desk/category`,
      Type: "POST",
    };
    if (IsActive === "A") {
      dataForm.IsActive = "I";
    } else {
      dataForm.IsActive = "A";
    }
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        formLst();
        let statsN = JSON.parse(JSON.stringify(newStat));
        let pitchStatus;

        if (dataForm.IsActive === "A") {
          pitchStatus = "Activated";
        } else if (dataForm.IsActive === "I") {
          pitchStatus = "Deactivated";
        }

        setNewStat(statsN);
        toast.success(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  const changeStatus = (ID, IsActive) => {
    deactivateData(ID, IsActive);
  };

  const checkStatus = (IsActive) => {
    if (IsActive === "A") {
      return mode === "en" ? "Deactivate" : "निष्क्रिय";
    } else if (IsActive === "I") {
      return mode === "en" ? "Activate" : "सक्रिय";
    }
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container newpopup-bg addCategoryPopBg">
        <div className="newpopup addCategoryPop " style={mainBg}>
          <div className="newpopup-head ps-0 pe-0" style={sidePanelBg}>
            <div className="popUpTitle">
              {" "}
              {mode === "en"
                ? "Manage Category"
                : "श्रेणी व्यवस्थापन गर्नुहोस्"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div className="newpopup-body ps-3 pe-3">
            <div className="form-padding">
              <div className="row text-start mt-3 ">
                <div className="col">
                  <label htmlFor="category">
                    {mode === "en" ? "Category" : "श्रेणी"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="category"
                    type="text"
                    name="category"
                    className="form-control form-control-sm "
                    onChange={handleChange}
                    value={formValues.category}
                  />
                  {formErrors.category && (
                    <p className="errormsg">{formErrors.category}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="uk-flex uk-flex-right mt-2">
              <button
                type="button"
                className="btn btn-sm"
                style={{ background: "var(--button-color)", color: "white" }}
                onClick={editData ? handleEditData : handleSubmit}
              >
                {editData ? (
                  <>{mode === "en" ? "Update" : "अपडेट गर्नुहोस्"}</>
                ) : (
                  <>{mode === "en" ? "Submit" : "बुझाउनुहोस्"}</>
                )}
              </button>
            </div>

            <div className="category__table mt-4">
              {loading ? (
                <Spinner />
              ) : (
                <DataTable
                  columns={columns}
                  data={formList}
                  customStyles={customStyles}
                  pagination
                  paginationRowsPerPageOptions={[10, 20, 50, 100]} // Customizable options
                  fixedHeader
                  fixedHeaderScrollHeight="350px"
                  highlightOnHover
                  pointerOnHover
                  responsive
                  dense
                  striped
                />
              )}
            </div>
          </div>

          <div className="new-footer">
            <div className="row  mt-1 mb-1">
              <div>
                <button
                  type="button"
                  className="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
