import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import AuthContext from "../../../context/auth-context";
import UpperbarContext from "../../../context/upperbar-context";
import Toast from "../../../Toast";
import { GoPlus } from "react-icons/go";
import {
  AiOutlineSearch,
  AiOutlineEdit,
  AiTwotonePrinter,
} from "react-icons/ai";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import LedgerPopup from "./LedgerPopup";
import LedgerContext from "./ledgerState/LedgerContext";
import EditLedgerPopup from "./EditLedgerPopup";
import "./ledger.css";
import AccountGroupContext from "../account group/accountGroupState/AccountGroupContext";
import AgentContext from "../agent/agentState/AgentContext";
import AreaContext from "../area/areaState/AreaContext";
import Spinner from "../../../loading/spinner";
import { englishToNepaliNumber } from "nepali-number";
import { generatePdf } from "../../../hooks/PdfExcel";

export default function Ledger() {
  const { User } = useContext(AuthContext);

  //   const searchInput = useRef("");
  const {
    fiscalYear,
    todayDate,
    customStyles,
    appURL,
    userDetails,
    darkText,
    mode,
  } = useContext(UpperbarContext);
  const { accLst } = useContext(AccountGroupContext);

  const {
    loading,
    setLoading,
    originalList,
    setOriginalList,
    popup,
    setPopup,
    submit,
    setSubmit,
    perEditSubmit,
    setPerEditSubmit,
    initalvalue,
    formValues,
    setFormValues,
    formErrors,
    setFormErrors,
    ledgerList,
    setLedgerList,
    editPopup,
    setEditPopup,
    handleEdit,
    editData,
    deactivateData,
    ledgerLst,
    checkedVat,
    setCheckedVat,
    checkedActive,
    setCheckedActive,
    checkedInv,
    setCheckedInv,
    checkedBill,
    setCheckedBill,
    checkedBankCash,
    setCheckedBankCash,
    DFlag,
    isUploaded,
    setIsUploaded,
    typeFile,
    setTypeFile,
    files,
    setFile,
    rateInput,
    setRateInput,
    ledgerUnderList,
    ldgrunderLst,
  } = useContext(LedgerContext);
  const { agentUnderList, agntunderLst } = useContext(AgentContext);
  const { areaUnderList, areaunderLst } = useContext(AreaContext);
  const [re, setRe] = useState();

  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      grow: 0,
      center: true,
      width: "70px",
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },
    {
      name: mode === "en" ? "Ledger" : "लेजर",
      // center: true,
      // grow: 0,

      selector: (row) => row.Ledger,
    },
    {
      name: mode === "en" ? "Refer" : "उल्लेख",
      // center: true,
      // grow: 0,

      selector: (row) => row.ReferName,
    },
    {
      name: mode === "en" ? "Account Group" : "खाता समूह",
      // center: true,
      width: "200px",
      // grow: 0,
      selector: (row) => row.AccGroup,
    },
    {
      name: mode === "en" ? "PAN" : "प्यान",
      // grow: 0,
      // center: true,
      width: "150px",
      selector: (row) =>
        mode === "en" ? row.PAN : englishToNepaliNumber(row.PAN),
    },
    {
      name: mode === "en" ? "Currency" : "मुद्रा",
      // grow: 0,
      // center: true,
      width: "150px",
      selector: (row) =>
        mode === "en" ? row.Currency : englishToNepaliNumber(row.Currency),
    },

    {
      name: mode === "en" ? "Status" : "स्थिति",
      // grow: 0,
      center: true,
      width: "140px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                // class="btn btn-sm actvspan"
                className="border-0 bg-transparent"
                onClick={() => changeStatus(row.LedgerID, row.IsActive)}
              >
                <span
                  className=" badge rounded-pill bg-success"
                  style={{ fontSize: "11px" }}
                >
                  {checkStatus(row.IsActive)}
                </span>
              </button>
            </div>
          </>
        );
      },
    },
    {
      name: mode === "en" ? "Action" : "कार्य",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                class="btn btn-sm editspan"
                onClick={() => handleEdit(row)}
                uk-tooltip={mode === "en" ? "Edit" : "सम्पादन गर्नुहोस्"}
              >
                <AiOutlineEdit />
              </button>{" "}
            </div>
          </>
        );
      },
    },
  ];

  const checkStatus = (IsActive) => {
    if (IsActive === "A") {
      return mode === "en" ? "Deactivate" : "निष्क्रिय";
    } else if (IsActive === "I") {
      return mode === "en" ? "Activate" : "सक्रिय";
    }
  };

  const changeStatus = (ID, IsActive) => {
    deactivateData(ID, IsActive);
  };

  const handleAdd = () => {
    setPopup(true);
    setFormValues(initalvalue);
    setCheckedActive(false);
    setCheckedBill(false);
    setCheckedInv(false);
    setCheckedVat(false);
    setCheckedBankCash(false);
    setIsUploaded(false);
    setRateInput(false);
    setRe(Math.random());
    accLst();
    areaunderLst();
    agntunderLst();
    ldgrunderLst();
  };

  const searchInput = useRef("");

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Ledger"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setLedgerList(srchResult);
      } else {
        setLedgerList({});
      }
    } else {
      setLedgerList(originalList);
    }
  };

  const dataWithIndex = ledgerList.map((d, i) => ({
    ...d,
    Index: i + 1,
    Status: d.IsActive === "I" ? "Inactive" : "Active",
  }));

  const cols = [
    { header: mode === "en" ? "S.N" : "क्र.सं", field: "Index" },
    { header: "Ledger", field: "Ledger" },
    { header: "Refer", field: "ReferName" },
    { header: "Account Group", field: "AccGroup" },
    { header: "PAN", field: "PAN" },
    { header: "Currency", field: "Currency" },
    { header: "Status", field: "Status" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    generatePdf(userDetails, exportColumns, dataWithIndex);
  };

  // ---------------------------
  const dataToExcel = ledgerList.map((d, i) => ({
    "S.N.": i + 1,
    Ledger: d.Ledger,
    Refer: d.ReferName,
    "Account Group": d.AccGroup,
    PAN: d.PAN,
    Currency: d.Currency,
    Status: d.IsActive === 'I' ? "Inactive" : "Active",
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Ledger");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper ps-4 pe-4">
        <div className="row ">
          <div className="page-header">
            <div className="text-start  page-title" style={darkText}>
              {mode === "en" ? "Ledger" : "लेजर"}
            </div>
            <div className="page-date">
              <div className="sec-content" style={darkText}>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>

        <>
          <div className="sec-dataTable">
            <div className="upper-dataTbl pb-3">
              <div className="">
                <div className="d-flex uk-flex-middle uk-flex-right">
                  <div className="btn-addlnote mt-3">
                    <button
                      type="button"
                      class="btn btn-sm"
                      style={{
                        background: "var(--button-color)",
                        color: "white",
                      }}
                      onClick={handleAdd}
                    >
                      <GoPlus color="#fff" className="me-1" />
                      {mode === "en" ? "Add Ledger" : "लेजर थप्नुहोस्"}
                    </button>
                  </div>
                  <div className="export-btn">
                    <button
                      uk-tooltip="Export Excel"
                      onClick={toExcel}
                      className="me-1 ms-2 border-0"
                    >
                      <RiFileExcel2Fill size="1rem" color="#136438" />
                    </button>
                  </div>
                  <div className="export-btn">
                    <button
                      uk-tooltip="Export Pdf"
                      onClick={toPdf}
                      className="mx-1 border-0"
                    >
                      <BsFileEarmarkPdfFill size="1rem" color="#ff0002" />
                    </button>
                  </div>
                  {/* <div className="export-btn">
                    <button
                      uk-tooltip="Print"
                      // onClick={toPrint}
                      className="mx-1 border-0"
                    >
                      <AiTwotonePrinter size="1rem" color="#555" />
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={ledgerList}
                customStyles={customStyles}
                pagination
                paginationRowsPerPageOptions={[10, 20, 50, 100]} // Customizable options
                fixedHeader
                fixedHeaderScrollHeight="350px"
                highlightOnHover
                pointerOnHover
                responsive
                // progressPending={loading}
                dense
                striped
                subHeader
                subHeaderComponent={
                  <>
                    <div className=" w-100 mb-3 mt-3">
                      <div className="d-flex uk-flex-middle justify-content-end">
                        <div>
                          <form class="uk-search uk-search-default">
                            <AiOutlineSearch className="search-icon" />
                            <input
                              placeholder={
                                mode === "en" ? "Search" : "खोजी गर्नुहोस्"
                              }
                              ref={searchInput}
                              type="text"
                              className="form-control form-control-sm searchField"
                              onChange={searchHandler}
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      <LedgerPopup
        popup={popup}
        setPopup={setPopup}
        submit={submit}
        setSubmit={setSubmit}
        formValues={formValues}
        setFormValues={setFormValues}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        ledgerLst={ledgerLst}
        checkedVat={checkedVat}
        setCheckedVat={setCheckedVat}
        checkedBankCash={checkedBankCash}
        setCheckedBankCash={setCheckedBankCash}
        checkedActive={checkedActive}
        setCheckedActive={setCheckedActive}
        checkedInv={checkedInv}
        setCheckedInv={setCheckedInv}
        checkedBill={checkedBill}
        setCheckedBill={setCheckedBill}
        DFlag={DFlag}
        isUploaded={isUploaded}
        setIsUploaded={setIsUploaded}
        typeFile={typeFile}
        setTypeFile={setTypeFile}
        files={files}
        setFile={setFile}
        re={re}
        rateInput={rateInput}
        agentUnderList={agentUnderList}
        areaUnderList={areaUnderList}
        ledgerUnderList={ledgerUnderList}
      />

      <EditLedgerPopup
        formValues={formValues}
        setFormValues={setFormValues}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        editPopup={editPopup}
        setEditPopup={setEditPopup}
        perEditSubmit={perEditSubmit}
        setPerEditSubmit={setPerEditSubmit}
        editData={editData}
        checkedVat={checkedVat}
        setCheckedVat={setCheckedVat}
        checkedBankCash={checkedBankCash}
        setCheckedBankCash={setCheckedBankCash}
        checkedActive={checkedActive}
        setCheckedActive={setCheckedActive}
        checkedInv={checkedInv}
        setCheckedInv={setCheckedInv}
        checkedBill={checkedBill}
        setCheckedBill={setCheckedBill}
        DFlag={DFlag}
        isUploaded={isUploaded}
        setIsUploaded={setIsUploaded}
        typeFile={typeFile}
        setTypeFile={setTypeFile}
        files={files}
        setFile={setFile}
        re={re}
        rateInput={rateInput}
        agentUnderList={agentUnderList}
        areaUnderList={areaUnderList}
        ledgerUnderList={ledgerUnderList}
      />
    </>
  );
}
