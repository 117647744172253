import React, { useContext, useEffect, useState } from "react";
import "./profile.css";
import DataTable from "react-data-table-component";
import { Fetchdata } from "../hooks/getData";
import AuthContext from "../context/auth-context";
import Spinner from "../loading/spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NepaliDate from "nepali-date-converter";
import { GetNepaliDate } from "../hooks/dateConvertor";
import UpperbarContext from "../context/upperbar-context";
import StaffContext from "../adminPanel/organization/staffState/StaffContext";
import Toast from "../Toast";
import { englishToNepaliNumber } from "nepali-number";

function Service({ userDetails }) {
  const { customStyles } = useContext(StaffContext);
  const { mode } = useContext(UpperbarContext);

  const [DFlag, setDFlag] = useState("N");
  const services = [
    {
      title: mode === "en" ? "Designation" : "पदनाम",
      body:
        userDetails.DesignationName === null ||
        userDetails.DesignationName === "-" ||
        userDetails.DesignationName === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : userDetails.DesignationName,
    },
    {
      title: mode === "en" ? "Department" : "विभाग",
      body:
        userDetails.DepartmentName === null ||
        userDetails.DepartmentName === "-" ||
        userDetails.DepartmentName === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : userDetails.DepartmentName,
    },
    // {
    //   title: mode === "en" ? "Sub department" : "उप विभाग",
    //   body:
    //     userDetails.SubDepartmentName === null ||
    //     userDetails.SubDepartmentName === "-" ||
    //     userDetails.SubDepartmentName === ""
    //       ? mode === "en"
    //         ? "Not mentioned"
    //         : "उल्लेख गरिएको छैन"
    //       : userDetails.SubDepartmentName,
    // },
    {
      title: mode === "en" ? "Grade" : "ग्रेड",
      body:
        userDetails.GradeName === null ||
        userDetails.GradeName === "-" ||
        userDetails.GradeName === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : userDetails.GradeName,
    },
    {
      title: mode === "en" ? "Type" : "प्रकार",
      body:
        userDetails.JobType === null ||
        userDetails.JobType === "-" ||
        userDetails.JobType === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : userDetails.JobType,
    },
  ];

  const [loading, setLoading] = useState(true);
  const [jobInfo, setJobinfo] = useState([]);
  const { User } = useContext(AuthContext);
  const { appURL, darkText } = useContext(UpperbarContext);

  // useEffect(() => {
  //   const params = {
  //     Type: "GET",
  //     FetchURL: `${appURL}api/job-information?ComId=${User.CompanyId}&UserID=${User.UID}`,
  //   };

  //   Fetchdata(params)
  //     .then(function (result) {
  //       if (result.StatusCode === 200) {
  //         const postResult = result.JobInfo ? result.JobInfo : "";
  //         setJobinfo(postResult);
  //         setLoading(false);
  //       } else {
  //         setJobinfo([]);
  //         setLoading(false);
  //       }
  //     })
  //     .catch((err) => {
  //       setJobinfo([]);
  //       setLoading(false);
  //     });
  // }, []);

  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      grow: 0,
      center: true,
      width: "70px",
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },
    {
      name: mode === "en" ? "Designation" : "पदनाम",
      // center: true,
      grow: 1,
      selector: (row) => row.Designation,
    },
    {
      name: mode === "en" ? "Department" : "विभाग",
      // grow: 0,
      // center: true,
      selector: (row) => row.Department,
    },
    {
      name: mode === "en" ? "Job Type" : "काम प्रकार",
      // grow: 0,
      // center: true,
      width: "150px",
      selector: (row) => row.JobType,
    },
    {
      name: mode === "en" ? "From Date" : "मिति देखि",
      // grow: 0,
      // center: true,
      width: "150px",
      selector: (row) =>
        mode === "en"
          ? GetNepaliDate(row.StartDate)
          : englishToNepaliNumber(GetNepaliDate(row.StartDate)),
    },
    {
      name: mode === "en" ? "To Date" : "मिति सम्म",
      // grow: 0,
      // center: true,
      width: "150px",
      selector: (row) =>
        mode === "en"
          ? GetNepaliDate(row.Enddate)
          : englishToNepaliNumber(GetNepaliDate(row.Enddate)),
    },
  ];

  return (
    <>
      {" "}
      {/* <Toast /> */}
      <div className="service-content">
        <div className="service-center">
          {services.map((item, index) => {
            return (
              <article key={index} className="basic-info">
                <h6 className="basic-title">{item.title}</h6>
                <p>{item.body}</p>
              </article>
            );
          })}
        </div>

        {/* <h4 className="uk-text-left fs-6 mt-2">
          {mode === "en" ? "Service History" : " सेवा इतिहास"} <hr />
        </h4>
        {loading ? (
          <>
            {" "}
            <Spinner />
          </>
        ) : (
          <DataTable
            columns={columns}
            data={jobInfo}
            customStyles={customStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="410px"
            highlightOnHover
            pointerOnHover
            responsive
            dense
            striped
            progressPending={loading}
          />
        )} */}
      </div>
    </>
  );
}

export default Service;
