import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import Toast from "../../../Toast";
import { GoPlus } from "react-icons/go";
import {
  AiOutlineSearch,
  AiOutlineEdit,
  AiTwotonePrinter,
} from "react-icons/ai";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import $ from "jquery";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import "../voucher/voucher.css";
import UpperbarContext from "../../../context/upperbar-context";
import { Fetchdata } from "../../../hooks/getData";
import AuthContext from "../../../context/auth-context";

export default function StockVoucher() {
  const { User } = useContext(AuthContext);
  const [DFlag, setDflag] = useState("N");
  const [voucherData, setVoucherData] = useState("");
  const [rateIndex, setRateIndex] = useState("");
  const [editRateIndex, setEditRateIndex] = useState("");

  //   const searchInput = useRef("");
  const { fiscalYear, todayDate, appURL, userDetails, darkText } =
    useContext(UpperbarContext);

  const [popup, setPopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [perEditSubmit, setPerEditSubmit] = useState(false);

  // const [re, setRe] = useState();
  const inputRef = useRef();
  const [autoFocused, setAutoFocused] = useState(false);
  const [open, setOpen] = useState(false);
  const [addInput, setAddInput] = useState(false);
  const [ind, setInd] = useState("");

  //   API to get account head

  const [accountList, setAccountList] = useState([]);

  useEffect(() => {
    accountLst();
  }, []);

  const accountLst = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      DFlag: "LEDGER",
      CID: "1",
      Flag: "S",
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/ref-values`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setAccountList(postResult);
      } else {
        setAccountList([]);
      }
    });
  };

  // list for a/c head
  const value = accountList.map((item) => ({
    id: item.LedgerID,
    name: item.Ledger,
  }));

  const initialValue = {
    product: "",
    qty: "",
    au: "",
    mu: "",
    rate: "",
    amount: "",
  };

  const [inputList, setInputList] = useState([initialValue]);

  // to focus on next input on pressing enter
  $(".inputs").keydown(function (e) {
    if (e.which === 13) {
      var inputVal = $(this).val();

      if (inputVal.length > 0) {
        var index = $(".inputs").index(this) + 1;
        $(".inputs").eq(index).focus();
      }

      if ($('.inputs[value=""]').length > 0) {
        console.log("some fields are empty!");
      } else {
        handleAdd();
      }
    }
  });

  // handle autocomplete input change
  const handleOnSelect = (e, index) => {
    let name = "product";
    let id = "id";
    const list = [...inputList];
    list[index][name] = e.name;
    list[index][id] = e.id;
    setInputList(list);
  };

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];

    const deleteItems = list.filter((item, i) => {
      return i != index;
    });
    setInputList(deleteItems);
    // setAutoFocused(false);
  };

  // handle click event of the Add button
  const handleAdd = () => {
    setInputList([...inputList, initialValue]);
    setAddInput(!addInput);
    // setAutoFocused(false);
    // setRe(Math.random());
  };

  // to add voucher
  const handleVoucher = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setVoucherData(value);
    setInputList([initialValue]);
  };

  // to add class to autocomplete textfield
  useEffect(() => {
    const elements = document.querySelectorAll(".sc-ciZhAO input");

    elements.forEach((element) => {
      element.setAttribute("name", "accountHead");
      // element.setAttribute("value", inputList[ind].accountHead);
      element.classList.add("inputs");
    });
  }, [addInput]);

  // to focus on amount field after selecting rate

  // useEffect(() => {
  //   if (autoFocused) {
  //     inputRef.current.focus();
  //   }
  // }, [autoFocused]);

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper ps-4 pe-4">
        <div className="row ">
          <div className="page-header">
            <div className="text-start  page-title" style={darkText}>
              Stock Voucher
            </div>
            <div className="page-date">
              <div className="sec-content" style={darkText}>
                <FaRegCalendarAlt /> {todayDate} <span>|</span> Fiscal Year :{" "}
                {fiscalYear.StartDate}
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>

        <>
          <div className="sec-dataTable">
            <div className="upper-dataTbl">
              <div className="uk-flex uk-flex-wrap uk-flex-between uk-flex-middle mb-3">
                <div className="uk-flex uk-flex-wrap me-5">
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      Voucher No.
                    </label>

                    <input
                      id="alias"
                      style={{ fontSize: "13px" }}
                      type="text"
                      className="form-control form-control-sm "
                      name="alias"
                      placeholder="Voucher No."
                      //   value={formValues.LeaveTitle}
                      //   onChange={handleChange}
                    />
                  </div>

                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      Select Napali Date
                    </label>

                    {DFlag === "N" ? (
                      <Calendar
                        // id="eventDate"
                        className="form-control form-control-sm pt-0 pb-0 "
                        dateFormat="YYYY/MM/DD"
                        // defaultDate="2010/01/01"
                        theme="default"
                        language="en"
                        // value={holidayFormValue.eventDate}
                        // onChange={handleEventDate}
                        name="eventDate"

                        // hideDefaultValue={true}
                        // placeholder={"Select Follow Date"}
                      />
                    ) : (
                      <input
                        type="date"
                        // value={holidayFormValue.eventDate}
                        placeholder="Select a Date"
                        className="form-control form-control-sm "
                        name="eventDate"
                        // onChange={handleChange}
                        // id="eventDate"
                      />
                    )}
                  </div>
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      Select Voucher Type
                    </label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="snotifiaction"
                      value={voucherData}
                      onChange={handleVoucher}
                      className="form-select form-select-sm"
                    >
                      <option
                        value=""
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        Select Voucher Type
                      </option>
                      <option value="1">Journal voucher</option>
                      <option value="2">Receipt Voucher</option>
                      <option value="3">Payment Voucher</option>
                      <option value="4">Contra Voucher</option>
                      <option value="5">Purchase Invoice</option>
                      <option value="6">Sales Invoice</option>
                      <option value="7">Debit Note</option>
                      <option value="8">Credit Note</option>
                    </select>
                  </div>
                </div>
                <div className="">
                  <div className="d-flex uk-flex-middle uk-flex-right">
                    <div className="btn-addlnote mt-3">
                      <button
                        type="button"
                        class="btn btn-sm"
                        style={{
                          background: "var(--button-color)",
                          color: "white",
                        }}
                        onClick={handleAdd}
                      >
                        <GoPlus color="#fff" className="me-1" />
                        Add Voucher
                      </button>
                    </div>
                    <div className="export-btn">
                      <button
                        uk-tooltip="Export Excel"
                        // onClick={toExcel}
                        className="me-1 ms-2 border-0"
                      >
                        <RiFileExcel2Fill size="1rem" color="#136438" />
                      </button>
                    </div>
                    <div className="export-btn">
                      <button
                        uk-tooltip="Export Pdf"
                        // onClick={toPdf}
                        className="mx-1 border-0"
                      >
                        <BsFileEarmarkPdfFill size="1rem" color="#ff0002" />
                      </button>
                    </div>
                    <div className="export-btn">
                      <button
                        uk-tooltip="Print"
                        // onClick={toPrint}
                        className="mx-1 border-0"
                      >
                        <AiTwotonePrinter size="1rem" color="#555" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="d-flex uk-flex-middle justify-content-end mt-3">
                <div>
                  <form class="uk-search uk-search-default">
                    <AiOutlineSearch className="search-icon" />
                    <input
                      placeholder="Search"
                      // ref={searchInput}
                      type="text"
                      className="form-control form-control-sm searchField"
                      // onChange={searchHandler}
                    />
                  </form>
                </div>
              </div>
            </div>

            <div className="mt-3">
              <table className="uk-table uk-table-striped voucher-table">
                <thead>
                  <tr>
                    <td className="rdt_TableHeadRow tableHead text-start">
                      Product
                    </td>
                    <td
                      className="rdt_TableHeadRow tableHead text-start"
                      style={{ width: "100px" }}
                    >
                      Quantity
                    </td>

                    <td
                      className="rdt_TableHeadRow tableHead "
                      style={{ width: "100px" }}
                    >
                      A.U
                    </td>

                    <td
                      className="rdt_TableHeadRow tableHead "
                      style={{ width: "100px" }}
                    >
                      M.U
                    </td>
                    <td
                      className="rdt_TableHeadRow tableHead "
                      style={{ width: "150px" }}
                    >
                      Rate
                    </td>
                    <td
                      className="rdt_TableHeadRow tableHead "
                      style={{ width: "150px" }}
                    >
                      Amount
                    </td>
                  </tr>
                </thead>

                <tbody>
                  {inputList.map((x, i) => {
                    return (
                      <tr>
                        <td className="rdt_TableCell tablecell">
                          <ReactSearchAutocomplete
                            items={value}
                            autoFocus
                            value={x.product}
                            onSelect={(item) => handleOnSelect(item, i)}
                            className="form-control form-control-sm inputs voucher-input"
                          />
                        </td>
                        <td className="rdt_TableCell tablecell">
                          <input
                            style={{
                              fontSize: "13px",
                            }}
                            type="text"
                            className="form-control form-control-sm inputs voucher-input"
                            name="qty"
                            placeholder="Qty"
                            value={x.qty}
                            onChange={(e) => handleInputChange(e, i)}
                            autoComplete="off"
                          />
                        </td>
                        <td className="rdt_TableCell tablecell">
                          <input
                            style={{
                              fontSize: "13px",
                            }}
                            type="text"
                            className="form-control form-control-sm inputs voucher-input"
                            name="au"
                            placeholder="A.U"
                            value={x.au}
                            onChange={(e) => handleInputChange(e, i)}
                            autoComplete="off"
                          />
                        </td>
                        <td className="rdt_TableCell tablecell">
                          <input
                            style={{
                              fontSize: "13px",
                            }}
                            type="text"
                            className="form-control form-control-sm inputs voucher-input"
                            name="mu"
                            placeholder="M.U"
                            value={x.mu}
                            onChange={(e) => handleInputChange(e, i)}
                            autoComplete="off"
                          />
                        </td>
                        <td className="rdt_TableCell tablecell">
                          <input
                            style={{
                              fontSize: "13px",
                            }}
                            type="text"
                            className="form-control form-control-sm inputs voucher-input"
                            name="rate"
                            placeholder="Rate"
                            value={x.rate}
                            onChange={(e) => handleInputChange(e, i)}
                            autoComplete="off"
                          />
                        </td>
                        <td className="rdt_TableCell tablecell">
                          <div className="uk-flex">
                            <input
                              style={{
                                fontSize: "13px",
                              }}
                              type="text"
                              className="form-control form-control-sm inputs voucher-input"
                              name="amount"
                              placeholder="Amount"
                              value={x.amount}
                              onChange={(e) => handleInputChange(e, i)}
                              autoComplete="off"
                            />
                            {inputList.length !== 1 && (
                              <button
                                type="button"
                                className=" btn btn-primary border-0"
                                onClick={() => handleRemoveClick(i)}
                              >
                                -
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <textarea
              id="description"
              style={{ fontSize: "13px" }}
              class="form-control ps-2"
              name="description"
              rows="3"
              cols="12"
              placeholder="Narration"
            ></textarea>
            {/* <DataTable
              columns={columns}
              data={data}
              customStyles={customStyles}
              pagination
              fixedHeader
              fixedHeaderScrollHeight="350px"
              highlightOnHover
              pointerOnHover
              responsive
              // progressPending={loading}
              dense
              striped
              subHeader
              subHeaderComponent={
                <>
                  <div className=" w-100">
                    <div className="d-flex uk-flex-middle justify-content-end">
                      <div>
                        <form class="uk-search uk-search-default">
                          <AiOutlineSearch className="search-icon" />
                          <input
                            placeholder="Search"
                            // ref={searchInput}
                            type="text"
                            className="form-control form-control-sm searchField"
                            // onChange={searchHandler}
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                </>
              }
            /> */}
          </div>
        </>
      </div>
    </>
  );
}
