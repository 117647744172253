import React, { useContext, useEffect, useState } from "react";
import "../../../leaveNotes/leaveNotePopup.css";
import "../subdepartment/SubdepartmentPopup.css";
import CloseIcon from "../../../../images/CloseIcon.svg";
import AuthContext from "../../../context/auth-context";
import { Fetchdata } from "../../../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UpperbarContext from "../../../context/upperbar-context";
import Toast from "../../../Toast";
import $ from "jquery";

export default function DesignationPopup({
  setDesignationPopup,
  reload,
  setReload,
  designationFormValue,
  setDesignationFormValue,
  designationFormError,
  setDesignationFormError,
  desgList,
  designationPopup,
}) {
  const { User } = useContext(AuthContext);
  const { mode, appURL } = useContext(UpperbarContext);
  const closePopUp = (e) => {
    setDesignationPopup(false);
    setDesignationFormError({});
    $(".addDesigationPopBg").fadeOut(500);
    $(".addDesigationPop").slideUp(500);
    setIsSubmit(false);
  };

  useEffect(() => {
    if (designationPopup) {
      $(".addDesigationPopBg").fadeIn(500);
      $(".addDesigationPop").slideDown(500);
    }
  }, [designationPopup]);

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type == "checkbox" ? target.checked : target.value;
    setDesignationFormValue({ ...designationFormValue, [name]: value });
  };

  const [isSubmit, setIsSubmit] = useState(false);

  const validate = (values) => {
    const errors = {};
    const numv = /^[0-9]+$/i;
    // if (!values.subdepartment) {
    //   errors.subdepartment = mode === "en" ? "Required" : "आवश्यक छ";
    // }
    if (!values.minSalary) {
      errors.minSalary = mode === "en" ? "Required" : "आवश्यक छ";
    }else if (!numv.test(values.minSalary)) {
      errors.minSalary = mode === "en" ? "Must be digits" : "अंक हुनुपर्छ";
    } 

    if (!values.maxSalary) {
      errors.maxSalary = mode === "en" ? "Required" : "आवश्यक छ";
    }else if (!numv.test(values.maxSalary)) {
      errors.maxSalary = mode === "en" ? "Must be digits" : "अंक हुनुपर्छ";
    }else if (values.maxSalary < values.minSalary) {
      errors.maxSalary = mode === "en" ? "Must be greater than minimum salary" : "न्यूनतम पारिश्रमिक भन्दा बढी हुनुपर्छ";
    } 
   
    if (!values.department) {
      errors.department = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.designation) {
      errors.designation = mode === "en" ? "Required" : "आवश्यक छ";
    }

    return errors;
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setDesignationFormError(validate(designationFormValue));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(designationFormError).length === 0 && isSubmit) {
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: User.UID,
        DepartID: designationFormValue.department,
        Designation: designationFormValue.designation,
        MaxSal: designationFormValue.maxSalary,
        MinSal: designationFormValue.minSalary,
        Flag: "i",
        BranchID: User.BranchID,
        FiscalID: User.FiscalID,
        FetchURL: `${appURL}api/admin/designation`,
        Type: "POST",
      };
      Fetchdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          desgList();
          setDesignationPopup(false);
          $(".addDesigationPopBg").fadeOut(500);
          $(".addDesigationPop").slideUp(500);
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });

      setIsSubmit(false);
    }
  }, [designationFormError]);

  //API to hit Department list
  const [departmentList, setDepartmentList] = useState([]);

  useEffect(() => {
    deptList();
  }, []);

  const deptList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      IsActive: "A",
      Type: "POST",
      FetchURL: `${appURL}api/admin/department`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";

        setDepartmentList(postResult);
      } else {
      }
    });
  };

  // API to hit Sub-Department list
  const [subdepartmentList, setSubdepartmentList] = useState([]);

  // useEffect(() => {
  //   subdeptList();
  // }, [designationFormValue.department]);

  // const subdeptList = () => {
  //   const params = {
  //     ComID: User.CompanyId,
  //     StaffID: -1,
  //     DepartID: designationFormValue.department,
  //     Flag: "S",
  //     Status: 1,
  //     Type: "POST",
  //     BranchID: User.BranchId,
  //     FetchURL: `${appURL}api/admin/sub-department`,
  //   };

  //   Fetchdata(params).then(function (result) {
  //     if (result.StatusCode === 200) {
  //       const postResult = result.SubDepList ? result.SubDepList : "";
  //       setSubdepartmentList(postResult);
  //     } else {
  //       setSubdepartmentList([]);
  //     }
  //   });
  // };

  return (
    <>
      {/* <Toast /> */}
      <div className="container newpopup-bg addDesigationPopBg">
        <div className="newpopup addDesigationPop" style={{ width: "50%" }}>
          <div className="newpopup-head ps-0 pe-0">
            <div className="popUpTitle">
              {" "}
              {mode === "en" ? "Designation" : "पदनाम"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div className="newpopup-body ps-3 pe-3">
            <div className="form-in-padding">
              <div className="row text-start mt-2 ">
                <div className="col-md-12 col-sm-12 col-lg-12">
                  <div className="row text-start ">
                    <div className="col-md-12 col-sm-12 col-lg-12">
                      <div className="form-group mt-0">
                        <label
                          htmlFor="department"
                          style={{ fontSize: "12px" }}
                        >
                          {mode === "en"
                            ? "Select Department"
                            : "विभाग चयन गर्नुहोस्"}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example"
                          name="department"
                          value={designationFormValue.department}
                          onChange={handleChange}
                        >
                          <option disabled value="" selected>
                            {mode === "en"
                              ? "Select Department"
                              : "विभाग चयन गर्नुहोस्"}
                          </option>

                          {departmentList.map((item) => (
                            <option
                              key={item.DepartmentID}
                              value={item.DepartmentID}
                            >
                              {item.Department}
                            </option>
                          ))}
                        </select>
                        {designationFormError.department && (
                          <p className="errormsg">
                            {designationFormError.department}
                          </p>
                        )}
                      </div>
                    </div>

                    {/* <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="form-group mt-0">
                        <label
                          htmlFor="subdepartment"
                          style={{ fontSize: "12px" }}
                        >
                          {mode === "en"
                            ? "Select Sub Department"
                            : "उप विभाग चयन गर्नुहोस्"}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example"
                          name="subdepartment"
                          value={designationFormValue.subdepartment}
                          onChange={handleChange}
                        >
                          <option disabled value="" selected>
                            {mode === "en"
                              ? "Select Sub Department"
                              : "उप विभाग चयन गर्नुहोस्"}
                          </option>

                          {subdepartmentList.map((item) => (
                            <option
                              key={item.SubDepartID}
                              value={item.SubDepartID}
                            >
                              {item.SubDepartName}
                            </option>
                          ))}
                        </select>
                        {designationFormError.subdepartment && (
                          <p className="errormsg">
                            {designationFormError.subdepartment}
                          </p>
                        )}
                      </div>
                    </div> */}
                  </div>

                  <div className="row text-start ">
                    <div className="form-group">
                      <label htmlFor="designation" style={{ fontSize: "12px" }}>
                        {mode === "en" ? "Designation" : "पदनाम"}
                        <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <input
                        id="designation"
                        style={{ fontSize: "13px" }}
                        type="text"
                        className="form-control form-control-sm "
                        name="designation"
                        value={designationFormValue.designation}
                        onChange={handleChange}
                        placeholder={mode === "en" ? "Designation" : "पदनाम"}
                      />
                      {designationFormError.designation && (
                        <p className="errormsg">
                          {designationFormError.designation}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="row text-start ">
                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="minSalary" style={{ fontSize: "12px" }}>
                          {mode === "en" ? "Min. Salary" : "न्यूनतम तलब"}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                          id="minSalary"
                          style={{ fontSize: "13px" }}
                          type="text"
                          className="form-control form-control-sm "
                          name="minSalary"
                          value={designationFormValue.minSalary}
                          onChange={handleChange}
                          placeholder={
                            mode === "en" ? "Min. Salary" : "न्यूनतम तलब"
                          }
                        />
                        {designationFormError.minSalary && (
                          <p className="errormsg">
                            {designationFormError.minSalary}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="maxSalary" style={{ fontSize: "12px" }}>
                          {mode === "en" ? "Max. Salary" : "अधिकतम तलब"}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                          id="maxSalary"
                          style={{ fontSize: "13px" }}
                          type="text"
                          className="form-control form-control-sm "
                          name="maxSalary"
                          value={designationFormValue.maxSalary}
                          onChange={handleChange}
                          placeholder={
                            mode === "en" ? "Max. Salary" : "अधिकतम तलब"
                          }
                        />
                        {designationFormError.maxSalary && (
                          <p className="errormsg">
                            {designationFormError.maxSalary}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="new-footer">
            <div className="row  mt-1 mb-1">
              <div>
                <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={formSubmit}
                >
                  {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
