import React from "react";
import { useContext } from "react";
import UpperbarContext from "../context/upperbar-context";
import { GetTwelveHourFormatTime } from "../hooks/dateConvertor";
import "./profile.css";

function Shift({ userDetails }) {
  const { mode } = useContext(UpperbarContext);
  const services = [
    {
      title: mode === "en" ? "Shift" : "शिफ्ट",
      body:
        userDetails.ShiftName === null ||
        userDetails.ShiftName === "-" ||
        userDetails.ShiftName === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : userDetails.ShiftName,
    },
    {
      title: mode === "en" ? "Type" : "प्रकार",
      body:
        userDetails.JobType === null ||
        userDetails.JobType === "-" ||
        userDetails.JobType === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : userDetails.JobType,
    },
    {
      title: mode === "en" ? "Shift Start" : "शिफ्ट सुरु",
      body:
        userDetails.ShiftStart === null ||
        userDetails.ShiftStart === "-" ||
        userDetails.ShiftStart === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : GetTwelveHourFormatTime(userDetails.ShiftStart),
    },
    {
      title: mode === "en" ? "Shift End" : "सिफ्ट अन्त्य",
      body:
        userDetails.ShiftEnd === null ||
        userDetails.ShiftEnd === "-" ||
        userDetails.ShiftEnd === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : GetTwelveHourFormatTime(userDetails.ShiftEnd),
    },
    {
      title: mode === "en" ? "Lunch Start" : "लंच सुरु",
      body:
        userDetails.LaunchStart === null ||
        userDetails.LaunchStart === "-" ||
        userDetails.LaunchStart === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : GetTwelveHourFormatTime(userDetails.LaunchStart),
    },
    {
      title: mode === "en" ? "Lunch End" : "लंच अन्त्य",
      body:
        userDetails.LaunchEnd === null ||
        userDetails.LaunchEnd === "-" ||
        userDetails.LaunchEnd === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : GetTwelveHourFormatTime(userDetails.LaunchEnd),
    },
    {
      title: mode === "en" ? "Allowed Late In" : "ढिलो अनुमति दिइएको",
      body:
        userDetails.AllowedLateIn === null ||
        userDetails.AllowedLateIn === "-" ||
        userDetails.AllowedLateIn === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : GetTwelveHourFormatTime(userDetails.AllowedLateIn),
    },
    {
      title:
        mode === "en" ? "Allowed Early Out" : "प्रारम्भिक बाहिर अनुमति दिइएको",
      body:
        userDetails.AllowedEarlyOut === null ||
        userDetails.AllowedEarlyOut === "-" ||
        userDetails.AllowedEarlyOut === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : GetTwelveHourFormatTime(userDetails.AllowedEarlyOut),
    },
  ];

  return (
    <div className="basic-center">
      <div className="shift-basic-dyno">
        {services.map((item, index) => {
          return (
            <article key={index} className="basic-info">
              <h6 className="basic-title">{item.title}</h6>
              <p>{item.body}</p>
            </article>
          );
        })}
      </div>
    </div>
  );
}

export default Shift;
