import React, { useState, useEffect, useContext } from "react";
import StaffContext from "./StaffContext";
import { Fetchdata } from "../../../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import AuthContext from "../../../context/auth-context";
import UpperbarContext from "../../../context/upperbar-context";
import { defaultThemes } from "react-data-table-component";
// import { getBoundingClientObj } from "react-select/dist/declarations/src/utils";
import { GetEnglishDate } from "../../../hooks/dateConvertor";
import { GetNepaliDate } from "../../../hooks/dateConvertor";
import { red } from "@material-ui/core/colors";
import { nepaliToEnglishNumber } from "nepali-number";
import $ from "jquery";

function StaffState(props) {
  const { User } = useContext(AuthContext);
  const { appURL } = useContext(UpperbarContext);

  const [staffPopup, setStaffPopup] = useState(false);
  const [staffEditPopup, setStaffEditPopup] = useState(false);
  const [currentStep, setStep] = useState(1);
  const [isSubmit, setIsSubmit] = useState(false);
  const [finalSubmit, setFinalSubmit] = useState(false);
  const [editIsSubmit, setEditIsSubmit] = useState(false);
  const [editFinalSubmit, setEditFinalSubmit] = useState(false);

  const [originalList, setOriginalList] = useState(null);

  const [chooseDepartment, setChooseDepartment] = useState("-1");
  const [chooseSubDepartment, setChooseSubDepartment] = useState("-1");
  const [chooseDesignation, setChooseDesignation] = useState("-1");

  const [loading, setLoading] = useState(false);

  const staffValue = {
    firstName: "",
    middleName: "",
    lastName: "",
    userID: "",
    userCode: "",
    deviceCode: "",
    mobileCode: "",
    userName: "",
    password: "",
    email: "",
    contact: "",
    phone: "",
    address: "",
    district: "",
    dateOfBirth: "",
    citizenshipNo: "",
    pan: "",
    gender: "",
    blood: "",
    religion: "",
    marital: "",
    enrollDate: "",
    leaveDate: "",
    jobType: "",
    selectShift: "",
    shiftType: "",
    grade: "",
    branch: "",
    department: "",
    subDepartment: "",
    designation: "",
    days: "",
  };

  const [staffFormValue, setStaffFormValue] = useState(staffValue);
  const [staffFormError, setStaffFormError] = useState({});

  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");
  const [image, setImage] = useState("");

  const [checkedList, setCheckedList] = useState([]);
  const [managerChecked, setManagerChecked] = useState(false);
  const [notWorking, setNotWorking] = useState(false);

  //API to hit Staff list
  const [staffList, setStaffList] = useState([]);
  const [workingStatus, setworkingStatus] = useState("Y");
  const [branch, setBranch] = useState(User.BranchID);

  useEffect(() => {
    stfList();
  }, [chooseDepartment, chooseDesignation, workingStatus, branch]);

  const stfList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      Flag: "S",
      UserID: "-1",
      Department: chooseDepartment,
      Designation: chooseDesignation,
      WorkingStatus: workingStatus,
      IsActive: "-1",
      BranchID: branch,
      FiscalID: User.FiscalID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/user`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setStaffList(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setLoading(false);
        setOriginalList([]);
        setStaffList([]);
      }
    });
  };

  //API to hit Department list
  const [departmentList, setDepartmentList] = useState([]);

  useEffect(() => {
    deptList();
  }, []);

  const deptList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      IsActive: "A",
      Type: "POST",
      FetchURL: `${appURL}api/admin/department`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setDepartmentList(postResult);
      } else {
        setDepartmentList([]);
      }
    });
  };

  // API to hit Sub-Department list
  const [subdepartmentList, setSubdepartmentList] = useState([]);

  // useEffect(() => {
  //   subdeptList();
  // }, [staffFormValue.department]);

  // const subdeptList = () => {
  //   const params = {
  //     ComID: User.CompanyId,
  //     StaffID: -1,
  //     DepartID: staffFormValue.department,
  //     Flag: "S",
  //     Type: "POST",
  //     Status: 1,
  //     BranchID: User.BranchId,
  //     FetchURL: `${appURL}api/admin/sub-department`,
  //   };

  //   Fetchdata(params).then(function (result) {
  //     if (result.StatusCode === 200) {
  //       const postResult = result.SubDepList ? result.SubDepList : "";
  //       setSubdepartmentList(postResult);
  //     } else {
  //       setSubdepartmentList([]);
  //     }
  //   });
  // };

  //API to hit Designation list
  const [designationList, setDesignationList] = useState([]);

  useEffect(() => {
    desgList();
  }, [staffFormValue.department]);

  const desgList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      DepartID: staffFormValue.department,
      IsActive: "A",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/designation`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setDesignationList(postResult);
      } else {
        setDesignationList([]);
      }
    });
  };

  //API to hit Shift list
  const [shiftList, setShiftList] = useState([]);
  useEffect(() => {
    shftList();
  }, []);

  const shftList = () => {
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      BranchID: Branch,
      FiscalID: Fiscal,
      Type: "POST",
      FetchURL: `${appURL}api/admin/shift`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setShiftList(postResult);
      } else {
        setShiftList([]);
      }
    });
  };

  // const nan = [];
  const [titleId, setTitleID] = useState();
  const editPop = (datas) => {
    setTitleID(datas.UID);
    setStaffEditPopup(true);
  };

  useEffect(() => {
    setCheckedList(staffFormValue.days.split(","));
  }, [staffFormValue.days]);

  // API to get staff info

  const [staffInfoList, setStaffInfoList] = useState([]);

  useEffect(() => {
    easyList();
  }, [titleId]);

  const easyList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      Flag: "SI",
      UserID: User.UID,
      StaffID: titleId,
      FetchURL: `${appURL}api/admin/user`,
      Type: "POST",
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const data = result.Values[0] ? result.Values[0] : "";
        setStaffFormValue({
          firstName: data.FirstName,
          middleName: data.MiddleName,
          lastName: data.LastName,
          userID: data.UserID,
          userCode: data.UserCode,
          deviceCode: data.DeviceCode === null ? "" : data.DeviceCode,
          mobileCode: data.MobileID,
          userName: data.UserName,
          email: data.Email,
          contact: data.Contact,
          phone: data.Phone,
          address: data.Address,
          district: data.DistrictID,
          dateOfBirth: data.DateOfBirthNp,
          citizenshipNo: data.CitizenshipNo,
          pan: data.PAN,
          gender: data.GenderID,
          blood: data.BloodGroupID,
          religion: data.ReligionID,
          marital: data.MaritialStatusID,
          enrollDate: GetNepaliDate(data.EnrollDate),
          leaveDate:
            data.WorkingStatusID === "Y" ? "" : GetNepaliDate(data.LeaveDate),
          jobType: data.JobTypeID,
          selectShift: data.ShiftID,
          shiftType: data.ShiftTypeID,
          grade: data.GradeID,
          department: data.DepartmentID,
          subDepartment: data.SubDepartmentID,
          designation: data.DesignationID,
          days: data.WorkingDays,
        });
        setImage(data.Image);
        setManagerChecked(data.IsManager === "Y" ? true : false);
        setNotWorking(data.WorkingStatusID === "Y" ? false : true);
      } else {
      }
    });
  };

  //API to hit Staff status

  const stateInitial = [];
  const [newStat, setNewStat] = useState(stateInitial);

  const deactivateDepart = (ID, IsActive) => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID,
      Flag: "AI",
      StaffID: ID,
      IsActive: IsActive,
      FetchURL: `${appURL}api/admin/user`,
      Type: "POST",
    };
    if (IsActive === "A") {
      dataForm.IsActive = "I";
    } else {
      dataForm.IsActive = "A";
    }
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        stfList();
        let statsN = JSON.parse(JSON.stringify(newStat));
        let pitchStatus;

        if (dataForm.IsActive === "A") {
          pitchStatus = "Activated";
        } else if (dataForm.IsActive === "I") {
          pitchStatus = "Deactivated";
        }
        setNewStat(statsN);
        toast.success(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  return (
    <StaffContext.Provider
      value={{
        staffPopup,
        setStaffPopup,
        staffEditPopup,
        setStaffEditPopup,
        currentStep,
        setStep,
        staffFormValue,
        setStaffFormValue,
        staffFormError,
        setStaffFormError,
        staffValue,
        isUploaded,
        setIsUploaded,
        typeFile,
        setTypeFile,
        image,
        setImage,
        checkedList,
        setCheckedList,
        managerChecked,
        setManagerChecked,
        notWorking,
        setNotWorking,
        isSubmit,
        setIsSubmit,
        finalSubmit,
        setFinalSubmit,
        staffList,
        departmentList,
        designationList,
        subdepartmentList,
        editIsSubmit,
        setEditIsSubmit,
        editFinalSubmit,
        setEditFinalSubmit,
        editPop,
        titleId,
        deactivateDepart,
        stfList,
        originalList,
        setOriginalList,
        setStaffList,
        chooseDepartment,
        setChooseDepartment,
        chooseSubDepartment,
        setChooseSubDepartment,
        chooseDesignation,
        setChooseDesignation,
        shiftList,
        loading,
        setLoading,
        workingStatus,
        setworkingStatus,
        branch,
        setBranch,
      }}
    >
      {props.children}
    </StaffContext.Provider>
  );
}
export default StaffState;
