import React, { useState, useEffect, useContext } from "react";
import AgentContext from "./AgentContext";
import { Fetchdata } from "../../../../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import AuthContext from "../../../../context/auth-context";
import UpperbarContext from "../../../../context/upperbar-context";
import $ from "jquery";

function AgentState(props) {
  const { User } = useContext(AuthContext);
  const { appURL } = useContext(UpperbarContext);
  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const [popup, setPopup] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [perEditSubmit, setPerEditSubmit] = useState(false);

  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState(null);
  const [files, setFile] = useState("");
  const [rateInput, setRateInput] = useState(false);

  const [checkedVat, setCheckedVat] = useState(false);
  const [checkedActive, setCheckedActive] = useState(false);

  const initalvalue = {
    agentName: "",
    under: "",
    alias: "",
    area: "",
    pan: "",
    description: "",
    address: "",
    contactPerson: "",
    contact: "",
    email: "",
    website: "",
    creditAmt: "",
    actionAmt: "",
    creditDays: "",
    actionDay: "",
    rate: "",
    pdf: "",
    ext: "",
  };
  const [formValues, setFormValues] = useState(initalvalue);
  const [formErrors, setFormErrors] = useState({});

  //  API to show main group list
  const [agentList, setAgentList] = useState([]);

  useEffect(() => {
    agentLst();
  }, [submit, perEditSubmit]);

  const agentLst = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      CID: "1",
      IsActive: "-1",
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/agent`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setAgentList(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setAgentList([]);
        setLoading(false);
      }
    });
  };

  //  API to edit main group
  const [perID, setPerId] = useState();
  const [editPopup, setEditPopup] = useState(false);
  const handleEdit = (data) => {
    setPerId(data.AgentID);
    setEditPopup(true);
    agntunderLst();
  };

  const editData = () => {
    const UserID = "" + User.UID;
    const referid = "" + formValues.under;
    const areaid = "" + formValues.area;
    const id = "" + perID;

    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      CID: "1",
      AgentID: id,
      Flag: "U",
      ReferID: referid !== " " ? referid : " ",
      Name: formValues.agentName !== " " ? formValues.agentName : " ",
      Alias: formValues.alias !== " " ? formValues.alias : " ",
      AreaID: areaid !== " " ? areaid : " ",
      PAN: formValues.pan !== " " ? formValues.pan : " ",
      IsVat: checkedVat ? "Y" : "N",
      Description:
        formValues.description !== " " ? formValues.description : " ",
      Address: formValues.address !== " " ? formValues.address : " ",
      ContactPerson:
        formValues.contactPerson !== " " ? formValues.contactPerson : " ",
      Contact: formValues.contact !== " " ? formValues.contact : " ",
      Email: formValues.email !== " " ? formValues.email : " ",
      Website: formValues.website !== " " ? formValues.website : " ",
      Document: typeFile !== null ? typeFile.split(",")[1] : "",
      FetchURL: `${appURL}api/acc/admin/agent`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (resp) {
      if (resp.StatusCode === 200) {
        setEditPopup(false);
        $(".editAgentPopBg").fadeOut(300);
        $(".editAgentPop").slideUp(500);
        agentLst();
        toast.success(resp.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + resp.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };
  // API to get main group info
  useEffect(() => {
    infoList();
  }, [perID]);

  const infoList = () => {
    const UserID = "" + User.UID;
    const id = "" + perID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      Flag: "SI",
      CID: "1",
      AgentID: id,
      FetchURL: `${appURL}api/acc/admin/agent`,
      Type: "POST",
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const data = result.Values[0] ? result.Values[0] : "";
        setFormValues({
          agentName: data.Agent,
          under: data.ReferID,
          alias: data.Alias,
          area: data.AreaID,
          pan: data.PAN,
          description: data.Description,
          address: data.Address,
          contactPerson: data.ContactPerson,
          contact: data.Contact,
          email: data.Email,
          website: data.Website,
          // pdf:data.Docs,
        });
        setFile(data.Docs !== "" ? data.Docs : "");
        setIsUploaded(data.Docs !== "" ? true : false);
        setCheckedActive(data.IsActive === "A" ? true : false);
      } else {
      }
    });
  };

  // API to hit main group status
  const stat = [];
  const [newStatus, setNewStatus] = useState(stat);

  const deactivateData = (ID, IsActive) => {
    const UserID = "" + User.UID;
    const id = "" + ID;
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      Flag: "AI",
      CID: "1",
      AgentID: id,
      IsActive: IsActive,
      FetchURL: `${appURL}api/acc/admin/agent`,
      Type: "POST",
    };
    if (IsActive === "A") {
      dataForm.IsActive = "I";
    } else {
      dataForm.IsActive = "A";
    }
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        agentLst();
        let NewstatsN = JSON.parse(JSON.stringify(newStatus));
        let pitchNewStatus;

        if (dataForm.IsActive === "A") {
          pitchNewStatus = "Activated";
        } else if (dataForm.IsActive === "I") {
          pitchNewStatus = "Deactivated";
        }
        setNewStatus(NewstatsN);
        toast.success(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  //   API to under list
  const [agentUnderList, setAgentUnderList] = useState([]);

  useEffect(() => {
    agntunderLst();
  }, [submit]);

  const agntunderLst = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      DFlag: "AGENT",
      Flag: "S",
      CID: "1",
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/ref-values`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setAgentUnderList(postResult);
      } else {
        setAgentUnderList([]);
      }
    });
  };

  return (
    <AgentContext.Provider
      value={{
        loading,
        setLoading,
        originalList,
        setOriginalList,
        popup,
        setPopup,
        submit,
        setSubmit,
        perEditSubmit,
        setPerEditSubmit,
        initalvalue,
        formValues,
        setFormValues,
        formErrors,
        setFormErrors,
        agentList,
        setAgentList,
        editPopup,
        setEditPopup,
        handleEdit,
        editData,
        deactivateData,
        agentLst,

        isUploaded,
        setIsUploaded,
        typeFile,
        setTypeFile,
        files,
        setFile,
        checkedVat,
        setCheckedVat,
        checkedActive,
        setCheckedActive,
        agentUnderList,
        rateInput,
        setRateInput,
        agntunderLst,
      }}
    >
      {props.children}
    </AgentContext.Provider>
  );
}

export default AgentState;
