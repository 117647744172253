import React, { useContext, useEffect, useState, useRef } from "react";
import "./attendanceReport.css";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import UpperbarContext from "../../context/upperbar-context";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../loading/spinner";
import DataTable from "react-data-table-component";
import AuthContext from "../../context/auth-context";
import { Fetchdata } from "../../hooks/getData";
import { AiOutlineSearch } from "react-icons/ai";
import { englishToNepaliNumber } from "nepali-number";
import { ToastContainer, toast } from "react-toastify";

export default function DeviceCode() {
  const { User } = useContext(AuthContext);

  const { fiscalYear, customStyles, appURL, todayDate, mode } =
    useContext(UpperbarContext);
  const searchInput = useRef("");

  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      // grow: 0,
      width: "70px",
      center: true,
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },

    {
      name: mode === "en" ? "Full Name" : "पुरा नाम",
      // grow: 2,
      // width: "150px",
      selector: (row) => row.FullName,
    },
    {
      name: mode === "en" ? "UserName" : "प्रयोगकर्ता नाम",
      // grow: 2,
      // width: "150px",
      selector: (row) => row.UserName,
    },
    {
      name: mode === "en" ? "Device Code" : "उपकरण कोड",
      // grow: 2,
      // center: true,
      cell: (row) => {
        return (
          <>
            <input
              type="number"
              style={{ fontSize: "13px" }}
              className="form-control form-control-sm "
              value={row.DeviceCode}
              onChange={(event) => handleInputChange(event, row, "DeviceCode")}
              defaultValue={row.DeviceCode}
              onKeyPress={handleKeyPress}
            />
          </>
        );
      },
    },
  ];

  const handleKeyPress = (event) => {
    // Check if the key pressed is not a number
    if (isNaN(Number(event.key))) {
      event.preventDefault(); // Prevent the character from being entered
    }
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["FullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setDeviceCodeList(srchResult);
      } else {
        setDeviceCodeList({});
      }
    } else {
      setDeviceCodeList(originalList);
    }
  };

  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const [originalList, setOriginalList] = useState(null);

  const [deviceCodeList, setDeviceCodeList] = useState([]);

  // to show route list

  useEffect(() => {
    codeLst();
  }, []);

  const codeLst = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID,
      Flag: "S",
      FetchURL: `${appURL}api/admin/user-attendance`,
      Type: "POST",
    };

    Fetchdata(params).then(function (resp) {
      if (resp.StatusCode === 200) {
        const postResult = resp.Values ? resp.Values : "";
        setDeviceCodeList(postResult);
        setOriginalList(postResult);
        setLoading(false);
        setInitialLoad(false);
      } else {
        setDeviceCodeList([]);
        setOriginalList([]);
        setLoading(false);
      }
    });
  };

  const handleInputChange = (event, row, field) => {
    const value = event.target.value.toString();
    const updatedMarksInputList = deviceCodeList.map((item) => {
      if (item.UserName === row.UserName) {
        if (field === "DeviceCode") {
          return {
            ...item,
            [field]: value,
          };
        }
      } else {
        return item;
      }
    });
    setDeviceCodeList(updatedMarksInputList);
  };

  const storeRemove = ["FullName"];
  const newArr = deviceCodeList.map((obj) => {
    const newObj = { ...obj };
    storeRemove.forEach((key) => delete newObj[key]);
    return newObj;
  });

  const handleSave = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID,
      Param: newArr,
      Flag: "i",
      FetchURL: `${appURL}api/admin/user-attendance`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        toast.success(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
        codeLst();
      } else {
        toast.error("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  return (
    <>
      {/* <Toast /> */}
      <div className=" sumatten-wrapper  mt-3 ">
        <>
          {" "}
          <div>
            <div className="upper-dataTbl">
              <div className="uk-flex uk-flex-wrap uk-flex-between uk-flex-middle mb-3">
                <div className="uk-flex uk-flex-wrap">
                  {/* <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en"
                        ? " Select Active Route"
                        : "इन्टरनेटको सक्रिय मार्ग चयन गर्नुहोस्"}
                    </label>
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example "
                      name="activeRoute"
                      onChange={handleChange}
                      value={route.activeRoute}
                    >
                      <option disabled value="" selected>
                        {mode === "en"
                          ? " Select Active Route"
                          : "इन्टरनेटको सक्रिय मार्ग चयन गर्नुहोस्"}
                      </option>
                      <option value="-1">
                        {mode === "en" ? "Select All" : "सबै चयन गर्नुहोस्"}
                      </option>
                      <option value="A">
                        {" "}
                        {mode === "en"
                          ? " Active Route"
                          : "इन्टरनेटको सक्रिय मार्ग"}
                      </option>
                      <option value="I">
                        {" "}
                        {mode === "en"
                          ? " Deactive Route"
                          : "इन्टरनेटको निष्क्रिय मार्ग"}
                      </option>
                    </select>
                  </div> */}
                </div>

                <div className="">
                  <div className="d-flex uk-flex-wrap uk-flex-middle uk-flex-right">
                    <div className="btn-addlnote mt-3">
                      <button
                        type="button"
                        class="btn btn-sm"
                        style={{
                          background: "var(--button-color)",
                          color: "white",
                        }}
                        onClick={handleSave}
                      >
                        {mode === "en" ? "Save" : "बुझाउनुहोस्"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <>
              {loading ? (
                <Spinner />
              ) : (
                <>
                  {" "}
                  <DataTable
                    columns={columns}
                    data={deviceCodeList}
                    customStyles={customStyles}
                    pagination
                    fixedHeader
                    paginationRowsPerPageOptions={[10, 20, 50, 100]} // Customizable options
                    fixedHeaderScrollHeight="335px"
                    highlightOnHover
                    pointerOnHover
                    progressPending={loading}
                    responsive
                    // subHeader
                    dense
                    striped
                    // subHeaderComponent={
                    //   <>
                    //     <div className=" w-100">
                    //       <div className="d-flex uk-flex-middle justify-content-end">
                    //         <div>
                    //           <div class="uk-search uk-search-default">
                    //             <AiOutlineSearch className="search-icon" />
                    //             <input
                    //               placeholder={
                    //                 mode === "en" ? "Search" : "खोजी गर्नुहोस्"
                    //               }
                    //               ref={searchInput}
                    //               type="text"
                    //               className="form-control form-control-sm searchField"
                    //               onChange={searchHandler}
                    //             />
                    //           </div>
                    //         </div>
                    //       </div>
                    //     </div>
                    //   </>
                    // }
                  />
                </>
              )}
            </>
          </div>
        </>
      </div>
    </>
  );
}
