import React, { useContext, useEffect, useState } from "react";
import "../../../leaveNotes/leaveNotePopup.css";
import "../subdepartment/SubdepartmentPopup.css";
import CloseIcon from "../../../../images/CloseIcon.svg";
import AuthContext from "../../../context/auth-context";
import { Fetchdata } from "../../../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UpperbarContext from "../../../context/upperbar-context";
import Toast from "../../../Toast";
import $ from "jquery";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";

export default function PromotionPopup({
  setPopup,
  reload,
  setReload,
  formValue,
  setFormValue,
  formError,
  setFormError,
  dataInfo,
  popup,
  DFlag,
  isApproved,
  setIsApproved,
}) {
  const { User } = useContext(AuthContext);
  const { mode, appURL } = useContext(UpperbarContext);
  const closePopUp = (e) => {
    setPopup(false);
    setFormError({});
    $(".addPromotionPopBg").fadeOut(500);
    $(".addPromotionPop").slideUp(500);
    setIsSubmit(false);
  };

  useEffect(() => {
    if (popup) {
      $(".addPromotionPopBg").fadeIn(500);
      $(".addPromotionPop").slideDown(500);
    }
  }, [popup]);

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleDate = ({ bsDate }) => {
    // setFromDate(bsDate);
    let name = "date";
    setFormValue({ ...formValue, [name]: bsDate });
  };

  const [isSubmit, setIsSubmit] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.staff) {
      errors.staff = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.type) {
      errors.type = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.fromDepartment) {
      errors.fromDepartment = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.fromDesignation) {
      errors.fromDesignation = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.fromJobType) {
      errors.fromJobType = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.fromShift) {
      errors.fromShift = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.fromShiftType) {
      errors.fromShiftType = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.fromGrade) {
      errors.fromGrade = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.fromBranch) {
      errors.fromBranch = mode === "en" ? "Required" : "आवश्यक छ";
    }

    if (!values.toDepartment) {
      errors.toDepartment = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.toDesignation) {
      errors.toDesignation = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.toJobType) {
      errors.toJobType = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.toShift) {
      errors.toShift = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.toShiftType) {
      errors.toShiftType = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.toGrade) {
      errors.toGrade = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.toBranch) {
      errors.toBranch = mode === "en" ? "Required" : "आवश्यक छ";
    }

    return errors;
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setFormError(validate(formValue));
    setIsSubmit(true);
  };

  const addData = async (dataToSend) => {
    const response = await fetch(dataToSend.FetchURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Signature: "p0m76",
      },
      body: JSON.stringify(dataToSend),
    });
    const cooptive = await response.json();
    return cooptive;
  };

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: User.UID.toString(),
        Flag: "i",
        StaffID: formValue.staff,
        FromDepart: formValue.fromDepartment,
        FromDesign: formValue.fromDesignation,
        FromJobType: formValue.fromJobType,
        FromShift: formValue.fromShift,
        FromShiftType: formValue.fromShiftType,
        FromGrade: formValue.fromGrade,
        FromBranch: formValue.fromBranch,
        ToDepart: formValue.toDepartment,
        ToDesign: formValue.toDesignation,
        ToJobType: formValue.toJobType,
        ToShift: formValue.toShift,
        ToShiftType: formValue.toShiftType,
        ToGrade: formValue.toGrade,
        ToBranch: formValue.toBranch,
        IsApproved: isApproved ? "Y" : "N",
        Type: formValue.type,
        FetchURL: `${appURL}api/admin/pro-transfer`,
      };

      addData(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          dataInfo();
          setPopup(false);
          $(".addPromotionPopBg").fadeOut(500);
          $(".addPromotionPop").slideUp(500);
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });

      setIsSubmit(false);
    }
  }, [formError]);

  //API to hit Department list
  const [departmentList, setDepartmentList] = useState([]);

  useEffect(() => {
    deptList();
  }, []);

  const deptList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      IsActive: "A",
      Type: "POST",
      FetchURL: `${appURL}api/admin/department`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";

        setDepartmentList(postResult);
      } else {
      }
    });
  };

  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    staffLst();
  }, []);

  const staffLst = () => {
    const BranchID = "" + User.BranchID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      DFlag: "STAFF",
      BranchID: BranchID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/ref-values`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setStaffList(postResult);
      } else {
        setStaffList([]);
      }
    });
  };

  //API to hit Designation list
  const [designationList, setDesignationList] = useState([]);

  useEffect(() => {
    desgList();
  }, [formValue.fromDepartment]);

  const desgList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      DepartID: formValue.fromDepartment,
      IsActive: "A",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/designation`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setDesignationList(postResult);
      } else {
        setDesignationList([]);
      }
    });
  };

  //API to hit Designation list
  const [toDesignationList, setToDesignationList] = useState([]);

  useEffect(() => {
    todesgList();
  }, [formValue.toDepartment]);

  const todesgList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      DepartID: formValue.toDepartment,
      IsActive: "A",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/designation`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setToDesignationList(postResult);
      } else {
        setToDesignationList([]);
      }
    });
  };

  //API to hit Shift list
  const [shiftList, setShiftList] = useState([]);
  useEffect(() => {
    shftList();
  }, []);

  const shftList = () => {
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      BranchID: Branch,
      FiscalID: Fiscal,
      Type: "POST",
      FetchURL: `${appURL}api/admin/shift`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setShiftList(postResult);
      } else {
        setShiftList([]);
      }
    });
  };

  const [branchList, setBranchList] = useState([]);

  useEffect(() => {
    brnchList();
  }, []);

  const brnchList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      IsActive: "-1",
      Type: "POST",
      FetchURL: `${appURL}api/admin/branch`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setBranchList(postResult);
      } else {
        setBranchList([]);
      }
    });
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container newpopup-bg addPromotionPopBg">
        <div className="newpopup addPromotionPop" style={{ width: "50%" }}>
          <div className="newpopup-head ps-0 pe-0">
            <div className="popUpTitle">
              {" "}
              {mode === "en" ? "Promotion" : "पदोन्नति"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div className="newpopup-body ps-3 pe-3">
            <div className="form-in-padding">
              <div className="row text-start mt-2 ">
                <div className="col-md-12 col-sm-12 col-lg-12">
                  <div className="row text-start ">
                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="form-group mt-0">
                        <label htmlFor="staff" style={{ fontSize: "12px" }}>
                          {mode === "en"
                            ? "Select Staff"
                            : "कर्मचारी चयन गर्नुहोस्"}{" "}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example"
                          name="staff"
                          value={formValue.staff}
                          onChange={handleChange}
                        >
                          <option disabled value="" selected>
                            {mode === "en"
                              ? "Select Staff"
                              : "कर्मचारी चयन गर्नुहोस्"}
                          </option>
                          {staffList.map((item) => (
                            <option key={item.UserID} value={item.UserID}>
                              {item.FullName}
                            </option>
                          ))}
                        </select>
                        {formError.staff && (
                          <p className="errormsg">{formError.staff}</p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="form-group mt-0">
                        <label className="d-block text-start dark-gray mb-1">
                          {mode === "en" ? "Select Type" : "टाइप चयन गर्नुहोस्"}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="type"
                          value={formValue.type}
                          onChange={handleChange}
                          className="form-select form-select-sm"
                        >
                          <option
                            value="0"
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            {mode === "en"
                              ? "Select Type"
                              : "टाइप चयन गर्नुहोस्"}
                          </option>
                          <option value="P">
                            {mode === "en" ? "Promotion" : "पदोन्नति"}
                          </option>
                          <option value="T">
                            {mode === "en" ? "Transfer" : "स्थानान्तरण"}
                          </option>
                        </select>
                        {formError.type && (
                          <p className="errormsg">{formError.type}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row text-start ">
                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <form action="">
                        <fieldset>
                          <legend>From</legend>
                          <div>
                            <div className="form-group">
                              <label
                                htmlFor="fromDepartment"
                                style={{ fontSize: "12px" }}
                              >
                                {mode === "en"
                                  ? "Select Department"
                                  : "विभाग चयन गर्नुहोस्"}
                                <sup style={{ color: "red" }}>*</sup>
                              </label>
                              <select
                                class="form-select form-select-sm"
                                aria-label="Default select example"
                                name="fromDepartment"
                                value={formValue.fromDepartment}
                                onChange={handleChange}
                              >
                                <option disabled value="" selected>
                                  {mode === "en"
                                    ? "Select Department"
                                    : "विभाग चयन गर्नुहोस्"}
                                </option>

                                {departmentList.map((item) => (
                                  <option
                                    key={item.DepartmentID}
                                    value={item.DepartmentID}
                                  >
                                    {item.Department}
                                  </option>
                                ))}
                              </select>
                              {formError.fromDepartment && (
                                <p className="errormsg">
                                  {formError.fromDepartment}
                                </p>
                              )}
                            </div>

                            <div className="form-group">
                              <label
                                htmlFor="fromDesignation"
                                style={{ fontSize: "12px" }}
                              >
                                {mode === "en"
                                  ? "Select Designation"
                                  : "पदनाम चयन गर्नुहोस्"}
                                <sup style={{ color: "red" }}>*</sup>
                              </label>
                              <select
                                class="form-select form-select-sm"
                                aria-label="Default select example"
                                name="fromDesignation"
                                value={formValue.fromDesignation}
                                onChange={handleChange}
                              >
                                <option disabled value="" selected>
                                  {mode === "en"
                                    ? "Select Designation"
                                    : "पदनाम चयन गर्नुहोस्"}
                                </option>

                                {designationList.map((item) => (
                                  <option
                                    key={item.DesignationID}
                                    value={item.DesignationID}
                                  >
                                    {item.Designation}
                                  </option>
                                ))}
                              </select>
                              {formError.fromDesignation && (
                                <p className="errormsg">
                                  {formError.fromDesignation}
                                </p>
                              )}
                            </div>

                            <div className="form-group">
                              <label
                                htmlFor="fromJobType"
                                style={{ fontSize: "12px" }}
                              >
                                {mode === "en"
                                  ? "Select Job Type"
                                  : "कामको प्रकार चयन गर्नुहोस्"}
                                <sup style={{ color: "red" }}>*</sup>
                              </label>
                              <select
                                class="form-select form-select-sm"
                                aria-label="Default select example"
                                name="fromJobType"
                                value={formValue.fromJobType}
                                onChange={handleChange}
                              >
                                <option disabled value="" selected>
                                  {mode === "en"
                                    ? "Select Job Type"
                                    : "कामको प्रकार चयन गर्नुहोस्"}
                                </option>
                                <option value="1">
                                  {mode === "en" ? "Full Time" : "पुरै समय"}
                                </option>
                                <option value="2">
                                  {mode === "en" ? "Part Time" : "आंशिक समय"}
                                </option>
                                <option value="3">
                                  {mode === "en" ? "Intern" : "इन्टर्न"}
                                </option>
                                <option value="4">
                                  {mode === "en"
                                    ? "Paid Intern"
                                    : "भुक्तान गरिएको इन्टर्न"}
                                </option>
                                <option value="5">
                                  {mode === "en" ? "Freelance" : "स्वतन्त्र"}
                                </option>
                                <option value="6">
                                  {mode === "en" ? "Contract" : "सम्झौता"}
                                </option>
                                <option value="7">
                                  {mode === "en" ? "Training" : "प्रशिक्षण"}
                                </option>
                              </select>
                              {formError.fromJobType && (
                                <p className="errormsg">
                                  {formError.fromJobType}
                                </p>
                              )}
                            </div>

                            <div className="row text-start px-2">
                              <div className="col-md-6 col-sm-6 col-lg-6">
                                <div className="form-group">
                                  <label
                                    htmlFor="fromShift"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {mode === "en"
                                      ? "Select Shift"
                                      : "शिफ्ट चयन गर्नुहोस्"}
                                    <sup style={{ color: "red" }}>*</sup>
                                  </label>
                                  <select
                                    class="form-select form-select-sm"
                                    aria-label="Default select example"
                                    name="fromShift"
                                    value={formValue.fromShift}
                                    onChange={handleChange}
                                  >
                                    <option disabled value="" selected>
                                      {mode === "en"
                                        ? "Select Shift"
                                        : "शिफ्ट चयन गर्नुहोस्"}
                                    </option>
                                    {shiftList.map((item) => (
                                      <option
                                        key={item.ShiftID}
                                        value={item.ShiftID}
                                      >
                                        {item.Shift}
                                      </option>
                                    ))}
                                  </select>
                                  {formError.fromShift && (
                                    <p className="errormsg">
                                      {formError.fromShift}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6 col-sm-6 col-lg-6">
                                <div className="form-group">
                                  <label
                                    htmlFor="fromShiftType"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {mode === "en"
                                      ? "Select Shift Type"
                                      : "शिफ्ट प्रकार चयन गर्नुहोस्"}
                                    <sup style={{ color: "red" }}>*</sup>
                                  </label>
                                  <select
                                    class="form-select form-select-sm"
                                    aria-label="Default select example"
                                    name="fromShiftType"
                                    value={formValue.fromShiftType}
                                    onChange={handleChange}
                                  >
                                    <option disabled value="" selected>
                                      {mode === "en"
                                        ? "Select Shift Type"
                                        : "शिफ्ट प्रकार चयन गर्नुहोस्"}
                                    </option>
                                    <option value="1">
                                      {" "}
                                      {mode === "en" ? "Weekly" : "साप्ताहिक"}
                                    </option>
                                    <option value="2">
                                      {" "}
                                      {mode === "en" ? "Monthly" : "मासिक"}
                                    </option>
                                    <option value="3">
                                      {" "}
                                      {mode === "en" ? "Yearly" : "वार्षिक"}
                                    </option>
                                  </select>
                                  {formError.fromShiftType && (
                                    <p className="errormsg">
                                      {formError.fromShiftType}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <label
                                htmlFor="fromGrade"
                                style={{ fontSize: "12px" }}
                              >
                                {mode === "en"
                                  ? "Select Grade"
                                  : "ग्रेड चयन गर्नुहोस्"}
                                <sup style={{ color: "red" }}>*</sup>
                              </label>
                              <select
                                class="form-select form-select-sm"
                                aria-label="Default select example"
                                name="fromGrade"
                                value={formValue.fromGrade}
                                onChange={handleChange}
                              >
                                <option disabled value="" selected>
                                  {mode === "en"
                                    ? "Select Grade"
                                    : "ग्रेड चयन गर्नुहोस्"}
                                </option>
                                <option value="0">
                                  {" "}
                                  {mode === "en" ? "A" : "ए"}
                                </option>
                              </select>
                              {formError.fromGrade && (
                                <p className="errormsg">
                                  {formError.fromGrade}
                                </p>
                              )}
                            </div>

                            <div className="form-group">
                              <label
                                htmlFor="fromBranch"
                                style={{ fontSize: "12px" }}
                              >
                                {mode === "en"
                                  ? "Select Branch"
                                  : "साखा चयन गर्नुहोस्"}
                                <sup style={{ color: "red" }}>*</sup>
                              </label>
                              <select
                                class="form-select form-select-sm"
                                aria-label="Default select example"
                                name="fromBranch"
                                value={formValue.fromBranch}
                                onChange={handleChange}
                              >
                                <option disabled value="" selected>
                                  {mode === "en"
                                    ? "Select Branch"
                                    : "साखा चयन गर्नुहोस्"}
                                </option>

                                {branchList.map((item) => (
                                  <option
                                    key={item.BranchID}
                                    value={item.BranchID}
                                  >
                                    {item.Name}
                                  </option>
                                ))}
                              </select>
                              {formError.fromBranch && (
                                <p className="errormsg">
                                  {formError.fromBranch}
                                </p>
                              )}
                            </div>
                          </div>
                        </fieldset>
                      </form>
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <form action="">
                        <fieldset>
                          <legend>To</legend>
                          <div className="">
                            <div className="form-group">
                              <label
                                htmlFor="toDepartment"
                                style={{ fontSize: "12px" }}
                              >
                                {mode === "en"
                                  ? "Select Department"
                                  : "विभाग चयन गर्नुहोस्"}
                                <sup style={{ color: "red" }}>*</sup>
                              </label>
                              <select
                                class="form-select form-select-sm"
                                aria-label="Default select example"
                                name="toDepartment"
                                value={formValue.toDepartment}
                                onChange={handleChange}
                              >
                                <option disabled value="" selected>
                                  {mode === "en"
                                    ? "Select Department"
                                    : "विभाग चयन गर्नुहोस्"}
                                </option>

                                {departmentList.map((item) => (
                                  <option
                                    key={item.DepartmentID}
                                    value={item.DepartmentID}
                                  >
                                    {item.Department}
                                  </option>
                                ))}
                              </select>
                              {formError.toDepartment && (
                                <p className="errormsg">
                                  {formError.toDepartment}
                                </p>
                              )}
                            </div>

                            <div className="form-group">
                              <label
                                htmlFor="toDesignation"
                                style={{ fontSize: "12px" }}
                              >
                                {mode === "en"
                                  ? "Select Designation"
                                  : "पदनाम चयन गर्नुहोस्"}
                                <sup style={{ color: "red" }}>*</sup>
                              </label>
                              <select
                                class="form-select form-select-sm"
                                aria-label="Default select example"
                                name="toDesignation"
                                value={formValue.toDesignation}
                                onChange={handleChange}
                              >
                                <option disabled value="" selected>
                                  {mode === "en"
                                    ? "Select Designation"
                                    : "पदनाम चयन गर्नुहोस्"}
                                </option>

                                {toDesignationList.map((item) => (
                                  <option
                                    key={item.DesignationID}
                                    value={item.DesignationID}
                                  >
                                    {item.Designation}
                                  </option>
                                ))}
                              </select>
                              {formError.toDesignation && (
                                <p className="errormsg">
                                  {formError.toDesignation}
                                </p>
                              )}
                            </div>

                            <div className="form-group">
                              <label
                                htmlFor="toJobType"
                                style={{ fontSize: "12px" }}
                              >
                                {mode === "en"
                                  ? "Select Job Type"
                                  : "कामको प्रकार चयन गर्नुहोस्"}
                                <sup style={{ color: "red" }}>*</sup>
                              </label>
                              <select
                                class="form-select form-select-sm"
                                aria-label="Default select example"
                                name="toJobType"
                                value={formValue.toJobType}
                                onChange={handleChange}
                              >
                                <option disabled value="" selected>
                                  {mode === "en"
                                    ? "Select Job Type"
                                    : "कामको प्रकार चयन गर्नुहोस्"}
                                </option>
                                <option value="1">
                                  {mode === "en" ? "Full Time" : "पुरै समय"}
                                </option>
                                <option value="2">
                                  {mode === "en" ? "Part Time" : "आंशिक समय"}
                                </option>
                                <option value="3">
                                  {mode === "en" ? "Intern" : "इन्टर्न"}
                                </option>
                                <option value="4">
                                  {mode === "en"
                                    ? "Paid Intern"
                                    : "भुक्तान गरिएको इन्टर्न"}
                                </option>
                                <option value="5">
                                  {mode === "en" ? "Freelance" : "स्वतन्त्र"}
                                </option>
                                <option value="6">
                                  {mode === "en" ? "Contract" : "सम्झौता"}
                                </option>
                                <option value="7">
                                  {mode === "en" ? "Training" : "प्रशिक्षण"}
                                </option>
                              </select>
                              {formError.toJobType && (
                                <p className="errormsg">
                                  {formError.toJobType}
                                </p>
                              )}
                            </div>

                            <div className="row text-start px-2">
                              <div className="col-md-6 col-sm-6 col-lg-6">
                                <div className="form-group">
                                  <label
                                    htmlFor="toShift"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {mode === "en"
                                      ? "Select Shift"
                                      : "शिफ्ट चयन गर्नुहोस्"}
                                    <sup style={{ color: "red" }}>*</sup>
                                  </label>
                                  <select
                                    class="form-select form-select-sm"
                                    aria-label="Default select example"
                                    name="toShift"
                                    value={formValue.toShift}
                                    onChange={handleChange}
                                  >
                                    <option disabled value="" selected>
                                      {mode === "en"
                                        ? "Select Shift"
                                        : "शिफ्ट चयन गर्नुहोस्"}
                                    </option>
                                    {shiftList.map((item) => (
                                      <option
                                        key={item.ShiftID}
                                        value={item.ShiftID}
                                      >
                                        {item.Shift}
                                      </option>
                                    ))}
                                  </select>
                                  {formError.toShift && (
                                    <p className="errormsg">
                                      {formError.toShift}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6 col-sm-6 col-lg-6">
                                <div className="form-group">
                                  <label
                                    htmlFor="toShiftType"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {mode === "en"
                                      ? "Select Shift Type"
                                      : "शिफ्ट प्रकार चयन गर्नुहोस्"}
                                    <sup style={{ color: "red" }}>*</sup>
                                  </label>
                                  <select
                                    class="form-select form-select-sm"
                                    aria-label="Default select example"
                                    name="toShiftType"
                                    value={formValue.toShiftType}
                                    onChange={handleChange}
                                  >
                                    <option disabled value="" selected>
                                      {mode === "en"
                                        ? "Select Shift Type"
                                        : "शिफ्ट प्रकार चयन गर्नुहोस्"}
                                    </option>
                                    <option value="1">
                                      {" "}
                                      {mode === "en" ? "Weekly" : "साप्ताहिक"}
                                    </option>
                                    <option value="2">
                                      {" "}
                                      {mode === "en" ? "Monthly" : "मासिक"}
                                    </option>
                                    <option value="3">
                                      {" "}
                                      {mode === "en" ? "Yearly" : "वार्षिक"}
                                    </option>
                                  </select>
                                  {formError.toShiftType && (
                                    <p className="errormsg">
                                      {formError.toShiftType}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <label
                                htmlFor="toGrade"
                                style={{ fontSize: "12px" }}
                              >
                                {mode === "en"
                                  ? "Select Grade"
                                  : "ग्रेड चयन गर्नुहोस्"}
                                <sup style={{ color: "red" }}>*</sup>
                              </label>
                              <select
                                class="form-select form-select-sm"
                                aria-label="Default select example"
                                name="toGrade"
                                value={formValue.toGrade}
                                onChange={handleChange}
                              >
                                <option disabled value="" selected>
                                  {mode === "en"
                                    ? "Select Grade"
                                    : "ग्रेड चयन गर्नुहोस्"}
                                </option>
                                <option value="0">
                                  {" "}
                                  {mode === "en" ? "A" : "ए"}
                                </option>
                              </select>
                              {formError.toGrade && (
                                <p className="errormsg">{formError.toGrade}</p>
                              )}
                            </div>

                            <div className="form-group">
                              <label
                                htmlFor="toBranch"
                                style={{ fontSize: "12px" }}
                              >
                                {mode === "en"
                                  ? "Select Branch"
                                  : "साखा चयन गर्नुहोस्"}
                                <sup style={{ color: "red" }}>*</sup>
                              </label>
                              <select
                                class="form-select form-select-sm"
                                aria-label="Default select example"
                                name="toBranch"
                                value={formValue.toBranch}
                                onChange={handleChange}
                              >
                                <option disabled value="" selected>
                                  {mode === "en"
                                    ? "Select Branch"
                                    : "साखा चयन गर्नुहोस्"}
                                </option>

                                {branchList.map((item) => (
                                  <option
                                    key={item.BranchID}
                                    value={item.BranchID}
                                  >
                                    {item.Name}
                                  </option>
                                ))}
                              </select>
                              {formError.toBranch && (
                                <p className="errormsg">{formError.toBranch}</p>
                              )}
                            </div>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                  </div>

                  <div className="row text-start">
                    <div className="form-group mt-0">
                      <div class="form-check" style={{ fontSize: "12px" }}>
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="isApproved"
                          name="isApproved"
                          checked={isApproved}
                          onChange={() => setIsApproved(!isApproved)}
                        />
                        <label
                          style={{ fontSize: "12px", cursor: "pointer" }}
                          class="form-check-label"
                          htmlFor="isApproved"
                        >
                          {mode === "en" ? "Is Approved" : "स्वीकृत छ"}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="new-footer">
            <div className="row  mt-1 mb-1">
              <div>
                <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={formSubmit}
                >
                  {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
