import React, { useContext, useState, useEffect } from "react";
import CloseIcon from "../../../../images/CloseIcon.svg";
import Plus from "../../../../images/Plus.png";
import EliteJobContext from "../../EliteJobContext/EliteJobContext";
import CandidateContext from "../../CandidateContext/CandidateContext";
import UpperbarContext from "../../../context/upperbar-context";
import $ from "jquery";
export default function CandidateForm({ closePopup }) {
  const context = useContext(CandidateContext);
  const { mode } = useContext(UpperbarContext);

  const {
    togglePopup,
    candidateFormError,
    setCandidateFormError,
    isSubmit,
    setIsSubmit,
    logBookValue,
    candidateFormValue,
    setCandidateFormValue,
    setCloseChecked,
    closeChecked,

    isCVUploaded,
    setIsCVUploaded,
    setTypeCVFile,
    cvFile,
    setCVFile,

    isCoverUploaded,
    setIsCoverUploaded,
    setTypeCoverFile,
    coverFile,
    setCoverFile,
    popup,
    fetchdata,
  } = context;

  const { jobList } = useContext(EliteJobContext);
  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setCandidateFormValue({ ...candidateFormValue, [name]: value });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setCandidateFormError(validate(candidateFormValue));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (popup) {
      $(".addCandidateFormBg").fadeIn(500);
      $(".addCandidateForm").fadeIn(500);
    }
  }, [popup]);
  useEffect(() => {
    if (Object.keys(candidateFormError).length === 0 && isSubmit) {
      fetchdata(candidateFormValue);
      if (closeChecked === true) {
        togglePopup();
      }
      setCandidateFormValue(candidateFormValue);

      setIsSubmit(false);
      setCloseChecked(false);
    }
  }, [candidateFormError]);

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Required";
    }
    if (!values.contactno) {
      errors.contactno = "Required";
    }
    if (!values.email) {
      errors.email = "Required";
    }
    if (!values.message) {
      errors.message = "Required";
    }
    if (candidateFormValue.cvRadio === "cvFile") {
      if (cvFile === "") {
        if (!values.cv) {
          errors.cv = "Required";
        }
      }
    }

    return errors;
  };
  function handlePdfChange(e) {
    if (e.target.files && e.target.files[0]) {
      setTypeCVFile(e.target.files[0].type);
      setCVFile(e.target.files[0].name);
      let reader = new FileReader();

      reader.onload = function (e) {
        setTypeCVFile(e.target.result);
        setIsCVUploaded(true);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }
  function handleCoverChange(e) {
    if (e.target.files && e.target.files[0]) {
      setTypeCoverFile(e.target.files[0].type);
      setCoverFile(e.target.files[0].name);
      let reader = new FileReader();

      reader.onload = function (e) {
        setTypeCoverFile(e.target.result);
        setIsCoverUploaded(true);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  return (
    <>
      <div className="newpopup-body ps-3 pe-3">
        <div className="col-md-12 col-sm-12 col-lg-12">
          <form>
            <div className="form-padding">
              <div className="row form-row">
                <div className="form-group col-md-12">
                  <label
                    className="form-label"
                    htmlFor="name"
                    style={{ fontSize: "12px" }}
                  >
                    Name<sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="name"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="name"
                    value={candidateFormValue.name}
                    onChange={handleChange}
                  />
                  <p className="errormsg " style={{ marginBottom: "0" }}>
                    {candidateFormError.name}
                  </p>
                </div>
              </div>
              <div className="row form-row">
                <div className="form-group col-md-6">
                  <label
                    className="form-label"
                    htmlFor="contactno"
                    style={{ fontSize: "12px" }}
                  >
                    Contact<sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="contactno"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="contactno"
                    value={candidateFormValue.contactno}
                    onChange={handleChange}
                  />
                  <p className="errormsg " style={{ marginBottom: "0" }}>
                    {candidateFormError.contactno}
                  </p>
                </div>
                <div className="form-group col-md-6">
                  <label
                    className="form-label"
                    htmlFor="email"
                    style={{ fontSize: "12px" }}
                  >
                    Email<sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="email"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="email"
                    value={candidateFormValue.email}
                    onChange={handleChange}
                  />
                  <p className="errormsg " style={{ marginBottom: "0" }}>
                    {candidateFormError.email}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="form-group">
                  <label
                    className="form-label"
                    htmlFor="message"
                    style={{ fontSize: "12px" }}
                  >
                    Message<sup style={{ color: "red" }}>*</sup>
                  </label>
                  <textarea
                    id="message"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="message"
                    value={candidateFormValue.message}
                    onChange={handleChange}
                    rows="3"
                  />
                  <p className="errormsg " style={{ marginBottom: "0" }}>
                    {candidateFormError.message}
                  </p>
                </div>
              </div>

              <div className="row text-start mt-3">
                <label className="form-label" style={{ fontSize: "12px" }}>
                  CV<sup style={{ color: "red" }}>*</sup>
                </label>
                {/* <div className="form-group">
              <input
                id="cvUrl"
                style={{ fontSize: "13px" }}
                type="radio"
                name="cvRadio"
                value="cvUrl"
                onChange={handleChange}
                checked={candidateFormValue.cvRadio === "cvUrl"}
              />
              <label
                for="cvUrl"
                style={{
                  fontSize: "12px",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  cursor: "pointer",
                }}
                className="mr-3"
              >
                URL
              </label>

              <input
                id="cvFile"
                style={{ fontSize: "12px" }}
                type="radio"
                name="cvRadio"
                value="cvFile"
                onChange={handleChange}
                checked={candidateFormValue.cvRadio === "cvFile"}
              />
              <label
                for="cvFile"
                style={{
                  fontSize: "12px",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  cursor: "pointer",
                }}
              >
                File
              </label>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {candidateFormError.cv}
              </p>
            </div> */}
              </div>

              {candidateFormValue.cvRadio === "cvFile" && (
                <div className="row text-start">
                  <div className="">
                    {!isCVUploaded ? (
                      <>
                        <div className="pdfFile w-100">
                          <label htmlFor="pdfData">
                            <div
                              class="btn btn-sm"
                              style={{
                                background: "var(--button-color)",
                                color: "white",
                              }}
                            >
                              Upload Pdf
                            </div>
                          </label>

                          <input
                            id="pdfData"
                            type="file"
                            name="cv"
                            accept=".pdf"
                            onChange={handlePdfChange}
                            className="d-none"
                            value={candidateFormValue.cv}
                          />
                        </div>
                        <p className="errormsg " style={{ marginBottom: "0" }}>
                          {candidateFormError.cv}
                        </p>
                      </>
                    ) : (
                      <>
                        <div className="w-100 ">
                          <div className="uk-position-relative">
                            <input
                              style={{ fontSize: "13px" }}
                              type="text"
                              className="form-control form-control-sm "
                              value={cvFile}
                              disabled
                            />
                            <img
                              className="close-icon"
                              src={CloseIcon}
                              alt="CloseIcon"
                              onClick={() => {
                                setIsCVUploaded(false);
                                setTypeCVFile(null);
                                setCVFile("");
                              }}
                              style={{
                                position: "absolute",
                                top: "50%",
                                transform: "translateY(-50%)",
                                height: " 15px",
                              }}
                            />
                          </div>
                        </div>
                        <p className="errormsg " style={{ marginBottom: "0" }}>
                          {candidateFormError.cv}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              )}

              <div className="row text-start mt-3">
                <label className="form-label" style={{ fontSize: "12px" }}>
                  Cover letter
                </label>
                {/* <div className="form-group">
              <input
                id="coverUrl"
                style={{ fontSize: "13px" }}
                type="radio"
                name="coverRadio"
                value="coverUrl"
                onChange={handleChange}
                checked={candidateFormValue.coverRadio === "coverUrl"}
              />
              <label
                for="coverUrl"
                style={{
                  fontSize: "12px",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  cursor: "pointer",
                }}
                className="mr-3"
              >
                URL
              </label>

              <input
                id="coverFile"
                style={{ fontSize: "12px" }}
                type="radio"
                name="coverRadio"
                value="coverFile"
                onChange={handleChange}
                checked={candidateFormValue.coverRadio === "coverFile"}
              />
              <label
                for="coverFile"
                style={{
                  fontSize: "12px",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  cursor: "pointer",
                }}
              >
                File
              </label>

              <p className="errormsg " style={{ marginBottom: "0" }}>
                {candidateFormError.cover}
              </p>
            </div> */}
              </div>

              {candidateFormValue.coverRadio === "coverFile" && (
                <div className="row text-start ">
                  <div className="">
                    {!isCoverUploaded ? (
                      <>
                        <div className="pdfFile w-100">
                          <label htmlFor="coverData">
                            <div
                              class="btn btn-sm"
                              style={{
                                background: "var(--button-color)",
                                color: "white",
                              }}
                            >
                              Upload Pdf
                            </div>
                          </label>

                          <input
                            id="coverData"
                            type="file"
                            accept=".pdf"
                            onChange={handleCoverChange}
                            className="d-none"
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="w-100 ">
                          <div className="uk-position-relative">
                            <input
                              style={{ fontSize: "13px" }}
                              type="text"
                              className="form-control form-control-sm "
                              disabled
                              value={coverFile}
                            />
                            <img
                              className="close-icon"
                              src={CloseIcon}
                              alt="CloseIcon"
                              onClick={() => {
                                setIsCoverUploaded(false);
                                setTypeCoverFile(null);
                                setCoverFile("");
                              }}
                              style={{
                                position: "absolute",
                                top: "50%",
                                transform: "translateY(-50%)",
                                height: " 15px",
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      <div className="new-footer">
        <div className="row  mt-1 mb-1">
          <div>
            <button
              type="button"
              class="btn btn-sm me-2"
              style={{ background: "var(--button-color)", color: "white" }}
              onClick={formSubmit}
            >
              {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
            </button>
            <button
              type="button"
              class="btn btn-sm btn-danger me-3"
              style={{ color: "white" }}
              onClick={closePopup}
            >
              {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
