import React, { useState, useEffect, useContext } from "react";
import CompanyContext from "./CompanyContext";
import { Fetchdata } from "../../../../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import AuthContext from "../../../../context/auth-context";
import UpperbarContext from "../../../../context/upperbar-context";
import $ from "jquery";
import { GetNepaliDate } from "../../../../hooks/dateConvertor";

function CompanyState(props) {
  const { User } = useContext(AuthContext);
  const { appURL } = useContext(UpperbarContext);
  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const [popup, setPopup] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [perEditSubmit, setPerEditSubmit] = useState(false);
  const [millionFormat, setMillionFormat] = useState(false);
  const [allowSpace, setAllowSpace] = useState(false);

  var date = new Date();

  const initalvalue = {
    companyName: "",
    country: "",
    state: "",
    city: "",
    address: "",
    postalCode: "",
    contact: "",
    telephone: "",
    pan: "",
    email: "",
    website: "",
    companyRegistration: "",
    fiscal: GetNepaliDate(date),
    bookStart: GetNepaliDate(date),
    decimal: "",
    word: "",
    symbol: "",
    currency: "",
    user: "",
    feature: "",
    password: "",
  };
  const [formValues, setFormValues] = useState(initalvalue);
  const [formErrors, setFormErrors] = useState({});

  //  API to show main group list
  const [companyList, setCompanyList] = useState([]);

  useEffect(() => {
    companyLst();
  }, [submit, perEditSubmit]);

  const companyLst = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      IsActive: "-1",
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/company`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setCompanyList(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setCompanyList([]);
        setLoading(false);
      }
    });
  };

  //  API to edit main group
  const [re, setRe] = useState();
  const [perID, setPerId] = useState();
  const [editPopup, setEditPopup] = useState(false);
  const handleEdit = (data) => {
    setPerId(data.CID);
    setEditPopup(true);
    setRe(Math.random());
  };

  const editData = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID,
      Flag: "U",
      CID: perID,
      ComName: formValues.companyName !== " " ? formValues.companyName : " ",
      ComLogo: formValues.groupName !== " " ? formValues.groupName : " ",
      ComWebsite: formValues.website !== " " ? formValues.website : " ",
      ComAddress: formValues.address !== " " ? formValues.address : " ",
      ComCountry: formValues.country !== " " ? formValues.country : " ",
      ComState: formValues.state !== " " ? formValues.state : " ",
      ComCity: formValues.groupName !== " " ? formValues.groupName : " ",
      ComPostal: formValues.postalCode !== " " ? formValues.postalCode : " ",
      ComPAN: formValues.pan !== " " ? formValues.pan : " ",
      ComContact: formValues.contact !== " " ? formValues.contact : " ",
      ComTel: formValues.telephone !== " " ? formValues.telephone : " ",
      ComEmail: formValues.email !== " " ? formValues.email : " ",
      ComNoOfUser: formValues.user !== " " ? formValues.user : " ",
      ComFeatAccess: formValues.groupName !== " " ? formValues.groupName : " ",
      ComRegDate:
        formValues.companyRegistration !== " "
          ? formValues.companyRegistration
          : " ",
      ComFiscalStart: formValues.fiscal !== " " ? formValues.fiscal : " ",
      ComBookStart: formValues.bookStart !== " " ? formValues.bookStart : " ",
      DecSupport: formValues.groupName !== " " ? formValues.groupName : " ",
      BaseCurrencySymbol: formValues.symbol !== " " ? formValues.symbol : " ",
      BaseCurrencyName: formValues.currency !== " " ? formValues.currency : " ",
      CurrencyFormat: formValues.groupName !== " " ? formValues.groupName : " ",
      AmountCurrencySpace:
        formValues.groupName !== " " ? formValues.groupName : " ",
      DecimalPlace: formValues.decimal !== " " ? formValues.decimal : " ",
      FetchURL: `${appURL}api/acc/admin/company`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (resp) {
      if (resp.StatusCode === 200) {
        setEditPopup(false);
        $(".editCompanyPopBg").fadeOut(300);
        $(".editCompanyPop").slideUp(500);
        companyLst();
        toast.success(resp.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + resp.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  // API to get main group info
  useEffect(() => {
    infoList();
  }, [perID]);

  const infoList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID,
      Flag: "SI",
      CID: perID,
      FetchURL: `${appURL}api/acc/admin/company`,
      Type: "POST",
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const data = result.Values[0] ? result.Values[0] : "";
        setFormValues({
          companyName: data.companyName,
          country: data.country,
          state: data.state,
          address: data.address,
          postalCode: data.postalCode,
          contact: data.contact,
          telephone: data.telephone,
          pan: data.pan,
          email: data.email,
          website: data.website,
          companyRegistration: data.companyRegistration,
          fiscal: data.fiscal,
          bookStart: data.bookStart,
          decimal: data.decimal,
          word: data.word,
          symbol: data.symbol,
          currency: data.currency,
          user: data.user,
          feature: data.feature,
          password: data.password,
        });
        // setChecked()
      } else {
      }
    });
  };

  // API to hit main group status
  const stat = [];
  const [newStatus, setNewStatus] = useState(stat);

  const deactivateData = (ID, IsActive) => {
    const UserID = "" + User.UID;
    const id = "" + ID;
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: id,
      UserID: UserID,
      Flag: "AI",
      CID: "1",
      IsActive: IsActive,
      FetchURL: `${appURL}api/acc/admin/company`,
      Type: "POST",
    };
    if (IsActive === "A") {
      dataForm.IsActive = "I";
    } else {
      dataForm.IsActive = "A";
    }
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        companyLst();
        let NewstatsN = JSON.parse(JSON.stringify(newStatus));
        let pitchNewStatus;

        if (dataForm.IsActive === "A") {
          pitchNewStatus = "Activated";
        } else if (dataForm.IsActive === "I") {
          pitchNewStatus = "Deactivated";
        }
        setNewStatus(NewstatsN);
        toast.success(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  //   API to get Country

  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    countryLst();
  }, []);

  const countryLst = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      DFlag: "COUNTRY",
      Flag: "S",
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/ref-values`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setCountryList(postResult);
      } else {
        setCountryList([]);
      }
    });
  };

  //   API to get State

  const [stateList, setStateList] = useState([]);

  useEffect(() => {
    stateLst();
  }, []);

  const stateLst = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      DFlag: "STATE",
      Flag: "S",
      Value: "NP",
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/ref-values`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setStateList(postResult);
      } else {
        setStateList([]);
      }
    });
  };

  return (
    <CompanyContext.Provider
      value={{
        loading,
        setLoading,
        originalList,
        setOriginalList,
        popup,
        setPopup,
        submit,
        setSubmit,
        perEditSubmit,
        setPerEditSubmit,
        initalvalue,
        formValues,
        setFormValues,
        formErrors,
        setFormErrors,
        companyList,
        setCompanyList,
        editPopup,
        setEditPopup,
        handleEdit,
        editData,
        deactivateData,
        companyLst,
        millionFormat,
        setMillionFormat,
        allowSpace,
        setAllowSpace,
        countryList,
        setCountryList,
        stateList,
        setStateList,
        re,
        setRe,
      }}
    >
      {props.children}
    </CompanyContext.Provider>
  );
}

export default CompanyState;
