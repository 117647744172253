import React, { useContext, useEffect, useState, useRef } from "react";
import CloseIcon from "../../../../images/CloseIcon.svg";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import $ from "jquery";
import Toast from "../../../Toast";
import UpperbarContext from "../../../context/upperbar-context";
import AuthContext from "../../../context/auth-context";

export default function RatePopup({
  popup,
  setPopup,
  submit,
  setSubmit,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
  inputList,
  setInputList,
  rateIndex,
  nameIndex,
  setAutoFocused,
  rateCurrency,
  voucherData,
}) {
  const { appURL, userDetails, sidePanelBg } = useContext(UpperbarContext);
  const { User } = useContext(AuthContext);

  const inputRef = useRef();

  const closePopUp = (e) => {
    setPopup(false);
    $(".addRatePopBg").fadeOut(300);
    $(".addRatePop").fadeOut(300);
    setSubmit(false);
    setFormErrors({});
  };

  useEffect(() => {
    if (popup) {
      $(".addRatePop").fadeIn(300);
      $(".addRatePopBg").fadeIn(300);
      inputRef.current.focus();
    }
  }, [popup]);

  const validate = (values) => {
    const errors = {};
    const numv = /^[0-9]+$/i;
    if (!values.rate) {
      errors.rate = "Required";
    } else if (!numv.test(values.rate)) {
      errors.rate = "Must be digits";
    }
    if (!values.ledgerRate) {
      errors.ledgerRate = "Required";
    } else if (!numv.test(values.ledgerRate)) {
      errors.ledgerRate = "Must be digits";
    }

    return errors;
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleKeyPress = (event) => {
    // Check if the key pressed is not a number
    if (isNaN(Number(event.key))) {
      event.preventDefault(); // Prevent the character from being entered
    }
  };

  const handleSubmit = (e) => {
    // e.preventDefault();
    setFormErrors(validate(formValues));
    setSubmit(true);
    setPopup(false);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && submit) {
      let name = nameIndex;
      // let ledgerName = "ledgerRate";
      const list = [...inputList];
      list[rateIndex][name] = formValues.ledgerRate * formValues.rate;
      // list[rateIndex][ledgerName] = formValues.ledgerRate;
      setInputList(list);
      $(".addRatePopBg").fadeOut(300);
      $(".addRatePop").fadeOut(300);
      // toast.success("Success", {
      //   style: {
      //     color: "green",
      //     fontSize: "13px",
      //   },
      //   theme: "light",
      // });
      setSubmit(false);
      // setAutoFocused(true);
      $(".crInputs").focus();
      if (voucherData === "PAV") {
        $(".drInputs").focus();
      }
    }
  }, [formErrors]);

  $(".rateInput").keydown(function (e) {
    if (e.which === 13) {
      var inputVal = $(this).val();

      if (inputVal.length > 0) {
        var index = $(".rateInput").index(this) + 1;
        $(".rateInput").eq(index).focus();
      }
    }
  });
  $(".onEnter").keydown(function (e) {
    if (e.which === 13) {
      if ($('.rateInput[value=""]').length > 0) {
        handleSubmit();
      }
    }
  });

  return (
    <>
      {/* <Toast /> */}
      <div className="container popup-wrapper addRatePopBg">
        <div className="popup-inner addRatePop" style={{ height: "30vh" }}>
          <div className="popUpHeader ps-0 pe-0" style={sidePanelBg}>
            <div className="popUpTitle">Select Rate</div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div
            className="popUpBody ps-3 pe-3"
            style={{ height: "auto", overflowY: "auto" }}
          >
            <div className="form-padding">
              <div className="row text-start uk-flex-middle">
                <div className="mt-2 d-flex uk-flex-right">
                  {userDetails.BaseCurrency} :{" "}
                  {formValues.ledgerRate * formValues.rate}
                </div>

                <div className="ledger-side">
                  <div className="form-group">
                    <label htmlFor="ledger" style={{ fontSize: "12px" }}>
                      Ledger Currency
                    </label>
                    <div className="uk-position-relative">
                      <input
                        id="ledger"
                        style={{ fontSize: "13px", paddingLeft: "2.7rem" }}
                        type="number"
                        className="form-control form-control-sm rateInput"
                        name="ledgerRate"
                        // placeholder="$  |"
                        value={formValues.ledgerRate}
                        onChange={handleChange}
                        autoComplete="off"
                        ref={inputRef}
                        onKeyPress={handleKeyPress}
                      />
                      <span>{rateCurrency}</span>
                    </div>
                    {formErrors.ledgerRate && (
                      <p className="errormsg">{formErrors.ledgerRate}</p>
                    )}
                  </div>
                </div>
                <div className="equal-side">
                  <div className="form-group mt-0 uk-flex-middle uk-flex uk-flex-center">
                    <p className="equal"> x </p>
                  </div>
                </div>
                <div className="base-side mt-3">
                  <div className="form-group mt-0">
                    <label htmlFor="ledger" style={{ fontSize: "12px" }}>
                      Base Currency
                    </label>
                    <div className="uk-position-relative">
                      <input
                        id="ledger"
                        style={{ fontSize: "13px", paddingLeft: "3.2rem" }}
                        type="number"
                        className="form-control form-control-sm rateInput onEnter"
                        name="rate"
                        // placeholder="Rs"
                        value={formValues.rate}
                        onChange={handleChange}
                        autoComplete="off"
                        onKeyPress={handleKeyPress}
                      />
                      <span>{userDetails.BaseCurrency}</span>
                    </div>
                    {formErrors.rate && (
                      <p className="errormsg">{formErrors.rate}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ln-popUpFooter">
            <div className="row  mt-1 mb-1">
              <div>
                <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
