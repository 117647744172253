import React, { useContext, useState, useEffect } from "react";
import { FaUser } from "react-icons/fa";
import AuthContext from "../context/auth-context";
import UpperbarContext from "../context/upperbar-context";
import { Fetchdata } from "../hooks/getData";
import Spinner from "../loading/spinner";
import { englishToNepaliNumber } from "nepali-number";

const Holiday = () => {
  const { appURL, mode } = useContext(UpperbarContext);
  const { User } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  const [holidayList, setHolidayList] = useState([]);
  useEffect(() => {
    holilst();
  }, []);

  const holilst = () => {
    const branch = "" + User.BranchID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      EvType: "-1",
      Flag: "S",
      BranchID: branch,
      Type: "POST",
      FetchURL: `${appURL}api/event`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setHolidayList(postResult);
        setLoading(false);
      } else {
        setHolidayList([]);
        setLoading(false);
      }
    });
  };

  if (holidayList.length > 0) {
    return (
      <>
        <div className="attendance mt-3">
          <table className="uk-table uk-table-striped">
            <thead className="sticky-head">
              <tr>
                <td className="fw-bold">{mode === "en" ? "Date" : "मिति"}</td>
                <td className="fw-bold">
                  {mode === "en" ? "Event Name" : "घटना नाम"}
                </td>
                <td className="fw-bold ">{mode === "en" ? "Day" : "दिन"}</td>
                <td className="fw-bold ">
                  {mode === "en" ? "Remaining Days" : "दिन बाँकी"}
                </td>
              </tr>
            </thead>
            <tbody>
              {holidayList.map((props) => {
                const { NepaliDate, Title, DaysRemaining } = props;
                const splitDate = (date) => {
                  let result = date.split("/");
                  return result;
                };

                let splittedDate = splitDate(NepaliDate);
                let year;
                if (mode === "en") {
                  year = splittedDate[0];
                } else {
                  year = englishToNepaliNumber(splittedDate[0]);
                }
                let month = splittedDate[1];

                let day;
                if (mode === "en") {
                  day = splittedDate[2];
                } else {
                  day = englishToNepaliNumber(splittedDate[2]);
                }

                var days = [
                  "Sunday",
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                ];

                var npdays = [
                  "आइतबार",
                  "सोमबार",
                  "मंगलबार",
                  "बुधबार",
                  "बिहिबार",
                  "शुक्रबार",
                  "शनिबार",
                ];
                var d = new Date(NepaliDate);
                if (mode === "en") {
                  var dayName = days[d.getDay()];
                } else {
                  var dayName = npdays[d.getDay()];
                }

                var months = [
                  "Baisakh",
                  "Jesth",
                  "Asar",
                  "Srawan",
                  "Bhadra",
                  "Aaswin",
                  "Kartik",
                  "Mangsir",
                  "Paush",
                  "Magh",
                  "Falgun",
                  "Chaitra",
                ];
                var npMonths = [
                  "बैशाख",
                  "जेष्ठ",
                  "असार",
                  "श्रावण",
                  "भाद्र",
                  "असोज",
                  "कार्तिक",
                  "मंसिर",
                  "पौष",
                  "माघ",
                  "फाल्गुन",
                  "चैत्र",
                ];
                var m = new Date(NepaliDate);
                if (mode === "en") {
                  var monthName = months[m.getMonth()];
                } else {
                  var monthName = npMonths[m.getMonth()];
                }

                return (
                  <tr>
                    <td>
                      {day} {monthName} {year}
                    </td>

                    <td>{Title}</td>

                    <td>{dayName}</td>

                    <td>
                      {DaysRemaining < 0 && DaysRemaining !== "-1" && (
                        <span>
                          {mode === "en"
                            ? Math.abs(DaysRemaining)
                            : englishToNepaliNumber(
                                Math.abs(DaysRemaining)
                              )}{" "}
                          {mode === "en" ? "days ago" : "दिन पहिले"}
                        </span>
                      )}
                      {DaysRemaining === "-1" && (
                        <span>{mode === "en" ? "Yesterday" : "हिजो"}</span>
                      )}
                      {DaysRemaining === "Today" && (
                        <span>{mode === "en" ? "Today" : "आज"}</span>
                      )}
                      {DaysRemaining === "1" && (
                        <span>{mode === "en" ? "Tommorow" : "भोलि"}</span>
                      )}
                      {DaysRemaining > 0 && DaysRemaining !== "1" && (
                        <span>
                          {mode === "en"
                            ? DaysRemaining
                            : englishToNepaliNumber(DaysRemaining)}{" "}
                          {mode === "en" ? "days remaining" : "दिन बाँकी"}
                        </span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="uk-flex uk-flex-center mt-3">
          <span>
            {" "}
            {mode === "en" ? "No holiday available" : "बिदा उपलब्ध छैन"}
          </span>
        </div>
      </>
    );
  }
};

export default Holiday;
