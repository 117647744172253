import React, { useContext, useEffect, useState, useRef } from "react";
import "../../../profile/profile.css";
import DataTable from "react-data-table-component";
import { Fetchdata } from "../../../hooks/getData";
import AuthContext from "../../../context/auth-context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShiftPopup from "./ShiftPopup";
import ShiftEditPopup from "./ShiftEditPopup";
import Spinner from "../../../loading/spinner";
import UpperbarContext from "../../../context/upperbar-context";
import StaffContext from "../staffState/StaffContext";
import { FaRegCalendarAlt } from "react-icons/fa";
import Toast from "../../../Toast";
import { GetTwelveHourFormatTime } from "../../../hooks/dateConvertor";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { GoPlus } from "react-icons/go";
import { AiOutlineEdit, AiOutlineSearch } from "react-icons/ai";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { englishToNepaliNumber } from "nepali-number";
import { generatePdf } from "../../../hooks/PdfExcel";

export default function Shift() {
  const {
    fiscalYear,
    todayDate,
    expires,
    customStyles,
    appURL,
    userDetails,
    mode,
  } = useContext(UpperbarContext);
  const [shiftPopup, setShiftPopup] = useState(false);
  const [shiftEditPopup, setShiftEditPopup] = useState(false);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);

  const shiftValue = {
    shifts: "",
    shiftStart: "",
    shiftEnd: "",
    allowLateIn: "",
    allowEarlyOut: "",
    lunchStart: "",
    lunchEnd: "",
    hdHour: "",
  };

  const [shiftFormValue, setShiftFormValue] = useState(shiftValue);
  const [shiftFormError, setShiftFormError] = useState({});

  const { User } = useContext(AuthContext);
  const searchInput = useRef("");
  const [originalList, setOriginalList] = useState(null);

  const addLeaveNote = (e) => {
    setShiftPopup(true);

    setShiftFormValue(shiftValue);
  };

  const [titleId, setTitleID] = useState();
  const editPop = (datas) => {
    setShiftEditPopup(true);
    setTitleID(datas.ShiftID);
    setShiftFormValue({
      shifts: datas.Shift,
      shiftStart: datas.Start,
      shiftEnd: datas.Ends,
      allowLateIn: datas.AllowLateIn,
      allowEarlyOut: datas.AllowEarlyOut,
      lunchStart: datas.LunchStart,
      lunchEnd: datas.LunchEnd,
      hdHour: datas.HDHour,
    });
    // setShiftFormValue(datas)
  };

  //API to hit Department status

  const stateInitial = [];
  const [newStat, setNewStat] = useState(stateInitial);

  const deactivateDepart = (ID, IsActive) => {
    const user = "" + User.UID;
    const id = "" + ID;
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: user,
      Flag: "AI",
      ShiftID: id,
      IsActive: IsActive,
      FetchURL: `${appURL}api/admin/shift`,
      Type: "POST",
    };
    if (IsActive === "A") {
      dataForm.IsActive = "I";
    } else {
      dataForm.IsActive = "A";
    }
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        shftList();
        let statsN = JSON.parse(JSON.stringify(newStat));
        let pitchStatus;

        if (dataForm.IsActive === "A") {
          pitchStatus = "Activated";
        } else if (dataForm.IsActive === "I") {
          pitchStatus = "Deactivated";
        }

        setNewStat(statsN);
        toast.success(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  const changeStatus = (ID, IsActive) => {
    deactivateDepart(ID, IsActive);
  };

  const checkStatus = (IsActive) => {
    if (IsActive === "A") {
      return mode === "en" ? "Deactivate" : "निष्क्रिय";
    } else if (IsActive === "I") {
      return mode === "en" ? "Activate" : "सक्रिय";
    }
  };

  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      grow: 0,
      center: true,
      width: "70px",
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },
    {
      name: mode === "en" ? "Shift" : "शिफ्ट",
      // grow: 0,
      // center: true,
      minWidth: "200px",
      selector: (row) => row.Shift,
    },
    {
      name: mode === "en" ? "Start" : "सुरु",
      // center: true,
      // grow: 0,
      selector: (row) => row.Start,
      width: "120px",
      // selector: (row) => GetTwelveHourFormatTime(row.Start),
    },
    {
      name: mode === "en" ? "End" : "अन्त्य",
      // grow: 0,
      // center: true,
      selector: (row) => row.Ends,
      width: "120px",
      // selector: (row) => GetTwelveHourFormatTime(row.Ends),
    },

    {
      name: mode === "en" ? "Late In" : "ढिलो अनुमति",
      // grow: 0,
      // center: true,
      width: "120px",
      // selector: (row) => GetTwelveHourFormatTime(row.AllowLateIn),
      selector: (row) => row.AllowLateIn,
    },
    {
      name: mode === "en" ? "Early Out" : "प्रारम्भिक बाहिर अनुमति",
      // center: true,
      // grow: 0,
      width: "120px",
      // selector: (row) => GetTwelveHourFormatTime(row.AllowEarlyOut),
      selector: (row) => row.AllowEarlyOut,
    },
    {
      name: mode === "en" ? "Lunch Start" : "लंच सुरु",
      // center: true,
      width: "120px",
      // grow: 0,
      selector: (row) => row.LunchStart,
      // selector: (row) => GetTwelveHourFormatTime(row.LunchStart),
    },
    {
      name: mode === "en" ? "Lunch End" : "लंच अन्त्य",
      // center: true,
      width: "120px",
      // grow: 0,
      // selector: (row) => GetTwelveHourFormatTime(row.LunchEnd),
      selector: (row) => row.LunchEnd,
    },
    {
      name: mode === "en" ? "Status" : "स्थिति",
      // grow: 0,
      center: true,
      width: "140px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                className="border-0 bg-transparent"
                onClick={() => changeStatus(row.ShiftID, row.IsActive)}
              >
                <span
                  className=" badge rounded-pill bg-success"
                  style={{ fontSize: "11px" }}
                >
                  {checkStatus(row.IsActive)}
                </span>
              </button>
            </div>
          </>
        );
      },
    },
    {
      name: mode === "en" ? "Action" : "कार्य",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                class="btn btn-sm editspan"
                onClick={() => editPop(row)}
                uk-tooltip={mode === "en" ? "Edit" : "सम्पादन गर्नुहोस्"}
              >
                <AiOutlineEdit />
              </button>{" "}
            </div>
          </>
        );
      },
    },
  ];

  //API to hit Shift list
  const [shiftList, setShiftList] = useState([]);

  useEffect(() => {
    shftList();
  }, []);
  const shftList = () => {
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      BranchID: Branch,
      FiscalID: Fiscal,
      Type: "POST",
      FetchURL: `${appURL}api/admin/shift`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setShiftList(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setLoading(false);
        setOriginalList([]);
        setShiftList([]);
      }
    });
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Shift"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setShiftList(srchResult);
      } else {
        setShiftList({});
      }
    } else {
      setShiftList(originalList);
    }
  };

  const dataWithIndex = shiftList.map((d, i) => ({
    ...d,
    Index: i + 1,
    Start: GetTwelveHourFormatTime(d.Start),
    End: GetTwelveHourFormatTime(d.Ends),
    AllowLateIn: GetTwelveHourFormatTime(d.AllowLateIn),
    AllowEarlyOut: GetTwelveHourFormatTime(d.AllowEarlyOut),
    LunchStart: GetTwelveHourFormatTime(d.LunchStart),
    LunchEnd: GetTwelveHourFormatTime(d.LunchEnd),
    Status: d.IsActive === "I" ? "Inactive" : "Active",
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Shift", field: "Shift" },
    { header: "Start", field: "Start" },
    { header: "End", field: "End" },
    { header: "Late In", field: "AllowLateIn" },
    { header: "Early Out", field: "AllowEarlyOut" },
    { header: "Lunch Start", field: "LunchStart" },
    { header: "Lunch End", field: "LunchEnd" },
    { header: "Status", field: "IsActive" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    generatePdf(userDetails, exportColumns, dataWithIndex);
  };

  const dataToExcel = shiftList.map((d, i) => ({
    "S.N.": i + 1,
    Shift: d.Shift,
    Start: GetTwelveHourFormatTime(d.Start),
    End: GetTwelveHourFormatTime(d.Ends),
    "Late In": GetTwelveHourFormatTime(d.AllowLateIn),
    "Early Out": GetTwelveHourFormatTime(d.AllowEarlyOut),
    "Lunch Start": GetTwelveHourFormatTime(d.LunchStart),
    "Lunch End": GetTwelveHourFormatTime(d.LunchEnd),
    Status: d.IsActive === "I" ? "Inactive" : "Active",
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Shift");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper   ps-4 pe-4">
        <div className="row ">
          <div className="page-header">
            <div className="text-start  page-title">
              {mode === "en" ? "Shift" : "शिफ्ट"}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <span className="me-2 ">Expires in: {expires} </span>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>
        <>
          <div className="sec-dataTable">
            <div className="upper-dataTbl">
              <div className="d-flex uk-flex-middle uk-flex-right mb-3">
                <div className="btn-addlnote mt-3">
                  <button
                    type="button"
                    class="btn btn-sm"
                    style={{
                      background: "var(--button-color)",
                      color: "white",
                    }}
                    onClick={addLeaveNote}
                  >
                    <GoPlus color="#fff" className="me-1" />
                    {mode === "en" ? "Add New" : "नयाँ थप्नुहोस्"}
                  </button>
                </div>

                <div className="export-btn">
                  <button
                    uk-tooltip="Export Excel"
                    onClick={toExcel}
                    className="me-1 ms-2 border-0"
                  >
                    <RiFileExcel2Fill size="1rem" color="#136438" />
                  </button>
                </div>
                <div className="export-btn">
                  <button
                    uk-tooltip="Export Pdf"
                    onClick={toPdf}
                    className="mx-1 border-0"
                  >
                    <BsFileEarmarkPdfFill size="1rem" color="#ff0002" />
                  </button>
                </div>
              </div>
            </div>
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={shiftList}
                customStyles={customStyles}
                pagination
                paginationRowsPerPageOptions={[10, 20, 50, 100]} // Customizable options
                fixedHeader
                fixedHeaderScrollHeight="370px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderComponent={
                  <>
                    <div className=" w-100">
                      <div className="d-flex uk-flex-middle justify-content-end">
                        <div>
                          <div class="uk-search uk-search-default">
                            <AiOutlineSearch className="search-icon" />
                            <input
                              placeholder={
                                mode === "en" ? "Search" : "खोजी गर्नुहोस्"
                              }
                              ref={searchInput}
                              type="text"
                              className="form-control form-control-sm searchField"
                              onChange={searchHandler}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      <ShiftPopup
        setShiftPopup={setShiftPopup}
        shiftPopup={shiftPopup}
        reload={reload}
        setReload={setReload}
        shftList={shftList}
        shiftFormValue={shiftFormValue}
        setShiftFormValue={setShiftFormValue}
        shiftFormError={shiftFormError}
        setShiftFormError={setShiftFormError}
      />

      <ShiftEditPopup
        setShiftEditPopup={setShiftEditPopup}
        reload={reload}
        shiftEditPopup={shiftEditPopup}
        setReload={setReload}
        shftList={shftList}
        shiftFormValue={shiftFormValue}
        setShiftFormValue={setShiftFormValue}
        shiftFormError={shiftFormError}
        setShiftFormError={setShiftFormError}
        titleId={titleId}
      />
    </>
  );
}
