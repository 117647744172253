import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import urlSlug from "url-slug";
import CloseIcon from "../../../images/CloseIcon.svg";
import Plus from "../../../images/Plus.png";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EliteJobContext from "../EliteJobContext/EliteJobContext";
import UpperbarContext from "../../context/upperbar-context";

export default function JobEditForm({ closePopup }) {
  const context = useContext(EliteJobContext);
  const { mode , dateMode } = useContext(UpperbarContext);
  const {
    toggleEditPopup,
    jobFormValue,
    setJobFormValue,
    jobFormError,
    setJobFormError,
    editIsSubmit,
    setEditIsSubmit,
    image,
    setImage,
    negotiable,
    setNegotiable,
    jobvalue,
    getJobList,
    closeChecked,
    setCloseChecked,
    editPop,
    editJobdata,

    categorySlug,
    setCategorySlug,

    setTypeFile,
    setIsUploaded,
    isUploaded,
    jobDesignationList,
    description,
    setDescription,
    qualification,
    setQualification,
    responsibility,
    setResponsibility,
    designationName,
  } = context;
  const [DFlag, setDFlag] = useState("N");

  useEffect(() => {
    setCategorySlug(urlSlug(jobFormValue.title));
  }, [jobFormValue]);

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setJobFormValue({ ...jobFormValue, [name]: value });
  };
  const handleSelectDesignation = (e) => {
    let name = "designation";
    setJobFormValue({
      ...jobFormValue,
      [name]: e.value,
    });
  };

  function handleImageChange(e) {
    if (e.target.files && e.target.files[0]) {
      setTypeFile(e.target.files[0].type);
      let reader = new FileReader();

      reader.onload = function (e) {
        setImage(e.target.result);
        setIsUploaded(true);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const handleEnd = ({ adDate }) => {
    setJobFormValue({ ...jobFormValue, expiryDate: adDate });
  };
  const handleInterview = ({ adDate }) => {
    setJobFormValue({ ...jobFormValue, interviewDate: adDate });
  };
  const handleNegoChange = (e) => {
    setNegotiable(!negotiable);
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setJobFormError(validate(jobFormValue));
    setEditIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(jobFormError).length === 0 && editIsSubmit) {
      editJobdata(jobFormValue);

      setEditIsSubmit(false);
    }
  }, [jobFormError]);

  const validate = (values) => {
    const errors = {};
    const numv = /^[0-9]+$/i;

    if (!values.designation) {
      errors.designation = "Required";
    }
    if (!values.shifts) {
      errors.shifts = "Required";
    }
    if (!values.shiftType) {
      errors.shiftType = "Required";
    }
    if (!values.jobType) {
      errors.jobType = "Required";
    }
    if (!values.title) {
      errors.title = "Required";
    }
    // if (!values.description) {
    //   errors.description = "Required";
    // }

    if (!values.position) {
      errors.position = "Required";
    } else if (!numv.test(values.position)) {
      errors.position = "Must be digits";
    }else if (values.position <= 0) {
      errors.position = "Must be more than 0";
    }
    if (!values.education) {
      errors.education = "Required";
    }
    if (!values.experience) {
      errors.experience = "Required";
    }

    // if (!values.expiryDate) {
    //   errors.expiryDate = "Required";
    // }
    if (!negotiable) {
      if (!values.salaryFrom) {
        errors.salaryFrom = "Required";
      }else if (!numv.test(values.salaryFrom)) {
        errors.salaryFrom = "Must be digits" ;
      }
      if (!values.salaryTo) {
        errors.salaryTo = "Required";
      }else if (!numv.test(values.salaryTo)) {
        errors.salaryTo = "Must be digits" ;
      }else if (values.salaryTo <= values.salaryFrom) {
        errors.salaryTo = "Must be greater than minimum salary";
      } 
    }
    // if (!values.interviewDate) {
    //   errors.interviewDate = "Required";
    // }

    // if (!values.jobQual) {
    //   errors.jobQual = "Required";
    // }
    // if (!values.jobResp) {
    //   errors.jobResp = "Required";
    // }
    // if (!values.workType) {
    //   errors.workType = "Required";
    // }

    return errors;
  };

  const dropDownDesignation = jobDesignationList.map((item) => ({
    value: item.DesignID,
    label: item.Designation,
  }));

  const designationValue = jobDesignationList.filter(
    (item) => item.DesignID === jobFormValue.designation
  );

  // const defaultDesignation = designationValue[0];
  const defaultDesignation = designationValue.map((item) => ({
    label: item.Designation,
    value: item.DesignID,
  }));

  return (
    <>
      <div className="newpopup-body ps-3 pe-3">
        <div className="col-md-12 col-sm-12 col-lg-12">
          <form action="">
            <div className="form-padding">
              <div className="row">
                <div className="form-group">
                  <label
                    className="form-label"
                    htmlFor="title"
                    style={{ fontSize: "12px" }}
                  >
                    Title<sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="title"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="title"
                    value={jobFormValue.title}
                    onChange={handleChange}
                  />
                  <p className="errormsg " style={{ marginBottom: "0" }}>
                    {jobFormError.title}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="form-group">
                  <label className="form-label" htmlFor="designation">
                    Designation<sup style={{ color: "red" }}>*</sup>
                  </label>
                  {/* <input
              id="designation"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="designation"
              value={jobFormValue.designation}
              onChange={handleChange}
            /> */}
                  <Select
                    className="select"
                    style={{ fontSize: "11px", marginBottom: "3px" }}
                    options={dropDownDesignation}
                    onChange={(item) => handleSelectDesignation(item)}
                    isSearchable={true}
                    defaultValue={{ label: designationName }}
                  />
                  <p className="errormsg " style={{ marginBottom: "0" }}>
                    {jobFormError.designation}
                  </p>
                </div>
              </div>

              <div className="row form-row">
                <div className="form-group col-md-6">
                  <label className="form-label" htmlFor="jobType">
                    Job Type<sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    id="jobType"
                    name="jobType"
                    onChange={handleChange}
                    value={jobFormValue.jobType}
                    className="form-select form-select-sm mb-1"
                    style={{ fontSize: "12px" }}
                  >
                    <option
                      value=""
                      disabled
                      selected
                      style={{ fontSize: "11px" }}
                    >
                      Select Job Type
                    </option>
                    <option value="Full Time">Full Time</option>
                    <option value="Part Time">Part Time</option>
                    <option value="Intern">Intern</option>
                    <option value="Paid Intern">Paid Intern</option>
                    <option value="Freelance">Freelance</option>
                    <option value="Contract">Contract</option>
                    <option value="Training">Training</option>
                  </select>
                  <p className="errormsg " style={{ marginBottom: "0" }}>
                    {jobFormError.jobType}
                  </p>
                </div>

                <div className="form-group col-md-6">
                  <label
                    className="form-label"
                    htmlFor="position"
                    style={{ fontSize: "12px" }}
                  >
                    No. of Position<sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="position"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="position"
                    value={jobFormValue.position}
                    onChange={handleChange}
                  />
                  <p className="errormsg " style={{ marginBottom: "0" }}>
                    {jobFormError.position}
                  </p>
                </div>
              </div>

              <div className="row form-row">
                <div className="form-group col-md-6">
                  <label
                    className="form-label"
                    htmlFor="salaryFrom"
                    style={{ fontSize: "12px" }}
                  >
                    Salary From{" "}
                    {negotiable === true ? (
                      ""
                    ) : (
                      <sup style={{ color: "red" }}>*</sup>
                    )}
                  </label>
                  <input
                    id="salaryFrom"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="salaryFrom"
                    value={jobFormValue.salaryFrom}
                    onChange={handleChange}
                    disabled={negotiable}
                  />
                  <p className="errormsg " style={{ marginBottom: "0" }}>
                    {jobFormError.salaryFrom}
                  </p>
                </div>
                <div className="form-group col-md-6">
                  <label
                    className="form-label"
                    htmlFor="salaryTo"
                    style={{ fontSize: "12px" }}
                  >
                    Salary To{" "}
                    {negotiable === true ? (
                      ""
                    ) : (
                      <sup style={{ color: "red" }}>*</sup>
                    )}
                  </label>
                  <input
                    id="salaryTo"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="salaryTo"
                    value={jobFormValue.salaryTo}
                    onChange={handleChange}
                    disabled={negotiable}
                  />
                  <p className="errormsg " style={{ marginBottom: "0" }}>
                    {jobFormError.salaryTo}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="form-group">
                  <div style={{ marginBottom: "5px" }}>
                    <input
                      type="checkbox"
                      // className="form-check-input"
                      id="exampleCheck3"
                      name="notWorking"
                      onChange={handleNegoChange}
                      checked={negotiable}
                    />
                    <label
                      class="form-check-label"
                      for="exampleCheck3"
                      style={{ fontSize: "12px", paddingLeft: "3px" }}
                    >
                      Negotiable
                    </label>
                  </div>
                </div>
              </div>

              {/* <div className="form-group">
            <label className="form-label" htmlFor="slug">
              Slug
            </label>
            <input
              type="text"
              name="slug"
              className="form-control form-control-sm mb-1"
              value={categorySlug}
              id="slug"
              placeholder="slug"
              disabled
            />
          </div> */}

              <div className="row form-row">
                <div className="form-group col-md-4">
                  <div>
                    <label className="form-label" htmlFor="purpose">
                      Shift <sup style={{ color: "red" }}>*</sup>
                    </label>
                  </div>
                  <div
                    className="d-flex uk-flex-wrap"
                    style={{ rowGap: "10px" }}
                  >
                    <div>
                      <input
                        type="radio"
                        className="radio--button"
                        name="shifts"
                        id="morning"
                        onChange={handleChange}
                        value="Morning"
                        checked={jobFormValue.shifts === "Morning"}
                      />
                      <label
                        className="label-radio-routine px-3"
                        htmlFor="morning"
                        style={{ marginRight: "10px" }}
                      >
                        Morning
                      </label>
                    </div>

                    <div>
                      <input
                        type="radio"
                        className="radio--button"
                        name="shifts"
                        id="day"
                        onChange={handleChange}
                        value="Day"
                        checked={jobFormValue.shifts === "Day"}
                      />
                      <label
                        className="label-radio-routine px-3"
                        htmlFor="day"
                        style={{ marginRight: "10px" }}
                      >
                        Day
                      </label>
                    </div>

                    <div>
                      <input
                        type="radio"
                        className="radio--button"
                        name="shifts"
                        id="evening"
                        onChange={handleChange}
                        value="Evening"
                        checked={jobFormValue.shifts === "Evening"}
                      />
                      <label
                        className="label-radio-routine px-3"
                        htmlFor="evening"
                        style={{ marginRight: "10px" }}
                      >
                        Evening
                      </label>
                    </div>
                  </div>
                  <p className="errormsg " style={{ marginBottom: "0" }}>
                    {jobFormError.shifts}
                  </p>
                </div>

                <div className="form-group col-md-4">
                  <div>
                    <label className="form-label" htmlFor="purpose">
                      Shift Type <sup style={{ color: "red" }}>*</sup>
                    </label>
                  </div>
                  <div
                    className="d-flex uk-flex-wrap"
                    style={{ rowGap: "10px" }}
                  >
                    <div>
                      <input
                        type="radio"
                        className="radio--button"
                        name="shiftType"
                        id="weekly"
                        onChange={handleChange}
                        value="Weekly"
                        checked={jobFormValue.shiftType === "Weekly"}
                      />
                      <label
                        className="label-radio-routine px-3"
                        htmlFor="weekly"
                        style={{ marginRight: "10px" }}
                      >
                        Weekly
                      </label>
                    </div>

                    <div>
                      <input
                        type="radio"
                        className="radio--button"
                        name="shiftType"
                        id="monthly"
                        onChange={handleChange}
                        value="Monthly"
                        checked={jobFormValue.shiftType === "Monthly"}
                      />
                      <label
                        className="label-radio-routine px-3"
                        htmlFor="monthly"
                        style={{ marginRight: "10px" }}
                      >
                        Monthly
                      </label>
                    </div>

                    <div>
                      <input
                        type="radio"
                        className="radio--button"
                        name="shiftType"
                        id="yearly"
                        onChange={handleChange}
                        value="Yearly"
                        checked={jobFormValue.shiftType === "Yearly"}
                      />
                      <label
                        className="label-radio-routine px-3"
                        htmlFor="yearly"
                        style={{ marginRight: "10px" }}
                      >
                        Yearly
                      </label>
                    </div>
                  </div>
                  <p className="errormsg " style={{ marginBottom: "0" }}>
                    {jobFormError.shiftType}
                  </p>
                </div>

                <div className="form-group col-md-4">
                  <div>
                    <label className="form-label" htmlFor="purpose">
                      Work Type <sup style={{ color: "red" }}>*</sup>
                    </label>
                  </div>
                  <div
                    className="d-flex uk-flex-wrap"
                    style={{ rowGap: "10px" }}
                  >
                    <div>
                      <input
                        type="radio"
                        className="radio--button"
                        name="workType"
                        id="on-Site"
                        onChange={handleChange}
                        value="On-Site"
                        checked={jobFormValue.workType === "On-Site"}
                      />
                      <label
                        className="label-radio-routine px-3"
                        htmlFor="on-Site"
                        style={{ marginRight: "10px" }}
                      >
                        On-Site
                      </label>
                    </div>

                    <div>
                      <input
                        type="radio"
                        className="radio--button"
                        name="workType"
                        id="remote"
                        onChange={handleChange}
                        value="Remote"
                        checked={jobFormValue.workType === "Remote"}
                      />
                      <label
                        className="label-radio-routine px-3"
                        htmlFor="remote"
                        style={{ marginRight: "10px" }}
                      >
                        Remote
                      </label>
                    </div>

                    <div>
                      <input
                        type="radio"
                        className="radio--button"
                        name="workType"
                        id="hybrid"
                        onChange={handleChange}
                        value="Hybrid"
                        checked={jobFormValue.workType === "Hybrid"}
                      />
                      <label
                        className="label-radio-routine px-3"
                        htmlFor="hybrid"
                        style={{ marginRight: "10px" }}
                      >
                        Hybrid
                      </label>
                    </div>
                  </div>
                  <p className="errormsg " style={{ marginBottom: "0" }}>
                    {jobFormError.workType}
                  </p>
                </div>
              </div>

              <div className="row form-row">
                <div className="form-group col-md-4">
                  <label
                    className="form-label"
                    htmlFor="education"
                    style={{ fontSize: "12px" }}
                  >
                    Education<sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    id="education"
                    name="education"
                    onChange={handleChange}
                    value={jobFormValue.education}
                    className="form-select form-select-sm mb-1"
                    style={{ fontSize: "12px" }}
                  >
                    <option
                      value=""
                      disabled
                      selected
                      style={{ fontSize: "11px" }}
                    >
                      Select Education
                    </option>
                    <option value="US">Under Secondary</option>
                    <option value="S">Secondary</option>
                    <option value="HS">Higher Secondary</option>
                    <option value="B">Bachelor</option>
                    <option value="M">Master</option>
                    <option value="N">Not Required</option>
                  </select>
                  <p className="errormsg " style={{ marginBottom: "0" }}>
                    {jobFormError.education}
                  </p>
                </div>

                <div className="form-group col-md-4">
                  <label
                    className="form-label"
                    htmlFor="experience"
                    style={{ fontSize: "12px" }}
                  >
                    Experience<sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    id="experience"
                    name="experience"
                    onChange={handleChange}
                    value={jobFormValue.experience}
                    className="form-select form-select-sm mb-1"
                    style={{ fontSize: "12px" }}
                  >
                    <option
                      value=""
                      disabled
                      selected
                      style={{ fontSize: "11px" }}
                    >
                      Select Experience
                    </option>
                    <option value="Freshers">Freshers</option>
                    <option value="6 Months - 1 Year">6 Months - 1 Year</option>
                    <option value="1-2 YearsHS">1-2 Years</option>
                    <option value="3-5 Years">3-5 Years</option>
                    <option value="5 Years & Above">5 Years & Above</option>
                  </select>
                  <p className="errormsg " style={{ marginBottom: "0" }}>
                    {jobFormError.experience}
                  </p>
                </div>

                <div className="form-group col-md-4">
                  <label
                    className="form-label"
                    htmlFor="expiryDate"
                    style={{ fontSize: "12px" }}
                  >
                    Expiry Date<sup style={{ color: "red" }}>*</sup>
                  </label>
                  {dateMode === "np" ? (
                    <Calendar
                      className="form-control form-control-sm mb-1"
                      dateFormat="YYYY/MM/DD"
                      theme="default"
                      language="en"
                      value={jobFormValue.expiryDate}
                      onChange={handleEnd}
                    />
                  ) : (
                    <input
                      type="date"
                      value={jobFormValue.expiryDate}
                      name="expiryDate"
                      placeholder="Select End Date"
                      className="form-control form-control-sm "
                      onChange={handleChange}
                    />
                  )}
                  <p className="errormsg " style={{ marginBottom: "0" }}>
                    {jobFormError.expiryDate}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="form-group">
                  <label
                    className="form-label"
                    htmlFor="description"
                    style={{ fontSize: "12px" }}
                  >
                    Job Description
                  </label>
                  {/* <textarea
              id="description"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="description"
              value={jobFormValue.description}
              onChange={handleChange}
            /> */}
                  <ReactQuill value={description} onChange={setDescription} />
                  {/* <p className="errormsg " style={{ marginBottom: "0" }}>
              {jobFormError.description}
            </p> */}
                </div>
              </div>

              <div className="row">
                <div className="form-group mt-5">
                  <label
                    className="form-label"
                    htmlFor="jobQual"
                    style={{ fontSize: "12px" }}
                  >
                    Job Qualification
                  </label>
                  {/* <textarea
              id="jobQual"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="jobQual"
              value={jobFormValue.jobQual}
              onChange={handleChange}
            /> */}
                  <ReactQuill
                    value={qualification}
                    onChange={setQualification}
                  />
                  {/* <p className="errormsg " style={{ marginBottom: "0" }}>
              {jobFormError.jobQual}
            </p> */}
                </div>
              </div>

              <div className="row">
                <div className="form-group mt-5">
                  <label
                    className="form-label"
                    htmlFor="jobResp"
                    style={{ fontSize: "12px" }}
                  >
                    Job Responsibility
                  </label>
                  {/* <textarea
              id="jobResp"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="jobResp"
              value={jobFormValue.jobResp}
              onChange={handleChange}
            /> */}
                  <ReactQuill
                    value={responsibility}
                    onChange={setResponsibility}
                  />
                  {/* <p className="errormsg " style={{ marginBottom: "0" }}>
              {jobFormError.jobResp}
            </p> */}
                </div>
              </div>

              {/* <div className="form-row">
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="shifts">
                Shift<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="shifts"
                name="shifts"
                onChange={handleChange}
                value={jobFormValue.shifts}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select Shift
                </option>
                <option value="Morning">Morning</option>
                <option value="Day">Day</option>
                <option value="Evening">Evening</option>
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {jobFormError.shifts}
              </p>
            </div>

            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="shiftType">
                Shift Type<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="shiftType"
                name="shiftType"
                onChange={handleChange}
                value={jobFormValue.shiftType}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select Shift Type
                </option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
                <option value="Yearly">Yearly</option>
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {jobFormError.shiftType}
              </p>
            </div>
          </div> */}

              {/* <div className="form-row">
            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="interviewDate"
                style={{ fontSize: "12px" }}
              >
                Interview Date<sup style={{ color: "red" }}>*</sup>
              </label>
              {DFlag === "N" ? (
                <Calendar
                  className="form-control form-control-sm mb-1"
                  dateFormat="YYYY/MM/DD"
                  theme="default"
                  language="en"
                  value={jobFormValue.interviewDate}
                  onChange={handleInterview}
                  // name="interviewDate"
                />
              ) : (
                <input
                  type="date"
                  value={jobFormValue.interviewDate}
                  name="interviewDate"
                  placeholder="Select Start Date"
                  className="form-control form-control-sm "
                  onChange={handleChange}
                />
              )}
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {jobFormError.interviewDate}
              </p>
            </div>
          </div> */}

              {/* <div className="form-row"> */}

              {/* <div className="form-group col-md-6">
              <label className="form-label" htmlFor="workType">
                Work Type<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="workType"
                name="workType"
                onChange={handleChange}
                value={jobFormValue.workType}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select Work Type
                </option>
                <option value="On-Site">On-Site</option>
                <option value="Remote">Remote</option>
                <option value="Hybrid">Hybrid</option>
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {jobFormError.workType}
              </p>
            </div> */}
              {/* </div> */}

              <div className="">
                {/* <div
              className="form-label"
              htmlFor="text"
              style={{ fontSize: "12px", textAlign: "left" }}
            >
              Upload Image
            </div>

            <div className="BoxUpload">
              <div className="image-upload">
                {!isUploaded ? (
                  <>
                    <label htmlFor="upload-input">
                      <img
                        src={Plus}
                        draggable={"false"}
                        alt="placeholder"
                        style={{ width: 90, height: 100, paddingTop: "10px" }}
                      />
                    </label>

                    <input
                      id="upload-input"
                      type="file"
                      accept=".jpg,.jpeg,.gif,.png,.mov,.mp4"
                      onChange={handleImageChange}
                      name="image"
                    />
                  </>
                ) : (
                  <div className="ImagePreview">
                    <img
                      className="close-icon"
                      src={CloseIcon}
                      alt="CloseIcon"
                      onClick={() => {
                        setIsUploaded(false);
                        setImage(null);
                      }}
                    />

                    <img
                      id="uploaded-image"
                      src={image}
                      draggable={false}
                      alt="uploaded-img"
                    />
                  </div>
                )}
              </div>
            </div> */}
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="new-footer">
        <div className="row  mt-1 mb-1">
          <div>
            <button
              type="button"
              class="btn btn-sm me-2"
              style={{ background: "var(--button-color)", color: "white" }}
              onClick={formSubmit}
            >
              {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
            </button>
            <button
              type="button"
              class="btn btn-sm btn-danger me-3"
              style={{ color: "white" }}
              onClick={closePopup}
            >
              {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
