import React, { useState, useEffect, useContext } from "react";
import AccountGroupContext from "./AccountGroupContext";
import { Fetchdata } from "../../../../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import AuthContext from "../../../../context/auth-context";
import UpperbarContext from "../../../../context/upperbar-context";
import $ from "jquery";

function AccountGroupState(props) {
  const { User } = useContext(AuthContext);
  const { appURL } = useContext(UpperbarContext);
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const [popup, setPopup] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [perEditSubmit, setPerEditSubmit] = useState(false);

  const initalvalue = {
    groupName: "",
    under: "",
    category: "",
    type: "",
    schedule: "",
  };
  const [formValues, setFormValues] = useState(initalvalue);
  const [formErrors, setFormErrors] = useState({});

  //  API to show account group list
  const [accountGroupList, setAccountGroupList] = useState([]);

  useEffect(() => {
    accountGroupLst();
  }, [submit, perEditSubmit]);

  const accountGroupLst = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      IsActive: "-1",
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/main-group`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setAccountGroupList(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setAccountGroupList([]);
        setLoading(false);
      }
    });
  };

  //  API to edit main group
  const [perID, setPerId] = useState();
  const [editPopup, setEditPopup] = useState(false);
  const handleEdit = (data) => {
    setPerId(data.GroupID);
    setEditPopup(true);
    accLst();
    // setLoad(true);
  };

  const editData = () => {
    const UserID = "" + User.UID;
    const id = "" + perID;
    const referid = "" + formValues.under;

    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      Flag: "U",
      CID: "1",
      GroupID: id,
      ReferID: referid !== " " ? referid : " ",
      GroupName: formValues.groupName !== " " ? formValues.groupName : " ",
      GroupType: formValues.category !== " " ? formValues.category : " ",
      BSPLType: formValues.type !== " " ? formValues.type : " ",
      Schedule: formValues.schedule !== " " ? formValues.schedule : " ",
      FetchURL: `${appURL}api/acc/admin/main-group`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (resp) {
      if (resp.StatusCode === 200) {
        setEditPopup(false);
        $(".editAccountGroupPopBg").fadeOut(300);
        $(".editAccountGroupPop").slideUp(500);
        accountGroupLst();
        toast.success(resp.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + resp.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  const id = "" + perID;

  // API to get main group info
  const [mainGroupInfo, setMainGroupInfo] = useState([]);

  useEffect(() => {
    infoList();
  }, [id]);

  const infoList = () => {
    const UserID = "" + User.UID;

    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      Flag: "SI",
      GroupID: id,
      FetchURL: `${appURL}api/acc/admin/main-group`,
      Type: "POST",
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const data = result.Values[0] ? result.Values[0] : "";
        setFormValues({
          groupName: data.GroupName,
          under: data.ReferID.toString(),
          category: data.GroupTypeID,
          type: data.BSPLTypeID,
          schedule: data.Schedule,
        });
        // setLoad(false);
      } else {
        // setLoad(false);
      }
    });
  };

  // API to hit main group status
  const stat = [];
  const [newStatus, setNewStatus] = useState(stat);

  const deactivateData = (ID, IsActive) => {
    const UserID = "" + User.UID;
    const id = "" + ID;
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      Flag: "AI",
      CID: "1",
      GroupID: id,
      IsActive: IsActive,
      FetchURL: `${appURL}api/acc/admin/main-group`,
      Type: "POST",
    };
    if (IsActive === "A") {
      dataForm.IsActive = "I";
    } else {
      dataForm.IsActive = "A";
    }
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        accountGroupLst();
        let NewstatsN = JSON.parse(JSON.stringify(newStatus));
        let pitchNewStatus;

        if (dataForm.IsActive === "A") {
          pitchNewStatus = "Activated";
        } else if (dataForm.IsActive === "I") {
          pitchNewStatus = "Deactivated";
        }
        setNewStatus(NewstatsN);
        toast.success(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  //   API to account group list

  const [acGrpList, setAcGrpList] = useState([]);

  useEffect(() => {
    accLst();
  }, [submit]);

  const accLst = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      DFlag: "ACCGROUP",
      IsPrimary: "Y",
      Flag: "S",
      CID: "1",
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/ref-values`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setAcGrpList(postResult);
      } else {
        setAcGrpList([]);
      }
    });
  };

  const [nonAcGrpList, setNonAcGrpList] = useState([]);

  useEffect(() => {
    nonaccLst();
  }, [submit]);

  const nonaccLst = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      DFlag: "ACCGROUP",
      IsPrimary: "N",
      Flag: "S",
      CID: "1",
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/ref-values`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setNonAcGrpList(postResult);
      } else {
        setNonAcGrpList([]);
      }
    });
  };

  return (
    <AccountGroupContext.Provider
      value={{
        loading,
        setLoading,
        originalList,
        setOriginalList,
        popup,
        setPopup,
        submit,
        setSubmit,
        perEditSubmit,
        setPerEditSubmit,
        initalvalue,
        formValues,
        setFormValues,
        formErrors,
        setFormErrors,
        accountGroupList,
        setAccountGroupList,
        editPopup,
        setEditPopup,
        handleEdit,
        editData,
        deactivateData,
        accountGroupLst,
        acGrpList,
        load,
        setLoad,
        accLst,
        nonAcGrpList,
        setNonAcGrpList,
      }}
    >
      {props.children}
    </AccountGroupContext.Provider>
  );
}

export default AccountGroupState;
