import React, { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import CloseIcon from "../../images/CloseIcon.svg";
import Plus from "../../images/Plus.png";
import UpperbarContext from "../context/upperbar-context";
import { Fetchdata } from "../hooks/getData";
import AuthContext from "../context/auth-context";
// import NepaliDate from "nepali-date-converter";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import StaffContext from "../adminPanel/organization/staffState/StaffContext";
import { GetEnglishDate } from "../hooks/dateConvertor";
import Toast from "../Toast";
import $ from "jquery";
import { nepaliToEnglishNumber } from "nepali-number";
import NotificationContext from "../notificationState/NotificationContext";
// import NepaliDate from "nepali-date-converter";
const NotificationPopup = ({
  setNotificationPopup,
  notificationPopup,
  //   crList,
  notificationValues,
  setNotificationValues,
  notificationErrors,
  setNotificationErrors,
  DFlag,
  submit,
  setSubmit,
  re,
  // fetchNotification,
  // image,
  // setImage,

  //   chooseCooperative,
}) => {
  const {
    // departmentList,
    // subdepartmentList,
    // designationList,
    // staffList,
    notifyList,
    initialvalue,
  } = useContext(NotificationContext);
  const { User } = useContext(AuthContext);
  const { mode, appURL } = useContext(UpperbarContext);
  const [isUploaded, setIsUploaded] = useState(false);

  const [typeFile, setTypeFile] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    if (notificationPopup) {
      $(".addNotificationPopBg").fadeIn(500);
      $(".addNotificationPop").slideDown(500);
    }
  }, [notificationPopup]);

  function handleImageChange(e) {
    if (e.target.files && e.target.files[0]) {
      setTypeFile(e.target.files[0].type);
      let reader = new FileReader();

      reader.onload = function (e) {
        setImage(e.target.result);
        setIsUploaded(true);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.description) {
      errors.description = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.pubDate) {
      errors.pubDate = mode === "en" ? "Required" : "आवश्यक छ";
    }

    if (notificationValues.all === "d") {
      if (!values.department) {
        errors.department = mode === "en" ? "Required" : "आवश्यक छ";
      }
    }
    // if (notificationValues.all === "s") {
    //   if (!values.department) {
    //     errors.department = mode === "en" ? "Required" : "आवश्यक छ";
    //   }
    //   if (!values.subDepartment) {
    //     errors.subDepartment = mode === "en" ? "Required" : "आवश्यक छ";
    //   }
    // }
    if (notificationValues.all === "i") {
      // if (!values.department) {
      //   errors.department = mode === "en" ? "Required" : "आवश्यक छ";
      // }
      // if (!values.subDepartment) {
      //   errors.subDepartment = mode === "en" ? "Required" : "आवश्यक छ";
      // }
      if (!values.staff) {
        errors.staff = mode === "en" ? "Required" : "आवश्यक छ";
      }
    }
    if (notificationValues.all === "de") {
      if (!values.department) {
        errors.department = mode === "en" ? "Required" : "आवश्यक छ";
      }
      // if (!values.subDepartment) {
      //   errors.subDepartment = mode === "en" ? "Required" : "आवश्यक छ";
      // }
      if (!values.designation) {
        errors.designation = mode === "en" ? "Required" : "आवश्यक छ";
      }
    }

    return errors;
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    // if (name === "department") {
    //   notificationValues.subDepartment = "";
    //   notificationValues.staff = "";
    //   notificationValues.designation = "";
    // }
    // if (name === "subDepartment") {
    //   notificationValues.staff = "";
    //   notificationValues.designation = "";
    // }
    setNotificationValues({ ...notificationValues, [name]: value });
  };
  const handle = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    if (name === "all") {
      notificationValues.department = "";
      notificationValues.subDepartment = "";
      notificationValues.designation = "";
      notificationValues.staff = "";
      notificationErrors.department = "";
      notificationErrors.subDepartment = "";
      notificationErrors.designation = "";
      notificationErrors.staff = "";
    }
    if (name === "department") {
      notificationValues.department = "";
      notificationErrors.department = "";
    }
    if (name === "subDepartment") {
      notificationValues.department = "";
      notificationValues.subDepartment = "";
      notificationErrors.department = "";
      notificationErrors.subDepartment = "";
    }
    if (name === "designation") {
      notificationValues.department = "";
      notificationValues.subDepartment = "";
      notificationValues.designation = "";
      notificationErrors.department = "";
      notificationErrors.subDepartment = "";
      notificationErrors.designation = "";
    }
    if (name === "staff") {
      notificationValues.department = "";
      notificationValues.subDepartment = "";
      notificationValues.staff = "";
      notificationErrors.department = "";
      notificationErrors.subDepartment = "";
      notificationErrors.staff = "";
    }

    // setNotificationValues({
    //   all: value,
    //   department: "",
    //   subDepartment: "",
    //   staff: "",
    //   designation: "",
    // });
    setNotificationValues({ ...notificationValues, [name]: value });
    // setNotificationErrors({});
  };


  const handleIssueDate = ({ bsDate }) => {
    let name = "pubDate";
    setNotificationValues({ ...notificationValues, [name]: bsDate });
  };

  const closePopUp = () => {
    setNotificationPopup(false);
    setNotificationErrors({});
    // setNotificationValues("");
    setNotificationValues(initialvalue);
    setImage("");
    setSubmit(false);
    $(".addNotificationPopBg").fadeOut(500);
    $(".addNotificationPop").slideUp(500);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setNotificationErrors(validate(notificationValues));
    setSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(notificationErrors).length === 0 && submit) {
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        Flag: "i",
        UserID: User.UID.toString(),
        Title: notificationValues.title,
        Description: notificationValues.description,
        Image: image !== null ? image.split(",")[1] : "",
        PublishedEnDate: GetEnglishDate(notificationValues.pubDate),
        PublishedNpDate: notificationValues.pubDate,
        DepartmentID: notificationValues.department
          ? notificationValues.department.toString()
          : "0",
        // SubDepartmentID: notificationValues.subDepartment
        //   ? notificationValues.subDepartment
        //   : 0,
        DesignationID: notificationValues.designation
          ? notificationValues.designation.toString()
          : "0",
        StaffID: notificationValues.staff ? notificationValues.staff.toString() : 0,
        BranchID: User.BranchID.toString(),
        FiscalID: User.FiscalID.toString(),
        FetchURL: `${appURL}api/admin/notification`,
        Type: "POST",
      };
      Fetchdata(dataForm).then(function (resp) {
        if (resp.StatusCode === 200) {
          setNotificationPopup(false);
          $(".addNotificationPopBg").fadeOut(500);
          $(".addNotificationPop").slideUp(500);
          notifyList();
          toast.success(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error("Error: " + resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });
      setSubmit(false);
      setImage("");
    }
  }, [notificationErrors]);

  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    staffLst();
  }, []);

  const staffLst = () => {
    const BranchID = "" + User.BranchID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      DFlag: "STAFF",
      BranchID: BranchID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/ref-values`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setStaffList(postResult);
      } else {
        setStaffList([]);
      }
    });
  };

  //API to hit Department list
  const [departmentList, setDepartmentList] = useState([]);

  useEffect(() => {
    deptList();
  }, []);

  const deptList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      IsActive: "-1",
      Type: "POST",
      FetchURL: `${appURL}api/admin/department`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setDepartmentList(postResult);
      } else {
        setDepartmentList([]);
      }
    });
  };

  // API to hit Sub-Department list
  const [subdepartmentList, setSubdepartmentList] = useState([]);

  // useEffect(() => {
  //   subdeptList();
  // }, [notificationValues.department]);

  // const subdeptList = () => {
  //   const params = {
  //     ComID: User.CompanyId,
  //     StaffID: -1,
  //     DepartID: notificationValues.department,
  //     Flag: "S",
  //     Type: "POST",
  //     Status: 1,
  //     BranchID: User.BranchId,
  //     FetchURL: `${appURL}api/admin/sub-department`,
  //   };

  //   Fetchdata(params).then(function (result) {
  //     if (result.StatusCode === 200) {
  //       const postResult = result.SubDepList ? result.SubDepList : "";
  //       setSubdepartmentList(postResult);
  //     } else {
  //       setSubdepartmentList([]);
  //     }
  //   });
  // };

  //API to hit Staff list
  // const [staffList, setStaffList] = useState([]);

  // useEffect(() => {
  //   const dataForm = {
  //     FetchURL: `${appURL}api/org-staff?ComID=${User.CompanyId}&BranchID=${User.BranchId}&DepartmentId=${notificationValues.department}&SubDepartmentId=${notificationValues.subDepartment}`,
  //     Type: "GET",
  //   };

  //   Fetchdata(dataForm).then(function (result) {
  //     if (result.StatusCode === 200) {
  //       const postResult = result.OrganizationStaffs
  //         ? result.OrganizationStaffs
  //         : "";
  //       setStaffList(postResult);
  //     } else {
  //       setStaffList([]);
  //     }
  //   });
  // }, [notificationValues.department, notificationValues.subDepartment]);

  //API to hit Designation list

  const [designationList, setDesignationList] = useState([]);

  useEffect(() => {
    desgList();
  }, [notificationValues.department]);

  const desgList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      DepartID: notificationValues.department,
      IsActive: "-1",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/designation`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setDesignationList(postResult);
      } else {
        setDesignationList([]);
      }
    });
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container newpopup-bg addNotificationPopBg">
        <div
          className="newpopup bg addNotificationPop"
          style={{ width: "50%" }}
        >
          <div className="newpopup-head ps-0 pe-0">
            <div className="popUpTitle">
              {mode === "en" ? "Notification" : "सूचना"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          {/* {loading ? (
            <>
              <Spinner />
            </>
          ) : ( */}
          <div className="newpopup-body ps-3 pe-3 pt-2">
            <div className="form-padding">
              <div className="row text-start ">
                <div className="form-group mt-0">
                  <label htmlFor="type">
                    {mode === "en" ? "Select Type" : "प्रकार चयन गर्नुहोस्"}{" "}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    value={notificationValues.all}
                    name="all"
                    id="type"
                    onChange={handle}
                    onClick={setSubmit(false)}
                  >
                    <option value="a">
                      {mode === "en" ? "Select All" : "सबै चयन गर्नुहोस्"}
                    </option>
                    {/* <option value="d">Department Wise</option>
                    <option value="s">Sub Department Wise</option> */}
                    <option value="i">
                      {mode === "en" ? "Individual" : "व्यक्तिगत"}
                    </option>
                    {/* <option value="de">Designation</option> */}
                  </select>
                </div>
              </div>

              {notificationValues.all === "d" && (
                <div className="row text-start mt-2">
                  <div className="form-group">
                    <label htmlFor="department">
                      {mode === "en"
                        ? "Select Department"
                        : "विभाग चयन गर्नुहोस्"}{" "}
                      <sup style={{ color: "red" }}>*</sup>
                    </label>
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      name="department"
                      id="department"
                      value={notificationValues.department}
                      onChange={handleChange}
                    >
                      <option disabled value="" selected>
                        {mode === "en"
                          ? "Select Department"
                          : "विभाग चयन गर्नुहोस्"}
                      </option>
                      {departmentList.map((list) => (
                        <option
                          key={list.DepartmentID}
                          value={list.DepartmentID}
                        >
                          {list.Department}
                        </option>
                      ))}
                    </select>
                    {notificationErrors.department && (
                      <p className="errormsg">
                        {notificationErrors.department}
                      </p>
                    )}
                  </div>
                </div>
              )}

              {notificationValues.all === "s" && (
                <div className="row text-start mt-2">
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="department">
                        {mode === "en"
                          ? "Select Department"
                          : "विभाग चयन गर्नुहोस्"}{" "}
                        <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <select
                        class="form-select form-select-sm"
                        aria-label="Default select example"
                        name="department"
                        id="department"
                        value={notificationValues.department}
                        onChange={handleChange}
                      >
                        <option disabled value="" selected>
                          {mode === "en"
                            ? "Select Department"
                            : "विभाग चयन गर्नुहोस्"}
                        </option>
                        {departmentList.map((list) => (
                          <option
                            key={list.DepartmentID}
                            value={list.DepartmentID}
                          >
                            {list.Department}
                          </option>
                        ))}
                      </select>
                      {notificationErrors.department && (
                        <p className="errormsg">
                          {notificationErrors.department}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="subDepartment">
                        {mode === "en"
                          ? "Select Sub-Department"
                          : "उप विभाग चयन गर्नुहोस्"}{" "}
                        <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <select
                        class="form-select form-select-sm"
                        aria-label="Default select example"
                        name="subDepartment"
                        id="subDepartment"
                        value={notificationValues.subDepartment}
                        onChange={handleChange}
                      >
                        <option disabled value="" selected>
                          {mode === "en"
                            ? "Select Sub-Department"
                            : "उप विभाग चयन गर्नुहोस्"}
                        </option>
                        {subdepartmentList.map((list) => (
                          <option
                            key={list.SubDepartID}
                            value={list.SubDepartID}
                          >
                            {list.SubDepartName}
                          </option>
                        ))}
                      </select>
                      {notificationErrors.subDepartment && (
                        <p className="errormsg">
                          {notificationErrors.subDepartment}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {notificationValues.all === "i" && (
                <>
                  {/* <div className="row text-start mt-2">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="department">
                          {mode === "en"
                            ? "Select Department"
                            : "विभाग चयन गर्नुहोस्"}{" "}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example"
                          name="department"
                          id="department"
                          value={notificationValues.department}
                          onChange={handleChange}
                        >
                          <option disabled value="" selected>
                            {mode === "en"
                              ? "Select Department"
                              : "विभाग चयन गर्नुहोस्"}
                          </option>
                          {departmentList.map((list) => (
                            <option
                              key={list.DepartmentID}
                              value={list.DepartmentID}
                            >
                              {list.Department}
                            </option>
                          ))}
                        </select>
                        {notificationErrors.department && (
                          <p className="errormsg">
                            {notificationErrors.department}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="subDepartment">
                          {mode === "en"
                            ? "Select Sub-Department"
                            : "उप विभाग चयन गर्नुहोस्"}{" "}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example"
                          name="subDepartment"
                          id="subDepartment"
                          value={notificationValues.subDepartment}
                          onChange={handleChange}
                        >
                          <option disabled value="" selected>
                            {mode === "en"
                              ? "Select Sub-Department"
                              : "उप विभाग चयन गर्नुहोस्"}
                          </option>
                          {subdepartmentList.map((list) => (
                            <option
                              key={list.SubDepartID}
                              value={list.SubDepartID}
                            >
                              {list.SubDepartName}
                            </option>
                          ))}
                        </select>
                        {notificationErrors.subDepartment && (
                          <p className="errormsg">
                            {notificationErrors.subDepartment}
                          </p>
                        )}
                      </div>
                    </div>
                  </div> */}

                  <div className="row text-start mt-2">
                    <div className="form-group">
                      <label htmlFor="staff">
                        {mode === "en"
                          ? "Select Staff"
                          : "कर्मचारी चयन गर्नुहोस्"}{" "}
                        <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <select
                        class="form-select form-select-sm"
                        aria-label="Default select example"
                        name="staff"
                        id="staff"
                        value={notificationValues.staff}
                        onChange={handleChange}
                      >
                        <option disabled value="" selected>
                          {mode === "en"
                            ? "Select Staff"
                            : "कर्मचारी चयन गर्नुहोस्"}
                        </option>
                        {staffList.map((item) => (
                          <option key={item.UserID} value={item.UserID}>
                            {item.FullName}
                          </option>
                        ))}
                      </select>
                      {notificationErrors.staff && (
                        <p className="errormsg">{notificationErrors.staff}</p>
                      )}
                    </div>
                  </div>
                </>
              )}

              {notificationValues.all === "de" && (
                <>
                  <div className="row text-start mt-2">
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="department">
                          {mode === "en"
                            ? "Select Department"
                            : "विभाग चयन गर्नुहोस्"}{" "}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example"
                          name="department"
                          id="department"
                          value={notificationValues.department}
                          onChange={handleChange}
                        >
                          <option disabled value="" selected>
                            {mode === "en"
                              ? "Select Department"
                              : "विभाग चयन गर्नुहोस्"}
                          </option>
                          {departmentList.map((list) => (
                            <option
                              key={list.DepartmentID}
                              value={list.DepartmentID}
                            >
                              {list.Department}
                            </option>
                          ))}
                        </select>
                        {notificationErrors.department && (
                          <p className="errormsg">
                            {notificationErrors.department}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="subDepartment">
                          {mode === "en"
                            ? "Select Sub-Department"
                            : "उप विभाग चयन गर्नुहोस्"}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example"
                          name="subDepartment"
                          id="subDepartment"
                          value={notificationValues.subDepartment}
                          onChange={handleChange}
                        >
                          <option disabled value="" selected>
                            {mode === "en"
                              ? "Select Sub-Department"
                              : "उप विभाग चयन गर्नुहोस्"}
                          </option>
                          {subdepartmentList.map((list) => (
                            <option
                              key={list.SubDepartID}
                              value={list.SubDepartID}
                            >
                              {list.SubDepartName}
                            </option>
                          ))}
                        </select>
                        {notificationErrors.subDepartment && (
                          <p className="errormsg">
                            {notificationErrors.subDepartment}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row text-start mt-2">
                    <div className="form-group">
                      <label htmlFor="designation">
                        {mode === "en"
                          ? "Select Designation"
                          : "पदनाम चयन गर्नुहोस्"}{" "}
                        <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <select
                        class="form-select form-select-sm"
                        aria-label="Default select example"
                        name="designation"
                        id="designation"
                        value={notificationValues.designation}
                        onChange={handleChange}
                      >
                        <option disabled value="" selected>
                          {mode === "en"
                            ? "Select Designation"
                            : "पदनाम चयन गर्नुहोस्"}
                        </option>
                        {designationList.map((list) => (
                          <option
                            key={list.DesignationID}
                            value={list.DesignationID}
                          >
                            {list.Designation}
                          </option>
                        ))}
                      </select>
                      {notificationErrors.designation && (
                        <p className="errormsg">
                          {notificationErrors.designation}
                        </p>
                      )}
                    </div>
                  </div>
                </>
              )}

              <div className="row text-start ">
                <div className="form-group">
                  <label htmlFor="title">
                    {mode === "en" ? "Title" : "शीर्षक"}{" "}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="title"
                    name="title"
                    value={notificationValues.title}
                    onChange={handleChange}
                    style={{ fontSize: "13px" }}
                    type="text"
                    placeholder={mode === "en" ? "Title" : "शीर्षक"}
                    className="form-control form-control-sm "
                  />
                  {notificationErrors.title && (
                    <p className="errormsg">{notificationErrors.title}</p>
                  )}
                </div>
              </div>

              <div className="row text-start ">
                <div className="form-group">
                  <label htmlFor="description">
                    {mode === "en" ? "Description" : "विवरण"}{" "}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <textarea
                    id="description"
                    value={notificationValues.description}
                    onChange={handleChange}
                    style={{ fontSize: "13px" }}
                    class="form-control ps-2"
                    name="description"
                    rows="3"
                    cols="12"
                    placeholder={mode === "en" ? "Description" : "विवरण"}
                  ></textarea>
                  {notificationErrors.description && (
                    <p className="errormsg">{notificationErrors.description}</p>
                  )}
                </div>
              </div>

              {/* <div className="row text-start ">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="actionButton" >
                    Action Button
                  </label>
                  <input
                    id="actionButton"
                    name="actionButton"
                    value={notificationValues.actionButton}
                    onChange={handleChange}
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="actionUrl" >
                    Action URL
                  </label>
                  <input
                    id="actionUrl"
                    name="actionUrl"
                    value={notificationValues.actionUrl}
                    onChange={handleChange}
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                  />
                </div>
              </div>
            </div> */}

              {/* <div className="row text-start mt-3 ">
                <label className="text-start mb-1" style={{ fontSize: "12px" }}>
                  {mode === "en" ? "Publish Date" : "प्रकाशित मिति"}
                  <sup style={{ color: "red" }}>*</sup>
                </label>
                {DFlag === "N" ? (
                  <Calendar
                    className="form-control form-control-sm pt-0 pb-0 "
                    dateFormat="YYYY/MM/DD"
                    // defaultDate="2010/01/01"
                    theme="default"
                    language="en"
                    value={notificationValues.pubDate}
                    onChange={handleIssueDate}
                    name="pubDate"
                    key={re}
                    // hideDefaultValue={true}
                    // placeholder="YYYY/MM/DD"
                  />
                ) : (
                  <input
                    type="date"
                    value={notificationValues.pubDate}
                    placeholder="Select a Date"
                    className="form-control form-control-sm "
                    name="pubDate"
                    onChange={handleChange}
                  />
                )}
                {notificationErrors.pubDate && (
                  <p className="errormsg">{notificationErrors.pubDate}</p>
                )}
              </div> */}

              {/* <div className="row text-start mb-2">
                <div className="form-group">
                  <div className="form-group  ">
                    <label
                      className="form-label"
                      htmlFor="text"
                      
                    >
                      Add Image
                    </label>

                    <div className="BoxUpload">
                      <div className="image-upload">
                        {!isUploaded ? (
                          <>
                            <label htmlFor="upload-input">
                              <img
                                src={Plus}
                                draggable={"false"}
                                alt="placeholder"
                                style={{
                                  width: 90,
                                  height: 100,
                                  paddingTop: "10px",
                                }}
                              />
                            </label>

                            <input
                              id="upload-input"
                              type="file"
                              accept=".jpg,.jpeg,.gif,.png,.mov,.mp4"
                              onChange={handleImageChange}
                              name="image"
                            />
                          </>
                        ) : (
                          <div className="ImagePreview">
                            <img
                              className="close-icon"
                              src={CloseIcon}
                              alt="CloseIcon"
                              onClick={() => {
                                setIsUploaded(false);
                                setImage(null);
                              }}
                            />

                            <img
                              id="uploaded-image"
                              src={image}
                              draggable={false}
                              alt="uploaded-img"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="row text-start mt-1">
                <div className="form-group">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="0"
                      id="fieldWork"
                      name="fieldWork"
                      checked={notificationValues.fieldWork}
                      // onChange={() => setFieldWork(!fieldWork)}
                    />
                    <label
                      style={{ fontSize: "12px", cursor: "pointer" }}
                      class="form-check-label"
                      htmlFor="fieldWork"
                    >
                      {mode === "en"
                        ? "Notify Users"
                        : "प्रयोगकर्ताहरूलाई सूचित गर्नुहोस्"}
                    </label>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* //   )} */}
          <div className="new-footer">
            <div className="row  mt-1 mb-1">
              <div>
                <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={handleSubmit}
                >
                  {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationPopup;
