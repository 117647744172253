import React, { useContext, useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import AuthContext from "../../context/auth-context";
import { Fetchdata } from "../../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../loading/spinner";
import NepaliDate from "nepali-date-converter";
import { GetCurrYear, GetEngCurrYear } from "../../hooks/dateConvertor";
import StaffContext from "../organization/staffState/StaffContext";
import Toast from "../../Toast";
import { AiOutlineSearch } from "react-icons/ai";
import jsPDF from "jspdf";
import "jspdf-autotable";
import UpperbarContext from "../../context/upperbar-context";
import { englishToNepaliNumber } from "nepali-number";
import { generatePdf } from "../../hooks/PdfExcel";
export default function AMonthlySummary({
  DFlag,
  month,
  year,
  department,
  subDepartment,
  appURL,
  monthExcel,
  monthPdf,
}) {
  const { User } = useContext(AuthContext);
  const { userDetails, mode, customStyles } = useContext(UpperbarContext);
  const [loading, setLoading] = useState(true);
  const [mSummary, setMSummary] = useState([]);
  const searchInput = useRef("");
  const [originalList, setOriginalList] = useState("");

  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      grow: 0,
      center: true,
      width: "70px",
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },
    {
      name: mode === "en" ? "Name" : "नाम",
      // center: true,
      selector: (row) => row.StaffName,
    },

    {
      name: mode === "en" ? "Present" : "उपस्थित",
      // center: true,
      width: "150px",
      selector: (row) => row.TotalPresent,
    },
    {
      name: mode === "en" ? "Absent" : "अनुपस्थित",
      // center: true,
      width: "150px",
      selector: (row) => row.TotalAbsent,
    },

    {
      name: mode === "en" ? "Half Day" : "आधा दिन",
      // center: true,
      width: "150px",
      selector: (row) => row.HalfDays,
    },
    {
      name: mode === "en" ? "Full Day" : "पुरा दिन",
      // center: true,
      width: "150px",
      selector: (row) => row.FullDays,
    },
    {
      name: mode === "en" ? "Leave" : "बिदा",
      // center: true,
      width: "150px",
      selector: (row) => row.Leave,
    },
    {
      name: mode === "en" ? "Working days" : "काम गर्ने दिनहरू",
      width: "150px",
      // center: true,
      selector: (row) => row.TotalAbsent + row.TotalPresent,
    },
  ];

  useEffect(() => {
    if (Object.keys(User).length) {
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        DepartmentID: department,
        Flag: "M",
        Value: `${year}/${month}`,
        DFlag: DFlag,
        Type: "POST",
        FetchURL: `${appURL}api/admin/atten-summary`,
      };

      Fetchdata(dataForm)
        .then(function (result) {
          if (result.StatusCode === 200) {
            const postResult = result.Values ? result.Values : "";
            setMSummary(postResult);
            setOriginalList(postResult);
            setLoading(false);
          } else {
            setMSummary([]);
            setOriginalList([]);
            setLoading(false);
          }
        })
        .catch((err) => {
          setMSummary([]);
          setOriginalList([]);
          setLoading(false);
        });
    }
  }, [month, year, department]);

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();

    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["StaffName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setMSummary(srchResult);
      } else {
        setMSummary({});
      }
    } else {
      setMSummary(originalList);
    }
  };

  const dataWithIndex = mSummary.map((d, i) => ({
    ...d,
    Index: i + 1,
    Total: d.TotalPresent + d.TotalAbsent,
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Name", field: "StaffName" },
    { header: "Present", field: "TotalPresent" },
    { header: "Absent", field: "TotalAbsent" },
    { header: "Half Days", field: "HalfDays" },
    { header: "Full Days", field: "FullDays" },
    { header: "Leave", field: "Leave" },
    { header: "Working Days", field: "Total" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  useEffect(() => {
    if (monthPdf) {
      generatePdf(userDetails, exportColumns, dataWithIndex);
    }
  }, [monthPdf]);

  const dataToExcel = mSummary.map((d, i) => ({
    "S.N.": i + 1,
    Name: d.StaffName,
    Present: d.TotalPresent,
    Absent: d.TotalAbsent,
    "Half Days": d.HalfDays,
    "Full Days": d.FullDays,
    Leave: d.Leave,
    "Working Days": d.TotalPresent + d.TotalAbsent,
  }));

  useEffect(() => {
    if (monthExcel) {
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
        var wscols = [
          { wch: 5 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
        ];
        worksheet["!cols"] = wscols;
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        saveAsExcelFile(excelBuffer, "Monthly summary");
      });
    }
  }, [monthExcel]);

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  return (
    <>
      {/* <Toast /> */}

      <div>
        {loading ? (
          <>
            {/* <div
              className=" text-center d-flex flex-column justify-content-center align-items-center"
              style={{ margin: "10% auto", width: "120px" }}
            >
              <p className="initial-msg">Please provide input!</p>
            </div> */}
            <Spinner />
          </>
        ) : (
          <DataTable
            columns={columns}
            data={mSummary}
            customStyles={customStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="375px"
            progressPending={loading}
            highlightOnHover
            pointerOnHover
            paginationRowsPerPageOptions={[10, 20, 50, 100]} // Customizable options
            responsive
            dense
            striped
            subHeader
            subHeaderComponent={
              <>
                <div className=" w-100">
                  <div className="d-flex uk-flex-middle justify-content-end">
                    <div>
                      <div class="uk-search uk-search-default">
                        <AiOutlineSearch className="search-icon" />
                        <input
                          placeholder={
                            mode === "en" ? "Search" : "खोजी गर्नुहोस्"
                          }
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
          />
        )}
      </div>
    </>
  );
}
