import React, { useState, useEffect, useContext } from "react";
import PersonalSlipContext from "./PersonalSlipContext";
import { Fetchdata } from "../../../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import AuthContext from "../../../context/auth-context";
import UpperbarContext from "../../../context/upperbar-context";
import $ from "jquery";
import { GetCurrYear, GetCurrMonth } from "../../../hooks/dateConvertor";

function PersonalSlipState(props) {
  const { User } = useContext(AuthContext);
  const { appURL } = useContext(UpperbarContext);
  const initalvalue = {
    year: GetCurrYear(),
    month: GetCurrMonth(),
    user: "-1",
  };

  const [inputValues, setInputValues] = useState(initalvalue);

  const [slipList, setSlipList] = useState([]);

  useEffect(() => {
    slipLst();
  }, [inputValues]);

  const slipLst = () => {
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: inputValues.user,
      CID: "1",
      Flag: "S",
      MonthEn: "",
      MonthNp: `${inputValues.year}/${inputValues.month}`,
      BranchID: Branch,
      FiscalID: Fiscal,
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/payroll-slip`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setSlipList(postResult);
      } else {
        setSlipList([]);
      }
    });
  };

  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    staffLst();
  }, []);

  const staffLst = () => {
    const BranchID = "" + User.BranchID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      DFlag: "STAFF",
      BranchID: BranchID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/ref-values`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setStaffList(postResult);
      } else {
        setStaffList([]);
      }
    });
  };

  return (
    <PersonalSlipContext.Provider
      value={{
        inputValues,
        setInputValues,
        slipList,
        staffList,
      }}
    >
      {props.children}
    </PersonalSlipContext.Provider>
  );
}

export default PersonalSlipState;
