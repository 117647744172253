import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "../../../images/CloseIcon.svg";
import JobForm from "./JobForm";
import JobEditForm from "./JobEditForm";
import Delete from "./Delete";
import JobActivate from "./JobActivate";
import JobDeactivate from "./JobDeactivate";
import ViewJob from "./ViewJob";
import Candidate from "./Candidate/Candidate";
import { BsGlobe } from "react-icons/bs";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import UpperbarContext from "../../context/upperbar-context";
import EliteJobContext from "../EliteJobContext/EliteJobContext";
import CandidateContext from "../CandidateContext/CandidateContext";
import { MdOutlineDelete } from "react-icons/md";
import { AiOutlineEdit, AiOutlineSearch } from "react-icons/ai";
import { GoPlus } from "react-icons/go";
import $ from "jquery";
import Spinner from "../../loading/spinner";
import { englishToNepaliNumber } from "nepali-number";
import { GetNepaliDate } from "../../hooks/dateConvertor";
export default function Job({ handleSubmit }) {
  const { customStyles, mode, dateMode } = useContext(UpperbarContext);
  const {
    setJobFormValue,
    jobvalue,
    popup,
    setPopup,
    editPop,
    originalList,
    loading,
    jobList,
    handleEdit,
    jobStatus,
    setJobStatus,
    changeStatus,
    setJobList,
    togglePopup,
    setJobFormError,
    setIsSubmit,
    setImage,
    setIsUploaded,
    toggleEditPopup,
    setEditIsSubmit,
    setNegotiable,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,

    handleActivate,
    handleInActivate,
    activatePopup,
    inActivatePopup,
    handleActivateTrue,
    handleActivateFalse,
    handleInActivateTrue,
    handleInActivateFalse,
    handleView,
    viewPop,
    toggleViewPopup,
    viewJob,

    setDescription,

    setQualification,

    setResponsibility,
    candidatePop,
    setCandidatePop,
    isActive,
    setIsActive,
  } = useContext(EliteJobContext);

  const [activeTab, setActiveTab] = useState({
    tab1: true,
    tab2: false,
  });

  const handleTab1 = () => {
    setActiveTab({
      tab1: true,
      tab2: false,
    });
  };
  const handleTab2 = () => {
    setActiveTab({
      tab1: false,
      tab2: true,
    });
  };

  useEffect(() => {
    if (activeTab.tab1 === true) {
      setIsActive("A");
    } else if (activeTab.tab2 === true) {
      setIsActive("-1");
    }
  }, [activeTab]);

  const { tableData, setTableData } = useContext(CandidateContext);
  const searchInput = useRef("");
  const closeViewPopup = (e) => {
    e.preventDefault();
    toggleViewPopup();
  };
  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    // {
    //   name: "Title",
    //   minWidth: "250px",
    //   selector: (row) => row.Title,
    // },
    {
      name: "Designation",
      width: "200px",
      selector: (row) => row.Designation,
    },
    {
      name: "Job Type",
      selector: (row) => row.JobType,
    },
    // {
    //   name: "Shift",
    //   selector: (row) => row.Shift,
    // },
    {
      name: "No of Position",
      width: "120px",
      selector: (row) => row.NoOfPos,
    },
    {
      name: "Applicant",
      width: "90px",
      selector: (row) => (
        <span
          style={{ color: "var(--MainColor)", textDecoration: "underline" }}
          onClick={() => handleCandidate(row)}
        >
          {row.NoOfApplication}
        </span>
      ),
    },
    {
      name: "No of View",
      width: "100px",
      selector: (row) => row.NoOfView,
    },
    {
      name: "Expiry Date",
      width: "110px",
      selector: (row) =>
        dateMode === "en" ? row.EndDate : GetNepaliDate(row.EndDate),
    },
    {
      name: "Created Date",
      width: "120px",
      selector: (row) =>
        dateMode === "en"
          ? row.CreatedDate
          : GetNepaliDate(row.CreatedDate),
    },

    // {
    //   name: "Shift Type",
    //   selector: (row) => row.ShiftType,
    // },

    // {
    //   name: "Interview Date",
    //   width: "120px",
    //   selector: (row) => row.InterviewDate,
    // },
    {
      name: "Status",
      // grow: 0,
      center: true,
      width: "120px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              {row.IsPublished === "I" ? (
                <>
                  <button
                    type="button"
                    className="border-0 bg-transparent"
                    onClick={() => handleActivate(row)}
                  >
                    <span
                      className=" badge rounded-pill bg-success"
                      style={{ fontSize: "11px" }}
                    >
                      Publish
                    </span>
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="border-0 bg-transparent"
                    onClick={() => handleInActivate(row)}
                  >
                    <span
                      className=" badge rounded-pill bg-danger"
                      style={{ fontSize: "11px" }}
                    >
                      Unpublish
                    </span>
                  </button>
                </>
              )}
            </div>
          </>
        );
      },
    },

    {
      name: "Action",
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <>
              <div className="ln-verition d-flex">
                {/* <Tippy content="View">
                  <i
                    className="bi bi-eye  viewspan--action"
                    onClick={() => handleView(row)}
                  ></i>
                </Tippy> */}
                <button
                  type="button"
                  class="btn btn-sm editspan mx-1"
                  onClick={() => handleEdit(row)}
                  uk-tooltip={mode === "en" ? "View" : "हेर्नुहोस्"}
                >
                  <AiOutlineEdit />
                </button>{" "}
                <button
                  type="button"
                  class="btn btn-sm deletespan mx-1"
                  onClick={() => handleDelete(row.JobID)}
                  uk-tooltip={mode === "en" ? "Delete" : "हटाउनुहोस्"}
                >
                  <MdOutlineDelete />
                </button>{" "}
              </div>
            </>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    if (popup) {
      $(".addJOBPopBg").fadeIn(500);
      $(".addJOBPop").slideDown(500);
    }
  }, [popup]);

  useEffect(() => {
    if (editPop) {
      $(".editJOBPopBg").fadeIn(500);
      $(".editJOBPop").slideDown(500);
    }
  }, [editPop]);
  useEffect(() => {
    if (candidatePop) {
      $(".candidatePopBg").fadeIn(500);
      $(".candidatePop").slideDown(500);
    }
  }, [candidatePop]);

  const closePopup = (e) => {
    e.preventDefault();
    setPopup(false);
    setJobFormValue(jobvalue);
    setJobFormError({});
    setIsSubmit(false);
    setImage("");
    setIsUploaded(false);
    setNegotiable(false);
    $(".addJOBPopBg").fadeOut(500);
    $(".addJOBPop").slideUp(500);
    $(".side__panel,.nav").removeClass("z-index-1");
  };
  const closeEditPopup = (e) => {
    e.preventDefault();
    toggleEditPopup();
    setJobFormValue(jobvalue);
    setJobFormError({});
    setEditIsSubmit(false);
    setImage("");
    setIsUploaded(false);
    setNegotiable(false);
    $(".editJOBPopBg").fadeOut(500);
    $(".editJOBPop").slideUp(500);
    $(".side__panel,.nav").removeClass("z-index-1");
  };

  const addnew = (e) => {
    e.preventDefault();
    setPopup(true);
    setIsSubmit(false);
    setJobFormValue(jobvalue);
    setNegotiable(false);
    setDescription("");
    setQualification("");
    setResponsibility("");
    $(".side__panel,.nav").addClass("z-index-1");
  };
  const closeCandidate = (e) => {
    e.preventDefault();
    setCandidatePop(false);
    $(".candidatePopBg").fadeOut(500);
    $(".candidatePop").slideUp(500);
    $(".side__panel,.nav").removeClass("z-index-1");
  };
  const handleCandidate = (data) => {
    setCandidatePop(true);
    $(".candidatePopBg").fadeIn(500);
    $(".candidatePop").slideDown(500);
    $(".side__panel,.nav").addClass("z-index-1");
    setTableData(data);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Designation"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setJobList(srchResult);
      } else {
        setJobList({});
      }
    } else {
      setJobList(originalList);
    }
  };

  return (
    <>
      <div className="sec-dataTable">
        <div className="insidePopup mt-3 attRoute">
          <ul>
            <li
              className={activeTab.tab1 === true ? "active" : ""}
              onClick={handleTab1}
            >
              <i className="fa fa-sliders icon"></i>
              {mode === "en" ? "Active" : "सक्रिय"}{" "}
            </li>

            <li
              className={activeTab.tab2 === true ? "active" : ""}
              onClick={handleTab2}
            >
              <i className="fas fa-sliders icon"></i>
              {mode === "en" ? "All" : "सबै"}{" "}
            </li>
          </ul>
        </div>
        <div className="upper-dataTbl">
          <div className="d-flex uk-flex-wrap uk-flex-middle uk-flex-right my-3">
            <div className="d-flex uk-flex-wrap uk-flex-middle">
              <div uk-tooltip="Visit Website">
                <a href="https://jobs.eliteinfotechnp.com/" target="_blank">
                  <BsGlobe color="var(--button-color)" size="1rem" />
                </a>
              </div>
              <div uk-tooltip="Download App">
                <a
                  href="https://play.google.com/store/apps/details?id=com.eliteinfotech.elitejobs"
                  target="_blank"
                >
                  <IoLogoGooglePlaystore
                    style={{ marginLeft: "10px" }}
                    color="var(--button-color)"
                    size="1.3rem"
                  />
                </a>
              </div>
            </div>
            <div className="btn-addlnote mx-2">
              <button
                className="btn btn-sm btn-cmpy"
                style={{
                  background: "var(--button-color)",
                  color: "white",
                }}
                onClick={handleSubmit}
              >
                Sync Company
              </button>
            </div>
            <div className="btn-addlnote">
              <button
                type="button"
                class="btn btn-sm"
                style={{
                  background: "var(--button-color)",
                  color: "white",
                }}
                onClick={addnew}
              >
                <GoPlus color="#fff" className="me-1" />
                Add New
              </button>
            </div>
          </div>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <DataTable
            columns={columns}
            data={jobList}
            customStyles={customStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="280px"
            highlightOnHover
            pointerOnHover
            responsive
            dense
            striped
            subHeader
            subHeaderAlign="left"
            subHeaderComponent={
              <>
                {/* <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                                        <label className="list-label">Select Job Title</label>
                                        <select
                                            style={{ fontSize: "11px" }}
                                            name="status"
                                            className="form-control form-control-sm"
                                            aria-label="Default select example "
                                            value={chooseDesignation}
                                            onChange={handleDesignation}
                                        >
                                            <option selected disabled value="0" style={{ fontSize: "11px" }}>
                                                Job Title
                                            </option>

                                            {designationTopicList.map((item) => (
                                                <option
                                                    key={item.DesignationID}
                                                    value={item.DesignationID}
                                                >
                                                    {item.Designation}
                                                </option>
                                            ))}

                                        </select>
                                        <i class="fas fa-angle-down  notificationpositon"></i>
                                    </div>


                                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                                        <label className="list-label">Job Type</label>
                                        <select
                                            style={{ fontSize: "11px" }}
                                            name="snotifiaction"
                                            value={chooseJobType}
                                            onChange={handleJobType}
                                            className="form-control form-control-sm"
                                            aria-label="Default control example "
                                        >
                                            <option
                                                value=""
                                                disabled
                                                selected
                                                style={{ fontSize: "11px" }}
                                            >
                                                Select Job Type
                                            </option>
                                            <option value="Full Time">
                                                Full Time
                                            </option>
                                            <option value="Part Time">
                                                Part Time
                                            </option>
                                            <option value="Intern">
                                                Intern
                                            </option>
                                            <option value="Paid Intern">
                                                Paid Intern
                                            </option>
                                            <option value="Freelance">
                                                Freelance
                                            </option>
                                            <option value="Contract">
                                                Contract
                                            </option>
                                            <option value="Training">
                                                Training
                                            </option>
                                        </select>
                                        <i class="fas fa-angle-down  notificationpositon"></i>
                                    </div> */}

                <div
                  className="col-lg-2 offset-lg-10 col-md-2 offset-md-10 col-sm-2 align-self-end Search"
                  style={{ padding: "inherit", marginBottom: "10px" }}
                >
                  <div className="d-flex">
                    <div>
                      <div class="uk-search uk-search-default">
                        <AiOutlineSearch className="search-icon" />
                        <input
                          placeholder={
                            mode === "en" ? "Search" : "खोजी गर्नुहोस्"
                          }
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
          />
        )}
      </div>
      <div className="container newpopup-bg addJOBPopBg">
        <div className="newpopup addJOBPop" style={{ width: "65%" }}>
          <div className="newpopup-head ps-0 pe-0">
            <div className="popUpTitle"> {mode === "en" ? "Job" : "काम"} </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopup}
              />
            </div>
          </div>
          <JobForm closePopup={closePopup} />
        </div>
      </div>

      <div className="container newpopup-bg editJOBPopBg">
        <div className="newpopup editJOBPop" style={{ width: "65%" }}>
          <div className="newpopup-head ps-0 pe-0">
            <div className="popUpTitle">
              {mode === "en" ? "Job Edit" : "काम सम्पादन गर्नुहोस्"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closeEditPopup}
              />
            </div>
          </div>
          <JobEditForm closePopup={closeEditPopup} />
        </div>
      </div>

      {deletePopup.show && (
        <Delete
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}

      {activatePopup.show && (
        <JobActivate
          handleActivateTrue={handleActivateTrue}
          handleActivateFalse={handleActivateFalse}
        />
      )}
      {inActivatePopup.show && (
        <JobDeactivate
          handleInActivateTrue={handleInActivateTrue}
          handleInActivateFalse={handleInActivateFalse}
        />
      )}

      <div className="container newpopup-bg candidatePopBg">
        <div className="newpopup candidatePop" style={{ width: "65%" }}>
          <div className="newpopup-head ps-0 pe-0">
            <div className="popUpTitle">Candidate</div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closeCandidate}
              />
            </div>
          </div>
          <Candidate closeCandidate={closeCandidate} />
        </div>
      </div>
    </>
  );
}
