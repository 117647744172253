import React, { useContext, useEffect, useState } from "react";
import UpperbarContext from "./context/upperbar-context";
import TimeOutPop from "./TimeOutPop";

const TimeOutComponent = () => {
  const { logoutHandler, isActive, setIsActive } = useContext(UpperbarContext);

  const timeoutDuration = 900000; //5  60  1000 5 minutes = 5  60 seconds  1000 milliseconds

  useEffect(() => {
    let timeout;

    const resetTimer = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setIsActive(false);
      }, timeoutDuration);
    };

    const clearTimer = () => {
      clearTimeout(timeout);
    };

    const handleUserActivity = (event) => {
      // Check if the event is a scroll event
      const isScrollEvent = event.type === "scroll";

      // Check if it's not a scroll event
      if (!isScrollEvent) {
        resetTimer();
      }
    };

    // Attach event listeners for user activity
    document.addEventListener("mousemove", handleUserActivity);
    document.addEventListener("keydown", handleUserActivity);
    document.addEventListener("scroll", handleUserActivity);

    resetTimer();

    // Cleanup function to remove event listeners and clear timer
    return () => {
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("keydown", handleUserActivity);
      document.removeEventListener("scroll", handleUserActivity);
      clearTimer();
    };
  }, []);

  useEffect(() => {
    if (!isActive) {
      //   $(".TimeOutpopupBg").fadeIn(500);
      //   $(".TimeOutpopUp").slideDown(500);
      logoutHandler();
    }
  }, [isActive]);

  if (!isActive) {
    return (
      <>
        <TimeOutPop />
      </>
    );
  }
};

export default TimeOutComponent;
