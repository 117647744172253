import React, { useContext, useState, useEffect } from "react";
import UpperbarContext from "../context/upperbar-context";
import AuthContext from "../context/auth-context";
import { Fetchdata } from "../hooks/getData";
import DataTable from "react-data-table-component";
import StaffContext from "../adminPanel/organization/staffState/StaffContext";
import { englishToNepaliNumber } from "nepali-number";

const Birthday = () => {
  const { appURL, mode } = useContext(UpperbarContext);
  const { User } = useContext(AuthContext);
  // const { customStyles } = useContext(StaffContext);
  const [birthdayList, setBirthdayList] = useState([]);
  const d = new Date();
  let month = d.getMonth();
  let curMonth = month + 1;

  const getMonth = "" + curMonth;

  useEffect(() => {
    birthdaylst();
  }, []);

  const birthdaylst = () => {
    const branch = "" + User.BranchID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      RFlag: "M",
      Flag: "S",
      Value: getMonth,
      BranchID: branch,
      Type: "POST",
      FetchURL: `${appURL}api/birthday`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setBirthdayList(postResult);
      } else {
        setBirthdayList([]);
      }
    });
  };


  if (birthdayList.length > 0) {
    return (
      <>
        <div className="mt-3  attendance">
          <table className="uk-table uk-table-striped">
            <thead className="sticky-head">
              <tr>
                <td className="fw-bold">{mode === "en" ? "Name" : "नाम"}</td>
                <td className="fw-bold">
                  {mode === "en" ? "Remaining Days" : "बाँकी दिनहरू"}
                </td>
              </tr>
            </thead>
            <tbody>
              {birthdayList.map((props) => {
                const { FullName, DaysRemaining } = props;
                return (
                  <tr>
                    <td>{FullName}</td>
                    <td>
                      {DaysRemaining < 0 && DaysRemaining !== -1 && (
                        <span>
                          {mode === "en"
                            ? Math.abs(DaysRemaining)
                            : englishToNepaliNumber(
                                Math.abs(DaysRemaining)
                              )}{" "}
                          {mode === "en" ? "days ago" : "दिन पहिले"}
                        </span>
                      )}
                      {DaysRemaining === -1 && (
                        <span>{mode === "en" ? "Yesterday" : "हिजो"}</span>
                      )}
                      {DaysRemaining === 0 && (
                        <span>{mode === "en" ? "Today" : "आज"}</span>
                      )}
                      {DaysRemaining === 1 && (
                        <span>{mode === "en" ? "Tommorow" : "भोलि"}</span>
                      )}
                      {DaysRemaining > 0 && DaysRemaining !== 1 && (
                        <span>
                          {mode === "en"
                            ? DaysRemaining
                            : englishToNepaliNumber(DaysRemaining)}{" "}
                          {mode === "en" ? "days remaining" : "दिन बाँकी"}
                        </span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="uk-flex uk-flex-center mt-3">
          <span>
            {" "}
            {mode === "en" ? "No birthday available" : "जन्मदिन उपलब्ध छैन"}
          </span>
        </div>
      </>
    );
  }
};

export default Birthday;
