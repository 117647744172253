import React, { useContext, useEffect, useState } from "react";
import { FaRegCalendarAlt } from "react-icons/fa";
import UpperbarContext from "../../context/upperbar-context";
import Toast from "../../Toast";
import AAttendanceReport from "./attendanceReport";
import AttendanceRoute from "./AttendanceRoute";
import AdminSummary from "./summary";
import DeviceCode from "./DeviceCode";
import { Link } from "react-router-dom";

export default function AttendanceMain() {
  const { fiscalYear, todayDate, appURL, expires, mode } =
    useContext(UpperbarContext);

  const [activeTab, setActiveTab] = useState({
    tab1: true,
    tab2: false,
    tab3: false,
    tab4: false,
  });

  const handleTab1 = () => {
    setActiveTab({
      tab1: true,
      tab2: false,
      tab3: false,
      tab4: false,
    });
  };
  const handleTab2 = () => {
    setActiveTab({
      tab1: false,
      tab2: true,
      tab3: false,
      tab4: false,
    });
  };
  const handleTab3 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: true,
      tab4: false,
    });
  };
  const handleTab4 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: false,
      tab4: true,
    });
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper  mt-3 ps-4 pe-4">
        <div className="row mt-3 ">
          <div className="page-header">
            <div className="text-start  page-title">
              {mode === "en" ? "Attendance" : "उपस्थिति"}{" "}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <span className="me-2 ">Expires in: {expires} </span>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>
        <div className="sec-dataTable">
          <div className="insidePopup mt-3 attRoute">
            <ul>
              <li
                className={activeTab.tab1 === true ? "active" : ""}
                onClick={handleTab1}
              >
                <i className="fa fa-sliders icon"></i>
                {mode === "en" ? "Report" : "रिपोर्ट"}{" "}
              </li>
              <li
                className={activeTab.tab2 === true ? "active" : ""}
                onClick={handleTab2}
              >
                <i className="fas fa-sliders icon"></i>
                {mode === "en" ? "Summary" : "सारांश"}{" "}
              </li>
              <li
                className={activeTab.tab3 === true ? "active" : ""}
                onClick={handleTab3}
              >
                <i className="fas fa-sliders icon"></i>
                {mode === "en" ? "Route" : "इन्टरनेटको मार्ग"}{" "}
              </li>
              <li
                className={activeTab.tab4 === true ? "active" : ""}
                onClick={handleTab4}
              >
                <i className="fas fa-sliders icon"></i>
                {mode === "en" ? "Device Code" : "उपकरण कोड"}{" "}
              </li>
            </ul>
            {activeTab.tab3 && (
              <label className="downloadApp">
                <a
                  target="_blank"
                  // title="https://play.google.com/store/apps/details?id=com.eliteinfotech.elitewifitool"
                  href="https://play.google.com/store/apps/details?id=com.eliteinfotech.elitewifitool"
                >
                  Download app to get SSID
                </a>
              </label>
            )}
          </div>

          <div>
            {activeTab.tab1 && <AAttendanceReport />}
            {activeTab.tab2 && <AdminSummary />}
            {activeTab.tab3 && <AttendanceRoute />}
            {activeTab.tab4 && <DeviceCode />}
          </div>
        </div>
      </div>
    </>
  );
}
