import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../context/auth-context";
import UpperbarContext from "../context/upperbar-context";
import { Fetchdata } from "../hooks/getData";
import placeholder from "../../images/placeholder.png";
import { ShowImgPreview } from "../hooks/imagePreview";
import { englishToNepaliNumber } from "nepali-number";

const Notification = () => {
  const [notificationList, setNotificationList] = useState([]);
  const {  mode } = useContext(UpperbarContext);
  const appURL = "https://testing.esnep.com/office/";
  const { User } = useContext(AuthContext);
  const [imgPrv, setImgPrv] = useState(false);
  const [ImgPreview, setImgPreview] = useState("");
  useEffect(() => {
    const params = {
      FetchURL: `${appURL}api/notification-list?ComID=${User.CompanyId}&UserID=-1`,
      Type: "GET",
    };

    Fetchdata(params)
      .then(function (result) {
        if (result.StatusCode === 200) {
          const postResult = result.NotificationList
            ? result.NotificationList
            : "";

          setNotificationList(postResult);
        } else {
          setNotificationList([]);
        }
      })
      .catch((err) => {
        setNotificationList([]);
        // setLoading(false);
      });
  }, []);

  if (notificationList.length > 0) {
    return (
      <div className="box uk-margin-top dashNotify">
        <h4>{mode === "en" ? "Notification" : "सूचना"}</h4>
        {notificationList.map((props) => {
          const {
            Title,
            Description,
            PublishedDate,
            Image,
            CreatedBy,
            CreatedByImage,
          } = props;
          return (
            <div className="notifyWrapper">
              <div className="wrapper my-1 border-0">
                <div className="uk-flex uk-flex-between uk-flex-middle">
                  <div>
                    <h5
                      className="fw-bold m-0 mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      {Title}
                    </h5>
                    <span
                      className="mb-1 d-block"
                      style={{ color: "#555", fontSize: "12px" }}
                    >
                      <img
                        src={
                          CreatedByImage === null || CreatedByImage === ""
                            ? placeholder
                            : CreatedByImage
                        }
                        alt="image"
                        className="me-2 "
                        style={{
                          cursor: "pointer",
                          width: "20px",
                          borderRadius: "0.375rem",
                        }}
                        onClick={() => {
                          setImgPreview(
                            CreatedByImage === null || CreatedByImage === ""
                              ? placeholder
                              : CreatedByImage
                          );
                          setImgPrv(true);
                        }}
                      />
                      {CreatedBy} ,{" "}
                      {mode === "en"
                        ? PublishedDate
                        : englishToNepaliNumber(PublishedDate)}
                    </span>
                    <p className="m-0 fw-normal" style={{ fontSize: "16px" }}>
                      {Description}
                    </p>
                  </div>
                  <div>
                    {Image === null || Image === "" ? (
                      ""
                    ) : (
                      <img
                        src={Image}
                        alt="image"
                        style={{
                          cursor: "pointer",
                          width: "60px",
                          borderRadius: "0.375rem",
                        }}
                        onClick={() => {
                          setImgPreview(Image);
                          setImgPrv(true);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        {imgPrv &&
          ShowImgPreview({
            img: ImgPreview,
            setTrigger: setImgPrv,
          })}
      </div>
    );
  } else {
    return (
      <div className="box uk-margin-top dashNotify">
        <h4>{mode === "en" ? "Notification" : "सूचना"}</h4>
        <div className="uk-flex uk-flex-center mt-3">
          <span>
            {mode === "en" ? "No notification available" : "सूचना उपलब्ध छैन"}
          </span>
        </div>
      </div>
    );
  }
};

export default Notification;
