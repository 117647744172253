import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "../../../../images/CloseIcon.svg";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import $ from "jquery";
import Toast from "../../../Toast";
import UpperbarContext from "../../../context/upperbar-context";
import AuthContext from "../../../context/auth-context";
import AccountGroupContext from "../../setup/account group/accountGroupState/AccountGroupContext";
import { Fetchdata } from "../../../hooks/getData";
import { capitalizeFirstLetter } from "../../../hooks/dateConvertor";
// import { Fetchdata } from "../../hooks/getData";

export default function AllowancePopup({
  popup,
  setPopup,
  submit,
  setSubmit,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
  checkedTaxable,
  setCheckedTaxable,
  allowanceLst,
  addNew,
  setAddNew,
  nonAcGrpList,
}) {
  const { appURL, sidePanelBg, mode } = useContext(UpperbarContext);
  const { User } = useContext(AuthContext);

  const [accountList, setAccountList] = useState([]);

  useEffect(() => {
    accountLst();
  }, []);

  const accountLst = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      DFlag: "LEDGER",
      IsBank: "-1",
      IsPrimary: "Y",
      CID: "1",
      Flag: "S",
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/ref-values`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setAccountList(postResult);
      } else {
        setAccountList([]);
      }
    });
  };

  const closePopUp = (e) => {
    setPopup(false);
    $(".addAllowancePopBg").fadeOut(300);
    $(".addAllowancePop").slideUp(500);
    $(".side__panel").removeClass("low");
    $(".nav").removeClass("high");
    setSubmit(false);
    setFormErrors({});
  };

  useEffect(() => {
    if (popup) {
      $(".addAllowancePopBg").fadeIn(500);
      $(".addAllowancePop").slideDown(500);
    }
  }, [popup]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: capitalizeFirstLetter(value) });
    };

  const validate = (values) => {
    const errors = {};
    if (!values.allowance) {
      errors.allowance = mode === "en" ? "Required" : "आवश्यक छ";
    }
    // if (!values.acHead) {
    //   errors.acHead = mode === "en" ? "Required" : "आवश्यक छ";
    // }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && submit) {
      const UserID = "" + User.UID;
      const Branch = "" + User.BranchID;
      const Fiscal = "" + User.FiscalID;
      const dataForm = {
        // Tax: checkedTaxable ? formValues.tax : "",
        // IsActive: "A",

        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: UserID,
        CID: "1",
        Flag: "I",
        DFlag: "A",
        Head: formValues.allowance,
        IsNewLedger: addNew ? "Y" : "N",
        Ledger: addNew ? formValues.ledgerName : "",
        AccountGroup: addNew ? formValues.acGrp : "",
        LedgerID: addNew ? "" : formValues.acHead,
        IsTaxable: checkedTaxable ? "Y" : "N",
        BranchID: Branch,
        FiscalID: Fiscal,

        FetchURL: `${appURL}api/acc/admin/payroll-head`,
        Type: "POST",
      };
      Fetchdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          setPopup(false);
          allowanceLst();
          $(".addAllowancePopBg").fadeOut(300);
          $(".addAllowancePop").slideUp(500);
          $(".side__panel").removeClass("low");
          $(".nav").removeClass("high");

          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });

      setSubmit(false);
    }
  }, [formErrors]);

  return (
    <>
      {/* <Toast /> */}
      <div className="container popup-wrapper addAllowancePopBg">
        <div className="popup-inner addAllowancePop">
          <div className="popUpHeader ps-0 pe-0" style={sidePanelBg}>
            <div className="popUpTitle">
              {mode === "en" ? "Add Addition" : "थप थप्नुहोस्"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div className="popUpBody ps-3 pe-3">
            <div className="form-padding">
              <div className="row text-start">
                <div className="form-group ">
                  <label htmlFor="ledger" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Addition" : "थप"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="ledger"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="allowance"
                    placeholder={mode === "en" ? "Addition" : "थप"}
                    value={formValues.allowance}
                    onChange={handleChange}
                  />
                  {formErrors.allowance && (
                    <p className="errormsg">{formErrors.allowance}</p>
                  )}
                </div>
              </div>

              {!addNew && (
                <div className="row">
                  <div className="form-group">
                    <label htmlFor="acHead" style={{ fontSize: "12px" }}>
                      {mode === "en"
                        ? "Select Account Head"
                        : "खाता प्रमुख चयन गर्नुहोस्"}
                      <sup style={{ color: "red" }}>*</sup>
                    </label>
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      name="acHead"
                      id="acHead"
                      value={formValues.acHead}
                      onChange={handleChange}
                    >
                      <option disabled value="" selected>
                        {mode === "en"
                          ? "Select Account Head"
                          : "खाता प्रमुख चयन गर्नुहोस्"}
                      </option>
                      {accountList.map((props) => {
                        return (
                          <option key={props.LedgerID} value={props.LedgerID}>
                            {props.Ledger}
                          </option>
                        );
                      })}
                    </select>
                    {/* {formErrors.acHead && (
                      <p className="errormsg">{formErrors.acHead}</p>
                    )} */}
                  </div>
                </div>
              )}

              <div className="row">
                <div className="form-group">
                  <div class="form-check" style={{ fontSize: "12px" }}>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="addNew"
                      name="addNew"
                      checked={addNew}
                      onChange={() => setAddNew(!addNew)}
                    />
                    <label
                      style={{ fontSize: "12px", cursor: "pointer" }}
                      class="form-check-label"
                      htmlFor="addNew"
                    >
                      {mode === "en" ? "Add New" : "नयाँ थप्नुहोस्"}
                    </label>
                  </div>
                </div>
              </div>

              {addNew && (
                <>
                  <div className="row text-start">
                    <div className="form-group ">
                      <label htmlFor="ledger" style={{ fontSize: "12px" }}>
                        {mode === "en" ? "Ledger Name" : "खाताको नाम"}
                        <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <input
                        id="ledger"
                        style={{ fontSize: "13px" }}
                        type="text"
                        className="form-control form-control-sm "
                        name="ledgerName"
                        placeholder={
                          mode === "en" ? "Ledger Name" : "खाताको नाम"
                        }
                        value={formValues.ledgerName}
                        onChange={handleChange}
                      />
                      {formErrors.ledgerName && (
                        <p className="errormsg">{formErrors.ledgerName}</p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group">
                      <label htmlFor="acGrp" style={{ fontSize: "12px" }}>
                        {mode === "en"
                          ? "Select Under Account Group"
                          : "खाता समूह अन्तर्गत चयन गर्नुहोस्"}
                      </label>
                      <select
                        class="form-select form-select-sm"
                        aria-label="Default select example"
                        name="acGrp"
                        id="acGrp"
                        value={formValues.acGrp}
                        onChange={handleChange}
                      >
                        <option disabled value="" selected>
                          {mode === "en"
                            ? "Select Account Group"
                            : "खाता समूह चयन गर्नुहोस्"}
                        </option>
                        {nonAcGrpList.map((props) => {
                          return (
                            <option
                              key={props.AccGroupID}
                              value={props.AccGroupID}
                            >
                              {props.AccGroup}
                            </option>
                          );
                        })}
                      </select>
                      {/* {formErrors.acHead && (
                        <p className="errormsg">{formErrors.acHead}</p>
                      )} */}
                    </div>
                  </div>
                </>
              )}

              <div className="row">
                <div className="form-group">
                  <div class="form-check" style={{ fontSize: "12px" }}>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="checkedTaxable"
                      name="checkedTaxable"
                      checked={checkedTaxable}
                      onChange={() => setCheckedTaxable(!checkedTaxable)}
                    />
                    <label
                      style={{ fontSize: "12px", cursor: "pointer" }}
                      class="form-check-label"
                      htmlFor="checkedTaxable"
                    >
                      {mode === "en" ? "Taxable" : "कर योग्य"}
                    </label>
                  </div>
                </div>
              </div>

              {/* {checkedTaxable && (
                <div className="row text-start ">
                  <div className="form-group mt-0">
                    <label htmlFor="tax" style={{ fontSize: "12px" }}>
                      Tax (%)
                    </label>
                    <input
                      id="tax"
                      style={{ fontSize: "13px" }}
                      type="text"
                      className="form-control form-control-sm "
                      name="tax"
                      placeholder=" Tax (%)"
                      value={formValues.tax}
                      onChange={handleChange}
                    />
                  
                  </div>
                </div>
              )} */}
            </div>
          </div>

          <div className="ln-popUpFooter">
            <div className="row  mt-1 mb-1">
              <div>
                <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={handleSubmit}
                >
                  {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
