import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import AuthContext from "../../../context/auth-context";
import UpperbarContext from "../../../context/upperbar-context";
import Toast from "../../../Toast";
import "../ledger report/ledger.css";
import { Fetchdata } from "../../../hooks/getData";
import Spinner from "../../../loading/spinner";
export default function Summary() {
  const { User } = useContext(AuthContext);

  //   const searchInput = useRef("");
  const { fiscalYear, todayDate, appURL, userDetails, darkText, mode } =
    useContext(UpperbarContext);

  //   API to day book list
  const [loading, setLoading] = useState(true);
  const [summaryList, setSummaryList] = useState([]);

  useEffect(() => {
    summaryLst();
  }, []);

  const summaryLst = () => {
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      CID: "1",
      Flag: "SS",
      BranchID: Branch,
      FiscalID: Fiscal,
      Type: "POST",
      FetchURL: `${appURL}api/acc/report/trial-balance`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setSummaryList(postResult);
        setLoading(false);
      } else {
        setSummaryList([]);
        setLoading(false);
      }
    });
  };

  let dr = summaryList.reduce(function (prev, current) {
    return prev + +current.DrAmt;
  }, 0);
  let cr = summaryList.reduce(function (prev, current) {
    return prev + +current.CrAmt;
  }, 0);
  let total = dr - cr;

  return (
    <>
      {/* <Toast /> */}

      <>
        <div className="mt-3" style={{ overflow: "auto" }}>
          {loading ? (
            <Spinner />
          ) : (
            <>
              {summaryList.length > 0 ? (
                <table className="uk-table reportTable">
                  <thead>
                    <tr>
                      <td className="rdt_TableHeadRow tableHead">
                        {mode === "en" ? "Account Head" : "खाता प्रमुख"}
                      </td>
                      <td
                        className="rdt_TableHeadRow tableHead text-end"
                        width="150px"
                      >
                        {mode === "en" ? "Debit Amount" : "डेबिट रकम"}
                      </td>
                      <td
                        className="rdt_TableHeadRow tableHead text-end"
                        width="150px"
                      >
                        {mode === "en" ? "Credit Amount" : "क्रेडिट रकम"}
                      </td>
                      <td
                        className="rdt_TableHeadRow tableHead text-end"
                        width="150px"
                      >
                        {mode === "en" ? "Balance" : "सन्तुलन"}
                      </td>
                    </tr>
                  </thead>

                  <tbody>
                    {summaryList.map((data) => {
                      const total = Math.abs(data.DrAmt - data.CrAmt);
                      return (
                        <tr>
                          <td
                            style={{ minWidth: "200px" }}
                            className="rdt_TableCell tablecell "
                          >
                            {data.AccGroup}
                          </td>
                          <td
                            style={{ minWidth: "150px" }}
                            className="rdt_TableCell tablecell text-end"
                          >
                            {data.DrAmt.toFixed(2)}
                          </td>
                          <td
                            style={{ minWidth: "150px" }}
                            className="rdt_TableCell tablecell text-end"
                          >
                            {data.CrAmt.toFixed(2)}
                          </td>
                          <td
                            style={{ minWidth: "150px" }}
                            className="rdt_TableCell tablecell text-end"
                          >
                            {total.toFixed(2)} {""}
                            {""}
                            {data.CrAmt > data.DrAmt ? "CR" : "DR"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>

                  <tfoot>
                    <tr className="reportTotal">
                      <td className="rdt_TableCell tablecell text-end fw-bold">
                        {mode === "en" ? "Total" : "कुल"}
                      </td>
                      <td className="rdt_TableCell tablecell text-end fw-bold">
                        {dr.toFixed(2)}
                      </td>
                      <td className="rdt_TableCell tablecell text-end fw-bold">
                        {cr.toFixed(2)}
                      </td>
                      <td className="rdt_TableCell tablecell text-end fw-bold">
                        {Math.abs(total).toFixed(2)}
                        {""} {cr > dr ? "CR" : "DR"}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              ) : (
                <p className="text-center">
                  {mode === "en" ? "No Data" : "डाटा उपलब्ध छैन"}
                </p>
              )}
            </>
          )}
        </div>
      </>
    </>
  );
}
