import React, { useContext, useEffect, useState } from "react";
import "./addAttendance.css";
import CloseIcon from "../../../images/CloseIcon.svg";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import { Fetchdata } from "../../hooks/getData";
import AuthContext from "../../context/auth-context";
import NepaliDate from "nepali-date-converter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  GetEnglishDate,
  GetTwelveHourFormatTime,
} from "../../hooks/dateConvertor";
import UpperbarContext from "../../context/upperbar-context";
import AttendanceContext from "../attendanceState/AttendanceContext";
import Toast from "../../Toast";
import $ from "jquery";

export default function AttendanceRoutePopup({ setPopUp, popUp }) {
  const {
    routeValues,
    setRouteValues,
    routeErrors,
    setRouteErrors,
    routeSubmit,
    setRouteSubmit,
    routeActive,
    setRouteActive,
    rtList,
  } = useContext(AttendanceContext);

  const { User } = useContext(AuthContext);
  const { appURL, sidePanelBg, mainBg, mode } = useContext(UpperbarContext);

  //

  useEffect(() => {
    if (popUp) {
      $(".addRoutePopBg").fadeIn(500);
      $(".addRoutePop").slideDown(500);
    }
  }, [popUp]);

  const handleChange = (e) => {
    e.preventDefault();
    const target = e.target;
    const name = target.name;
    const value = target.type == "checkbox" ? target.checked : target.value;

    setRouteValues({ ...routeValues, [name]: value });
  };

  const handleOnChange = (e) => {
    setRouteActive(!routeActive);

    if (e.target.checked === true) {
      setRouteActive(1);
    } else if (e.target.checked === false) {
      setRouteActive(0);
    }
  };

  const closePopUp = (e) => {
    setPopUp(false);
    $(".addRoutePopBg").fadeOut(500);
    $(".addRoutePop").slideUp(500);
    setRouteErrors({});
    setRouteSubmit(false);
    setRouteActive(false);
  };

  const validate = (values) => {
    const errors = {};
    const ptn = /^\w+$/;
    const digitPtn = /[0-9]/;
    const alphaptn = /[a-z]/;
    const capalpha = /[A-Z]/;
    const numv = /^[0-9]+$/i;

    if (!values.name) {
      errors.name = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.ssid) {
      errors.ssid = mode === "en" ? "Required" : "आवश्यक छ";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setRouteErrors(validate(routeValues));
    setRouteSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(routeErrors).length === 0 && routeSubmit) {
      const Branch = "" + User.BranchID;
      const Fiscal = "" + User.FiscalID;
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: User.UID,
        Flag: "i",
        Name: routeValues.name,
        SSID: routeValues.ssid,
        IsActive: routeActive === 1 ? "A" : "I",
        BranchID: Branch,
        FiscalID: Fiscal,
        FetchURL: `${appURL}api/admin/atten-route`,
        Type: "POST",
      };

      Fetchdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
         
          $(".addRoutePopBg").fadeOut(500);
          $(".addRoutePop").slideUp(500);
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
          rtList();
          setPopUp(false);
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
          setRouteErrors({
            errorv: "Please enter valid credentials",
          });
        }
      });

      setRouteSubmit(false);
    }
  }, [routeErrors]);

  //

  return (
    <>
      {/* <Toast /> */}
      <div className="container newpopup-bg addRoutePopBg">
        <div className="newpopup addRoutePop " style={mainBg}>
          <div className="newpopup-head ps-0 pe-0" style={sidePanelBg}>
            <div className="popUpTitle">
              {" "}
              {mode === "en"
                ? "Add Attendance Route"
                : "उपस्थिति इन्टरनेटको मार्ग थप्नुहोस्"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div
            className="newpopup-body ps-3 pe-3"
            style={{ maxHeight: "45vh" }}
          >
            <div className="form-padding">
              <div className="row text-start mt-2 ">
                <div className="col">
                  <label htmlFor="name">
                    {mode === "en" ? "Name" : "नाम"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    className="form-control form-control-sm "
                    onChange={handleChange}
                    value={routeValues.name}
                  />
                  {routeErrors.name && (
                    <p className="errormsg">{routeErrors.name}</p>
                  )}
                </div>
              </div>
              <div className="row text-start mt-2 ">
                <div className="col">
                  <label htmlFor="ssid">
                    {mode === "en" ? "SSID" : "एस एस आईडी"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="ssid"
                    type="text"
                    name="ssid"
                    className="form-control form-control-sm "
                    onChange={handleChange}
                    value={routeValues.ssid}
                  />
                  {routeErrors.ssid && (
                    <p className="errormsg">{routeErrors.ssid}</p>
                  )}
                </div>
              </div>

              <div className="row text-start mt-1">
                <div className="form-group">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="active"
                      name="active"
                      checked={routeActive}
                      onChange={handleOnChange}
                    />
                    <label
                      style={{ fontSize: "12px", cursor: "pointer" }}
                      className="form-check-label"
                      htmlFor="active"
                    >
                      {mode === "en" ? "Active" : "सक्रिय"}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="new-footer">
            <div className="row  mt-1 mb-1">
              <div>
                <button
                  type="button"
                  className="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={handleSubmit}
                >
                  {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
