import React, { useContext, useState, useEffect } from "react";
import { Chart as ChartJS } from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import UpperbarContext from "../context/upperbar-context";
import AuthContext from "../context/auth-context";
import { Fetchdata } from "../hooks/getData";
import { englishToNepaliNumber } from "nepali-number";

const Employee = () => {
  const { appURL, mode } = useContext(UpperbarContext);
  const { User } = useContext(AuthContext);
  const [employeeList, setEmployeeList] = useState([]);

  useEffect(() => {
    Employeelst();
  }, []);

  const Employeelst = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      DFlag: "G",
      Type: "POST",
      FetchURL: `${appURL}api/admin/ref-values`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values[0] ? result.Values[0] : "";

        setEmployeeList(postResult);
      } else {
        setEmployeeList([]);
      }
    });
  };

  const male = employeeList.MaleCt;
  const female = employeeList.FemaleCt;

  return (
    <>
      <div className="box uk-margin-top">
        <h4>{mode === "en" ? "Employee" : "कर्मचारी"}</h4>
        <div className="mt-3">
          <Doughnut
            height={150}
            width={300}
            data={{
              labels: [
                mode === "en" ? "Male" : "पुरुष",
                mode === "en" ? "Female" : "महिला",
              ],
              datasets: [
                {
                  label: "Numbers",
                  data: [male, female],
                  backgroundColor: ["#0049ae", "#6C9BD1"],
                  borderWidth: 1,
                },
              ],
            }}
            options={{ maintainAspectRatio: false }}
          />
        </div>
        <div className="uk-flex uk-flex-center mt-3">
          <h5 className="m-0 mx-2 text-center">
            {mode === "en" ? male : englishToNepaliNumber(male)} <br />{" "}
            {mode === "en" ? "Male" : "पुरुष"}
          </h5>
          <h5 className="m-0 mx-2 text-center">
            {mode === "en" ? female : englishToNepaliNumber(female)} <br />{" "}
            {mode === "en" ? "Female" : "महिला"}
          </h5>
        </div>
      </div>
    </>
  );
};

export default Employee;
