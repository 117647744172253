import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "../../images/CloseIcon.svg";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import { Fetchdata } from "../hooks/getData";
import AuthContext from "../context/auth-context";
import NepaliDate from "nepali-date-converter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import UpperbarContext from "../context/upperbar-context";
import $ from "jquery";
import Plus from "../../images/Plus.png";
import HelpdeskContext from "../helpdeskState/HelpdeskContext";

export default function HelpDeskPop({ setPopUp, popUp, DFlag }) {
  const {
    formValues,
    setFormValues,
    formErrors,
    setFormErrors,
    submit,
    setSubmit,
    formLst,
    isUploaded,
    setIsUploaded,
    typeFile,
    setTypeFile,
    image,
    setImage,
    categoryList,
  } = useContext(HelpdeskContext);

  const { User } = useContext(AuthContext);
  const { appURL, sidePanelBg, mainBg, mode } = useContext(UpperbarContext);

  //

  useEffect(() => {
    if (popUp) {
      $(".addHelpdeskPopBg").fadeIn(500);
      $(".addHelpdeskPop").slideDown(500);
    }
  }, [popUp]);

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;

    setFormValues({ ...formValues, [name]: value });
  };

  const handleDueDate = ({ adDate }) => {
    let name = "dueDate";
    setFormValues({ ...formValues, [name]: adDate });
  };

  const closePopUp = (e) => {
    setPopUp(false);
    $(".addHelpdeskPopBg").fadeOut(500);
    $(".addHelpdeskPop").slideUp(500);
    setFormErrors({});
    setSubmit(false);
  };

  function handleImageChange(e) {
    if (e.target.files && e.target.files[0]) {
      setTypeFile(e.target.files[0].name);
      let reader = new FileReader();

      reader.onload = function (e) {
        setImage(e.target.result);
        setIsUploaded(true);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const validate = (values) => {
    const errors = {};

    if (!values.fullname) {
      errors.fullname = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.category) {
      errors.category = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.subject) {
      errors.subject = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.message) {
      errors.message = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.priority) {
      errors.priority = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.dueDate) {
      errors.dueDate = mode === "en" ? "Required" : "आवश्यक छ";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && submit) {
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: User.UID.toString(),
        Flag: "i",
        CatID: formValues.category,
        FullName: formValues.fullname,
        Email: User.Username,
        DueDate: formValues.dueDate,
        Tags: "Easy School",
        Subject: formValues.subject,
        Description: formValues.message,
        Priority: formValues.priority,
        Attachment: image !== "" ? image.split(",")[1] : "",
        AssignedTo: "0",
        FetchURL: `${appURL}api/help-desk/admin`,
        Type: "POST",
      };

      Fetchdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          $(".addHelpdeskPopBg").fadeOut(500);
          $(".addHelpdeskPop").slideUp(500);
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
          formLst();
          setPopUp(false);
          setImage("");
          setIsUploaded(false);
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
          setFormErrors({
            errorv: "Please enter valid credentials",
          });
        }
      });

      setSubmit(false);
    }
  }, [formErrors]);

  //

  return (
    <>
      {/* <Toast /> */}
      <div className="container newpopup-bg addHelpdeskPopBg">
        <div className="newpopup addHelpdeskPop " style={mainBg}>
          <div className="newpopup-head ps-0 pe-0" style={sidePanelBg}>
            <div className="popUpTitle">
              {" "}
              {mode === "en" ? "Issue Ticket" : "टिकट जारी गर्नुहोस्"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div className="newpopup-body ps-3 pe-3" style={{paddingBottom:"1rem"}}>
            <div className="form-padding">
              <div className="row text-start ">
                <div className="form-group">
                  <label htmlFor="category" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Category" : "श्रेणी"}{" "}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    name="category"
                    value={formValues.category}
                    onChange={handleChange}
                  >
                    <option disabled value="" selected>
                      {mode === "en" ? "Category" : "श्रेणी"}
                    </option>
                    {categoryList.map((item) => (
                      <option key={item.CatID} value={item.CatID}>
                        {item.CatName}
                      </option>
                    ))}
                  </select>
                  {formErrors.category && (
                    <p className="errormsg">{formErrors.category}</p>
                  )}
                </div>
              </div>
              <div className="row text-start mt-3 ">
                <div className="col">
                  <label htmlFor="fullname">
                    {mode === "en" ? "Fullname" : "पुरा नाम"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="fullname"
                    type="text"
                    name="fullname"
                    className="form-control form-control-sm "
                    onChange={handleChange}
                    value={formValues.fullname}
                  />
                  {formErrors.fullname && (
                    <p className="errormsg">{formErrors.fullname}</p>
                  )}
                </div>
              </div>
              <div className="row text-start mt-3 ">
                <div className="col">
                  <label htmlFor="subject">
                    {mode === "en" ? "Subject" : "विषय"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="subject"
                    type="text"
                    name="subject"
                    className="form-control form-control-sm "
                    onChange={handleChange}
                    value={formValues.subject}
                  />
                  {formErrors.subject && (
                    <p className="errormsg">{formErrors.subject}</p>
                  )}
                </div>
              </div>

              <div className="row text-start mt-3 ">
                <label className="text-start mb-1" style={{ fontSize: "12px" }}>
                  {mode === "en" ? "Due Date" : "अन्तिम मिति"}
                  <sup style={{ color: "red" }}>*</sup>
                </label>
                {DFlag === "N" ? (
                  <Calendar
                    className="form-control form-control-sm pt-0 pb-0 "
                    dateFormat="YYYY/MM/DD"
                    // defaultDate="2010/01/01"
                    theme="default"
                    language="en"
                    value={formValues.dueDate}
                    onChange={handleDueDate}
                    name="dueDate"
                    // hideDefaultValue={true}
                    // placeholder="YYYY/MM/DD"
                  />
                ) : (
                  <input
                    type="date"
                    value={formValues.dueDate}
                    placeholder="Select a Date"
                    className="form-control form-control-sm "
                    name="dueDate"
                    onChange={handleChange}
                  />
                )}
                {formErrors.dueDate && (
                  <p className="errormsg">{formErrors.dueDate}</p>
                )}
              </div>
              <div className="row text-start ">
                <div className="form-group">
                  <label htmlFor="message">
                    {mode === "en" ? "Message" : "सन्देश"}{" "}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <textarea
                    id="message"
                    value={formValues.message}
                    onChange={handleChange}
                    style={{ fontSize: "13px" }}
                    class="form-control ps-2"
                    name="message"
                    rows="3"
                    cols="12"
                  ></textarea>
                  {formErrors.message && (
                    <p className="errormsg">{formErrors.message}</p>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="form-group">
                  <div style={{ textAlign: "left" }}>
                    <label className="form-label" htmlFor="purpose">
                      {mode === "en" ? "Priority" : "प्राथमिकता"}
                      <sup style={{ color: "red" }}>*</sup>
                    </label>
                  </div>

                  <div
                    className="d-flex uk-flex-wrap"
                    style={{ rowGap: "10px" }}
                  >
                    <div>
                      <input
                        type="radio"
                        className="radio--button"
                        name="priority"
                        id="low"
                        onChange={handleChange}
                        value="L"
                        checked={formValues.priority === "L"}
                      />
                      <label
                        className="label-radio-routine px-3"
                        htmlFor="low"
                        style={{ marginRight: "10px" }}
                      >
                        {mode === "en" ? "Low" : "कम"}
                      </label>
                    </div>

                    <div>
                      <input
                        type="radio"
                        className="radio--button"
                        name="priority"
                        id="medium"
                        onChange={handleChange}
                        value="M"
                        checked={formValues.priority === "M"}
                      />
                      <label
                        className="label-radio-routine px-3"
                        htmlFor="medium"
                        style={{ marginRight: "10px" }}
                      >
                        {mode === "en" ? "Medium" : "मध्यम"}
                      </label>
                    </div>

                    <div>
                      <input
                        type="radio"
                        className="radio--button"
                        name="priority"
                        id="high"
                        onChange={handleChange}
                        value="H"
                        checked={formValues.priority === "H"}
                      />
                      <label
                        className="label-radio-routine px-3"
                        htmlFor="high"
                        style={{ marginRight: "10px" }}
                      >
                        {mode === "en" ? "High" : "उच्च"}
                      </label>
                    </div>

                    <div>
                      <input
                        type="radio"
                        className="radio--button"
                        name="priority"
                        id="urgent"
                        onChange={handleChange}
                        value="U"
                        checked={formValues.priority === "U"}
                      />
                      <label
                        className="label-radio-routine px-3"
                        htmlFor="urgent"
                        style={{ marginRight: "10px" }}
                      >
                        {mode === "en" ? "Urgent" : "अत्यावश्यक"}
                      </label>
                    </div>
                  </div>

                  {formErrors.priority && (
                    <p className="errormsg">{formErrors.priority}</p>
                  )}
                </div>
              </div>

              <div className="row text-start mb-2">
                <div className="form-group">
                  <div className="form-group  ">
                    <label className="form-label" htmlFor="text">
                      {mode === "en" ? "Add Image" : "छवि थप्नुहोस्"}
                    </label>

                    <div className="BoxUpload">
                      <div className="image-upload">
                        {!isUploaded ? (
                          <>
                            <label htmlFor="upload-input">
                              <img
                                src={Plus}
                                draggable={"false"}
                                alt="placeholder"
                                style={{
                                  width: 90,
                                  height: 100,
                                  paddingTop: "10px",
                                }}
                              />
                            </label>

                            <input
                              id="upload-input"
                              type="file"
                              accept=".jpg,.jpeg,.gif,.png,.mov,.mp4"
                              onChange={handleImageChange}
                              name="image"
                            />
                          </>
                        ) : (
                          <div className="ImagePreview">
                            <img
                              className="close-icon"
                              src={CloseIcon}
                              alt="CloseIcon"
                              onClick={() => {
                                setIsUploaded(false);
                                setImage(null);
                              }}
                            />

                            <img
                              id="uploaded-image"
                              src={image}
                              draggable={false}
                              alt="uploaded-img"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="new-footer">
            <div className="row  mt-1 mb-1">
              <div>
                <button
                  type="button"
                  className="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={handleSubmit}
                >
                  {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
