import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "../../../../images/CloseIcon.svg";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import $ from "jquery";
import Toast from "../../../Toast";
import UpperbarContext from "../../../context/upperbar-context";
import AuthContext from "../../../context/auth-context";
import EditBasic from "./editTab/EditBasic";
import EditAddressContact from "./editTab/EditAddressContact";
import EditDocument from "./editTab/EditDocument";
import { capitalizeFirstLetter } from "../../../hooks/dateConvertor";

export default function EditAgentPopup({
  editPopup,
  setEditPopup,
  perEditSubmit,
  setPerEditSubmit,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
  editData,
  isUploaded,
  setIsUploaded,
  typeFile,
  setTypeFile,
  files,
  setFile,
  checkedActive,
  setCheckedActive,
  checkedVat,
  setCheckedVat,
  agentUnderList,
}) {
  const { appURL, sidePanelBg, mode } = useContext(UpperbarContext);
  const { User } = useContext(AuthContext);

  const [activeTab, setActiveTab] = useState({
    tab1: true,
    tab2: false,
    tab3: false,
  });
  const handleTab1 = () => {
    setActiveTab({
      tab1: true,
      tab2: false,
      tab3: false,
    });
  };
  const handleTab2 = () => {
    setActiveTab({
      tab1: false,
      tab2: true,
      tab3: false,
    });
  };
  const handleTab3 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: true,
    });
  };

  const closePopUp = (e) => {
    setEditPopup(false);
    $(".editAgentPopBg").fadeOut(300);
    $(".editAgentPop").slideUp(500);
    setPerEditSubmit(false);
    setFormErrors({});
    setActiveTab({
      tab1: true,
      tab2: false,
      tab3: false,
    });
  };

  useEffect(() => {
    if (editPopup) {
      $(".editAgentPopBg").fadeIn(500);
      $(".editAgentPop").slideDown(500);
    }
  }, [editPopup]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: capitalizeFirstLetter(value) });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.agentName) {
      errors.agentName = mode === "en" ? "Required" : "आवश्यक छ";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setPerEditSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && perEditSubmit) {
      editData();
      setPerEditSubmit(false);
      setActiveTab({
        tab1: true,
        tab2: false,
        tab3: false,
      });
    }
  }, [formErrors]);

  return (
    <>
      {/* <Toast /> */}
      <div className="container popup-wrapper editAgentPopBg">
        <div className="popup-inner editAgentPop">
          <div className="popUpHeader ps-0 pe-0" style={sidePanelBg}>
            <div className="popUpTitle">
              {mode === "en" ? "Edit Agent " : "एजेन्ट सम्पादन थप्नुहोस्"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div className="popUpBody ps-3 pe-3">
            <div className="form-padding">
              <div className="row text-start">
                <div className="form-group ">
                  <label htmlFor="name" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Name" : "नाम"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="agentName"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="agentName"
                    placeholder={mode === "en" ? "Name" : "नाम"}
                    value={formValues.agentName}
                    onChange={handleChange}
                  />
                  {formErrors.agentName && (
                    <p className="errormsg">{formErrors.agentName}</p>
                  )}
                </div>
              </div>
              <div className="row text-start">
                <div className="form-group">
                  <label htmlFor="alias" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Alias" : "उपनाम"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="alias"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="alias"
                    placeholder={mode === "en" ? "Alias" : "उपनाम"}
                    value={formValues.alias}
                    onChange={handleChange}
                  />
                  {/* {formErrors.alias && (
                    <p classalias="errormsg">{formErrors.name}</p>
                  )} */}
                </div>
              </div>

              <div className="row text-start">
                <div className="form-group">
                  <label htmlFor="under" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Under" : "अन्तर्गत"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    name="under"
                    id="under"
                    value={formValues.under}
                    onChange={handleChange}
                  >
                    <option disabled value="" selected>
                      {mode === "en" ? "Under" : "अन्तर्गत"}
                    </option>
                    <option value="0">
                      {mode === "en" ? "Primary" : "प्राथमिक"}
                    </option>
                    {agentUnderList.map((props) => {
                      return (
                        <option key={props.AgentID} value={props.AgentID}>
                          {props.Agent}
                        </option>
                      );
                    })}
                  </select>
                  {/* {formErrors.under && (
                    <p classunder="errormsg">{formErrors.name}</p>
                  )} */}
                </div>
              </div>

              <div className="row text-start">
                <ul className="tab nav-tabs">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab.tab1 === true ? "active" : ""
                      }`}
                      onClick={handleTab1}
                    >
                      {mode === "en" ? "Basic" : "आधारभूत"}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab.tab2 === true ? "active" : ""
                      }`}
                      onClick={handleTab2}
                    >
                      {mode === "en" ? "Address & Contact" : "ठेगाना र सम्पर्क"}
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab.tab3 === true ? "active" : ""
                      }`}
                      onClick={handleTab3}
                    >
                      {mode === "en" ? "Document" : "कागजात"}
                    </a>
                  </li>
                </ul>
              </div>

              {activeTab.tab1 && (
                <EditBasic
                  formValues={formValues}
                  handleChange={handleChange}
                  formErrors={formErrors}
                  checkedActive={checkedActive}
                  setCheckedActive={setCheckedActive}
                  checkedVat={checkedVat}
                  setCheckedVat={setCheckedVat}
                  mode={mode}
                />
              )}
              {activeTab.tab2 && (
                <EditAddressContact
                  formValues={formValues}
                  handleChange={handleChange}
                  formErrors={formErrors}
                  mode={mode}
                />
              )}

              {activeTab.tab3 && (
                <EditDocument
                  formValues={formValues}
                  handleChange={handleChange}
                  formErrors={formErrors}
                  isUploaded={isUploaded}
                  setIsUploaded={setIsUploaded}
                  typeFile={typeFile}
                  setTypeFile={setTypeFile}
                  files={files}
                  setFile={setFile}
                  mode={mode}
                />
              )}
            </div>
          </div>

          <div className="ln-popUpFooter">
            <div className="row  mt-1 mb-1">
              <div>
                <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={handleSubmit}
                >
                  {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
