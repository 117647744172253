import React, { useContext, useState, useEffect } from "react";
import { TbCheckbox } from "react-icons/tb";
import { HiOutlineClock } from "react-icons/hi";
import { AiOutlineStop } from "react-icons/ai";
import { GiUmbrella } from "react-icons/gi";
import UpperbarContext from "../context/upperbar-context";
import AuthContext from "../context/auth-context";
import { Fetchdata } from "../hooks/getData";
import { englishToNepaliNumber } from "nepali-number";

const Attendance = () => {
  const { appURL, mode } = useContext(UpperbarContext);
  const { User } = useContext(AuthContext);
  const [attList, setAttList] = useState([]);


  useEffect(() => {
    Attlst();
  }, []);

  const Attlst = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      DFlag: "A",
      Type: "POST",
      FetchURL: `${appURL}api/admin/ref-values`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values[0] ? result.Values[0] : "";

        setAttList(postResult);
      } else {
        setAttList([]);
      }
    });
  };

  const attendance =
    mode === "en"
      ? attList.AttendanceCt
      : englishToNepaliNumber(attList.AttendanceCt);
  const late =
    mode === "en"
      ? attList.LateComingCt
      : englishToNepaliNumber(attList.LateComingCt);
  const absent =
    mode === "en" ? attList.AbsentCt : englishToNepaliNumber(attList.AbsentCt);
  const leave =
    mode === "en"
      ? attList.OnLeaveCt
      : englishToNepaliNumber(attList.OnLeaveCt);

  const att = [
    {
      id: 1,
      number: attendance,
      title: mode === "en" ? "Attendance" : "उपस्थित",
      icon: <TbCheckbox />,
    },
    {
      id: 2,
      number: late,
      title: mode === "en" ? "Late Coming" : "ढिलो आउने",
      icon: <HiOutlineClock />,
    },
    {
      id: 3,
      number: absent,
      title: mode === "en" ? "Absent" : "अनुपस्थित",
      icon: <AiOutlineStop />,
    },
    {
      id: 4,
      number: leave,
      title: mode === "en" ? "On Leave" : "बिदामा",
      icon: <GiUmbrella />,
    },
  ];

  return (
    <>
      <div className="box uk-margin-top">
        <h4>{mode === "en" ? "Attendance" : "उपस्थित"}</h4>
        <div className="uk-grid uk-child-width-1-2@l uk-child-width-1-1@m uk-child-width-1-2 ">
          {att.map((props) => {
            const { id, number, title, icon } = props;
            return (
              <div key={id} className="dashAtt">
                <div className="wrapper mt-3">
                  {icon}
                  <p className="m-0 mt-2">{title}</p>
                  <h5 style={{ color: "#555" }} className="m-1">
                    {number}
                  </h5>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Attendance;
