import React, { useContext, useEffect } from "react";
import CloseIcon from "../../images/CloseIcon.svg";
import $ from "jquery";
import UpperbarContext from "../context/upperbar-context";
const ViewTodoPop = ({ viewTodoPop, setViewTodoPop, viewTodoList }) => {
  const { mode } = useContext(UpperbarContext);
  const closePopUp = (e) => {
    setViewTodoPop(false);
    $(".viewTodoPop").slideUp(500);
    $(".viewTodoPopBg").fadeOut(500);
  };

  // Set the deadline date
  const deadlineDate = new Date(viewTodoList.DueDate);

  // Get the current date
  const currentDate = new Date();

  // Calculate the remaining days
  const timeDifference = deadlineDate.getTime() - currentDate.getTime();

  let remainingDays;

  if (deadlineDate < currentDate) {
    remainingDays = "Expired";
  } else {
    remainingDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
  }

  const todo = [
    {
      title: mode === "en" ? "Title" : "शीर्षक",
      body: viewTodoList.Title,
    },
    {
      title: mode === "en" ? "Description" : "विवरण",
      body: viewTodoList.Description,
    },

    {
      title: mode === "en" ? "Due Date" : "अन्तिम मिति",
      body: viewTodoList.DueDate,
    },
    {
      title: mode === "en" ? "Days Remaining" : "दिन बाँकी",
      body:
        remainingDays === 1 ? (
          <span>{remainingDays} day remaining</span>
        ) : remainingDays === "Expired" ? (
          <span>{remainingDays}</span>
        ) : (
          <span>{remainingDays} days remaining</span>
        ),
    },
    {
      title: mode === "en" ? "Priority" : "प्राथमिकता",
      body:
        viewTodoList.Priority === "U"
          ? "Urgent"
          : viewTodoList.Priority === "H"
          ? "High"
          : viewTodoList.Priority === "N"
          ? "Normal"
          : "Low",
    },
    {
      title: mode === "en" ? "Status" : "स्थिति",
      body:
        viewTodoList.Status === "T"
          ? "To Do"
          : viewTodoList.Status === "P"
          ? "In Progress"
          : viewTodoList.Status === "D"
          ? "Done"
          : "Complete",
    },
  ];

  useEffect(() => {
    if (viewTodoPop) {
      $(".viewTodoPop").slideDown(500);
      $(".viewTodoPopBg").fadeIn(500);
    }
  }, [viewTodoPop]);

  return (
    <>
      <div className="container viewNotifyPopup-wrapper viewTodoPopBg">
        <div className="viewNotifyPopup-inner viewTodoPop">
          <div className="popUpHeader ps-0 pe-0">
            <div className="popUpTitle">
              {mode === "en" ? "View" : "हेर्नुहोस्"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div className="viewNotifyPopupBody ps-3 pe-3">
            <div className="row text-start mt-2">
              <table className="table">
                <tbody>
                  {todo.map((props) => {
                    const { title, body } = props;
                    return (
                      <>
                        <tr>
                          <td
                            className="fw-bold"
                            style={{ width: "max-content" }}
                          >
                            {title}
                          </td>
                          <td
                            style={{
                              borderLeft: "1px solid rgb(225, 226, 227)",
                            }}
                          >
                            {body}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="viewNotifyPopupFooter">
            <div className="row  mt-1 mb-1">
              <div>
                {/* <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={handleSubmit}
                >
                  Submit
                </button> */}
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewTodoPop;
