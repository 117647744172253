import React, { useContext, useEffect, useRef, useState } from "react";
import "../../../profile/profile.css";
import DataTable from "react-data-table-component";
import { Fetchdata } from "../../../hooks/getData";
import AuthContext from "../../../context/auth-context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Spinner from "../../../loading/spinner";
import UpperbarContext from "../../../context/upperbar-context";
import { FaRegCalendarAlt } from "react-icons/fa";
import Toast from "../../../Toast";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { GoPlus } from "react-icons/go";
import { AiOutlineEdit, AiOutlineSearch } from "react-icons/ai";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { englishToNepaliNumber } from "nepali-number";
import { generatePdf } from "../../../hooks/PdfExcel";
import PromotionPopup from "./PromotionPopup";
import ApprovePop from "../../leaveReport/ApprovePop";
import RejectPop from "../../leaveReport/RejectPop";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

export default function Promotion() {
  const {
    fiscalYear,
    expires,
    customStyles,
    appURL,
    todayDate,
    mode,
    userDetails,
  } = useContext(UpperbarContext);
  const [popup, setPopup] = useState(false);
  const [viewPopup, setViewPopup] = useState(false);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const searchInput = useRef("");
  const [DFlag, setDFlag] = useState("N");

  const initialValue = {
    staff: "",
    type: "",
    fromDepartment: "",
    fromDesignation: "",
    fromJobType: "",
    fromShift: "",
    fromShiftType: "",
    fromGrade: "",
    fromBranch: "",

    toDepartment: "",
    toDesignation: "",
    toJobType: "",
    toShift: "",
    toShiftType: "",
    toGrade: "",
    toBranch: "",
  };
  const [formValue, setFormValue] = useState(initialValue);
  const [formError, setFormError] = useState({});
  const [isApproved, setIsApproved] = useState(false);

  const [typeOption, setTypeOption] = useState("P");

  const { User } = useContext(AuthContext);

  const addLeaveNote = (e) => {
    setPopup(true);
    setFormValue(initialValue);
  };

  const [viewData, setViewData] = useState();
  const handleView = (datas) => {
    setViewPopup(true);
    setViewData(datas);
  };

  //

  const [approveGmail, setApproveGmail] = useState(false);
  const [rejectGmail, setRejectGmail] = useState(false);

  //Everything to pop up and approve the  list
  const [approvePopup, setApprovePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [rejectPopup, setRejectPopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  // To approve the credit List

  const [Acdata, setACData] = useState();
  const [leaveAccess, setLeaveAccess] = useState();
  const [acceptUserId, setAcceptUserId] = useState();

  const handleApprove = async (data) => {
    setACData(data[0]);
    setLeaveAccess(data[1]);
    setAcceptUserId(data[2]);

    setApprovePopup({
      show: true,
      data,
    });
  };

  // This will perform the approve and hide the Confirmation Box

  const handleApproveTrue = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID.toString(),
      Flag: "AI",
      StaffID: acceptUserId.toString(),
      ProTransferID: Acdata.toString(),
      IsApproved: "Y",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Signature: "p0m76" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/reg-term`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          dataInfo();
          toast.success(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });
    });
    setApprovePopup({
      show: false,
      data: null,
    });
    setApproveGmail(false);
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleApproveFalse = () => {
    setApprovePopup({
      show: false,
      data: null,
    });
    setApproveGmail(false);
  };

  //Everything to pop up and approve the leave list ends

  // To reject the leave List

  const [bcdata, setBcData] = useState();
  const [leavedata, setLeaveData] = useState();
  const [userId, setUserId] = useState();

  const handleReject = async (data) => {
    setBcData(data[0]);
    setLeaveData(data[1]);
    setUserId(data[2]);

    setRejectPopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleRejectTrue = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID.toString(),
      Flag: "AI",
      StaffID: userId.toString(),
      ProTransferID: bcdata.toString(),
      IsApproved: "N",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Signature: "p0m76" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/reg-term`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          dataInfo();
          toast.success(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });
    });
    setRejectPopup({
      show: false,
      data: null,
    });
    setRejectGmail(false);
  };

  //
  // };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleRejectFalse = () => {
    setRejectPopup({
      show: false,
      data: null,
    });
    setRejectGmail(false);
  };

  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      grow: 0,
      center: true,
      width: "70px",
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },
    {
      name: mode === "en" ? "FullName" : "पुरानाम",
      // grow: 0,
      // center: true,
      minWidth: "200px",
      selector: (row) => row.FullName,
    },
    {
      name: mode === "en" ? "From Department" : "विभागबाट",
      width: "150px",
      selector: (row) => row.FromDepart,
    },
    {
      name: mode === "en" ? "To Department" : "विभागलाई",
      width: "150px",
      selector: (row) => row.ToDepart,
    },
    {
      name: mode === "en" ? "From Designation" : "पदनामबाट",
      width: "150px",
      selector: (row) => row.FromDesign,
    },
    {
      name: mode === "en" ? "To Designation" : "पदनामलाई",
      width: "150px",
      selector: (row) => row.ToDesign,
    },
    {
      name: mode === "en" ? "From Job Type" : "कामको प्रकारबाट",
      width: "150px",
      selector: (row) => row.FromJobType,
    },
    {
      name: mode === "en" ? "To Job Type" : "कामको प्रकारलाई",
      width: "150px",
      selector: (row) => row.ToJobType,
    },
    {
      name: mode === "en" ? "From Shift" : "शिफ्टबाट",
      selector: (row) => row.FromShift,
    },
    {
      name: mode === "en" ? "To Shift" : "शिफ्टलाई",
      selector: (row) => row.ToShift,
    },
    {
      name: mode === "en" ? "From Shift Type" : "शिफ्ट प्रकारबाट",
      width: "150px",
      selector: (row) => row.FromShitType,
    },
    {
      name: mode === "en" ? "To Shift Type" : "शिफ्ट प्रकारलाई",
      width: "150px",
      selector: (row) => row.ToShiftType,
    },
    {
      name: mode === "en" ? "From Grade" : "ग्रेडबाट",
      width: "150px",
      selector: (row) => row.FromGrade,
    },
    {
      name: mode === "en" ? "To Grade" : "ग्रेडलाई",
      width: "150px",
      selector: (row) => row.ToGrade,
    },
    {
      name: mode === "en" ? "From Branch" : "साखाबाट",
      width: "150px",
      selector: (row) => row.FromBranch,
    },
    {
      name: mode === "en" ? "To Branch" : "साखालाई",
      width: "150px",
      selector: (row) => row.ToBranch,
    },
    {
      name: mode === "en" ? "Status" : "स्थिति",
      // grow: 0,
      center: true,
      width: "200px",
      selector: (row) => {
        return (
          <>
            {row.IsApproved === "P" ? (
              <div className="ln-verition d-flex">
                <button
                  type="button"
                  className="ln-verition btn btn-sm d-flex pe-1"
                  // style={{ background: "var(--button-color)", color: "white" }}
                  onClick={() =>
                    handleApprove([row.ProTransID, row.IsApproved, row.UserID])
                  }
                >
                  <span
                    className=" badge rounded-pill bg-primary"
                    style={{ fontSize: "11px" }}
                  >
                    {mode === "en" ? "Approve" : "अनुमोदन"}
                  </span>
                </button>
                <button
                  type="button"
                  className="ln-verition btn btn-sm d-flex ps-1"
                  // style={{
                  //   background: "red",
                  //   color: "white",
                  //   marginLeft: "5px",
                  // }}
                  onClick={() =>
                    handleReject([row.ProTransID, row.IsApproved, row.UserID])
                  }
                >
                  <span
                    className=" badge rounded-pill bg-danger"
                    style={{ fontSize: "11px" }}
                  >
                    {mode === "en" ? "Reject" : "अस्वीकार"}
                  </span>
                </button>
              </div>
            ) : row.IsApproved === "Y" ? (
              <span
                className=" badge rounded-pill bg-success"
                style={{ fontSize: "11px" }}
              >
                {mode === "en" ? "Approved" : "स्वीकृत"}
              </span>
            ) : row.IsApproved === "N" ? (
              <span
                className=" badge rounded-pill bg-danger"
                style={{ fontSize: "11px" }}
              >
                {mode === "en" ? "Rejected" : "अस्वीकार गरियो"}
              </span>
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];

  //API to hit Resignation list
  const [dataList, setDataList] = useState([]);
  useEffect(() => {
    dataInfo();
  }, [typeOption]);

  const dataInfo = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      Flag: "S",
      UserID: User.UID.toString(),
      IsApproved: "Y",
      Type: typeOption,
      Type: "POST",
      FetchURL: `${appURL}api/admin/pro-transfer`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setDataList(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setLoading(false);
        setOriginalList([]);
        setDataList([]);
      }
    });
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["FullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setDataList(srchResult);
      } else {
        setDataList({});
      }
    } else {
      setDataList(originalList);
    }
  };

  const handleOption = (e) => {
    setTypeOption(e.target.value);
  };

  const dataWithIndex = dataList.map((d, i) => ({
    ...d,
    Index: i + 1,
    Status: d.IsActive === "I" ? "Inactive" : "Active",
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Designation", field: "Designation" },
    { header: "Department", field: "Department" },
    { header: "Sub Department", field: "SubDepartment" },
    { header: "Staff", field: "NoOfStaff" },
    { header: "Max. Sal", field: "MaxSal" },
    { header: "Min. Sal", field: "MinSal" },
    { header: "Status", field: "IsActive" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    generatePdf(userDetails, exportColumns, dataWithIndex);
  };

  const dataToExcel = dataList.map((d, i) => ({
    "S.N.": i + 1,
    Designation: d.Designation,
    Department: d.Department,
    "Sub Department": d.SubDepartment,
    Staff: d.NoOfStaff,
    "Max. Sal": d.MaxSal,
    "Min. Sal": d.MinSal,
    Status: d.IsActive === "I" ? "Inactive" : "Active",
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Designation");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper  ps-4 pe-4">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">
              {mode === "en" ? "Promotion" : "पदोन्नति"}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <span className="me-2 ">Expires in: {expires} </span>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>
        <>
          <div className="sec-dataTable">
            <div className="upper-dataTbl">
              <div className="uk-flex  uk-flex-wrap uk-flex-between uk-flex-middle mb-3">
                <div>
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en" ? "Select Type" : "टाइप चयन गर्नुहोस्"}
                    </label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="snotifiaction"
                      value={typeOption}
                      onChange={handleOption}
                      className="form-select form-select-sm"
                    >
                      <option
                        value="0"
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        {mode === "en" ? "Select Type" : "टाइप चयन गर्नुहोस्"}
                      </option>
                      {/* <option value="A">{mode === "en" ? "All" : "सबै"}</option> */}
                      <option value="P">
                        {mode === "en" ? "Promotion" : "पदोन्नति"}
                      </option>
                      <option value="T">
                        {mode === "en" ? "Transfer" : "स्थानान्तरण"}
                      </option>
                    </select>
                  </div>
                </div>
                <div className="">
                  <div className="d-flex uk-flex-wrap uk-flex-middle uk-flex-right">
                    <div className="btn-addlnote mt-3">
                      <button
                        type="button"
                        class="btn btn-sm"
                        style={{
                          background: "var(--button-color)",
                          color: "white",
                        }}
                        onClick={addLeaveNote}
                      >
                        <GoPlus color="#fff" className="me-1" />
                        {mode === "en" ? "Add New" : "नयाँ थप्नुहोस्"}
                      </button>
                    </div>

                    <div className="export-btn" uk-tooltip="Export Excel">
                      <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button me-1 ms-2 border-0"
                        table="table-to-xls"
                        filename="Promotion List"
                        sheet="tablexls"
                        buttonText={
                          <RiFileExcel2Fill size="1rem" color="#136438" />
                        }
                      />
                    </div>
                    {/* <div className="export-btn">
                      <button
                        uk-tooltip="Export Pdf"
                        // onClick={toPdf}
                        className="mx-1 border-0"
                      >
                        <BsFileEarmarkPdfFill size="1rem" color="#ff0002" />
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className=" w-100 mb-3 mt-3">
              <div className="d-flex uk-flex-middle justify-content-end">
                <div>
                  <form class="uk-search uk-search-default">
                    <AiOutlineSearch className="search-icon" />
                    <input
                      placeholder={mode === "en" ? "Search" : "खोजी गर्नुहोस्"}
                      ref={searchInput}
                      type="text"
                      className="form-control form-control-sm searchField"
                      onChange={searchHandler}
                    />
                  </form>
                </div>
              </div>
            </div>

            <div
              className="mt-3 mt-3 tableHeight show-scrollbar"
              style={{ maxHeight: "400px" }}
            >
              {loading ? (
                <Spinner />
              ) : (
                <>
                  {dataList.length > 0 ? (
                    <div className="uk-table  promotion__table">
                      <table id="table-to-xls">
                        <thead>
                          <tr>
                            <td
                              className="rdt_TableHeadRow tableHead text-center"
                              width="70px"
                              rowSpan="2"
                              style={{ verticalAlign: "middle" }}
                            >
                              {mode === "en" ? "S.N." : "क्र.सं"}
                            </td>
                            <td
                              className="rdt_TableHeadRow tableHead sticky-left"
                              minWidth="200px"
                              rowSpan="2"
                              style={{ verticalAlign: "middle" }}
                            >
                              {mode === "en" ? "FullName" : "पुरानाम"}
                            </td>

                            <td
                              className="rdt_TableHeadRow tableHead text-center"
                              colSpan="7"
                            >
                              {mode === "en" ? "From" : "देखि"}
                            </td>
                            <td
                              className="rdt_TableHeadRow tableHead text-center"
                              colSpan="7"
                            >
                              {mode === "en" ? "To" : "सम्म"}
                            </td>

                            <td
                              className="rdt_TableHeadRow tableHead text-center"
                              minWidth="200px"
                              rowSpan="2"
                              style={{ verticalAlign: "middle" }}
                            >
                              {mode === "en" ? "Status" : "स्थिति"}
                            </td>
                          </tr>

                          <tr>
                            <td
                              className="rdt_TableHeadRow tableHead text-center"
                              minWidth="100px"
                            >
                              {mode === "en" ? "Department" : "विभाग"}
                            </td>
                            <td
                              className="rdt_TableHeadRow tableHead text-center"
                              minWidth="100px"
                            >
                              {mode === "en" ? "Designation" : "पदनाम"}
                            </td>

                            <td
                              className="rdt_TableHeadRow tableHead text-center "
                              minWidth="100px"
                            >
                              {mode === "en" ? "Job Type" : "कामको प्रकार"}
                            </td>
                            <td
                              className="rdt_TableHeadRow tableHead text-center "
                              minWidth="100px"
                            >
                              {mode === "en" ? "Shift" : "शिफ्ट"}
                            </td>
                            <td
                              className="rdt_TableHeadRow tableHead text-center "
                              minWidth="100px"
                            >
                              {mode === "en" ? "Shift Type" : "शिफ्टको प्रकार"}
                            </td>
                            <td
                              className="rdt_TableHeadRow tableHead text-center "
                              minWidth="100px"
                            >
                              {mode === "en" ? "Grade" : "ग्रेड"}
                            </td>
                            <td
                              className="rdt_TableHeadRow tableHead text-center"
                              minWidth="100px"
                            >
                              {mode === "en" ? "Branch" : "साखा"}
                            </td>

                            <td
                              className="rdt_TableHeadRow tableHead text-center"
                              minWidth="100px"
                            >
                              {mode === "en" ? "Department" : "विभाग"}
                            </td>
                            <td
                              className="rdt_TableHeadRow tableHead text-center"
                              minWidth="100px"
                            >
                              {mode === "en" ? "Designation" : "पदनाम"}
                            </td>

                            <td
                              className="rdt_TableHeadRow tableHead text-center "
                              minWidth="100px"
                            >
                              {mode === "en" ? "Job Type" : "कामको प्रकार"}
                            </td>
                            <td
                              className="rdt_TableHeadRow tableHead text-center "
                              minWidth="100px"
                            >
                              {mode === "en" ? "Shift" : "शिफ्ट"}
                            </td>
                            <td
                              className="rdt_TableHeadRow tableHead  text-center"
                              minWidth="100px"
                            >
                              {mode === "en" ? "Shift Type" : "शिफ्टको प्रकार"}
                            </td>
                            <td
                              className="rdt_TableHeadRow tableHead text-center "
                              minWidth="100px"
                            >
                              {mode === "en" ? "Grade" : "ग्रेड"}
                            </td>
                            <td
                              className="rdt_TableHeadRow tableHead text-center "
                              minWidth="100px"
                            >
                              {mode === "en" ? "Branch" : "साखा"}
                            </td>
                          </tr>
                        </thead>

                        <tbody>
                          {dataList.map((item, i) => {
                            return (
                              <tr key={item.id}>
                                <td
                                  style={{ minWidth: "70px" }}
                                  className="rdt_TableCell tablecell text-center"
                                >
                                  {mode === "en"
                                    ? i + 1
                                    : englishToNepaliNumber(i + 1)}
                                </td>
                                <td
                                  style={{ minWidth: "200px" }}
                                  className="rdt_TableCell tablecell text-start sticky-left"
                                >
                                  {item.FullName}
                                </td>
                                <td
                                  style={{ minWidth: "100px" }}
                                  className="rdt_TableCell tablecell text-center"
                                >
                                  {item.FromDepart}
                                </td>
                                <td
                                  style={{ minWidth: "100px" }}
                                  className="rdt_TableCell tablecell text-center"
                                >
                                  {item.FromDesign}
                                </td>
                                <td
                                  style={{ minWidth: "100px" }}
                                  className="rdt_TableCell tablecell text-center"
                                >
                                  {item.FromJobType}
                                </td>
                                <td
                                  style={{ minWidth: "100px" }}
                                  className="rdt_TableCell tablecell text-center"
                                >
                                  {item.FromShift}
                                </td>
                                <td
                                  style={{ minWidth: "100px" }}
                                  className="rdt_TableCell tablecell text-center"
                                >
                                  {item.FromShitType}
                                </td>
                                <td
                                  style={{ minWidth: "100px" }}
                                  className="rdt_TableCell tablecell text-center"
                                >
                                  {item.FromGrade}
                                </td>
                                <td
                                  style={{ minWidth: "100px" }}
                                  className="rdt_TableCell tablecell text-center"
                                >
                                  {item.FromBranch}
                                </td>

                                <td
                                  style={{ minWidth: "100px" }}
                                  className="rdt_TableCell tablecell text-center"
                                >
                                  {item.ToDepart}
                                </td>
                                <td
                                  style={{ minWidth: "100px" }}
                                  className="rdt_TableCell tablecell text-center"
                                >
                                  {item.ToDesign}
                                </td>
                                <td
                                  style={{ minWidth: "100px" }}
                                  className="rdt_TableCell tablecell text-center"
                                >
                                  {item.ToJobType}
                                </td>
                                <td
                                  style={{ minWidth: "100px" }}
                                  className="rdt_TableCell tablecell text-center"
                                >
                                  {item.ToShift}
                                </td>
                                <td
                                  style={{ minWidth: "100px" }}
                                  className="rdt_TableCell tablecell text-center"
                                >
                                  {item.ToShiftType}
                                </td>
                                <td
                                  style={{ minWidth: "100px" }}
                                  className="rdt_TableCell tablecell text-center"
                                >
                                  {item.ToGrade}
                                </td>
                                <td
                                  style={{ minWidth: "100px" }}
                                  className="rdt_TableCell tablecell text-center"
                                >
                                  {item.ToBranch}
                                </td>

                                <td
                                  style={{ minWidth: "200px" }}
                                  className="rdt_TableCell tablecell text-center"
                                >
                                  {item.IsApproved === "P" ? (
                                    <div className="ln-verition d-flex">
                                      <button
                                        type="button"
                                        className="ln-verition btn btn-sm d-flex pe-1"
                                        // style={{ background: "var(--button-color)", color: "white" }}
                                        onClick={() =>
                                          handleApprove([
                                            item.ProTransID,
                                            item.IsApproved,
                                            item.UserID,
                                          ])
                                        }
                                      >
                                        <span
                                          className=" badge rounded-pill bg-primary"
                                          style={{ fontSize: "11px" }}
                                        >
                                          {mode === "en"
                                            ? "Approve"
                                            : "अनुमोदन"}
                                        </span>
                                      </button>
                                      <button
                                        type="button"
                                        className="ln-verition btn btn-sm d-flex ps-1"
                                        // style={{
                                        //   background: "red",
                                        //   color: "white",
                                        //   marginLeft: "5px",
                                        // }}
                                        onClick={() =>
                                          handleReject([
                                            item.ProTransID,
                                            item.IsApproved,
                                            item.UserID,
                                          ])
                                        }
                                      >
                                        <span
                                          className=" badge rounded-pill bg-danger"
                                          style={{ fontSize: "11px" }}
                                        >
                                          {mode === "en"
                                            ? "Reject"
                                            : "अस्वीकार"}
                                        </span>
                                      </button>
                                    </div>
                                  ) : item.IsApproved === "Y" ? (
                                    <span
                                      className=" badge rounded-pill bg-success"
                                      style={{ fontSize: "11px" }}
                                    >
                                      {mode === "en" ? "Approved" : "स्वीकृत"}
                                    </span>
                                  ) : item.IsApproved === "N" ? (
                                    <span
                                      className=" badge rounded-pill bg-danger"
                                      style={{ fontSize: "11px" }}
                                    >
                                      {mode === "en"
                                        ? "Rejected"
                                        : "अस्वीकार गरियो"}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>

                        {/* <DataTable
                        columns={columns}
                        data={dataList}
                        customStyles={customStyles}
                        pagination
                        paginationRowsPerPageOptions={[10, 20, 50, 100]} // Customizable options
                        fixedHeader
                        fixedHeaderScrollHeight="370px"
                        highlightOnHover
                        pointerOnHover
                        responsive
                        dense
                        striped
                      /> */}
                      </table>
                    </div>
                  ) : (
                    <p className="text-center">
                      {mode === "en" ? "No Data" : "डाटा उपलब्ध छैन"}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      </div>

      <PromotionPopup
        setPopup={setPopup}
        reload={reload}
        popup={popup}
        setReload={setReload}
        dataInfo={dataInfo}
        formValue={formValue}
        setFormValue={setFormValue}
        formError={formError}
        setFormError={setFormError}
        DFlag={DFlag}
        isApproved={isApproved}
        setIsApproved={setIsApproved}
      />

      {approvePopup.show && (
        <ApprovePop
          handleApproveTrue={handleApproveTrue}
          handleApproveFalse={handleApproveFalse}
          approveGmail={approveGmail}
          setApproveGmail={setApproveGmail}
        />
      )}

      {rejectPopup.show && (
        <RejectPop
          handleRejectTrue={handleRejectTrue}
          handleRejectFalse={handleRejectFalse}
          rejectGmail={rejectGmail}
          setRejectGmail={setRejectGmail}
        />
      )}

      {/* <viewPopup
        setViewPopup={setViewPopup}
        viewPopup={viewPopup}
        reload={reload}
        setReload={setReload}
        dataInfo={dataInfo}
        formValue={formValue}
        setFormValue={setFormValue}
        formError={formError}
        setFormError={setFormError}
        titleId={titleId}
      /> */}
    </>
  );
}
