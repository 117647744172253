import React, { useContext, useEffect, useState, useRef } from "react";
import "./attendanceReport.css";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import NepaliDate from "nepali-date-converter";
import UpperbarContext from "../../context/upperbar-context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../loading/spinner";
import DataTable from "react-data-table-component";
import AuthContext from "../../context/auth-context";
import AAddAttendance from "./addAttendance";
import { Fetchdata } from "../../hooks/getData";
import {
  GetCurrMonth,
  GetCurrYear,
  GetNepaliDate,
  GetToDate,
  GetTwelveHourFormatTime,
} from "../../hooks/dateConvertor";
import StaffContext from "../organization/staffState/StaffContext";
import { FaRegCalendarAlt } from "react-icons/fa";
import PopUp from "../../attendance/popUp";
import Toast from "../../Toast";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { GoPlus } from "react-icons/go";
import { AiOutlineSearch, AiTwotonePrinter } from "react-icons/ai";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { englishToNepaliNumber } from "nepali-number";
import comLogo from "../../../images/logo.png";
import { generatePdf } from "../../hooks/PdfExcel";
export default function AAttendanceReport() {
  const { User } = useContext(AuthContext);

  const initialValue = {
    deparment: "-1",
    subDepartment: "-1",
    date: GetToDate(),
    dateopt: "1",
    engDate: "",
  };
  const [formValues, setFormValues] = useState(initialValue);

  const [attPopup, setAttPopup] = useState(false);
  const [DFlag, setDFlag] = useState("N");
  const {
    fiscalYear,
    todayDate,
    customStyles,
    appURL,
    mode,
    userDetails,
    dateMode,
  } = useContext(UpperbarContext);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [depList, setDepList] = useState([]);

  const [subDepList, setSubDepList] = useState([]);
  const [attendanceList, setAttendanceList] = useState([]);
  const [attendanceDateList, setAttendanceDateList] = useState([]);

  const [approve, setApprove] = useState("");
  const [reject, setReject] = useState("");
  const [staffId, setStaffId] = useState(null);
  const [attenDate, setAttenDate] = useState("");
  const [sFlag, setSFlag] = useState("");
  const [popUp, setPopUp] = useState(false);

  const searchInput = useRef("");
  const [re, setRe] = useState();

  const [originalList, setOriginalList] = useState(null);

  const getNepaliDate = () => {
    var news = new Date();
    var newss = news.toLocaleDateString();
    const nepDate = new NepaliDate(new Date(newss));
    var cm = nepDate.getMonth() + 1;
    var cd = nepDate.getDate() + 1;
    //
    let strDate = nepDate.getYear() + "-" + cm + "-" + cd;
    //
    return strDate;
  };

  const date = [
    {
      date: "01",
    },
    {
      date: "02",
    },
    {
      date: "03",
    },
    {
      date: "04",
    },
    {
      date: "05",
    },
  ];

  const weekDay = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const columns = [
    // {
    //   name: mode === "en" ? "AttenID" : "क्र.सं",
    //   grow: 0,
    //   center: true,
    //   width: "100px",
    //   selector: (row) => row.AttenID,
    // },
    {
      name: mode === "en" ? "Date" : "मिति",
      grow: 2,
      width: "150px",
      selector: (row) => row.AttenDate,
    },
    // {
    //   name: mode === "en" ? "Day" : "दिन",
    //   grow: 2,
    //   width: "150px",
    //   selector: (row) => row.AttenDay,
    // },
    {
      name: mode === "en" ? "Check In" : "चेक इन",
      // center: true,
      width: "140px",
      selector: (row) => {
        if (row.LeaveType === "N") {
          if (row.CheckIn === "No Checkin" && row.CheckOut === "No Checkout") {
            return mode === "en" ? "No Check In" : "कोई चेक इन नहीं";
          } else if (
            row.CheckIn !== "No Checkin" &&
            row.CheckOut === "No Checkout"
          ) {
            return row.CheckIn;
          } else if (
            row.CheckIn !== "No Checkin" &&
            row.CheckOut !== "No Checkout"
          ) {
            return row.CheckIn;
          }
        } else if (row.LeaveType === "F") {
          return mode === "en" ? "No Check In" : "कोई चेक इन नहीं";
        } else if (row.LeaveType === "H") {
          return row.CheckIn
            ? row.CheckIn
            : mode === "en"
            ? "No Check In"
            : "कोई चेक इन नहीं";
        } else {
          return row.CheckIn
            ? row.CheckIn
            : mode === "en"
            ? "No Check In"
            : "कोई चेक इन नहीं";
        }
      },
    },
    {
      name: mode === "en" ? "Check Out" : "चेक आउट",
      // center: true,
      width: "140px",
      selector: (row) => {
        if (row.LeaveType === "N") {
          if (row.CheckIn === "No Checkin" && row.CheckOut === "No Checkout") {
            return mode === "en" ? "No Check Out" : "चेक आउट नहीं";
          } else if (
            row.CheckIn !== "No Checkin" &&
            row.CheckOut === "No Checkout"
          ) {
            return mode === "en" ? "No Check Out" : "चेक आउट नहीं";
          } else if (
            row.CheckIn !== "No Checkin" &&
            row.CheckOut !== "No Checkout"
          ) {
            return row.CheckOut;
          }
        } else if (row.LeaveType === "F") {
          return mode === "en" ? "No Check Out" : "चेक आउट नहीं";
        } else if (row.LeaveType === "H") {
          return row.CheckOut
            ? row.CheckOut
            : mode === "en"
            ? "No Check Out"
            : "चेक आउट नहीं";
        } else {
          return row.CheckOut
            ? row.CheckOut
            : mode === "en"
            ? "No Check Out"
            : "चेक आउट नहीं";
        }
      },
    },
    {
      name: mode === "en" ? "Day Type" : "दिनको प्रकार",
      // grow: 0,
      // center: true,
      width: "140px",
      selector: (row) => {
        if (row.LeaveType === "N") {
          if (row.CheckIn === "No Checkin" && row.CheckOut === "No Checkout") {
            return mode === "en" ? "Absent" : "अनुपस्थित";
          } else if (
            row.CheckIn !== "No Checkin" &&
            row.CheckOut === "No Checkout"
          ) {
            return mode === "en" ? "No Check Out" : "चेक आउट नहीं";
          } else if (
            row.CheckIn !== "No Checkin" &&
            row.CheckOut !== "No Checkout"
          ) {
            return row.DayType;
          }
        } else if (row.LeaveType === "F") {
          return mode === "en" ? "Full Day" : "पुरा दिन";
        } else if (row.LeaveType === "H") {
          return mode === "en" ? "Half Day" : "आधा दिन";
        } else {
          return row.DayType
            ? row.DayType
            : mode === "en"
            ? "No Check Out"
            : "चेक आउट नहीं";
        }
      },
    },

    {
      name: mode === "en" ? "Status" : "स्थिति",
      // grow: 0,
      center: true,
      width: "140px",
      selector: (row) => {
        if (row.LeaveType === "N") {
          if (row.CheckIn === "No Checkin" && row.CheckOut === "No Checkout") {
            return (
              <span
                className=" badge rounded-pill bg-danger"
                style={{ fontSize: "11px" }}
              >
                {mode === "en" ? "Absent" : "अनुपस्थित"}
              </span>
            );
          } else if (
            row.CheckIn !== "No Checkin" &&
            row.CheckOut === "No Checkout"
          ) {
            return (
              <span
                className=" badge rounded-pill bg-success"
                style={{ fontSize: "11px" }}
              >
                {mode === "en" ? "Present" : "उपस्थित"}
              </span>
            );
          } else if (
            row.CheckIn !== "No Checkin" &&
            row.CheckOut !== "No Checkout"
          ) {
            return (
              <span
                className=" badge rounded-pill bg-success"
                style={{ fontSize: "11px" }}
              >
                {mode === "en" ? "Present" : "उपस्थित"}
              </span>
            );
          }
        } else if (row.LeaveType === "F") {
          return (
            <span
              className=" badge rounded-pill bg-primary"
              style={{ fontSize: "11px" }}
            >
              {mode === "en" ? "On Leave" : "बिदामा"}
            </span>
          );
        } else if (row.LeaveType === "H") {
          return (
            <span
              className=" badge rounded-pill bg-primary"
              style={{ fontSize: "11px" }}
            >
              {mode === "en" ? "On Half Leave" : "आधा बिदामा"}
            </span>
          );
        } else if (row.CheckIn && row.CheckOut) {
          return (
            <span
              className=" badge rounded-pill bg-success"
              style={{ fontSize: "11px" }}
            >
              {mode === "en" ? "Present" : "उपस्थित"}
            </span>
          );
        } else {
          return (
            <span
              className=" badge rounded-pill bg-danger"
              style={{ fontSize: "11px" }}
            >
              {mode === "en" ? "Absent" : "अनुपस्थित"}
            </span>
          );
        }
      },
    },

    {
      name: mode === "en" ? "Remark" : "टिप्पणी",
      // center: true,
      width: "250px",
      selector: (row) => {
        if (row.LeaveType === "N") {
          if (row.CheckIn === "No Checkin" && row.CheckOut === "No Checkout") {
            return mode === "en" ? "Absent" : "अनुपस्थित";
          } else if (
            row.CheckIn !== "No Checkin" &&
            row.CheckOut === "No Checkout"
          ) {
            return row.InRemarks;
          } else if (
            row.CheckIn !== "No Checkin" &&
            row.CheckOut !== "No Checkout"
          ) {
            const remark = row.InRemarks + " | " + row.OutRemarks;
            return remark;
          }
        } else if (row.LeaveType === "F") {
          return mode === "en" ? "Absent" : "अनुपस्थित";
        } else if (row.LeaveType === "H") {
          const remark = row.InRemarks + " | " + row.OutRemarks;
          return remark;
        } else {
          const remark = row.InRemarks + " | " + row.OutRemarks;
          return remark;
        }
      },
    },
  ];

  const daycolumns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      // grow: 0,
      width: "70px",
      center: true,
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },
    // {
    //   name: "Date",
    //   // grow: 0,
    //   // width: "150px",
    //   center: true,
    //   selector: (row) => row.AttenDate,
    // },
    // {
    //   name: "Day",
    //   center: true,
    //   grow: 0,
    //   selector: (row) => {
    //     if (DFlag === "N") {
    //       const nDate = new NepaliDate(row.AttenDate).getBS();
    //       //
    //       const day = nDate.day;
    //       return weekDay[day];
    //     } else {
    //       const day = new Date(row.AttenDate).getDay();
    //
    //       return weekDay[day];
    //     }
    //   },
    // },
    {
      name: mode === "en" ? "Name" : "नाम",
      // grow: 2,
      // width: "150px",
      selector: (row) => row.StaffName,
    },
    {
      name: mode === "en" ? "Check In" : "चेक इन",
      // center: true,
      width: "140px",
      // selector: (row) => {
      //   if (row.LeaveType === "N") {
      //     if (row.CheckIn) {
      //       // Add this condition to check if CheckInValue has a value
      //       return row.CheckIn;
      //     } else {
      //       return mode === "en" ? "No Check In" : "कोई चेक इन नहीं";
      //     }
      //   } else if (row.LeaveType === "F") {
      //     if (row.CheckIn) {
      //       // Add this condition to check if CheckInValue has a value
      //       return row.CheckIn;
      //     } else {
      //       return mode === "en" ? "No Check In" : "कोई चेक इन नहीं";
      //     }
      //   } else if (row.LeaveType === "H") {
      //     if (row.CheckIn) {
      //       // Add this condition to check if CheckInValue has a value
      //       return row.CheckIn;
      //     } else {
      //       return mode === "en" ? "No Check In" : "कोई चेक इन नहीं";
      //     }
      //   }
      // },
      selector: (row) => {
        if (row.LeaveType === "N") {
          if (row.CheckIn === null && row.CheckOut === null) {
            return mode === "en" ? "No Check In" : "कोई चेक इन नहीं";
          } else if (row.CheckIn && row.CheckOut === null) {
            return row.CheckIn;
          } else if (row.CheckIn && row.CheckOut) {
            return row.CheckIn;
          }
        } else if (row.LeaveType === "F") {
          return mode === "en" ? "No Check In" : "कोई चेक इन नहीं";
        } else if (row.LeaveType === "H") {
          return row.CheckIn
            ? row.CheckIn
            : mode === "en"
            ? "No Check In"
            : "कोई चेक इन नहीं";
        } else if (row.CheckIn === null) {
          return mode === "en" ? "No Check In" : "कोई चेक इन नहीं";
        }
      },
    },
    {
      name: mode === "en" ? "Check Out" : "चेक आउट",
      // center: true,
      width: "140px",
      // selector: (row) => {
      //   if (row.LeaveType === "N") {
      //     if (row.CheckOut) {
      //       // Add this condition to check if CheckInValue has a value
      //       return row.CheckOut;
      //     } else {
      //       return mode === "en" ? "No Check Out" : "चेक आउट नहीं";
      //     }
      //   } else if (row.LeaveType === "F") {
      //     if (row.CheckOut) {
      //       // Add this condition to check if CheckInValue has a value
      //       return row.CheckOut;
      //     } else {
      //       return mode === "en" ? "No Check Out" : "चेक आउट नहीं";
      //     }
      //   } else if (row.LeaveType === "H") {
      //     if (row.CheckOut) {
      //       // Add this condition to check if CheckInValue has a value
      //       return row.CheckOut;
      //     } else {
      //       return mode === "en" ? "No Check Out" : "चेक आउट नहीं";
      //     }
      //   }
      // },
      selector: (row) => {
        if (row.LeaveType === "N") {
          if (row.CheckIn === null && row.CheckOut === null) {
            return mode === "en" ? "No Check Out" : "चेक आउट नहीं";
          } else if (row.CheckIn && row.CheckOut === null) {
            return mode === "en" ? "No Check Out" : "चेक आउट नहीं";
          } else if (row.CheckIn && row.CheckOut) {
            return row.CheckOut;
          }
        } else if (row.LeaveType === "F") {
          return mode === "en" ? "No Check Out" : "चेक आउट नहीं";
        } else if (row.LeaveType === "H") {
          return row.CheckOut
            ? row.CheckOut
            : mode === "en"
            ? "No Check Out"
            : "चेक आउट नहीं";
        } else if (row.CheckOut === null) {
          return mode === "en" ? "No Check Out" : "चेक आउट नहीं";
        }
      },
    },
    {
      name: mode === "en" ? "Day Type" : "दिनको प्रकार",
      // grow: 0,
      // center: true,
      width: "140px",
      // selector: (row) => {
      //   if (row.LeaveType === "N") {
      //     if (row.CheckIn === "No Checkin" && row.CheckOut === "No Checkout") {
      //       return mode === "en" ? "On Leave" : "बिदामा";
      //     }
      //   } else if (row.LeaveType === "F") {
      //     if (row.CheckIn == null && row.CheckOut == null) {
      //       return mode === "en" ? "On Leave" : "बिदामा";
      //     } else if (row.CheckIn === "No Checkin" && row.CheckOut === "No Checkout") {
      //       return mode === "en" ? "On Leave" : "बिदामा";
      //     }
      //   } else if (row.LeaveType === "H") {
      //     if (row.CheckIn && row.CheckOut) {
      //       return mode === "en" ? "Half Day" : "आधा दिन";
      //     }
      //   }
      // }
      selector: (row) => {
        if (row.LeaveType === "N") {
          if (row.CheckIn === null && row.CheckOut === null) {
            return mode === "en" ? "Absent" : "अनुपस्थित";
          } else if (row.CheckIn && row.CheckOut === null) {
            return mode === "en" ? "No Check Out" : "चेक आउट नहीं";
          } else if (row.CheckIn && row.CheckOut) {
            return row.DayType;
          }
        } else if (row.LeaveType === "F") {
          return mode === "en" ? "Full Day" : "पुरा दिन";
        } else if (row.LeaveType === "H") {
          return mode === "en" ? "Half Day" : "आधा दिन";
        }
      },
    },

    {
      name: mode === "en" ? "Status" : "स्थिति",
      // grow: 0,
      center: true,
      width: "140px",
      // selector: (row) => {
      //   if (row.LeaveType === "N") {
      //     if (row.CheckIn === "No Checkin" && row.CheckOut === "No Checkout") {
      //       return (
      //         <span
      //           className=" badge rounded-pill bg-danger"
      //           style={{ fontSize: "11px" }}
      //         >
      //           {mode === "en" ? "Absent" : "अनुपस्थित"}
      //         </span>
      //       );
      //     } else if (row.CheckIn === null && row.CheckOut === null) {
      //       return (
      //         <span
      //           className=" badge rounded-pill bg-danger"
      //           style={{ fontSize: "11px" }}
      //         >
      //           {mode === "en" ? "Absent" : "अनुपस्थित"}
      //         </span>
      //       );
      //     } else if (row.CheckIn !== "" && row.CheckOut === null) {
      //       return (
      //         <span
      //           className=" badge rounded-pill bg-success"
      //           style={{ fontSize: "11px" }}
      //         >
      //           {mode === "en" ? "Present" : "उपस्थित"}
      //         </span>
      //       );
      //     } else if (row.CheckIn === null && row.CheckOut !== "") {
      //       return (
      //         <span
      //           className=" badge rounded-pill bg-success"
      //           style={{ fontSize: "11px" }}
      //         >
      //           {mode === "en" ? "Present" : "उपस्थित"}
      //         </span>
      //       );
      //     } else if (row.CheckIn && row.CheckOut) {
      //       return (
      //         <span
      //           className=" badge rounded-pill bg-success"
      //           style={{ fontSize: "11px" }}
      //         >
      //           {mode === "en" ? "Present" : "उपस्थित"}
      //         </span>
      //       );
      //     }
      //   } else if (row.LeaveType === "F") {
      //     if (row.CheckIn === null && row.CheckOut === null) {
      //       return (
      //         <span
      //           className=" badge rounded-pill bg-danger"
      //           style={{ fontSize: "11px" }}
      //         >
      //           {mode === "en" ? "Absent" : "अनुपस्थित"}
      //         </span>
      //       );
      //     } else if (
      //       row.CheckIn === "No Checkin" &&
      //       row.CheckOut === "No Checkout"
      //     ) {
      //       return (
      //         <span
      //           className=" badge rounded-pill bg-danger"
      //           style={{ fontSize: "11px" }}
      //         >
      //           {mode === "en" ? "Absent" : "अनुपस्थित"}
      //         </span>
      //       );
      //     }
      //   } else if (row.LeaveType === "H") {
      //     if (row.CheckIn && row.CheckOut) {
      //       return (
      //         <span
      //           className=" badge rounded-pill bg-danger"
      //           style={{ fontSize: "11px" }}
      //         >
      //           {mode === "en" ? "Half Day" : "आधा दिन"}
      //         </span>
      //       );
      //     } else if (row.CheckIn !== "" && row.CheckOut === null) {
      //       return (
      //         <span
      //           className=" badge rounded-pill bg-danger"
      //           style={{ fontSize: "11px" }}
      //         >
      //           {mode === "en" ? "Half Day" : "आधा दिन"}
      //         </span>
      //       );
      //     } else if (row.CheckIn === null && row.CheckOut !== "") {
      //       return (
      //         <span
      //           className=" badge rounded-pill bg-danger"
      //           style={{ fontSize: "11px" }}
      //         >
      //           {mode === "en" ? "Half Day" : "आधा दिन"}
      //         </span>
      //       );
      //     }
      //   }
      // },
      selector: (row) => {
        if (row.LeaveType === "N") {
          if (row.CheckIn === null && row.CheckOut === null) {
            return (
              <span
                className=" badge rounded-pill bg-danger"
                style={{ fontSize: "11px" }}
              >
                {mode === "en" ? "Absent" : "अनुपस्थित"}
              </span>
            );
          } else if (row.CheckIn && row.CheckOut === null) {
            return (
              <span
                className=" badge rounded-pill bg-success"
                style={{ fontSize: "11px" }}
              >
                {mode === "en" ? "Present" : "उपस्थित"}
              </span>
            );
          } else if (row.CheckIn && row.CheckOut) {
            return (
              <span
                className=" badge rounded-pill bg-success"
                style={{ fontSize: "11px" }}
              >
                {mode === "en" ? "Present" : "उपस्थित"}
              </span>
            );
          }
        } else if (row.LeaveType === "F") {
          return (
            <span
              className=" badge rounded-pill bg-primary"
              style={{ fontSize: "11px" }}
            >
              {mode === "en" ? "On Leave" : "बिदामा"}
            </span>
          );
        } else if (row.LeaveType === "H") {
          return (
            <span
              className=" badge rounded-pill bg-primary"
              style={{ fontSize: "11px" }}
            >
              {mode === "en" ? "On Half Leave" : "आधा बिदामा"}
            </span>
          );
        }
      },
    },

    {
      name: mode === "en" ? "Remark" : "टिप्पणी",
      // center: true,
      width: "250px",
      // selector: (row) => {
      //   if (row.LeaveType === "N") {
      //     if (row.CheckIn === "No Checkin" && row.CheckOut === "No Checkout") {
      //       return mode === "en" ? "Absent" : "अनुपस्थित";
      //     } else if (row.CheckIn === null && row.CheckOut) {
      //       return row.InRemarks + "/ " + row.OutRemarks;
      //     } else if (row.CheckIn && row.CheckOut === null) {
      //       return row.InRemarks + "/ " + row.OutRemarks;
      //     } else if (row.CheckIn === null && row.CheckOut === null) {
      //       return mode === "en" ? "Absent" : "अनुपस्थित";
      //     } else if (row.CheckIn && row.CheckOut) {
      //       return mode === "en" ? "Present" : "उपस्थित";
      //     }
      //   } else if (row.LeaveType === "F") {
      //     if (row.CheckIn === "No Checkin" && row.CheckOut === "No Checkout") {
      //       return mode === "en" ? "Absent" : "अनुपस्थित";
      //     } else if (row.CheckIn === null && row.CheckOut === null) {
      //       return mode === "en" ? "Absent" : "अनुपस्थित";
      //     }
      //   } else if (row.LeaveType === "H") {
      //     if (row.CheckIn && row.CheckOut) {
      //       return mode === "en" ? "half leave" : "आधा बिदा";
      //     } else if (row.CheckIn === null && row.CheckOut) {
      //       return mode === "en" ? "half leave" : "आधा बिदा";
      //     } else if (row.CheckIn && row.CheckOut === null) {
      //       return mode === "en" ? "half leave" : "आधा बिदा";
      //     }
      //   }
      // },
      selector: (row) => {
        if (row.LeaveType === "N") {
          if (row.CheckIn === null && row.CheckOut === null) {
            return mode === "en" ? "Absent" : "अनुपस्थित";
          } else if (row.CheckIn && row.CheckOut === null) {
            return row.InRemarks;
          } else if (row.CheckIn && row.CheckOut) {
            const remark = row.InRemarks + " | " + row.OutRemarks;
            return remark;
          }
        } else if (row.LeaveType === "F") {
          return mode === "en" ? "Absent" : "अनुपस्थित";
        } else if (row.LeaveType === "H") {
          const remark = row.InRemarks + " | " + row.OutRemarks;
          return remark;
        }
      },
    },
    // {
    //   name: mode === "en" ? "Remark" : "टिप्पणी",
    //   // center: true,
    //   width: "250px",
    //   selector: (row) => {
    //     if (row.LeaveType === "H") {
    //       return mode === "en" ? "Half Day" : "आधा दिन";
    //     } else if (
    //       row.CheckIn === "No Check In" || "चेक इन छैन" &&
    //       row.CheckOut === "No Check Out" || "चेक आउट छैन"
    //     ) {
    //       if (row.LeaveID === "0") {
    //         return mode === "en" ? "On Leave" : "बिदामा";
    //       } else {
    //         return mode === "en" ? "Absent" : "अनुपस्थित";
    //       }
    //     } else if (
    //       row.CheckIn !== "No Check In" &&
    //       row.CheckOut === "No Check Out"
    //     ) {
    //       const remark = row.InRemarks + " | " + "No Check out";
    //       return remark;
    //     } else if (
    //       row.CheckIn === "No Check In" &&
    //       row.CheckOut !== "No Check Out"
    //     ) {
    //       const remark = row.InRemarks + " | " + "No Check out";
    //       return remark;
    //     } else {
    //       if (row.IsVerified === "Verified") {
    //         const remark = row.InRemarks + " | " + row.OutRemarks;
    //         return remark;
    //       } else if (row.IsVerified === "Unverified") {
    //         return (
    //           <div className="ln-verition d-flex">
    //             <button
    //               type="button"
    //               className="ln-verition btn btn-sm d-flex pe-1"
    //               // style={{
    //               //   background: "var(--button-color)",
    //               //   color: "white",
    //               // }}
    //               onClick={() =>
    //                 handleAttendance({ row, sFlag: "A", approve: "approve" })
    //               }
    //             >
    //               <span
    //                 className=" badge rounded-pill bg-primary"
    //                 style={{ fontSize: "11px" }}
    //               >
    //                 {mode === "en" ? "Approve" : "अनुमोदन"}
    //               </span>
    //             </button>

    //             <button
    //               type="button"
    //               className="ln-verition btn btn-sm d-flex ps-1"
    //               // style={{
    //               //   background: "red",
    //               //   color: "white",
    //               // }}
    //               onClick={() =>
    //                 handleAttendance({ row, sFlag: "R", reject: "reject" })
    //               }
    //             >
    //               <span
    //                 className=" badge rounded-pill bg-danger"
    //                 style={{ fontSize: "11px" }}
    //               >
    //                 {mode === "en" ? "Reject" : "अस्वीकार"}
    //               </span>
    //             </button>
    //           </div>
    //         );
    //       } else {
    //         return (
    //           <span
    //             className=" badge rounded-pill bg-danger"
    //             style={{ fontSize: "11px" }}
    //           >
    //             {mode === "en" ? "Rejected" : "अस्वीकार गरियो"}
    //           </span>
    //         );
    //       }
    //     }
    //   },
    // },

    // {
    //   name: "Action",
    //   center: true,
    //   width: "200px",
    //   selector: (row) => {
    //     const apiDate = row.AttenDate;
    //     let clipADate = apiDate.slice(8, 10);

    //     let clipTDate = todayDate.slice(8, 10);

    //     let showAction = false;
    //     if (clipADate === clipTDate) {
    //       showAction = true;
    //     }
    //     return (
    //       <>
    //         <div className="ln-verition d-flex">
    //           <button
    //             type="button"
    //             class="btn btn-sm "
    //             style={{
    //               background: "var(--button-color)",
    //               color: "white",
    //             }}
    //             // onClick={() => updateRequest(row)}
    //           >
    //             View
    //           </button>
    //           <span className="pt-1">|</span>

    //           {!showAction && (
    //             <button
    //               type="button"
    //               class="btn btn-sm "
    //               style={{
    //                 background: "var(--button-color)",
    //                 color: "white",
    //               }}
    //               onClick={() => ApproveAttendance(row)}
    //             >
    //               Approve
    //             </button>
    //           )}
    //         </div>
    //       </>
    //     );
    //   },
    // },
  ];

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === "deparment") {
      formValues.subDepartment = "-1";
    }
    setFormValues({ ...formValues, [name]: value });
  };

  const addAttendance = (e) => {
    setAttPopup(true);
    setRe(Math.random());
  };

  const handleDate = ({ bsDate }) => {
    let name = "date";
    setFormValues({ ...formValues, [name]: bsDate });
  };

  useEffect(() => {
    deptList();
  }, []);

  const deptList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      IsActive: "A",
      Type: "POST",
      FetchURL: `${appURL}api/admin/department`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setDepList(postResult);
      } else {
      }
    });
  };

  // useEffect(() => {
  //   if (formValues.deparment) {
  //     const dataForm = {
  //       ComID: User.CompanyId,
  //       StaffID: -1,
  //       DepartID: formValues.deparment,
  //       Flag: "S",
  //       Status: "1",
  //       BranchID: User.BranchId,
  //       FetchURL: `${appURL}api/admin/sub-department`,
  //       Type: "POST",
  //     };

  //     Fetchdata(dataForm).then(function (result) {
  //       if (result.StatusCode === 200) {
  //         const postResult = result.SubDepList;
  //         setSubDepList(postResult);
  //       } else {
  //         setSubDepList([]);
  //       }
  //     });
  //   }
  // }, [formValues.deparment]);

  useEffect(() => {
    if (formValues.deparment && formValues.date) {
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: -1,
        DepartmentID: formValues.deparment,
        Flag: "D",
        DFlag: "N",
        Value: dateMode === "np" ? formValues.date : formValues.engDate,
        FetchURL: `${appURL}api/admin/atten-report`,
        Type: "POST",
      };

      Fetchdata(dataForm)
        .then(function (result) {
          if (result.StatusCode === 200) {
            const postResult = result.Values;
            setAttendanceDateList(postResult);
            setOriginalList(postResult);
            setLoading(false);
          } else {
            setAttendanceDateList([]);
            setOriginalList([]);
            setLoading(false);
          }
        })
        .catch((err) => {
          setAttendanceDateList([]);
          setOriginalList([]);
          setLoading(false);
        });
    }
  }, [reload, formValues, formValues]);

  // const attendanceDateListLeave = attendanceDateList.map((data) => {
  //   const leavetype = data.LeaveType;
  //   if (leavetype === "H") {
  //     return {
  //       ...data,
  //       CheckIn: data.CheckIn !== null && data.CheckIn === "" ? data.CheckIn : mode === "en" ? "No Check In" : "चेक इन छैन",
  //       CheckOut: mode === "en" ? "Check Out" : "चेक आउट",
  //       DayType: mode === "en" ? "Half day" : "आधा दिन",
  //     };
  //   } else if (leavetype === "F") {
  //     return {
  //       ...data,
  //       CheckIn: mode === "en" ? "Check In" : "चेक इन",
  //       CheckOut: mode === "en" ? "Check Out" : "चेक आउट",
  //       DayType: "Full day",
  //     };
  //   } else if (leavetype === "N") {
  //     return {
  //       ...data,
  //       CheckIn: data.CheckIn !== null && data.CheckIn === "" ? data.CheckIn : mode === "en" ? "No Check In" : "चेक इन छैन",
  //       CheckOut: mode === "en" ? "No Check Out" : "चेक आउट छैन",
  //       DayType: mode === "en" ? "On Leave" : "बिदामा",
  //     };
  //   }
  //   return data;
  // });

  const handleAttendance = (data) => {
    setApprove(data.approve);
    setReject(data.reject);
    setSFlag(data.sFlag);
    setStaffId(data.row.StaffID);
    setAttenDate(data.row.AttenDate);
    setPopUp(true);
  };

  const atten = () => {
    const dataForm = {
      ComID: User.CompanyId,
      StaffID: staffId,
      UserID: User.UID,
      Flag: "ULS",
      AttenDate: attenDate,
      DFlag: "N",
      AttenStatus: sFlag === "A" ? "1" : "2",
      BranchID: User.BranchId,
      FiscalID: User.FiscalID,
      FetchURL: `${appURL}api/admin/atten`,
      Type: "POST",
    };

    Fetchdata(dataForm)
      .then(function (result) {
        if (result.StatusCode === 200) {
          setPopUp(false);
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
          setReload(!reload);
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      })
      .catch((err) => {
        toast.error("Error: " + err, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      });
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();

    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["FullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setAttendanceList(srchResult);
      } else {
        setAttendanceList({});
      }
    } else {
      setAttendanceList(originalList);
    }
  };

  // to get attendance list date wise
  const dataWithIndex = attendanceDateList.map((d, i) => ({
    ...d,
    Index: i + 1,
    Name: d.StaffName,
    CheckIn:
      d.LeaveType === "N"
        ? d.CheckIn === null && d.CheckOut === null
          ? "No Check In"
          : d.CheckIn && d.CheckOut === null
          ? d.CheckIn
          : d.CheckIn && d.CheckOut
          ? d.CheckIn
          : null
        : d.LeaveType === "F"
        ? "No Check In"
        : d.LeaveType === "H"
        ? d.CheckIn
          ? d.CheckIn
          : "No Check In"
        : "No Check In",

    CheckOut:
      d.LeaveType === "N"
        ? d.CheckIn === null && d.CheckOut === null
          ? "No Check Out"
          : d.CheckIn && d.CheckOut === null
          ? "No Check Out"
          : d.CheckIn && d.CheckOut
          ? d.CheckOut
          : null
        : d.LeaveType === "F"
        ? "No Check Out"
        : d.LeaveType === "H"
        ? d.CheckOut
          ? d.CheckOut
          : "No Check Out"
        : "No Check Out",

    DayType:
      d.LeaveType === "N"
        ? d.CheckIn === null && d.CheckOut === null
          ? "Absent"
          : d.CheckIn && d.CheckOut === null
          ? "No Check Out"
          : d.CheckIn && d.CheckOut
          ? d.DayType
          : null
        : d.LeaveType === "F"
        ? "Full Day"
        : d.LeaveType === "H"
        ? "Half Day"
        : null,

    Status:
      d.LeaveType === "N"
        ? d.CheckIn === null && d.CheckOut === null
          ? "Absent"
          : d.CheckIn && d.CheckOut === null
          ? "Present"
          : d.CheckIn && d.CheckOut
          ? "Present"
          : null
        : d.LeaveType === "F"
        ? "On Leave"
        : d.LeaveType === "H"
        ? "On Half Leave"
        : null,

    Remark:
      d.LeaveType === "N"
        ? d.CheckIn === null && d.CheckOut === null
          ? "Absent"
          : d.CheckIn && d.CheckOut === null
          ? d.InRemarks
          : d.CheckIn && d.CheckOut
          ? d.InRemarks + " | " + d.OutRemarks
          : null
        : d.LeaveType === "F"
        ? "Absent"
        : d.LeaveType === "H"
        ? d.InRemarks + " | " + d.OutRemarks
        : null,
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Name", field: "StaffName" },
    { header: "Check In", field: "CheckIn" },
    { header: "Check Out", field: "CheckOut" },
    { header: "Day Type", field: "DayType" },
    { header: "Status", field: "Status" },
    { header: "Remark", field: "Remark" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    generatePdf(userDetails, exportColumns, dataWithIndex);
  };

  const dataToExcel = attendanceDateList.map((d, i) => ({
    "S.N.": i + 1,
    Name: d.StaffName,
    "Check In":
      d.LeaveType === "N"
        ? d.CheckIn === null && d.CheckOut === null
          ? "No Check In"
          : d.CheckIn && d.CheckOut === null
          ? d.CheckIn
          : d.CheckIn && d.CheckOut
          ? d.CheckIn
          : null
        : d.LeaveType === "F"
        ? "No Check In"
        : d.LeaveType === "H"
        ? d.CheckIn
          ? d.CheckIn
          : "No Check In"
        : "No Check In",
    "Check Out":
      d.LeaveType === "N"
        ? d.CheckIn === null && d.CheckOut === null
          ? "No Check Out"
          : d.CheckIn && d.CheckOut === null
          ? "No Check Out"
          : d.CheckIn && d.CheckOut
          ? d.CheckOut
          : null
        : d.LeaveType === "F"
        ? "No Check Out"
        : d.LeaveType === "H"
        ? d.CheckOut
          ? d.CheckOut
          : "No Check Out"
        : "No Check Out",
    "Day Type":
      d.LeaveType === "N"
        ? d.CheckIn === null && d.CheckOut === null
          ? "Absent"
          : d.CheckIn && d.CheckOut === null
          ? "No Check Out"
          : d.CheckIn && d.CheckOut
          ? d.DayType
          : null
        : d.LeaveType === "F"
        ? "Full Day"
        : d.LeaveType === "H"
        ? "Half Day"
        : null,
    Status:
      d.LeaveType === "N"
        ? d.CheckIn === null && d.CheckOut === null
          ? "Absent"
          : d.CheckIn && d.CheckOut === null
          ? "Present"
          : d.CheckIn && d.CheckOut
          ? "Present"
          : null
        : d.LeaveType === "F"
        ? "On Leave"
        : d.LeaveType === "H"
        ? "On Half Leave"
        : null,
    Remarks:
      d.LeaveType === "N"
        ? d.CheckIn === null && d.CheckOut === null
          ? "Absent"
          : d.CheckIn && d.CheckOut === null
          ? d.InRemarks
          : d.CheckIn && d.CheckOut
          ? d.InRemarks + " | " + d.OutRemarks
          : null
        : d.LeaveType === "F"
        ? "Absent"
        : d.LeaveType === "H"
        ? d.InRemarks + " | " + d.OutRemarks
        : null,
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Attendance report");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  // ------  -----

  const [chooseStaff, setChooseStaff] = useState("-1");

  const handleStaff = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setChooseStaff(value);
  };

  const [chooseView, setChooseView] = useState("1");

  const handleView = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setChooseView(value);
  };

  const [sYear, setSYear] = useState(GetCurrYear());
  const [sMonth, setSMonth] = useState(GetCurrMonth());
  const cur_year = new Date().getFullYear();
  const eYears = generateArrayOfYears(cur_year);

  const nYears = generateArrayOfNepYears(cur_year);

  function generateArrayOfYears(cur_year) {
    var max = cur_year;
    var min = 2021;
    var years = [];

    for (var i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  }
  function getNepaliDates(date) {
    const nepDate = new NepaliDate().getBS();
    //
    return nepDate;
  }

  function generateArrayOfNepYears(cur_year) {
    let nCur_date = getNepaliDates(cur_year);
    var max = nCur_date.year;
    var min = 2078;
    var years = [];

    // for (var i = max; i >= min; i--) {
    //   years.push(i);
    // }
    for (var i = min; i <= max; i++) {
      years.push(i);
    }
    return years;
  }

  // api to get attendance month list

  useEffect(() => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      DepartmentID: formValues.deparment,
      Flag: "M",
      Value: `${sYear}/${sMonth}`,
      DFlag: "N",
      FetchURL: `${appURL}api/admin/atten-report`,
      Type: "POST",
    };

    Fetchdata(params)
      .then(function (result) {
        if (result.StatusCode === 200) {
          const postResult = result.Values ? result.Values : "";
          setAttendanceList(postResult);
          setOriginalList(postResult);
          setLoading(false);
        } else {
          setAttendanceList([]);
          setOriginalList([]);

          setLoading(false);
        }
      })
      .catch((err) => {
        setAttendanceList([]);
        setOriginalList([]);

        setLoading(false);
      });
  }, [reload, sYear, sMonth, formValues.deparment]);

  // -----new attendance list-----------

  const groupedByName = attendanceList.reduce((acc, curr) => {
    if (acc[curr.StaffName]) {
      acc[curr.StaffName].push(curr);
    } else {
      acc[curr.StaffName] = [curr];
    }
    return acc;
  }, {});

  const newAttendanceList = Object.keys(groupedByName).map((name) => ({
    StaffName: name,
    data: groupedByName[name].reverse(),
  }));

  // ------- list of unique date only -------
  const uniqueDate = [...new Set(attendanceList.map((item) => item.AttenDate))];

  const value = null;
  let uniqueDates = uniqueDate.reverse();
  uniqueDates = uniqueDates.filter(function (item) {
    return item !== value;
  });

  // --- to get first and last day of current month
  // Get the current Nepali date
  // function getDaysInNepaliMonth(year, month) {
  //   const daysInMonth = [
  //     32, // Baishakh
  //     32, // Jestha
  //     31, // Ashadh
  //     31, // Shrawan
  //     31, // Bhadra
  //     30, // Ashwin
  //     29, // Kartik
  //     29, // Mangsir
  //     30, // Poush
  //     30, // Magh
  //     30, // Falgun
  //     30, // Chaitra
  //   ];
  //   return (
  //     daysInMonth[month - 1] +
  //     (month === 8 && NepaliDate.isLeapYear(year) ? 1 : 0)
  //   );
  // }

  const daysInMonth = [
    32, // Baishakh
    32, // Jestha
    31, // Ashadh
    31, // Shrawan
    31, // Bhadra
    30, // Ashwin
    29, // Kartik
    29, // Mangsir
    30, // Poush
    30, // Magh
    30, // Falgun
    30, // Chaitra
  ];

  const Mdays = daysInMonth[sMonth - 1];

  // Get the current Nepali date
  // const currentDate = new NepaliDate();

  // Get the year and month of the current Nepali month
  // const year = sYear;
  // const month = sMonth - 1;

  // Get the number of days in the current Nepali month
  // const numDaysInMonth = getDaysInNepaliMonth(year, month);

  // Get the first date of the current Nepali month
  // const firstDate = new NepaliDate(year, month, 1).toJsDate();
  // const firstElement = GetNepaliDate(firstDate);

  // Get the last date of the current Nepali month
  // const lastDate = new NepaliDate(year, month, numDaysInMonth).toJsDate();
  // const lastElement = GetNepaliDate(lastDate);

  // ----new object to match AttenDate with unique date ----

  // create an object to store the attendance data
  const attendanceData = {};

  // loop through the data and populate the object
  newAttendanceList.forEach((item) => {
    attendanceData[item.StaffName] = {};

    uniqueDates.forEach((date) => {
      const data = item.data.find((d) => d.AttenDate === date);

      if (data) {
        attendanceData[item.StaffName][date] = {
          CheckIn: data.CheckIn,
          CheckOut: data.CheckOut,
          DayType: data.DayType,
          LeaveID: data.LeaveID,
          AttenID: data.AttenID,
        };
      } else {
        attendanceData[item.StaffName][date] = null;
      }
    });
  });

  //API to get staff list

  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    staffLst(formValues.deparment);
  }, [formValues.deparment]);

  const staffLst = () => {
    const BranchID = "" + User.BranchID;
    const departmentId = formValues.deparment;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID,
      Flag: "S",
      Designation: departmentId,
      BranchID: BranchID,
      WorkingStatus: "-1",
      IsActive: "-1",
      FiscalID: "1",
      Type: "POST",
      FetchURL: `${appURL}api/admin/user`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setStaffList(postResult);
      } else {
        setStaffList([]);
      }
    });
  };

  // --- to get list of individual staff
  const [staffDataList, setStaffDataList] = useState([]);

  useEffect(() => {
    getStaffList(chooseStaff);
  }, [chooseStaff, sYear, sMonth]);

  const getStaffList = () => {
    const dataNow = `${sYear}/${sMonth}`;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: chooseStaff,
      Flag: "M",
      DFlag: "N",
      value: dataNow,
      Type: "POST",
      FetchURL: `${appURL}api/atten-report`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setStaffDataList(postResult);
      } else {
        setStaffDataList([]);
      }
    });
  };

  // to get attendance list month wise
  const dataWithIndexMonthly = staffDataList.map((d, i) => ({
    ...d,
    Date: d.AttenDate,
    CheckIn:
      d.LeaveType === "N"
        ? d.CheckIn === "No Checkin" && d.CheckOut === "No Checkout"
          ? "No Check In"
          : d.CheckIn && d.CheckOut === "No Checkout"
          ? d.CheckIn
          : d.CheckIn && d.CheckOut
          ? d.CheckIn
          : null
        : d.LeaveType === "F"
        ? "No Check In"
        : d.LeaveType === "H"
        ? d.CheckIn
          ? d.CheckIn
          : "No Check In"
        : "No Check In",

    CheckOut:
      d.LeaveType === "N"
        ? d.CheckIn === "No Checkin" && d.CheckOut === "No Checkout"
          ? "No Check Out"
          : d.CheckIn && d.CheckOut === "No Checkout"
          ? "No Check Out"
          : d.CheckIn && d.CheckOut
          ? d.CheckOut
          : null
        : d.LeaveType === "F"
        ? "No Check Out"
        : d.LeaveType === "H"
        ? d.CheckOut
          ? d.CheckOut
          : "No Check Out"
        : "No Check Out",

    DayType:
      d.LeaveType === "N"
        ? d.CheckIn === "No Checkin" && d.CheckOut === "No Checkout"
          ? "Absent"
          : d.CheckIn && d.CheckOut === "No Checkout"
          ? "No Check Out"
          : d.CheckIn && d.CheckOut
          ? d.DayType
          : null
        : d.LeaveType === "F"
        ? "Full Day"
        : d.LeaveType === "H"
        ? "Half Day"
        : null,

    Status:
      d.LeaveType === "N"
        ? d.CheckIn === "No Checkin" && d.CheckOut === "No Checkout"
          ? "Absent"
          : d.CheckIn && d.CheckOut === "No Checkout"
          ? "Present"
          : d.CheckIn && d.CheckOut
          ? "Present"
          : null
        : d.LeaveType === "F"
        ? "On Leave"
        : d.LeaveType === "H"
        ? "On Half Leave"
        : null,

    Remark:
      d.LeaveType === "N"
        ? d.CheckIn === "No Checkin" && d.CheckOut === "No Checkout"
          ? "Absent"
          : d.CheckIn && d.CheckOut === "No Checkout"
          ? d.InRemarks
          : d.CheckIn && d.CheckOut
          ? d.InRemarks + " | " + d.OutRemarks
          : null
        : d.LeaveType === "F"
        ? "Absent"
        : d.LeaveType === "H"
        ? d.InRemarks + " | " + d.OutRemarks
        : null,
  }));

  const colsMonthly = [
    { header: "Date", field: "Date" },
    { header: "Check In", field: "CheckIn" },
    { header: "Check Out", field: "CheckOut" },
    { header: "Day Type", field: "DayType" },
    { header: "Status", field: "Status" },
    { header: "Remark", field: "Remark" },
  ];

  const exportColumnsMonthly = colsMonthly.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdfMonthly = () => {
    generatePdf(userDetails, exportColumnsMonthly, dataWithIndexMonthly);
  };

  const dataToExcelMonthly = staffDataList.map((d, i) => ({
    Date: d.AttenDate,
    "Check In":
      d.LeaveType === "N"
        ? d.CheckIn === "No Checkin" && d.CheckOut === "No Checkout"
          ? "No Check In"
          : d.CheckIn && d.CheckOut === "No Checkout"
          ? d.CheckIn
          : d.CheckIn && d.CheckOut
          ? d.CheckIn
          : null
        : d.LeaveType === "F"
        ? "No Check In"
        : d.LeaveType === "H"
        ? d.CheckIn
          ? d.CheckIn
          : "No Check In"
        : "No Check In",
    "Check Out":
      d.LeaveType === "N"
        ? d.CheckIn === "No Checkin" && d.CheckOut === "No Checkout"
          ? "No Check Out"
          : d.CheckIn && d.CheckOut === "No Checkout"
          ? "No Check Out"
          : d.CheckIn && d.CheckOut
          ? d.CheckOut
          : null
        : d.LeaveType === "F"
        ? "No Check Out"
        : d.LeaveType === "H"
        ? d.CheckOut
          ? d.CheckOut
          : "No Check Out"
        : "No Check Out",
    "Day Type":
      d.LeaveType === "N"
        ? d.CheckIn === "No Checkin" && d.CheckOut === "No Checkout"
          ? "Absent"
          : d.CheckIn && d.CheckOut === "No Checkout"
          ? "No Check Out"
          : d.CheckIn && d.CheckOut
          ? d.DayType
          : null
        : d.LeaveType === "F"
        ? "Full Day"
        : d.LeaveType === "H"
        ? "Half Day"
        : null,
    Status:
      d.LeaveType === "N"
        ? d.CheckIn === "No Checkin" && d.CheckOut === "No Checkout"
          ? "Absent"
          : d.CheckIn && d.CheckOut === "No Checkout"
          ? "Present"
          : d.CheckIn && d.CheckOut
          ? "Present"
          : null
        : d.LeaveType === "F"
        ? "On Leave"
        : d.LeaveType === "H"
        ? "On Half Leave"
        : null,
    Remarks:
      d.LeaveType === "N"
        ? d.CheckIn === "No Checkin" && d.CheckOut === "No Checkout"
          ? "Absent"
          : d.CheckIn && d.CheckOut === "No Checkout"
          ? d.InRemarks
          : d.CheckIn && d.CheckOut
          ? d.InRemarks + " | " + d.OutRemarks
          : null
        : d.LeaveType === "F"
        ? "Absent"
        : d.LeaveType === "H"
        ? d.InRemarks + " | " + d.OutRemarks
        : null,
  }));

  const toExcelMonthly = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcelMonthly);
      var wscols = [
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFileMonthly(excelBuffer, "Attendance report");
    });
  };

  const saveAsExcelFileMonthly = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const viewTwo = [];
  for (let i = 0; i < uniqueDates.length; i += 6) {
    viewTwo.push(uniqueDates.slice(i, i + 6));
  }

  return (
    <>
      {/* <Toast /> */}
      <div className="sumatten-wrapper ">
        {/* <div className="row mt-3">
          <div className="page-header">
            <div className="text-start  page-title">
              Attendance Report
            </div>
            <div className="page-date">
              <div className="sec-content">
                <FaRegCalendarAlt /> {todayDate} <span>|</span> Fiscal Year :{" "}
                {fiscalYear.StartDate}
                 
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div> */}
        <>
          {" "}
          <div>
            <div className="upper-dataTbl">
              <div className="uk-flex uk-flex-wrap uk-flex-between uk-flex-middle mb-3">
                <div className="uk-flex uk-flex-wrap">
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en"
                        ? "Select Date option"
                        : "मिति विकल्प चयन गर्नुहोस्"}
                    </label>
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example "
                      name="dateopt"
                      onChange={handleChange}
                      value={formValues.dateopt}
                    >
                      <option disabled value="" selected>
                        {mode === "en"
                          ? "Select Date option"
                          : "मिति विकल्प चयन गर्नुहोस्"}
                      </option>
                      <option value="1">
                        {mode === "en" ? "Date Wise" : "मिति अनुसार"}
                      </option>
                      <option value="2">
                        {mode === "en" ? "Month Wise" : "महिना अनुसार"}
                      </option>
                    </select>
                  </div>
                  {formValues.dateopt === "1" && (
                    <>
                      <div className="me-2 mt-3">
                        <label className="d-block text-start dark-gray mb-1">
                          {mode === "en"
                            ? "Select Department"
                            : "विभाग चयन गर्नुहोस्"}
                        </label>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example "
                          name="deparment"
                          onChange={handleChange}
                          value={formValues.deparment}
                        >
                          <option disabled value="" selected>
                            {mode === "en"
                              ? "Select Department"
                              : "विभाग चयन गर्नुहोस्"}
                          </option>
                          <option value="-1">
                            {" "}
                            {mode === "en" ? "Select All" : "सबै चयन गर्नुहोस्"}
                          </option>
                          {depList.map((list) => (
                            <>
                              <option
                                key={list.DepartmentID}
                                value={list.DepartmentID}
                              >
                                {list.Department}
                              </option>
                            </>
                          ))}
                        </select>
                      </div>

                      {/* {formValues.deparment !== "-1" && (
                        <div className="me-2 mt-3">
                          <label className="d-block text-start dark-gray mb-1">
                            {mode === "en"
                              ? "Select Sub-Department"
                              : "उप विभाग चयन गर्नुहोस्"}
                          </label>
                          <select
                            class="form-select form-select-sm"
                            aria-label="Default select example "
                            name="subDepartment"
                            onChange={handleChange}
                            value={formValues.subDepartment}
                          >
                            <option disabled value="" selected>
                              {mode === "en"
                                ? "Select Sub-Department"
                                : "उप विभाग चयन गर्नुहोस्"}
                            </option>
                            <option value="-1">
                              {" "}
                              {mode === "en"
                                ? "Select All"
                                : "सबै चयन गर्नुहोस्"}
                            </option>
                            {subDepList.map((list) => (
                              <>
                                <option
                                  key={list.SubDepartID}
                                  value={list.SubDepartID}
                                >
                                  {list.SubDepartName}
                                </option>
                              </>
                            ))}
                          </select>
                        </div>
                      )} */}

                      <div className="me-2 mt-3">
                        <label className="d-block text-start dark-gray mb-1">
                          {mode === "en" ? "Select Date" : "मिति चयन गर्नुहोस्"}
                        </label>
                        {dateMode === "np" ? (
                          <Calendar
                            className="form-control form-control-sm pt-0 pb-0 "
                            dateFormat="YYYY/MM/DD"
                            // defaultDate="2010/01/01"
                            theme="default"
                            language="en"
                            value={formValues.date}
                            onChange={handleDate}
                            name="date"
                            hideDefaultValue={true}
                            placeholder={formValues.date}
                            maxDate={getNepaliDate()}
                          />
                        ) : (
                          <input
                            type="date"
                            value={formValues.engDate}
                            placeholder="Select a Date"
                            className="form-control form-control-sm "
                            name="engDate"
                            onChange={handleChange}
                          />
                        )}
                      </div>
                    </>
                  )}

                  {formValues.dateopt === "2" && (
                    <>
                      <div className="me-2 mt-3">
                        <label className="d-block text-start dark-gray mb-1">
                          {mode === "en" ? "Select Year" : "वर्ष चयन गर्नुहोस्"}
                        </label>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example "
                          onChange={(e) => setSYear(e.target.value)}
                          value={sYear}
                        >
                          <option disabled value="" selected>
                            {mode === "en"
                              ? "Select Year"
                              : "वर्ष चयन गर्नुहोस्"}
                          </option>
                          {DFlag === "N"
                            ? nYears.map((list) => (
                                <>
                                  <option key={list} value={list}>
                                    {list}
                                  </option>
                                </>
                              ))
                            : eYears.map((list) => (
                                <>
                                  <option key={list} value={list}>
                                    {list}
                                  </option>
                                </>
                              ))}
                        </select>
                      </div>
                      <div className="me-2 mt-3">
                        <label className="d-block text-start dark-gray mb-1">
                          {mode === "en"
                            ? "Select Month"
                            : "महिना चयन गर्नुहोस्"}
                        </label>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example "
                          onChange={(e) => setSMonth(e.target.value)}
                          value={sMonth}
                        >
                          <option disabled value="" selected>
                            {mode === "en"
                              ? "Select Month"
                              : "महिना चयन गर्नुहोस्"}
                          </option>
                          {DFlag === "N" ? (
                            <>
                              <option value="01">
                                {mode === "en" ? "Baishakh" : "बैशाख"}
                              </option>
                              <option value="02">
                                {mode === "en" ? "Jestha" : "जेष्ठ"}
                              </option>
                              <option value="03">
                                {mode === "en" ? "Ashar" : "असार"}
                              </option>
                              <option value="04">
                                {mode === "en" ? "Shrawan" : "श्रावण"}
                              </option>
                              <option value="05">
                                {mode === "en" ? "Bhadra" : "भाद्र"}
                              </option>
                              <option value="06">
                                {mode === "en" ? "Asoj" : "असोज"}
                              </option>
                              <option value="07">
                                {mode === "en" ? "Kartik" : "कार्तिक"}
                              </option>
                              <option value="08">
                                {mode === "en" ? "Mangsir" : "मंसिर"}
                              </option>
                              <option value="09">
                                {mode === "en" ? "Poush" : "पौष"}
                              </option>
                              <option value="10">
                                {mode === "en" ? "Magh" : "माघ"}
                              </option>
                              <option value="11">
                                {mode === "en" ? "Falgun" : "फाल्गुन"}
                              </option>
                              <option value="12">
                                {mode === "en" ? "Chaitra" : "चैत्र"}
                              </option>
                            </>
                          ) : (
                            <>
                              <option value="01">January</option>
                              <option value="02">February</option>
                              <option value="03">March</option>
                              <option value="04">April</option>
                              <option value="05">May</option>
                              <option value="06">June</option>
                              <option value="07">July</option>
                              <option value="08">August</option>
                              <option value="09">September</option>
                              <option value="10">October</option>
                              <option value="11">November</option>
                              <option value="12">December</option>
                            </>
                          )}
                        </select>
                      </div>

                      <div className="me-2 mt-3">
                        <label className="d-block text-start dark-gray mb-1">
                          {mode === "en"
                            ? "Select Department"
                            : "विभाग चयन गर्नुहोस्"}
                        </label>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example "
                          name="deparment"
                          onChange={handleChange}
                          value={formValues.deparment}
                        >
                          <option disabled value="" selected>
                            {mode === "en"
                              ? "Select Department"
                              : "विभाग चयन गर्नुहोस्"}
                          </option>
                          <option value="-1">
                            {" "}
                            {mode === "en" ? "Select All" : "सबै चयन गर्नुहोस्"}
                          </option>
                          {depList.map((list) => (
                            <>
                              <option
                                key={list.DepartmentID}
                                value={list.DepartmentID}
                              >
                                {list.Department}
                              </option>
                            </>
                          ))}
                        </select>
                      </div>
                      <div className="me-2 mt-3">
                        <label className="d-block text-start dark-gray mb-1">
                          {mode === "en"
                            ? "Select Staff"
                            : "कर्मचारी चयन गर्नुहोस्"}
                        </label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="snotifiaction"
                          value={chooseStaff}
                          onChange={handleStaff}
                          className="form-select form-select-sm"
                        >
                          <option
                            value="0"
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            {mode === "en"
                              ? "Select Staff"
                              : "कर्मचारी चयन गर्नुहोस्"}
                          </option>
                          <option value="-1">
                            {mode === "en" ? "Select All" : "सबै चयन गर्नुहोस्"}
                          </option>
                          {staffList.map((item) => (
                            <option key={item.UserID} value={item.UID}>
                              {item.FirstName + " " + item.LastName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="me-2 mt-3">
                        <label className="d-block text-start dark-gray mb-1">
                          {mode === "en" ? "View" : "हेर्नुहोस्"}
                        </label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="snotifiaction"
                          value={chooseView}
                          onChange={handleView}
                          className="form-select form-select-sm"
                        >
                          <option
                            value="0"
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            {mode === "en" ? "View" : "हेर्नुहोस्"}
                          </option>

                          <option value="1">View 1</option>
                          <option value="2">View 2</option>
                        </select>
                      </div>
                    </>
                  )}
                </div>

                <div className="">
                  <div className="d-flex uk-flex-wrap uk-flex-middle uk-flex-right">
                    <div className="btn-addlnote mt-3">
                      <button
                        type="button"
                        class="btn btn-sm"
                        style={{
                          background: "var(--button-color)",
                          color: "white",
                        }}
                        onClick={addAttendance}
                      >
                        <GoPlus color="#fff" className="me-1" />

                        {mode === "en"
                          ? "Add Attendance"
                          : "उपस्थिति थप्नुहोस्"}
                      </button>
                    </div>

                    {formValues.dateopt === "2" && (
                      <>
                        {chooseStaff === "-1" && (
                          <div className="export-btn" uk-tooltip="Export Excel">
                            <ReactHTMLTableToExcel
                              id="test-table-xls-button"
                              className="download-table-xls-button me-1 ms-2 border-0"
                              table="table-to-xls"
                              filename="Attendance Report"
                              sheet="tablexls"
                              buttonText={
                                <RiFileExcel2Fill size="1rem" color="#136438" />
                              }
                            />
                          </div>
                        )}

                        {chooseStaff !== "-1" && (
                          <>
                            <div className="export-btn" uk-tooltip="Export Pdf">
                              <button
                                onClick={toPdfMonthly}
                                className="mx-1 border-0"
                              >
                                <BsFileEarmarkPdfFill
                                  size="1rem"
                                  color="#ff0002"
                                />
                              </button>
                            </div>
                            <div
                              className="export-btn"
                              uk-tooltip="Export Excel"
                            >
                              <button
                                onClick={toExcelMonthly}
                                className="mx-1 border-0"
                              >
                                <RiFileExcel2Fill size="1rem" color="#136438" />
                              </button>
                            </div>
                          </>
                        )}
                      </>
                    )}

                    {formValues.dateopt === "1" && (
                      <>
                        <div className="export-btn" uk-tooltip="Export Pdf">
                          <button onClick={toPdf} className="mx-1 border-0">
                            <BsFileEarmarkPdfFill size="1rem" color="#ff0002" />
                          </button>
                        </div>
                        <div className="export-btn" uk-tooltip="Export Excel">
                          <button onClick={toExcel} className="mx-1 border-0">
                            <RiFileExcel2Fill size="1rem" color="#136438" />
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <>
              {loading ? (
                <Spinner />
              ) : (
                <>
                  {" "}
                  {/* <DataTable
                        columns={columns}
                        data={attendanceList}
                        customStyles={customStyles}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight="335px"
                        highlightOnHover
                        pointerOnHover
                        progressPending={loading}
                        responsive
                        subHeader
                        dense
                        striped
                        subHeaderComponent={
                          <>
                            <div className=" w-100">
                              <div className="d-flex uk-flex-middle justify-content-end">
                                <div>
                                  <div class="uk-search uk-search-default">
                                    <AiOutlineSearch className="search-icon" />
                                    <input
                                      placeholder="Search"
                                      ref={searchInput}
                                      type="text"
                                      className="form-control form-control-sm searchField"
                                      onChange={searchHandler}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        }
                      /> */}
                  {/* <div className=" w-100 mt-3">
                    <div className="d-flex uk-flex-middle justify-content-end">
                      <div>
                        <div class="uk-search uk-search-default">
                          <AiOutlineSearch className="search-icon" />
                          <input
                            placeholder="Search"
                            ref={searchInput}
                            type="text"
                            className="form-control form-control-sm searchField"
                            onChange={searchHandler}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="attendace-table mt-3">
                    {formValues.dateopt === "2" && (
                      <>
                        {/* {staffDataList.AttenID === null && (
                          <span className="d-block text-center">
                            {" "}
                            {mode === "en" ? "No Data" : "डाटा उपलब्ध छैन"}
                          </span>
                        )} */}

                        {chooseStaff === "-1" && chooseView === "1" && (
                          <>
                            {Object.keys(attendanceData).length !== 0 ? (
                              <table
                                className="uk-table"
                                style={{ width: "max-content" }}
                                id="table-to-xls"
                              >
                                {/* head */}

                                <tr className="head-top">
                                  <td
                                    rowSpan="2"
                                    className="rdt_TableHeadRow tableHead text-center"
                                    width="70px"
                                    style={{
                                      verticalAlign: "middle",
                                      textAlign: "center",
                                      backgroundColor: "#004aad",
                                      color: "#fff",
                                    }}
                                  >
                                    {mode === "en" ? "S.N." : "क्र.सं"}
                                  </td>
                                  <td
                                    rowSpan="2"
                                    className="rdt_TableHeadRow tableHead text-start"
                                    width="200px"
                                    style={{
                                      verticalAlign: "middle",
                                      backgroundColor: "#004aad",
                                      color: "#fff",
                                    }}
                                  >
                                    {mode === "en" ? "Full Name" : "पुरा नाम"}
                                  </td>

                                  {uniqueDates.map((date) => {
                                    return (
                                      <td
                                        colSpan="2"
                                        className="rdt_TableHeadRow tableHead text-center"
                                        width="200px"
                                        style={{
                                          textAlign: "center",
                                          backgroundColor: "#004aad",
                                          color: "#fff",
                                        }}
                                      >
                                        {mode === "en"
                                          ? date
                                          : englishToNepaliNumber(date)}
                                      </td>
                                    );
                                  })}
                                </tr>

                                <tr className="head-bottom">
                                  {uniqueDates.map((props) => {
                                    return (
                                      <>
                                        <td
                                          className="rdt_TableHeadRow tableHead text-center"
                                          width="150px"
                                          style={{
                                            textAlign: "center",
                                            backgroundColor: "#004aad",
                                            color: "#fff",
                                          }}
                                        >
                                          {mode === "en"
                                            ? "Check In"
                                            : "चेक इन"}
                                        </td>
                                        <td
                                          className="rdt_TableHeadRow tableHead text-center"
                                          width="150px"
                                          style={{
                                            textAlign: "center",
                                            backgroundColor: "#004aad",
                                            color: "#fff",
                                          }}
                                        >
                                          {mode === "en"
                                            ? "Check Out"
                                            : "चेक आउट"}
                                        </td>
                                      </>
                                    );
                                  })}
                                </tr>

                                {/* head */}

                                {/* cell */}
                                {Object.keys(attendanceData).map(
                                  (fullName, i) => {
                                    return (
                                      <>
                                        <tr
                                        // style={{
                                        //   backgroundColor:
                                        //     (i + 1) % 2 === 0
                                        //       ? "#f8f8ff"
                                        //       : "transparent",
                                        // }}
                                        >
                                          <td
                                            // rowSpan="2"
                                            className="rdt_TableCell tablecell  text-center"
                                            style={{
                                              textAlign: "center",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {mode === "en"
                                              ? i + 1
                                              : englishToNepaliNumber(i + 1)}
                                          </td>

                                          <td
                                            // rowSpan="2"
                                            className="rdt_TableCell tablecell text-start"
                                          >
                                            {fullName}
                                          </td>
                                          {uniqueDates.map((date) => {
                                            const data =
                                              attendanceData[fullName][date];

                                            if (data) {
                                              return (
                                                <React.Fragment key={date}>
                                                  <td
                                                    className="rdt_TableCell tablecell text-center"
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {data.AttenID === null ? (
                                                      <span></span>
                                                    ) : data.LeaveID !== "0" ||
                                                      null ? (
                                                      <span>
                                                        {mode === "en"
                                                          ? "No Check In"
                                                          : "चेक इन छैन"}
                                                      </span>
                                                    ) : data.CheckIn ===
                                                      null ? (
                                                      <span>
                                                        {mode === "en"
                                                          ? "No Check In"
                                                          : "चेक इन छैन"}
                                                      </span>
                                                    ) : (
                                                      data.CheckIn
                                                    )}
                                                  </td>
                                                  <td
                                                    className="rdt_TableCell tablecell text-center"
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {data.AttenID === null ? (
                                                      <span></span>
                                                    ) : data.LeaveID !== "0" ||
                                                      null ? (
                                                      <span>
                                                        {mode === "en"
                                                          ? "No Check Out"
                                                          : "चेक आउट छैन"}
                                                      </span>
                                                    ) : data.CheckOut ===
                                                      null ? (
                                                      <span>
                                                        {mode === "en"
                                                          ? "No Check Out"
                                                          : "चेक आउट छैन"}
                                                      </span>
                                                    ) : (
                                                      data.CheckOut
                                                    )}
                                                  </td>
                                                </React.Fragment>
                                              );
                                            } else {
                                              return (
                                                <React.Fragment key={date}>
                                                  <td
                                                    className="rdt_TableCell tablecell text-center"
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {mode === "en"
                                                      ? "No Check In"
                                                      : "चेक इन छैन"}
                                                  </td>
                                                  <td
                                                    className="rdt_TableCell tablecell text-center"
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {mode === "en"
                                                      ? "No Check Out"
                                                      : "चेक आउट छैन"}
                                                  </td>
                                                </React.Fragment>
                                              );
                                            }
                                          })}
                                        </tr>

                                        {/* <tr>
                              {uniqueDates.map((date) => {
                                const data = attendanceData[fullName][date];

                                if (data) {
                                  return (
                                    <React.Fragment key={date}>
                                      <td
                                        colSpan="2"
                                        className="rdt_TableCell tablecell text-center"
                                      >
                                        <span className="mx-2">
                                          {data.AttenID === null ? (
                                            <span></span>
                                          ) : data.LeaveID !== null || 0 ? (
                                            <span
                                              className=" badge rounded-pill bg-primary"
                                              style={{ fontSize: "11px" }}
                                            >
                                              On Leave
                                            </span>
                                          ) : data.CheckIn === null &&
                                            data.CheckOut === null ? (
                                            <span
                                              className=" badge rounded-pill bg-danger"
                                              style={{ fontSize: "11px" }}
                                            >
                                              Absent
                                            </span>
                                          ) : data.DayType === "Absent" ? (
                                            <span
                                              className=" badge rounded-pill bg-danger"
                                              style={{ fontSize: "11px" }}
                                            >
                                              Absent
                                            </span>
                                          ) : data.CheckIn !== "" &&
                                            data.CheckOut !== "" ? (
                                            <span
                                              className=" badge rounded-pill bg-success"
                                              style={{ fontSize: "11px" }}
                                            >
                                              Present
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                        <span className="mx-2">
                                          {data.AttenID === null ? (
                                            <span></span>
                                          ) : data.LeaveID !== null || 0 ? (
                                            <span
                                              className=" badge rounded-pill bg-primary"
                                              style={{ fontSize: "11px" }}
                                            >
                                              On Leave
                                            </span>
                                          ) : data.CheckIn === null &&
                                            data.CheckOut === null ? (
                                            <span
                                              className=" badge rounded-pill bg-danger"
                                              style={{ fontSize: "11px" }}
                                            >
                                              Absent
                                            </span>
                                          ) : data.DayType === "Absent" ? (
                                            <span
                                              className=" badge rounded-pill bg-danger"
                                              style={{ fontSize: "11px" }}
                                            >
                                              Absent
                                            </span>
                                          ) : (
                                            <span
                                              className=" badge rounded-pill bg-primary"
                                              style={{ fontSize: "11px" }}
                                            >
                                              {data.DayType}
                                            </span>
                                          )}
                                        </span>
                                      </td>
                                    </React.Fragment>
                                  );
                                } else {
                                  return (
                                    <React.Fragment key={date}>
                                      <td
                                        className="rdt_TableCell tablecell text-center"
                                        colSpan="2"
                                      ></td>
                                    </React.Fragment>
                                  );
                                }
                              })}
                            </tr> */}
                                        {/* <tr>
                                  {data.map((props) => {
                                    const {
                                      CheckIn,
                                      CheckOut,
                                      LeaveID,
                                      DayType,
                                    } = props;
                                    return (
                                      <>
                                        <td
                                          colSpan="2"
                                          className="rdt_TableCell tablecell text-center"
                                          // width="100px"
                                        >
                                          <span className="mx-2">
                                            {
                                              // LeaveID !== 0 || null ? (
                                              //   <span
                                              //     className=" badge rounded-pill bg-primary"
                                              //     style={{ fontSize: "11px" }}
                                              //   >
                                              //     On Leave
                                              //   </span>
                                              // ) :
                                              CheckIn === null &&
                                              CheckOut === null ? (
                                                <span
                                                  className=" badge rounded-pill bg-danger"
                                                  style={{ fontSize: "11px" }}
                                                >
                                                  Absent
                                                </span>
                                              ) : DayType === "Absent" ? (
                                                <span
                                                  className=" badge rounded-pill bg-danger"
                                                  style={{ fontSize: "11px" }}
                                                >
                                                  Absent
                                                </span>
                                              ) : CheckIn !== "" &&
                                                CheckOut !== "" ? (
                                                <span
                                                  className=" badge rounded-pill bg-success"
                                                  style={{ fontSize: "11px" }}
                                                >
                                                  Present
                                                </span>
                                              ) : (
                                                ""
                                              )
                                            }
                                          </span>
                                          <span className="mx-2">
                                            {
                                              // LeaveID !== 0 || null ? (
                                              //   <span
                                              //     className=" badge rounded-pill bg-primary"
                                              //     style={{ fontSize: "11px" }}
                                              //   >
                                              //     On Leave
                                              //   </span>
                                              // ) :
                                              CheckIn === null &&
                                              CheckOut === null ? (
                                                <span
                                                  className=" badge rounded-pill bg-danger"
                                                  style={{ fontSize: "11px" }}
                                                >
                                                  Absent
                                                </span>
                                              ) : DayType === "Absent" ? (
                                                <span
                                                  className=" badge rounded-pill bg-danger"
                                                  style={{ fontSize: "11px" }}
                                                >
                                                  Absent
                                                </span>
                                              ) : (
                                                <span
                                                  className=" badge rounded-pill bg-primary"
                                                  style={{ fontSize: "11px" }}
                                                >
                                                  {DayType}
                                                </span>
                                              )
                                            }
                                          </span>
                                        </td>
                                      </>
                                    );
                                  })}
                                </tr> */}
                                      </>
                                    );
                                  }
                                )}

                                {/* cell */}
                              </table>
                            ) : (
                              <span className="d-block text-center">
                                {" "}
                                {mode === "en" ? "No Data" : "डाटा उपलब्ध छैन"}
                              </span>
                            )}
                          </>
                        )}

                        {chooseStaff !== "-1" && (
                          <DataTable
                            columns={columns}
                            data={staffDataList}
                            customStyles={customStyles}
                            pagination
                            paginationRowsPerPageOptions={[10, 20, 50, 100]}
                            fixedHeader
                            fixedHeaderScrollHeight="370px"
                            highlightOnHover
                            pointerOnHover
                            responsive
                            progressPending={loading}
                            dense
                            striped
                          />
                        )}

                        {chooseStaff === "-1" && chooseView === "2" && (
                          <>
                            {Object.keys(attendanceData).length !== 0 ? (
                              <table
                                className="uk-table new__table"
                                // style={{ width: "max-content" }}
                                id="table-to-xls"
                              >
                                {/* head */}

                                <tr className="head-top">
                                  <td
                                    className="rdt_TableHeadRow tableHead text-center"
                                    width="70px"
                                    style={{
                                      textAlign: "center",
                                      backgroundColor: "#004aad",
                                      color: "#fff",
                                    }}
                                  >
                                    {mode === "en" ? "S.N." : "क्र.सं"}
                                  </td>

                                  <td
                                    className="rdt_TableHeadRow tableHead text-center"
                                    colSpan={
                                      uniqueDates.length < 6
                                        ? uniqueDates.length
                                        : 6
                                    }
                                    style={{
                                      textAlign: "center",
                                      backgroundColor: "#004aad",
                                      color: "#fff",
                                    }}
                                  ></td>
                                </tr>

                                {/* head */}

                                {/* cell */}
                                {Object.keys(attendanceData).map(
                                  (fullName, i) => {
                                    return (
                                      <>
                                        <tr>
                                          <td
                                            rowSpan={viewTwo.length + 1}
                                            className="rdt_TableCell tablecell  text-center"
                                            style={{
                                              textAlign: "center",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {mode === "en"
                                              ? i + 1
                                              : englishToNepaliNumber(i + 1)}
                                          </td>

                                          <td
                                            className="rdt_TableCell tablecell text-center uk-text-bold"
                                            colSpan={
                                              uniqueDates.length < 6
                                                ? uniqueDates.length
                                                : 6
                                            }
                                            style={{
                                              textAlign: "center",
                                              backgroundColor: "#eee",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {fullName}
                                          </td>
                                        </tr>

                                        {viewTwo.map((row, rowIndex) => {
                                          return (
                                            <tr
                                              className="new__table__row"
                                              key={rowIndex}
                                            >
                                              {row.map((item, colIndex) => {
                                                const data =
                                                  attendanceData[fullName][
                                                    item
                                                  ];
                                                return (
                                                  <td
                                                    className="rdt_TableCell tablecell text-center"
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    <div className="table__date">
                                                      {mode === "en"
                                                        ? item
                                                        : englishToNepaliNumber(
                                                            item
                                                          )}
                                                    </div>
                                                    <div className="table__time">
                                                      {data ? (
                                                        <React.Fragment>
                                                          {data.AttenID ===
                                                          null ? (
                                                            <span></span>
                                                          ) : data.LeaveID !==
                                                              "0" || null ? (
                                                            <span>
                                                              {mode === "en"
                                                                ? "No Check In"
                                                                : "चेक इन छैन"}
                                                            </span>
                                                          ) : data.CheckIn ===
                                                            null ? (
                                                            <span>
                                                              {mode === "en"
                                                                ? "No Check In"
                                                                : "चेक इन छैन"}
                                                            </span>
                                                          ) : (
                                                            data.CheckIn
                                                          )}{" "}
                                                          -{" "}
                                                          {data.AttenID ===
                                                          null ? (
                                                            <span></span>
                                                          ) : data.LeaveID !==
                                                              "0" || null ? (
                                                            <span>
                                                              {mode === "en"
                                                                ? "No Check Out"
                                                                : "चेक आउट छैन"}
                                                            </span>
                                                          ) : data.CheckOut ===
                                                            null ? (
                                                            <span>
                                                              {mode === "en"
                                                                ? "No Check Out"
                                                                : "चेक आउट छैन"}
                                                            </span>
                                                          ) : (
                                                            data.CheckOut
                                                          )}
                                                        </React.Fragment>
                                                      ) : (
                                                        <React.Fragment>
                                                          {mode === "en"
                                                            ? "No Check In/Out"
                                                            : "चेक इन/आउट छैन"}
                                                        </React.Fragment>
                                                      )}
                                                    </div>
                                                  </td>
                                                );
                                              })}
                                              {/* {row.length < 6 &&
                                                Array.from({
                                                  length: 6 - row.length,
                                                }).map((_, colIndex) => (
                                                  <td
                                                    key={`empty-${colIndex}`}
                                                  ></td>
                                                ))} */}
                                            </tr>
                                          );
                                        })}
                                      </>
                                    );
                                  }
                                )}

                                {/* cell */}
                              </table>
                            ) : (
                              <span className="d-block text-center">
                                {" "}
                                {mode === "en" ? "No Data" : "डाटा उपलब्ध छैन"}
                              </span>
                            )}
                          </>
                        )}
                      </>
                    )}

                    {formValues.dateopt === "1" && (
                      <DataTable
                        columns={daycolumns}
                        data={attendanceDateList}
                        customStyles={customStyles}
                        pagination
                        paginationRowsPerPageOptions={[10, 20, 50, 100]} // Customizable options
                        fixedHeader
                        fixedHeaderScrollHeight="335px"
                        highlightOnHover
                        pointerOnHover
                        progressPending={loading}
                        responsive
                        dense
                        striped
                      />
                    )}
                  </div>
                </>
              )}
            </>
          </div>
        </>
      </div>

      <AAddAttendance
        setAttPopup={setAttPopup}
        attPopup={attPopup}
        DFlag={DFlag}
        reload={reload}
        setReload={setReload}
        re={re}
      />

      {popUp && (
        <PopUp
          approve={approve}
          reject={reject}
          atten={atten}
          setPopUp={setPopUp}
        />
      )}
    </>
  );
}
