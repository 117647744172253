import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "../../images/CloseIcon.svg";
import UpperbarContext from "../context/upperbar-context";
import "./ApprovePop.css";

export default function ApprovePop({
  handleApproveTrue,
  handleApproveFalse,
  approveGmail,
  setApproveGmail,
}) {
  const handleOnChange = (e) => {
    setApproveGmail(!approveGmail);
  };
  const { mode } = useContext(UpperbarContext);

  return (
    <>
      <div className="container confirm-popup">
        <div className="confirm-leave-popup-inner " style={{ height: "24vh" }}>
          <div className="popUpHeader ps-0 pe-0">
            <div className="popUpDelTitle">Elite Office</div>
            <div className="popUpClose">
              <img
                style={{ cursor: "pointer" }}
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={handleApproveFalse}
              />
            </div>
          </div>

          <div className="confirm-leave-body ps-4 mt-2">
            <div className="row text-start ">
              <div className="delText">
                {" "}
                {mode === "en"
                  ? "Are you sure want to Approve this?"
                  : "के तपाइँ यसलाई अनुमोदन गर्न निश्चित हुनुहुन्छ?"}
              </div>
            </div>
          </div>
          {/* <div className="checkbox-close">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck3"
              name="notWorking"
              onChange={handleOnChange}
              checked={approveGmail}
              style={{ marginLeft: "10px" }}
            />
            <label
              class="form-check-label"
              for="exampleCheck3"
              style={{ fontSize: "12px", paddingLeft: "5px" }}
            >
              {mode === "en" ? "Send Mail" : "मेल पठाउनुहोस्"}
            </label>
          </div> */}

          <div className="confirm-footer">
            <div className="row  mt-2 mb-2">
              <div>
                <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "#0079bf", color: "white" }}
                  onClick={handleApproveTrue}
                >
                  {mode === "en" ? "Okay" : "बुझाउनुहोस्"}
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  style={{ color: "white" }}
                  onClick={handleApproveFalse}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
