import React from "react";
import { useContext } from "react";
import AreaContext from "../../area/areaState/AreaContext";

const Basic = ({
  handleChange,
  formValues,
  formErrors,
  checkedActive,
  setCheckedActive,
  checkedVat,
  setCheckedVat,
  mode,
}) => {
  const { areaUnderList } = useContext(AreaContext);
  return (
    <>
      <div className="row text-start align-items-end">
        <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
            <label htmlFor="area" style={{ fontSize: "12px" }}>
              {mode === "en" ? "Select Area" : "क्षेत्र चयन गर्नुहोस्"}
            </label>
            <select
              class="form-select form-select-sm"
              aria-label="Default select example"
              name="area"
              id="area"
              value={formValues.area}
              onChange={handleChange}
            >
              <option disabled value="" selected>
                {mode === "en" ? "Select Area" : "क्षेत्र चयन गर्नुहोस्"}
              </option>
              {areaUnderList.map((props) => {
                return (
                  <option key={props.AreaID} value={props.AreaID}>
                    {props.Area}
                  </option>
                );
              })}
            </select>
            {/* {formErrors.pan && (
      <p className="errormsg">{formErrors.pan}</p>
    )} */}
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
            <div class="form-check" style={{ fontSize: "12px" }}>
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkedVat"
                name="checkedVat"
                checked={checkedVat}
                onChange={() => setCheckedVat(!checkedVat)}
              />
              <label
                style={{ fontSize: "12px", cursor: "pointer" }}
                class="form-check-label"
                htmlFor="checkedVat"
              >
                {mode === "en" ? "Reg. VAT" : "दर्ता भ्याट"}
              </label>
            </div>
          </div>
          <div className="form-group mt-0">
            <label htmlFor="pan" style={{ fontSize: "12px" }}>
              {mode === "en" ? "PAN No." : "प्यान नं."}
            </label>
            <input
              id="pan"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="pan"
              placeholder={mode === "en" ? "PAN No." : "प्यान नं."}
              value={formValues.pan}
              onChange={handleChange}
            />
            {/* {formErrors.commission && (
      <p className="errormsg">{formErrors.commission}</p>
    )} */}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="form-group">
          <label htmlFor="description" style={{ fontSize: "12px" }}>
            {mode === "en" ? "Description" : "विवरण"}
          </label>
          <textarea
            id="description"
            value={formValues.description}
            onChange={handleChange}
            style={{ fontSize: "13px" }}
            class="form-control ps-2"
            name="description"
            rows="7"
            cols="12"
            placeholder={mode === "en" ? "Description" : "विवरण"}
          ></textarea>
        </div>
      </div>

      <div className="row text-start uk-flex uk-flex-wrap">
        <div className="form-group me-3" style={{ width: "max-content" }}>
          <div class="form-check" style={{ fontSize: "12px" }}>
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="checkedActive"
              name="checkedActive"
              checked={checkedActive}
              onChange={() => setCheckedActive(!checkedActive)}
            />
            <label
              style={{ fontSize: "12px", cursor: "pointer" }}
              class="form-check-label"
              htmlFor="checkedActive"
            >
              {mode === "en" ? "Active" : "सक्रिय"}
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default Basic;
