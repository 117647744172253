import React, { useContext, useEffect } from "react";
import CloseIcon from "../../images/CloseIcon.svg";
import $ from "jquery";
import UpperbarContext from "../context/upperbar-context";
import { ShowImgPreview } from "../hooks/imagePreview";
import Comment from "./Comment";
import { useState } from "react";
const ViewPop = ({ viewPop, setViewPop, viewList }) => {
  const { mode } = useContext(UpperbarContext);
  const closePopUp = (e) => {
    setViewPop(false);
    $(".viewHelpdeskPopBg").fadeOut(500);
    $(".viewHelpdeskPop").slideUp(500);
  };

  useEffect(() => {
    if (viewPop) {
      $(".viewHelpdeskPopBg").fadeIn(500);
      $(".viewHelpdeskPop").slideDown(500);
    }
  }, [viewPop]);

  const [activeTab, setActiveTab] = useState({
    tab1: true,
    tab2: false,
  });
  const handleTab1 = () => {
    setActiveTab({
      tab1: true,
      tab2: false,
    });
  };
  const handleTab2 = () => {
    setActiveTab({
      tab1: false,
      tab2: true,
    });
  };
  const data = [
    {
      id: 1,
      title: mode === "en" ? "Ticket No" : "शीर्षक",
      desc: viewList.TicketNo,
    },
    {
      id: 2,
      title: mode === "en" ? "Category" : "श्रेणी",
      desc: viewList.Category,
    },
    {
      id: 3,
      title: mode === "en" ? "Subject" : "विषय",
      desc: viewList.Subject,
    },
    {
      id: 4,
      title: mode === "en" ? "Message" : "सन्देश",
      desc: viewList.Description,
    },
    {
      id: 5,
      title: mode === "en" ? "Priority" : "प्राथमिकता",
      desc:
        viewList.Priority === "M"
          ? mode === "en"
            ? "Medium"
            : "मध्यम"
          : viewList.Priority === "H"
          ? mode === "en"
            ? "High"
            : "उच्च"
          : viewList.Priority === "L"
          ? mode === "en"
            ? "Low"
            : "कम"
          : mode === "en"
          ? "Urgent"
          : "अत्यावश्यक",
    },
    {
      id: 6,
      title: mode === "en" ? "Assigned To" : "खटाइएका",
      desc: viewList.AssignedTo,
    },
    {
      id: 7,
      title: mode === "en" ? "Status" : "स्थिति",
      desc:
        viewList.Status === "P"
          ? mode === "en"
            ? "Pending"
            : "विचाराधीन"
          : viewList.Status === "A"
          ? mode === "en"
            ? "Approved"
            : "स्वीकृत"
          : viewList.Status === "R"
          ? mode === "en"
            ? "Rejected"
            : "अस्वीकार"
          : viewList.Status,
    },
    {
      id: 8,
      title:
        mode === "en" ? "Last Updated Date" : "अन्तिम अद्यावधिक गरिएको मिति",
      desc: viewList.LastUpdateDate,
    },
  ];
  const initialValue = {
    comment: "",
  };
  const [formValue, setFormValue] = useState(initialValue);
  const [formError, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const validate = (values) => {
    const errors = {};
    if (!values.comment) {
      errors.fullname = mode === "en" ? "Required" : "आवश्यक छ";
    }

    return errors;
  };
  const formSubmit = (e) => {
    e.preventDefault();
    setFormError(validate(formValue));
    setIsSubmit(true);
  };

  const [imgPrv, setImgPrv] = useState(false);
  const [imagePre, setImagePre] = useState("");

  return (
    <>
      <div className="container newpopup-bg viewHelpdeskPopBg">
        <div className="newpopup viewHelpdeskPop">
          <div className="newpopup-head ps-0 pe-0">
            <div className="popUpTitle">
              {mode === "en" ? "View" : "हेर्नुहोस्"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div
            className="newpopup-body ps-3 pe-3"
            style={{ paddingBottom: "1rem", minHeight: "70vh" }}
          >
            <div className="tabview comment__tab">
              <div className="insidePopup mt-3 attRoute">
                <ul>
                  <li
                    className={activeTab.tab1 === true ? "active" : ""}
                    onClick={handleTab1}
                  >
                    {mode === "en" ? "Ticket" : "टिकट"}
                  </li>
                  <li
                    className={activeTab.tab2 === true ? "active" : ""}
                    onClick={handleTab2}
                  >
                    {mode === "en" ? "Comment" : "टिप्पणी"}
                  </li>
                </ul>
              </div>
            </div>

            <div className="">
              {activeTab.tab1 && (
                <>
                  <div className="content-section main-content">
                    <div className="view__table">
                      {data.map((props) => {
                        const { id, title, desc } = props;
                        return (
                          <div key={id}>
                            <h4 className="the__title">{title}</h4>
                            <p className="the__content">{desc}</p>
                          </div>
                        );
                      })}
                    </div>
                    {viewList.Attachment && (
                      <div className="mt-4 view__table__img">
                        <img
                          src={viewList.Attachment}
                          alt="Helpdesk image"
                          onClick={() => {
                            setImagePre(viewList.Attachment);
                            setImgPrv(true);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
              {activeTab.tab2 && (
                <Comment
                  initialValue={initialValue}
                  formValue={formValue}
                  setFormValue={setFormValue}
                  formError={formError}
                  isSubmit={isSubmit}
                  setIsSubmit={setIsSubmit}
                  viewList={viewList}
                />
              )}
            </div>
          </div>

          <div className="new-footer">
            <div className="row  mt-1 mb-1">
              <div>
                {activeTab.tab2 && (
                  <button
                    type="button"
                    class="btn btn-sm me-2"
                    style={{
                      background: "var(--button-color)",
                      color: "white",
                    }}
                    onClick={formSubmit}
                  >
                    {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
                  </button>
                )}
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {imgPrv &&
        ShowImgPreview({
          img: imagePre,
          setTrigger: setImgPrv,
        })}
    </>
  );
};

export default ViewPop;
