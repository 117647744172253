import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "../../../../images/CloseIcon.svg";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import $ from "jquery";
import Toast from "../../../Toast";
import UpperbarContext from "../../../context/upperbar-context";
import AuthContext from "../../../context/auth-context";
import EditBasic from "./editTab/EditBasic";
import EditAddressContact from "./editTab/EditAddressContact";
import EditCreditLimit from "./editTab/EditCreditLimit";
import EditBankGuareente from "./editTab/EditBankGuareente";
import EditDocument from "./editTab/EditDocument";
import { capitalizeFirstLetter } from "../../../hooks/dateConvertor";

export default function EditLedgerPopup({
  editPopup,
  setEditPopup,
  perEditSubmit,
  setPerEditSubmit,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
  editData,
  DFlag,
  isUploaded,
  setIsUploaded,
  typeFile,
  setTypeFile,
  files,
  setFile,
  checkedVat,
  setCheckedVat,
  checkedBankCash,
  setCheckedBankCash,
  checkedActive,
  setCheckedActive,
  checkedInv,
  setCheckedInv,
  checkedBill,
  setCheckedBill,
  re,
  agentUnderList,
  areaUnderList,
  ledgerUnderList,
}) {
  const { appURL, sidePanelBg, mode } = useContext(UpperbarContext);
  const { User } = useContext(AuthContext);

  const [activeTab, setActiveTab] = useState({
    tab1: true,
    tab2: false,
    tab3: false,
    tab4: false,
    tab5: false,
  });
  const handleTab1 = () => {
    setActiveTab({
      tab1: true,
      tab2: false,
      tab3: false,
      tab4: false,
      tab5: false,
    });
  };
  const handleTab2 = () => {
    setActiveTab({
      tab1: false,
      tab2: true,
      tab3: false,
      tab4: false,
      tab5: false,
    });
  };
  const handleTab3 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: true,
      tab4: false,
      tab5: false,
    });
  };
  const handleTab4 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: false,
      tab4: true,
      tab5: false,
    });
  };
  const handleTab5 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: false,
      tab4: false,
      tab5: true,
    });
  };

  const closePopUp = (e) => {
    setEditPopup(false);
    $(".editLedgerPopBg").fadeOut(300);
    $(".editLedgerPop").slideUp(500);
    setPerEditSubmit(false);
    setFormErrors({});
    setActiveTab({
      tab1: true,
      tab2: false,
      tab3: false,
      tab4: false,
      tab5: false,
    });
  };

  useEffect(() => {
    if (editPopup) {
      $(".editLedgerPopBg").fadeIn(500);
      $(".editLedgerPop").slideDown(500);
    }
  }, [editPopup]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: capitalizeFirstLetter(value) });
  };
  const handleStartDate = ({ bsDate }) => {
    let name = "startDate";
    setFormValues({ ...formValues, [name]: bsDate });
  };
  const handleExpiryDate = ({ bsDate }) => {
    let name = "expiryDate";
    setFormValues({ ...formValues, [name]: bsDate });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.ledgerName) {
      errors.ledgerName = mode === "en" ? "Required" : "आवश्यक छ";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setPerEditSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && perEditSubmit) {
      editData();
      setPerEditSubmit(false);
      setActiveTab({
        tab1: true,
        tab2: false,
        tab3: false,
        tab4: false,
        tab5: false,
      });
    }
  }, [formErrors]);

  return (
    <>
      {/* <Toast /> */}
      <div className="container popup-wrapper editLedgerPopBg">
        <div className="popup-inner editLedgerPop">
          <div className="popUpHeader ps-0 pe-0" style={sidePanelBg}>
            <div className="popUpTitle">
              {mode === "en" ? "Edit Ledger " : "लेजर सम्पादन थप्नुहोस्"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div className="popUpBody ps-3 pe-3">
            <div className="form-padding">
              <div className="row text-start">
                <div className="form-group">
                  <label htmlFor="ledger" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Ledger Name" : "खाताको नाम"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="ledger"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="ledgerName"
                    placeholder={mode === "en" ? "Ledger Name" : "खाताको नाम"}
                    value={formValues.ledgerName}
                    onChange={handleChange}
                  />
                  {formErrors.ledgerName && (
                    <p className="errormsg">{formErrors.ledgerName}</p>
                  )}
                </div>
              </div>

              <div className="row text-start ">
                <div className="form-group">
                  <label htmlFor="alias" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Alias" : "उपनाम"}
                  </label>
                  <input
                    id="alias"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="alias"
                    placeholder={mode === "en" ? "Alias" : "उपनाम"}
                    value={formValues.alias}
                    onChange={handleChange}
                  />
                  {/* {formErrors.alias && (
                  <p className="errormsg">{formErrors.alias}</p>
                )} */}
                </div>
              </div>

              {/* <div className="row text-start">
                <div className="form-group">
                  <label htmlFor="under" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Under" : "अन्तर्गत"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    name="under"
                    id="under"
                    value={formValues.under}
                    onChange={handleChange}
                  >
                    <option disabled value="" selected>
                      {mode === "en" ? "Under" : "अन्तर्गत"}
                    </option>
                    <option value="0">
                      {" "}
                      {mode === "en" ? "Primary" : "प्राथमिक"}
                    </option>
                    {ledgerUnderList.map((props) => {
                      return (
                        <option key={props.LedgerID} value={props.LedgerID}>
                          {props.Ledger}
                        </option>
                      );
                    })}
                  </select>
                
                </div>
              </div> */}

              <div className="row text-start">
                <ul className="tab nav-tabs">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab.tab1 === true ? "active" : ""
                      }`}
                      onClick={handleTab1}
                    >
                      {mode === "en" ? "Basic" : "आधारभूत"}
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab.tab2 === true ? "active" : ""
                      }`}
                      onClick={handleTab2}
                    >
                      {mode === "en" ? "Address & Contact" : "ठेगाना र सम्पर्क"}
                    </a>
                  </li> */}
                  {/* <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab.tab3 === true ? "active" : ""
                      }`}
                      onClick={handleTab3}
                    >
                      {mode === "en" ? "Credit Limit" : "उधारोको सिमा"}
                    </a>
                  </li> */}
                  {/* <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab.tab4 === true ? "active" : ""
                      }`}
                      onClick={handleTab4}
                    >
                      {mode === "en" ? "Bank Guareente" : "बैंक ग्यारेन्टी"}
                    </a>
                  </li> */}
                  {/* <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab.tab5 === true ? "active" : ""
                      }`}
                      onClick={handleTab5}
                    >
                      {mode === "en" ? "Document" : "कागजात"}
                    </a>
                  </li> */}
                </ul>
              </div>

              {activeTab.tab1 && (
                <EditBasic
                  formValues={formValues}
                  handleChange={handleChange}
                  formErrors={formErrors}
                  checkedVat={checkedVat}
                  setCheckedVat={setCheckedVat}
                  checkedBankCash={checkedBankCash}
                  setCheckedBankCash={setCheckedBankCash}
                  checkedActive={checkedActive}
                  setCheckedActive={setCheckedActive}
                  checkedInv={checkedInv}
                  setCheckedInv={setCheckedInv}
                  checkedBill={checkedBill}
                  setCheckedBill={setCheckedBill}
                  agentUnderList={agentUnderList}
                  areaUnderList={areaUnderList}
                  mode={mode}
                />
              )}
              {activeTab.tab2 && (
                <EditAddressContact
                  formValues={formValues}
                  handleChange={handleChange}
                  formErrors={formErrors}
                  mode={mode}
                />
              )}
              {activeTab.tab3 && (
                <EditCreditLimit
                  formValues={formValues}
                  handleChange={handleChange}
                  formErrors={formErrors}
                  mode={mode}
                />
              )}
              {activeTab.tab4 && (
                <EditBankGuareente
                  formValues={formValues}
                  handleChange={handleChange}
                  formErrors={formErrors}
                  handleStartDate={handleStartDate}
                  handleExpiryDate={handleExpiryDate}
                  DFlag={DFlag}
                  re={re}
                  mode={mode}
                />
              )}
              {activeTab.tab5 && (
                <EditDocument
                  formValues={formValues}
                  handleChange={handleChange}
                  formErrors={formErrors}
                  isUploaded={isUploaded}
                  setIsUploaded={setIsUploaded}
                  typeFile={typeFile}
                  setTypeFile={setTypeFile}
                  files={files}
                  setFile={setFile}
                  mode={mode}
                />
              )}
            </div>
          </div>

          <div className="ln-popUpFooter">
            <div className="row  mt-1 mb-1">
              <div>
                <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={handleSubmit}
                >
                  {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
