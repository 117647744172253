import React, { useContext } from "react";
import CloseIcon from "../../images/CloseIcon.svg";
import "../../components/attendance/DeletePop.css";
import UpperbarContext from "../context/upperbar-context";

export default function DeleteNotification({ deleteNotice, setConfirmPopup }) {
  const { mode } = useContext(UpperbarContext);
  return (
    <>
      <div className="container confirm-popup">
        <div className="confirm-popup-inner ">
          <div className="popUpHeader">
            <div className="popUpDelTitle">Elite Office</div>
            <div className="popUpClose p-0">
              <img
                style={{ cursor: "pointer" }}
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={() => setConfirmPopup(false)}
              />
            </div>
          </div>

          <div className="confirm-body ps-4 ">
            <div className="row text-start ">
              <div className="delText">
                {mode === "en"
                  ? "Are you sure you want to delete ?"
                  : " के तपाइँ मेटाउन चाहनुहुन्छ"}
              </div>
            </div>
          </div>

          <div className="confirm-footer">
            <div className="row  mt-2 mb-2">
              <div>
                <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "#0079bf", color: "white" }}
                  onClick={deleteNotice}
                >
                  {mode === "en" ? "Okay" : "बुझाउनुहोस्"}
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger "
                  style={{ color: "white" }}
                  onClick={() => setConfirmPopup(false)}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
