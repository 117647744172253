import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import AuthContext from "../../../context/auth-context";
import UpperbarContext from "../../../context/upperbar-context";
import Toast from "../../../Toast";
import "../../entry/voucher/voucher.css";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import { Fetchdata } from "../../../hooks/getData";
import Spinner from "../../../loading/spinner";
import {
  GetNepaliDate,
  GetCurrYear,
  GetCurrMonth,
  GetEnglishDate,
} from "../../../hooks/dateConvertor";
import NepaliDate from "nepali-date-converter";
import { englishToNepaliNumber } from "nepali-number";

export default function Addition() {
  const { User } = useContext(AuthContext);

  //   const searchInput = useRef("");
  const { fiscalYear, todayDate, appURL, userDetails, mode } =
    useContext(UpperbarContext);

  const [DFlag, setDflag] = useState("N");
  const [dateData, setDateData] = useState("F");
  const [ledgerData, setLedgerData] = useState("-1");
  const [loading, setLoading] = useState(true);

  const cur_year = new Date().getFullYear();
  const nYears = generateArrayOfNepYears(cur_year);

  function getNepaliDate(date) {
    const nepDate = new NepaliDate().getBS();
    //
    return nepDate;
  }

  function generateArrayOfNepYears(cur_year) {
    let nCur_date = getNepaliDate(cur_year);
    var max = nCur_date.year;
    var min = 2079;
    var years = [];

    // for (var i = max; i >= min; i--) {
    //   years.push(i);
    // }
    for (var i = min; i <= max; i++) {
      years.push(i);
    }
    return years;
  }

  var date = new Date();
  const initalvalue = {
    fromDate: GetNepaliDate(date),
    toDate: GetNepaliDate(date),
    year: GetCurrYear(),
    month: GetCurrMonth(),
    frommonth: GetCurrMonth(),
    tomonth: GetCurrMonth(),
  };
  const [inputValues, setInputValues] = useState(initalvalue);

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setDateData(value);
  };

  const handleOnChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleFromDate = ({ adDate }) => {
    setInputValues({ ...inputValues, fromDate: adDate });
  };
  const handleToDate = ({ adDate }) => {
    setInputValues({ ...inputValues, toDate: adDate });
  };

  //  API to show list
  const [staffData, setStaffData] = useState({});
  useEffect(() => {
    staffLst();
  }, [dateData, inputValues]);

  const staffLst = () => {
    const UserID = "" + User.UID;
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;

    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      CID: "1",
      Flag: "AR",
      DtFlag: dateData === "F" ? "F" : "M",
      Values:
        dateData === "M"
          ? `${inputValues.year}/${inputValues.month}`
          : dateData === "F"
          ? ""
          : "",
      DateFrom:
        dateData === "F" ? `${inputValues.year}/${inputValues.frommonth}` : "",
      DateTo:
        dateData === "F" ? `${inputValues.year}/${inputValues.tomonth}` : "",
      BranchID: Branch,
      FiscalID: Fiscal,
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/payroll-report`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setLoading(false);

        const groupedByName = postResult.reduce((acc, curr) => {
          if (acc[curr.FullName]) {
            acc[curr.FullName].push(curr);
          } else {
            acc[curr.FullName] = [curr];
          }
          return acc;
        }, {});

        // create newStaffList from groupedByName
        const newStaffList = Object.keys(groupedByName).map((name) => {
          const designation = groupedByName[name][0].Designation; // assuming all objects with the same name have the same designation
          return {
            FullName: name,
            Designation: designation,
            data: groupedByName[name],
          };
        });

        const uniqueHead = [...new Set(postResult.map((item) => item.Head))];

        // create staffData object
        const newData = {};
        newStaffList.forEach((item) => {
          newData[item.FullName] = {};
          newData[item.FullName]["Designation"] = item.data[0].Designation;

          uniqueHead.forEach((head) => {
            const data = item.data.find((d) => d.Head === head);

            if (data) {
              newData[item.FullName][head] = {
                Amount: data.Amount,
                StaffID: data.StaffID,
                HeadID: data.HeadID,
              };
            } else {
              newData[item.FullName][head] = "null";
            }
          });
        });

        setStaffData(newData);
      } else {
        setStaffData({});
        setLoading(false);
      }
    });
  };

  const allStaffData = Object.values(staffData)
    .flatMap((staff) => Object.entries(staff))
    .filter(([key, value]) => key !== "Designation" && value !== "null")
    .map(([key, value]) => value);

  let totalAmt = allStaffData.reduce(function (prev, current) {
    return prev + +current.Amount;
  }, 0);
  return (
    <>
      {/* <Toast /> */}

      <>
        <div className="upper-dataTbl">
          <div className="uk-flex uk-flex-wrap uk-flex-between uk-flex-middle mb-3">
            <div className="uk-flex uk-flex-wrap me-5">
              <div className="me-2 mt-3">
                <label className="d-block text-start dark-gray mb-1">
                  {mode === "en" ? "Select Option" : "विकल्प चयन गर्नुहोस्"}
                </label>
                <select
                  style={{ fontSize: "11px" }}
                  name="ledger"
                  value={dateData}
                  onChange={handleChange}
                  className="form-select form-select-sm"
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    {mode === "en" ? "Select Option" : "विकल्प चयन गर्नुहोस्"}
                  </option>

                  <option value="F">
                    {mode === "en" ? "Month From & To" : "महिना देखि र सम्म"}
                  </option>

                  <option value="M">
                    {mode === "en"
                      ? "Select Month Wise"
                      : "महिना चयन गर्नुहोस्"}
                  </option>
                </select>
              </div>

              {dateData === "F" && (
                <>
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en" ? "Select Year" : "वर्ष चयन गर्नुहोस्"}
                    </label>
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example "
                      onChange={handleOnChange}
                      name="year"
                      value={inputValues.year}
                    >
                      <option disabled value="" selected>
                        {mode === "en" ? "Select Year" : "वर्ष चयन गर्नुहोस्"}
                      </option>
                      {nYears.map((list) => (
                        <>
                          <option key={list} value={list}>
                            {list}
                          </option>
                        </>
                      ))}
                    </select>
                  </div>
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en"
                        ? "Select From Month"
                        : "महिना देखि नेपाली चयन गर्नुहोस्"}
                    </label>

                    {/* <Calendar
                      // id="eventDate"
                      className="form-control form-control-sm pt-0 pb-0 "
                      dateFormat="YYYY/MM/DD"
                      // defaultDate="2010/01/01"
                      theme="default"
                      language="en"
                      // value={holidayFormValue.eventDate}
                      onChange={handleFromDate}
                      name="fromDate"

                      // hideDefaultValue={true}
                      // placeholder={"Select Follow Date"}
                    /> */}

                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example "
                      onChange={handleOnChange}
                      name="frommonth"
                      value={inputValues.frommonth}
                    >
                      <option disabled value="" selected>
                        {mode === "en" ? "Select Month" : "महिना चयन गर्नुहोस्"}
                      </option>
                      <option value="01">
                        {mode === "en" ? "Baishakh" : "बैशाख"}
                      </option>
                      <option value="02">
                        {mode === "en" ? "Jestha" : "जेष्ठ"}
                      </option>
                      <option value="03">
                        {mode === "en" ? "Ashar" : "असार"}
                      </option>
                      <option value="04">
                        {mode === "en" ? "Shrawan" : "श्रावण"}
                      </option>
                      <option value="05">
                        {mode === "en" ? "Bhadra" : "भाद्र"}
                      </option>
                      <option value="06">
                        {mode === "en" ? "Asoj" : "असोज"}
                      </option>
                      <option value="07">
                        {mode === "en" ? "Kartik" : "कार्तिक"}
                      </option>
                      <option value="08">
                        {mode === "en" ? "Mangsir" : "मंसिर"}
                      </option>
                      <option value="09">
                        {mode === "en" ? "Poush" : "पौष"}
                      </option>
                      <option value="10">
                        {mode === "en" ? "Magh" : "माघ"}
                      </option>
                      <option value="11">
                        {mode === "en" ? "Falgun" : "फाल्गुन"}
                      </option>
                      <option value="12">
                        {mode === "en" ? "Chaitra" : "चैत्र"}
                      </option>
                    </select>
                  </div>
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en"
                        ? "Select To Month"
                        : "महिना सम्म नेपाली चयन गर्नुहोस्"}
                    </label>

                    {/* <Calendar
                      // id="eventDate"
                      className="form-control form-control-sm pt-0 pb-0 "
                      dateFormat="YYYY/MM/DD"
                      // defaultDate="2010/01/01"
                      theme="default"
                      language="en"
                      // value={holidayFormValue.eventDate}
                      onChange={handleToDate}
                      name="toDate"

                      // hideDefaultValue={true}
                      // placeholder={"Select Follow Date"}
                    /> */}
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example "
                      onChange={handleOnChange}
                      name="tomonth"
                      value={inputValues.tomonth}
                    >
                      <option disabled value="" selected>
                        {mode === "en" ? "Select Month" : "महिना चयन गर्नुहोस्"}
                      </option>
                      <option value="01">
                        {mode === "en" ? "Baishakh" : "बैशाख"}
                      </option>
                      <option value="02">
                        {mode === "en" ? "Jestha" : "जेष्ठ"}
                      </option>
                      <option value="03">
                        {mode === "en" ? "Ashar" : "असार"}
                      </option>
                      <option value="04">
                        {mode === "en" ? "Shrawan" : "श्रावण"}
                      </option>
                      <option value="05">
                        {mode === "en" ? "Bhadra" : "भाद्र"}
                      </option>
                      <option value="06">
                        {mode === "en" ? "Asoj" : "असोज"}
                      </option>
                      <option value="07">
                        {mode === "en" ? "Kartik" : "कार्तिक"}
                      </option>
                      <option value="08">
                        {mode === "en" ? "Mangsir" : "मंसिर"}
                      </option>
                      <option value="09">
                        {mode === "en" ? "Poush" : "पौष"}
                      </option>
                      <option value="10">
                        {mode === "en" ? "Magh" : "माघ"}
                      </option>
                      <option value="11">
                        {mode === "en" ? "Falgun" : "फाल्गुन"}
                      </option>
                      <option value="12">
                        {mode === "en" ? "Chaitra" : "चैत्र"}
                      </option>
                    </select>
                  </div>
                </>
              )}

              {dateData === "M" && (
                <>
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en" ? "Select Year" : "वर्ष चयन गर्नुहोस्"}
                    </label>
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example "
                      onChange={handleOnChange}
                      name="year"
                      value={inputValues.year}
                    >
                      <option disabled value="" selected>
                        {mode === "en" ? "Select Year" : "वर्ष चयन गर्नुहोस्"}
                      </option>
                      {nYears.map((list) => (
                        <>
                          <option key={list} value={list}>
                            {list}
                          </option>
                        </>
                      ))}
                    </select>
                  </div>

                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en" ? "Select Month" : "महिना चयन गर्नुहोस्"}
                    </label>
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example "
                      onChange={handleOnChange}
                      name="month"
                      value={inputValues.month}
                    >
                      <option disabled value="" selected>
                        {mode === "en" ? "Select Month" : "महिना चयन गर्नुहोस्"}
                      </option>
                      <option value="01">
                        {mode === "en" ? "Baishakh" : "बैशाख"}
                      </option>
                      <option value="02">
                        {mode === "en" ? "Jestha" : "जेष्ठ"}
                      </option>
                      <option value="03">
                        {mode === "en" ? "Ashar" : "असार"}
                      </option>
                      <option value="04">
                        {mode === "en" ? "Shrawan" : "श्रावण"}
                      </option>
                      <option value="05">
                        {mode === "en" ? "Bhadra" : "भाद्र"}
                      </option>
                      <option value="06">
                        {mode === "en" ? "Asoj" : "असोज"}
                      </option>
                      <option value="07">
                        {mode === "en" ? "Kartik" : "कार्तिक"}
                      </option>
                      <option value="08">
                        {mode === "en" ? "Mangsir" : "मंसिर"}
                      </option>
                      <option value="09">
                        {mode === "en" ? "Poush" : "पौष"}
                      </option>
                      <option value="10">
                        {mode === "en" ? "Magh" : "माघ"}
                      </option>
                      <option value="11">
                        {mode === "en" ? "Falgun" : "फाल्गुन"}
                      </option>
                      <option value="12">
                        {mode === "en" ? "Chaitra" : "चैत्र"}
                      </option>
                    </select>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="mt-3 mt-3 tableHeight">
          {loading ? (
            <Spinner />
          ) : (
            <>
              {Object.keys(staffData).length !== 0 ? (
                <table className="uk-table reportTable">
                  <thead>
                    <tr>
                      <td
                        className="rdt_TableHeadRow tableHead text-center"
                        width="70px"
                      >
                        {mode === "en" ? "S.N." : "क्र.सं"}
                      </td>
                      <td
                        className="rdt_TableHeadRow tableHead sticky-left"
                        width="200px"
                      >
                        {mode === "en" ? "Name" : "नाम"}
                      </td>
                      {Object.keys(staffData)
                        .reduce((acc, curr) => {
                          Object.keys(staffData[curr]).forEach((head) => {
                            if (!acc.includes(head)) {
                              acc.push(head);
                            }
                          });
                          return acc;
                        }, [])
                        .map((head) => (
                          <td className="rdt_TableHeadRow tableHead" key={head}>
                            {head}
                          </td>
                        ))}
                      <td
                        className="rdt_TableHeadRow tableHead  text-end sticky-right"
                        width="150px"
                      >
                        {mode === "en" ? "Total" : "कुल"}
                      </td>
                    </tr>
                  </thead>

                  <tbody>
                    {Object.keys(staffData).map((fullName, i) => {
                      let rowTotal = 0;
                      return (
                        <tr key={fullName}>
                          <td
                            style={{ minWidth: "70px" }}
                            className="rdt_TableCell tablecell text-center"
                          >
                            {mode === "en"
                              ? i + 1
                              : englishToNepaliNumber(i + 1)}
                          </td>

                          <td
                            style={{ minWidth: "200px" }}
                            className="rdt_TableCell sticky-left tablecell text-start"
                          >
                            {fullName}
                          </td>

                          <td
                            style={{ minWidth: "200px" }}
                            className="rdt_TableCell tablecell text-start"
                          >
                            {staffData[fullName].Designation}
                          </td>

                          {Object.keys(staffData[fullName]).map((head) => {
                            if (head === "Designation") {
                              return null;
                            }
                            const data = staffData[fullName][head];
                            if (data) {
                              rowTotal += isNaN(data.Amount)
                                ? 0.0
                                : parseInt(data.Amount);
                            }
                            const amt = isNaN(data.Amount)
                              ? 0.0
                              : parseInt(data.Amount);
                            return (
                              <td
                                style={{ minWidth: "150px" }}
                                className="rdt_TableCell tablecell voucherCell text-end"
                                key={head}
                              >
                                {data && amt.toFixed(2) ? amt.toFixed(2) : 0}
                              </td>
                            );
                          })}

                          <td
                            style={{
                              minWidth: "150px",
                            }}
                            className="rdt_TableCell tablecell voucherCell reportTotal sticky-right text-end fw-bold"
                          >
                            {rowTotal.toFixed(2)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>

                  <tfoot style={{ position: "sticky", bottom: "-1px" }}>
                    <tr className="reportTotal">
                      <td
                        colSpan="3"
                        className="rdt_TableCell tablecell text-end fw-bold"
                      >
                        {mode === "en" ? "Total" : "कुल"}
                      </td>

                      {Object.keys(staffData)
                        .reduce((acc, curr) => {
                          Object.keys(staffData[curr]).forEach((head) => {
                            if (!acc.includes(head)) {
                              acc.push(head);
                            }
                          });
                          return acc;
                        }, [])
                        .map((head) => {
                          if (head === "Designation") {
                            return null;
                          }
                          let headTotal = 0;
                          Object.keys(staffData).forEach((fullName) => {
                            const data = staffData[fullName][head];
                            if (data) {
                              headTotal += isNaN(data.Amount)
                                ? 0.0
                                : parseInt(data.Amount);
                            }
                          });
                          return (
                            <td
                              className="rdt_TableCell tablecell text-end fw-bold"
                              key={head}
                            >
                              {headTotal.toFixed(2)}
                            </td>
                          );
                        })}
                      <td
                        className="rdt_TableCell tablecell text-end sticky-right fw-bold"
                        style={{ background: "#f8f8ff" }}
                      >
                        {totalAmt.toFixed(2)}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              ) : (
                <p className="text-center">
                  {mode === "en" ? "No Data" : "डाटा उपलब्ध छैन"}
                </p>
              )}
            </>
          )}
        </div>
      </>
    </>
  );
}
