import React, { useState, useEffect, useContext } from "react";
import { Fetchdata } from "../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import TodoContext from "./TodoContext";
import UpperbarContext from "../context/upperbar-context";
import $ from "jquery";
import AuthContext from "../context/auth-context";
import { GetEnglishDate, GetNepaliDate } from "../hooks/dateConvertor";

function TodoState(props) {
  const { User } = useContext(AuthContext);
  const { appURL } = useContext(UpperbarContext);
  var d = new Date();
  // attendance Todo--------------
  const todoValue = {
    staff: "",
    title: "",
    description: "",
    priority: "",
    dueDate: GetEnglishDate(d),
  };

  const [todoValues, setTodoValues] = useState(todoValue);

  const [todoErrors, setTodoErrors] = useState({});
  const [submit, setSubmit] = useState(false);
  const [todoEditSubmit, setTodoEditSubmit] = useState(false);
  const [loading, setLoading] = useState(true);

  const [originalList, setOriginalList] = useState(null);

  const [todoList, setTodoList] = useState([]);

  // to show Todo list

  useEffect(() => {
    todoLst();
  }, []);

  const todoLst = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "1",
      Flag: "S",
      FetchURL: `${appURL}api/todo`,
      Type: "POST",
    };

    Fetchdata(params).then(function (resp) {
      if (resp.StatusCode === 200) {
        const postResult = resp.Values ? resp.Values : "";
        setTodoList(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setTodoList([]);
        setOriginalList([]);
        setLoading(false);
      }
    });
  };

  // to edit and view Todo

  const [perTodoID, setPerTodoId] = useState("");
  const [editTodoPop, setEditTodoPop] = useState(false);
  const [viewTodoPop, setViewTodoPop] = useState(false);
  const [viewTodoList, setViewTodoList] = useState(false);
  const handleTodoEdit = (data) => {
    setPerTodoId(data.TaskID);
    setTodoValues({
      staff: data.AssignedTo,
      title: data.Title,
      description: data.Description,
      dueDate: data.DueDate,
      priority: data.Priority,
    });
    setEditTodoPop(true);
  };
  const handleTodoView = (data) => {
    setPerTodoId(data.TaskID);

    setViewTodoPop(true);
  };

  useEffect(() => {
    todoInfo();
  }, [perTodoID]);

  const todoInfo = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID.toString(),
      Flag: "SI",
      TaskID: perTodoID.toString(),
      FetchURL: `${appURL}api/todo`,
      Type: "POST",
    };

    Fetchdata(params).then(function (resp) {
      if (resp.StatusCode === 200) {
        const data = resp.Values[0] ? resp.Values[0] : "";

        setViewTodoList(data);
      } else {
        setViewTodoList([]);
      }
    });
  };
  const [re, setRe] = useState();
  const editTodo = () => {
    const id = "" + perTodoID;
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID.toString(),
      TaskID: id,
      Flag: "U",
      AssignedTo: todoValues.staff.toString(),
      Title: todoValues.title,
      Description: todoValues.description,
      Priority: todoValues.priority,
      DueDate: todoValues.dueDate,
      Status: "T",
      FetchURL: `${appURL}api/todo`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (resp) {
      if (resp.StatusCode === 200) {
        setEditTodoPop(false);
        todoLst();
        todoInfo();
        setRe(Math.random());
        $(".editTodoPopBg").fadeOut(500);
        $(".editTodoPop").slideUp(500);
        toast.success(resp.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + resp.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  // to hit Todo status
  const [todoStatus, setTodoStatus] = useState("");

  const changeStatus = (data) => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID.toString(),
      Flag: "CS",
      TaskID: data[0].toString(),
      Status: data[1],
      FetchURL: `${appURL}api/todo`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        todoLst();
        todoInfo();
      }
    });
  };

  // to hit Todo Priority
  const [todoPriority, setTodoPriority] = useState("");

  const changePriority = (data) => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID.toString(),
      Flag: "CP",
      TaskID: data[0].toString(),
      Priority: data[1],
      FetchURL: `${appURL}api/todo`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        todoLst();
        todoInfo();
      }
    });
  };

  // to hit Todo Staff
  const [todoStaff, setTodoStaff] = useState("");

  const changeStaff = (data) => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID.toString(),
      Flag: "CA",
      TaskID: data[0].toString(),
      AssignedTo: data[1],
      FetchURL: `${appURL}api/todo`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        todoLst();
        todoInfo();
      }
    });
  };

  // to hit Todo Due Date
  const [todoDate, setTodoDate] = useState("");

  const changeDate = (data) => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID.toString(),
      Flag: "CD",
      TaskID: data[0].toString(),
      DueDate: todoDate,
      FetchURL: `${appURL}api/todo`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        todoLst();
        todoInfo();
      }
    });
  };

  // delete Todo
  const [deleteTodoPopUp, setDeleteTodoPopUp] = useState(false);
  const [deleteTodoID, setDeleteTodoId] = useState("");
  const handleTodoDelete = (data) => {
    setDeleteTodoId(data.TaskID);
    setDeleteTodoPopUp(true);
  };

  const TodoDeleteID = "" + deleteTodoID;

  const deleteTodo = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID.toString(),
      Flag: "R",
      TaskID: TodoDeleteID,
      FetchURL: `${appURL}api/todo`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (resp) {
      if (resp.StatusCode === 200) {
        setDeleteTodoPopUp(false);
        todoLst();
        toast.success(resp.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + resp.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  return (
    <TodoContext.Provider
      value={{
        todoValues,
        setTodoValues,
        todoValue,
        todoErrors,
        setTodoErrors,
        submit,
        setSubmit,
        todoList,
        setTodoList,
        loading,
        setLoading,
        todoLst,
        editTodo,
        todoEditSubmit,
        setTodoEditSubmit,
        handleTodoEdit,
        editTodoPop,
        setEditTodoPop,
        handleTodoDelete,
        deleteTodoPopUp,
        setDeleteTodoPopUp,
        deleteTodo,

        originalList,

        todoStatus,
        setTodoStatus,
        changeStatus,

        todoPriority,
        setTodoPriority,
        changePriority,

        todoStaff,
        setTodoStaff,
        changeStaff,

        todoDate,
        setTodoDate,
        changeDate,

        handleTodoView,
        viewTodoPop,
        setViewTodoPop,
        viewTodoList,
        setViewTodoList,
        re,
        setRe,
      }}
    >
      {props.children}
    </TodoContext.Provider>
  );
}
export default TodoState;
