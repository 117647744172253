import React, { useContext, useEffect, useState } from "react";
import "./profile.css";
import DataTable from "react-data-table-component";
import AuthContext from "../context/auth-context";
import { Fetchdata } from "../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../loading/spinner";
import UpperbarContext from "../context/upperbar-context";
import StaffContext from "../adminPanel/organization/staffState/StaffContext";
import Toast from "../Toast";
import { AiOutlineEye } from "react-icons/ai";
import { englishToNepaliNumber } from "nepali-number";

function Document() {
  const [loading, setLoading] = useState(true);
  const [documentList, setDocumentList] = useState([]);
  const { User } = useContext(AuthContext);
  const { mode, appURL, customStyles } = useContext(UpperbarContext);

  useEffect(() => {
    doclst();
  }, []);

  const doclst = () => {
    const userid = "" + User.UID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      Flag: "S",
      UserID: userid,
      Type: "POST",
      FetchURL: `${appURL}api/document`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setDocumentList(postResult);
        setLoading(false);
      } else {
        setDocumentList([]);
        setLoading(false);
      }
    });
  };
  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      grow: 0,
      center: true,
      width: "70px",
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },
    {
      name: mode === "en" ? "Name" : "नाम",
      // center: true,
      // grow: 0,
      minWidth: "200px",
      selector: (row) => row.Title,
    },
    {
      name: mode === "en" ? "Type" : "प्रकार",
      // grow: 0,
      width: "150px",
      // center: true,
      selector: (row) => row.FileType,
    },
    {
      name: mode === "en" ? "Action" : "कार्य",
      // grow: 0,
      center: true,
      width: "130px",
      // selector: (row) => row.FilePath,
      selector: (row) => {
        return (
          <a
            href={row.FilePath}
            target="_blank"
            class="btn btn-sm editspan"
            uk-tooltip={mode === "en" ? "View" : "हेर्नुहोस्"}
          >
            <AiOutlineEye />
          </a>
        );
      },
    },
  ];

  return (
    <>
      {/* <Toast /> */}
      {loading ? (
        <>
          <Spinner />
        </>
      ) : (
        <DataTable
          columns={columns}
          data={documentList}
          customStyles={customStyles}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="410px"
          highlightOnHover
          pointerOnHover
          responsive
          progressPending={loading}
          dense
          striped
        />
      )}
    </>
  );
}

export default Document;
