import React, { useState, useEffect, useContext } from "react";
import AreaContext from "./AreaContext";
import { Fetchdata } from "../../../../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import AuthContext from "../../../../context/auth-context";
import UpperbarContext from "../../../../context/upperbar-context";
import $ from "jquery";

function AreaState(props) {
  const { User } = useContext(AuthContext);
  const { appURL } = useContext(UpperbarContext);
  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const [popup, setPopup] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [perEditSubmit, setPerEditSubmit] = useState(false);
  const [checkedActive, setCheckedActive] = useState(false);

  const initalvalue = {
    name: "",
    under: "",
    type: "",
  };
  const [formValues, setFormValues] = useState(initalvalue);
  const [formErrors, setFormErrors] = useState({});

  //  API to show main group list
  const [areaList, setAreaList] = useState([]);

  useEffect(() => {
    areaLst();
  }, [submit, perEditSubmit]);

  const areaLst = () => {
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      CID: "1",
      Flag: "S",
      IsActive: "A",
      BranchID: Branch,
      FiscalID: Fiscal,
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/area`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setAreaList(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setAreaList([]);
        setLoading(false);
      }
    });
  };

  //  API to edit main group
  const [perID, setPerId] = useState();
  const [editPopup, setEditPopup] = useState(false);
  const handleEdit = (data) => {
    setPerId(data.AreaID);
    setEditPopup(true);
    areaunderLst();
  };

  const editData = () => {
    const UserID = "" + User.UID;
    const id = "" + perID;
    const referid = "" + formValues.under;
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      CID: "1",
      Flag: "U",
      AreaID: id,
      ReferID: referid !== " " ? referid : " ",
      Name: formValues.name !== " " ? formValues.name : " ",
      AreaTypeID: formValues.type !== " " ? formValues.type : " ",
      FetchURL: `${appURL}api/acc/admin/area`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (resp) {
      if (resp.StatusCode === 200) {
        setEditPopup(false);
        $(".editAreaPopBg").fadeOut(300);
        $(".editAreaPop").slideUp(500);
        areaLst();
        toast.success(resp.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + resp.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  // API to get main group info
  useEffect(() => {
    infoList();
  }, [perID]);

  const infoList = () => {
    const UserID = "" + User.UID;
    const id = "" + perID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      Flag: "SI",
      CID: "1",
      AreaID: id,
      IsActive: "A",
      FetchURL: `${appURL}api/acc/admin/area`,
      Type: "POST",
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const data = result.Values[0] ? result.Values[0] : "";
        setFormValues({
          name: data.Area,
          under: data.ReferID,
          type: data.AreaType,
        });
        setCheckedActive(data.IsActive === "A" ? true : false);
      } else {
      }
    });
  };

  // API to hit main group status
  const stat = [];
  const [newStatus, setNewStatus] = useState(stat);

  const deactivateData = (ID, IsActive) => {
    const UserID = "" + User.UID;
    const id = "" + ID;
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      Flag: "AI",
      CID: "1",
      AreaID: id,
      IsActive: IsActive,
      FetchURL: `${appURL}api/acc/admin/area`,
      Type: "POST",
    };
    if (IsActive === "A") {
      dataForm.IsActive = "I";
    } else {
      dataForm.IsActive = "A";
    }
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        areaLst();
        let NewstatsN = JSON.parse(JSON.stringify(newStatus));
        let pitchNewStatus;

        if (dataForm.IsActive === "A") {
          pitchNewStatus = "Activated";
        } else if (dataForm.IsActive === "I") {
          pitchNewStatus = "Deactivated";
        }
        setNewStatus(NewstatsN);
        toast.success(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  //   API to under list
  const [areaUnderList, setAreaUnderList] = useState([]);

  useEffect(() => {
    areaunderLst();
  }, [submit]);

  const areaunderLst = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      DFlag: "AREA",
      Flag: "S",
      CID: "1",
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/ref-values`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setAreaUnderList(postResult);
      } else {
        setAreaUnderList([]);
      }
    });
  };

  return (
    <AreaContext.Provider
      value={{
        loading,
        setLoading,
        originalList,
        setOriginalList,
        popup,
        setPopup,
        submit,
        setSubmit,
        perEditSubmit,
        setPerEditSubmit,
        initalvalue,
        formValues,
        setFormValues,
        formErrors,
        setFormErrors,
        areaList,
        setAreaList,
        editPopup,
        setEditPopup,
        handleEdit,
        editData,
        deactivateData,
        areaLst,
        checkedActive,
        setCheckedActive,
        areaUnderList,
        areaunderLst,
      }}
    >
      {props.children}
    </AreaContext.Provider>
  );
}

export default AreaState;
