import React, { useContext, useEffect, useState, useRef } from "react";
import "../../../profile/profile.css";
import { Fetchdata } from "../../../hooks/getData";
import AuthContext from "../../../context/auth-context";
import "react-toastify/dist/ReactToastify.css";
import UpperbarContext from "../../../context/upperbar-context";
import { FaRegCalendarAlt } from "react-icons/fa";
import { AiFillPlusSquare, AiFillMinusSquare } from "react-icons/ai";
import { BsCheckLg } from "react-icons/bs";
import { MdOutlineClose } from "react-icons/md";
import Toast from "../../../Toast";
import "jspdf-autotable";
import NepaliDate from "nepali-date-converter";
import { englishToNepaliNumber } from "nepali-number";
import { GetCurrMonth, GetEnglishDate } from "../../../hooks/dateConvertor";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { AiOutlineSearch } from "react-icons/ai";
import Spinner from "../../../loading/spinner";
import { ToastContainer, toast } from "react-toastify";

export default function ShiftRoaster() {
  const { appURL, fiscalYear, dateMode,todayDate, expires, userDetails, mode } =
    useContext(UpperbarContext);

  //   const [loading, setLoading] = useState(true);
  const searchInput = useRef("");
  const { User } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  const initialValue = {
    deparment: "-1",
    subDepartment: "-1",
  };
  const [formValues, setFormValues] = useState(initialValue);
  const [sMonth, setSMonth] = useState(GetCurrMonth());
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === "deparment") {
      formValues.subDepartment = "-1";
    }
    setFormValues({ ...formValues, [name]: value });
  };

  const [depList, setDepList] = useState([]);
  // const [subDepList, setSubDepList] = useState([]);

  useEffect(() => {
    deptList();
  }, []);

  const deptList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      IsActive: "A",
      Type: "POST",
      FetchURL: `${appURL}api/admin/department`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setDepList(postResult);
      } else {
      }
    });
  };

  // useEffect(() => {
  //   if (formValues.deparment) {
  //     const dataForm = {
  //       ComID: User.CompanyId,
  //       StaffID: -1,
  //       DepartID: formValues.deparment,
  //       Flag: "S",
  //       Status: "1",
  //       BranchID: User.BranchId,
  //       FetchURL: `${appURL}api/admin/sub-department`,
  //       Type: "POST",
  //     };

  //     Fetchdata(dataForm).then(function (result) {
  //       if (result.StatusCode === 200) {
  //         const postResult = result.SubDepList;
  //         setSubDepList(postResult);
  //       } else {
  //         setSubDepList([]);
  //       }
  //     });
  //   }
  // }, [formValues.deparment]);

  const [staffList, setStaffList] = useState([]);
  const [originalList, setOriginalList] = useState(null);

  useEffect(() => {
    staffLst();
  }, []);

  const staffLst = () => {
    const BranchID = "" + User.BranchID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      DFlag: "STAFF",
      BranchID: BranchID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/ref-values`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setStaffList(postResult);
        setOriginalList(postResult);
      } else {
        setStaffList([]);
        setOriginalList([]);
      }
    });
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["FullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setStaffList(srchResult);
      } else {
        setStaffList({});
      }
    } else {
      setStaffList(originalList);
    }
  };

  // Create a lookup object to map UserIDs to Names
  const nameLookup = {};
  staffList.forEach(({ UserID, FullName }) => {
    nameLookup[UserID] = FullName;
  });

  // =-----

  const currentDate = new NepaliDate(); // create a NepaliDate object with the current date
  const currentYear = currentDate.getYear(); // get the current year
  const currentMonth = sMonth - 1; // get the current month

  // calculate the number of days in the current Nepali month
  function getLastDayOfNepaliMonth() {
    const today = new NepaliDate(); // create a new NepaliDate instance for the current date
    const year = today.getYear();
    const month = sMonth - 1;
    const lastDay = new NepaliDate(year, month + 1, 0); // create a new NepaliDate instance for the last day of the current month
    return lastDay.getDate(); // return only the last day of the month in number format
  }

  const lastDay = getLastDayOfNepaliMonth();

  const dates = [];

  // loop through all the days of the current month and add them to the dates array
  for (let day = 1; day <= lastDay; day++) {
    const date = new NepaliDate(currentYear, currentMonth, day);
    const formattedDate = date.format("YYYY/MM/DD"); // format the date string as YYYY/MM/DD
    dates.push(formattedDate);
  }

  //API to hit Shift list
  const [shiftList, setShiftList] = useState([]);

  useEffect(() => {
    shftList();
  }, []);

  const shftList = () => {
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      BranchID: Branch,
      FiscalID: Fiscal,
      Type: "POST",
      FetchURL: `${appURL}api/admin/shift`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setShiftList(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setLoading(false);
        setOriginalList([]);
        setShiftList([]);
      }
    });
  };
  //API to hit Shift Roaster list
  const [shiftRoasterList, setShiftRoasterList] = useState([]);

  useEffect(() => {
    shftRoasterList();
  }, [currentYear, sMonth]);

  const shftRoasterList = () => {
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      StaffID: "-1",
      UserID: "-1",
      Flag: "S",
      DtFlag: "NM",
      ShiftDate: `${currentYear}/${sMonth}`,
      BranchID: Branch,
      FiscalID: Fiscal,
      Type: "POST",
      FetchURL: `${appURL}api/admin/shift-roaster`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setShiftRoasterList(postResult);
      } else {
        setShiftRoasterList([]);
      }
    });
  };

  const shiftsByUserIDAndDate = {};
  shiftRoasterList.forEach((shift) => {
    const { UserID, NepDate, Shift, ShiftRoastID } = shift;
    // shiftsByUserIDAndDate[UserID] = shiftsByUserIDAndDate[UserID] || {};
    // shiftsByUserIDAndDate[UserID][NepDate] = Shift;
    shiftsByUserIDAndDate[UserID] = shiftsByUserIDAndDate[UserID] || {};
    shiftsByUserIDAndDate[UserID][NepDate] =
      shiftsByUserIDAndDate[UserID][NepDate] || [];
    shiftsByUserIDAndDate[UserID][NepDate][ShiftRoastID] = Shift;
  });

  const dropDownValue = shiftList.map((item) => ({
    value: item.ShiftID,
    label: item.Shift,
  }));

  // const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  // const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [activeCell, setActiveCell] = useState(null);
  const handleAdd = (i, j) => {
    setActiveCell({ row: i, col: j });
    setSelectedOptions([]);
  };
  const handleClose = () => {
    setActiveCell(false);
    setSelectedOptions([]);
  };

  const handleRemove = (data) => {
    const userid = "" + User.UID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: userid,
      Flag: "R",
      ShiftRoastID: data,
      Type: "POST",
      FetchURL: `${appURL}api/admin/shift-roaster`,
    };
    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        shftRoasterList();
      } else {
      }
    });
  };

  const [isSubmit, setIsSubmit] = useState(false);

  const handleSubmit = () => {
    setIsSubmit(true);
  };

  useEffect(() => {
    const userid = "" + User.UID;
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;
    if (isSubmit) {
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: userid,
        Flag: "IU",
        Values: selectedOptions,
        BranchID: Branch,
        FiscalID: Fiscal,
        FetchURL: `${appURL}api/admin/shift-roaster`,
        Type: "POST",
      };
      Fetchdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          shftRoasterList();
          // setActiveCell(false);
          setSelectedOptions([]);
          // toast.success(result.Message, {
          //   style: {
          //     color: "green",
          //     fontSize: "13px",
          //   },
          //   theme: "light",
          // });
        } else {
          // toast.error("Error: " + result.Message, {
          //   style: {
          //     color: "red",
          //     fontSize: "13px",
          //   },
          //   theme: "light",
          // });
        }
      });

      setIsSubmit(false);
    }
  }, [isSubmit]);

  // -------

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper   ps-4 pe-4">
        <div className="row ">
          <div className="page-header">
            <div className="text-start  page-title">
              {mode === "en" ? "Shift Roaster" : "शिफ्ट रोस्टर"}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <span className="me-2 ">Expires in: {expires} </span>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>
        <>
          <div className="sec-dataTable">
            <div className="upper-dataTbl">
              <div className="uk-flex  uk-flex-wrap uk-flex-between uk-flex-middle mb-3">
                <div className="uk-flex uk-flex-wrap me-3">
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en" ? "Select Month" : "महिना चयन गर्नुहोस्"}
                    </label>
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example "
                      onChange={(e) => setSMonth(e.target.value)}
                      value={sMonth}
                    >
                      <option disabled value="" selected>
                        {mode === "en" ? "Select Month" : "महिना चयन गर्नुहोस्"}
                      </option>

                      <option value="01">
                        {mode === "en" ? "Baishakh" : "बैशाख"}
                      </option>
                      <option value="02">
                        {mode === "en" ? "Jestha" : "जेष्ठ"}
                      </option>
                      <option value="03">
                        {mode === "en" ? "Ashar" : "असार"}
                      </option>
                      <option value="04">
                        {mode === "en" ? "Shrawan" : "श्रावण"}
                      </option>
                      <option value="05">
                        {mode === "en" ? "Bhadra" : "भाद्र"}
                      </option>
                      <option value="06">
                        {mode === "en" ? "Asoj" : "असोज"}
                      </option>
                      <option value="07">
                        {mode === "en" ? "Kartik" : "कार्तिक"}
                      </option>
                      <option value="08">
                        {mode === "en" ? "Mangsir" : "मंसिर"}
                      </option>
                      <option value="09">
                        {mode === "en" ? "Poush" : "पौष"}
                      </option>
                      <option value="10">
                        {mode === "en" ? "Magh" : "माघ"}
                      </option>
                      <option value="11">
                        {mode === "en" ? "Falgun" : "फाल्गुन"}
                      </option>
                      <option value="12">
                        {mode === "en" ? "Chaitra" : "चैत्र"}
                      </option>
                    </select>
                  </div>
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en"
                        ? "Select Department"
                        : "विभाग चयन गर्नुहोस्"}
                    </label>
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example "
                      name="deparment"
                      onChange={handleChange}
                      value={formValues.deparment}
                    >
                      <option disabled value="" selected>
                        {mode === "en"
                          ? "Select Department"
                          : "विभाग चयन गर्नुहोस्"}
                      </option>
                      <option value="-1">
                        {" "}
                        {mode === "en" ? "Select All" : "सबै चयन गर्नुहोस्"}
                      </option>
                      {depList.map((list) => (
                        <>
                          <option
                            key={list.DepartmentID}
                            value={list.DepartmentID}
                          >
                            {list.Department}
                          </option>
                        </>
                      ))}
                    </select>
                  </div>

                  {/* {formValues.deparment !== "-1" && (
                    <div className="me-2 mt-3">
                      <label className="d-block text-start dark-gray mb-1">
                        {mode === "en"
                          ? "Select Sub-Department"
                          : "उप विभाग चयन गर्नुहोस्"}
                      </label>
                      <select
                        class="form-select form-select-sm"
                        aria-label="Default select example "
                        name="subDepartment"
                        onChange={handleChange}
                        value={formValues.subDepartment}
                      >
                        <option disabled value="" selected>
                          {mode === "en"
                            ? "Select Sub-Department"
                            : "उप विभाग चयन गर्नुहोस्"}
                        </option>
                        <option value="-1">
                          {" "}
                          {mode === "en" ? "Select All" : "सबै चयन गर्नुहोस्"}
                        </option>
                        {subDepList.map((list) => (
                          <>
                            <option
                              key={list.SubDepartID}
                              value={list.SubDepartID}
                            >
                              {list.SubDepartName}
                            </option>
                          </>
                        ))}
                      </select>
                    </div>
                  )} */}
                </div>

                {/* <div className="">
                  <div className="d-flex uk-flex-wrap uk-flex-middle uk-flex-right">
                    <div className="btn-addlnote mt-3">
                      <button
                        type="button"
                        class="btn btn-sm"
                        style={{
                          background: "var(--button-color)",
                          color: "white",
                        }}
                        // onClick={addLeaveNote}
                      >
                        {mode === "en"
                          ? "Apply Changes"
                          : "परिवर्तनहरू लागू गर्नुहोस्"}
                      </button>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            {/* <div className=" w-100 mt-3">
              <div className="d-flex uk-flex-middle justify-content-end">
                <div>
                  <div class="uk-search uk-search-default">
                    <AiOutlineSearch className="search-icon" />
                    <input
                      placeholder={mode === "en" ? "Search" : "खोजी गर्नुहोस्"}
                      ref={searchInput}
                      type="text"
                      className="form-control form-control-sm searchField"
                      onChange={searchHandler}
                    />
                  </div>
                </div>
              </div>
            </div> */}
            {loading ? (
              <Spinner />
            ) : (
              <>
                {staffList.length > 0 ? (
                  <div className="shiftRoasterTable mt-3">
                    <div className="sc-fLlhyt ifOHjV">
                      <table
                        className="uk-table"
                        style={{ width: "max-content" }}
                      >
                        <tr className="head-top">
                          <td
                            className="rdt_TableHeadRow tableHead text-center"
                            width="70px"
                            style={{ verticalAlign: "middle" }}
                          >
                            {mode === "en" ? "S.N." : "क्र.सं"}
                          </td>
                          <td
                            className="rdt_TableHeadRow tableHead text-start"
                            width="200px"
                            style={{ verticalAlign: "middle" }}
                          >
                            {mode === "en" ? "Full Name" : "पुरा नाम"}
                          </td>

                          {dates.map((date) => {
                            return (
                              <td
                                className="rdt_TableHeadRow tableHead text-center"
                                width="200px"
                              >
                                {/* {mode === "en"
                                  ? date
                                  : englishToNepaliNumber(date)} */}

                                  {
                                     dateMode === "en" && mode === "np" ?
                                     englishToNepaliNumber(GetEnglishDate(date)) :
                                     dateMode === "en" ? 
                                     GetEnglishDate(date) :
                                     dateMode === "np" && mode === "np" ?
                                     englishToNepaliNumber(date):
                                     dateMode === "np" ? 
                                     date :
                                     null
                                  }
                              </td>
                            );
                          })}
                        </tr>

                        {staffList.map((data, i) => {
                          return (
                            <>
                              <tr>
                                <td className="rdt_TableCell tablecell  text-center">
                                  {mode === "en"
                                    ? i + 1
                                    : englishToNepaliNumber(i + 1)}
                                </td>

                                <td className="rdt_TableCell tablecell text-start">
                                  {data.FullName}
                                </td>

                                {dates.map((date, j) => {
                                  const isActive =
                                    activeCell &&
                                    activeCell.row === i &&
                                    activeCell.col === j;

                                  const ShiftList =
                                    shiftsByUserIDAndDate[data.UserID]?.[date];

                                  const renderShifts = (ShiftList) => {
                                    const ShiftLength = shiftsByUserIDAndDate[
                                      data.UserID
                                    ]?.[date]
                                      ? Object.values(
                                          shiftsByUserIDAndDate[data.UserID]?.[
                                            date
                                          ]
                                        )
                                      : [];
                                    const Sftlength = ShiftLength.length;

                                    if (Sftlength === 1) {
                                      return ShiftList.map((value) => (
                                        <span className="span" key={value}>
                                          {value}
                                        </span>
                                      ));
                                    } else {
                                      return (
                                        <span className="span">
                                          {Sftlength} Shifts
                                        </span>
                                      );
                                    }
                                  };

                                  return (
                                    <td
                                      key={`${data.UserID}-${date}`}
                                      className=" rdt_TableCell tablecell multipleCheckbox"
                                    >
                                      <div className="uk-flex uk-flex-between uk-flex-middle">
                                        <div className="shiftListWidth">
                                          {ShiftList?.length ? (
                                            renderShifts(ShiftList)
                                          ) : (
                                            <span
                                              style={{
                                                color: "rgba(0, 0, 0, 0.5)",
                                              }}
                                            >
                                              Select Shift
                                            </span>
                                          )}
                                        </div>
                                        <div>
                                          {isActive ? (
                                            <span
                                              style={{ cursor: "pointer" }}
                                              onClick={handleClose}
                                            >
                                              <AiFillMinusSquare
                                                color="#bb2d3b"
                                                size="1.5rem"
                                              />
                                            </span>
                                          ) : (
                                            <span
                                              style={{ cursor: "pointer" }}
                                              onClick={() => handleAdd(i, j)}
                                            >
                                              <AiFillPlusSquare
                                                color="var(--button-color)"
                                                size="1.5rem"
                                              />
                                            </span>
                                          )}
                                        </div>

                                        {isActive && (
                                          <div className="multipleCheckboxDropdown uk-animation-slide-bottom uk-animation-fast">
                                            {shiftList.map((item) => {
                                              const shiftid = "" + item.ShiftID;
                                              const userid = "" + data.UserID;

                                              const shiftValue =
                                                shiftsByUserIDAndDate[
                                                  data.UserID
                                                ]?.[date];
                                              const isChecked =
                                                shiftValue?.includes(
                                                  item.Shift
                                                );

                                              const shiftRoasterID =
                                                shiftsByUserIDAndDate[
                                                  data.UserID
                                                ]?.[date]
                                                  ? Object.keys(
                                                      shiftsByUserIDAndDate[
                                                        data.UserID
                                                      ][date]
                                                    )
                                                  : [];

                                              return (
                                                <>
                                                  <div className="uk-flex">
                                                    <input
                                                      type="checkbox"
                                                      name=""
                                                      id={item.ShiftID}
                                                      defaultChecked={isChecked}
                                                      className="me-2"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onChange={(e) => {
                                                        const isChecked =
                                                          e.target.checked;

                                                        if (isChecked) {
                                                          setSelectedOptions([
                                                            // ...selectedOptions,
                                                            {
                                                              ShiftID: shiftid,
                                                              StaffID: userid,
                                                              NepDate: date,
                                                              EngDate:
                                                                GetEnglishDate(
                                                                  date
                                                                ),
                                                            },
                                                          ]);
                                                          handleSubmit();
                                                        } else {
                                                          const uncheckedShiftRoasterID =
                                                            shiftRoasterID.find(
                                                              (id) =>
                                                                shiftsByUserIDAndDate[
                                                                  data.UserID
                                                                ][date][id] ===
                                                                item.Shift
                                                            );
                                                          // setSelectedOptions(
                                                          //   selectedOptions.filter(
                                                          //     (props) =>
                                                          //       props.ShiftID !==
                                                          //       shiftid
                                                          //   )
                                                          // );
                                                          handleRemove(
                                                            uncheckedShiftRoasterID
                                                          );
                                                        }
                                                      }}
                                                      value={selectedOptions}
                                                    />

                                                    <label
                                                      htmlFor={item.ShiftID}
                                                    >
                                                      {item.Shift}
                                                    </label>
                                                  </div>
                                                </>
                                              );
                                            })}
                                            {/* <div
                                              className="ln-popUpFooter"
                                              style={{ position: "sticky" }}
                                            >
                                              <div className="row  mt-1 mb-1">
                                                <div>
                                                  <button
                                                    type="button"
                                                    class="btn btn-sm me-2"
                                                    style={{
                                                      background:
                                                        "var(--button-color)",
                                                      color: "white",
                                                    }}
                                                    // onClick={handleSubmit}
                                                  >
                                                    <BsCheckLg />
                                                  </button>
                                                  <button
                                                    type="button"
                                                    class="btn btn-sm btn-danger me-1"
                                                    // style={{ color: "white" }}
                                                    onClick={handleClose}
                                                  >
                                                    <MdOutlineClose color="#FFF" />
                                                  </button>
                                                </div>
                                              </div>
                                            </div> */}
                                          </div>
                                        )}
                                      </div>
                                    </td>
                                  );
                                })}
                              </tr>
                            </>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                ) : (
                  <p>No data</p>
                )}
              </>
            )}
          </div>
        </>
      </div>
    </>
  );
}
