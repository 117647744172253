import React from "react";
import { useContext } from "react";
import LedgerContext from "../ledgerState/LedgerContext";

const CreditLimit = ({ handleChange, formValues, mode, formErrors }) => {
  const { rateInput, setRateInput } = useContext(LedgerContext);

  return (
    <>
      <div className="row text-start ">
        <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
            <label htmlFor="creditAmt" style={{ fontSize: "12px" }}>
              {mode === "en" ? "Credit Amount" : "क्रेडिट रकम"}
            </label>
            <input
              id="creditAmt"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="creditAmt"
              placeholder={mode === "en" ? "Credit Amount" : "क्रेडिट रकम"}
              value={formValues.creditAmt}
              onChange={handleChange}
            />
            {/* {formErrors.creditAmt && (
      <p className="errormsg">{formErrors.creditAmt}</p>
    )} */}
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
            <label htmlFor="actionAmt" style={{ fontSize: "12px" }}>
              {mode === "en" ? "Select Action" : "कार्य चयन गर्नुहोस्"}
            </label>
            <select
              class="form-select form-select-sm"
              aria-label="Default select example"
              name="actionAmt"
              id="actionAmt"
              value={formValues.actionAmt}
              onChange={handleChange}
            >
              <option disabled value="" selected>
                {mode === "en" ? "Select Action" : "कार्य चयन गर्नुहोस्"}
              </option>
              <option value="I">
                {mode === "en" ? "Ignore" : "बेवास्ता गर्नुहोस्"}
              </option>
              <option value="W">{mode === "en" ? "Warning" : "चेतावनी"}</option>
              <option value="B">{mode === "en" ? "Block" : "ब्लक"}</option>
            </select>
            {/* {formErrors.actionAmt && (
      <p className="errormsg">{formErrors.actionAmt}</p>
    )} */}
          </div>
        </div>
      </div>
      <div className="row text-start ">
        <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
            <label htmlFor="creditDays" style={{ fontSize: "12px" }}>
              {mode === "en" ? "Credit Days" : "क्रेडिट दिनहरू"}
            </label>
            <input
              id="creditDays"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="creditDays"
              placeholder={mode === "en" ? "Credit Days" : "क्रेडिट दिनहरू"}
              value={formValues.creditDays}
              onChange={handleChange}
            />
            {/* {formErrors.creditDays && (
      <p className="errormsg">{formErrors.creditDays}</p>
    )} */}
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
            <label htmlFor="actionDay" style={{ fontSize: "12px" }}>
              {mode === "en" ? "Select Action" : "कार्य चयन गर्नुहोस्"}
            </label>
            <select
              class="form-select form-select-sm"
              aria-label="Default select example"
              name="actionDay"
              id="actionDay"
              value={formValues.actionDay}
              onChange={handleChange}
            >
              <option disabled value="" selected>
                {mode === "en" ? "Select Action" : "कार्य चयन गर्नुहोस्"}
              </option>
              <option value="I">
                {mode === "en" ? "Ignore" : "बेवास्ता गर्नुहोस्"}
              </option>
              <option value="W">{mode === "en" ? "Warning" : "चेतावनी"}</option>
              <option value="B">{mode === "en" ? "Block" : "ब्लक"}</option>
            </select>
            {/* {formErrors.actionDay && (
      <p className="errormsg">{formErrors.actionDay}</p>
    )} */}
          </div>
        </div>
      </div>

      <div className="row text-start ">
        <div className="form-group" style={{ width: "max-content" }}>
          <div class="form-check" style={{ fontSize: "12px" }}>
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="RATE"
              // name="allowSpace"
              // checked={allowSpace}
              onChange={() => setRateInput(!rateInput)}
            />
            <label
              style={{ fontSize: "12px", cursor: "pointer" }}
              class="form-check-label"
              htmlFor="RATE"
            >
              {mode === "en" ? "Rate of Interest (%)" : "ब्याज दर (%)"}
            </label>
          </div>
        </div>
      </div>
      {rateInput && (
        <div className="row text-start ">
          <div className="form-group mt-0">
            <label htmlFor="rate" style={{ fontSize: "12px" }}>
              {mode === "en" ? "Rate of Interest (%)" : "ब्याज दर (%)"}
            </label>
            <input
              id="rate"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="rate"
              placeholder={
                mode === "en" ? "Rate of Interest (%)" : "ब्याज दर (%)"
              }
              value={formValues.rate}
              onChange={handleChange}
            />
            {/* {formErrors.rate && (
      <p className="errormsg">{formErrors.rate}</p>
    )} */}
          </div>
        </div>
      )}
    </>
  );
};

export default CreditLimit;
