import React, { useContext, useEffect, useState } from "react";
import "./addAttendance.css";
import CloseIcon from "../../../images/CloseIcon.svg";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import { Fetchdata } from "../../hooks/getData";
import AuthContext from "../../context/auth-context";
import NepaliDate from "nepali-date-converter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  GetEnglishDate,
  GetTwelveHourFormatTime,
  GetCurrTime,
  GetNepaliDate,
} from "../../hooks/dateConvertor";
import UpperbarContext from "../../context/upperbar-context";
import Toast from "../../Toast";
import $ from "jquery";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { TimePicker } from "antd";
import moment from "moment";

export default function AAddAttendance({
  setAttPopup,
  reload,
  setReload,
  DFlag,
  attPopup,
  re,
}) {
  const { sidePanelBg, mainBg, appURL, mode, dateMode } =
  useContext(UpperbarContext);
  var d = new Date();
  const initalvalue = {
    date: dateMode === "np" ? GetNepaliDate(d) : "",
    // time: GetCurrTime(),
    time: "",
    status: "",
    staff: "",
  };

  const { User } = useContext(AuthContext);
 
  const [formValues, setFormValues] = useState(initalvalue);
  const [formErrors, setformErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [fieldWork, setFieldWork] = useState(false);

  //

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  //API to get staff list
  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    staffLst();
  }, []);

  const staffLst = () => {
    const BranchID = "" + User.BranchID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      DFlag: "STAFF",
      BranchID: BranchID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/ref-values`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setStaffList(postResult);
      } else {
        setStaffList([]);
      }
    });
  };

  const dropDownValue = staffList.map((item) => ({
    value: item.UserID,
    label: item.FullName,
  }));

  const handleSelectStaff = (e) => {
    let name = "staff";
    setFormValues({ ...formValues, [name]: e.value });
  };

  useEffect(() => {
    if (attPopup) {
      $(".addAttPopBg").fadeIn(500);
      $(".addAttPop").slideDown(500);
    }
  }, [attPopup]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setFormValues({ ...formValues, [name]: value });
  };

  const handleTimeChange = (value, timeString) => {
    let name = "time";
    setFormValues({
      ...formValues,
      [name]: timeString,
    });
  };

  const getNepaliDate = () => {
    var news = new Date();
    var newss = news.toLocaleDateString();
    const nepDate = new NepaliDate(new Date(newss));
    var cm = nepDate.getMonth() + 1;
    var cd = nepDate.getDate() + 1;
    //
    let strDate = nepDate.getYear() + "-" + cm + "-" + cd;
    //
    return strDate;
  };

  const closePopUp = (e) => {
    setAttPopup(false);
    $(".addAttPopBg").fadeOut(500);
    $(".addAttPop").slideUp(500);
    setformErrors({});
    setIsSubmit(false);
    setFormValues(initalvalue);
    setFieldWork(false);
    setInputValue("");
  };

  const handleDateChange = ({ bsDate }) => {
    let name = "date";
    setFormValues({ ...formValues, [name]: bsDate });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setformErrors(validate(formValues));
    setIsSubmit(true);
  };

  const validate = (values) => {
    const errors = {};
    const ptn = /^\w+$/;
    const digitPtn = /[0-9]/;
    const alphaptn = /[a-z]/;
    const capalpha = /[A-Z]/;

    if (
      // values.date &&
      values.time &&
      values.status &&
      // values.LeaveDescription &&
      values.staff
    ) {
      //code here
    } else {
      // if (!values.date) {
      //   errors.date = mode === "en" ? "Required" : "आवश्यक छ"
      // }
      if (!values.time) {
        errors.time = mode === "en" ? "Required" : "आवश्यक छ";
      }
      if (!values.status) {
        errors.status = mode === "en" ? "Required" : "आवश्यक छ";
      }
      if (!values.staff) {
        errors.staff = mode === "en" ? "Required" : "आवश्यक छ";
      }
      return errors;
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: formValues.staff,
        Flag: "i",
        DepartmentID: User.DepartmentID,
        DesignationID: User.DesignationID,
        AttenDate:
          dateMode === "np" ? GetEnglishDate(formValues.date) : formValues.date,
        AttenTime: formValues.time,
        AttenStatus: formValues.status,
        AttenPlace: fieldWork ? "H" : "O",
        AttenVia: "M",
        SSID: fieldWork ? "N" : "WEB",
        BranchID: User.BranchID,
        FiscalID: User.FiscalID,
        FetchURL: `${appURL}api/create-attendance`,
        Type: "POST",
      };

      Fetchdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          setReload(!reload);
          $(".addAttPopBg").fadeOut(500);
          $(".addAttPop").slideUp(500);
          setAttPopup(false);
          setformErrors({});
          setFormValues(initalvalue);
          setFieldWork(false);
          setInputValue("");
          // setRe(Math.random());
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
          setformErrors({
            errorv: "Please enter valid credentials",
          });
        }
      });

      setIsSubmit(false);
    }
  }, [formErrors]);

  //

  const [disabledHours, setDisabledHours] = useState(() =>
    Array.from({ length: 24 }, (_, i) => (i > moment().hour() ? i : -1))
  );

  const [disabledMinutes, setDisabledMinutes] = useState(() =>
    Array.from({ length: 60 }, (_, i) =>
      i > moment().minute() && moment().hour() === disabledHours[0] ? i : -1
    )
  );

  console.log('formValues',formValues);
  const now = moment();
  return (
    <>
      {/* <Toast /> */}
      <div className="container newpopup-bg addAttPopBg">
        <div className="newpopup addAttPop " style={mainBg}>
          <div className="newpopup-head ps-0 pe-0" style={sidePanelBg}>
            <div className="popUpTitle">
              {" "}
              {mode === "en" ? "Add Attendance" : "उपस्थिति थप्नुहोस्"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div
            className="newpopup-body ps-3 pe-3 "
            style={{ maxHeight: "45vh" }}
          >
            <div className="form-padding">
              <div className="row text-start mt-2 ">
                <div className="col-md-6 col-sm-6 col-lg-6">
                  <label className="text-start mb-1">
                    {mode === "en" ? "Date" : "मिति"}
                  </label>
                  {dateMode === "np" ? (
                    <Calendar
                      className="form-control form-control-sm pt-0 pb-0 from-date"
                      dateFormat="YYYY/MM/DD"
                      defaultDate="2010/01/01"
                      theme="default"
                      language="en"
                      value={formValues.date}
                      // minDate={getNepaliDate()}
                      maxDate={getNepaliDate()}
                      onChange={handleDateChange}
                      name="date"
                      key={re}

                      // hideDefaultValue={true}
                      // placeholder={"Select From Date"}
                    />
                  ) : (
                    <input
                      type="date"
                      name="date"
                      value={formValues.date}
                      placeholder="Select a Date"
                      className="form-control form-control-sm "
                      onChange={handleChange}
                    />
                  )}
                  {/* {formErrors.date && (
                    <p className="errormsg">{formErrors.date}</p>
                  )} */}
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6">
                  <label className="text-start mb-1">
                    {mode === "en" ? "Time" : "समय"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  {/* <input
                    type="time"
                    // name="time"
                    // value={formValues.time}
                    // placeholder="Select Time"
                    className="form-control form-control-sm "
                    // onChange={handleChange}
                  /> */}
                  <TimePicker
                    name="time"
                    key={re}
                    // defaultValue={now}
                    // value={formValues.time}
                    use12Hours
                    format="h:mm:ss A"
                    // disabledHours={() => disabledHours}
                    // disabledMinutes={() => disabledMinutes}
                    onChange={handleTimeChange}
                  />
                  {formErrors.time && (
                    <p className="errormsg">{formErrors.time}</p>
                  )}
                </div>
              </div>

              <div className="row text-start ">
                <div className="form-group">
                  <label htmlFor="staff" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Select Staff" : "कर्मचारी चयन गर्नुहोस्"}{" "}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  {/* <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    name="staff"
                    value={formValues.staff}
                    onChange={handleChange}
                  >
                    <option disabled value="" selected>
                      Select Staff
                    </option>
                    {staffList.map((item) => (
                      <option key={item.StaffId} value={item.StaffId}>
                        {item.StaffName}
                      </option>
                    ))}
                  </select> */}

                  <Autocomplete
                    className="w-100"
                    disablePortal
                    id="combo-box-demo"
                    open={open}
                    onOpen={() => {
                      if (inputValue) {
                        setOpen(true);
                      }
                    }}
                    key={re}
                    onClose={() => setOpen(false)}
                    options={dropDownValue}
                    name="staff"
                    isOptionEqualToValue={(option) => option.label}
                    onChange={(event, newValue) => {
                      handleSelectStaff(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(e, value, reason) => {
                      setInputValue(value);

                      if (!value) {
                        setOpen(false);
                      }
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  {formErrors.staff && (
                    <p className="errormsg">{formErrors.staff}</p>
                  )}
                </div>
              </div>

              <div className="row text-start ">
                <div className="form-group">
                  <label>
                    {mode === "en" ? "Status" : "स्थिति"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    value={formValues.status}
                    name="status"
                    onChange={handleChange}
                  >
                    <option disabled value="" selected>
                      {mode === "en" ? "Select Status" : "स्थिति चयन गर्नुहोस्"}
                    </option>
                    <option value="I">
                      {mode === "en" ? "Check In" : "चेक इन"}
                    </option>
                    <option value="O">
                      {mode === "en" ? "Check Out" : "चेक आउट"}
                    </option>
                  </select>
                  {formErrors.status && (
                    <p className="errormsg">{formErrors.status}</p>
                  )}
                </div>
              </div>

              {/* <div className="row text-start mt-1">
                <div className="form-group">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="fieldWork"
                      name="fieldWork"
                      checked={fieldWork}
                      onChange={() => setFieldWork(!fieldWork)}
                    />
                    <label
                      style={{ fontSize: "12px", cursor: "pointer" }}
                      class="form-check-label"
                      htmlFor="fieldWork"
                    >
                      Work From Home
                    </label>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <div className="new-footer">
            <div className="row  mt-1 mb-1">
              <div>
                <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={handleSubmit}
                >
                  {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
