import "./App.css";
import "../src/components/job page/jobListPage.css";
import { useContext, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthContext from "./components/context/auth-context";
import Login from "./components/login";
import Layout from "./components/layout/layout";
import LeaveNoteList from "./components/leaveNotes/leaveNoteList";
import Notification from "./components/notification/notification";
import Summary from "./components/attendance/summary";
import Profile from "./components/profile/profile";
import ReportAttendance from "./components/attendance/reportAttendance";
import Holiday from "./components/holiday/holiday";
import NoMatchPage from "./components/pageNoFound";
import Dashboard from "./components/dashboard/Dashboard";

// adminpanel
import AAttendanceReport from "./components/adminPanel/attendance/attendanceReport";
import AdminSummary from "./components/adminPanel/attendance/summary";
import StaffState from "./components/adminPanel/organization/staffState/StaffState";
import Department from "./components/adminPanel/organization/department/Department";
import Subdepartment from "./components/adminPanel/organization/subdepartment/Subdepartment";
import Designation from "./components/adminPanel/organization/designation/Designation";
import Shift from "./components/adminPanel/organization/shift/Shift";
import Staff from "./components/adminPanel/organization/staff/Staff";
import AdminHoliday from "./components/adminPanel/organization/holiday/Holiday";
import Branch from "./components/adminPanel/organization/branch/Branch";
import Fiscal from "./components/adminPanel/organization/fiscal/Fiscal";
import Bank from "./components/adminPanel/organization/bank/Bank";
import Document from "./components/adminPanel/organization/document/Document";
import Leave from "./components/adminPanel/organization/leaveType/Leave";
import JobInformation from "./components/adminPanel/organization/jobInformation/JobInformation";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Complain from "./components/Complain";
import UserNotification from "./components/notification/UserNotification";
import AttendanceMain from "./components/adminPanel/attendance/attendance";
import LeaveReport from "./components/adminPanel/leaveReport/LeaveReport";
import AttendanceRoute from "./components/adminPanel/attendance/AttendanceRoute";
import AttendanceState from "./components/adminPanel/attendanceState/AttendanceState";
import Job from "./components/adminPanel/job/Job";
import JobState from "./components/adminPanel/jobState/JobState";
import Index from "./components";
import LayoutPage from "./components/job page/LayoutPage";
import JobList from "./components/job page/JobList";
import JobPage from "./components/job page/JobPage";
import ShiftRoaster from "./components/adminPanel/organization/shift roaster/ShiftRoaster";
import NotificationState from "./components/notificationState/NotificationState";

// Khata -----
import Company from "./components/khata/setup/company/Company";
import AccountGroup from "./components/khata/setup/account group/AccountGroup";
import Ledger from "./components/khata/setup/ledger/Ledger";
import Agent from "./components/khata/setup/agent/Agent";
import Area from "./components/khata/setup/area/Area";
import StockVoucher from "./components/khata/entry/stock voucher/StockVoucher";
import Voucher from "./components/khata/entry/voucher/Voucher";
import LedgerReport from "./components/khata/report/ledger report/LedgerReport";
import StockLedger from "./components/khata/report/stock ledger/StockLedger";
import TrialBalance from "./components/khata/report/trial balance/TrialBalance";
import DayBook from "./components/khata/report/day book/DayBook";
import AllowanceDeduction from "./components/khata/payroll/AllowanceDeduction";
import SalaryFacilities from "./components/khata/payroll/salaryFacilities/SalaryFacilities";
import GenerateSalary from "./components/khata/payroll/generateSalary/GenerateSalary";

// Khata State ---
import CompanyState from "./components/khata/setup/company/companyState/CompanyState";
import AccountGroupState from "./components/khata/setup/account group/accountGroupState/AccountGroupState";
import LedgerState from "./components/khata/setup/ledger/ledgerState/LedgerState";
import AgentState from "./components/khata/setup/agent/agentState/AgentState";
import AreaState from "./components/khata/setup/area/areaState/AreaState";
import AllowanceState from "./components/khata/payroll/payrollState/AllowanceState";
import DeductionState from "./components/khata/payroll/payrollState/DeductionState";
import FilteredJobList from "./components/job page/FilteredJobList";
import TaxCalculator from "./components/taxCalculator/TaxCalculator";
import ProvidentFund from "./components/khata/payroll/ProvidentFund/ProvidentFund";
import PersonalSlip from "./components/khata/payroll/personalSlip/PersonalSlip";
import PersonalSlipState from "./components/khata/payroll/payrollState/PersonalSlipState";
import Absent from "./components/khata/payroll/absent/Absent";
import LeaveNote from "./components/adminPanel/leaveReport/LeaveNote";
import TodoState from "./components/todoState/TodoState";
import Todo from "./components/todo/Todo";
import PayrollReport from "./components/khata/payroll/payrollReport/PayrollReport";
import Helpdesk from "./components/helpdesk/Helpdesk";
import HelpdeskState from "./components/helpdeskState/HelpdeskState";
import CandidateState from "./components/adminPanel/CandidateContext/CandidateState";
import EliteJobState from "./components/adminPanel/EliteJobContext/EliteJobState";
import EliteJob from "./components/adminPanel/EliteJob/EliteJob";
import LeaveBalance from "./components/adminPanel/leaveReport/LeaveBalance";
import Resignation from "./components/adminPanel/organization/resignation/Resignation";
import Promotion from "./components/adminPanel/organization/promotion/Promotion";

function App() {
  const { User } = useContext(AuthContext);
  const main_color = "#0049ae";

  //
  useEffect(() => {
    document.documentElement.style.setProperty("--main-color", main_color);
  }, []);

  return (
    <>
      <Routes>
        {!User && <Route path="/login" element={<Login />} />}
        {User && <Route path="/login" element={<Navigate to="/dashboard" />} />}
        {!User && <Route path="/" element={<Index />} />}
        {User && <Route path="/" element={<Navigate to="/dashboard" />} />}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/complain" element={<Complain />} />
      </Routes>
      {!User && (
        <Routes>
          <Route path="/jobList" element={<JobList />} />
          <Route path="/jobPage/:id" element={<JobPage />} />
          <Route path="/filtered-job" element={<FilteredJobList />} />
          <Route path="/tax-calculator" element={<TaxCalculator />} />
        </Routes>
      )}

      <div className="App">
        {User && (
          <>
            <Layout>
              <EliteJobState>
                <CandidateState>
                  <HelpdeskState>
                    <TodoState>
                      <PersonalSlipState>
                        <DeductionState>
                          <AllowanceState>
                            <AreaState>
                              <CompanyState>
                                <AgentState>
                                  <LedgerState>
                                    <AccountGroupState>
                                      <JobState>
                                        <StaffState>
                                          <AttendanceState>
                                            <NotificationState>
                                              <Routes>
                                                <Route
                                                  index
                                                  path="/dashboard"
                                                  element={<Dashboard />}
                                                />

                                                <Route
                                                  path="profile"
                                                  element={<Profile />}
                                                />
                                                <Route
                                                  path="notification"
                                                  element={<Notification />}
                                                />
                                                {/* <Route
                        path="user-notification"
                        element={<UserNotification />}
                      /> */}
                                                <Route
                                                  path="attendance-summary"
                                                  element={<Summary />}
                                                />
                                                <Route
                                                  path="report-attendance"
                                                  element={<ReportAttendance />}
                                                />
                                                {/* <Route path="holiday" element={<Holiday />} /> */}

                                                <Route
                                                  path=""
                                                  element={<LeaveNoteList />}
                                                />

                                                <Route
                                                  path="admin-attendance"
                                                  element={<AttendanceMain />}
                                                />

                                                <Route
                                                  path="/admin-department"
                                                  element={<Department />}
                                                />
                                                {/* <Route
                        path="/admin-subdepartment"
                        element={<Subdepartment />}
                      /> */}
                                                <Route
                                                  path="/admin-designation"
                                                  element={<Designation />}
                                                />

                                                <Route
                                                  path="/admin-shift"
                                                  element={<Shift />}
                                                />
                                                <Route
                                                  path="/admin-shift-roaster"
                                                  element={<ShiftRoaster />}
                                                />
                                                <Route
                                                  path="/admin-staff"
                                                  element={<Staff />}
                                                />
                                                <Route
                                                  path="/admin-promotion"
                                                  element={<Promotion />}
                                                />
                                                <Route
                                                  path="/admin-resignation"
                                                  element={<Resignation />}
                                                />
                                                {/* <Route
                                                  path="/admin-transfer"
                                                  element={<Staff />}
                                                /> */}
                                                <Route
                                                  path="/admin-holiday"
                                                  element={<AdminHoliday />}
                                                />
                                                <Route
                                                  path="/admin-branch"
                                                  element={<Branch />}
                                                />
                                                <Route
                                                  path="/admin-fiscal"
                                                  element={<Fiscal />}
                                                />
                                                <Route
                                                  path="/admin-bank"
                                                  element={<Bank />}
                                                />
                                                <Route
                                                  path="/admin-document"
                                                  element={<Document />}
                                                />
                                                <Route
                                                  path="/admin-leave-type"
                                                  element={<Leave />}
                                                />

                                                <Route
                                                  path="/privacy-policy"
                                                  element={<PrivacyPolicy />}
                                                />

                                                {/* <Route
                        path="/admin-job-information"
                        element={<JobInformation />}
                      /> */}

                                                <Route
                                                  path="/leave-report"
                                                  element={<LeaveReport />}
                                                />
                                                <Route
                                                  path="/leave-notes"
                                                  element={<LeaveNote />}
                                                />
                                                <Route
                                                  path="/leave-balance"
                                                  element={<LeaveBalance />}
                                                />

                                                <Route
                                                  path="/job"
                                                  element={<EliteJob />}
                                                />

                                                {/* -----------KHATA starts ---------- */}

                                                {/* ---- set up ---- */}
                                                <Route
                                                  path="company"
                                                  element={<Company />}
                                                />
                                                <Route
                                                  path="account-group"
                                                  element={<AccountGroup />}
                                                />
                                                <Route
                                                  path="ledger"
                                                  element={<Ledger />}
                                                />
                                                <Route
                                                  path="agent"
                                                  element={<Agent />}
                                                />
                                                <Route
                                                  path="area"
                                                  element={<Area />}
                                                />

                                                {/* ---- entry ---- */}
                                                <Route
                                                  path="voucher"
                                                  element={<Voucher />}
                                                />
                                                <Route
                                                  path="stock-voucher"
                                                  element={<StockVoucher />}
                                                />

                                                {/* ---- report ---- */}
                                                <Route
                                                  path="ledger-report"
                                                  element={<LedgerReport />}
                                                />
                                                <Route
                                                  path="stock-ledger"
                                                  element={<StockLedger />}
                                                />
                                                <Route
                                                  path="trial-balance"
                                                  element={<TrialBalance />}
                                                />
                                                <Route
                                                  path="day-book"
                                                  element={<DayBook />}
                                                />

                                                {/* ---- pay roll ---- */}
                                                <Route
                                                  path="addition-deduction"
                                                  element={
                                                    <AllowanceDeduction />
                                                  }
                                                />
                                                <Route
                                                  path="salary-facilities"
                                                  element={<SalaryFacilities />}
                                                />
                                                <Route
                                                  path="generate-salary"
                                                  element={<GenerateSalary />}
                                                />
                                                <Route
                                                  path="provident-fund"
                                                  element={<ProvidentFund />}
                                                />
                                                <Route
                                                  path="personal-slip"
                                                  element={<PersonalSlip />}
                                                />
                                                <Route
                                                  path="absent"
                                                  element={<Absent />}
                                                />
                                                <Route
                                                  path="payroll-report"
                                                  element={<PayrollReport />}
                                                />

                                                {/* -----------KHATA ends---------- */}

                                                <Route
                                                  path="todo"
                                                  element={<Todo />}
                                                />
                                                <Route
                                                  path="help-desk"
                                                  element={<Helpdesk />}
                                                />

                                                {/* Page Not Found */}
                                                <Route
                                                  path="*"
                                                  element={<NoMatchPage />}
                                                />
                                              </Routes>
                                            </NotificationState>
                                          </AttendanceState>
                                        </StaffState>
                                      </JobState>
                                    </AccountGroupState>
                                  </LedgerState>
                                </AgentState>
                              </CompanyState>
                            </AreaState>
                          </AllowanceState>
                        </DeductionState>
                      </PersonalSlipState>
                    </TodoState>
                  </HelpdeskState>
                </CandidateState>
              </EliteJobState>
            </Layout>
          </>
        )}
      </div>
    </>
  );
}

export default App;
