import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import AuthContext from "../../../context/auth-context";
import UpperbarContext from "../../../context/upperbar-context";
import Toast from "../../../Toast";
import "../ledger report/ledger.css";
export default function StockLedger() {
  const { User } = useContext(AuthContext);

  //   const searchInput = useRef("");
  const { fiscalYear, todayDate, appURL, userDetails, darkText } =
    useContext(UpperbarContext);

  const data = [
    {
      Date: "2022-02-02",
      Particulars: "parti",
      VN: "2",
      Debit: "2323",
      Credit: "0",
      DrCr: "DR",
      Balance: "999",
    },
    {
      Date: "2022-02-02",
      Particulars: "parti",
      VN: "2",
      Debit: "2323",
      Credit: "0",
      DrCr: "DR",
      Balance: "999",
    },
    {
      Date: "2022-02-02",
      Particulars: "parti",
      VN: "2",
      Debit: "2323",
      Credit: "0",
      DrCr: "DR",
      Balance: "999",
    },
    {
      Date: "2022-02-02",
      Particulars: "parti",
      VN: "2",
      Debit: "2323",
      Credit: "0",
      DrCr: "DR",
      Balance: "999",
    },
  ];

  const columns = [
    {
      name: "Date",
      grow: 0,
      //   center: true,
      width: "150px",
      cell: (row) => row.Date,
    },
    {
      name: "Particulars",
      // center: true,
      // grow: 0,

      selector: (row) => row.Particulars,
    },
    {
      name: "V.N",
      center: true,
      width: "70px",
      // grow: 0,
      selector: (row) => row.VN,
    },
    {
      name: () => {
        return (
          <>
            <div>hello</div>
          </>
        );
      },
      // grow: 0,
      center: true,
      width: "150px",
      selector: (row) => {
        return (
          <>
            <div>{row.Debit}</div>
          </>
        );
      },
    },
    {
      name: "Goods Out",
      // grow: 0,
      center: true,
      width: "150px",
      selector: (row) => row.Credit,
    },

    {
      name: "Balance",
      // grow: 0,
      //   center: true,
      width: "150px",
      right: true,
      selector: (row) => row.Balance,
    },
  ];

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper ps-4 pe-4">
        <div className="row ">
          <div className="page-header">
            <div className="text-start  page-title" style={darkText}>
              Stock Ledger
            </div>
            <div className="page-date">
              <div className="sec-content" style={darkText}>
                <FaRegCalendarAlt /> {todayDate} <span>|</span> Fiscal Year :{" "}
                {fiscalYear.StartDate}
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>

        <>
          <div className="sec-dataTable">
            {/* <div className="upper-dataTbl">
              <div className="uk-flex uk-flex-wrap uk-flex-between uk-flex-middle mb-3">
                <div className="uk-flex uk-flex-wrap me-5">
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      Select Ledger
                    </label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="ledger"
                      //   value={voucherData}
                      //   onChange={handleVoucher}
                      className="form-select form-select-sm"
                    >
                      <option
                        value=""
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        Select Ledger
                      </option>
                      <option value="1">Ledger</option>
                      <option value="2">Ledger</option>
                      <option value="3">Ledger</option>
                    </select>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="mt-3">
              <table className="uk-table">
                <tr>
                  <td rowspan="2" className="rdt_TableHeadRow tableHead">
                    Date
                  </td>
                  <td rowspan="2" className="rdt_TableHeadRow tableHead">
                    Particulars
                  </td>
                  <td
                    rowspan="2"
                    className="rdt_TableHeadRow tableHead text-center"
                  >
                    V.N
                  </td>
                  <td
                    colspan="3"
                    className="rdt_TableHeadRow tableHead text-center"
                  >
                    Goods in
                  </td>
                  <td
                    colspan="3"
                    className="rdt_TableHeadRow tableHead text-center"
                  >
                    Goods out
                  </td>
                  <td
                    rowspan="2"
                    className="rdt_TableHeadRow tableHead text-end"
                  >
                    Balance
                  </td>
                </tr>
                <tr>
                  <td className="rdt_TableHeadRow tableHead text-center">
                    Qty
                  </td>
                  <td className="rdt_TableHeadRow tableHead text-center">
                    Rate
                  </td>
                  <td className="rdt_TableHeadRow tableHead text-center">
                    Amount
                  </td>
                  <td className="rdt_TableHeadRow tableHead text-center">
                    Qty
                  </td>
                  <td className="rdt_TableHeadRow tableHead text-center">
                    Rate
                  </td>
                  <td className="rdt_TableHeadRow tableHead text-center">
                    Amount
                  </td>
                </tr>

                <tr>
                  <td className="rdt_TableCell tablecell ">2022-02-02</td>
                  <td className="rdt_TableCell tablecell ">parti</td>
                  <td className="rdt_TableCell tablecell  text-center">2</td>
                  <td className="rdt_TableCell tablecell text-center">2</td>
                  <td className="rdt_TableCell tablecell text-center">2</td>
                  <td className="rdt_TableCell tablecell text-center">999</td>
                  <td className="rdt_TableCell tablecell text-center">2</td>
                  <td className="rdt_TableCell tablecell text-center">2</td>
                  <td className="rdt_TableCell tablecell text-center">999</td>
                  <td className="rdt_TableCell tablecell text-end">999</td>
                </tr>

                <tr>
                  <td className="rdt_TableCell tablecell ">2022-02-02</td>
                  <td className="rdt_TableCell tablecell ">parti</td>
                  <td className="rdt_TableCell tablecell  text-center">2</td>
                  <td className="rdt_TableCell tablecell text-center">2</td>
                  <td className="rdt_TableCell tablecell text-center">2</td>
                  <td className="rdt_TableCell tablecell text-center">999</td>
                  <td className="rdt_TableCell tablecell text-center">2</td>
                  <td className="rdt_TableCell tablecell text-center">2</td>
                  <td className="rdt_TableCell tablecell text-center">999</td>
                  <td className="rdt_TableCell tablecell text-end">999</td>
                </tr>

                <tr>
                  <td className="rdt_TableCell tablecell ">2022-02-02</td>
                  <td className="rdt_TableCell tablecell ">parti</td>
                  <td className="rdt_TableCell tablecell  text-center">2</td>
                  <td className="rdt_TableCell tablecell text-center">2</td>
                  <td className="rdt_TableCell tablecell text-center">2</td>
                  <td className="rdt_TableCell tablecell text-center">999</td>
                  <td className="rdt_TableCell tablecell text-center">2</td>
                  <td className="rdt_TableCell tablecell text-center">2</td>
                  <td className="rdt_TableCell tablecell text-center">999</td>
                  <td className="rdt_TableCell tablecell text-end">999</td>
                </tr>

                <tr>
                  <td className="rdt_TableCell tablecell ">2022-02-02</td>
                  <td className="rdt_TableCell tablecell ">parti</td>
                  <td className="rdt_TableCell tablecell  text-center">2</td>
                  <td className="rdt_TableCell tablecell text-center">2</td>
                  <td className="rdt_TableCell tablecell text-center">2</td>
                  <td className="rdt_TableCell tablecell text-center">999</td>
                  <td className="rdt_TableCell tablecell text-center">2</td>
                  <td className="rdt_TableCell tablecell text-center">2</td>
                  <td className="rdt_TableCell tablecell text-center">999</td>
                  <td className="rdt_TableCell tablecell text-end">999</td>
                </tr>
              </table>
            </div>
          </div>
        </>
      </div>
    </>
  );
}
