import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "../../images/CloseIcon.svg";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import { Fetchdata } from "../hooks/getData";
import AuthContext from "../context/auth-context";
import NepaliDate from "nepali-date-converter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import UpperbarContext from "../context/upperbar-context";
import $ from "jquery";
import TodoContext from "../todoState/TodoContext";
import { englishToNepaliNumber } from "nepali-number";
import { GetNepaliDate } from "../hooks/dateConvertor";

export default function EditTodoPop({ setEditPop, editPop, DFlag, staffList }) {
  const {
    todoValues,
    setTodoValues,
    todoErrors,
    setTodoErrors,
    todoEditSubmit,
    setTodoEditSubmit,
    editTodo,
  } = useContext(TodoContext);

  const { User } = useContext(AuthContext);
  const { appURL, sidePanelBg, mainBg, mode } = useContext(UpperbarContext);

  //

  useEffect(() => {
    if (editPop) {
      $(".editTodoPopBg").fadeIn(500);
      $(".editTodoPop").slideDown(500);
    }
  }, [editPop]);

  const handleChange = (e) => {
    e.preventDefault();
    const target = e.target;
    const name = target.name;
    const value = target.value;

    setTodoValues({ ...todoValues, [name]: value });
  };

  const handleDueDate = ({ adDate }) => {
    let name = "dueDate";
    setTodoValues({ ...todoValues, [name]: adDate });
  };

  const closePopUp = (e) => {
    setEditPop(false);
    $(".editTodoPopBg").fadeOut(500);
    $(".editTodoPop").slideUp(500);
    setTodoErrors({});
    setTodoEditSubmit(false);
  };

  const validate = (values) => {
    const errors = {};

    if (!values.staff) {
      errors.staff = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.title) {
      errors.title = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.description) {
      errors.description = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.dueDate) {
      errors.dueDate = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.priority) {
      errors.priority = mode === "en" ? "Required" : "आवश्यक छ";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setTodoErrors(validate(todoValues));
    setTodoEditSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(todoErrors).length === 0 && todoEditSubmit) {
      editTodo(todoValues);

      setTodoEditSubmit(false);
    }
  }, [todoErrors]);

  //

  return (
    <>
      {/* <Toast /> */}
      <div className="container newpopup-bg editTodoPopBg">
        <div className="newpopup editTodoPop " style={mainBg}>
          <div className="newpopup-head ps-0 pe-0" style={sidePanelBg}>
            <div className="popUpTitle">
              {" "}
              {mode === "en" ? "Add Todo" : "काम थप्नुहोस्"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div
            className="newpopup-body ps-3 pe-3"
            style={{ maxHeight: "45vh" }}
          >
            <div className="form-padding">
              <div className="row text-start ">
                <div className="form-group">
                  <label htmlFor="staff" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Assigned To" : "खटाइएका"}{" "}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    name="staff"
                    value={todoValues.staff}
                    onChange={handleChange}
                  >
                    <option disabled value="" selected>
                      {mode === "en" ? "Assigned To" : "खटाइएका"}
                    </option>
                    {staffList.map((item) => (
                      <option key={item.UserID} value={item.UserID}>
                        {item.FullName}
                      </option>
                    ))}
                  </select>
                  {todoErrors.staff && (
                    <p className="errormsg">{todoErrors.staff}</p>
                  )}
                </div>
              </div>
              <div className="row text-start mt-3 ">
                <div className="col">
                  <label htmlFor="title">
                    {mode === "en" ? "Title" : "शीर्षक"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="title"
                    type="text"
                    name="title"
                    className="form-control form-control-sm "
                    onChange={handleChange}
                    value={todoValues.title}
                  />
                  {todoErrors.title && (
                    <p className="errormsg">{todoErrors.title}</p>
                  )}
                </div>
              </div>
              <div className="row text-start ">
                <div className="form-group">
                  <label htmlFor="description">
                    {mode === "en" ? "Description" : "विवरण"}{" "}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <textarea
                    id="description"
                    value={todoValues.description}
                    onChange={handleChange}
                    style={{ fontSize: "13px" }}
                    class="form-control ps-2"
                    name="description"
                    rows="3"
                    cols="12"
                    placeholder={mode === "en" ? "Description" : "विवरण"}
                  ></textarea>
                  {todoErrors.description && (
                    <p className="errormsg">{todoErrors.description}</p>
                  )}
                </div>
              </div>

              <div className="row text-start mt-3 ">
                <label className="text-start mb-1" style={{ fontSize: "12px" }}>
                  {mode === "en" ? "Due Date" : "अन्तिम मिति"}
                  <sup style={{ color: "red" }}>*</sup>
                </label>
                {DFlag === "N" ? (
                  <Calendar
                    className="form-control form-control-sm pt-0 pb-0 "
                    dateFormat="YYYY/MM/DD"
                    // defaultDate="2010/01/01"
                    theme="default"
                    language="en"
                    value={todoValues.dueDate}
                    onChange={handleDueDate}
                    name="dueDate"
                    // key={re}
                    hideDefaultValue={true}
                    placeholder={GetNepaliDate(todoValues.dueDate)}
                  />
                ) : (
                  <input
                    type="date"
                    value={todoValues.dueDate}
                    placeholder="Select a Date"
                    className="form-control form-control-sm "
                    name="dueDate"
                    onChange={handleChange}
                  />
                )}
                {todoErrors.pubDate && (
                  <p className="errormsg">{todoErrors.pubDate}</p>
                )}
              </div>
              <div className="row text-start mt-3 ">
                <label className="text-start mb-1" style={{ fontSize: "12px" }}>
                  {mode === "en"
                    ? "Select Priority"
                    : "प्राथमिकता चयन गर्नुहोस्"}
                  <sup style={{ color: "red" }}>*</sup>
                </label>
                <select
                  style={{ fontSize: "11px", marginLeft: "5px" }}
                  name="priority"
                  className="form-select form-select-sm"
                  aria-label="Default select example "
                  value={todoValues.priority}
                  onChange={handleChange}
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    {mode === "en"
                      ? "Select Priority"
                      : "प्राथमिकता चयन गर्नुहोस्"}
                  </option>

                  <option value="U">
                    {mode === "en" ? "Urgent" : "खोल्नुहोस्"}
                  </option>
                  <option value="H">
                    {mode === "en" ? "High" : "बन्द गर्नुहोस्"}
                  </option>
                  <option value="N">
                    {mode === "en" ? "Normal" : "बन्द गर्नुहोस्"}
                  </option>
                  <option value="L">
                    {mode === "en" ? "Low" : "बन्द गर्नुहोस्"}
                  </option>
                </select>
                {todoErrors.priority && (
                  <p className="errormsg">{todoErrors.priority}</p>
                )}
              </div>
            </div>
          </div>

          <div className="new-footer">
            <div className="row  mt-1 mb-1">
              <div>
                <button
                  type="button"
                  className="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={handleSubmit}
                >
                  {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
