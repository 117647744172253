import React, { useContext, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import EliteJobDashboard from "./EliteJobDashboard";
import EliteJobLogin from "./EliteJobLogin";
import AuthContext from "../../context/auth-context";
import UpperbarContext from "../../context/upperbar-context";
import { Fetchdata } from "../../hooks/getData";
import Spinner from "../../loading/spinner";

const EliteJob = () => {
  const { appURL } = useContext(UpperbarContext);
  const { User } = useContext(AuthContext);
  const [checkCompany, setCheckCompany] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getCompany();
  }, []);

  const getCompany = () => {
    const params = {
      ComID: User.ComID,
      Flag: "CK",
      Type: "POST",
      FetchURL: `${appURL}api/job/com-sync`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        setCheckCompany(true);
        setLoading(false);
      } else {
        setCheckCompany(false);
        setLoading(false);
      }
    });
  };

  // to check if there is a user
  // const [checkUser, setCheckUser] = useState(false);
  // useEffect(() => {
  //   if (checkCompany) {
  //     getUser();
  //   }
  // }, [checkUser, checkCompany]);

  // const getUser = () => {
  //   const params = {
  //      ComID: User.ComID,
  //     Flag: "CK",
  //     UserName: userinfo.UserName,
  //     Type: "POST",
  //     FetchURL: `${appURL}api/job/user-sync`,
  //   };

  //   Fetchdata(params).then(function (result) {
  //     if (result.StatusCode === 200) {
  //       setCheckUser(true);
  //     } else {
  //       setCheckUser(false);
  //     }
  //   });
  // };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : checkCompany ? (
        // && checkUser
        <EliteJobDashboard />
      ) : (
        <EliteJobLogin
          // checkUser={checkUser}
          // setCheckUser={setCheckUser}
          checkCompany={checkCompany}
          setCheckCompany={setCheckCompany}
        />
      )}
    </>
  );
};

export default EliteJob;
