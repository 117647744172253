import React, { useContext, useEffect, useState, useRef } from "react";
import PopUp from "../attendance/popUp";
import AuthContext from "../context/auth-context";
import UpperbarContext from "../context/upperbar-context";
import { GetToDate, GetTwelveHourFormatTime } from "../hooks/dateConvertor";
import { Fetchdata } from "../hooks/getData";
import Spinner from "../loading/spinner";
import { ToastContainer, toast } from "react-toastify";
import { AiOutlineSearch } from "react-icons/ai";

const TodayAttendance = () => {
  const { User } = useContext(AuthContext);
  const { appURL, userDetails, mode } = useContext(UpperbarContext);

  const [attendanceList, setAttendanceList] = useState([]);
  const [clientAttendanceList, setClientAttendanceList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [DFlag, setDFlag] = useState("N");
  const date = GetToDate();

  const [originalList, setOriginalList] = useState(null);

  const searchInput = useRef("");

  const [approve, setApprove] = useState("");
  const [reject, setReject] = useState("");
  const [staffId, setStaffId] = useState(null);
  const [attenDate, setAttenDate] = useState("");
  const [sFlag, setSFlag] = useState("");
  const [popUp, setPopUp] = useState(false);

  useEffect(() => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      DepartmentID: -1,
      Flag: "D",
      DFlag: "N",
      Value: date,
      FetchURL: `${appURL}api/admin/atten-report`,
      Type: "POST",
    };

    Fetchdata(dataForm)
      .then(function (result) {
        if (result.StatusCode === 200) {
          const postResult = result.Values;
          setAttendanceList(postResult);
          setOriginalList(postResult);
          setLoading(false);
        } else {
          setAttendanceList([]);
          setOriginalList([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        setAttendanceList([]);
        setOriginalList([]);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (Object.keys(User).length) {
      const dataForm = {
        Type: "GET",
        FetchURL: `${appURL}api/attendance-report?ComID=${User.CompanyId}&UserID=${User.UID}&Flag=D&DFlag=${DFlag}&From=${date}&To=${date}`,
      };

      Fetchdata(dataForm)
        .then(function (result) {
          if (result.StatusCode === 200) {
            const postResult = result.AttenRepMonth ? result.AttenRepMonth : "";
            setClientAttendanceList(postResult);
            setLoading(false);
          } else {
            setClientAttendanceList([]);
            setLoading(false);
          }
        })
        .catch((err) => {
          setClientAttendanceList([]);
          setLoading(false);
        });
    }
  }, []);

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();

    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["StaffName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setAttendanceList(srchResult);
      } else {
        setAttendanceList({});
      }
    } else {
      setAttendanceList(originalList);
    }
  };

  const handleAttendance = (data) => {
    setApprove(data.approve);
    setReject(data.reject);
    setSFlag(data.sFlag);
    setStaffId(data.StaffID);
    setAttenDate(data.AttenDate);
    setPopUp(true);
  };

  const atten = () => {
    const dataForm = {
      ComID: User.CompanyId,
      StaffID: staffId,
      UserID: User.UID,
      Flag: "ULS",
      AttenDate: attenDate,
      DFlag: "N",
      AttenStatus: sFlag === "A" ? "1" : "2",
      BranchID: User.BranchId,
      FiscalID: User.FiscalId,
      FetchURL: `${appURL}api/admin/atten`,
      Type: "POST",
    };

    Fetchdata(dataForm)
      .then(function (result) {
        if (result.StatusCode === 200) {
          setPopUp(false);
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      })
      .catch((err) => {
        toast.error("Error: " + err, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      });
  };

  return (
    <>
      <div className="uk-flex uk-flex-right sticky-search">
        <div class="uk-search uk-search-default">
          <AiOutlineSearch className="search-icon" />
          <input
            placeholder={mode === "en" ? "Search" : "खोजी गर्नुहोस्"}
            ref={searchInput}
            type="text"
            className="form-control form-control-sm searchField"
            onChange={searchHandler}
          />
        </div>
      </div>
      <div
        className="attendance mt-3 uk-position-relative"
        // style={{ minHeight: "100px" }}
      >
        {userDetails.IsManager !== 0 ? (
          <>
            {attendanceList.length > 0 ? (
              <table className="uk-table uk-table-striped mt-3">
                <thead className="sticky-head">
                  <tr>
                    <td className="fw-bold">
                      {mode === "en" ? "Name" : "नाम"}
                    </td>
                    <td className="fw-bold">
                      {mode === "en" ? "Check In" : "चेक इन"}
                    </td>
                    <td className="fw-bold">
                      {mode === "en" ? "Check Out" : "चेक आउट"}
                    </td>
                    <td className="fw-bold text-center">
                      {mode === "en" ? "Status" : "स्थिति"}
                    </td>
                  </tr>
                </thead>

                {loading ? (
                  <Spinner />
                ) : (
                  <tbody>
                    {attendanceList.map((props) => {
                      const {
                        StaffName,
                        LeaveType,
                        CheckIn,
                        CheckOut,
                        LeaveID,
                        DayType,
                      } = props;

                      return (
                        <tr style={{ borderLeft: "1px solid transparent" }}>
                          <td style={{ minWidth: "150px" }}>
                            {StaffName === null ? (
                              <span>
                                {mode === "en"
                                  ? "Not mentioned"
                                  : "उल्लेख गरिएको छैन"}
                              </span>
                            ) : (
                              StaffName
                            )}
                          </td>
                          <td style={{ minWidth: "150px" }}>
                            {LeaveType === "N" ? (
                              CheckIn === null && CheckOut === null ? (
                                <span>
                                  {mode === "en"
                                    ? "No Check In"
                                    : "कोई चेक इन नहीं"}
                                </span>
                              ) : CheckIn && CheckOut === null ? (
                                CheckIn
                              ) : CheckIn && CheckOut ? (
                                CheckIn
                              ) : null
                            ) : LeaveType === "F" ? (
                              <span>
                                {mode === "en"
                                  ? "No Check In"
                                  : "कोई चेक इन नहीं"}
                              </span>
                            ) : LeaveType === "H" ? (
                              CheckIn
                            ) : null}
                          </td>
                          <td style={{ minWidth: "150px" }}>
                            {LeaveType === "N" ? (
                              CheckIn === null && CheckOut === null ? (
                                <span>
                                  {mode === "en"
                                    ? "No Check Out"
                                    : "चेक आउट नहीं"}
                                </span>
                              ) : CheckIn && CheckOut === null ? (
                                <span>
                                  {mode === "en"
                                    ? "No Check Out"
                                    : "चेक आउट नहीं"}
                                </span>
                              ) : CheckIn && CheckOut ? (
                                CheckOut
                              ) : null
                            ) : LeaveType === "F" ? (
                              <span>
                                {mode === "en"
                                  ? "No Check Out"
                                  : "चेक आउट नहीं"}
                              </span>
                            ) : LeaveType === "H" ? (
                              CheckOut
                            ) : null}
                          </td>
                          <td
                            style={{ minWidth: "100px" }}
                            className="text-center"
                          >
                            {LeaveType === "N" ? (
                              CheckIn === null && CheckOut === null ? (
                                <span
                                  className=" badge rounded-pill bg-danger"
                                  style={{ fontSize: "11px" }}
                                >
                                  {mode === "en" ? "Absent" : "अनुपस्थित"}
                                </span>
                              ) : CheckIn && CheckOut === null ? (
                                <span
                                  className=" badge rounded-pill bg-success"
                                  style={{ fontSize: "11px" }}
                                >
                                  {mode === "en" ? "Present" : "उपस्थित"}
                                </span>
                              ) : CheckIn && CheckOut ? (
                                <span
                                  className=" badge rounded-pill bg-success"
                                  style={{ fontSize: "11px" }}
                                >
                                  {mode === "en" ? "Present" : "उपस्थित"}
                                </span>
                              ) : null
                            ) : LeaveType === "F" ? (
                              <span
                                className=" badge rounded-pill bg-primary"
                                style={{ fontSize: "11px" }}
                              >
                                {mode === "en" ? "On Leave" : "बिदामा"}
                              </span>
                            ) : LeaveType === "H" ? (
                              <span
                                className=" badge rounded-pill bg-primary"
                                style={{ fontSize: "11px" }}
                              >
                                {mode === "en" ? "On Half Leave" : "आधा बिदामा"}
                              </span>
                            ) : null}
                          </td>
                        </tr>
                      );
                    })}
                    {/* {IsVerified === "Verified" && <span>Rejected</span>} */}
                  </tbody>
                )}
              </table>
            ) : (
              <span className="d-block text-center">
                {mode === "en" ? "No Data" : "डाटा उपलब्ध छैन"}
              </span>
            )}
          </>
        ) : (
          <>
            <table className="uk-table uk-table-striped">
              <thead className="sticky-head">
                <tr>
                  <td className="fw-bold">
                    {mode === "en" ? "Check In" : "चेक इन"}
                  </td>
                  <td className="fw-bold">
                    {" "}
                    {mode === "en" ? "Check Out" : "चेक आउट"}
                  </td>
                </tr>
              </thead>
              {loading ? (
                <Spinner />
              ) : (
                <tbody>
                  {clientAttendanceList.map((props) => {
                    const { CheckIn, CheckOut } = props;
                    return (
                      <tr style={{ borderLeft: "1px solid transparent" }}>
                        <td>
                          {CheckIn === null ? (
                            <span>
                              {mode === "en" ? "No Check In" : "चेक इन छैन"}
                            </span>
                          ) : (
                            GetTwelveHourFormatTime(CheckIn)
                          )}
                        </td>
                        <td>
                          {CheckOut === null ? (
                            <span>
                              {" "}
                              {mode === "en" ? "No Check Out" : "चेक आउट छैन"}
                            </span>
                          ) : (
                            GetTwelveHourFormatTime(CheckOut)
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </>
        )}
      </div>

      {popUp && (
        <PopUp
          approve={approve}
          reject={reject}
          atten={atten}
          setPopUp={setPopUp}
        />
      )}
    </>
  );
};

export default TodayAttendance;
