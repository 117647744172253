import React, { useState, useEffect, useContext, useRef } from "react";
import { Fetchdata } from "../hooks/getData";
import { toast } from "react-toastify";
import UpperbarContext from "../context/upperbar-context";
import AuthContext from "../context/auth-context";
import "./taxCalculator.css";
import Footer from "../Footer";
import logo from "../../images/logo.png";
import Navbar from "../Navbar";

const TaxCalculator = () => {
  const { appURL } = useContext(UpperbarContext);
  const { User } = useContext(AuthContext);
  const initialValue = {
    salary: "",
    bonus: "",
    allowance: "",
    others: "",
    providentFund: "",
    citizenFund: "",
    socialFund: "",
    lifeInsurance: "",
    medicalInsurance: "",
  };
  const [taxValue, setTaxValue] = useState(initialValue);
  const [grossSalary, setGrossSalary] = useState("");
  const [taxableIncome, setTaxableIncome] = useState("");
  const [payableTax, setPayableTax] = useState("");
  const [taxSlab, setTaxSlab] = useState("0");
  const [error, setError] = useState(false);


  const initalvalue = {
    options: "2",
  };
  const [optionsValues, setOptionsValues] = useState(initalvalue);

  const handleOnChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setOptionsValues({ ...optionsValues, [name]: value });
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.value;
    setTaxValue({ ...taxValue, [name]: value });
  };

  const handleKeyPress = (event) => {
    // Check if the key pressed is not a number
    if (isNaN(Number(event.key))) {
      event.preventDefault(); // Prevent the character from being entered
    }
  };

  //   API to get tax slab
  const [taxSlabList, setTaxSlabList] = useState([]);

  useEffect(() => {
    taxslablst();
  }, []);

  const taxslablst = () => {
    const dataForm = {
      AuthCode: "r1d3r",
      ComID: "ES45",
      UserID: "-1",
      DFlag: "TAXSLAB",
      Flag: "S",
      CID: "1",
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/ref-values`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setTaxSlabList(postResult);
      } else {
        setTaxSlabList([]);
      }
    });
  };

  const calculateTax = () => {
    const grosssalary =
      optionsValues.options === "2"
        ? parseInt(taxValue.salary ? taxValue.salary : "0")
        : parseInt(taxValue.salary ? taxValue.salary * 12 : "0");
    setGrossSalary(grosssalary);

    const deductedAmount =
      parseInt(taxValue.providentFund ? taxValue.providentFund : "0") +
      parseInt(taxValue.citizenFund ? taxValue.citizenFund : "0") +
      parseInt(taxValue.socialFund ? taxValue.socialFund : "0") +
      parseInt(taxValue.lifeInsurance ? taxValue.lifeInsurance : "0") +
      parseInt(taxValue.medicalInsurance ? taxValue.medicalInsurance : "0");

    const monthlyDeductedAmount =
      parseInt(taxValue.providentFund ? taxValue.providentFund * 12 : "0") +
      parseInt(taxValue.citizenFund ? taxValue.citizenFund * 12 : "0") +
      parseInt(taxValue.socialFund ? taxValue.socialFund * 12 : "0") +
      parseInt(taxValue.lifeInsurance ? taxValue.lifeInsurance : "0") +
      parseInt(taxValue.medicalInsurance ? taxValue.medicalInsurance : "0");


    let grossAmt = grossSalary * 0.333333;
    let deduction;
    if (optionsValues.options === "2") {
      if (grossAmt < deductedAmount) {
        deduction = grossAmt;
      } else {
        deduction = deductedAmount;
      }
    } else if (optionsValues.options === "1") {
      if (grossAmt < monthlyDeductedAmount) {
        deduction = grossAmt;
      } else {
        deduction = monthlyDeductedAmount;
      }
    }

    const taxableAmount =
      optionsValues.options === "2"
        ? parseFloat(
            parseInt(taxValue.salary ? taxValue.salary : "0") +
              parseInt(taxValue.bonus ? taxValue.bonus : "0") +
              parseInt(taxValue.allowance ? taxValue.allowance : "0") +
              parseInt(taxValue.others ? taxValue.others : "0") -
              (deduction ? deduction : "0")
          )
        : parseFloat(
            parseInt(taxValue.salary ? taxValue.salary * 12 : "0") +
              parseInt(taxValue.bonus ? taxValue.bonus : "0") +
              parseInt(taxValue.allowance ? taxValue.allowance * 12 : "0") +
              parseInt(taxValue.others ? taxValue.others * 12 : "0") -
              (deduction ? deduction : "0")
          );
    setTaxableIncome(taxableAmount);

    let slabAmount = 0;
    let taxAmount = 0;
    let remainingAmount = taxableAmount;
    let taxslab = 0;

    for (let i = 0; i < taxSlabList.length; i++) {
      const taxGroup = taxSlabList[i];
      const incomeFrom = parseInt(taxGroup.IncomeFrom);
      const incomeTo = parseInt(taxGroup.IncomeTo);
      const isMarried = taxGroup.isMarried === "Y";
      const taxRate = parseFloat(taxGroup.TaxRate);

      if (taxValue.socialFund) {
        taxSlabList[0].TaxRate = "0";
      } else {
        taxSlabList[0].TaxRate = "1";
      }

      if (taxableAmount >= incomeFrom) {
        if (remainingAmount >= incomeTo - incomeFrom) {
          slabAmount =
            i === 0
              ? taxSlabList[0].IncomeTo
              : incomeTo - taxSlabList[i - 1].IncomeTo;


          taxAmount += (slabAmount * taxRate) / 100;
          remainingAmount -= slabAmount;

        } else {
          taxAmount += (remainingAmount * taxRate) / 100;
          remainingAmount = 0;
          taxslab = taxableAmount ? taxRate : "0";
          setTaxSlab(taxslab);
          break;
        }
      }
    }

    setPayableTax(taxAmount);
  };

  useEffect(() => {
    calculateTax();
  }, [optionsValues, error, taxableIncome]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (taxValue.providentFund > taxValue.salary * 0.2) {
      let name = "providentFund";
      setTaxValue({ ...taxValue, [name]: "" });
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 1000);
      toast.error(
        "Provident Fund must not be greater than 20% of total income.",
        {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        }
      );
    } else if (
      optionsValues.options === "1" &&
      parseInt(taxValue.providentFund * 12) +
        parseInt(taxValue.citizenFund * 12) >
        300000
    ) {
      let name = "providentFund";
      let name1 = "citizenFund";
      setTaxValue({ ...taxValue, [name]: "", [name1]: "" });
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 1000);
      toast.error("Contribution mustn't be greater than 3,00,000 per year.", {
        style: {
          color: "red",
          fontSize: "13px",
        },
        theme: "light",
      });
    } else if (
      optionsValues.options === "2" &&
      parseInt(taxValue.providentFund) + parseInt(taxValue.citizenFund) > 300000
    ) {
      let name = "providentFund";
      let name1 = "citizenFund";

      setTaxValue({ ...taxValue, [name]: "", [name1]: "" });
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 1000);
      toast.error("Contribution mustn't be greater than 3,00,000 per year.", {
        style: {
          color: "red",
          fontSize: "13px",
        },
        theme: "light",
      });
    } else if (
      optionsValues.options === "1" &&
      parseInt(taxValue.socialFund * 12) + parseInt(taxValue.citizenFund * 12) >
        500000
    ) {
      let name = "socialFund";
      let name1 = "citizenFund";
      setTaxValue({ ...taxValue, [name]: "", [name1]: "" });
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 1000);
      toast.error("Contribution mustn't be greater than 5,00,000 per year.", {
        style: {
          color: "red",
          fontSize: "13px",
        },
        theme: "light",
      });
    } else if (
      optionsValues.options === "2" &&
      parseInt(taxValue.socialFund) + parseInt(taxValue.citizenFund) > 500000
    ) {
      let name = "socialFund";
      let name1 = "citizenFund";

      setTaxValue({ ...taxValue, [name]: "", [name1]: "" });
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 1000);
      toast.error("Contribution mustn't be greater than 5,00,000 per year.", {
        style: {
          color: "red",
          fontSize: "13px",
        },
        theme: "light",
      });
    } else if (
      optionsValues.options === "1" &&
      taxValue.citizenFund * 12 > 300000
    ) {
      let name = "citizenFund";
      setTaxValue({ ...taxValue, [name]: "" });
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 1000);
      toast.error("Contribution mustn't be greater than 3,00,000 per year.", {
        style: {
          color: "red",
          fontSize: "13px",
        },
        theme: "light",
      });
    } else if (optionsValues.options === "2" && taxValue.citizenFund > 300000) {
      let name = "citizenFund";
      setTaxValue({ ...taxValue, [name]: "" });
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 1000);
      toast.error("Contribution mustn't be greater than 3,00,000 per year.", {
        style: {
          color: "red",
          fontSize: "13px",
        },
        theme: "light",
      });
    } else if (
      optionsValues.options === "1" &&
      taxValue.socialFund * 12 > 500000
    ) {
      let name = "socialFund";
      setTaxValue({ ...taxValue, [name]: "" });
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 1000);
      toast.error("Contribution mustn't be greater than 5,00,000 per year.", {
        style: {
          color: "red",
          fontSize: "13px",
        },
        theme: "light",
      });
    } else if (optionsValues.options === "2" && taxValue.socialFund > 500000) {
      let name = "socialFund";
      setTaxValue({ ...taxValue, [name]: "" });
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 1000);
      toast.error("Contribution mustn't be greater than 5,00,000 per year.", {
        style: {
          color: "red",
          fontSize: "13px",
        },
        theme: "light",
      });
    } else if (taxValue.providentFund && taxValue.socialFund) {
      let name = "providentFund";
      let name1 = "socialFund";
      setTaxValue({ ...taxValue, [name]: "", [name1]: "" });
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 1000);
      toast.error(
        "You can't contribute on both Provident Fund and Social Security Fund.",
        {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        }
      );
    } else if (taxValue.lifeInsurance > 40000) {
      let name = "lifeInsurance";
      setTaxValue({ ...taxValue, [name]: "40000" });
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 1000);
    } else if (taxValue.medicalInsurance > 20000) {
      let name = "medicalInsurance";
      setTaxValue({ ...taxValue, [name]: "20000" });
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 1000);
    } else {
      calculateTax();
      setError(false);
    }
  };
  const handleReset = (e) => {
    e.preventDefault();
    setTaxValue(initialValue);
    setGrossSalary(0);
    setTaxableIncome(0);
    setPayableTax(0);
    setTaxSlab("0");
  };

  const monthlyTax = payableTax / 12;

  return (
    <>
      {/* <Navbar /> */}
      <div className="uk-container  my-5 tax-calculator">
        <div className="uk-grid ">
          <div className="uk-width-expand ">
            <div className="wrapper">
              <form onClick={handleSubmit} className="taxForm">
                <div className="row">
                  <div className="col-sm-6">
                    <h5>Income</h5>
                    <div className="row">
                      <div className="col-md-7 col-sm-6 col-7">
                        <input
                          type="text"
                          onKeyPress={handleKeyPress}
                          name="salary"
                          className="form-control form-control-sm "
                          onChange={handleChange}
                          placeholder="Salary"
                          value={taxValue.salary}
                          autoComplete="off"
                        />
                      </div>
                      <div className="col-md-5 col-sm-6 col-5">
                        <select
                          onChange={handleOnChange}
                          name="options"
                          class="form-select form-select-sm"
                          value={optionsValues.options}
                        >
                          <option disabled value="" selected>
                            Select options
                          </option>
                          <option value="1">Monthly</option>
                          <option value="2">Yearly</option>
                        </select>
                      </div>
                    </div>

                    <br />
                    <input
                      type="text"
                      onKeyPress={handleKeyPress}
                      name="bonus"
                      className="form-control form-control-sm "
                      onChange={handleChange}
                      placeholder="Bonus"
                      value={taxValue.bonus}
                      autoComplete="off"
                    />

                    <br />
                    <input
                      type="text"
                      onKeyPress={handleKeyPress}
                      name="allowance"
                      className="form-control form-control-sm "
                      onChange={handleChange}
                      placeholder="Allowance"
                      value={taxValue.allowance}
                      autoComplete="off"
                    />
                    <br />
                    <input
                      type="text"
                      onKeyPress={handleKeyPress}
                      name="others"
                      className="form-control form-control-sm "
                      onChange={handleChange}
                      placeholder="Others"
                      value={taxValue.others}
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-sm-6 mt-sm-0 mt-4">
                    <h5>Deduction</h5>
                    <input
                      type="text"
                      onKeyPress={handleKeyPress}
                      name="providentFund"
                      className="form-control form-control-sm "
                      onChange={handleChange}
                      placeholder="Provident fund"
                      value={taxValue.providentFund}
                      autoComplete="off"
                    />
                    <br />
                    <input
                      type="text"
                      onKeyPress={handleKeyPress}
                      name="citizenFund"
                      className="form-control form-control-sm "
                      onChange={handleChange}
                      placeholder="Citizen investment fund"
                      value={taxValue.citizenFund}
                      autoComplete="off"
                    />
                    <br />
                    <input
                      type="text"
                      onKeyPress={handleKeyPress}
                      name="socialFund"
                      className="form-control form-control-sm "
                      onChange={handleChange}
                      placeholder="Social security fund"
                      value={taxValue.socialFund}
                      autoComplete="off"
                    />
                    <br />
                    <input
                      type="text"
                      onKeyPress={handleKeyPress}
                      name="lifeInsurance"
                      className="form-control form-control-sm "
                      onChange={handleChange}
                      placeholder="Life Insurance"
                      value={taxValue.lifeInsurance}
                      autoComplete="off"
                    />
                    <br />
                    <input
                      type="text"
                      onKeyPress={handleKeyPress}
                      name="medicalInsurance"
                      className="form-control form-control-sm "
                      onChange={handleChange}
                      placeholder="Medical Insurance"
                      value={taxValue.medicalInsurance}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <br />
              </form>
              <div className="row  mt-1 mb-1">
                <div>
                  <button
                    className="uk-button approve-btn"
                    onClick={handleSubmit}
                  >
                    Calculate tax
                  </button>
                  <button
                    className="uk-button cancel-btn"
                    onClick={handleReset}
                  >
                    Reset
                  </button>
                </div>
              </div>

              <br />
              <table className="uk-table mb-0 tax-table">
                <thead>
                  <tr>
                    <th className="tableHead">Taxable salary</th>
                    <th className="tableHead">Taxable Amt</th>
                    <th className="tableHead">Tax Rate</th>
                    <th className="tableHead">Tax Liability</th>
                  </tr>
                </thead>

                {taxableIncome !== 0 ? (
                  <tbody>
                    {taxSlabList.map((taxGroup, index) => {
                      const incomeFrom = parseInt(taxGroup.IncomeFrom);
                      const incomeTo = parseInt(taxGroup.IncomeTo);
                      const isMarried = taxGroup.isMarried === "Y";
                      const taxRate = parseFloat(taxGroup.TaxRate);

                      const taxableAmount = taxableIncome;

                      let slabAmount = 0;
                      let taxableAmt = 0;
                      let taxAmt = 0;

                      if (taxableAmount >= incomeFrom) {
                        if (taxableAmount >= incomeTo) {
                          slabAmount =
                            index === 0
                              ? taxSlabList[0].IncomeTo
                              : incomeTo - taxSlabList[index - 1].IncomeTo;

                          taxableAmt = slabAmount;
                        } else {
                          slabAmount =
                            index === 0
                              ? taxableAmount
                              : taxableAmount - taxSlabList[index - 1].IncomeTo;

                          taxableAmt = slabAmount;
                        }

                        taxAmt = (slabAmount * taxRate) / 100;

                        return (
                          <tr key={index}>
                            <td className="tableBody">{taxGroup.GroupName}</td>
                            <td className="tableBody">
                              {taxableAmt.toLocaleString("en-IN")}
                            </td>
                            <td className="tableBody">{taxRate}%</td>
                            <td className="tableBody">
                              {taxAmt.toLocaleString("en-IN")}
                            </td>
                          </tr>
                        );
                      }
                    })}
                    <tr>
                      <td colSpan="3" className="tableFooter text-end">
                        Net Tax Liability (yearly)
                      </td>
                      <td className="tableFooter">
                        {payableTax.toLocaleString("en-IN")}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" className="tableFooter text-end">
                        Net Tax Liability (monthly)
                      </td>
                      <td className="tableFooter">
                        {Math.ceil(monthlyTax).toLocaleString("en-IN")}
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    <td className="tableBody text-center" colSpan={4}>
                      Please make a calculation to view your income data.
                    </td>
                  </tbody>
                )}
              </table>
            </div>
          </div>

          <div className="uk-width-1-3@m  sticky-tax-top">
            <div className="tax-result wrapper">
              <div className="tax-wrapper">
                <span>{grossSalary.toLocaleString("en-IN")}</span>
                <h3>Annual Gross Salary</h3>
                <span className="icon"></span>
              </div>
              <div className="tax-wrapper">
                <span>{taxableIncome.toLocaleString("en-IN")}</span>
                <h3>Taxable Income</h3>
              </div>
              <div className="tax-wrapper setBorder">
                <span>{payableTax.toLocaleString("en-IN")}</span>
                <h3>Net Payable Tax </h3>
              </div>
              <div className="tax-slab">
                <h3>
                  Your Tax slab is: <br /> <span>Upto {`${taxSlab}%`}</span>{" "}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer logo={logo} />
    </>
  );
};

export default TaxCalculator;
