import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { Fetchdata } from "../hooks/getData";
import AuthContext from "../context/auth-context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../loading/spinner";
import { FaRegCalendarAlt } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import {
  AiOutlineEdit,
  AiOutlineSearch,
  AiTwotonePrinter,
  AiOutlineEye,
} from "react-icons/ai";
import "jspdf-autotable";
import { englishToNepaliNumber } from "nepali-number";
import TodoContext from "../todoState/TodoContext";
import UpperbarContext from "../context/upperbar-context";
import TodoPopup from "./TodoPopup";
import EditTodoPop from "./EditTodoPop";
import { MdOutlineDelete } from "react-icons/md";
import DeleteTodo from "./DeleteTodo";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import ViewTodoPop from "./ViewTodoPop";
import { GetNepaliDate } from "../hooks/dateConvertor";

export default function Todo() {
  const { User } = useContext(AuthContext);
  const {
    fiscalYear,
    todayDate,
    appURL,
    customStyles,
    mode,
    userDetails,
    expires,
  } = useContext(UpperbarContext);

  const {
    loading,
    todoList,
    setTodoValues,
    todoValue,
    handleTodoEdit,
    editTodoPop,
    setEditTodoPop,
    setTodoErrors,
    handleTodoDelete,
    deleteTodoPopUp,
    setDeleteTodoPopUp,
    originalList,
    setTodoList,
    deleteTodo,
    todoStatus,
    setTodoStatus,
    changeStatus,

    todoPriority,
    setTodoPriority,
    changePriority,

    todoStaff,
    setTodoStaff,
    changeStaff,
    todoDate,
    setTodoDate,
    changeDate,
    handleTodoView,
    viewTodoPop,
    setViewTodoPop,
    viewTodoList,
    setViewTodoList,
    re,
    setRe,
  } = useContext(TodoContext);
  const [popUp, setPopUp] = useState(false);
  const [DFlag, setDFlag] = useState("N");

  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      grow: 0,
      center: true,
      width: "70px",
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },
    {
      name: mode === "en" ? "Title" : "शीर्षक",
      // center: true,
      // grow: 0,
      // minWidth: "200px",
      selector: (row) => row.Title,
    },
    {
      name: mode === "en" ? "Assigned To" : "खटाइएका",
      // grow: 0,
      // center: true,
      width: "200px",
      selector: (row) => {
        const id = "" + row.AssignedTo;
        return (
          <>
            <div>
              <select
                style={{ fontSize: "11px", marginLeft: "5px" }}
                name="status"
                className="form-select form-select-sm"
                aria-label="Default select example "
                // value={jobStatus}
                value={id}
                onChange={handleStaff}
                onClick={(e) => changeStaff([row.TaskID, e.target.value])}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  {mode === "en" ? "Select Staff" : "काम स्थिति चयन गर्नुहोस्"}
                </option>

                {staffList.map((item) => (
                  <option key={item.UserID} value={item.UserID}>
                    {item.FullName}
                  </option>
                ))}
              </select>
            </div>
          </>
        );
      },
    },

    {
      name: mode === "en" ? "Priority" : "प्राथमिकता",
      // grow: 0,
      // center: true,
      width: "150px",
      selector: (row) => {
        return (
          <>
            <div>
              <select
                style={{ fontSize: "11px", marginLeft: "5px" }}
                name="priority"
                className="form-select form-select-sm"
                aria-label="Default select example "
                // value={jobStatus}
                value={row.Priority}
                onChange={handlePriority}
                onClick={(e) => changePriority([row.TaskID, e.target.value])}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  {mode === "en"
                    ? "Select Priority"
                    : "प्राथमिकता चयन गर्नुहोस्"}
                </option>

                <option value="U">
                  {mode === "en" ? "Urgent" : "खोल्नुहोस्"}
                </option>
                <option value="H">
                  {mode === "en" ? "High" : "बन्द गर्नुहोस्"}
                </option>
                <option value="N">
                  {mode === "en" ? "Normal" : "बन्द गर्नुहोस्"}
                </option>
                <option value="L">
                  {mode === "en" ? "Low" : "बन्द गर्नुहोस्"}
                </option>
              </select>
            </div>
          </>
        );
      },
    },
    {
      name: mode === "en" ? "Status" : "स्थिति",
      // grow: 0,
      // center: true,
      width: "150px",
      selector: (row) => {
        return (
          <>
            <div>
              <select
                style={{ fontSize: "11px", marginLeft: "5px" }}
                name="status"
                className="form-select form-select-sm"
                aria-label="Default select example "
                // value={jobStatus}
                defaultValue={row.Status}
                onChange={handleStatus}
                onClick={(e) => changeStatus([row.TaskID, e.target.value])}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  {mode === "en" ? "Select Status" : "स्थिति चयन गर्नुहोस्"}
                </option>

                <option value="T">
                  {mode === "en" ? "To Do" : "खोल्नुहोस्"}
                </option>
                <option value="P">
                  {mode === "en" ? "In progress" : "बन्द गर्नुहोस्"}
                </option>
                <option value="D">
                  {mode === "en" ? "Done" : "बन्द गर्नुहोस्"}
                </option>
                <option value="C">
                  {mode === "en" ? "Complete" : "बन्द गर्नुहोस्"}
                </option>
              </select>
            </div>
          </>
        );
      },
    },
    {
      name: mode === "en" ? "Deadline" : "म्याद",
      center: true,
      minWidth: "150px",
      selector: (row) => row.DueDate,
    },
    {
      name: mode === "en" ? "Days Remaining" : "दिन बाँकी",
      grow: 1,
      center: true,
      minWidth: "200px",
      selector: (row) => {
        // Set the deadline date
        const deadlineDate = new Date(row.DueDate);

        // Get the current date
        const currentDate = new Date();

        // Calculate the remaining days
        const timeDifference = deadlineDate.getTime() - currentDate.getTime();

        let remainingDays;

        if (deadlineDate < currentDate) {
          remainingDays = "Expired";
        } else {
          remainingDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
        }
        return (
          <>
            {remainingDays === 1 ? (
              <span>{remainingDays} day remaining</span>
            ) : remainingDays === "Expired" ? (
              <span>{remainingDays}</span>
            ) : (
              <span>{remainingDays} days remaining</span>
            )}
          </>
        );
      },
    },
    {
      name: mode === "en" ? "Action" : "कार्य",
      center: true,
      width: "200px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                class="btn btn-sm downloadspan mx-1"
                onClick={() => handleTodoView(row)}
                uk-tooltip={mode === "en" ? "View" : "हेर्नुहोस्"}
              >
                <AiOutlineEye />
              </button>{" "}
              <button
                type="button"
                class="btn btn-sm editspan mx-1"
                onClick={() => handleTodoEdit(row)}
                uk-tooltip={mode === "en" ? "Edit" : "सम्पादन गर्नुहोस्"}
              >
                <AiOutlineEdit />
              </button>{" "}
              <button
                type="button"
                class="btn btn-sm deletespan mx-1"
                onClick={() => handleTodoDelete(row)}
                uk-tooltip={mode === "en" ? "Delete" : "हटाउनुहोस्"}
              >
                <MdOutlineDelete />
              </button>{" "}
            </div>
          </>
        );
      },
    },
  ];

  const handleStatus = (data) => {
    let getId = data[0];
    let indexList = todoStatus
      ? todoStatus.findIndex((list) => list.TaskID === getId)
      : -1;
    if (indexList < 0) {
      setTodoStatus(data[1]);
    }
  };
  const handlePriority = (data) => {
    let getId = data[0];
    let indexList = todoPriority
      ? todoPriority.findIndex((list) => list.TaskID === getId)
      : -1;
    if (indexList < 0) {
      setTodoPriority(data[1]);
    }
  };
  const handleStaff = (data) => {
    let getId = data[0];
    let indexList = todoStaff
      ? todoStaff.findIndex((list) => list.TaskID === getId)
      : -1;
    if (indexList < 0) {
      setTodoStaff(data[1]);
    }
  };

  // const handleDueDate = ({ adDate }) => {
  //   setTodoDate(adDate);
  // };

  const addTodo = (e) => {
    setPopUp(true);
    setTodoValues(todoValue);
    setTodoErrors({});
    setRe(Math.random());
  };

  //API to get staff list
  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    staffLst();
  }, []);

  const staffLst = () => {
    const BranchID = "" + User.BranchID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      DFlag: "STAFF",
      BranchID: BranchID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/ref-values`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setStaffList(postResult);
      } else {
        setStaffList([]);
      }
    });
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper ps-4 pe-4">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">
              {mode === "en" ? "Todo" : "काम"}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <span className="me-2 ">Expires in: {expires} </span>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>
        <>
          <div className="sec-dataTable">
            <div className="upper-dataTbl">
              <div className="d-flex uk-flex-wrap uk-flex-middle uk-flex-right mb-3">
                <div className="btn-addlnote mt-3">
                  <button
                    type="button"
                    class="btn btn-sm"
                    style={{
                      background: "var(--button-color)",
                      color: "white",
                    }}
                    onClick={addTodo}
                  >
                    <GoPlus color="#fff" className="me-1" />
                    {mode === "en" ? "Add New" : "नयाँ थप्नुहोस्"}
                  </button>
                </div>
              </div>
            </div>

            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={todoList}
                customStyles={customStyles}
                pagination
                paginationRowsPerPageOptions={[10, 20, 50, 100]} // Customizable options
                fixedHeader
                fixedHeaderScrollHeight="350px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderComponent={
                  <>
                    {/* <div className=" w-100 ">
                      <div className="d-flex uk-flex-middle justify-content-end">
                        <div>
                          <div class="uk-search uk-search-default">
                            <AiOutlineSearch className="search-icon" />
                            <input
                              placeholder={
                                mode === "en" ? "Search" : "खोजी गर्नुहोस्"
                              }
                              ref={searchInput}
                              type="text"
                              className="form-control form-control-sm searchField"
                              onChange={searchHandler}
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      <TodoPopup
        setPopUp={setPopUp}
        popUp={popUp}
        DFlag={DFlag}
        staffList={staffList}
        re={re}
      />
      <EditTodoPop
        setEditPop={setEditTodoPop}
        editPop={editTodoPop}
        DFlag={DFlag}
        staffList={staffList}
        re={re}
      />
      <ViewTodoPop
        viewTodoList={viewTodoList}
        viewTodoPop={viewTodoPop}
        setViewTodoPop={setViewTodoPop}
      />

      {deleteTodoPopUp && (
        <DeleteTodo
          deleteTodo={deleteTodo}
          setDeleteTodoPopUp={setDeleteTodoPopUp}
        />
      )}
    </>
  );
}
