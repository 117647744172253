import React, { useContext, useEffect, useState } from "react";
import "../../../leaveNotes/leaveNotePopup.css";
import "./DepartmentPopup.css";
import CloseIcon from "../../../../images/CloseIcon.svg";
import AuthContext from "../../../context/auth-context";
import { Fetchdata } from "../../../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UpperbarContext from "../../../context/upperbar-context";
import Toast from "../../../Toast";
import $ from "jquery";

export default function DepartmentPopupEdit({
  setDepartmentEditPopup,
  reload,
  setReload,
  deptList,
  documentFormValue,
  setDocumentFormValue,
  departmentFormError,
  setDepartmentFormError,
  titleId,
  departmentEditPopup,
}) {
  const { User } = useContext(AuthContext);
  const { appURL, mode } = useContext(UpperbarContext);
  const closePopUp = (e) => {
    setDepartmentEditPopup(false);
    setDepartmentFormError({});
    $(".editDepartmentPopBg").fadeOut(500);
    $(".editDepartmentPop").slideUp(500);
    setEditIsSubmit(false);
  };

  useEffect(() => {
    if (departmentEditPopup) {
      $(".editDepartmentPopBg").fadeIn(500);
      $(".editDepartmentPop").slideDown(500);
    }
  }, [departmentEditPopup]);

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type == "checkbox" ? target.checked : target.value;
    setDocumentFormValue({ ...documentFormValue, [name]: value });
  };
  const [editIsSubmit, setEditIsSubmit] = useState(false);

  const validate = (values) => {
    const errors = {};
    if (!values.title) {
      errors.title = mode === "en" ? "Required" : "आवश्यक छ";
    }
    // if (!values.head) {
    //   errors.head = mode === "en" ? "Required" : "आवश्यक छ";
    // }

    return errors;
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setDepartmentFormError(validate(documentFormValue));
    setEditIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(departmentFormError).length === 0 && editIsSubmit) {
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: User.UID,
        DepartmentID: titleId,
        Department: documentFormValue.title,
        DepHeadID: documentFormValue.head,
        Flag: "U",
        FetchURL: `${appURL}api/admin/department`,
        Type: "POST",
      };
      Fetchdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          setDepartmentEditPopup(false);
          $(".editDepartmentPopBg").fadeOut(500);
          $(".editDepartmentPop").slideUp(500);
          deptList();
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          setDepartmentFormError({
            errorv: "Please enter valid credentials",
          });
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });

      setEditIsSubmit(false);
    }
  }, [departmentFormError]);

  //API to get staff list

  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    staffLst();
  }, []);

  const staffLst = () => {
    const BranchID = "" + User.BranchID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      DFlag: "STAFF",
      BranchID: BranchID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/ref-values`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setStaffList(postResult);
      } else {
        setStaffList([]);
      }
    });
  };

  return (
    <>
      {/* <Toast /> */}

      <div className="container newpopup-bg editDepartmentPopBg">
        <div className="newpopup editDepartmentPop">
          <div className="newpopup-head ps-0 pe-0">
            <div className="popUpTitle">
              {" "}
              {mode === "en" ? "Department Edit" : "विभाग सम्पादन गर्नुहोस्"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div className="newpopup-body ps-3 pe-3">
            <div className="form-in-padding">
              <div className="row text-start mt-2 ">
                <div className="col-md-12 col-sm-12 col-lg-12">
                  <div className="row text-start ">
                    <div className="form-group mt-0">
                      <label htmlFor="title" style={{ fontSize: "12px" }}>
                        {mode === "en" ? "Title" : "शीर्षक"}
                        <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <input
                        id="title"
                        style={{ fontSize: "13px" }}
                        type="text"
                        className="form-control form-control-sm "
                        name="title"
                        value={documentFormValue.title}
                        onChange={handleChange}
                        placeholder={mode === "en" ? "Title" : "शीर्षक"}
                      />
                      {departmentFormError.title && (
                        <p className="errormsg">{departmentFormError.title}</p>
                      )}
                    </div>
                  </div>

                  <div className="row text-start ">
                    <div className="form-group">
                      <label htmlFor="head" style={{ fontSize: "12px" }}>
                        {mode === "en" ? "Select Head" : "प्रमुख चयन गर्नुहोस्"}
                      </label>
                      <select
                        class="form-select form-select-sm"
                        aria-label="Default select example"
                        name="head"
                        value={documentFormValue.head}
                        onChange={handleChange}
                      >
                        <option disabled value="" selected>
                          {mode === "en"
                            ? "Select Head"
                            : "प्रमुख चयन गर्नुहोस्"}
                        </option>
                        <option value="-1">
                          {mode === "en" ? "No Head" : "प्रमुख छैन"}
                        </option>
                        {staffList.map((item) => (
                          <option key={item.UserID} value={item.UserID}>
                            {item.FullName}
                          </option>
                        ))}
                      </select>
                      {/* {departmentFormError.head && (
                      <p className="errormsg">{departmentFormError.head}</p>
                    )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="new-footer">
            <div className="row  mt-1 mb-1">
              <div>
                <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={formSubmit}
                >
                  {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
