import React, { useContext, useEffect, useState } from "react";
import "./leaveNotePopup.css";
import CloseIcon from "../../images/CloseIcon.svg";
import { Fetchdata } from "../hooks/getData";
import AuthContext from "../context/auth-context";
import NepaliDate from "nepali-date-converter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import UpperbarContext from "../context/upperbar-context";
import Toast from "../Toast";
import $ from "jquery";

export default function LeaveNotePopup({
  setLeaveNotePopup,
  reload,
  setReload,
  DFlag,
  leaveNotePopup,
  initalvalue,
  formValues,
  setFormValues,
  setformErrors,
  formErrors,
  re,
}) {
  const { appURL, sidePanelBg, mode } = useContext(UpperbarContext);

  const { User } = useContext(AuthContext);

  const [isSubmit, setIsSubmit] = useState(false);
  // const [fromDate, setFromDate] = useState("");
  // const [toDate, setToDate] = useState("");
  const [fieldWork, setFieldWork] = useState(false);
  const [leaveTList, setLeaveTList] = useState([]);
  const [orgStaffList, setOrgStaffList] = useState([]);
  const [minDate, setMinDate] = useState(null);

  useEffect(() => {
    leavelst();
  }, []);

  const leavelst = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      Flag: "SL",
      Type: "POST",
      FetchURL: `${appURL}api/leave-note`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setLeaveTList(postResult);
      } else {
        setLeaveTList([]);
      }
    });
  };

  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    staffLst();
  }, []);

  const staffLst = () => {
    const BranchID = "" + User.BranchID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      DFlag: "STAFF",
      BranchID: BranchID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/ref-values`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setStaffList(postResult);
      } else {
        setStaffList([]);
      }
    });
  };

  useEffect(() => {
    const cur_date = new Date();
    const min_date = `${cur_date.getFullYear()}/${cur_date.getMonth()}/${cur_date.getDate()}`;
    setMinDate(min_date.toString());
  }, []);

  const getNepaliDate = () => {
    var news = new Date();
    var newss = news.toLocaleDateString();
    const nepDate = new NepaliDate(new Date(newss));
    var cm = nepDate.getMonth() + 1;

    var cd = nepDate.getDate() - 1;
    //
    let strDate = nepDate.getYear() + "-" + cm + "-" + cd;
    //
    return strDate;
  };

  const getMaxNepaliDate = () => {
    var news = new Date();
    var newss = news.toLocaleDateString();
    const nepDate = new NepaliDate(new Date(newss));
    var cm = nepDate.getMonth() + 1;

    var cd = nepDate.getDate() + 1;
    //
    let strDate = nepDate.getYear() + "-" + cm + "-" + cd;
    //
    return strDate;
  };

  const getEnglishDate = (date) => {
    let nDate = new NepaliDate(date);

    let eDate = nDate.getAD();

    let enMonth = eDate.month + 1;
    let enM = enMonth < 10 ? `0${enMonth}` : enMonth;

    let enDat = eDate.date < 10 ? `0${eDate.date}` : eDate.date;
    return `${eDate.year}-${enM}-${enDat}`;
  };

  const handleFromDate = ({ bsDate }) => {
    // setFromDate(bsDate);
    let name = "fromDate";
    setFormValues({ ...formValues, [name]: bsDate });
  };

  const handleToDate = ({ bsDate }) => {
    // setToDate(bsDate);
    let name = "toDate";
    setFormValues({ ...formValues, [name]: bsDate });
  };

  //

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const closePopUp = (e) => {
    setLeaveNotePopup(false);
    $(".addLeavePop").slideUp(500);
    $(".addLeavePopBg").fadeOut(500);
    setformErrors({});
    setFormValues(initalvalue);
    setIsSubmit(false);
    setFieldWork(false);
  };

  useEffect(() => {
    if (leaveNotePopup) {
      $(".addLeavePop").slideDown(500);
      $(".addLeavePopBg").fadeIn(500);
    }
  }, [leaveNotePopup]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // setformErrors(validate(formValues));
    setIsSubmit(true);
  };

  useEffect(() => {
    let cur_date = new Date();
    let en_date = `${cur_date.getMonth()}/${cur_date.getDate()}/${cur_date.getFullYear()}`;
    setMinDate(en_date);
  }, []);

  const validate = (values) => {
    const errors = {};
    const ptn = /^\w+$/;
    const digitPtn = /[0-9]/;
    const alphaptn = /[a-z]/;
    const capalpha = /[A-Z]/;

    if (
      values.leaveType &&
      values.dayType &&
      values.LeaveTitle &&
      values.LeaveDescription &&
      values.assignedStaff &&
      values.fromDate &&
      values.toDate
    ) {
      //code here
    } else {
      if (!values.leaveType) {
        errors.leaveType = mode === "en" ? "Required" : "आवश्यक छ";
      }
      if (!values.dayType) {
        errors.dayType = mode === "en" ? "Required" : "आवश्यक छ";
      }
      if (!values.assignedStaff) {
        errors.assignedStaff = mode === "en" ? "Required" : "आवश्यक छ";
      }
      if (!values.LeaveTitle) {
        errors.LeaveTitle = mode === "en" ? "Required" : "आवश्यक छ";
      }
      if (!values.LeaveDescription) {
        errors.LeaveDescription = mode === "en" ? "Required" : "आवश्यक छ";
      }
      if (!values.fromDate) {
        errors.fromDate = mode === "en" ? "Required" : "आवश्यक छ";
      }
      if (!values.toDate) {
        errors.toDate = mode === "en" ? "Required" : "आवश्यक छ";
      }
      return errors;
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {

      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: User.UID,
        Flag: "i",
        LeaveTypeID: formValues.leaveType,
        DayTypeID: formValues.dayType,
        Title: formValues.LeaveTitle,
        Cause: formValues.LeaveDescription,
        FromDate: getEnglishDate(formValues.fromDate),
        ToDate: getEnglishDate(formValues.toDate),
        FromNepDate: formValues.fromDate,
        ToNepDate: formValues.toDate,
        IsFieldWork: fieldWork ? "Y" : "N",
        LeaveAssignedTo: formValues.assignedStaff,
        Notify: "N",
        FiscalID: User.FiscalID,
        BranchID: User.BranchID,
        FetchURL: `${appURL}api/leave-note`,
        Type: "POST",
      };


      Fetchdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          setReload(!reload);
          setLeaveNotePopup(false);
          $(".addLeavePop").slideUp(500);
          $(".addLeavePopBg").fadeOut(500);
          setFieldWork(false);
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
          setformErrors({
            errorv: "Please enter valid credentials",
          });
        }
      });

      setIsSubmit(false);
    }
  }, [formErrors]);

  //
  return (
    <>
      {/* <Toast /> */}
      <div className="container leavenotepopup-wrapper addLeavePopBg">
        <div className="leavenotepopup-inner addLeavePop">
          <div className="popUpHeader ps-0 pe-0" style={sidePanelBg}>
            <div className="popUpTitle">Leave Note</div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div className="lnotepopUpBody ps-3 pe-3">
            <div className="form-padding">
              <div className="row text-start mt-2 ">
                <div className="col-md-6 col-sm-6 col-lg-6">
                  <label
                    className="text-start mb-1"
                    style={{ fontSize: "12px" }}
                  >
                    Leave Type <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    name="leaveType"
                    value={formValues.leaveType}
                    onChange={handleChange}
                  >
                    <option value="" selected disabled>
                      Select Leave Type
                    </option>
                    {leaveTList.map((list) => (
                      <>
                        <option key={list.LeaveTypeID} value={list.LeaveTypeID}>
                          {list.Type}
                        </option>
                      </>
                    ))}
                  </select>
                  {formErrors.leaveType && (
                    <p className="errormsg">{formErrors.leaveType}</p>
                  )}
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6">
                  <label
                    className="text-start mb-1"
                    style={{ fontSize: "12px" }}
                  >
                    Day Type<sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    name="dayType"
                    value={formValues.dayType}
                    onChange={handleChange}
                  >
                    <option disabled value="" selected>
                      Select Day Type
                    </option>
                    <option value="H">Half Day</option>
                    <option value="F">Full Day</option>
                  </select>
                  {formErrors.dayType && (
                    <p className="errormsg">{formErrors.dayType}</p>
                  )}
                </div>
              </div>

              <div className="row text-start ">
                <div className="form-group">
                  <label htmlFor="cause" style={{ fontSize: "12px" }}>
                    Title<sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="cause"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="LeaveTitle"
                    value={formValues.LeaveTitle}
                    onChange={handleChange}
                    placeholder="Title"
                  />
                  {formErrors.LeaveTitle && (
                    <p className="errormsg">{formErrors.LeaveTitle}</p>
                  )}
                </div>
              </div>

              <div className="row text-start ">
                <div className="form-group">
                  <label htmlFor="desc" style={{ fontSize: "12px" }}>
                    Cause<sup style={{ color: "red" }}>*</sup>
                  </label>
                  <textarea
                    id="desc"
                    value={formValues.LeaveDescription}
                    onChange={handleChange}
                    style={{ fontSize: "13px" }}
                    class="form-control ps-2"
                    name="LeaveDescription"
                    rows="3"
                    cols="12"
                    placeholder="Cause"
                  ></textarea>
                  {formErrors.LeaveDescription && (
                    <p className="errormsg">{formErrors.LeaveDescription}</p>
                  )}
                </div>
              </div>

              <div className="row text-start mt-1 ">
                <div className="col-md-6 col-sm-6 col-lg-6">
                  <label
                    className="text-start mb-1"
                    style={{ fontSize: "12px" }}
                  >
                    From Date<sup style={{ color: "red" }}>*</sup>
                  </label>
                  {DFlag === "N" ? (
                    <Calendar
                      className="form-control form-control-sm pt-0 pb-0 from-date"
                      dateFormat="YYYY/MM/DD"
                      // defaultDate="2010/01/01"
                      theme="default"
                      language="en"
                      value={formValues.fromDate}
                      // minDate={getNepaliDate()}
                      // maxDate={getMaxNepaliDate()}
                      onChange={handleFromDate}
                      name="fromDate"
                      hideDefaultValue={true}
                      placeholder={"Select From Date"}
                      key={re}
                    />
                  ) : (
                    <input
                      type="date"
                      value={formValues.fromDate}
                      name="fromDate"
                      placeholder="Select a Date"
                      className="form-control form-control-sm "
                      onChange={handleChange}
                    />
                  )}
                  {formErrors.fromDate && (
                    <p className="errormsg">{formErrors.fromDate}</p>
                  )}
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6">
                  <label
                    className="text-start mb-1"
                    style={{ fontSize: "12px" }}
                  >
                    To Date<sup style={{ color: "red" }}>*</sup>
                  </label>
                  {DFlag === "N" ? (
                    <Calendar
                      className="form-control form-control-sm pt-0 pb-0 "
                      dateFormat="YYYY/MM/DD"
                      // defaultDate="2010/01/01"
                      theme="default"
                      language="en"
                      // minDate={getNepaliDate()}
                      // maxDate={getMaxNepaliDate()}
                      value={formValues.toDate}
                      onChange={handleToDate}
                      name="toDate"
                      hideDefaultValue={true}
                      placeholder={"Select To Date"}
                      key={re}
                    />
                  ) : (
                    <input
                      type="date"
                      value={formValues.toDate}
                      name="toDate"
                      placeholder="Select a Date"
                      className="form-control form-control-sm "
                      onChange={handleChange}
                    />
                  )}
                  {formErrors.toDate && (
                    <p className="errormsg">{formErrors.toDate}</p>
                  )}
                </div>
              </div>

              <div className="row text-start ">
                <div className="form-group">
                  <label style={{ fontSize: "12px" }}>
                    Assigned To<sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    value={formValues.assignedStaff}
                    name="assignedStaff"
                    onChange={handleChange}
                  >
                    <option disabled value="" selected>
                      Select Staff
                    </option>
                    {orgStaffList.map((list) => (
                      <>
                        <option key={list.StaffId} value={list.StaffId}>
                          {list.StaffName}
                        </option>
                      </>
                    ))}
                    {/* <option value="-1">No one</option>
                    {staffList.map((item) => (
                      <option key={item.UserID} value={item.UserID}>
                        {item.FullName}
                      </option>
                    ))} */}
                  </select>
                  {formErrors.assignedStaff && (
                    <p className="errormsg">{formErrors.assignedStaff}</p>
                  )}
                </div>
              </div>

              <div className="row text-start mt-1">
                <div className="form-group">
                  <div class="form-check" style={{ fontSize: "12px" }}>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="fieldWork"
                      name="fieldWork"
                      checked={fieldWork}
                      onChange={() => setFieldWork(!fieldWork)}
                    />
                    <label
                      style={{ fontSize: "12px", cursor: "pointer" }}
                      class="form-check-label"
                      htmlFor="fieldWork"
                    >
                      Field Work
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ln-popUpFooter">
            <div className="row  mt-1 mb-1">
              <div>
                <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
