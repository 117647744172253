import {  toast } from "react-toastify";

let currentToastId = null;
export default function ErrorToast(msg) {
  // Dismiss the current toast if it exists
  if (currentToastId !== null) {
    toast.dismiss(currentToastId);
  }

  // Show a new toast and store its ID
  currentToastId = toast.error(msg, {
    style: {
      color: "#ff4949",
      fontSize: "13px",
    },
    theme: "light",
    toastId: `Toast-${Date.now()}`,
  });
}
