import comLogo from "../../images/logo.png";
import jsPDF from "jspdf";
import "jspdf-autotable";

export const generatePdf = (userDetails, exportColumns, dataWithIndex) => {
  const doc = new jsPDF("p", "pt", "a4", true);

  var midPage = doc.internal.pageSize.getWidth() / 2;

  var logoWidth = 60;
  var logoHeight = 40;
  var logoX = midPage - logoWidth / 2;
  var logoY = 40; // y-coordinate for the logo
  var textY = logoY + logoHeight + 20;

  doc.addImage(`${comLogo}`, "JPG", logoX, logoY, logoWidth, logoHeight);

  doc.setFontSize(18);
  doc.text(`${userDetails.ComName}`, midPage, textY, null, null, "center");

  doc.setFontSize(15);
  doc.text(
    `${userDetails.ComAddress},${userDetails.ComBranch}`,
    midPage,
    textY + 18,
    null,
    null,
    "center"
  );

  doc.setFontSize(10);
  doc.text(
    `Tel:${userDetails.ComTel}`,
    midPage,
    textY + 36,
    null,
    null,
    "center"
  );

  doc.autoTable({
    startY: textY + 56,
    theme: "grid",
    columns: exportColumns,
    body: dataWithIndex,
  });
  doc.save("Data.pdf");
};

export const generateTablePdf = (userDetails) => {
    const doc = new jsPDF("p", "pt", "a4", true);
  
    var midPage = doc.internal.pageSize.getWidth() / 2;
  
    var logoWidth = 60;
    var logoHeight = 40;
    var logoX = midPage - logoWidth / 2;
    var logoY = 40; // y-coordinate for the logo
    var textY = logoY + logoHeight + 20;
  
    doc.addImage(`${comLogo}`, "JPG", logoX, logoY, logoWidth, logoHeight);
  
    doc.setFontSize(18);
    doc.text(`${userDetails.ComName}`, midPage, textY, null, null, "center");
  
    doc.setFontSize(15);
    doc.text(
      `${userDetails.ComAddress},${userDetails.ComBranch}`,
      midPage,
      textY + 18,
      null,
      null,
      "center"
    );
  
    doc.setFontSize(10);
    doc.text(
      `Tel:${userDetails.ComTel}`,
      midPage,
      textY + 36,
      null,
      null,
      "center"
    );
  
    doc.autoTable({
      startY: textY + 56,
      theme: "grid",
      html: "#table-to-xls",
    });
    doc.save("Data.pdf");
  };