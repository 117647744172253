import React from "react";
import { useContext } from "react";
import UpperbarContext from "../context/upperbar-context";
import "./profile.css";
import { GetEnglishDate } from "../hooks/dateConvertor";

function Basic({ userDetails }) {
  const { mode, dateMode } = useContext(UpperbarContext);

  const services = [
    {
      title: mode === "en" ? "Company ID" : "कम्पनी आईडी",
      body:
        userDetails.ComID === null ||
        userDetails.ComID === "-" ||
        userDetails.ComID === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : userDetails.ComID,
    },
    // {
    //   title: mode === "en" ? "Company" : "कम्पनी",
    //   body: "Easy Software",
    // },
    {
      title: mode === "en" ? "Device Code" : "डिभाइस कोड",
      body:
        userDetails.DeviceCode === null ||
        userDetails.DeviceCode === "-" ||
        userDetails.DeviceCode === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : userDetails.DeviceCode,
    },
    {
      title: mode === "en" ? "User Code" : "प्रयोगकर्ता कोड",
      body:
        userDetails.UID === null ||
        userDetails.UID === "-" ||
        userDetails.UID === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : userDetails.UID,
    },
    {
      title: mode === "en" ? "Email" : "इमेल",
      body:
        userDetails.Email === null ||
        userDetails.Email === "-" ||
        userDetails.Email === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : userDetails.Email,
    },
    {
      title: mode === "en" ? "Contact" : "सम्पर्क",
      body:
        userDetails.Contact === null ||
        userDetails.Contact === "-" ||
        userDetails.Contact === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : userDetails.Contact,
    },
    {
      title: mode === "en" ? "Phone" : "फोन",
      body:
        userDetails.Phone === null ||
        userDetails.Phone === "-" ||
        userDetails.Phone === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : userDetails.Phone,
    },
    {
      title: mode === "en" ? "Date of Birth" : "जन्म मिति",
      body:
        userDetails.DateOfBirthNp === null ||
        userDetails.DateOfBirthNp === "-" ||
        userDetails.DateOfBirthNp === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : dateMode === "np"
          ? userDetails.DateOfBirthNp
          : userDetails.DateOfBirthEn,
    },
    {
      title: mode === "en" ? "Gender" : "लिङ्ग",
      body:
        userDetails.Gender === null ||
        userDetails.Gender === "-" ||
        userDetails.Gender === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : userDetails.Gender,
    },
    {
      title: mode === "en" ? "Religion" : "धर्म",
      body:
        userDetails.Religion === null ||
        userDetails.Religion === "-" ||
        userDetails.Religion === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : userDetails.Religion,
    },
    {
      title: mode === "en" ? "Blood Group" : "रक्त समूह",
      body:
        userDetails.BloodGroup === null ||
        userDetails.BloodGroup === "-" ||
        userDetails.BloodGroup === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : userDetails.BloodGroup,
    },
    {
      title: mode === "en" ? "Marital Status" : "रक्त समूह",
      body:
        userDetails.MaritalStatus === null ||
        userDetails.MaritalStatus === "-" ||
        userDetails.MaritalStatus === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : userDetails.MaritalStatus,
    },
    {
      title: mode === "en" ? "PAN" : "प्यान",
      body:
        userDetails.PAN === null ||
        userDetails.PAN === "-" ||
        userDetails.PAN === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : userDetails.PAN,
    },
    {
      title: mode === "en" ? "Citizenship No." : "नागरिकता नं.",
      body:
        userDetails.CitizenshipNo === null ||
        userDetails.CitizenshipNo === "-" ||
        userDetails.CitizenshipNo === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : userDetails.CitizenshipNo,
    },
    {
      title: mode === "en" ? "Address" : "ठेगाना",
      body:
        userDetails.Address === null ||
        userDetails.Address === "-" ||
        userDetails.Address === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : userDetails.Address,
    },
    {
      title: mode === "en" ? "District" : "जिल्ला",
      body:
        userDetails.District === null ||
        userDetails.District === "-" ||
        userDetails.District === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : userDetails.District,
    },
    {
      title: mode === "en" ? "Branch" : "साखा",
      body:
        userDetails.BranchName === null ||
        userDetails.BranchName === "-" ||
        userDetails.BranchName === ""
          ? mode === "en"
            ? "Not mentioned"
            : "उल्लेख गरिएको छैन"
          : userDetails.BranchName,
    },
  ];

  return (
    <div className="basic-center ">
      <div className="basic-dyno">
        {services.map((item, index) => {
          return (
            <article key={index} className="basic-info">
              <h6 className="basic-title">{item.title}</h6>
              <p>{item.body}</p>
            </article>
          );
        })}
      </div>
    </div>
  );
}

export default Basic;
