import React, { useContext, useEffect } from "react";
import UpperbarContext from "../../context/upperbar-context";
import CloseIcon from "../../../images/CloseIcon.svg";
import { GetNepaliDate } from "../../hooks/dateConvertor";
import $ from "jquery";
const ViewLeaveReport = ({ note, setViewPopup, viewPopup }) => {
  const { mode } = useContext(UpperbarContext);
  const closePopUp = (e) => {
    setViewPopup(false);
    $(".viewLeaveReportPop").slideUp(500);
    $(".viewLeaveReportPopBg").fadeOut(500);
  };

  const leave = [
    {
      title: mode === "en" ? "Full Name" : "पुरा नाम",
      body: note.StaffName,
    },
    {
      title: mode === "en" ? "Title" : "शीर्षक",
      body: note.Title,
    },
    {
      title: mode === "en" ? "Cause" : "कारण",
      body: note.Cause,
    },
    {
      title: mode === "en" ? "Day Type" : "दिनको प्रकार",
      body: note.DayType,
    },
    {
      title: mode === "en" ? "Leave Type" : "बिदाको प्रकार",
      body: note.LeaveType,
    },
    {
      title: mode === "en" ? "From Date" : "मिति देखि",
      body: note.FromDate,
    },
    {
      title: mode === "en" ? "To Date" : "मिति सम्म",
      body: note.ToDate,
    },
    {
      title: mode === "en" ? "Days" : "दिन",
      body: note.TotalDays,
    },
    // {
    //   title: "Is Field Work",
    //   body: note.IsFieldWork,
    // },
    // {
    //   title: "Assigned To",
    //   body: note.AssignedTo === null ? "Not assigned" : note.AssignedTo,
    // },
    {
      title: mode === "en" ? "Leave Status" : "बिदाको स्थिति",
      body: note.LeaveStatus,
    },
    {
      title: mode === "en" ? "Is Verified" : "प्रमाणित छ",
      body: note.LeaveVerified,
    },
    {
      title: mode === "en" ? "Verified By" : "द्वारा प्रमाणित",
      body: note.VerifiedBy === null ? "Not verified" : note.VerifiedBy,
    },
  ];

  useEffect(() => {
    if (viewPopup) {
      $(".viewLeaveReportPop").slideDown(500);
      $(".viewLeaveReportPopBg").fadeIn(500);
    }
  }, [viewPopup]);

  return (
    <>
      <div className="container viewNotifyPopup-wrapper viewLeaveReportPopBg">
        <div className="viewNotifyPopup-inner viewLeaveReportPop">
          <div className="popUpHeader ps-0 pe-0">
            <div className="popUpTitle">
              {mode === "en" ? "View" : "हेर्नुहोस्"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div className="viewNotifyPopupBody ps-3 pe-3">
            <div className="row text-start mt-2">
              <table className="table">
                <tbody>
                  {leave.map((props) => {
                    const { title, body } = props;
                    return (
                      <>
                        <tr>
                          <td
                            className="fw-bold"
                            style={{ width: "max-content" }}
                          >
                            {title}
                          </td>
                          <td
                            style={{
                              borderLeft: "1px solid rgb(225, 226, 227)",
                            }}
                          >
                            {body}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="viewNotifyPopupFooter">
            <div className="row  mt-1 mb-1">
              <div>
                {/* <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={handleSubmit}
                >
                  Submit
                </button> */}
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewLeaveReport;
