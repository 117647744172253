import React, { useContext, useEffect, useRef, useState } from "react";
import "./summary.css";
import Spinner from "../../loading/spinner";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Fetchdata } from "../../hooks/getData";
import AuthContext from "../../context/auth-context";
import NepaliDate from "nepali-date-converter";
import UpperbarContext from "../../context/upperbar-context";
import AYearlySummary from "./yearlySummary";
import AMonthlySummary from "./monthSummary";
import { GetCurrMonth, GetCurrYear } from "../../hooks/dateConvertor";
import { FaRegCalendarAlt } from "react-icons/fa";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { AiOutlineSearch } from "react-icons/ai";

export default function AdminSummary() {
  const { User } = useContext(AuthContext);
  const [category, setCategory] = useState("2");
  const { appURL, fiscalYear, todayDate, mode } = useContext(UpperbarContext);
  const [depList, setDepList] = useState([]);
  const [subDepList, setSubDepList] = useState([]);

  const [department, setDepartment] = useState("-1");
  const [subDepartment, setSubDepartment] = useState("-1");
  const [sYear, setSYear] = useState(GetCurrYear());
  const [sMonth, setSMonth] = useState(GetCurrMonth());

  const [DFlag, setDFlag] = useState("N");
  const [yearExcel, setYearExcel] = useState(false);
  const [monthExcel, setMonthExcel] = useState(false);
  const [yearPdf, setYearPdf] = useState(false);
  const [monthPdf, setMonthPdf] = useState(false);

  const cur_year = new Date().getFullYear();
  const eYears = generateArrayOfYears(cur_year);

  const nYears = generateArrayOfNepYears(cur_year);

  function generateArrayOfYears(cur_year) {
    var max = cur_year;
    var min = 2021;
    var years = [];

    for (var i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  }

  useEffect(() => {
    deptList();
  }, []);

  const deptList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      IsActive: "A",
      Type: "POST",
      FetchURL: `${appURL}api/admin/department`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setDepList(postResult);
      } else {
      }
    });
  };

  // useEffect(() => {
  //   if (department && department !== "-1") {
  //     const dataForm = {
  //       ComID: User.CompanyId,
  //       StaffID: -1,
  //       DepartID: department,
  //       Flag: "S",
  //       Status: "1",
  //       BranchID: User.BranchId,
  //       FetchURL: `${appURL}api/admin/sub-department`,
  //       Type: "POST",
  //     };

  //     Fetchdata(dataForm).then(function (result) {
  //       if (result.StatusCode === 200) {
  //         const postResult = result.SubDepList;
  //         setSubDepList(postResult);
  //       } else {
  //         setSubDepList([]);
  //       }
  //     });
  //   }
  // }, [department]);

  useEffect(() => {
    if (category) {
      if (category === "2") {
        setSMonth(GetCurrMonth());
        setSYear(GetCurrYear());
      } else if (category === "1") {
        setSYear(GetCurrYear());
      }
    }
  }, [category]);

  function getNepaliDate(date) {
    const nepDate = new NepaliDate().getBS();
    //
    return nepDate;
  }

  function generateArrayOfNepYears(cur_year) {
    let nCur_date = getNepaliDate(cur_year);
    var max = nCur_date.year;
    var min = 2078;
    var years = [];

    // for (var i = max; i >= min; i--) {
    //   years.push(i);
    // }
    for (var i = min; i <= max; i++) {
      years.push(i);
    }
    return years;
  }

  return (
    <>
      <div className=" classatten-wrapper  mt-3">
        {/* <div className="row mt-3">
          <div className="page-header">
            <div className="text-start  page-title" >
              Attendance Summary{" "}
            </div>
            <div className="page-date">
              <div className="sec-content" >
                <FaRegCalendarAlt /> {todayDate} <span>|</span> Fiscal Year :{" "}
                {fiscalYear.StartDate}
                 
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div> */}
        <>
          {" "}
          <div>
            <div className="upper-dataTbl">
              <div className="uk-flex uk-flex-wrap uk-flex-between uk-flex-middle mb-3">
                <div className="uk-flex uk-flex-wrap">
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en"
                        ? "Select Category"
                        : "श्रेणी चयन गर्नुहोस्"}
                    </label>
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example "
                      onChange={(e) => {
                        setCategory(e.target.value);
                        setDepartment("-1");
                        setSubDepartment("-1");
                        setYearPdf(false);
                        setYearExcel(false);
                        setMonthExcel(false);
                        setMonthPdf(false);
                      }}
                      value={category}
                    >
                      <option disabled value="" selected>
                        {mode === "en"
                          ? "Select Category"
                          : "श्रेणी चयन गर्नुहोस्"}
                      </option>
                      <option value="2">
                        {mode === "en" ? "Monthly" : "मासिक"}
                      </option>
                      <option value="1">
                        {mode === "en" ? "Yearly" : "वार्षिक"}
                      </option>
                    </select>
                  </div>

                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en"
                        ? "Select Department"
                        : "विभाग चयन गर्नुहोस्"}
                    </label>
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example "
                      name="deparment"
                      onChange={(e) => {
                        setDepartment(e.target.value);
                        setSubDepartment("-1");
                      }}
                      value={department}
                    >
                      <option disabled value="" selected>
                        {mode === "en"
                          ? "Select Department"
                          : "विभाग चयन गर्नुहोस्"}
                      </option>
                      <option value="-1">
                        {" "}
                        {mode === "en" ? "Select All" : "सबै चयन गर्नुहोस्"}
                      </option>
                      {depList.map((list) => (
                        <>
                          <option
                            key={list.DepartmentID}
                            value={list.DepartmentID}
                          >
                            {list.Department}
                          </option>
                        </>
                      ))}
                    </select>
                  </div>

                  {/* {department !== "-1" && (
                    <div className="me-2 mt-3">
                      <label className="d-block text-start dark-gray mb-1">
                        {mode === "en"
                          ? "Select Sub-Department"
                          : "उप विभाग चयन गर्नुहोस्"}
                      </label>
                      <select
                        class="form-select form-select-sm"
                        aria-label="Default select example "
                        name="subDepartment"
                        onChange={(e) => {
                          setSubDepartment(e.target.value);
                        }}
                        value={subDepartment}
                      >
                        <option disabled value="" selected>
                          {mode === "en"
                            ? "Select Sub-Department"
                            : "उप विभाग चयन गर्नुहोस्"}
                        </option>
                        <option value="-1">
                          {" "}
                          {mode === "en" ? "Select All" : "सबै चयन गर्नुहोस्"}
                        </option>
                        {subDepList.map((list) => (
                          <>
                            <option
                              key={list.SubDepartID}
                              value={list.SubDepartID}
                            >
                              {list.SubDepartName}
                            </option>
                          </>
                        ))}
                      </select>
                    </div>
                  )} */}

                  {category === "2" && (
                    <>
                      <div className="me-2 mt-3">
                        <label className="d-block text-start dark-gray mb-1">
                          {mode === "en" ? "Select Year" : "वर्ष चयन गर्नुहोस्"}
                        </label>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example "
                          onChange={(e) => setSYear(e.target.value)}
                          value={sYear}
                        >
                          <option disabled value="" selected>
                            {mode === "en"
                              ? "Select Year"
                              : "वर्ष चयन गर्नुहोस्"}
                          </option>
                          {DFlag === "N"
                            ? nYears.map((list) => (
                                <>
                                  <option key={list} value={list}>
                                    {list}
                                  </option>
                                </>
                              ))
                            : eYears.map((list) => (
                                <>
                                  <option key={list} value={list}>
                                    {list}
                                  </option>
                                </>
                              ))}
                        </select>
                      </div>

                      <div className="me-2 mt-3">
                        <label className="d-block text-start dark-gray mb-1">
                          {mode === "en"
                            ? "Select Month"
                            : "महिना चयन गर्नुहोस्"}
                        </label>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example "
                          onChange={(e) => setSMonth(e.target.value)}
                          value={sMonth}
                        >
                          <option disabled value="" selected>
                            {mode === "en"
                              ? "Select Month"
                              : "महिना चयन गर्नुहोस्"}
                          </option>
                          {DFlag === "N" ? (
                            <>
                              <option value="01">
                                {mode === "en" ? "Baishakh" : "बैशाख"}
                              </option>
                              <option value="02">
                                {mode === "en" ? "Jestha" : "जेष्ठ"}
                              </option>
                              <option value="03">
                                {mode === "en" ? "Ashar" : "असार"}
                              </option>
                              <option value="04">
                                {mode === "en" ? "Shrawan" : "श्रावण"}
                              </option>
                              <option value="05">
                                {mode === "en" ? "Bhadra" : "भाद्र"}
                              </option>
                              <option value="06">
                                {mode === "en" ? "Asoj" : "असोज"}
                              </option>
                              <option value="07">
                                {mode === "en" ? "Kartik" : "कार्तिक"}
                              </option>
                              <option value="08">
                                {mode === "en" ? "Mangsir" : "मंसिर"}
                              </option>
                              <option value="09">
                                {mode === "en" ? "Poush" : "पौष"}
                              </option>
                              <option value="10">
                                {mode === "en" ? "Magh" : "माघ"}
                              </option>
                              <option value="11">
                                {mode === "en" ? "Falgun" : "फाल्गुन"}
                              </option>
                              <option value="12">
                                {mode === "en" ? "Chaitra" : "चैत्र"}
                              </option>
                            </>
                          ) : (
                            <>
                              <option value="01">January</option>
                              <option value="02">February</option>
                              <option value="03">March</option>
                              <option value="04">April</option>
                              <option value="05">May</option>
                              <option value="06">June</option>
                              <option value="07">July</option>
                              <option value="08">August</option>
                              <option value="09">September</option>
                              <option value="10">October</option>
                              <option value="11">November</option>
                              <option value="12">December</option>
                            </>
                          )}
                        </select>
                      </div>
                    </>
                  )}

                  {category === "1" && (
                    <div className="me-2 mt-3">
                      <label className="d-block text-start dark-gray mb-1">
                        {mode === "en" ? "Select Year" : "वर्ष चयन गर्नुहोस्"}
                      </label>
                      <select
                        class="form-select form-select-sm"
                        aria-label="Default select example "
                        onChange={(e) => setSYear(e.target.value)}
                        value={sYear}
                      >
                        <option disabled value="" selected>
                          {mode === "en" ? "Select Year" : "वर्ष चयन गर्नुहोस्"}
                        </option>
                        {DFlag === "N"
                          ? nYears.map((list) => (
                              <>
                                <option key={list} value={list}>
                                  {list}
                                </option>
                              </>
                            ))
                          : eYears.map((list) => (
                              <>
                                <option key={list} value={list}>
                                  {list}
                                </option>
                              </>
                            ))}
                      </select>
                    </div>
                  )}
                </div>

                <div className="">
                  {category === "1" && (
                    <div className="d-flex uk-flex-wrap uk-flex-middle uk-flex-right">
                      <div className="export-btn">
                        <button
                          uk-tooltip="Export Excel"
                          onClick={() => setYearExcel(true)}
                          className="me-1 ms-2 border-0"
                        >
                          <RiFileExcel2Fill size="1rem" color="#136438" />
                        </button>
                      </div>
                      <div className="export-btn">
                        <button
                          uk-tooltip="Export Pdf"
                          onClick={() => setYearPdf(true)}
                          className="mx-1 border-0"
                        >
                          <BsFileEarmarkPdfFill size="1rem" color="#ff0002" />
                        </button>
                      </div>
                    </div>
                  )}
                  {category === "2" && (
                    <div className="d-flex uk-flex-wrap uk-flex-middle uk-flex-right">
                      <div className="export-btn">
                        <button
                          uk-tooltip="Export Excel"
                          onClick={() => setMonthExcel(true)}
                          className="me-1 ms-2 border-0"
                        >
                          <RiFileExcel2Fill size="1rem" color="#136438" />
                        </button>
                      </div>
                      <div className="export-btn">
                        <button
                          uk-tooltip="Export Pdf"
                          onClick={() => setMonthPdf(true)}
                          className="mx-1 border-0"
                        >
                          <BsFileEarmarkPdfFill size="1rem" color="#ff0002" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <>
              {!category && (
                <div
                  className=" text-center d-flex flex-column justify-content-center align-items-center"
                  style={{ margin: "10% auto", width: "120px" }}
                >
                  <p className="initial-msg">
                    {mode === "en"
                      ? "Please provide input!"
                      : "कृपया इनपुट प्रदान गर्नुहोस्"}
                  </p>
                </div>
              )}
              {category === "1" && sYear && department && subDepartment && (
                <AYearlySummary
                  year={sYear}
                  DFlag={DFlag}
                  department={department}
                  subDepartment={subDepartment}
                  appURL={appURL}
                  yearExcel={yearExcel}
                  yearPdf={yearPdf}
                />
              )}

              {category === "2" && sMonth && department && subDepartment && (
                <AMonthlySummary
                  year={sYear}
                  month={sMonth}
                  DFlag={DFlag}
                  department={department}
                  subDepartment={subDepartment}
                  appURL={appURL}
                  monthExcel={monthExcel}
                  monthPdf={monthPdf}
                />
              )}
            </>
          </div>
        </>
      </div>
    </>
  );
}
