import React, { useContext, useEffect, useState, useRef } from "react";
import "./attendanceReport.css";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import NepaliDate from "nepali-date-converter";
import UpperbarContext from "../../context/upperbar-context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../loading/spinner";
import DataTable from "react-data-table-component";
import AuthContext from "../../context/auth-context";
import AAddAttendance from "./addAttendance";
import { Fetchdata } from "../../hooks/getData";
import { GetToDate } from "../../hooks/dateConvertor";
import StaffContext from "../organization/staffState/StaffContext";
import { FaRegCalendarAlt } from "react-icons/fa";
import PopUp from "../../attendance/popUp";
import AttendanceRoutePopup from "./AttendanceRoutePopup";
import AttendanceContext from "../attendanceState/AttendanceContext";
import Toast from "../../Toast";
import EditAttendanceRoute from "./EditAttendanceRoute";
import DeleteAttendanceRoute from "./DeleteAttendanceRoute";
import { GoPlus } from "react-icons/go";
import { AiOutlineSearch, AiOutlineEdit } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import { englishToNepaliNumber } from "nepali-number";

export default function AttendanceRoute() {
  const { User } = useContext(AuthContext);
  const {
    setRouteValues,
    routeValue,
    setRouteActive,
    routeList,
    initialRouteLoad,
    routeLoading,
    route,
    setRoute,
    deactivateRoute,
    handleRouteEdit,
    editRoutePop,
    setEditRoutePop,
    setRouteErrors,
    handleRouteDelete,
    deleteRoutePopUp,
    setDeleteRoutePopUp,
    originalList,
    setRouteList,
  } = useContext(AttendanceContext);

  const { fiscalYear, customStyles, todayDate, mode } =
    useContext(UpperbarContext);
  const [popUp, setPopUp] = useState(false);
  const searchInput = useRef("");

  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      // grow: 0,
      width: "70px",
      center: true,
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },

    {
      name: mode === "en" ? "Name" : "नाम",
      // grow: 2,
      // width: "150px",
      selector: (row) => row.Name,
    },
    {
      name: mode === "en" ? "SSID" : "एस एस आईडी",
      // grow: 2,
      // width: "150px",
      selector: (row) => row.SSID,
    },
    {
      name: mode === "en" ? "Status" : "स्थिति",
      // grow: 2,
      center: true,
      width: "140px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                // class="btn btn-sm actvspan"
                className="border-0 bg-transparent"
                onClick={() => changeStatus(row.RouteID, row.IsActive)}
              >
                <span
                  className=" badge rounded-pill bg-success"
                  style={{ fontSize: "11px" }}
                >
                  {checkStatus(row.IsActive)}
                </span>
              </button>
            </div>
          </>
        );
      },
    },
    {
      name: mode === "en" ? "Action" : "कार्य",
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                class="btn btn-sm editspan mx-1"
                onClick={() => handleRouteEdit(row)}
                uk-tooltip={mode === "en" ? "Edit" : "सम्पादन गर्नुहोस्"}
              >
                <AiOutlineEdit />
              </button>{" "}
              <button
                type="button"
                class="btn btn-sm deletespan mx-1"
                onClick={() => handleRouteDelete(row)}
                uk-tooltip={mode === "en" ? "Delete" : "हटाउनुहोस्"}
              >
                <MdOutlineDelete />
              </button>{" "}
            </div>
          </>
        );
      },
    },
  ];

  const addAttendanceRoute = (e) => {
    setPopUp(true);
    setRouteValues(routeValue);
    setRouteActive(false);
    setRouteErrors({});
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setRoute({ ...route, [name]: value });
  };

  const changeStatus = (routeID, routeActive) => {
    const obj = {
      routeid: routeID.toString(),
    };
    deactivateRoute(obj.routeid, routeActive);
  };

  const checkStatus = (routeActive) => {
    if (routeActive === "A") {
      return mode === "en" ? "Deactivate" : "निष्क्रिय";
    } else if (routeActive === "I") {
      return mode === "en" ? "Activate" : "सक्रिय";
    }
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Name"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setRouteList(srchResult);
      } else {
        setRouteList({});
      }
    } else {
      setRouteList(originalList);
    }
  };

  return (
    <>
      {/* <Toast /> */}
      <div className=" sumatten-wrapper  mt-3 ">
        {/* <div className="row mt-3">
          <div className="page-header">
            <div className="text-start  page-title">
              Attendance Route
            </div>
            <div className="page-date">
              <div className="sec-content">
                <FaRegCalendarAlt /> {todayDate} <span>|</span> Fiscal Year :{" "}
                {fiscalYear.StartDate}
                 
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div> */}
        <>
          {" "}
          <div>
            <div className="upper-dataTbl">
              <div className="uk-flex uk-flex-wrap uk-flex-between uk-flex-middle mb-3">
                <div className="uk-flex uk-flex-wrap">
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en"
                        ? " Select Active Route"
                        : "इन्टरनेटको सक्रिय मार्ग चयन गर्नुहोस्"}
                    </label>
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example "
                      name="activeRoute"
                      onChange={handleChange}
                      value={route.activeRoute}
                    >
                      <option disabled value="" selected>
                        {mode === "en"
                          ? " Select Active Route"
                          : "इन्टरनेटको सक्रिय मार्ग चयन गर्नुहोस्"}
                      </option>
                      <option value="-1">
                        {mode === "en" ? "Select All" : "सबै चयन गर्नुहोस्"}
                      </option>
                      <option value="A">
                        {" "}
                        {mode === "en"
                          ? " Active Route"
                          : "इन्टरनेटको सक्रिय मार्ग"}
                      </option>
                      <option value="I">
                        {" "}
                        {mode === "en"
                          ? " Deactive Route"
                          : "इन्टरनेटको निष्क्रिय मार्ग"}
                      </option>
                    </select>
                  </div>
                </div>

                <div className="">
                  <div className="d-flex uk-flex-wrap uk-flex-middle uk-flex-right">
                    <div className="btn-addlnote mt-3">
                      <button
                        type="button"
                        class="btn btn-sm"
                        style={{
                          background: "var(--button-color)",
                          color: "white",
                        }}
                        onClick={addAttendanceRoute}
                      >
                        <GoPlus color="#fff" className="me-1" />
                        {mode === "en"
                          ? "Add Attendance Route"
                          : "उपस्थिति इन्टरनेटको मार्ग थप्नुहोस्"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <>
              {routeLoading ? (
                <Spinner />
              ) : (
                <>
                  {" "}
                  <DataTable
                    columns={columns}
                    data={routeList}
                    customStyles={customStyles}
                    pagination
                    paginationRowsPerPageOptions={[10, 20, 50, 100]} // Customizable options
                    fixedHeader
                    fixedHeaderScrollHeight="335px"
                    highlightOnHover
                    pointerOnHover
                    progressPending={routeLoading}
                    responsive
                    subHeader
                    dense
                    striped
                    subHeaderComponent={
                      <>
                        <div className=" w-100">
                          <div className="d-flex uk-flex-middle justify-content-end">
                            <div>
                              <div class="uk-search uk-search-default">
                                <AiOutlineSearch className="search-icon" />
                                <input
                                  placeholder={
                                    mode === "en" ? "Search" : "खोजी गर्नुहोस्"
                                  }
                                  ref={searchInput}
                                  type="text"
                                  className="form-control form-control-sm searchField"
                                  onChange={searchHandler}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                  />
                </>
              )}
            </>
          </div>
        </>
      </div>

      <AttendanceRoutePopup setPopUp={setPopUp} popUp={popUp} />

      <EditAttendanceRoute
        setEditRoutePop={setEditRoutePop}
        editRoutePop={editRoutePop}
      />

      {deleteRoutePopUp && (
        <DeleteAttendanceRoute setDeleteRoutePopUp={setDeleteRoutePopUp} />
      )}
    </>
  );
}
