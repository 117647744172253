import React, { useContext, useEffect, useRef, useState } from "react";
import "../../../profile/profile.css";
import DataTable from "react-data-table-component";

import { Fetchdata } from "../../../hooks/getData";
import AuthContext from "../../../context/auth-context";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FiscalPopup from "./FiscalPopup";
import FiscalPopupEdit from "./FiscalPopupEdit";
import UpperbarContext from "../../../context/upperbar-context";
import StaffContext from "../staffState/StaffContext";
import { FaRegCalendarAlt } from "react-icons/fa";
import Toast from "../../../Toast";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import {
  AiOutlineSearch,
  AiOutlineEdit,
  AiTwotonePrinter,
} from "react-icons/ai";
import { GoPlus } from "react-icons/go";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { englishToNepaliNumber } from "nepali-number";
import { generatePdf } from "../../../hooks/PdfExcel";

export default function Fiscal() {
  const {
    fiscalYear,
    todayDate,
    expires,
    mode,
    appURL,
    userDetails,
    customStyles,
  } = useContext(UpperbarContext);
  const [fiscalPopup, setFiscalPopup] = useState(false);
  const [fiscalEditPopup, setFiscalEditPopup] = useState(false);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);

  const [isCurrent, setIsCurrent] = useState(false);
  const [flagId, setFlagId] = useState("");

  const [originalList, setOriginalList] = useState(null);
  const searchInput = useRef("");

  const fiscalValue = {
    fiscal: "",
    start: "",
    end: "",
  };
  const [fiscalFormValue, setFiscalFormValue] = useState(fiscalValue);
  const [fiscalFormError, setFiscalFormError] = useState({});

  const { User } = useContext(AuthContext);

  const addLeaveNote = (e) => {
    setFiscalPopup(true);

    setFiscalFormValue(fiscalValue);
    setIsCurrent(false);
  };

  const [titleId, setTitleID] = useState();
  const editPop = (datas) => {
    setFiscalEditPopup(true);
    setTitleID(datas.FID);
    setFiscalFormValue({
      fiscal: datas.FiscalYear,
      start: datas.StartDate,
      end: datas.EndDate,
    });
    setIsCurrent(datas.IsCurrent === "Yes" ? 1 : 0);
  };

  //API to hit Holiday status

  const stateInitial = [];
  const [newStat, setNewStat] = useState(stateInitial);

  const deactivateDepart = (ID, IsActive) => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID,
      Flag: "AI",
      FID: ID,
      IsActive: IsActive,
      FetchURL: `${appURL}api/admin/fiscal`,
      Type: "POST",
    };
    if (IsActive === "A") {
      dataForm.IsActive = "I";
    } else {
      dataForm.IsActive = "A";
    }
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        fscList();
        let statsN = JSON.parse(JSON.stringify(newStat));
        let pitchStatus;

        if (dataForm.IsActive === "A") {
          pitchStatus = "Activated";
        } else if (dataForm.IsActive === "I") {
          pitchStatus = "Deactivated";
        }

        setNewStat(statsN);
        toast.success(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  const changeStatus = (ID, IsActive) => {
    deactivateDepart(ID, IsActive);
  };

  const checkStatus = (IsActive) => {
    if (IsActive === "A") {
      return mode === "en" ? "Deactivate" : "निष्क्रिय";
    } else if (IsActive === "I") {
      return mode === "en" ? "Activate" : "सक्रिय";
    }
  };

  const handleFlag = (ID) => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.CompanyId,
      UserID: User.UID,
      Flag: "CF",
      FID: ID,
      IsCurrent: "Y",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/fiscal`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        fscList();
        toast.success(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      grow: 0,
      center: true,
      width: "70px",
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },
    {
      name: mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष",
      // center: true,
      // grow: 0,
      minWidth: "150px",
      selector: (row) =>
        mode === "en" ? row.FiscalYear : englishToNepaliNumber(row.FiscalYear),
    },
    {
      name: mode === "en" ? "Start Date" : "सुरू मिति",
      // center: true,
      // grow: 0,
      minWidth: "150px",
      selector: (row) =>
        mode === "en" ? row.StartDate : englishToNepaliNumber(row.StartDate),
    },
    {
      name: mode === "en" ? "End Date" : "अन्त्य मिति",
      // grow: 0,
      // center: true,
      minWidth: "150px",
      selector: (row) =>
        mode === "en" ? row.EndDate : englishToNepaliNumber(row.EndDate),
    },
    {
      name: mode === "en" ? "Current" : "वर्तमान",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck2"
              checked={row.IsCurrent === "Yes" ? true : false}
              style={{ fontSize: "12px", cursor: "pointer" }}
              onChange={() => {
                handleFlag(row.FID);
              }}
            />
          </>
        );
      },
    },
    {
      name: mode === "en" ? "Status" : "स्थिति",
      // grow: 0,
      center: true,
      width: "140px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                className="border-0 bg-transparent"
                onClick={() => changeStatus(row.FID, row.IsActive)}
              >
                <span
                  className=" badge rounded-pill bg-success"
                  style={{ fontSize: "11px" }}
                >
                  {checkStatus(row.IsActive)}
                </span>
              </button>
            </div>
          </>
        );
      },
    },
    {
      name: mode === "en" ? "Action" : "कार्य",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                class="btn btn-sm editspan"
                onClick={() => editPop(row)}
                uk-tooltip={mode === "en" ? "Edit" : "सम्पादन गर्नुहोस्"}
              >
                <AiOutlineEdit />
              </button>{" "}
            </div>
          </>
        );
      },
    },
  ];

  //API to hit fiscal year list
  const [fiscalList, setFiscalList] = useState([]);
  useEffect(() => {
    fscList();
  }, []);

  const fscList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      IsActive: "-1",
      Type: "POST",
      FetchURL: `${appURL}api/admin/fiscal`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setFiscalList(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setLoading(true);
        setOriginalList([]);
        setFiscalList([]);
      }
    });
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["FiscalYear"].toLowerCase().includes(srchQuery);
        //  ||
        // list["Name"].toLowerCase().includes(srchQuery)
      });

      if (srchResult) {
        setFiscalList(srchResult);
      } else {
        setFiscalList({});
      }
    } else {
      setFiscalList(originalList);
    }
  };

  const dataWithIndex = fiscalList.map((d, i) => ({
    ...d,
    Index: i + 1,
    Status: d.IsActive === "I" ? "Inactive" : "Active",
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Fiscal Year", field: "FiscalYear" },
    { header: "Start Date", field: "StartDate" },
    { header: "End Date", field: "EndDate" },
    { header: "Current", field: "IsCurrent" },
    { header: "Status", field: "IsActive" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    generatePdf(userDetails, exportColumns, dataWithIndex);
  };

  const dataToExcel = fiscalList.map((d, i) => ({
    "S.N.": i + 1,
    "Fiscal Year": d.FiscalYear,
    "Start Date": d.StartDate,
    "End Date": d.EndDate,
    Current: d.IsCurrent,
    Status: d.IsActive === "I" ? "Inactive" : "Active",
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Fiscal Year");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper  mt-3 ps-4 pe-4">
        <div className="row mt-3">
          <div className="page-header">
            <div className="text-start  page-title">
              {mode === "en" ? "Fiscal" : "वित्तीय"}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <span className="me-2 ">Expires in: {expires} </span>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>
        <>
          <div className="sec-dataTable">
            {/* <div className="upper-dataTbl">
              <div className="btn-addlnote mb-3">
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--button-color)",
                    color: "white",
                  }}
                  onClick={addLeaveNote}
                >
                  {mode === "en" ? "Add New" : "नयाँ थप्नुहोस्"}
                </button>
              </div>
            </div> */}

            <div className="upper-dataTbl">
              <div className="d-flex uk-flex-middle uk-flex-right mb-3">
                <div className="btn-addlnote mt-3">
                  <button
                    type="button"
                    class="btn btn-sm"
                    style={{
                      background: "var(--button-color)",
                      color: "white",
                    }}
                    onClick={addLeaveNote}
                  >
                    <GoPlus color="#fff" className="me-1" />
                    {mode === "en" ? "Add New" : "नयाँ थप्नुहोस्"}
                  </button>
                </div>
                <div className="export-btn">
                  <button
                    uk-tooltip="Export Excel"
                    onClick={toExcel}
                    className="me-1 ms-2 border-0"
                  >
                    <RiFileExcel2Fill size="1rem" color="#136438" />
                  </button>
                </div>
                <div className="export-btn">
                  <button
                    uk-tooltip="Export Pdf"
                    onClick={toPdf}
                    className="mx-1 border-0"
                  >
                    <BsFileEarmarkPdfFill size="1rem" color="#ff0002" />
                  </button>
                </div>
                {/* <div className="export-btn">
                  <button
                    uk-tooltip="Print"
                    onClick={toPrint}
                    className="mx-1 border-0"
                  >
                    <AiTwotonePrinter size="1rem" color="#555" />
                  </button>
                </div> */}
              </div>
            </div>

            <DataTable
              columns={columns}
              data={fiscalList}
              customStyles={customStyles}
              pagination
              paginationRowsPerPageOptions={[10, 20, 50, 100]} // Customizable options
              fixedHeader
              fixedHeaderScrollHeight="370px"
              highlightOnHover
              pointerOnHover
              responsive
              dense
              striped
              subHeader
              subHeaderComponent={
                <>
                  <div className=" w-100">
                    <div className="d-flex uk-flex-middle justify-content-end">
                      <div>
                        <div class="uk-search uk-search-default">
                          <AiOutlineSearch className="search-icon" />
                          <input
                            placeholder={
                              mode === "en" ? "Search" : "खोजी गर्नुहोस्"
                            }
                            ref={searchInput}
                            type="text"
                            className="form-control form-control-sm searchField"
                            onChange={searchHandler}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
            />
          </div>
        </>
      </div>

      <FiscalPopup
        setFiscalPopup={setFiscalPopup}
        reload={reload}
        setReload={setReload}
        fscList={fscList}
        fiscalFormValue={fiscalFormValue}
        setFiscalFormValue={setFiscalFormValue}
        fiscalFormError={fiscalFormError}
        setFiscalFormError={setFiscalFormError}
        isCurrent={isCurrent}
        setIsCurrent={setIsCurrent}
        fiscalPopup={fiscalPopup}
      />

      <FiscalPopupEdit
        fiscalEditPopup={fiscalEditPopup}
        setFiscalEditPopup={setFiscalEditPopup}
        reload={reload}
        setReload={setReload}
        fscList={fscList}
        fiscalFormValue={fiscalFormValue}
        setFiscalFormValue={setFiscalFormValue}
        fiscalFormError={fiscalFormError}
        setFiscalFormError={setFiscalFormError}
        titleId={titleId}
        isCurrent={isCurrent}
        setIsCurrent={setIsCurrent}
      />
    </>
  );
}
