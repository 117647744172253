import React, { useContext } from "react";
import CloseIcon from "../../../images/CloseIcon.svg";
import UpperbarContext from "../../context/upperbar-context";
import AttendanceContext from "../attendanceState/AttendanceContext";
import "./deletePop.css";

export default function DeleteAttendanceRoute({ setDeleteRoutePopUp }) {
  const { deleteRoute } = useContext(AttendanceContext);
  const { mode } = useContext(UpperbarContext);
  return (
    <>
      <div className="container confirm-popup">
        <div className="confirm-popup-inner ">
          <div className="popUpHeader ps-0 pe-0">
            <div className="popUpDelTitle">Elite Office</div>
            <div className="popUpClose">
              <img
                style={{ paddingRight: "10px", cursor: "pointer" }}
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={() => setDeleteRoutePopUp(false)}
              />
            </div>
          </div>

          <div className="confirm-body ps-4 ">
            <div className="row text-start ">
              <div className="delText pt-0">
                {mode === "en"
                  ? "Are you sure want to remove this?"
                  : "के तपाइँ यसलाई हटाउन निश्चित हुनुहुन्छ?"}
              </div>
            </div>
          </div>

          <div className="confirm-footer">
            <div className="row  mt-2 mb-2">
              <div>
                <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "#0079bf", color: "white" }}
                  onClick={deleteRoute}
                >
                  {mode === "en" ? "Okay" : "बुझाउनुहोस्"}
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  style={{ color: "white" }}
                  onClick={() => setDeleteRoutePopUp(false)}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
