import React from "react";

const AddressContact = ({ handleChange, formValues, formErrors, mode }) => {
  return (
    <>
      <div className="row text-start ">
        <div className="form-group">
          <label htmlFor="address" style={{ fontSize: "12px" }}>
            {mode === "en" ? "Address" : "ठेगाना"}
          </label>
          <input
            id="address"
            style={{ fontSize: "13px" }}
            type="text"
            className="form-control form-control-sm "
            name="address"
            placeholder={mode === "en" ? "Address" : "ठेगाना"}
            value={formValues.address}
            onChange={handleChange}
          />
          {/* {formErrors.address && (
      <p className="errormsg">{formErrors.address}</p>
    )} */}
        </div>
      </div>
      <div className="row text-start ">
        <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
            <label htmlFor="contactPerson" style={{ fontSize: "12px" }}>
              {mode === "en" ? "Contact Person" : "सम्पर्क व्यक्ति"}
            </label>
            <input
              id="contactPerson"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="contactPerson"
              placeholder={mode === "en" ? "Contact Person" : "सम्पर्क व्यक्ति"}
              value={formValues.contactPerson}
              onChange={handleChange}
            />
            {/* {formErrors.contactPerson && (
      <p className="errormsg">{formErrors.contactPerson}</p>
    )} */}
          </div>
        </div>

        <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
            <label htmlFor="contact" style={{ fontSize: "12px" }}>
              {mode === "en" ? "Contact" : "सम्पर्क"}
            </label>
            <input
              id="contact"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="contact"
              placeholder={mode === "en" ? "Contact" : "सम्पर्क"}
              value={formValues.contact}
              onChange={handleChange}
            />
            {/* {formErrors.contact && (
      <p className="errormsg">{formErrors.contact}</p>
    )} */}
          </div>
        </div>
      </div>
      <div className="row text-start ">
        <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
            <label htmlFor="email" style={{ fontSize: "12px" }}>
              {mode === "en" ? "Email" : "इमेल"}
            </label>
            <input
              id="email"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="email"
              placeholder={mode === "en" ? "Email" : "इमेल"}
              value={formValues.email}
              onChange={handleChange}
            />
            {/* {formErrors.email && (
      <p className="errormsg">{formErrors.email}</p>
    )} */}
          </div>
        </div>

        <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
            <label htmlFor="website" style={{ fontSize: "12px" }}>
              {mode === "en" ? "Website" : "वेबसाइट"}
            </label>
            <input
              id="website"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="website"
              placeholder={mode === "en" ? "Website" : "वेबसाइट"}
              value={formValues.website}
              onChange={handleChange}
            />
            {/* {formErrors.website && (
      <p className="errormsg">{formErrors.website}</p>
    )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressContact;
