import React, { useContext, useState, useEffect } from "react";
import Employee from "./Employee";
import "./dashboard.css";
import { VscCalendar } from "react-icons/vsc";
import UpperbarContext from "../context/upperbar-context";
import Organization from "./Organization";
import Attendance from "./Attendance";
import Birthday from "./Birthday";
import Holiday from "./Holiday";
import TodayAttendance from "./TodayAttendance";
import TodayLeave from "./TodayLeave";
import "../profile/profile.css";
import AuthContext from "../context/auth-context";
import placeholder from "../../images/placeholder.png";
import { Fetchdata } from "../hooks/getData";
import { ShowImgPreview } from "../hooks/imagePreview";
import Toast from "../Toast";
import Notification from "./Notification";
import { englishToNepaliNumber } from "nepali-number";
const Dashboard = () => {
  const { User } = useContext(AuthContext);
  const { fiscalYear, expires, todayDate, appURL, mode, darkText } =
    useContext(UpperbarContext);

  const [userDetails, setUserDetails] = useState("");
  const [imgPrv, setImgPrv] = useState(false);
  const [ImgPreview, setImgPreview] = useState("");

  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  useEffect(() => {
    // const cur_user = localStorage.getItem("token");
    //
    // cur_user.length && setUserDetails(JSON.parse(cur_user));

    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UID: User.UID,
      NotificationToken: "strsadasdsing",
      DeviceID: "sdasd",
      FetchURL: `${appURL}api/checksession`,
      Type: "POST",
    };

    Fetchdata(dataForm)
      .then(function (result) {
        if (result.StatusCode === 200) {
          const postResult = result.Values[0] ? result.Values[0] : "";
          if (postResult) {
            // postResult.CompanyId = "ES25";
            // postResult.Username = User.UserName;
            setUserDetails(postResult);
          } else {
            const cur_user = localStorage.getItem("token");

            cur_user.length && setUserDetails(JSON.parse(cur_user));
          }
        } else {
          const cur_user = localStorage.getItem("token");

          cur_user.length && setUserDetails(JSON.parse(cur_user));
        }
      })
      .then((res) => {
        //
      });
  }, []);

  const [activeTab, setActiveTab] = useState({
    tab1: true,
    tab2: false,
  });

  const handleTab1 = () => {
    setActiveTab({
      tab1: true,
      tab2: false,
    });
  };
  const handleTab2 = () => {
    setActiveTab({
      tab1: false,
      tab2: true,
    });
  };

  const [activeTab1, setActiveTab1] = useState({
    tab1: true,
    tab2: false,
  });

  const handleTTab1 = () => {
    setActiveTab1({
      tab1: true,
      tab2: false,
    });
  };
  const handleTTab2 = () => {
    setActiveTab1({
      tab1: false,
      tab2: true,
    });
  };

  const name =
    userDetails.FirstName +
    " " +
    userDetails.MiddleName +
    " " +
    userDetails.LastName;
  //

  //Initiate date object
  const dt_date1 = new Date();
  const dt_date2 = new Date(userDetails.EnrollDate);
  //Get the Timestamp
  let date1 = dt_date1.getTime();
  let date2 = dt_date2.getTime();

  let calc;
  //Check which timestamp is greater
  if (date1 > date2) {
    calc = new Date(date1 - date2);
  } else {
    calc = new Date(date2 - date1);
  }
  //Retrieve the date, month and year
  let calcFormatTmp =
    calc.getDate() + "-" + (calc.getMonth() + 1) + "-" + calc.getFullYear();
  //Convert to an array and store
  let calcFormat = calcFormatTmp.split("-");
  //Subtract each member of our array from the default date
  let days_passed = parseInt(Math.abs(calcFormat[0]) - 1);
  let months_passed = parseInt(Math.abs(calcFormat[1]) - 1);
  let years_passed = parseInt(Math.abs(calcFormat[2] - 1970));

  useEffect(() => {
    if (years_passed === 0) {
      setYear("");
    } else {
      setYear(years_passed);
    }
    if (months_passed === 0) {
      setMonth("");
    } else {
      setMonth(months_passed);
    }
    if (days_passed === 0) {
      setDay("");
    } else {
      setDay(days_passed);
    }
  }, [years_passed, months_passed, days_passed]);

  return (
    <>
      {/* <Toast /> */}

      <div className="uk-flex uk-flex-between uk-flex-wrap uk-flex-middle head">
        <div className="uk-margin-right">
          <p className="uk-text-bold" style={darkText}>
            {mode === "en" ? "Welcome!" : "स्वागत छ"}
          </p>
        </div>

        <div className="uk-margin-left">
          <div className="sec-content" style={darkText}>
            <span className="me-2">Expires in: {expires} </span> <VscCalendar />{" "}
            {todayDate} <span>|</span>{" "}
            {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :
            {fiscalYear.StartDate}
            {fiscalYear.EndDate}
          </div>
        </div>
      </div>
      <section className="ps-sm-4 ps-0 pe-0">
        <div className="uk-grid uk-grid-match dashboard-wrapper">
          <div className="uk-width-1-3@m dasboard-wrap ps-3">
            <div className="box profile-dashboard uk-margin-top">
              <h5 className="uk-text-left m-0 mb-2">
                {mode === "en" ? "Profile" : "प्रोफाइल"}
              </h5>
              <div className="uk-flex uk-flex-middle m-0">
                <div className="contentLogo tl">
                  <div className="mgmtImg tl">
                    <img
                      src={
                        userDetails.UserImage === null
                          ? placeholder
                          : userDetails.UserImage
                      }
                      alt="image"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setImgPreview(
                          userDetails.UserImage === null
                            ? placeholder
                            : userDetails.UserImage
                        );
                        setImgPrv(true);
                      }}
                      onError={(e) => (e.target.src = placeholder)}
                    />
                  </div>
                </div>
                <div>
                  <h6 style={darkText} className="ps-3 uk-text-left m-0 mb-1">
                    {name}
                  </h6>
                  <span
                    style={{ lineHeight: "22px" }}
                    className="ps-3 uk-text-left d-block"
                  >
                    {mode === "en" ? "Username" : "प्रयोगकर्ता नाम"}:{" "}
                    {userDetails.Username} <br />
                    {userDetails.DesignationName} <br />
                    {userDetails.DepartmentName},{" "}
                    {userDetails.SubDepartmentName}
                    <br />
                    {mode === "en" ? "Service Period" : "सेवा अवधि"}:{" "}
                    {mode === "en" ? year : englishToNepaliNumber(year)}{" "}
                    {year === "" ? "" : mode === "en" ? "years" : "वर्ष"}{" "}
                    {mode === "en" ? month : englishToNepaliNumber(month)}{" "}
                    {month === "" ? "" : mode === "en" ? "months" : "महिना"}{" "}
                    {mode === "en" ? day : englishToNepaliNumber(day)}{" "}
                    {day === "" ? "" : mode === "en" ? "days" : "दिन"}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="uk-width-expand dasboard-wrap">
            <Organization />
          </div>
        </div>
      </section>
      <section className="ps-sm-4 ps-0 pe-0">
        <div className="uk-grid uk-grid-match uk-child-width-1-3@m dashboard-wrapper dashStat">
          <div className="dasboard-wrap ps-3 ">
            <Employee />
          </div>

          <div className="dasboard-wrap ps-3">
            <Attendance />
          </div>
          <div className="dasboard-wrap ps-3">
            <Notification />
          </div>
        </div>

        {/* <div className="uk-grid uk-grid-match uk-child-width-1-1 dashboard-wrapper ">
          <div className="dasboard-wrap">
            <QuickAccess />
          </div>
        </div> */}

        <div className="uk-grid uk-grid-match uk-child-width-1-2@m dashboard-wrapper mt-0">
          <div className="dasboard-wrap uk-margin-top ps-3 dashInfo">
            <div className="box insidePopup">
              <ul>
                <li
                  className={activeTab1.tab1 === true ? "active" : ""}
                  onClick={handleTTab1}
                >
                  {mode === "en" ? "Today Attendance" : "आजको उपस्थिति"}
                </li>
                <li
                  className={activeTab1.tab2 === true ? "active" : ""}
                  onClick={handleTTab2}
                >
                  {mode === "en" ? "Today Leave" : "आज बिदामा"}
                </li>
              </ul>
              <div>
                {activeTab1.tab1 && <TodayAttendance />}
                {activeTab1.tab2 && <TodayLeave />}
              </div>
            </div>
          </div>
          <div className="dasboard-wrap uk-margin-top ps-3 dashInfo">
            <div className="box insidePopup">
              <ul>
                <li
                  className={activeTab.tab1 === true ? "active" : ""}
                  onClick={handleTab1}
                >
                  {mode === "en" ? "Upcoming Birthday" : "आगामी जन्मदिन"}
                </li>
                <li
                  className={activeTab.tab2 === true ? "active" : ""}
                  onClick={handleTab2}
                >
                  {mode === "en" ? "Upcoming Holiday" : "आगामी बिदा"}
                </li>
              </ul>
              <div>
                {activeTab.tab1 && <Birthday />}
                {activeTab.tab2 && <Holiday />}
              </div>
            </div>
          </div>
        </div>
      </section>

      {imgPrv &&
        ShowImgPreview({
          img: ImgPreview,
          setTrigger: setImgPrv,
        })}
    </>
  );
};

export default Dashboard;
