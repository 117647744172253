import React, { useContext, useEffect, useState } from "react";
import "../../../leaveNotes/leaveNotePopup.css";
import "../subdepartment/SubdepartmentPopup.css";
import CloseIcon from "../../../../images/CloseIcon.svg";
import AuthContext from "../../../context/auth-context";
import { Fetchdata } from "../../../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UpperbarContext from "../../../context/upperbar-context";
import Toast from "../../../Toast";
import $ from "jquery";

export default function ResignationViewPopup({
  setViewPopup,
  viewData,
  viewPopup,
}) {
  const { User } = useContext(AuthContext);
  const viewList = viewData || {};
  const { mode, appURL } = useContext(UpperbarContext);
  const closePopUp = (e) => {
    setViewPopup(false);
    $(".editViewPopBg").fadeOut(500);
    $(".editViewPop").slideUp(500);
  };

  useEffect(() => {
    if (viewPopup) {
      $(".editViewPopBg").fadeIn(500);
      $(".editViewPop").slideDown(500);
    }
  }, [viewPopup]);

  const data = [
    {
      id: 1,
      title: mode === "en" ? "Fullname" : "पुरानाम",
      desc: viewList?.FullName,
    },
    {
      id: 2,
      title: mode === "en" ? "Department" : "विभाग",
      desc: viewList?.Department,
    },
    {
      id: 3,
      title: mode === "en" ? "Join Date" : "सामेल हुने मिति",
      desc: viewList?.JoinDate,
    },
    {
      id: 4,
      title: mode === "en" ? "Leave Date" : "मिति छोड्नुहोस्",
      desc: viewList?.LeaveDate,
    },
    {
      id: 5,
      title: mode === "en" ? "Type" : "टाइप ",
      desc: viewList?.Type,
    },

    {
      id: 6,
      title: mode === "en" ? "Reason" : "कारण",
      desc: viewList?.Reason,
    },
    {
      id: 7,
      title: mode === "en" ? "Status" : "स्थिति",
      desc:
        viewList?.IsApproved === "P"
          ? mode === "en"
            ? "Pending"
            : "विचाराधीन"
          : viewList?.IsApproved === "Y"
          ? mode === "en"
            ? "Approved"
            : "स्वीकृत"
          : viewList?.IsApproved === "N"
          ? mode === "en"
            ? "Rejected"
            : "अस्वीकार गरियो"
          : "",
    },
    {
      id: 8,
      title: mode === "en" ? "Approved Date" : "स्वीकृत मिति ",
      desc: viewList?.ApprovedDate || "Not mentioned",
    },
  ];

  return (
    <>
      <div className="container newpopup-bg editViewPopBg">
        <div className="newpopup editViewPop">
          <div className="newpopup-head ps-0 pe-0">
            <div className="popUpTitle">
              {" "}
              {mode === "en" ? "View Resignation" : "राजीनामा हेर्नुहोस्"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div className="newpopup-body ps-3 pe-3">
            <div className="content-section main-content">
              <div className="row p-2">
                {data.map((props) => {
                  const { id, title, desc } = props;
                  return (
                    <div className="col-md-6" key={id}>
                      <h4 className="the__title">{title}</h4>
                      <p className="the__content">{desc}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="new-footer">
            <div className="row  mt-1 mb-1">
              <div>
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
