import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "../../../../images/CloseIcon.svg";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import $ from "jquery";
import Toast from "../../../Toast";
import UpperbarContext from "../../../context/upperbar-context";
import AuthContext from "../../../context/auth-context";
import { capitalizeFirstLetter } from "../../../hooks/dateConvertor";

export default function EditAreaPopup({
  editPopup,
  setEditPopup,
  perEditSubmit,
  setPerEditSubmit,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
  editData,
  checkedActive,
  setCheckedActive,
  areaUnderList,
}) {
  const { appURL, sidePanelBg, mode } = useContext(UpperbarContext);
  const { User } = useContext(AuthContext);
  const closePopUp = (e) => {
    setEditPopup(false);
    $(".editAreaPopBg").fadeOut(300);
    $(".editAreaPop").slideUp(500);
    setPerEditSubmit(false);
    setFormErrors({});
  };

  useEffect(() => {
    if (editPopup) {
      $(".editAreaPopBg").fadeIn(500);
      $(".editAreaPop").slideDown(500);
    }
  }, [editPopup]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: capitalizeFirstLetter(value) });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = mode === "en" ? "Required" : "आवश्यक छ";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setPerEditSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && perEditSubmit) {
      editData();
      setPerEditSubmit(false);
    }
  }, [formErrors]);

  return (
    <>
      {/* <Toast /> */}
      <div className="container popup-wrapper editAreaPopBg">
        <div className="popup-inner editAreaPop" >
          <div className="popUpHeader ps-0 pe-0" style={sidePanelBg}>
            <div className="popUpTitle">
              {" "}
              {mode === "en" ? "Edit Area" : "क्षेत्र सम्पादन थप्नुहोस्"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div className="popUpBody ps-3 pe-3">
            <div className="form-padding">
              <div className="row text-start">
                <div className="form-group">
                  <label htmlFor="name" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Name" : "नाम"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="name"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="name"
                    placeholder={mode === "en" ? "Name" : "नाम"}
                    value={formValues.name}
                    onChange={handleChange}
                  />
                  {formErrors.name && (
                    <p className="errormsg">{formErrors.name}</p>
                  )}
                </div>
              </div>

              <div className="row text-start">
                <div className="form-group">
                  <label htmlFor="under" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Under" : "अन्तर्गत"}
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    name="under"
                    id="under"
                    value={formValues.under}
                    onChange={handleChange}
                  >
                    <option disabled value="" selected>
                      {mode === "en" ? "Under" : "अन्तर्गत"}
                    </option>
                    <option value="0">
                      {mode === "en" ? "Primary" : "प्राथमिक"}
                    </option>
                    {areaUnderList.map((props) => {
                      return (
                        <option key={props.AreaID} value={props.AreaID}>
                          {props.Area}
                        </option>
                      );
                    })}
                  </select>
                  {/* {formErrors.under && (
                  <p className="errormsg">{formErrors.under}</p>
                )} */}
                </div>
              </div>
              <div className="row text-start">
                <div className="form-group">
                  <label htmlFor="type" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Select Type" : "प्रकार चयन गर्नुहोस्"}
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    name="type"
                    id="type"
                    value={formValues.type}
                    onChange={handleChange}
                  >
                    <option disabled value="" selected>
                      {mode === "en" ? "Select Type" : "प्रकार चयन गर्नुहोस्"}
                    </option>
                    <option value="Continent">
                      {mode === "en" ? "Continent" : "महादेश"}
                    </option>
                    <option value="Country">
                      {mode === "en" ? "Country" : "देश"}
                    </option>
                    <option value="State">
                      {mode === "en" ? "State" : "राज्य"}
                    </option>
                    <option value="District">
                      {mode === "en" ? "District" : "जिल्ला"}
                    </option>
                    <option value="Municipality/VDC">
                      {mode === "en" ? "Municipality/VDC" : "नगरपालिका/ गाविस"}
                    </option>
                    <option value="Street">
                      {mode === "en" ? "Street" : "सडक"}
                    </option>
                  </select>
                  {/* {formErrors.type && (
                  <p className="errormsg">{formErrors.type}</p>
                )} */}
                </div>
              </div>
              <div className="row text-start ">
                <div className="form-group">
                  <div class="form-check" style={{ fontSize: "12px" }}>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="active"
                      name="active"
                      checked={checkedActive}
                      onChange={() => setCheckedActive(!checkedActive)}
                    />
                    <label
                      style={{ fontSize: "12px", cursor: "pointer" }}
                      class="form-check-label"
                      htmlFor="active"
                    >
                      {mode === "en" ? "Active" : "सक्रिय"}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ln-popUpFooter">
            <div className="row  mt-1 mb-1">
              <div>
                <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={handleSubmit}
                >
                  {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
