import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import AuthContext from "../../../context/auth-context";
import UpperbarContext from "../../../context/upperbar-context";
import Toast from "../../../Toast";
import "../ledger report/ledger.css";
import { Fetchdata } from "../../../hooks/getData";
import Spinner from "../../../loading/spinner";
export default function Report() {
  const { User } = useContext(AuthContext);

  //   const searchInput = useRef("");
  const { fiscalYear, todayDate, appURL, userDetails, darkText, mode } =
    useContext(UpperbarContext);

  //   API to day book list
  const [loading, setLoading] = useState(true);
  const [reportList, setReportList] = useState([]);

  useEffect(() => {
    reportLst();
  }, []);

  const reportLst = () => {
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      CID: "1",
      Flag: "SR",
      BranchID: Branch,
      FiscalID: Fiscal,
      Type: "POST",
      FetchURL: `${appURL}api/acc/report/trial-balance`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setReportList(postResult);
        setLoading(false);
      } else {
        setReportList([]);
        setLoading(false);
      }
    });
  };

  const groupedByName = reportList.reduce((acc, curr) => {
    if (acc[curr.AccGroup]) {
      acc[curr.AccGroup].push(curr);
    } else {
      acc[curr.AccGroup] = [curr];
    }
    return acc;
  }, {});

  const newGroupList = Object.keys(groupedByName).map((name) => {
    const data = groupedByName[name];
    const drTotal = data.reduce((acc, curr) => acc + curr.DrAmt, 0);
    const crTotal = data.reduce((acc, curr) => acc + curr.CrAmt, 0);
    return { AccGroup: name, data, drTotal, crTotal };
  });


  const [visibleRows, setVisibleRows] = useState(
    Array(reportList.length).fill(true)
  );

  const toggleVisibility = (index) => {
    setVisibleRows((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  let dr = reportList.reduce(function (prev, current) {
    return prev + +current.DrAmt;
  }, 0);
  let cr = reportList.reduce(function (prev, current) {
    return prev + +current.CrAmt;
  }, 0);

  return (
    <>
      {/* <Toast /> */}

      <>
        <div className="mt-3" style={{ overflow: "auto" }}>
          {loading ? (
            <Spinner />
          ) : (
            <>
              {reportList.length > 0 ? (
                <table className="uk-table reportTable">
                  <tr>
                    <td className="rdt_TableHeadRow tableHead">
                      {mode === "en" ? "Ledger" : "लेजर"}
                    </td>
                    <td
                      className="rdt_TableHeadRow tableHead text-end"
                      width="150px"
                    >
                      {mode === "en" ? "Debit Amount" : "डेबिट रकम"}
                    </td>
                    <td
                      className="rdt_TableHeadRow tableHead text-end"
                      width="150px"
                    >
                      {mode === "en" ? "Credit Amount" : "क्रेडिट रकम"}
                    </td>
                  </tr>
                  {newGroupList.map((group, index) => {
                    const isRowVisible = visibleRows[index];

                    return (
                      <>
                        <tr>
                          <td className="rdt_TableCell tablecell " colSpan="6">
                            <button
                              type="button"
                              className=" btn btn-primary border-0 trial-btn"
                              onClick={() => toggleVisibility(index)}
                            >
                              {isRowVisible ? "+" : "-"}
                            </button>{" "}
                            {group.AccGroup}
                          </td>
                        </tr>
                        {!isRowVisible && (
                          <>
                            {group.data.map((item, index) => {
                              // let totalDr = 0;
                              // let totalCr = 0;
                              // totalDr += item.DrAmt;
                              // totalCr += item.CrAmt;
                              return (
                                <>
                                  <tr>
                                    <td
                                      style={{ minWidth: "200px" }}
                                      className="rdt_TableCell tablecell"
                                    >
                                      {item.Ledger}
                                    </td>
                                    <td
                                      style={{ minWidth: "150px" }}
                                      className="rdt_TableCell tablecell text-end"
                                    >
                                      {item.DrAmt.toFixed(2)}
                                    </td>
                                    <td
                                      style={{ minWidth: "150px" }}
                                      className="rdt_TableCell tablecell text-end"
                                    >
                                      {item.CrAmt.toFixed(2)}
                                    </td>
                                  </tr>
                                  {/* <tr className="reportTotal">
                                    <td className="rdt_TableCell tablecell fw-bold text-end">
                                      {mode === "en" ? "Total" : "कुल"}
                                    </td>
                                    <td className="rdt_TableCell tablecell fw-bold text-end">
                                      {totalDr.toFixed(2)}
                                    </td>
                                    <td className="rdt_TableCell tablecell fw-bold text-end">
                                      {totalCr.toFixed(2)}
                                    </td>
                                  </tr> */}
                                </>
                              );
                            })}

                            <tr className="reportTotal">
                              <td className="rdt_TableCell tablecell fw-bold text-end">
                                {mode === "en" ? "Total" : "कुल"}
                              </td>
                              <td className="rdt_TableCell tablecell fw-bold text-end">
                                {group.drTotal.toFixed(2)}
                              </td>
                              <td className="rdt_TableCell tablecell fw-bold text-end">
                                {group.crTotal.toFixed(2)}
                              </td>
                            </tr>
                          </>
                        )}
                      </>
                    );
                  })}

                  <tfoot>
                    <tr className="reportTotal">
                      <td className="rdt_TableCell tablecell text-end">
                        <strong>
                          {mode === "en" ? "Grand Total" : "कूल जम्मा"}
                        </strong>
                      </td>
                      <td className="rdt_TableCell tablecell text-end">
                        <strong>{dr.toFixed(2)}</strong>
                      </td>
                      <td className="rdt_TableCell tablecell text-end">
                        <strong>{cr.toFixed(2)}</strong>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              ) : (
                <p className="text-center">
                  {mode === "en" ? "No Data" : "डाटा उपलब्ध छैन"}
                </p>
              )}
            </>
          )}
        </div>
      </>
    </>
  );
}
