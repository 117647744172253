import React, { useContext, useEffect, useState } from "react";
import { FaUsers, FaBuilding } from "react-icons/fa";
import { GiSuitcase } from "react-icons/gi";
import AuthContext from "../context/auth-context";
import UpperbarContext from "../context/upperbar-context";
import { Fetchdata } from "../hooks/getData";
import { englishToNepaliNumber } from "nepali-number";

const Organization = () => {
  const { mode,appURL } = useContext(UpperbarContext);
  const { User } = useContext(AuthContext);
  const [orgList, setOrgList] = useState([]);

  useEffect(() => {
    Orglst();
  }, []);

  const Orglst = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      DFlag: "OG",
      Type: "POST",
      FetchURL: `${appURL}api/admin/ref-values`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values[0] ? result.Values[0] : "";

        setOrgList(postResult);
      } else {
        setOrgList([]);
      }
    });
  };

  const department =
    mode === "en"
      ? orgList.DepartmentCt
      : englishToNepaliNumber(orgList.DepartmentCt);
  const subdepartment =
    mode === "en"
      ? orgList.SubDepartmentCt
      : englishToNepaliNumber(orgList.SubDepartmentCt);
  const designation =
    mode === "en"
      ? orgList.DesignationCt
      : englishToNepaliNumber(orgList.DesignationCt);
  const staff =
    mode === "en" ? orgList.StaffCt : englishToNepaliNumber(orgList.StaffCt);

  const org = [
    {
      id: 1,
      number: staff,
      title: mode === "en" ? "Staff" : "कर्मचारी",
      icon: <FaUsers size="1.5rem" color="#004aad" />,
    },
    {
      id: 2,
      number: department,
      title: mode === "en" ? "Department" : "विभाग",
      icon: <FaBuilding size="1.5rem" color="#004aad" />,
    },
    {
      id: 3,
      number: subdepartment,
      title: mode === "en" ? "Sub-Department" : "उप विभाग",
      icon: <FaBuilding size="1.5rem" color="#004aad" />,
    },
    {
      id: 4,
      number: designation,
      title: mode === "en" ? "Designation" : "पदनाम",
      icon: <GiSuitcase size="1.5rem" color="#004aad" />,
    },
  ];

  return (
    <>
      <div className="uk-grid uk-child-width-1-2@m dashOrg">
        {org.map((props) => {
          const { id, number, title, icon } = props;
          return (
            <div className="ps-3">
              <div className="box uk-margin-top p-3">
                <div
                  className="uk-flex uk-flex-middle uk-flex-between"
                  key={id}
                >
                  <div>
                    <h5 className="m-0 text-start">{title}</h5>
                    <p className="mt-1 text-start fw-bold">{number}</p>
                  </div>

                  <div className="user-icon">
                    {/* <FaUsers size="1.5rem" color="#004aad" /> */}
                    {icon}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Organization;
