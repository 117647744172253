import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeletePop from "./DeletePop";
// import CandidateEdit from "./CandidateEdit";
import CloseIcon from "../../../../images/CloseIcon.svg";
import CandidateForm from "./CandidateForm";
import ViewCandidate from "./ViewCandidate";
import UpperbarContext from "../../../context/upperbar-context";
import EliteJobContext from "../../EliteJobContext/EliteJobContext";
import CandidateContext from "../../CandidateContext/CandidateContext";
import { AiOutlineEye, AiOutlineSearch } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import Spinner from "../../../loading/spinner";
import $ from "jquery";
import { GoPlus } from "react-icons/go";
export default function Candidate({ closeCandidate }) {
  const { customStylesForImage, mode } = useContext(UpperbarContext);
  const {
    loading,
    originalList,
    popup,
    setPopup,
    togglePopup,
    setIsSubmit,
    setCandidateFormValue,
    setCandidateFormError,
    candidateValue,
    candidateList,
    setCandidateList,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    setCloseChecked,
    setTypeCVFile,
    setCVFile,
    setIsCVUploaded,
    setIsCoverUploaded,
    setTypeCoverFile,
    setCoverFile,
    viewPop,
    setViewPop,
    toggleViewPopup,
    handleView,
    viewCandidate,
    chooseStatus,
    chooseVia,
    setChooseJob,
    setChooseStatus,
    setChooseVia,
  } = useContext(CandidateContext);
  const { jobList } = useContext(EliteJobContext);

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "FullName",
      minWidth: "120px",
      selector: (row) => row.FullName,
    },

    {
      name: "Email",
      minWidth: "200px",
      selector: (row) => row.Email,
    },
    {
      name: "Contact",
      width: "110px",
      selector: (row) => row.Contact,
    },
    {
      name: "Info",
      center: true,
      width: "110px",
      // selector: (row) => row.CV + "/" + row.Cover,
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <a
                className="solvespan--action px-2"
                style={{ color: "#fff", width: "max-content" }}
                href={row.CV}
                target="_blank"
                rel="noreferrer"
              >
                CV
              </a>
              <a
                className="viewspan--action px-2"
                style={{ color: "#fff", width: "max-content" }}
                href={row.Cover}
                target="_blank"
                rel="noreferrer"
              >
                Cover
              </a>
            </div>
          </>
        );
      },
    },
    {
      name: "Created Date",
      width: "180px",
      selector: (row) => row.CreatedDate,
    },
    // {
    //   name: "Status",
    //   width: "100px",
    //   selector: (row) => row.JobStatus,
    // },
    // {
    //   name: "Via",
    //   width: "100px",
    //   selector: (row) => row.Via === "M" && "Manual",
    // },

    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                class="btn btn-sm downloadspan mx-1"
                onClick={() => handleView(row)}
                uk-tooltip={mode === "en" ? "View" : "हेर्नुहोस्"}
              >
                <AiOutlineEye />
              </button>{" "}
              <button
                type="button"
                class="btn btn-sm deletespan mx-1"
                onClick={() => handleDelete(row)}
                uk-tooltip={mode === "en" ? "Delete" : "हटाउनुहोस्"}
              >
                <MdOutlineDelete />
              </button>{" "}
            </div>
          </>
        );
      },
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    setPopup(false);
    setCandidateFormValue(candidateValue);
    setCandidateFormError({});
    setCloseChecked(false);
    setIsSubmit(false);
    $(".addCandidateFormBg").fadeOut(500);
    $(".addCandidateForm").fadeOut(500);
  };

  const closeViewPopup = (e) => {
    e.preventDefault();
    setViewPop(false);
    $(".viewCandidateBg").fadeOut(500);
    $(".viewCandidate").fadeOut(500);
  };



  const addnew = (e) => {
    e.preventDefault();
    setPopup(true);
    setIsSubmit(false);
    setCandidateFormValue(candidateValue);
    setCVFile("");
    setIsCVUploaded(false);
    setTypeCVFile(null);

    setCoverFile("");
    setIsCoverUploaded(false);
    setTypeCoverFile(null);
    $(".addCandidateFormBg").fadeIn(500);
    $(".addCandidateForm").fadeIn(500);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["FullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setCandidateList(srchResult);
      } else {
        setCandidateList({});
      }
    } else {
      setCandidateList(originalList);
    }
  };

  const handleSelectJob = (e) => {
    // let name = "designation";
    // setJobFormValue({
    //   ...jobFormValue,
    //   [name]: e.value,
    // });
  };

  const dropDownJob = jobList.map((item) => ({
    value: item.JobID,
    label: item.Designation,
  }));

  return (
    <>
      <div className="newpopup-body px-3 pb-3">
        <div className="upper-dataTbl">
          <div className="btn-addlnote my-3">
            <button
              type="button"
              class="btn btn-sm"
              style={{
                background: "var(--button-color)",
                color: "white",
              }}
              onClick={addnew}
            >
              <GoPlus color="#fff" className="me-1" />
              {mode === "en" ? "Add New" : "नयाँ थप्नुहोस्"}
            </button>
          </div>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <DataTable
            columns={columns}
            data={candidateList}
            customStyles={customStylesForImage}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="280px"
            highlightOnHover
            pointerOnHover
            responsive
            dense
            striped
            subHeader
            subHeaderAlign="left"
            subHeaderComponent={
              <>
                {/* <div
                className="col-lg-2  col-md-2 col-sm-2 dropdown"
                style={{ padding: "inherit", marginBottom: "10px" }}
              >
                <label className="list-label">Job Title</label>

                <Select
                  className="select"
                  style={{ fontSize: "11px", marginBottom: "3px" }}
                  options={dropDownJob}
                  onChange={(item) => setChooseJob(item.value)}
                  isSearchable={true}
                />
              </div>

              <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                <label className="list-label">Status</label>
                <select
                  style={{ fontSize: "11px" }}
                  name="snotifiaction"
                  value={chooseStatus}
                  onChange={(e) => setChooseStatus(e.target.value)}
                  className="form-control form-control-sm"
                  aria-label="Default control example "
                >
                  <option
                    value="-1"
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    Select Status
                  </option>
                  <option value="O">Open</option>
                  <option value="C">Close</option>
                </select>
                <i class="fas fa-angle-down  notificationpositon"></i>
              </div>

              <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                <label className="list-label">Via</label>
                <select
                  style={{ fontSize: "11px" }}
                  name="snotifiaction"
                  value={chooseVia}
                  onChange={(e) => setChooseVia(e.target.value)}
                  className="form-control form-control-sm"
                  aria-label="Default control example "
                >
                  <option
                    value="-1"
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    Select Via
                  </option>
                  <option value="M">Manual</option>
                </select>
                <i class="fas fa-angle-down  notificationpositon"></i>
              </div> */}

                <div className=" w-100 ">
                  <div className="d-flex uk-flex-middle justify-content-end">
                    <div>
                      <div class="uk-search uk-search-default">
                        <AiOutlineSearch className="search-icon" />
                        <input
                          placeholder={
                            mode === "en" ? "Search" : "खोजी गर्नुहोस्"
                          }
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
          />
        )}

        <div className="container newpopup-bg addCandidateFormBg">
          <div className="newpopup addCandidateForm">
            <div className="newpopup-head ps-0 pe-0">
              <div className="popUpTitle">Add Candidate</div>
              <div className="popUpClose">
                <img
                  className="popUpCloseIcon"
                  src={CloseIcon}
                  alt="CloseIcon"
                  onClick={closePopup}
                />
              </div>
            </div>
            <CandidateForm closePopup={closePopup} />
          </div>
        </div>

        <div className="container newpopup-bg viewCandidateBg">
          <div className="newpopup viewCandidate">
            <div className="newpopup-head ps-0 pe-0">
              <div className="popUpTitle">View Candidate</div>
              <div className="popUpClose">
                <img
                  className="popUpCloseIcon"
                  src={CloseIcon}
                  alt="CloseIcon"
                  onClick={closeViewPopup}
                />
              </div>
            </div>
            <ViewCandidate
              viewCandidate={viewCandidate}
              closeViewPopup={closeViewPopup}
              viewPop={viewPop}
            />
          </div>
        </div>

        {deletePopup.show && (
          <DeletePop
            handleDeleteTrue={handleDeleteTrue}
            handleDeleteFalse={handleDeleteFalse}
          />
        )}
      </div>
    </>
  );
}
