import React, { useContext, useEffect, useState } from "react";
import "./profile.css";
import Basic from "./Basic";
import Shift from "./Shift";
import Service from "./Service";
import Bank from "./Bank";
import Document from "./Document";
import UpperbarContext from "../context/upperbar-context";
import { ShowImgPreview } from "../hooks/imagePreview";
import AuthContext from "../context/auth-context";
import { Fetchdata } from "../hooks/getData";
import { FaRegCalendarAlt } from "react-icons/fa";
import placeholder from "../../images/placeholder.png";
import { englishToNepaliNumber } from "nepali-number";
export default function Profile() {
  const { User } = useContext(AuthContext);
  const [userDetails, setUserDetails] = useState("");
  const [imgPrv, setImgPrv] = useState(false);
  const [ImgPreview, setImgPreview] = useState("");

  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");

  const { fiscalYear, appURL, expires, todayDate, darkText, mode } =
    useContext(UpperbarContext);
  useEffect(() => {
    // const cur_user = localStorage.getItem("token");
    //
    // cur_user.length && setUserDetails(JSON.parse(cur_user));

    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UID: User.UID,
      NotificationToken: "strsadasdsing",
      DeviceID: "sdasd",
      FetchURL: `${appURL}api/checksession`,
      Type: "POST",
    };

    Fetchdata(dataForm)
      .then(function (result) {
        if (result.StatusCode === 200) {
          const postResult = result.Values[0] ? result.Values[0] : "";
          if (postResult) {
            // postResult.CompanyId = "ES25";
            // postResult.Username = User.UserName;
            setUserDetails(postResult);
          } else {
            const cur_user = localStorage.getItem("token");

            cur_user.length && setUserDetails(JSON.parse(cur_user));
          }
        } else {
          const cur_user = localStorage.getItem("token");

          cur_user.length && setUserDetails(JSON.parse(cur_user));
        }
      })
      .then((res) => {
        //
      });
  }, []);

  const [activeTab, setActiveTab] = useState({
    tab1: true,
    tab2: false,
    tab3: false,
    tab4: false,
    tab5: false,
  });

  const handleTab1 = () => {
    setActiveTab({
      tab1: true,
      tab2: false,
      tab3: false,
      tab4: false,
      tab5: false,
    });
  };
  const handleTab2 = () => {
    setActiveTab({
      tab1: false,
      tab2: true,
      tab3: false,
      tab4: false,
      tab5: false,
    });
  };
  const handleTab3 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: true,
      tab4: false,
      tab5: false,
    });
  };
  const handleTab4 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: false,
      tab4: true,
      tab5: false,
    });
  };
  const handleTab5 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: false,
      tab4: false,
      tab5: true,
    });
  };

  const name =
    userDetails.FirstName +
    " " +
    userDetails.MiddleName +
    " " +
    userDetails.LastName;
  //

  //Initiate date object
  const dt_date1 = new Date();
  const dt_date2 = new Date(userDetails.EnrollDate);
  //Get the Timestamp
  let date1 = dt_date1.getTime();
  let date2 = dt_date2.getTime();

  let calc;
  //Check which timestamp is greater
  if (date1 > date2) {
    calc = new Date(date1 - date2);
  } else {
    calc = new Date(date2 - date1);
  }
  //Retrieve the date, month and year
  let calcFormatTmp =
    calc.getDate() + "-" + (calc.getMonth() + 1) + "-" + calc.getFullYear();
  //Convert to an array and store
  let calcFormat = calcFormatTmp.split("-");
  //Subtract each member of our array from the default date
  let days_passed = parseInt(Math.abs(calcFormat[0]) - 1);
  let months_passed = parseInt(Math.abs(calcFormat[1]) - 1);
  let years_passed = parseInt(Math.abs(calcFormat[2] - 1970));

  useEffect(() => {
    if (years_passed === 0) {
      setYear("");
    } else {
      setYear(years_passed);
    }
    if (months_passed === 0) {
      setMonth("");
    } else {
      setMonth(months_passed);
    }
    if (days_passed === 0) {
      setDay("");
    } else {
      setDay(days_passed);
    }
  }, [years_passed, months_passed, days_passed]);

  return (
    <>
      <div className="container-fluid classatten-wrapper  mt-3 ps-4 pe-4">
        <div className="row mt-3 ">
          <div className="page-header">
            <div className="text-start  page-title" style={darkText}>
              {mode === "en" ? "Profile" : "प्रोफाइल"}
            </div>
            <div className="page-date">
              <div className="sec-content" style={darkText}>
                <span className="me-2 ">Expires in: {expires} </span>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>
        <div className="sec-dataTable">
          <div className="profile-info">
            <div className="contentLogo tl">
              <div className="mgmtImg tl">
                <img
                  src={
                    userDetails.UserImage === null
                      ? placeholder
                      : userDetails.UserImage
                  }
                  alt="image"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setImgPreview(
                      userDetails.UserImage === null
                        ? placeholder
                        : userDetails.UserImage
                    );
                    setImgPrv(true);
                  }}
                  onError={(e) => (e.target.src = placeholder)}
                />
              </div>
            </div>
            <div>
              <h6 style={darkText} className="ps-3 uk-text-left">
                {name}
              </h6>
              <p style={darkText} className="ps-3 uk-text-left">
                {userDetails.DesignationName} <br />
                {userDetails.DepartmentName}, {userDetails.SubDepartmentName}
                <br />
                {mode === "en" ? "Service Period" : "सेवा अवधि"}:{" "}
                {mode === "en" ? year : englishToNepaliNumber(year)}{" "}
                {year === "" ? "" : mode === "en" ? "years" : "वर्ष"}{" "}
                {mode === "en" ? month : englishToNepaliNumber(month)}{" "}
                {month === "" ? "" : mode === "en" ? "months" : "महिना"}{" "}
                {mode === "en" ? day : englishToNepaliNumber(day)}{" "}
                {day === "" ? "" : mode === "en" ? "days" : "दिन"}
              </p>
            </div>
          </div>

          <div className="insidePopup">
            <ul>
              <li
                className={activeTab.tab1 === true ? "active" : ""}
                onClick={handleTab1}
              >
                <i className="fa fa-user icon"></i>
                {mode === "en" ? "Basic Information" : "आधारभूत जानकारी"}
              </li>
              <li
                className={activeTab.tab2 === true ? "active" : ""}
                onClick={handleTab2}
              >
                <i className="fas fa-sliders icon"></i>{" "}
                {mode === "en" ? "Shift Information" : "शिफ्ट जानकारी"}
              </li>
              <li
                className={activeTab.tab3 === true ? "active" : ""}
                onClick={handleTab3}
              >
                <i className="fas fa-sliders icon"></i>{" "}
                {mode === "en" ? "Service Information" : "सेवा जानकारी"}
              </li>
              <li
                className={activeTab.tab4 === true ? "active" : ""}
                onClick={handleTab4}
              >
                <i className="fas fa-home icon"></i>{" "}
                {mode === "en" ? "Bank Information" : "बैंक जानकारी"}
              </li>
              <li
                className={activeTab.tab5 === true ? "active" : ""}
                onClick={handleTab5}
              >
                <i className="fas fa-users icon"></i>{" "}
                {mode === "en" ? "Document Information" : "कागजात जानकारी"}
              </li>
            </ul>
          </div>
          <div>
            {activeTab.tab1 && <Basic userDetails={userDetails} />}
            {activeTab.tab2 && <Shift userDetails={userDetails} />}
            {activeTab.tab3 && <Service userDetails={userDetails} />}
            {activeTab.tab4 && <Bank />}
            {activeTab.tab5 && <Document />}
          </div>
        </div>
      </div>
      {imgPrv &&
        ShowImgPreview({
          img: ImgPreview,
          setTrigger: setImgPrv,
        })}
    </>
  );
}
