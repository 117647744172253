import React, { useContext, useEffect, useState } from "react";
import "../../../leaveNotes/leaveNotePopup.css";
import "../subdepartment/SubdepartmentPopup.css";
import CloseIcon from "../../../../images/CloseIcon.svg";
import Plus from "../../../../images/Plus.png";
import "../../../hooks/imagePreview.css";
import "./LeavePopup.css";
import AuthContext from "../../../context/auth-context";
import { Fetchdata } from "../../../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UpperbarContext from "../../../context/upperbar-context";
import Toast from "../../../Toast";
import $ from "jquery";

export default function LeaveEditPopup({
  setLeaveEditPopup,
  reload,
  setReload,
  leaveFormValue,
  setLeaveFormValue,
  leaveFormError,
  setLeaveFormError,
  isCarry,
  setIsCarry,
  leavList,
  titleId,
  leaveEditPopup,
}) {
  const { User } = useContext(AuthContext);
  const { appURL, mode } = useContext(UpperbarContext);
  const closePopUp = (e) => {
    setLeaveEditPopup(false);
    setLeaveFormError({});

    $(".editLeaveTypePopBg").fadeOut(500);
    $(".editLeaveTypePop").slideUp(500);
    setIsSubmit(false);
  };

  useEffect(() => {
    if (leaveEditPopup) {
      $(".editLeaveTypePopBg").fadeIn(500);
      $(".editLeaveTypePop").slideDown(500);
    }
  }, [leaveEditPopup]);

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type == "checkbox" ? target.checked : target.value;
    setLeaveFormValue({ ...leaveFormValue, [name]: value });
  };

  const [isSubmit, setIsSubmit] = useState(false);

  const validate = (values) => {
    const errors = {};
    if (!values.description) {
      errors.description = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.name) {
      errors.name = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.type) {
      errors.type = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.balance) {
      errors.balance = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.gender) {
      errors.gender = mode === "en" ? "Required" : "आवश्यक छ";
    }

    return errors;
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setLeaveFormError(validate(leaveFormValue));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(leaveFormError).length === 0 && isSubmit) {
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: User.UID,
        Flag: "U",
        LeaveTypeID: titleId,
        Name: leaveFormValue.name,
        Balance: leaveFormValue.balance.toString(),
        IsPaid: leaveFormValue.type,
        IsCarryable: isCarry ? "Y" : "N",
        Gender: leaveFormValue.gender,
        Description: leaveFormValue.description,
        FetchURL: `${appURL}api/admin/leave-type`,
        Type: "POST",
      };
      Fetchdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          leavList();
          setLeaveEditPopup(false);
          $(".editLeaveTypePopBg").fadeOut(500);
          $(".editLeaveTypePop").slideUp(500);
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });

      setIsSubmit(false);
    }
  }, [leaveFormError]);

  const handleOnChange = (e) => {
    setIsCarry(!isCarry);
    if (e.target.checked === true) {
      setIsCarry(1);
    } else if (e.target.checked === false) {
      setIsCarry(0);
    }
  };


  return (
    <>
      {/* <Toast /> */}
      <div className="container newpopup-bg editLeaveTypePopBg">
        <div className="newpopup editLeaveTypePop" style={{ width: "50%" }}>
          <div className="newpopup-head ps-0 pe-0">
            <div className="popUpTitle">
              {" "}
              {mode === "en"
                ? "Leave Type Edit"
                : "बिदाको प्रकार सम्पादन गर्नुहोस्"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div className="newpopup-body ps-3 pe-3">
            <div className="form-in-padding">
              <div className="row text-start mt-2 ">
                <div className="col-md-12 col-sm-12 col-lg-12">
                  <div className="row text-start ">
                    <div className="form-group mt-0">
                      <label htmlFor="name" style={{ fontSize: "12px" }}>
                        {mode === "en" ? "Name" : "नाम"}
                        <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <input
                        id="name"
                        style={{ fontSize: "13px" }}
                        type="text"
                        className="form-control form-control-sm"
                        name="name"
                        value={leaveFormValue.name}
                        onChange={handleChange}
                        placeholder={mode === "en" ? "Name" : "नाम"}
                      />
                      {leaveFormError.name && (
                        <p className="errormsg">{leaveFormError.name}</p>
                      )}
                    </div>
                  </div>

                  <div className="row text-start ">
                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="balance" style={{ fontSize: "12px" }}>
                          {mode === "en" ? "Balance" : "सन्तुलन"}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                          id="balance"
                          style={{ fontSize: "13px" }}
                          type="number"
                          className="form-control form-control-sm "
                          name="balance"
                          value={leaveFormValue.balance}
                          onChange={handleChange}
                          placeholder={mode === "en" ? "Balance" : "सन्तुलन"}
                        />
                        {leaveFormError.balance && (
                          <p className="errormsg">{leaveFormError.balance}</p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="type" style={{ fontSize: "12px" }}>
                          {mode === "en" ? "Type" : "प्रकार"}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example"
                          name="type"
                          value={leaveFormValue.type}
                          onChange={handleChange}
                        >
                          <option disabled value="" selected>
                            {mode === "en"
                              ? "Select Type"
                              : "प्रकार चयन गर्नुहोस्"}
                          </option>
                          <option value="1">
                            {mode === "en" ? "Paid" : "भुक्तान गरिने"}
                          </option>
                          <option value="0">
                            {mode === "en" ? "Unpaid" : "भुक्तान नगरिने"}
                          </option>
                        </select>
                        {leaveFormError.type && (
                          <p className="errormsg">{leaveFormError.type}</p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="gender" style={{ fontSize: "12px" }}>
                          {mode === "en" ? "Gender" : "लिङ्ग"}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example"
                          name="gender"
                          value={leaveFormValue.gender}
                          onChange={handleChange}
                        >
                          <option disabled value="" selected>
                            {mode === "en"
                              ? "Select Gender"
                              : "लिङ्ग चयन गर्नुहोस्"}
                          </option>
                          <option value="M">
                            {" "}
                            {mode === "en" ? "Male" : "पुरुष"}{" "}
                          </option>
                          <option value="F">
                            {" "}
                            {mode === "en" ? "Female" : "महिला"}{" "}
                          </option>
                          <option value="O">
                            {" "}
                            {mode === "en" ? "Other" : "अन्य"}{" "}
                          </option>
                        </select>
                        {leaveFormError.gender && (
                          <p className="errormsg">{leaveFormError.gender}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row text-start ">
                    <div className="form-group">
                      <label htmlFor="description" style={{ fontSize: "12px" }}>
                        {mode === "en" ? "Description" : "विवरण"}
                        <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <textarea
                        id="description"
                        style={{ fontSize: "13px" }}
                        rows="2"
                        className="form-control form-control-sm"
                        name="description"
                        value={leaveFormValue.description}
                        onChange={handleChange}
                        placeholder={mode === "en" ? "Description" : "विवरण"}
                      />
                      {leaveFormError.description && (
                        <p className="errormsg">{leaveFormError.description}</p>
                      )}
                    </div>
                  </div>

                  <div className="checkbox-close">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck2"
                      name="closeChecked"
                      onChange={handleOnChange}
                      checked={isCarry}
                      style={{ fontSize: "12px", marginTop: "4px" }}
                    />
                    <label
                      class="form-check-label"
                      for="exampleCheck2"
                      style={{ fontSize: "12px", paddingLeft: "4px" }}
                    >
                      {mode === "en" ? "Carryable" : "बोक्न योग्य"}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="new-footer">
            <div className="row  mt-1 mb-1">
              <div>
                <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={formSubmit}
                >
                  {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
