import React, { useContext, useEffect, useState } from "react";
import "../../../leaveNotes/leaveNotePopup.css";
import "../../organization/department/DepartmentPopup.css";
import CloseIcon from "../../../../images/CloseIcon.svg";
import AuthContext from "../../../context/auth-context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Fetchdata } from "../../../hooks/getData";
import UpperbarContext from "../../../context/upperbar-context";
import Toast from "../../../Toast";
import $ from "jquery";

export default function BranchPopup({
  setBranchPopup,
  reload,
  setReload,
  branchFormValue,
  setBranchFormValue,
  branchFormError,
  setBranchFormError,
  brnchList,
  branchPopup,
}) {
  const { User } = useContext(AuthContext);
  const { mode, appURL } = useContext(UpperbarContext);

  const [data, setData] = useState([]);

  useEffect(() => {
    dataList();
  }, []);

  const dataList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      DFlag: "DISTRICT",
      Type: "POST",
      FetchURL: `${appURL}api/admin/ref-values`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setData(postResult);
      } else {
        setData([]);
      }
    });
  };

  const closePopUp = (e) => {
    setBranchPopup(false);
    setBranchFormError({});
    $(".addBranchPopBg").fadeOut(500);
    $(".addBranchPop").slideUp(500);
    setIsSubmit(false);
  };

  useEffect(() => {
    if (branchPopup) {
      $(".addBranchPopBg").fadeIn(500);
      $(".addBranchPop").slideDown(500);
    }
  }, [branchPopup]);

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type == "checkbox" ? target.checked : target.value;
    setBranchFormValue({ ...branchFormValue, [name]: value });
  };

  const [isSubmit, setIsSubmit] = useState(false);

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.address) {
      errors.address = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.district) {
      errors.district = mode === "en" ? "Required" : "आवश्यक छ";
    }

    return errors;
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setBranchFormError(validate(branchFormValue));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(branchFormError).length === 0 && isSubmit) {
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: User.UID,
        Flag: "i",
        Name: branchFormValue.name,
        Address: branchFormValue.address,
        District: branchFormValue.district,
        BranchID: User.BranchID,
        FiscalID: User.FiscalID,
        FetchURL: `${appURL}api/admin/branch`,
        Type: "POST",
      };

      Fetchdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          setBranchPopup(false);
          $(".addBranchPopBg").fadeOut(500);
          $(".addBranchPop").slideUp(500);
          brnchList();
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });

      setIsSubmit(false);
    }
  }, [branchFormError]);

  return (
    <>
      {/* <Toast /> */}
      <div className="container newpopup-bg addBranchPopBg">
        <div className="newpopup addBranchPop">
          <div className="newpopup-head ps-0 pe-0">
            <div className="popUpTitle">
              {mode === "en" ? "Branch" : "साखा"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div className="newpopup-body ps-3 pe-3">
            <div className="form-in-padding">
              <div className="row text-start mt-1">
                <div className="col-md-12 col-sm-12 col-lg-12">
                  <div className="row text-start ">
                    <div className="form-group mt-2">
                      <label htmlFor="name" style={{ fontSize: "12px" }}>
                        {mode === "en" ? "Branch" : "साखाको नाम"}
                        <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <input
                        id="name"
                        style={{ fontSize: "13px" }}
                        type="text"
                        className="form-control form-control-sm "
                        name="name"
                        value={branchFormValue.name}
                        onChange={handleChange}
                      />
                      {branchFormError.name && (
                        <p className="errormsg">{branchFormError.name}</p>
                      )}
                    </div>
                  </div>

                  <div className="row text-start ">
                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="address" style={{ fontSize: "12px" }}>
                          {mode === "en" ? "Address" : "ठेगाना"}{" "}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                          id="address"
                          style={{ fontSize: "13px" }}
                          type="text"
                          className="form-control form-control-sm"
                          name="address"
                          value={branchFormValue.address}
                          onChange={handleChange}
                        />
                        {branchFormError.address && (
                          <p className="errormsg">{branchFormError.address}</p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="district" style={{ fontSize: "12px" }}>
                          {mode === "en"
                            ? "Select District"
                            : "जिल्ला चयन गर्नुहोस्"}{" "}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example"
                          name="district"
                          value={branchFormValue.district}
                          onChange={handleChange}
                        >
                          <option disabled value="" selected>
                            {mode === "en"
                              ? "Select District"
                              : "जिल्ला चयन गर्नुहोस्"}
                          </option>
                          {data.map((list) => (
                            <option
                              key={list.DistrictID}
                              value={list.DistrictID}
                            >
                              {list.District}
                            </option>
                          ))}
                          {/* <option value="1">Kathmandu</option> */}
                          {/* {departmentList.map((item) => (<option key={item.DepartmentID} value={item.DepartmentID}>{item.Department}</option>))} */}
                        </select>
                        {branchFormError.district && (
                          <p className="errormsg">{branchFormError.district}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="new-footer">
            <div className="row  mt-1 mb-1">
              <div>
                <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={formSubmit}
                >
                  {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
