import React, { useContext, useEffect } from "react";
import $ from "jquery";
import UpperbarContext from "./context/upperbar-context";

const TimeOutPop = () => {
  const { isActive } = useContext(UpperbarContext);
  const handleOk = () => {
    $(".TimeOutpopupBg").fadeOut(500);
    $(".TimeOutpopUp").slideUp(500);
  };
  useEffect(() => {
    if (!isActive) {
      $(".TimeOutpopupBg").fadeIn(500);
      $(".TimeOutpopUp").slideDown(500);
    }
  }, [isActive]);
  return (
    <div className="timeoutBg TimeOutpopupBg">
      <div className="timeoutPop TimeOutpopUp" style={{ width: "max-content" }}>
        <div className="timeoutPop-body text-center">
          <h5 className="m-0 fw-bold mb-2">Session Expired</h5>
          <p className="m-0">Please Login to Continue</p>
        </div>

        <div className="timeoutPop-footer">
          <button className="btn btn-primary w-100" onClick={handleOk}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default TimeOutPop;
