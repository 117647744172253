import React, { useContext, useEffect, useState } from "react";
import "../../../leaveNotes/leaveNotePopup.css";
import "../subdepartment/SubdepartmentPopup.css";
import CloseIcon from "../../../../images/CloseIcon.svg";
import "../../../hooks/imagePreview.css";
import "../leaveType/LeavePopup.css";
import "./Staff.css";
import AuthContext from "../../../context/auth-context";
import { Fetchdata } from "../../../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Basic from "./Basic";
import Service from "./Service";
import StaffContext from "../staffState/StaffContext";
import UpperbarContext from "../../../context/upperbar-context";
import $ from "jquery";

export default function StaffPopup({ re, setRe }) {
  const { User } = useContext(AuthContext);
  const { mode } = useContext(UpperbarContext);
  const {
    staffPopup,
    setStaffPopup,
    staffEditPopup,
    setStaffEditPopup,
    currentStep,
    setStep,
    staffFormValue,
    setStaffFormValue,
    staffFormError,
    setStaffFormError,
    staffValue,
    setIsSubmit,
  } = useContext(StaffContext);

  const closePopUp = (e) => {
    setStaffPopup(false);
    setStaffFormError({});
    setStep(1);
    $(".addStaffPopBg").fadeOut(500);
    $(".addStaffPop").slideUp(500);
    setIsSubmit(false);
  };

  useEffect(() => {
    if (staffPopup) {
      $(".addStaffPopBg").fadeIn(500);
      $(".addStaffPop").slideDown(500);
    }
  }, [staffPopup]);

  // function showStep(step) {
  //     switch (step) {
  //         case 1:
  //             return <Basic />
  //         case 2:
  //             return <Service />
  //     }
  // }

  return (
    <>
      <div className="container newpopup-bg addStaffPopBg">
        <div className="newpopup addStaffPop" style={{ width: "66%" }}>
          <div className="newpopup-head ps-0 pe-0">
            <div className="popUpTitle">
              {mode === "en" ? "Staff" : "कर्मचारी"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <Basic re={re} setRe={setRe} />
        </div>
      </div>
    </>
  );
}
