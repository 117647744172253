import React from "react";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import { useState } from "react";

const BankGuareente = ({
  handleChange,
  formValues,
  formErrors,
  handleStartDate,
  DFlag,
  handleExpiryDate,
  re,
  mode,
}) => {
  return (
    <>
      <div className="row text-start ">
        <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
            <label htmlFor="amount" style={{ fontSize: "12px" }}>
              {mode === "en" ? "Amount" : "रकम"}
            </label>
            <input
              id="amount"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="amount"
              placeholder={mode === "en" ? "Amount" : "रकम"}
              value={formValues.amount}
              onChange={handleChange}
            />
            {/* {formErrors.amount && (
      <p className="errormsg">{formErrors.amount}</p>
    )} */}
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
            <label htmlFor="name" style={{ fontSize: "12px" }}>
              {mode === "en" ? "Name" : "नाम"}
            </label>
            <input
              id="name"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="name"
              placeholder={mode === "en" ? "Name" : "नाम"}
              value={formValues.name}
              onChange={handleChange}
            />
            {/* {formErrors.name && (
      <p className="errormsg">{formErrors.name}</p>
    )} */}
          </div>
        </div>
      </div>

      <div className="row text-start ">
        <div className="form-group">
          <label htmlFor="deposit" style={{ fontSize: "12px" }}>
            {mode === "en" ? "Security Deposit" : "सुरक्षा निक्षेप"}
          </label>
          <input
            id="deposit"
            style={{ fontSize: "13px" }}
            type="text"
            className="form-control form-control-sm "
            name="deposit"
            placeholder={mode === "en" ? "Security Deposit" : "सुरक्षा निक्षेप"}
            value={formValues.deposit}
            onChange={handleChange}
          />
          {/* {formErrors.deposit && (
      <p className="errormsg">{formErrors.deposit}</p>
    )} */}
        </div>
      </div>
      <div className="row text-start ">
        <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
            <label style={{ fontSize: "12px" }}>
              {mode === "en" ? "Select Start Date" : "सुरु मिति चयन गर्नुहोस्"}
            </label>
            {DFlag === "N" ? (
              <Calendar
                className="form-control form-control-sm pt-0 pb-0 from-date"
                dateFormat="YYYY/MM/DD"
                defaultDate="2010/01/01"
                theme="default"
                language="en"
                value={formValues.startDate}
                onChange={handleStartDate}
                name="startDate"
                key={re}
                // hideDefaultValue={true}
                // placeholder={"Select From Date"}
              />
            ) : (
              <input
                id="startDate"
                type="date"
                value={formValues.startDate}
                name="startDate"
                placeholder="Select a Date"
                className="form-control form-control-sm "
                onChange={handleChange}
              />
            )}
            {/* {formErrors.creditDays && (
      <p className="errormsg">{formErrors.creditDays}</p>
    )} */}
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
            <label style={{ fontSize: "12px" }}>
              {mode === "en"
                ? "Select Expiry Date"
                : "म्याद सकिने मिति चयन गर्नुहोस्"}
            </label>
            {DFlag === "N" ? (
              <Calendar
                className="form-control form-control-sm pt-0 pb-0 from-date"
                dateFormat="YYYY/MM/DD"
                defaultDate="2010/01/01"
                theme="default"
                language="en"
                value={formValues.expiryDate}
                onChange={handleExpiryDate}
                name="expiryDate"
                key={re}
                // hideDefaultValue={true}
                // placeholder={"Select From Date"}
              />
            ) : (
              <input
                id="expiryDate"
                type="date"
                value={formValues.expiryDate}
                name="expiryDate"
                placeholder="Select a Date"
                className="form-control form-control-sm "
                onChange={handleChange}
              />
            )}
            {/* {formErrors.actionDay && (
      <p className="errormsg">{formErrors.actionDay}</p>
    )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default BankGuareente;
