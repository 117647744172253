import React, { useState, useEffect, useContext } from "react";
import LedgerContext from "./LedgerContext";
import { Fetchdata } from "../../../../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import AuthContext from "../../../../context/auth-context";
import UpperbarContext from "../../../../context/upperbar-context";
import $ from "jquery";
import { GetEnglishDate, GetNepaliDate } from "../../../../hooks/dateConvertor";

function LedgerState(props) {
  const { User } = useContext(AuthContext);
  const { appURL } = useContext(UpperbarContext);
  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const [popup, setPopup] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [perEditSubmit, setPerEditSubmit] = useState(false);
  const [DFlag, setDFlag] = useState("N");
  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState(null);
  const [files, setFile] = useState("");

  const [checkedVat, setCheckedVat] = useState(false);
  const [checkedBankCash, setCheckedBankCash] = useState(false);
  const [checkedActive, setCheckedActive] = useState(false);
  const [checkedInv, setCheckedInv] = useState(false);
  const [checkedBill, setCheckedBill] = useState(false);

  var d = new Date();
  const initalvalue = {
    ledgerName: "",
    under: "",
    alias: "",
    acGrp: "",
    grade: "",
    agent: "",
    area: "",
    pan: "",
    currency: "",
    description: "",
    address: "",
    contactPerson: "",
    contact: "",
    email: "",
    website: "",
    creditAmt: "",
    actionAmt: "",
    creditDays: "",
    actionDay: "",
    rate: "",
    amount: "",
    name: "",
    deposit: "",
    startDate: GetNepaliDate(d),
    expiryDate: GetNepaliDate(d),
    pdf: "",
    ext: "",
  };
  const [formValues, setFormValues] = useState(initalvalue);
  const [formErrors, setFormErrors] = useState({});
  const [rateInput, setRateInput] = useState(false);
  //  API to show main group list
  const [ledgerList, setLedgerList] = useState([]);

  useEffect(() => {
    ledgerLst();
  }, [submit, perEditSubmit]);

  const ledgerLst = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      IsActive: "-1",
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/ledger`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setLedgerList(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setLedgerList([]);
        setLoading(false);
      }
    });
  };

  //  API to edit main group
  const [perID, setPerId] = useState();
  const [editPopup, setEditPopup] = useState(false);
  const handleEdit = (data) => {
    setPerId(data.LedgerID);
    setEditPopup(true);
    ldgrunderLst();
  };
  const editData = () => {
    const UserID = "" + User.UID;
    // const referid = "" + formValues.under;
    const areaid = "" + formValues.area;
    const acgrpid = "" + formValues.acGrp;
    const gradeid = "" + formValues.grade;
    const agentid = "" + formValues.agent;
    const currencyid = "" + formValues.currency;
    const id = "" + perID;

    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      CID: "1",
      Flag: "U",
      LedgerID: id,
      ReferID: "0",
      // ReferID: referid !== " " ? referid : " ",
      Name: formValues.ledgerName !== " " ? formValues.ledgerName : " ",
      Alias: formValues.alias !== " " ? formValues.alias : " ",
      AccountGroupID: acgrpid !== " " ? acgrpid : " ",
      GradeID: gradeid !== " " ? gradeid : " ",
      AgentID: agentid !== " " ? agentid : " ",
      AreaID: areaid !== " " ? areaid : " ",
      PAN: formValues.pan !== " " ? formValues.pan : " ",
      IsVat: checkedVat ? "Y" : "N",
      IsBankCash: checkedBankCash ? "Y" : "N",
      CurrencyID: currencyid !== " " ? currencyid : " ",
      Description:
        formValues.description !== " " ? formValues.description : " ",
      Address: formValues.address !== " " ? formValues.address : " ",
      ContactPerson:
        formValues.contactPerson !== " " ? formValues.contactPerson : " ",
      Contact: formValues.contact !== " " ? formValues.contact : " ",
      Email: formValues.email !== " " ? formValues.email : " ",
      Website: formValues.website !== " " ? formValues.website : " ",
      BankAmt: formValues.amount !== " " ? formValues.amount : " ",
      BankName: formValues.name !== " " ? formValues.name : " ",
      BankSecurityAmt: formValues.deposit !== " " ? formValues.deposit : " ",
      BankStart: formValues.startDate
        ? GetEnglishDate(formValues.startDate)
        : formValues.startDate,
      BankEnd: formValues.expiryDate
        ? GetEnglishDate(formValues.expiryDate)
        : formValues.expiryDate,
      Document: typeFile !== null ? typeFile.split(",")[1] : "",
      FetchURL: `${appURL}api/acc/admin/ledger`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (resp) {
      if (resp.StatusCode === 200) {
        setEditPopup(false);
        $(".editLedgerPopBg").fadeOut(300);
        $(".editLedgerPop").slideUp(500);
        ledgerLst();
        toast.success(resp.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + resp.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };
  // API to get main group info
  useEffect(() => {
    infoList();
  }, [perID]);

  const infoList = () => {
    const UserID = "" + User.UID;
    const id = "" + perID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      Flag: "SI",
      CID: "1",
      LedgerID: id,
      FetchURL: `${appURL}api/acc/admin/ledger`,
      Type: "POST",
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const data = result.Values[0] ? result.Values[0] : "";
        setFormValues({
          ledgerName: data.Ledger,
          alias: data.Alias,
          under: data.ReferID,
          acGrp: data.AccGroupID,
          grade: data.GradeID,
          agent: data.AgentID,
          area: data.AreaID,
          pan: data.PAN,
          currency: data.Currency,
          description: data.Description,
          address: data.Address,
          contactPerson: data.ContactPerson,
          contact: data.Contact,
          email: data.Email,
          website: data.Website,
          creditAmt: data.CreditAmt,
          actionAmt: data.CreditAmtDays,
          creditDays: data.CreditDays,
          actionDay: data.CreditDaysAct,
          rate: data.RateOfInterest,
          amount: data.BankAmt,
          name: data.BankName,
          deposit: data.BankSecurityAmt,
          startDate:
            data.BankStart !== null ? GetNepaliDate(data.BankStart) : "",
          expiryDate: data.BankEnd !== null ? GetNepaliDate(data.BankEnd) : "",
        });
        setRateInput(data.RateOfInterest !== "" ? true : false);
        setCheckedActive(data.IsActive === "A" ? true : false);
        setCheckedVat(data.IsVat === "Y" ? true : false);
        setCheckedBankCash(data.IsBankCash === "Y" ? true : false);
        setCheckedInv(data.AffectInventory === "Y" ? true : false);
        setCheckedBill(data.BillWiseDetail === "Y" ? true : false);
        setFile(data.Docs !== "" ? data.Docs : "");
        setIsUploaded(data.Docs !== "" ? true : false);
      } else {
      }
    });
  };

  // API to hit main group status
  const stat = [];
  const [newStatus, setNewStatus] = useState(stat);

  const deactivateData = (ID, IsActive) => {
    const UserID = "" + User.UID;
    const id = "" + ID;
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      Flag: "AI",
      CID: "1",
      LedgerID: id,
      IsActive: IsActive,
      FetchURL: `${appURL}api/acc/admin/ledger`,
      Type: "POST",
    };
    if (IsActive === "A") {
      dataForm.IsActive = "I";
    } else {
      dataForm.IsActive = "A";
    }
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        ledgerLst();
        let NewstatsN = JSON.parse(JSON.stringify(newStatus));
        let pitchNewStatus;

        if (dataForm.IsActive === "A") {
          pitchNewStatus = "Activated";
        } else if (dataForm.IsActive === "I") {
          pitchNewStatus = "Deactivated";
        }
        setNewStatus(NewstatsN);
        toast.success(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  //   API to under list
  const [ledgerUnderList, setLedgerUnderList] = useState([]);

  useEffect(() => {
    ldgrunderLst();
  }, [submit]);

  const ldgrunderLst = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      DFlag: "LEDGER",
      IsBank: "-1",
      Flag: "S",
      CID: "1",
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/ref-values`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setLedgerUnderList(postResult);
      } else {
        setLedgerUnderList([]);
      }
    });
  };

  return (
    <LedgerContext.Provider
      value={{
        loading,
        setLoading,
        originalList,
        setOriginalList,
        popup,
        setPopup,
        submit,
        setSubmit,
        perEditSubmit,
        setPerEditSubmit,
        initalvalue,
        formValues,
        setFormValues,
        formErrors,
        setFormErrors,
        ledgerList,
        setLedgerList,
        editPopup,
        setEditPopup,
        handleEdit,
        editData,
        deactivateData,
        ledgerLst,
        checkedVat,
        setCheckedVat,
        checkedBankCash,
        setCheckedBankCash,
        checkedActive,
        setCheckedActive,
        checkedInv,
        setCheckedInv,
        checkedBill,
        setCheckedBill,
        DFlag,
        isUploaded,
        setIsUploaded,
        typeFile,
        setTypeFile,
        files,
        setFile,

        rateInput,
        setRateInput,
        ledgerUnderList,
        ldgrunderLst,
      }}
    >
      {props.children}
    </LedgerContext.Provider>
  );
}

export default LedgerState;
