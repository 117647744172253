import React from "react";
import { useContext } from "react";
import PersonalSlipContext from "../payrollState/PersonalSlipContext";
import UpperbarContext from "../../../context/upperbar-context";
const PrintableComp = React.forwardRef((props, ref) => {
  const { mode } = useContext(UpperbarContext);
  const { slipList, inputValues } = useContext(PersonalSlipContext);

  function convertToNepaliMonth(month) {
    const monthMap = {
      "01": "Baisakh",
      "02": "Jestha",
      "03": "Ashadh",
      "04": "Shrawan",
      "05": "Bhadra",
      "06": "Ashwin",
      "07": "Kartik",
      "08": "Mangsir",
      "09": "Poush",
      10: "Magh",
      11: "Falgun",
      12: "Chaitra",
    };

    const monthStr = String(month).padStart(2, "0");
    return monthMap[monthStr] || "";
  }

  const getMonthName = convertToNepaliMonth(inputValues.month.toString());

  // Group data by FullName and HeadType
  const groupedData = slipList.reduce((result, entry) => {
    const nameKey = entry.FullName;
    const headTypeKey = entry.HeadType;

    if (!result[nameKey]) {
      result[nameKey] = {};
    }

    if (!result[nameKey][headTypeKey]) {
      result[nameKey][headTypeKey] = [];
    }

    result[nameKey][headTypeKey].push(entry);
    return result;
  }, {});

  const total = Object.entries(groupedData).map(([nameKey, nameGroup]) => {
    const facilitiesTotal = calculateTotal(nameGroup, "A");
    const deductionTotal = calculateTotal(nameGroup, "R");
    const netPayable = parseFloat(facilitiesTotal) - parseFloat(deductionTotal);

    return netPayable;
  });

  const numberToWords = require("number-to-words");

  return (
    <div ref={ref} className=" pb-3 print-table">
      <>
        {Object.keys(groupedData).length !== 0 ? (
          <>
            <div className="details text-center">
              <h4 className="m-0">Easy Software Accounting Software</h4>
              <h5 className="m-0">Bharatpur, Nepal.</h5>
              <p className="m-0">01-5959033</p>
            </div>
            {/* Display table for each table name */}
            {Object.entries(groupedData).map(([nameKey, nameGroup]) => {
              const personalInfo = nameGroup[Object.keys(nameGroup)[0]][0];
              const facilitiesTotal = calculateTotal(nameGroup, "A");
              const deductionTotal = calculateTotal(nameGroup, "R");
              const netPayable =
                parseFloat(facilitiesTotal) - parseFloat(deductionTotal);
              const netPayableInWords = numberToWords.toWords(
                netPayable.toFixed(2)
              );
              let capitalized;

              if (netPayableInWords) {
                capitalized =
                  netPayableInWords.charAt(0).toUpperCase() +
                  netPayableInWords.slice(1);
              }

              return (
                <div key={nameKey} className="tableHeight mt-5">
                  <table className="uk-table uk-table-divider reportTable personalSlip">
                    <thead>
                      <tr
                        className="text-center"
                        style={{ background: "#fff" }}
                      >
                        <td colSpan="4">{`Personal Slip of Month ${getMonthName}`}</td>
                      </tr>
                      <tr style={{ background: "#fff" }}>
                        <td className="w-25">Name: {personalInfo.FullName}</td>
                        <td className="w-25">
                          Post: {personalInfo.Designation}
                        </td>
                        {/* <td className="w-25">A/c: </td> */}
                        <td className="w-50">
                          Job Start: {personalInfo.EnrollDate}
                        </td>
                      </tr>
                      <tr className="text-center">
                        <td
                          colSpan="2"
                          className="rdt_TableHeadRow tableHead printable-text"
                        >
                          Facilities
                        </td>
                        <td
                          colSpan="2"
                          className="rdt_TableHeadRow tableHead printable-text"
                        >
                          Deduction
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="2">
                          {nameGroup["A"].map((entry) => (
                            <>
                              {entry.Amount > 0 && (
                                <div className="slipInfo" key={entry.Head}>
                                  {entry.Head}
                                  <span>{entry.Amount}</span>
                                </div>
                              )}
                            </>
                          ))}
                        </td>
                        <td colSpan="2">
                          {nameGroup["R"].map((entry) => (
                            <>
                              {entry.Amount > 0 && (
                                <div className="slipInfo" key={entry.Head}>
                                  {entry.Head}
                                  <span>{entry.Amount}</span>
                                </div>
                              )}
                            </>
                          ))}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr className="text-end reportTotal">
                        <td colSpan="2">
                          Facilities Total:{" "}
                          <span className="ms-5">
                            {calculateTotal(nameGroup, "A")}
                          </span>
                        </td>
                        <td colSpan="2">
                          Deduction Total:{" "}
                          <span className="ms-5">
                            {calculateTotal(nameGroup, "R")}
                          </span>
                        </td>
                      </tr>
                      <tr className="reportTotal text-center">
                        <td colSpan="4">
                          Net Payable Amount: {calculateNetPayable(nameGroup)}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  <span
                    className="d-block mt-2"
                    style={{ fontSize: "12px", fontStyle: "italic" }}
                  >
                    {`In words: ${capitalized} only.`}
                  </span>
                </div>
              );
            })}
          </>
        ) : (
          <span className="d-block text-center">
            {" "}
            {mode === "en" ? "No Data" : "डाटा उपलब्ध छैन"}
          </span>
        )}
      </>
    </div>
  );
});

// Helper function to calculate the total amount for a specific HeadType
const calculateTotal = (data, headType) => {
  const total = Object.values(data).reduce((sum, headTypeGroup) => {
    const filteredData = headTypeGroup.filter(
      (entry) => entry.HeadType === headType
    );
    const subtotal = filteredData.reduce(
      (acc, entry) => acc + parseFloat(entry.Amount),
      0
    );
    return sum + subtotal;
  }, 0);
  return total.toFixed(2);
};

const calculateNetPayable = (nameGroup) => {
  const facilitiesTotal = calculateTotal(nameGroup, "A");
  const deductionTotal = calculateTotal(nameGroup, "R");
  const netPayable = parseFloat(facilitiesTotal) - parseFloat(deductionTotal);
  return netPayable.toFixed(2);
};

export default PrintableComp;
