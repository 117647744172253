import React, { useContext, useState, useEffect, useRef } from "react";
import { FaUser } from "react-icons/fa";
import AuthContext from "../context/auth-context";
import UpperbarContext from "../context/upperbar-context";
// import SidebarContext from "../sidebarcontext/SidebarContext";
import { Fetchdata } from "../hooks/getData";
import {
  GetCurrYear,
  GetToDate,
  GetEnglishDate,
  GetNepaliDate,
} from "../hooks/dateConvertor";
import Spinner from "../loading/spinner";
import ApprovePop from "./ApprovePop";
import RejectPop from "./RejectPop";
import { AiOutlineEye, AiOutlineSearch } from "react-icons/ai";
import ViewLeaveReport from "../adminPanel/leaveReport/ViewPop";
import { ToastContainer, toast } from "react-toastify";

const TodayLeave = () => {
  const { appURL, userDetails, mode } = useContext(UpperbarContext);

  const { User } = useContext(AuthContext);
  const [mSummary, setMSummary] = useState([]);
  const [DFlag, setDFlag] = useState("N");
  const [loading, setLoading] = useState(true);
  const [sYear, setSYear] = useState(GetCurrYear());
  const date = GetToDate();

  const [viewPopup, setViewPopup] = useState(false);
  const [selected_note, setSelectedNote] = useState("");

  const [originalList, setOriginalList] = useState(null);

  const searchInput = useRef("");

  useEffect(() => {
    if (Object.keys(User).length) {
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: User.UID,
        Flag: "M",
        Value: sYear,
        DFlag: DFlag,
        Type: "POST",
        FetchURL: `${appURL}api/atten-summary`,
      };

      Fetchdata(dataForm)
        .then(function (result) {
          if (result.StatusCode === 200) {
            const postResult = result.Values ? result.Values : "";
            setMSummary(postResult);

            setLoading(false);
          } else {
            setMSummary([]);

            setLoading(false);
          }
        })
        .catch((err) => {
          setMSummary([]);
          setLoading(false);
        });
    }
  }, []);

  const [leaveReport, setLeaveReport] = useState([]);

  useEffect(() => {
    leaveList();
  }, []);

  const leaveList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "D",
      DFlag: "N",
      Values: date,
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/leave-report`,
    };


    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setLeaveReport(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setLoading(false);
        setOriginalList([]);
        setLeaveReport([]);
      }
    });
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();

    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["StaffName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setLeaveReport(srchResult);
      } else {
        setLeaveReport({});
      }
    } else {
      setLeaveReport(originalList);
    }
  };
  const [approveGmail, setApproveGmail] = useState(false);
  const [rejectGmail, setRejectGmail] = useState(false);
  //Everything to pop up and approve the  list
  const [approvePopup, setApprovePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [rejectPopup, setRejectPopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  // To approve the credit List

  const [Acdata, setACData] = useState();
  const [leaveAccess, setLeaveAccess] = useState();
  const [acceptUserId, setAcceptUserId] = useState();

  const handleApprove = async (data) => {
    setACData(data[0]);
    setLeaveAccess(data[1]);
    setAcceptUserId(data[2]);

    setApprovePopup({
      show: true,
      data,
    });
  };

  // This will perform the approve and hide the Confirmation Box

  const handleApproveTrue = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID,
      StaffID: acceptUserId,
      Flag: "ULS",
      LeaveID: Acdata,
      LeaveStatus: "A",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Signature: "p0m76" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/leave-report`, requestOptions).then((result) => {
      result.json().then((resp) => {

        if (resp.StatusCode === 200) {
          leaveList();
          toast.success(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });
    });
    setApprovePopup({
      show: false,
      data: null,
    });
    setApproveGmail(false);
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleApproveFalse = () => {
    setApprovePopup({
      show: false,
      data: null,
    });
    setApproveGmail(false);
  };

  //Everything to pop up and approve the leave list ends

  // To reject the leave List

  const [bcdata, setBcData] = useState();
  const [leavedata, setLeaveData] = useState();
  const [userId, setUserId] = useState();

  const handleReject = async (data) => {
    setBcData(data[0]);
    setLeaveData(data[1]);
    setUserId(data[2]);

    setRejectPopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleRejectTrue = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID,
      StaffID: userId,
      Flag: "ULS",
      LeaveID: bcdata,
      LeaveStatus: "R",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Signature: "p0m76" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/leave-report`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          leaveList();
          toast.success(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });
    });
    setRejectPopup({
      show: false,
      data: null,
    });
    setRejectGmail(false);
  };

  //
  // };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleRejectFalse = () => {
    setRejectPopup({
      show: false,
      data: null,
    });
    setRejectGmail(false);
  };

  const handleView = (row) => {
    setViewPopup(true);
    setSelectedNote(row);
  };

  return (
    <>
      <div className="uk-flex uk-flex-right sticky-search">
        <div class="uk-search uk-search-default">
          <AiOutlineSearch className="search-icon" />
          <input
            placeholder={mode === "en" ? "Search" : "खोजी गर्नुहोस्"}
            ref={searchInput}
            type="text"
            className="form-control form-control-sm searchField"
            onChange={searchHandler}
          />
        </div>
      </div>
      <div
        className="attendance mt-3"
        // style={{ minHeight: "100px" }}
      >
        {userDetails.IsManager !== 0 ? (
          <>
            {leaveReport.length > 0 ? (
              <table className="uk-table uk-table-striped">
                <thead className="sticky-head">
                  <tr>
                    <td className="fw-bold">
                      {" "}
                      {mode === "en" ? "Name" : "नाम"}
                    </td>
                    <td className="fw-bold">
                      {" "}
                      {mode === "en" ? "Title" : "शीर्षक"}
                    </td>
                    <td className="fw-bold">
                      {mode === "en" ? "Days" : "दिनहरू"}
                    </td>
                    <td className="fw-bold text-center">
                      {" "}
                      {mode === "en" ? "Status" : "स्थिति"}
                    </td>
                    <td className="fw-bold">
                      {mode === "en" ? "Action" : "कार्य"}
                    </td>
                  </tr>
                </thead>

                {loading ? (
                  <Spinner />
                ) : (
                  <tbody>
                    {leaveReport.map((props) => {
                      const {
                        LeaveID,
                        StaffName,
                        StaffID,
                        Title,
                        LeaveType,
                        TotalDays,
                        LeaveStatus,
                      } = props;

                      return (
                        <tr
                          key={LeaveID}
                          style={{ borderLeft: "1px solid transparent" }}
                        >
                          <td
                            style={{
                              minWidth: "150px",
                              verticalAlign: "middle",
                            }}
                          >
                            {StaffName}
                          </td>
                          <td
                            style={{
                              minWidth: "150px",
                              verticalAlign: "middle",
                            }}
                          >
                            {Title}
                          </td>
                          <td
                            style={{
                              minWidth: "50px",
                              verticalAlign: "middle",
                            }}
                          >
                            {TotalDays}
                          </td>

                          <td
                            style={{
                              minWidth: "100px",
                              verticalAlign: "middle",
                            }}
                            className="text-center"
                          >
                            {LeaveStatus === "Pending" ? (
                              <div className="ln-verition d-flex uk-flex-center">
                                <button
                                  type="button"
                                  className="ln-verition btn btn-sm d-flex pe-1"
                                  // style={{
                                  //   background: "var(--button-color)",
                                  //   color: "white",
                                  // }}
                                  onClick={() =>
                                    handleApprove([
                                      LeaveID,
                                      LeaveStatus,
                                      StaffID,
                                    ])
                                  }
                                >
                                  <span
                                    className=" badge rounded-pill bg-primary"
                                    style={{ fontSize: "11px" }}
                                  >
                                    {mode === "en" ? "Approve" : "अनुमोदन"}
                                  </span>
                                </button>{" "}
                                <button
                                  type="button"
                                  className="ln-verition btn btn-sm d-flex ps-1"
                                  // style={{
                                  //   background: "red",
                                  //   color: "white",
                                  //   marginLeft: "5px",
                                  // }}
                                  onClick={() =>
                                    handleReject([
                                      LeaveID,
                                      LeaveStatus,
                                      StaffID,
                                    ])
                                  }
                                >
                                  <span
                                    className=" badge rounded-pill bg-danger"
                                    style={{ fontSize: "11px" }}
                                  >
                                    {mode === "en" ? "Reject" : "अस्वीकार"}
                                  </span>
                                </button>
                              </div>
                            ) : LeaveStatus === "Approved" ? (
                              <span
                                className=" badge rounded-pill bg-success"
                                style={{ fontSize: "11px" }}
                              >
                                {mode === "en" ? "Approved" : "स्वीकृत"}
                              </span>
                            ) : LeaveStatus === "Rejected" ? (
                              <span
                                className=" badge rounded-pill bg-danger"
                                style={{ fontSize: "11px" }}
                              >
                                {mode === "en" ? "Rejected" : "अस्वीकार गरियो"}
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                          <td
                            style={{
                              minWidth: "70px",
                              verticalAlign: "middle",
                            }}
                          >
                            <button
                              type="button"
                              class="btn btn-sm editspan mx-1"
                              onClick={() => handleView(props)}
                              uk-tooltip={mode === "en" ? "View" : "हेर्नुहोस्"}
                            >
                              <AiOutlineEye />
                            </button>{" "}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            ) : (
              <span className="d-block text-center">
                {" "}
                {mode === "en" ? "No Data" : "डाटा उपलब्ध छैन"}
              </span>
            )}
          </>
        ) : (
          <>
            <div className="uk-grid uk-child-width-1-1 mt-1 sticky-head">
              <div>
                <h5 className="fw-bold">
                  {mode === "en" ? "Total Leave" : "आज बिदामा"}
                </h5>
              </div>
            </div>
            {mSummary.map((props) => {
              const { TotalLeave } = props;
              return (
                <>
                  <div className="uk-grid uk-child-width-1-1 mt-1">
                    <div>
                      <h5>
                        {TotalLeave === null ? (
                          <span>
                            {mode === "en" ? "No Leave" : "बिदा उपलब्ध छैन"}
                          </span>
                        ) : (
                          TotalLeave
                        )}
                      </h5>
                    </div>
                  </div>
                </>
              );
            })}
          </>
        )}
      </div>

      {approvePopup.show && (
        <ApprovePop
          handleApproveTrue={handleApproveTrue}
          handleApproveFalse={handleApproveFalse}
          approveGmail={approveGmail}
          setApproveGmail={setApproveGmail}
        />
      )}

      {rejectPopup.show && (
        <RejectPop
          handleRejectTrue={handleRejectTrue}
          handleRejectFalse={handleRejectFalse}
          rejectGmail={rejectGmail}
          setRejectGmail={setRejectGmail}
        />
      )}

      {selected_note && (
        <ViewLeaveReport
          note={selected_note}
          viewPopup={viewPopup}
          setViewPopup={setViewPopup}
        />
      )}
    </>
  );
};

export default TodayLeave;
