import React, { useState, useEffect, useContext } from "react";
import { Fetchdata } from "../../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import AttendanceContext from "./AttendanceContext";
import AuthContext from "../../context/auth-context";
import UpperbarContext from "../../context/upperbar-context";
import $ from "jquery";

function AttendanceState(props) {
  const { User } = useContext(AuthContext);
  const { appURL } = useContext(UpperbarContext);

  // attendance route--------------
  const routeValue = {
    name: "",
    ssid: "",
  };

  const initalRoutevalue = {
    activeRoute: "-1",
  };

  const [route, setRoute] = useState(initalRoutevalue);

  const [routeValues, setRouteValues] = useState(routeValue);
  const [routeErrors, setRouteErrors] = useState({});
  const [routeSubmit, setRouteSubmit] = useState(false);
  const [routeEditSubmit, setRouteEditSubmit] = useState(false);
  const [routeActive, setRouteActive] = useState(0);
  const [routeLoading, setRouteLoading] = useState(false);
  const [initialRouteLoad, setInitialRouteLoad] = useState(true);

  const [originalList, setOriginalList] = useState(null);

  const [routeList, setRouteList] = useState([]);

  // to show route list

  useEffect(() => {
    rtList();
  }, [route]);

  const rtList = () => {
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      IsActive: route.activeRoute,
      BranchID: Branch,
      FiscalID: Fiscal,
      FetchURL: `${appURL}api/admin/atten-route`,
      Type: "POST",
    };

    Fetchdata(params).then(function (resp) {
      if (resp.StatusCode === 200) {
        const postResult = resp.Values ? resp.Values : "";
        setRouteList(postResult);
        setOriginalList(postResult);
        setRouteLoading(false);
        setInitialRouteLoad(false);
      } else {
        setRouteList([]);
        setOriginalList([]);
        setRouteLoading(false);
      }
    });
  };

  // to edit route

  const [perRouteID, setPerRouteId] = useState("");
  const [editRoutePop, setEditRoutePop] = useState(false);
  const handleRouteEdit = (data) => {
    setPerRouteId(data.RouteID);
    setRouteValues({
      name: data.Name,
      ssid: data.SSID,
    });
    setRouteActive(data.IsActive === "A" ? 1 : 0);
    setEditRoutePop(true);
  };

  const editRoute = () => {
    const id = "" + perRouteID;
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID,
      Flag: "U",
      RouteID: id,
      Name: routeValues.name,
      SSID: routeValues.ssid,
      IsActive: routeActive === 1 ? "A" : "I",
      FetchURL: `${appURL}api/admin/atten-route`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (resp) {
      if (resp.StatusCode === 200) {
        setEditRoutePop(false);
        rtList();
        $(".editRoutePopBg").fadeOut(500);
        $(".editRoutePop").slideUp(500);
        toast.success(resp.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + resp.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  // to hit route status
  const stat = [];
  const [newStatus, setNewStatus] = useState(stat);

  const deactivateRoute = (routeID, IsActive) => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID,
      Flag: "AI",
      RouteID: routeID,
      IsActive: IsActive,
      FetchURL: `${appURL}api/admin/atten-route`,
      Type: "POST",
    };
    if (IsActive === "A") {
      dataForm.IsActive = "I";
    } else {
      dataForm.IsActive = "A";
    }
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        rtList();
        let NewstatsN = JSON.parse(JSON.stringify(newStatus));
        let pitchNewStatus;

        if (dataForm.IsActive === "A") {
          pitchNewStatus = "Activated";
        } else if (dataForm.IsActive === "I") {
          pitchNewStatus = "Deactivated";
        }
        setNewStatus(NewstatsN);
        toast.success(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  // delete route
  const [deleteRoutePopUp, setDeleteRoutePopUp] = useState(false);
  const [deleteRouteID, setDeleteRouteId] = useState("");
  const handleRouteDelete = (data) => {
    setDeleteRouteId(data.RouteID);
    setDeleteRoutePopUp(true);
  };

  const routeDeleteID = "" + deleteRouteID;

  const deleteRoute = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID,
      Flag: "R",
      RouteID: routeDeleteID,
      FetchURL: `${appURL}api/admin/atten-route`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (resp) {
      if (resp.StatusCode === 200) {
        setDeleteRoutePopUp(false);
        rtList();
        toast.success(resp.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + resp.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  // attendance device--------------
  const deviceValue = {
    dvname: "",
    ipAddress: "",
    code: "",
    port: "",
  };

  const initalDevicevalue = {
    activeDevice: "-1",
  };

  const [device, setDevice] = useState(initalDevicevalue);

  const [deviceValues, setDeviceValues] = useState(deviceValue);
  const [deviceErrors, setDeviceErrors] = useState({});
  const [deviceSubmit, setDeviceSubmit] = useState(false);
  const [deviceEditSubmit, setDeviceEditSubmit] = useState(false);
  const [deviceActive, setDeviceActive] = useState(0);
  const [deviceLoading, setDeviceLoading] = useState(false);
  const [initialDeviceLoad, setInitialDeviceLoad] = useState(true);

  const [deviceList, setDeviceList] = useState([]);

  // to show device list

  // useEffect(() => {
  //   dvList();
  // }, [device]);

  // const dvList = () => {
  //   const obj = {
  //     UserID: User.UID.toString(),
  //     BranchID: User.BranchId.toString(),
  //   };
  //   const params = {
  //     ComID: User.CompanyId,
  //     StaffID: "-1",
  //     Flag: "S",
  //     IsActive: device.activeDevice,
  //     BranchID: obj.BranchID,
  //     FetchURL: `${appURL}api/admin/atten-device`,
  //     Type: "POST",
  //   };

  //   Fetchdata(params).then(function (resp) {
  //     if (resp.StatusCode === 200) {
  //       const postResult = resp.AttenMediumLst ? resp.AttenMediumLst : "";
  //       setDeviceList(postResult);
  //       setDeviceLoading(false);
  //       setInitialDeviceLoad(false);
  //     } else {
  //       setDeviceList([]);
  //       setDeviceLoading(false);
  //     }
  //   });
  // };

  // to edit device

  const [perDeviceID, setPerDeviceId] = useState("");
  const [editDevicePop, setEditDevicePop] = useState(false);
  const handleDeviceEdit = (data) => {
    // setPerDeviceId(data.DevID);
    // setDeviceValues({
    //   dvname: data.DevName,
    //   ipAddress: data.DevIP,
    //   code: data.DevCode,
    //   port: data.DevPort,
    // });
    // setDeviceActive(data.IsActive === "Y" ? 1 : 0);
    // setEditDevicePop(true);
  };

  const deviceperID = "" + perDeviceID;

  const editDevice = () => {
    // const dataForm = {
    //   ComID: User.CompanyId,
    //   StaffID: UserID,
    //   Flag: "U",
    //   DevID: deviceperID,
    //   DevCode: deviceValues.code,
    //   DevName: deviceValues.dvname,
    //   DevIP: deviceValues.ipAddress,
    //   DevPort: deviceValues.port,
    //   IsActive: deviceActive === 1 ? "Y" : "N",
    //   FetchURL: `${appURL}api/admin/atten-device`,
    //   Type: "POST",
    // };
    // Fetchdata(dataForm).then(function (resp) {
    //   if (resp.StatusCode === 200) {
    //     setEditDevicePop(false);
    //     dvList();
    //     toast.success(resp.Message, {
    //       style: {
    //         color: "green",
    //         fontSize: "13px",
    //       },
    //       theme: "light",
    //     });
    //   } else {
    //     toast.error("Error: " + resp.Message, {
    //       style: {
    //         color: "red",
    //         fontSize: "13px",
    //       },
    //       theme: "light",
    //     });
    //   }
    // });
  };

  // to hit device status
  const stat1 = [];
  const [newStatus1, setNewStatus1] = useState(stat1);

  const deactivateDevice = (deviceID, deviceActive) => {
    // const dataForm = {
    //   ComID: User.CompanyId,
    //   StaffID: User.UID,
    //   Flag: "US",
    //   DevID: deviceID,
    //   IsActive: deviceActive,
    //   FetchURL: `${appURL}api/admin/atten-device`,
    //   Type: "POST",
    // };
    // if (deviceActive === "Y") {
    //   dataForm.IsActive = "N";
    // } else {
    //   dataForm.IsActive = "Y";
    // }
    // Fetchdata(dataForm).then(function (result) {
    //   if (result.StatusCode === 200) {
    //     dvList();
    //     let NewstatsN1 = JSON.parse(JSON.stringify(newStatus1));
    //     let pitchNewStatus1;

    //     if (dataForm.IsActive === "Y") {
    //       pitchNewStatus1 = "Activated";
    //     } else if (dataForm.IsActive === "N") {
    //       pitchNewStatus1 = "Deactivated";
    //     }
    //     setNewStatus1(NewstatsN1);
    //     toast.success(result.Message, {
    //       style: {
    //         color: "green",
    //         fontSize: "13px",
    //       },
    //       theme: "light",
    //     });
    //   } else {
    //     toast.error("Error: " + result.Message, {
    //       style: {
    //         color: "red",
    //         fontSize: "13px",
    //       },
    //       theme: "light",
    //     });
    //   }
    // });
  };

  // delete device
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [deleteDeviceID, setDeleteDeviceId] = useState("");
  const handleDeviceDelete = (data) => {
    setDeleteDeviceId(data.DevID);

    setDeletePopUp(true);
  };

  const deviceDeleteID = "" + deleteDeviceID;

  const deleteDevice = () => {
    // const dataForm = {
    //   ComID: User.CompanyId,
    //   StaffID: UserID,
    //   Flag: "R",
    //   DevID: deviceDeleteID,
    //   FetchURL: `${appURL}api/admin/atten-device`,
    //   Type: "POST",
    // };
    // Fetchdata(dataForm).then(function (resp) {
    //   if (resp.StatusCode === 200) {
    //     setDeletePopUp(false);
    //     dvList();
    //     toast.success(resp.Message, {
    //       style: {
    //         color: "green",
    //         fontSize: "13px",
    //       },
    //       theme: "light",
    //     });
    //   } else {
    //     toast.error("Error: " + resp.Message, {
    //       style: {
    //         color: "red",
    //         fontSize: "13px",
    //       },
    //       theme: "light",
    //     });
    //   }
    // });
  };

  return (
    <AttendanceContext.Provider
      value={{
        route,
        setRoute,
        routeValue,
        routeValues,
        setRouteValues,
        routeErrors,
        setRouteErrors,
        routeSubmit,
        setRouteSubmit,
        routeActive,
        setRouteActive,
        routeList,
        setRouteList,
        initialRouteLoad,
        setInitialRouteLoad,
        routeLoading,
        setRouteLoading,
        rtList,
        deactivateRoute,
        editRoute,
        routeEditSubmit,
        setRouteEditSubmit,
        handleRouteEdit,
        editRoutePop,
        setEditRoutePop,
        handleRouteDelete,
        deleteRoutePopUp,
        setDeleteRoutePopUp,
        deleteRoute,

        device,
        setDevice,
        deviceValue,
        deviceValues,
        setDeviceValues,
        deviceErrors,
        setDeviceErrors,
        deviceSubmit,
        setDeviceSubmit,
        deviceActive,
        setDeviceActive,
        deviceList,
        initialDeviceLoad,
        setInitialDeviceLoad,
        deviceLoading,
        setDeviceLoading,
        // dvList,
        deactivateDevice,
        editDevice,
        deviceEditSubmit,
        setDeviceEditSubmit,
        handleDeviceEdit,
        editDevicePop,
        setEditDevicePop,
        handleDeviceDelete,
        deletePopUp,
        setDeletePopUp,
        deleteDevice,
        originalList,
      }}
    >
      {props.children}
    </AttendanceContext.Provider>
  );
}
export default AttendanceState;
