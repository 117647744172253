import React from "react";
import "./style.css";
import white from "../images/decorative-white-circle.svg";
import blue from "../images/decorative-blue-circle.svg";
import green from "../images/decorative-green-diamond.svg";
import yellow from "../images/decorative-yellow-circle.svg";
import logo from "../images/logo.png";
import iphone from "../images/header-iphone.png";
import feature from "../images/features-app.png";
import { Link } from "react-router-dom";
import $ from "jquery";
import Footer from "./Footer";
import '../css/footer.css';

$(function () {
  // $(window).on("load", function () {
  //   var preloaderFadeOutTime = 500;
  //   function hidePreloader() {
  //     var preloader = $(".spinner-wrapper");
  //     setTimeout(function () {
  //       preloader.fadeOut(preloaderFadeOutTime);
  //     }, 500);
  //   }
  //   hidePreloader();
  // });
  $(window).on("scroll load", function () {
    if ($(".navbar").offset().top > 60) {
      $(".fixed-top").addClass("top-nav-collapse");
    } else {
      $(".fixed-top").removeClass("top-nav-collapse");
    }
  });
});

const Index = () => {
  return (
    <div className="index-page">
      {/* <div class="spinner-wrapper">
        <div class="spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div> */}
      <nav class="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
        <div class="container">
          <a className="navbar-brand" href="index.html">
            <img style={{ width: "100px" }} src={logo} alt="alternative" />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            class="collapse navbar-collapse justify-content-center"
            id="navbarSupportedContent"
          >
            <ul class="navbar-nav  mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#">
                  Home
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link">Features</a>
              </li>
              <li class="nav-item">
                <a class="nav-link">Blog</a>
              </li>
              <li class="nav-item">
                <Link className="nav-link" to="/jobList" target="_blank">
                  Job
                </Link>
              </li>
              <li class="nav-item">
                <Link className="nav-link" to="/tax-calculator" target="_blank">
                  Tax Calculator
                </Link>
              </li>
              <li class="nav-item">
                <a class="nav-link">Contact Us</a>
              </li>
            </ul>
            <div className="login-mob-btn mb-2" id="navbarsExampleDefault">
              {/* <span className="nav-item">
              <Link
                className="btn-solid-lg page-scroll"
                to="/jobList"
                target="_blank"
              >
                Job
              </Link>
            </span> */}
              <span className="nav-item">
                <Link
                  className="btn-solid-lg page-scroll"
                  to="/login"
                  target="_blank"
                >
                  Login
                </Link>
              </span>
            </div>
          </div>
          <div className="login-btn" id="navbarsExampleDefault">
            {/* <span className="nav-item">
              <Link
                className="btn-solid-lg page-scroll"
                to="/jobList"
                target="_blank"
              >
                Job
              </Link>
            </span> */}
            <span className="nav-item">
              <Link
                className="btn-solid-lg page-scroll"
                to="/login"
                target="_blank"
              >
                Login
              </Link>
            </span>
          </div>
        </div>
      </nav>

      <header className="header pb-0 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-container">
                <h1>
                  <font className="me-3" color="#c03110">
                    Elite
                  </font>
                  <font color="#3b5998">Office Management</font>
                </h1>
                <p className="p-large p-heading">
                  <font className="me-1" color="#3b5998">
                    By
                  </font>
                  <font className="me-1" color="#c03110">
                    Elite
                  </font>
                  <font color="#3b5998">Infotech</font>
                </p>
                <a
                  className="btn-solid-lg"
                  href="https://play.google.com/store/apps/details?id=com.eliteinfotech.eliteoffice"
                  target="_blank"
                >
                  <i className="fab fa-apple"></i>DOWNLOAD
                </a>
                <a
                  className="btn-solid-lg"
                  href="https://play.google.com/store/apps/details?id=com.eliteinfotech.eliteoffice"
                  target="_blank"
                >
                  <i className="fab fa-google-play"></i>DOWNLOAD
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="image-container p-5">
                <img
                  src={iphone}
                  alt=""
                  data-aos="zoom-in"
                  data-aos-delay="150"
                  style={{ transform: "1.2" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="deco-white-circle-1">
          <img src={white} alt="alternative" />
        </div>
        <div className="deco-white-circle-2">
          <img src={white} alt="alternative" />
        </div>
        <div className="deco-blue-circle">
          <img src={blue} alt="alternative" />
        </div>
        <div className="deco-yellow-circle">
          <img src={yellow} alt="alternative" />
        </div>
        <div className="deco-green-diamond">
          <img src={green} alt="alternative" />
        </div>
      </header>

      <div id="features" className="basic-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2>Elite Office Features</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <ul className="list-unstyled li-space-lg first">
                <li className="media">
                  <span className="fa-stack">
                    <i className="fas fa-circle fa-stack-2x"></i>
                    <i className="fas fa-users fa-stack-1x"></i>
                  </span>
                  <div className="media-body">
                    <h4>Staff Management</h4>
                    <p>
                      Manage staff according to department, sub-department and
                      designation.
                    </p>
                  </div>
                </li>
                <li className="media">
                  <span className="fa-stack">
                    <i className="fas fa-circle fa-stack-2x green"></i>
                    <i className="fas fa-check fa-stack-1x"></i>
                  </span>
                  <div className="media-body">
                    <h4>Attendance Management</h4>
                    <p>Manage attendance record of staff.</p>
                  </div>
                </li>
                <li className="media">
                  <span className="fa-stack">
                    <i className="fas fa-circle fa-stack-2x red"></i>
                    <i className="fas fa-sticky-note fa-stack-1x"></i>
                  </span>
                  <div className="media-body">
                    <h4>Leave Note Management</h4>
                    <p>Manage leave taken by staff.</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-lg-4">
              <img className="img-fluid" src={feature} alt="alternative" />
            </div>
            <div className="col-lg-4">
              <ul className="list-unstyled li-space-lg last">
                <li className="media">
                  <span className="fa-stack">
                    <i className="fas fa-circle fa-stack-2x yellow"></i>
                    <i className="fas fa-calendar fa-stack-1x"></i>
                  </span>
                  <div className="media-body">
                    <h4>Upcoming Holiday</h4>
                    <p>Inform about upcoming holiday in organization.</p>
                  </div>
                </li>
                <li className="media">
                  <span className="fa-stack">
                    <i className="fas fa-circle fa-stack-2x blue"></i>
                    <i className="fas fa-birthday-cake fa-stack-1x"></i>
                  </span>
                  <div className="media-body">
                    <h4>Upcoming Birthday</h4>
                    <p>Inform about upcoming holiday in organization.</p>
                  </div>
                </li>
                <li className="media">
                  <span className="fa-stack">
                    <i className="fas fa-circle fa-stack-2x"></i>
                    <i className="fas fa-bell fa-stack-1x"></i>
                  </span>
                  <div className="media-body">
                    <h4>Notice </h4>
                    <p>Inform about notice in organization.</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer logo={logo} />
    </div>
  );
};

export default Index;
