import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import AuthContext from "../../../context/auth-context";
import UpperbarContext from "../../../context/upperbar-context";
import Toast from "../../../Toast";
import "../ledger report/ledger.css";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import { Fetchdata } from "../../../hooks/getData";
import Spinner from "../../../loading/spinner";
import {
  GetNepaliDate,
  GetCurrYear,
  GetCurrMonth,
  GetEnglishDate,
} from "../../../hooks/dateConvertor";
import NepaliDate from "nepali-date-converter";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function Report() {
  const { User } = useContext(AuthContext);

  //   const searchInput = useRef("");
  const { fiscalYear, dateMode, todayDate, appURL, userDetails, mode } =
    useContext(UpperbarContext);

  const [DFlag, setDflag] = useState("N");
  const [dateData, setDateData] = useState("S");

  const [loading, setLoading] = useState(true);

  const cur_year = new Date().getFullYear();
  const nYears = generateArrayOfNepYears(cur_year);

  function getNepaliDate(date) {
    const nepDate = new NepaliDate().getBS();
    //
    return nepDate;
  }

  function generateArrayOfNepYears(cur_year) {
    let nCur_date = getNepaliDate(cur_year);
    var max = nCur_date.year;
    var min = 2079;
    var years = [];

    // for (var i = max; i >= min; i--) {
    //   years.push(i);
    // }
    for (var i = min; i <= max; i++) {
      years.push(i);
    }
    return years;
  }

  var date = new Date();
  const initalvalue = {
    // specificDate: mode === "np" ? GetNepaliDate(date) : GetEnglishDate(date),
    // fromDate: mode === "np" ? GetNepaliDate(date) : GetEnglishDate(date),
    // toDate: mode === "np" ? GetNepaliDate(date) : GetEnglishDate(date),
    specificDate: GetNepaliDate(date),
    fromDate: GetNepaliDate(date),
    toDate: GetNepaliDate(date),
    year: GetCurrYear(),
    month: GetCurrMonth(),
  };
  const [inputValues, setInputValues] = useState(initalvalue);

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setDateData(value);
  };
  // const handleLedger = (e) => {
  //   const target = e.target;
  //   const name = target.name;
  //   const value = target.value;
  //   setLedgerData(value);
  // };

  const handleOnChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };
  const handleDate = ({ bsDate }) => {
    setInputValues({ ...inputValues, specificDate: bsDate });
  };
  const handleFromDate = ({ bsDate }) => {
    setInputValues({ ...inputValues, fromDate: bsDate });
  };
  const handleToDate = ({ bsDate }) => {
    setInputValues({ ...inputValues, toDate: bsDate });
  };
  //   API to under list
  const [ledgerUnderList, setLedgerUnderList] = useState([]);

  useEffect(() => {
    ldgrunderLst();
  }, []);

  const ldgrunderLst = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      DFlag: "LEDGER",
      IsBank: "-1",
      IsPrimary: "Y",
      CID: "1",
      Flag: "S",
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/ref-values`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setLedgerUnderList(postResult);
      } else {
        setLedgerUnderList([]);
      }
    });
  };

  const ledgerValue = ledgerUnderList.map((item) => ({
    value: item.LedgerID,
    label: item.Ledger,
  }));

  const dropDownValue = [
    {
      value: -1,
      label: "All",
    },
    ...ledgerValue,
  ];
  console.log("dropDownValue", dropDownValue);

  const [ledgerData, setLedgerData] = useState(dropDownValue[0].value);
  const handleSelectLedger = (e) => {
    setLedgerData(e.value);
  };
  console.log("ledgerData", ledgerData);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  //   API to day book list
  const [reportList, setReportList] = useState([]);

  useEffect(() => {
    reportLst();
  }, [dateData, inputValues, ledgerData]);

  const reportLst = () => {
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      CID: "1",
      Flag: "S",
      DFlag: "SR",
      SFlag: dateData,
      DtFlag: "N",
      // DtFlag: mode === "np" ? "N" : "E",
      LedgerID: ledgerData.toString(),
      Values:
        dateData === "S"
          ? inputValues.specificDate
          : dateData === "Y"
          ? inputValues.year
          : dateData === "M"
          ? `${inputValues.year}/${inputValues.month}`
          : dateData === "F"
          ? ""
          : "",
      DateFrom: dateData === "F" ? inputValues.fromDate : "",
      DateTo: dateData === "F" ? inputValues.toDate : "",
      BranchID: Branch,
      FiscalID: Fiscal,
      Type: "POST",
      FetchURL: `${appURL}api/acc/report/ledger`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setReportList(postResult);
        setLoading(false);
      } else {
        setReportList([]);
        setLoading(false);
      }
    });
  };

  const groupData = reportList.reduce((acc, curr) => {
    if (!acc[curr.Ledger]) {
      acc[curr.Ledger] = [];
    }
    acc[curr.Ledger].push(curr);
    return acc;
  }, {});

  let dr = reportList.reduce(function (prev, current) {
    return prev + +current.DrAmt;
  }, 0);
  let cr = reportList.reduce(function (prev, current) {
    return prev + +current.CrAmt;
  }, 0);

  let total = dr - cr;

  return (
    <>
      {/* <Toast /> */}

      <>
        <div className="upper-dataTbl">
          <div className="uk-flex uk-flex-wrap uk-flex-between uk-flex-middle mb-3">
            <div className="uk-flex uk-flex-wrap me-5">
              <div className="me-2 mt-3">
                <label className="d-block text-start dark-gray mb-1">
                  {mode === "en" ? "Select Option" : "विकल्प चयन गर्नुहोस्"}
                </label>
                <select
                  style={{ fontSize: "11px" }}
                  name="ledger"
                  value={dateData}
                  onChange={handleChange}
                  className="form-select form-select-sm"
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    {mode === "en" ? "Select Option" : "विकल्प चयन गर्नुहोस्"}
                  </option>
                  <option value="S">
                    {mode === "en" ? "Specific Date" : "विशिष्ट मिति"}
                  </option>
                  <option value="F">
                    {mode === "en" ? "Date From & To" : "मिति देखि र सम्म"}
                  </option>
                  <option value="Y">
                    {mode === "en" ? "Select Year" : "वर्ष चयन गर्नुहोस्"}
                  </option>
                  <option value="M">
                    {mode === "en" ? "Select Month" : "महिना चयन गर्नुहोस्"}
                  </option>
                </select>
              </div>

              {dateData === "S" && (
                <div className="me-2 mt-3">
                  <label className="d-block text-start dark-gray mb-1">
                    {mode === "en" ? "Select Date" : "नेपाली चयन गर्नुहोस्"}
                  </label>
                  {dateMode === "np" ? (
                    <Calendar
                      // id="eventDate"
                      className="form-control form-control-sm pt-0 pb-0 "
                      dateFormat="YYYY/MM/DD"
                      // defaultDate="2010/01/01"
                      theme="default"
                      language="en"
                      // name="specificDate"
                      // value={holidayFormValue.eventDate}
                      onChange={handleDate}

                      // hideDefaultValue={true}
                      // placeholder={"Select Follow Date"}
                    />
                  ) : (
                    <input
                      type="date"
                      value={inputValues.specificDate}
                      placeholder="Select a Date"
                      className="form-control form-control-sm "
                      name="specificDate"
                      onChange={handleOnChange}
                    />
                  )}
                </div>
              )}

              {dateData === "F" && (
                <>
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en"
                        ? "Select From Date"
                        : "मिति देखि चयन गर्नुहोस्"}
                    </label>
                    {dateMode === "np" ? (
                      <Calendar
                        // id="eventDate"
                        className="form-control form-control-sm pt-0 pb-0 "
                        dateFormat="YYYY/MM/DD"
                        // defaultDate="2010/01/01"
                        theme="default"
                        language="en"
                        // value={holidayFormValue.eventDate}
                        onChange={handleFromDate}
                        name="fromDate"

                        // hideDefaultValue={true}
                        // placeholder={"Select Follow Date"}
                      />
                    ) : (
                      <input
                        type="date"
                        value={inputValues.fromDate}
                        placeholder="Select a Date"
                        className="form-control form-control-sm "
                        name="fromDate"
                        onChange={handleOnChange}
                      />
                    )}
                  </div>
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en"
                        ? "Select To Date"
                        : "मिति सम्म चयन गर्नुहोस्"}
                    </label>
                    {dateMode === "np" ? (
                      <Calendar
                        // id="eventDate"
                        className="form-control form-control-sm pt-0 pb-0 "
                        dateFormat="YYYY/MM/DD"
                        // defaultDate="2010/01/01"
                        theme="default"
                        language="en"
                        // value={holidayFormValue.eventDate}
                        onChange={handleToDate}
                        name="toDate"

                        // hideDefaultValue={true}
                        // placeholder={"Select Follow Date"}
                      />
                    ) : (
                      <input
                        type="date"
                        value={inputValues.toDate}
                        placeholder="Select a Date"
                        className="form-control form-control-sm "
                        name="toDate"
                        onChange={handleOnChange}
                      />
                    )}
                  </div>
                </>
              )}

              {dateData === "Y" && (
                <div className="me-2 mt-3">
                  <label className="d-block text-start dark-gray mb-1">
                    {mode === "en" ? "Select Year" : "वर्ष चयन गर्नुहोस्"}
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example "
                    onChange={handleOnChange}
                    name="year"
                    value={inputValues.year}
                  >
                    <option disabled value="" selected>
                      {mode === "en" ? "Select Year" : "वर्ष चयन गर्नुहोस्"}
                    </option>
                    {nYears.map((list) => (
                      <>
                        <option key={list} value={list}>
                          {list}
                        </option>
                      </>
                    ))}
                  </select>
                </div>
              )}
              {dateData === "M" && (
                <>
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en" ? "Select Year" : "वर्ष चयन गर्नुहोस्"}
                    </label>
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example "
                      onChange={handleOnChange}
                      name="year"
                      value={inputValues.year}
                    >
                      <option disabled value="" selected>
                        {mode === "en" ? "Select Year" : "वर्ष चयन गर्नुहोस्"}
                      </option>
                      {nYears.map((list) => (
                        <>
                          <option key={list} value={list}>
                            {list}
                          </option>
                        </>
                      ))}
                    </select>
                  </div>

                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en" ? "Select Month" : "महिना चयन गर्नुहोस्"}
                    </label>
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example "
                      onChange={handleOnChange}
                      name="month"
                      value={inputValues.month}
                    >
                      <option disabled value="" selected>
                        {mode === "en" ? "Select Month" : "महिना चयन गर्नुहोस्"}
                      </option>
                      <option value="01">
                        {mode === "en" ? "Baishakh" : "बैशाख"}
                      </option>
                      <option value="02">
                        {mode === "en" ? "Jestha" : "जेष्ठ"}
                      </option>
                      <option value="03">
                        {mode === "en" ? "Ashar" : "असार"}
                      </option>
                      <option value="04">
                        {mode === "en" ? "Shrawan" : "श्रावण"}
                      </option>
                      <option value="05">
                        {mode === "en" ? "Bhadra" : "भाद्र"}
                      </option>
                      <option value="06">
                        {mode === "en" ? "Asoj" : "असोज"}
                      </option>
                      <option value="07">
                        {mode === "en" ? "Kartik" : "कार्तिक"}
                      </option>
                      <option value="08">
                        {mode === "en" ? "Mangsir" : "मंसिर"}
                      </option>
                      <option value="09">
                        {mode === "en" ? "Poush" : "पौष"}
                      </option>
                      <option value="10">
                        {mode === "en" ? "Magh" : "माघ"}
                      </option>
                      <option value="11">
                        {mode === "en" ? "Falgun" : "फाल्गुन"}
                      </option>
                      <option value="12">
                        {mode === "en" ? "Chaitra" : "चैत्र"}
                      </option>
                    </select>
                  </div>
                </>
              )}

              <div className="me-2 mt-3" style={{ minWidth: "200px" }}>
                <label className="d-block text-start dark-gray mb-1">
                  {mode === "en" ? "Select Ledger" : "लेजर चयन गर्नुहोस्"}
                </label>
                {/* <select
                  style={{ fontSize: "11px" }}
                  name="ledger"
                  value={ledgerData}
                  onChange={handleLedger}
                  className="form-select form-select-sm"
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    {mode === "en" ? "Select Ledger" : "लेजर चयन गर्नुहोस्"}
                  </option>
                  <option value="-1">{mode === "en" ? "All" : "सबै"}</option>
                  {ledgerUnderList.map((props) => {
                    return (
                      <option key={props.LedgerID} value={props.LedgerID}>
                        {props.Ledger}
                      </option>
                    );
                  })}
                </select> */}
                <Autocomplete
                  className="w-100"
                  disablePortal
                  id="combo-box-demo"
                  open={open}
                  onOpen={() => {
                    if (inputValue) {
                      setOpen(true);
                    }
                  }}
                  defaultValue={dropDownValue[0]}
                  onClose={() => setOpen(false)}
                  options={dropDownValue}
                  name="staff"
                  isOptionEqualToValue={(option) => option.label}
                  onChange={(event, newValue) => {
                    handleSelectLedger(newValue);
                  }}
                  inputValue={inputValue}
                  onInputChange={(e, value, reason) => {
                    setInputValue(value);

                    if (!value) {
                      setOpen(false);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-3 mt-3 tableHeight">
          {loading ? (
            <Spinner />
          ) : (
            <>
              {reportList.length > 0 ? (
                <table className="uk-table reportTable">
                  <thead>
                    <tr className="fixed-head">
                      <td className="rdt_TableHeadRow tableHead" width="120px">
                        {mode === "en" ? "Date" : "मिति"}
                      </td>
                      <td
                        className="rdt_TableHeadRow tableHead text-center"
                        width="70px"
                      >
                        {mode === "en" ? "V.N" : "वि.एन"}
                      </td>
                      <td className="rdt_TableHeadRow tableHead">
                        {mode === "en" ? "Particular" : "विशेष"}
                      </td>

                      <td
                        className="rdt_TableHeadRow tableHead text-end"
                        width="150px"
                      >
                        {mode === "en" ? "Debit Amount" : "डेबिट रकम"}
                      </td>
                      <td
                        className="rdt_TableHeadRow tableHead text-end"
                        width="150px"
                      >
                        {mode === "en" ? "Credit Amount" : "क्रेडिट रकम"}
                      </td>
                      <td
                        className="rdt_TableHeadRow tableHead text-end"
                        width="150px"
                      >
                        {mode === "en" ? "Balance" : "सन्तुलन"}
                      </td>
                    </tr>
                  </thead>

                  <tbody className="mx-auto">
                    {Object.entries(groupData).map(
                      ([ledger, vouchers], index) => {
                        const drTotal = vouchers.reduce(
                          (total, voucher) => total + voucher.DrAmt,
                          0
                        );

                        const crTotal = vouchers.reduce(
                          (total, voucher) => total + voucher.CrAmt,
                          0
                        );

                        const drCr = Math.abs(
                          vouchers.reduce(
                            (total, voucher) => total + voucher.DrAmt,
                            0
                          ) -
                            vouchers.reduce(
                              (total, voucher) => total + voucher.CrAmt,
                              0
                            )
                        );
                        return (
                          <React.Fragment key={ledger}>
                            <tr>
                              <td
                                className="rdt_TableCell tablecell "
                                colSpan={4}
                              >
                                <strong>{ledger}</strong>
                              </td>
                              <td className="rdt_TableCell tablecell "></td>
                              <td className="rdt_TableCell tablecell "></td>
                            </tr>
                            {vouchers.map((voucher) => {
                              const totalDrCr = Math.abs(
                                voucher.DrAmt.toFixed(2) -
                                  voucher.CrAmt.toFixed(2)
                              );
                              return (
                                <tr key={voucher.VoucherNo}>
                                  <td
                                    style={{ minWidth: "120px" }}
                                    className="rdt_TableCell tablecell "
                                  >
                                    {voucher.Date}
                                  </td>
                                  <td
                                    style={{ minWidth: "70px" }}
                                    className="rdt_TableCell tablecell text-center"
                                  >
                                    {voucher.VoucherNo}
                                  </td>
                                  <td
                                    style={{ minWidth: "200px" }}
                                    className="rdt_TableCell tablecell "
                                  >
                                    {voucher.VoucherType}
                                  </td>
                                  <td
                                    style={{ minWidth: "150px" }}
                                    className="rdt_TableCell tablecell text-end"
                                  >
                                    {voucher.DrAmt.toFixed(2)}
                                  </td>
                                  <td
                                    style={{ minWidth: "150px" }}
                                    className="rdt_TableCell tablecell text-end"
                                  >
                                    {voucher.CrAmt.toFixed(2)}
                                  </td>
                                  <td
                                    style={{ minWidth: "150px" }}
                                    className="rdt_TableCell tablecell text-end"
                                  >
                                    {totalDrCr.toFixed(2)}{" "}
                                    {voucher.CrAmt > voucher.DrAmt
                                      ? "CR"
                                      : "DR"}
                                  </td>
                                </tr>
                              );
                            })}
                            <tr className="reportTotal">
                              <td
                                colSpan={3}
                                className="rdt_TableCell tablecell text-end"
                              >
                                <strong>
                                  {mode === "en" ? "Total" : "कुल"}
                                </strong>
                              </td>
                              <td className="rdt_TableCell tablecell text-end">
                                <strong>{drTotal.toFixed(2)}</strong>
                              </td>
                              <td className="rdt_TableCell tablecell text-end">
                                <strong>{crTotal.toFixed(2)}</strong>
                              </td>
                              <td className="rdt_TableCell tablecell text-end">
                                <strong>
                                  {drCr.toFixed(2)}{" "}
                                  {crTotal > drTotal ? "CR" : "DR"}
                                </strong>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      }
                    )}
                  </tbody>
                  <tfoot>
                    <tr className="reportTotal">
                      <td
                        colSpan={3}
                        className="rdt_TableCell tablecell text-end"
                      >
                        <strong>
                          {mode === "en" ? "Grand Total" : "कूल जम्मा"}
                        </strong>
                      </td>
                      <td className="rdt_TableCell tablecell text-end">
                        <strong>{dr.toFixed(2)}</strong>
                      </td>
                      <td className="rdt_TableCell tablecell text-end">
                        <strong>{cr.toFixed(2)}</strong>
                      </td>
                      <td className="rdt_TableCell tablecell text-end">
                        <strong>
                          {Math.abs(total).toFixed(2)}
                          {""} {cr > dr ? "CR" : "DR"}
                        </strong>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              ) : (
                <p className="text-center">
                  {mode === "en" ? "No Data" : "डाटा उपलब्ध छैन"}
                </p>
              )}
            </>
          )}
        </div>
      </>
    </>
  );
}
