import React, { useContext, useEffect, useRef, useState } from "react";
import "../../../profile/profile.css";
import DataTable from "react-data-table-component";
import { Fetchdata } from "../../../hooks/getData";
import AuthContext from "../../../context/auth-context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DesignationPopup from "./DesignationPopup";
import DesignationEditPopup from "./DesignationEditPopup";
import Spinner from "../../../loading/spinner";
import UpperbarContext from "../../../context/upperbar-context";
import StaffContext from "../staffState/StaffContext";
import { FaRegCalendarAlt } from "react-icons/fa";
import Toast from "../../../Toast";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { GoPlus } from "react-icons/go";
import { AiOutlineEdit, AiOutlineSearch } from "react-icons/ai";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { englishToNepaliNumber } from "nepali-number";
import { generatePdf } from "../../../hooks/PdfExcel";

export default function Designation() {
  const {
    fiscalYear,
    expires,
    customStyles,
    appURL,
    todayDate,
    mode,
    userDetails,
  } = useContext(UpperbarContext);
  const [designationPopup, setDesignationPopup] = useState(false);
  const [designationEditPopup, setDesignationEditPopup] = useState(false);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const searchInput = useRef("");

  const designationValue = {
    department: "",
    subdepartment: "",
    designation: "",
    maxSalary: "",
    minSalary: "",
  };
  const [designationFormValue, setDesignationFormValue] =
    useState(designationValue);
  const [designationFormError, setDesignationFormError] = useState({});

  const [chooseDepartment, setChooseDepartment] = useState("-1");
  const [chooseSubDepartment, setChooseSubDepartment] = useState("-1");

  const { User } = useContext(AuthContext);

  const addLeaveNote = (e) => {
    setDesignationPopup(true);

    setDesignationFormValue(designationValue);
  };

  const [titleId, setTitleID] = useState();
  const editPop = (datas) => {
    setDesignationEditPopup(true);
    setTitleID(datas.DesignationID);
    setDesignationFormValue({
      department: datas.DepartID,
      subdepartment: datas.SubDepartID,
      designation: datas.Designation,
      maxSalary: datas.MaxSal,
      minSalary: datas.MinSal,
    });
  };

  //API to hit Designation status

  const stateInitial = [];
  const [newStat, setNewStat] = useState(stateInitial);

  const deactivateDepart = (ID, IsActive) => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID,
      Flag: "AI",
      DesigID: ID,
      IsActive: IsActive,
      FetchURL: `${appURL}api/admin/designation`,
      Type: "POST",
    };
    if (IsActive === "A") {
      dataForm.IsActive = "I";
    } else {
      dataForm.IsActive = "A";
    }
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        desgList();
        let statsN = JSON.parse(JSON.stringify(newStat));
        let pitchStatus;

        if (dataForm.IsActive === "A") {
          pitchStatus = "Activated";
        } else if (dataForm.IsActive === "I") {
          pitchStatus = "Deactivated";
        }

        setNewStat(statsN);
        toast.success(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  const changeStatus = (ID, IsActive) => {
    deactivateDepart(ID, IsActive);
  };

  const checkStatus = (IsActive) => {
    if (IsActive === "A") {
      return mode === "en" ? "Deactivate" : "निष्क्रिय";
    } else if (IsActive === "I") {
      return mode === "en" ? "Activate" : "सक्रिय";
    }
  };

  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      grow: 0,
      center: true,
      width: "70px",
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },
    {
      name: mode === "en" ? "Designation" : "पदनाम",
      // grow: 0,
      // center: true,
      minWidth: "200px",
      selector: (row) => row.Designation,
    },
    {
      name: mode === "en" ? "Department" : "विभाग",
      // center: true,
      // grow: 0,
      minWidth: "200px",
      selector: (row) => row.Department,
    },
    // {
    //   name: mode === "en" ? "Sub Department" : "उप विभाग",
    //   grow: 1,
    //   // center: true,
    //   width: "200px",
    //   selector: (row) => row.SubDepartment,
    // },

    {
      name: mode === "en" ? "Staff" : "कर्मचारी",
      // grow: 0,
      // center: true,
      width: "150px",
      selector: (row) =>
        mode === "en" ? row.NoOfStaff : englishToNepaliNumber(row.NoOfStaff),
    },
    {
      name: mode === "en" ? "Max. Sal" : "अधिकतम तलब",
      // center: true,
      // grow: 0,
      width: "150px",
      selector: (row) =>
        mode === "en"
          ? row.MaxSal
          : englishToNepaliNumber(row.MaxSal) === "null"
          ? ""
          : englishToNepaliNumber(row.MaxSal),
    },
    {
      name: mode === "en" ? "Min. Sal" : "न्यूनतम तलब",
      // center: true,
      // grow: 0,
      width: "150px",
      selector: (row) =>
        mode === "en"
          ? row.MinSal
          : englishToNepaliNumber(row.MinSal) === "null"
          ? ""
          : englishToNepaliNumber(row.MinSal),
    },
    {
      name: mode === "en" ? "Status" : "स्थिति",
      // grow: 0,
      center: true,
      width: "140px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                className="border-0 bg-transparent"
                onClick={() => changeStatus(row.DesignationID, row.IsActive)}
              >
                <span
                  className=" badge rounded-pill bg-success"
                  style={{ fontSize: "11px" }}
                >
                  {checkStatus(row.IsActive)}
                </span>
              </button>
            </div>
          </>
        );
      },
    },
    {
      name: mode === "en" ? "Action" : "कार्य",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                class="btn btn-sm editspan"
                onClick={() => editPop(row)}
                uk-tooltip={mode === "en" ? "Edit" : "सम्पादन गर्नुहोस्"}
              >
                <AiOutlineEdit />
              </button>{" "}
            </div>
          </>
        );
      },
    },
  ];

  //API to hit Designation list
  const [designationList, setDesignationList] = useState([]);
  useEffect(() => {
    desgList();
  }, [chooseDepartment]);

  const desgList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      DepartID: chooseDepartment,
      IsActive: "-1",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/designation`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setDesignationList(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setLoading(false);
        setOriginalList([]);
        setDesignationList([]);
      }
    });
  };

  //API to hit Department list
  const [departmentList, setDepartmentList] = useState([]);

  useEffect(() => {
    deptList();
  }, []);

  const deptList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      IsActive: "A",
      Type: "POST",
      FetchURL: `${appURL}api/admin/department`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";

        setDepartmentList(postResult);
      } else {
      }
    });
  };

  // API to hit Sub-Department list
  // const [subdepartmentList, setSubdepartmentList] = useState([]);

  // useEffect(() => {
  //   subdeptList();
  // }, [chooseDepartment]);

  // const subdeptList = () => {
  //   const params = {
  //     ComID: User.CompanyId,
  //     StaffID: -1,
  //     DepartID: chooseDepartment,
  //     Flag: "S",
  //     Status: 1,
  //     Type: "POST",
  //     BranchID: User.BranchId,
  //     FetchURL: `${appURL}api/admin/sub-department`,
  //   };

  //   Fetchdata(params).then(function (result) {
  //     if (result.StatusCode === 200) {
  //       const postResult = result.SubDepList ? result.SubDepList : "";
  //       setLoading(false);
  //       setSubdepartmentList(postResult);
  //     } else {
  //       setLoading(false);
  //       setSubdepartmentList([]);
  //     }
  //   });
  // };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Designation"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setDesignationList(srchResult);
      } else {
        setDesignationList({});
      }
    } else {
      setDesignationList(originalList);
    }
  };

  const handleChanges = (e) => {
    setChooseSubDepartment("-1");
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setChooseDepartment(value);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setChooseSubDepartment(value);
  };

  const dataWithIndex = designationList.map((d, i) => ({
    ...d,
    Index: i + 1,
    Status: d.IsActive === "I" ? "Inactive" : "Active",
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Designation", field: "Designation" },
    { header: "Department", field: "Department" },
    { header: "Sub Department", field: "SubDepartment" },
    { header: "Staff", field: "NoOfStaff" },
    { header: "Max. Sal", field: "MaxSal" },
    { header: "Min. Sal", field: "MinSal" },
    { header: "Status", field: "IsActive" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    generatePdf(userDetails, exportColumns, dataWithIndex);
  };

  const dataToExcel = designationList.map((d, i) => ({
    "S.N.": i + 1,
    Designation: d.Designation,
    Department: d.Department,
    "Sub Department": d.SubDepartment,
    Staff: d.NoOfStaff,
    "Max. Sal": d.MaxSal,
    "Min. Sal": d.MinSal,
    Status: d.IsActive === "I" ? "Inactive" : "Active",
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Designation");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper  ps-4 pe-4">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">
              {mode === "en" ? "Designation" : "पदनाम"}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <span className="me-2 ">Expires in: {expires} </span>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>
        <>
          <div className="sec-dataTable">
            <div className="upper-dataTbl">
              <div className="uk-flex  uk-flex-wrap uk-flex-between uk-flex-middle mb-3">
                <div className="uk-flex uk-flex-wrap me-3">
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en"
                        ? "Select Department"
                        : "विभाग चयन गर्नुहोस्"}
                    </label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="snotifiaction"
                      value={chooseDepartment}
                      onChange={handleChanges}
                      className="form-select form-select-sm"
                    >
                      <option
                        value="0"
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        {mode === "en"
                          ? "Select Department"
                          : "विभाग चयन गर्नुहोस्"}
                      </option>
                      <option value="-1">
                        {mode === "en" ? "Select All" : "सबै चयन गर्नुहोस्"}
                      </option>
                      {departmentList.map((item) => (
                        <option
                          key={item.DepartmentID}
                          value={item.DepartmentID}
                        >
                          {item.Department}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en"
                        ? "Select Sub-Department"
                        : "उप विभाग चयन गर्नुहोस्"}
                    </label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="snotifiaction"
                      value={chooseSubDepartment}
                      onChange={handleChange}
                      className="form-select form-select-sm"
                    >
                      <option
                        value="0"
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        {mode === "en"
                          ? "Select Sub-Department"
                          : "उप विभाग चयन गर्नुहोस्"}
                      </option>
                      <option value="-1">
                        {mode === "en" ? "Select All" : "सबै चयन गर्नुहोस्"}
                      </option>
                      {subdepartmentList.map((item) => (
                        <option key={item.SubDepartID} value={item.SubDepartID}>
                          {item.SubDepartName}
                        </option>
                      ))}
                    </select>
                  </div> */}
                </div>

                <div className="">
                  <div className="d-flex uk-flex-wrap uk-flex-middle uk-flex-right">
                    <div className="btn-addlnote mt-3">
                      <button
                        type="button"
                        class="btn btn-sm"
                        style={{
                          background: "var(--button-color)",
                          color: "white",
                        }}
                        onClick={addLeaveNote}
                      >
                        <GoPlus color="#fff" className="me-1" />
                        {mode === "en" ? "Add New" : "नयाँ थप्नुहोस्"}
                      </button>
                    </div>

                    <div className="export-btn">
                      <button
                        uk-tooltip="Export Excel"
                        onClick={toExcel}
                        className="me-1 ms-2 border-0"
                      >
                        <RiFileExcel2Fill size="1rem" color="#136438" />
                      </button>
                    </div>
                    <div className="export-btn">
                      <button
                        uk-tooltip="Export Pdf"
                        onClick={toPdf}
                        className="mx-1 border-0"
                      >
                        <BsFileEarmarkPdfFill size="1rem" color="#ff0002" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={designationList}
                customStyles={customStyles}
                pagination
                paginationRowsPerPageOptions={[10, 20, 50, 100]} // Customizable options
                fixedHeader
                fixedHeaderScrollHeight="370px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderComponent={
                  <>
                    <div className=" w-100">
                      <div className="d-flex uk-flex-middle justify-content-end">
                        <div>
                          <div class="uk-search uk-search-default">
                            <AiOutlineSearch className="search-icon" />
                            <input
                              placeholder={
                                mode === "en" ? "Search" : "खोजी गर्नुहोस्"
                              }
                              ref={searchInput}
                              type="text"
                              className="form-control form-control-sm searchField"
                              onChange={searchHandler}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      <DesignationPopup
        setDesignationPopup={setDesignationPopup}
        reload={reload}
        designationPopup={designationPopup}
        setReload={setReload}
        desgList={desgList}
        designationFormValue={designationFormValue}
        setDesignationFormValue={setDesignationFormValue}
        designationFormError={designationFormError}
        setDesignationFormError={setDesignationFormError}
      />

      <DesignationEditPopup
        setDesignationEditPopup={setDesignationEditPopup}
        designationEditPopup={designationEditPopup}
        reload={reload}
        setReload={setReload}
        desgList={desgList}
        designationFormValue={designationFormValue}
        setDesignationFormValue={setDesignationFormValue}
        designationFormError={designationFormError}
        setDesignationFormError={setDesignationFormError}
        titleId={titleId}
      />
    </>
  );
}
