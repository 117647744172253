import React, { useContext, useEffect } from "react";
import CloseIcon from "../../images/CloseIcon.svg";
import $ from "jquery";
import UpperbarContext from "../context/upperbar-context";
import AuthContext from "../context/auth-context";
import { Fetchdata } from "../hooks/getData";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FaUser, FaCalendarAlt } from "react-icons/fa";
const Comment = ({
  initialValue,
  formValue,
  setFormValue,
  formError,
  isSubmit,
  setIsSubmit,
  viewList,
}) => {
  const { mode, appURL } = useContext(UpperbarContext);
  const { User } = useContext(AuthContext);
  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setFormValue({ ...formValue, [name]: value });
  };

  // API for comment List
  const [commentList, setCommentList] = useState([]);

  useEffect(() => {
    getCommentList();
  }, []);

  const getCommentList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      Flag: "SC",
      TicketNo: viewList.TicketNo,
      UserID: User.UID.toString(),
      Type: "POST",
      FetchURL: `${appURL}api/help-desk/admin`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setCommentList(postResult);
      } else {
        setCommentList([]);
      }
    });
  };

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        Flag: "IC",
        TicketNo: viewList.TicketNo,
        UserID: User.UID.toString(),
        Email: User.Username,
        Comment: formValue.comment,
        FetchURL: `${appURL}api/help-desk/admin`,
        Type: "POST",
      };

      Fetchdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          getCommentList();
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });
      setFormValue(initialValue);
      setIsSubmit(false);
    }
  }, [formError]);

  const handleDeleteTrue = async (data) => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      Flag: "RC",
      CommentID: data.CommentID.toString(),
      UserID: User.UID.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Signature: "p0m76" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/help-desk/admin`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getCommentList();
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });
    });
  };
  return (
    <>
      {commentList.length > 0 && (
        <div className="comment__list">
          {commentList.map((props) => (
            <div className="comment__wrapper">
              <div>{props.Comment}</div>
              <div className="d-flex uk-flex-between mt-2">
                <div className="uk-flex">
                  {props.Email && (
                    <span>
                      <>
                        <FaUser />
                        {props.Email}
                      </>
                    </span>
                  )}

                  <span>
                    <FaCalendarAlt />
                    {props.CreatedDate}
                  </span>
                </div>

                <div
                  className="remove__comment"
                  onClick={() => handleDeleteTrue(props)}
                >
                  {mode === "en" ? "Remove" : "हटाउनुहोस्"}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className=" basicform comment__form">
        <form>
          <div className="form-group">
            {/* <label
            className="form-label"
            htmlFor="comment"
            style={{ fontSize: "12px" }}
          >
            Comment <sup style={{ color: "red" }}>*</sup>
          </label> */}
            <textarea
              placeholder={
                mode === "en" ? "Add a comment" : "टिप्पणी थप्नुहोस्"
              }
              id="comment"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="comment"
              value={formValue.comment}
              onChange={handleChange}
              rows="3"
            />
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {formError.comment}
            </p>
          </div>
        </form>
      </div>
    </>
  );
};

export default Comment;
