import React, { useState, useEffect, useContext } from "react";
import { Fetchdata } from "../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import HelpdeskContext from "./HelpdeskContext";
import UpperbarContext from "../context/upperbar-context";
import $ from "jquery";
import AuthContext from "../context/auth-context";
import { GetEnglishDate, GetNepaliDate } from "../hooks/dateConvertor";

function HelpdeskState(props) {
  const { User } = useContext(AuthContext);
  const { appURL } = useContext(UpperbarContext);
  var d = new Date();
  // attendance Todo--------------
  const initialValue = {
    fullname: "",
    category: "",
    subject: "",
    message: "",
    priority: "M",
    dueDate: "",
  };

  const [formValues, setFormValues] = useState(initialValue);

  const listVal = {
    name: "openTicket",
  };

  const [listValues, setListValues] = useState(listVal);

  const [formErrors, setFormErrors] = useState({});
  const [submit, setSubmit] = useState(false);

  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");
  const [image, setImage] = useState("");

  const [loading, setLoading] = useState(true);

  const [originalList, setOriginalList] = useState(null);

  const [formList, setFormList] = useState([]);

  // to show Todo list

  useEffect(() => {
    formLst();
  }, [listValues]);

  const formLst = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID.toString(),
      Flag:
        listValues.name === "openTicket"
          ? "SO"
          : listValues.name === "assignedToMe"
          ? "SOT"
          : listValues.name === "assignedToOthers"
          ? "S"
          : listValues.name === "unassigned"
          ? "SU"
          : "",
      FetchURL: `${appURL}api/help-desk/admin`,
      Type: "POST",
    };

    Fetchdata(params).then(function (resp) {
      if (resp.StatusCode === 200) {
        const postResult = resp.Values ? resp.Values : "";
        setFormList(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setFormList([]);
        setOriginalList([]);
        setLoading(false);
      }
    });
  };

  // to edit and view Todo

  const [perID, setPerId] = useState("");

  const [viewPop, setViewPop] = useState(false);
  const [viewList, setViewList] = useState(false);

  const handleView = (data) => {
    setPerId(data.TicketNo);
    setViewPop(true);
  };

  useEffect(() => {
    formInfo();
  }, [perID]);

  const formInfo = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID.toString(),
      Flag: "SI",
      TicketNo: perID.replace("#", ""),
      FetchURL: `${appURL}api/help-desk/admin`,
      Type: "POST",
    };

    Fetchdata(params).then(function (resp) {
      if (resp.StatusCode === 200) {
        const data = resp.Values ? resp.Values[0] : "";

        setViewList(data);
      } else {
        setViewList([]);
      }
    });
  };

  const [categoryList, setCategoryList] = useState([]);

  // to show Todo list

  useEffect(() => {
    categoryLst();
  }, []);

  const categoryLst = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      IsActive: "A",
      FetchURL: `${appURL}api/help-desk/category`,
      Type: "POST",
    };

    Fetchdata(params).then(function (resp) {
      if (resp.StatusCode === 200) {
        const postResult = resp.Values ? resp.Values : "";
        setCategoryList(postResult);
      } else {
        setCategoryList([]);
      }
    });
  };

  return (
    <HelpdeskContext.Provider
      value={{
        formValues,
        setFormValues,
        initialValue,
        formErrors,
        setFormErrors,
        submit,
        setSubmit,
        formList,
        setFormList,
        loading,
        setLoading,
        formLst,
        originalList,
        setOriginalList,
        handleView,
        viewPop,
        setViewPop,
        viewList,
        setViewList,
        isUploaded,
        setIsUploaded,
        typeFile,
        setTypeFile,
        image,
        setImage,
        categoryLst,
        categoryList,
        listValues,
        setListValues,
      }}
    >
      {props.children}
    </HelpdeskContext.Provider>
  );
}
export default HelpdeskState;
