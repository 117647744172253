import React, { useContext, useEffect, useRef, useState } from "react";
import "./monthlyAttendance.css";
import Spinner from "../loading/spinner";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Fetchdata } from "../hooks/getData";
import AuthContext from "../context/auth-context";
import NepaliDate from "nepali-date-converter";
import StaffContext from "../adminPanel/organization/staffState/StaffContext";
import Toast from "../Toast";
import { GetTwelveHourFormatTime } from "../hooks/dateConvertor";
import UpperbarContext from "../context/upperbar-context";

export default function MonthlyAttendance({ month, DFlag, reload, appURL }) {
  const { customStyles } = useContext(UpperbarContext);
  const [loading, setLoading] = useState(true);
  const [attendanceList, setAttendanceList] = useState([]);
  const { User } = useContext(AuthContext);


  const weekDay = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  //

  const columns = [
    {
      name: "Date",
      width: "150px",
      center: true,
      grow: 0,
      selector: (row) => row.AttenDate,
    },
    {
      name: "Day",
      center: true,
      grow: 0,
      minWidth: "150px",
      selector: (row) => {
        if (DFlag === "N") {
          const nDate = new NepaliDate(row.AttenDate).getBS();
          //
          const day = nDate.day;
          return weekDay[day];
        } else {
          const day = new Date(row.AttenDate).getDay();

          return weekDay[day];
        }
      },
    },
    {
      name: "Check In",
      // grow: 0,
      minWidth: "150px",
      center: true,
      selector: (row) => {
        if (row.CheckIn === null) {
          return "No Check In";
        } else {
          return row.CheckIn;
        }
      },
    },
    {
      name: "Check Out",
      // grow: 0,
      minWidth: "150px",
      center: true,
      selector: (row) => {
        if (row.CheckOut === null) {
          return "No Check Out";
        } else {
          return row.CheckOut;
        }
      },
    },
    {
      name: "Day Type",
      center: true,
      selector: (row) => row.DayType,
    },
    {
      name: "Remark",
      // grow: 0,
      center: true,
      width: "250px",
      selector: (row) => {
        if (row.CheckIn === "No Check In" && row.CheckOut === "No Check Out") {
          if (row.LeaveID != "0") {
            return "On Leave";
          } else {
            return "Absent";
          }
        } else {
          if (row.IsVerified === "Verified") {
            const remark = row.InRemarks + " | " + row.OutRemarks;
            return remark;
          } else if (row.IsVerified === "Unverified") {
            return "In review";
          } else {
            return "Rejected";
          }
        }
      },
    },
  ];

  useEffect(() => {
    if (Object.keys(User).length && month) {
      let cur_year =
        DFlag === "N"
          ? new NepaliDate().getYear() + "/"
          : new Date().getFullYear() + "-";
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: User.UID,
        Flag: "M",
        Value: `${cur_year}${month}`,
        DFlag: DFlag,
        Type: "POST",
        FetchURL: `${appURL}api/atten-report`,
      };

      Fetchdata(dataForm)
        .then(function (result) {
          if (result.StatusCode === 200) {
            const postResult = result.Values ? result.Values : "";
            setAttendanceList(postResult);
            setLoading(false);
          } else {
            setAttendanceList([]);
            setLoading(false);
          }
        })
        .catch((err) => {
          setAttendanceList([]);
          setLoading(false);
        });
    }
  }, [month, reload]);



  return (
    <>
      {/* <Toast /> */}
      <div>
        {loading ? (
          <>
            {/* <div
              className=" text-center d-flex flex-column justify-content-center align-items-center"
              style={{ margin: "10% auto", width: "120px" }}
            >
              <p className="initial-msg">Please provide input!</p>
            </div> */}
            <Spinner />
          </>
        ) : (
          <>
            <DataTable
              columns={columns}
              data={attendanceList}
              customStyles={customStyles}
              pagination
              fixedHeader
              fixedHeaderScrollHeight="410px"
              highlightOnHover
              pointerOnHover
              responsive
              dense
              striped
              progressPending={loading}
            />
          </>
        )}
      </div>
    </>
  );
}
